import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import {Tooltip} from "flowbite-react";
import {InfoIcon} from "../../../../../Theme";

const WorkspaceOneTab: React.FC = () => {
  const [enabled, setEnabled] = useState(false);
  return (
    <div className="flex flex-col gap-5 mb-12">
      <div className="mb-3">
        <h2 className="text-textDark text-2xl font-normal mb-1">Workspace One Integration</h2>
        <p className="text-sm text-textDark">
          Manage the Workspace One Access & UEM settings for your organization.
        </p>
      </div>
      <hr className="border-1 h-px border-borderGray dark:border-textDark" />
      <div>
        <h2 className="text-xl mb-2 mt-2">WS1 Access Credentials</h2>
        <p className="text-sm text-textDark mt-2 mb-6">Manage your WS1 Access credentials which will be used to connect to your WS1 Access instance.</p>
        <div className="mb-5">
          <p className="text-base text-textDark mb-1">ClientID</p>
          <input
              type="text"
              className="border border-borderGray rounded-md p-1 w-8/12"
          />
        </div>
        <div className="mb-5">
          <p className="text-base text-textDark mb-1">Client Secret</p>
          <input
              type="text"
              className="border border-borderGray rounded-md p-1 w-8/12"
          />
        </div>
        <div className="mb-5">
          <p className="text-base text-textDark mb-1">Access Tenant URL</p>
          <input
              type="text"
              className="border border-borderGray rounded-md p-1 w-8/12"
          />
        </div>
      </div>

      <hr className="border-1 h-px border-borderGray dark:border-textDark" />

      <div>
        <h2 className="text-xl mb-2 mt-2">WS1 UEM Credentials</h2>
        <p className="text-sm text-textDark mt-2 mb-6">Manage your WS1 UEM credentials which will be used to connect to your WS1 UEM instance.</p>
        <div className="mb-5">
          <p className="text-base text-textDark mb-1">ClientID</p>
          <input
              type="text"
              className="border border-borderGray rounded-md p-1 w-8/12"
          />
        </div>
        <div className="mb-5">
          <p className="text-base text-textDark mb-1">Client Secret</p>
          <input
              type="text"
              className="border border-borderGray rounded-md p-1 w-8/12"
          />
        </div>
        <div className="mb-5">
          <p className="text-base text-textDark mb-1">UEM Tenant URL</p>
          <input
              type="text"
              className="border border-borderGray rounded-md p-1 w-8/12"
          />
        </div>
      </div>

    </div>
  );
};

export default WorkspaceOneTab;
