import React, {ReactElement} from "react"
import TabTitle from "./TabTitle"

type Props = {
    children: ReactElement[],
    setSelectedTab: string | ((tab: string) => void)
    selectedTab: string | ((tab: string) => void)
}

const Tabs: React.FC<Props> = ({children, setSelectedTab, selectedTab}) => {
    return (
        <div className="flex space-x-6 border-b">
            {children.map((item, index) => (
                <TabTitle
                    key={index}
                    TabKey={item.props.TabKey}
                    title={item.props.title}
                    count={item.props.count}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />
            ))}
        </div>
    )
}

export default Tabs