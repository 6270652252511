//^  This component is executed when the user clicks on one of the pathways and it shows the details of that pathway.

import { set_SelectedPathway } from "../../../GlobalState/store.actions";
import { store } from "../../../GlobalState/store";
import { Drawer } from "../../../Theme/Drawer";
import { EyeIcon, CloseEyeIcon, AppDivider, CustomClipboard, PathwayIcon, ContentIcon} from "../../../Theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Fragment, useCallback, useContext } from "react";
import { TabContext } from "../../../Context/TabContext/TabContext";
import { WorkerIcon } from "../../../Theme/Icons/WorkerIcon";
import { ActionIcon } from "../../../Theme/Icons/ActionTemplate/ActionIcon";
import { ArrowRight } from "../../../Theme/Icons/ArrowRight";
import { IPathways } from "../../../Types/Pathways/Pathways";
import { PathwayUpdated } from "../../../Types/Pathways/SinglePathwayVar";
import { IState } from "../../../Types/States/States";

//! Type script
interface IPropsDrawer { tableRef?: any; showDrawer: boolean; selectedPathway?: IPathways; singlePathwayVar?: PathwayUpdated; setShowDrawer: (showDrawer:boolean)=>void; setShowCommandBar: (showCommandBar:boolean)=>void}

//^ Start component
const AppDrawer=({ tableRef, showDrawer, selectedPathway, singlePathwayVar, setShowDrawer, setShowCommandBar,
}: IPropsDrawer) => {

  //! Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { globalTabs, updateTab } = useContext(TabContext);
  const { state: globalState, dispatch } = useContext(store);

  const closeDrawer = useCallback(() => {
    setShowDrawer(false);
    setShowCommandBar(false);
  }, []);

  //^ UI section
  //! Part I--> Name and Activity of pathway | Main details in the top of component 
  const pathwayName = () => {
    return (
      <div className="flex">
        <PathwayIcon  className="flex-shrink-0 w-10 h-10 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white" />
        <div className="flex flex-col items-start justify-center p-2">
          <p className="mb-2 text-lg whitespace-nowrap text-textDark dark:text-white">
            {selectedPathway?.name}
          </p>
          <p className="flex items-center gap-1 text-xs whitespace-nowrap text-grayColor dark:text-white">
            {selectedPathway?.status !== undefined && (
              <>
                {selectedPathway?.status ? (
                  <>
                    <EyeIcon className="w-3 h-3" />
                    <span className="inline text-xs">
                      {t("PathwayPage.Active")}
                    </span>
                  </>
                ) : (
                  <>
                    <CloseEyeIcon className="w-3 h-3" />
                    <span className="inline text-xs">
                      {" "}
                      {t("PathwayPage.Inactive")}{" "}
                    </span>
                  </>
                )}
              </>
            )}
          </p>
        </div>
      </div>
    );
  };

  //!  Part II--> pathway description
  const pathwayDescription = () => {
    return (
      <div className="flex flex-col items-start justify-start gap-1 mb-2">
        <p className="text-sm text-textGray">{t('PathwayPage.Pathway_Description')}</p>
        <p className="text-sm text-textDark">{selectedPathway?.description}</p>
      </div>
    );
  };

  //! Part III---> Pathway Details 
  const pathwayDetails = () => {
    return (
      <Fragment>
        <AppDivider />
        <div className="flex flex-col items-start justify-start gap-1 mb-2">
          <p className="text-sm text-textGray">{t('PathwayPage.Pathway_Details')}</p>
          <ul className="list-none">
            <li className="flex gap-2 my-2">
              <span className="w-20 text-sm text-textGray">{t('PathwayPage.States')}</span>
              <span className="text-sm text-textDark">
                {selectedPathway?.state_count || 0} {t('PathwayPage.States')}
              </span>
            </li>
            <li className="flex gap-2 my-2">
              <span className="w-20 text-sm text-textGray">{t('PathwayPage.Entry_State')}</span>
              <span className="text-sm text-textDark">
                {selectedPathway?.entry_state_name}
              </span>
            </li>
            <li className="flex gap-2 my-2">
              <span className="w-20 text-sm text-textGray">{t('PathwayPage.Workers')}</span>
              <span className="text-sm text-textDark">
                {selectedPathway?.participant_count || 0} {t('PathwayPage.Workers')}
              </span>
            </li>
            <li className="flex gap-2 my-2">
              <span className="w-20 text-sm text-textGray">{t('PathwayPage.Pathway_Slug')}</span>
              <CustomClipboard
                showInfoIcon={false}
                showClipboardText
                clipboardText={selectedPathway?.slug || ""}
              />
            </li>
          </ul>
        </div>
        <AppDivider />
      </Fragment>
    );
  };

  //! Part IV---> Pathway States 
  const pathwayStates = () => {
    return (
      <div className="flex flex-col items-start justify-start gap-1 mb-2">
        <p className="text-sm text-textGray">{t('PathwayPage.Pathway_States')}</p>
        <ul className="w-full list-none">
          {singlePathwayVar !== undefined && (
            <>
              {singlePathwayVar?.states !== undefined
                ? singlePathwayVar?.states?.map(
                    (state: IState, index: number) => {
                      return (
                        <li
                          className="flex flex-col w-full gap-1 my-4"
                          key={index}
                        >
                          <p className="text-base text-textDark">
                            {state.name}
                          </p>
                          <div className="flex text-xs text-textDark">
                            <span className="flex items-center gap-1 mr-5 text-sm text-textGray">
                              <WorkerIcon className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white" />
                              {state.participant_count || 0} {t('PathwayPage.Workers')}
                            </span>
                            <span className="flex items-center gap-1 mr-5 text-sm text-textGray">
                              <ContentIcon className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white" />
                              {singlePathwayVar?.states_info?.filter(
                                (item: any) => item?.state_id === state.id
                              )[0].content_count || 0}{" "}
                              {t('PathwayPage.Contents')}
                            </span>
                            <span className="flex items-center gap-1 mr-5 text-sm text-textGray">
                              <ActionIcon className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white" />
                              {singlePathwayVar?.states_info?.filter(
                                (item: any) => item?.state_id === state.id
                              )[0].action_count || 0}{" "}
                              {t('PathwayPage.Actions')}
                            </span>
                          </div>
                        </li>
                      );
                    }
                  )
                : null}
            </>
          )}
        </ul>
      </div>
    );
  };

  //! Part V---> view button 
  const viewPathwayButtons = () => {
    return (
      <div className="pb-5 ">
        <div
          onClick={() => {
            const newTab = {
              index: globalTabs.tabs.activeTab.index,
              path: `/pathways/${selectedPathway?.slug}`,
              name: `Pathway - ${selectedPathway?.name}`,
            };
            updateTab(newTab);

            //! this is for setting the selected pathway in the context store
            if(selectedPathway){
              dispatch(set_SelectedPathway(selectedPathway ));
            }
            navigate(`/pathways/${selectedPathway?.slug}`);
          }}
          className="flex items-center justify-center w-full py-6 bg-blueColor btn-drawer hover:cursor-pointer"
        >
          <span className="flex items-center justify-center gap-2 text-sm text-white">
            <PathwayIcon className="w-4 h-4" />
            View Pathway <ArrowRight className="w-4 h-4" />
          </span>
        </div>
      </div>
    );
  };

  //^ Main UI
  return (
    <Drawer outsideRef={tableRef} setOpen={closeDrawer} isOpen={showDrawer} title="" isFull
    >
      {selectedPathway ? (
        <div className="flex flex-col justify-between h-full overflow-y-scroll">
          <div className="flex flex-col gap-2 p-4">

            {/*//! Main details of pathway | name & active / non active */}
            {pathwayName()}

            {/*//! Pathway description  */}
            {pathwayDescription()}

            {/* //! Pathway details */}
            {pathwayDetails()}

            {/* //! Pathway States */}
            {pathwayStates()}

            {/* //!The dividing line */}
            <hr className="h-px border-1 border-borderGray dark:border-textDark" />
            
          </div>

          {/* //! View pathway buttons */}
          {viewPathwayButtons()}
        </div>
      ) : null}
    </Drawer>
  );
}

export default AppDrawer;
