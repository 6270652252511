import React, { FC, memo, useCallback, useState } from 'react';
import { MdClose, MdEmail, MdSms } from "react-icons/md";
import { AiTwotoneSound } from "react-icons/ai";
import EmailNew from "./EmailNew";
import SmsTextNew from "./SmsTextNew";
import PushAction from "./PushNotificationNew";
import { state_props } from "../../../Common/Types";
import { ActionTemplateIcons } from '../../../Theme';
import ALNotificationNew from './ALNotificationNew';

const Action: FC<state_props> = memo(({ onClose, setState, onSuccess }) => {
    const [selected, setSelected] = useState('SMS')

    const onCLickHandle = useCallback((item: string) => setSelected(item), [selected])

    const returnName = useCallback((item: string) => {
        if (item === 'SMS') return 'SMS Text'
        if (item === 'PUSH') return 'WS1 Push Notification'
        if (item === 'AL') return 'AL Notification'

        return item
    }, [])

    return (
        <div className="relative flex w-9/12 h-full p-8 overflow-auto text-gray-600 bg-white rounded-md modal max-w-screen-2xl">

            {/*---------------------------------------------------------*/}
            {/*Create New Action modal with Action type tabs*/}
            <div className="w-full">
                <div className="flex items-center justify-between w-full pb-4 text-lg">
                    <p className="text-2xl ">Add Action</p>
                    <div onClick={onClose} className="p-1 bg-gray-400 rounded-full cursor-pointer">
                        <MdClose className="text-white" />
                    </div>
                </div>
                <div className="flex border border-[#D8D8D8] rounded bg-white w-full my-5">

                    {/*---------------------------------------------------------*/}
                    {/*Create New Action tabs*/}
                    <div className="w-3/12 text-lg border-r border-[#D8D8D8] rounded">
                        {
                            [
                                // {icon: <MdEmail/>, text: 'Email'},
                                { icon: ActionTemplateIcons.sms, text: 'SMS' },
                                { icon: ActionTemplateIcons.notification, text: 'PUSH' },
                                { icon: ActionTemplateIcons.al_notification, text: 'AL' }

                            ].map((item, index) => {
                                return (
                                    <div key={index} className={`${selected === item.text && 'bg-blueColor'}`}>
                                        <div className={`flex items-center p-3 cursor-pointer space-x-2`} onClick={() => onCLickHandle(item.text)}>
                                            <div className={`w-6 h-6 ${selected === item.text ? 'text-white' : 'text-grayColor'}`}>
                                                {item.icon}
                                            </div>
                                            <span className={`${selected === item.text ? 'text-white' : 'text-gray-600'}`}>{returnName(item.text)}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/*---------------------------------------------------------*/}
                    {/*Action container for each Action Type*/}
                    <div className="flex w-full bg-gray-100 py-5 divide-x divide-[#D8D8D8]">
                        <div className="w-full px-4 space-y-2">
                            {(() => {
                                switch (selected) {
                                    // case 'Email':
                                    //     return <EmailNew type={'create'} setState={setState} onClose={onClose} onSuccess={onSuccess}/>
                                    case 'SMS':
                                        return <SmsTextNew type={'create'} setState={setState} onClose={onClose} onSuccess={onSuccess} />
                                    case 'PUSH':
                                        return <PushAction type={'create'} setState={setState} onClose={onClose} onSuccess={onSuccess} />
                                    case 'AL':
                                        return <ALNotificationNew type={'create'} setState={setState} onClose={onClose} onSuccess={onSuccess} />
                                    default:
                                        return null
                                }
                            })()}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
});

export default Action;