import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";
import Input from "../../General/Form/Input";
import TextArea from "../../General/Form/TextArea";
import Button from "../../General/Button";
import { AiFillDelete, AiFillFile } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import NestedContentListItem from "../NestedContentListItem";
import {
    checklist, Conditions_T,
    contents,
    ruleBody, ruleIds,
    Single_Content_Single,
    state_Edit_Checklist
} from "../../../Common/Types";
import { Fade, TextField } from "@mui/material";
import { reorder } from "../../Utils/Reorder";
import ContentExtra from "../../Event/ContentExtra";
import { MdModeEditOutline } from "react-icons/md";
import UseUploadFile from "../../../Hook/UseUploadFile";
import { uploadFile } from "../../../apiQuery/Common/Upload.api";
import { useMutation, useQueryClient } from "react-query";
import UseTab from "../../../Hook/UseTab";
import Tabs from "../../TabsT/Tabs";
import Tab from "../../TabsT/Tab";
import { BsFillEyeFill } from "react-icons/bs";
import OverlayModal from "../../Layouts/OverlayModal";
import { createContentInState, updateContent } from "../../../apiQuery/Contents/Content.apis";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../../../GlobalState/store";
// import { setSnackbar } from "../../../GlobalState/store.actions";
import { updateChecklist } from "../../../apiQuery/Contents/ContentType.apis";
import PreviewContentModal from "../ContentPreview/PreviewContentModal";
import ContentTypeCardPreview from "../ContentPreview/ContentTypeCardPreview";
import Mobile from "../ContentPreview/Mobile";
import {
    createTimeBaseEvent,
    deleteTimeCondition,
    updateTimeCondition
} from "../../../apiQuery/Condition/TimeBase.apis";
import { createContentRule } from "../../../apiQuery/Condition/Rule.apis";
import { formatQuery } from "react-querybuilder";
import initialQuery from "../../../utils/InitialRules";
import jsonHandler from "../../Utils/JsonHandler";
import UseUpload from "../../../Hook/UseUpload";
import { baseURL } from "../../../apiQuery";
import UploadProgress from "../../General/UploadProgress";
import { useTranslation } from 'react-i18next';
import { toast } from "../../../utils/Toast";
import { IChecklist } from '../../../Types/ContentTemplate/Checklist';
import { EnumContentType } from '../../../Types/ContentTemplate/ContentType';
import { IAttachTemplate, getContentTemplateFilter, setATemplateToContentType } from '../../../apiQuery/ContentTemplate/ContentTemplate.apis';
import { getStateContentTemplateFilter } from '../../../apiQuery/ContentTemplate/StateContentTemplate.apis';
import moment from "moment";
import AppInput from '../../Shared/AppInput/AppInput';
import { AppLoader } from '../../Shared/AppLoader';
import AppButton from '../../Shared/AppButton/AppButton';
import Condition from '../../Event/Condition';
import AppTextArea from '../../Shared/AppTextArea/AppTextArea';
import { CustomClipboard, AppButton as AppThemeButton } from '../../../Theme';
import { setAddContentTemplateModal } from '../../../GlobalState/store.actions';
import { LanguageEnum } from '../../../Types/ContentTemplate/Langauge';
import { CheckList } from '../../../@Core';

const ChecklistNew: FC<state_Edit_Checklist> = memo(({ onClose, defaultValue, type, onSuccess }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();;

    const queryClient = useQueryClient()

    const { register, handleSubmit, watch, setValue, trigger, getValues, formState: { errors } } = useForm<checklist>({
        defaultValues: defaultValue ? defaultValue?.checklist : {}
    });


    const [altTitle, setAltTitle] = useState<string>("")

    const { uploadForm, progress, isLoading } = UseUpload(
        `${baseURL}upload`
    );

    //@ts-ignore
    const timeValue = parseInt(getValues("timeValue")) as number

    //@ts-ignore
    const eventName = getValues("eventName") as string

    //@ts-ignore
    const typeOf = getValues("typeOf") as string

    //@ts-ignore
    const checkInValue = getValues("checkInValue") as string

    //@ts-ignore
    const target_func = getValues("target_func") || undefined as string

    const [timeEvent, setTimeEvent] = useState({
        isAdded: false,
        isRuleAdded: false,
    })

    const [passedContent, setPassedContent] = useState({
        targetField: '',
        targetFunction: '',
    })

    const params = useParams();
    const id = params.stateSlug || ""
    const pathwaySlug = params.slug || ""

    const {
        state,
        dispatch
    } = useContext(store);
    const organization_id = useMemo(() => state.organization_id, [state.organization_id])

    const [ruleId, setRuleId] = useState<ruleIds>({
        event: undefined,
        time: undefined
    });
    const [query, setQuery] = useState(initialQuery);
    const isContentTemplate = useMemo(() => defaultValue?.template_id ? true : false, [defaultValue?.template_id])

    const items = watch("items") || []

    const [modal, setModal] = useState({
        pathwayView: false,
        contentView: false
    })


    // mutations
    // =======================================================================================================
    const [previousPage, setPreviousPage] = useState<string>()
    const [nextPage, setNextPage] = useState<string>()
    const [contentPriority, setContentPriority] = useState<number>(0);
    const [searchContentTemplate, setSearchContentTemplate] = useState('')




    const { data: checklistTemplateData, isLoading: checklistCcontentTempalteIsLoading, isError: checklistTemplateIsError, error: checklistTemplateError, mutate } = useMutation(
        (pageDirection: 'next' | 'previous' | '') => getStateContentTemplateFilter<IChecklist, EnumContentType.ChecklistContent>
            (pathwaySlug, id, EnumContentType.ChecklistContent, 8, '', nextPage, previousPage, pageDirection), {
        onSuccess(data) {
            if (data?.data) {
                const { next, previous } = data?.data.paginate
                setNextPage(next)
                setPreviousPage(previous)
            }
        },
    });

    useEffect(() => {
        mutate('')
    }, [mutate]);



    const flattedCheckListTemplateOptions = useMemo(() => {
        // const newOption = { value: '0', title: 'No Template Content' };
        const currentLanguage = i18n.language;

        const flattedList = checklistTemplateData && checklistTemplateData.data.data.map((item) => {
            const { id } = item;
            return item.checklist.map((ann) => {
                return {
                    template_id: id,
                    checklist_id: ann.id,
                    title: ann.title,
                    description: ann.description,
                    updated_at: ann.updated_at,
                    metaData: item.content_metadata,
                    items: ann.items,
                    language: ann.language
                }
            })
        }).flatMap((item) => item).filter(item => item.language === LanguageEnum.English);
        return flattedList;
    }, [checklistTemplateData, i18n.language]);



    const search_result = useMemo(() => {
        if (searchContentTemplate === '') return flattedCheckListTemplateOptions
        return flattedCheckListTemplateOptions &&
            flattedCheckListTemplateOptions.filter(item => item.title.toLowerCase().includes(searchContentTemplate.toLowerCase()) ||
                item.description.toLowerCase().includes(searchContentTemplate.toLowerCase()))
    }, [flattedCheckListTemplateOptions, searchContentTemplate])



    const addContentTemplate = async (item: any) => {
        const obj = {
            template_id: item.template_id as  number,
            state_slug: id as string,
            pathway_slug: pathwaySlug as string,
            is_template: true,
            content_type: EnumContentType.ChecklistContent,
        }

        dispatch(setAddContentTemplateModal(obj))
        onClose()
    }





    // =======================================================================================================

    const createTimeEvent = useMutation((timeData: Conditions_T) => createTimeBaseEvent(organization_id, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.create_time_base_rule_failed')}`
            toast(message, 'error')
        }
    })

    const deleteCondition = useMutation((delId: number) => deleteTimeCondition(organization_id, delId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        }
    })

    const updateTimeEvent = useMutation((timeData: Conditions_T) => updateTimeCondition(organization_id, timeData.id || 0, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }))
    const createRuleCondition = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => {
            //@ts-ignore
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            toast(message, 'error')
        }
    })

    const UpDateFunc = async (createdData: contents) => {
        if (ruleId.event === 0) {
            let ruleId = null
            if (query.rules.length !== 0) {
                // const { data } = await createRuleCondition.mutateAsync(
                //     // {
                //     //     organization_id: organization_id,
                //     //     ...jsonHandler(timeValue, typeOf, eventName, query,target_func)
                //     // }
                // )
                // ruleId = data?.id || null
                ruleId = await createRuleConditionForContent()
            }
            await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId })
        } else await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId.event || null })
    }

    const createTimeRule = async (ID: number | undefined) => {
        const typeIs = typeOf === 'days' ? 'hour' : typeOf
        await createTimeEvent.mutateAsync({
            deliver_time: timeValue ? timeValue : 5,
            deliver_unit: typeIs || "minute",
            state_id: parseInt(defaultValue.state_id),
            content_id: ID
        })
    }

    const [tab, setTab] = UseTab('Checklist Details')

    const handlePathwayView = useCallback(() => {
        setModal(prev => ({ ...prev, pathwayView: !prev.pathwayView }))
    }, [])

    const handleContentView = useCallback(() => {
        setModal(prev => ({ ...prev, contentView: !prev.contentView }))
    }, [])

    const [input, setInput] = useState('')
    const [edit, setEdit] = useState({
        isEdit: false,
        index: 0
    })
    const [imageSrc, file, render, name] = UseUploadFile('Checklist')
    const handleUpload = useMutation((formData: any) => uploadFile(formData), {})

    const createContents = useMutation((newContent: contents) => createContentInState(organization_id, pathwaySlug, id, { ...newContent }), {
        onSuccess: async (createdData: Single_Content_Single) => {
            if (timeEvent.isAdded) {
                await createTimeRule(createdData.data?.id)
            }
            toast(`${t('content.created')}`, 'success')
            !!onSuccess && onSuccess()
            onClose()
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.failed')}`
            toast(message, 'error')
        }
    })

    const updateContentMutation = useMutation((updateContentDetails: contents) => updateContent(organization_id, pathwaySlug, id, { ...updateContentDetails }, updateContentDetails.slug || ''), {
        onSuccess: async (data: Single_Content_Single) => {
            if (defaultValue) {
                if (data?.data?.checklist) {
                    await updateContentChecklist.mutateAsync({
                        ...data?.data?.checklist
                    })
                }
                toast(`${t('content.updated')}`, 'success')
                !!onSuccess && onSuccess()
                await queryClient.invalidateQueries(['content', defaultValue.id])
                onClose()
            }

        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const updateContentChecklist = useMutation((checklistDetails: checklist) => updateChecklist(organization_id, { ...checklistDetails }, checklistDetails?.id || 0), {
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })



    // @ts-ignore
    const createRuleTimePassedCondition = useMutation(({ targetField, targetFunction }: any) => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, targetFunction, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func: targetFunction, target_field: targetField })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore
            // const message = error?.response?.data?.message || "Failed creating content Rule"
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            toast(message, 'error')
        }
    })


    async function createRuleConditionForContent() {
        let data = null
        if (passedContent.targetField && passedContent.targetFunction) {


            const resultCondition = await createRuleTimePassedCondition.mutateAsync(
                {
                    targetField: passedContent.targetField,
                    targetFunction: passedContent.targetFunction
                }
            )
            data = resultCondition?.data
        } else {
            const resultRule = await createRuleCondition.mutateAsync()
            data = resultRule?.data
        }
        return data?.id || null
    }
    // ===============  handle the form submit ===============
    const onSubmit: SubmitHandler<checklist> = async data => {
        await trigger()
        let fileUpload = {
            link: ''
        }
        if (file !== undefined) {
            const formData = new FormData();
            // @ts-ignore
            formData.append("file", file);
            const { link } = await uploadForm(formData)
            fileUpload.link = link
            // fileUpload = await uploadFile(formData)
        }

        let shapeData;

        if (isContentTemplate) {

            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "checklist",
                // content_type: "checklist",
                // cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                priority: contentPriority,
                // tags: data?.tags ? [data.tags] : [],
                // preview_description: data?.preview_description,
                // preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                // checklist: {
                //     title: data.title,
                //     description: data.description,
                //     items: data.items || [],
                //     owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                // }
            }

        } else {
            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "checklist",
                // content_type: "checklist",
                cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                priority: isContentTemplate ? contentPriority : Number(data.priority),
                tags: data?.tags ? [data.tags] : [],
                preview_description: data?.preview_description,
                preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                checklist: {
                    title: data.title,
                    description: data.description,
                    items: data.items || [],
                    owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                }
            }
        }

        if (type === 'update') {
            const timeId = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined

            if (timeEvent.isAdded) {
                if (defaultValue?.time_condition && timeId) {
                    await updateTimeEvent.mutateAsync({
                        id: timeId,
                        deliver_time: timeValue ? timeValue : 5,
                        deliver_unit: typeOf || "minute",
                        state_id: parseInt(defaultValue.state_id),
                    })
                } else await createTimeRule(defaultValue.id)
            }
            if (!timeEvent.isAdded && defaultValue?.is_time_base) {
                if (id) await deleteCondition.mutateAsync(timeId)
            }

            await UpDateFunc(shapeData)
        }


    };


    const onUpdateContentFields = async (priority:number, alternativeTitle:string) => {
        const shapeData  = {
            ...defaultValue,
            priority: priority,
            alt_title: alternativeTitle
        }
        await updateContentMutation.mutateAsync(shapeData)
    }


    const title = getValues("title");

    const submitItem = () => {
        if (input === '') return
        if (edit.isEdit) {
            const updatedItem = [...items];
            updatedItem[edit.index] = updatedItem[edit.index] = input;
            setValue('items', updatedItem);
            setInput('')
            setEdit({ isEdit: false, index: 0 })
            return;
        }
        setValue('items', [...items, input]);
        setInput('')
    }

    const handleRemove = (ind: number) => setValue('items', items.filter((_, index) => ind !== index))

    const handleEdit = (item: string, ind: number) => {
        setEdit({ isEdit: true, index: ind })
        setInput(item)
    }

    const onDragEnd = (result: DropResult): void => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const itemsData = reorder(
            items,
            result.source.index,
            result.destination.index
        ) as string[]
        setValue("items", itemsData)
    };

    useEffect(() => {
        if (defaultValue) {
            const id = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined
            setRuleId({ time: id, event: defaultValue?.rules_content_id })

            if (defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.deliver_time) {
                //@ts-ignore
                setValue("timeValue", defaultValue?.time_condition[0]?.deliver_time)  //@ts-ignore
                setValue("typeOf", defaultValue?.time_condition[0]?.deliver_unit)
            }
            setContentPriority(defaultValue?.priority)
            setAltTitle(defaultValue?.alt_title)

        }
    }, [])
   

    const contentSlug = defaultValue?.content_template?.checklist[0]?.slug || defaultValue?.slug
    return (
        <div className="flex items-center w-full">
            <div className="flex flex-col w-full gap-4">
                {
                    contentSlug ? 
                    <CustomClipboard showInfoIcon clipboardText={contentSlug } />
                    : null
                }
                {
                        type === 'update' && isContentTemplate === true ? <>
                            <CheckList contentTemplate={defaultValue?.content_template} 
                            priority={contentPriority} 
                            alternativeTitle={altTitle}
                            onUpdateFields={onUpdateContentFields} />
                        </> : null
                }
                
                <form onSubmit={handleSubmit(onSubmit)}
                             className='w-full h-full pb-2 space-y-3 overflow-auto text-gray-600 text-md'>
                    {type === 'create' ? <>
                        <AppInput placeholder='Search' value={searchContentTemplate} onChange={e => setSearchContentTemplate(e.target.value)} name='searchTemplate' />
                        <div className='flex items-center justify-center'>
                            {checklistCcontentTempalteIsLoading ? <AppLoader isLoading size={35} /> : null}
                        </div>

                        <div className='grid grid-cols-2 gap-1'>
                            {search_result?.length === 0 ? <p className='my-2 text-md'>There isn't any template to show.</p> : search_result && search_result.map((item, index) => {
                                return <div className='flex flex-col items-start justify-start p-4 m-1 transition-all delay-75 rounded-md shadow-sm bg-gray-50 hover:bg-white' key={index}>
                                    <span
                                        onClick={() => addContentTemplate(item)}
                                        className='px-2 text-xs border rounded hover:cursor-pointer hover:bg-sky-100 border-sky-500 bg-sky-50 text-sky-700'>Add +</span>
                                    <p className='my-1 font-medium text-md'>{item.title}</p>
                                    <p className='flex-1 mb-3 text-xs'>
                                        {item.description}
                                    </p>
                                    <p className='my-1 text-xs'>
                                        {/* @ts-ignore */}
                                        Languages: <span className='font-medium uppercase text-sky-700'>{item.metaData?.map((item: any) => item.language).join(', ')}</span>
                                    </p>
                                    {item.updated_at ? <p className="mt-1 text-xs">Last updated {moment(item.updated_at).fromNow() + '. on ' + moment(item.updated_at).format('MMM D, YYYY')
                                    }</p> : null}
                                </div>
                            })}
                        </div>

                        <div className="flex">
                            <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
                                onClick={() => {
                                    mutate('previous')
                                }}
                                extendClass="mx-1">Previous</AppButton>
                            <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
                                onClick={() => {
                                    mutate('next')
                                }}
                                extendClass="mx-1">Next</AppButton>
                        </div>

                    </> : null}


                    {
                        type === 'update' && isContentTemplate === true ? <>
                            {/*---------------------------------------------------------*/}
                            {/*Checklist Details and Content Details tabs*/}
                            {/* <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab TabKey='Checklist Details' count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView()
                                    e.stopPropagation()
                                }} />} title={`${t('Content.Checklist_Details')}`}>{`${t('Content.Checklist_Details')}`}  </Tab>
                                <Tab TabKey='Content Details' count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView()
                                    e.stopPropagation()
                                }} />} title={`${t('Content.Content_Details')}`}>{`${t('Content.Content_Details')}`}</Tab>
                            </Tabs> */}

                            {/*---------------------------------------------------------*/}
                            {/*Checklist Details tab with form inputs*/}
                            {/* <Fade in={tab === "Checklist Details"}
                                style={{ display: tab === "Checklist Details" ? '' : 'none' }}>
                                <div className="space-y-4"> */}

                                    {/* <AppInput label='Title' name='title' value={defaultValue?.content_template?.checklist[0]?.title || ''} disabled isFull extendClass='my-2' /> */}

                                    {/* <AppTextArea rows={3} name='description' label='Description' value={defaultValue?.content_template?.checklist[0]?.description || ''} disabled isFull extendClass='my-2' /> */}
                                    {/* <Input
                                        register={() => register("title", { required: 'Title is required', maxLength: 140 })}
                                        name="title"
                                        placeholder={`${t('Content.CheckList_Title')}`}
                                        errors={errors}
                                        extraClasses="w-full"
                                        value={defaultValue?.content_template?.checklist[0]?.title || ''}
                                        disabled />
                                    {
                                        //to stop textarea causing too many re-renders
                                        tab === "Checklist Details" &&
                                        <TextArea
                                            disabled
                                            register={() => register("description", { maxLength: 140 })}
                                            name="description"
                                            placeholder={`${t('Content.CheckList_Description')}`}
                                            errors={errors}
                                            value={defaultValue?.content_template?.checklist[0]?.description || ''}
                                            extraClasses="w-full" />
                                    } */}

                                    {/* <div className="p-4 space-y-2 bg-gray-200">
                                        <p className="text-lg">CheckList Items</p>
                                        {
                                            defaultValue?.content_template?.checklist[0]?.items && defaultValue?.content_template?.checklist[0]?.items.length ?
                                                <div className="flex flex-col space-y-2">
                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot): JSX.Element => (
                                                                <div {...provided.droppableProps} ref={provided.innerRef}
                                                                    className="pt-4 space-y-2">
                                                                    {defaultValue?.content_template?.checklist[0]?.items && defaultValue?.content_template?.checklist[0]?.items.map((item: any, index: number) => (
                                                                        <Draggable key={index} draggableId={index.toString()}
                                                                            index={index}>
                                                                            {(provided, snapshot): JSX.Element => (
                                                                                <div
                                                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                    <div
                                                                                        className="flex items-center w-full px-2 space-x-2 border border-gray-500">
                                                                                        <NestedContentListItem label={item} />
                                                                                        <MdModeEditOutline
                                                                                            onClick={() => handleEdit(item, index)}
                                                                                            className="text-2xl cursor-pointer" />
                                                                                        <AiFillDelete
                                                                                            onClick={() => handleRemove(index)}
                                                                                            className="text-2xl cursor-pointer" />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div> : null
                                        }

                                        <div className="flex py-1 space-x-10">
                                            <TextField label="Item" disabled value={input} onChange={(e) => setInput(e.target.value)}
                                                type="text"
                                                className="w-full p-3 leading-tight text-gray-600 bg-white rounded-md appearance-none focus:outline-none" />
                                            <Button disabled onClick={submitItem} label={
                                                <p className="text-white">{edit.isEdit ? "Update Item" : "Create Item"}</p>
                                            } extraClasses="w-1/3 px-3 py-3" />

                                        </div>
                                    </div>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }

<AppThemeButton  type='button' onClick={() => { navigate(`/content-templates/checklist/view/${defaultValue?.content_template?.id}`)  }} size='md' variant="info">
                                        <div className='flex items-center justify-between gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
</svg>
<span>Edit Checklist Template</span>
                                        </div>
                                        </AppThemeButton> 
                                </div>
                            </Fade> */}

                            {/*---------------------------------------------------------*/}
                            {/*Content Details tab with form inputs*/}
                            {/* <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div> */}

                                    {/* ===== */}

                                    {/* <div>
                                        <label htmlFor='alt_title' className='mb-1 ml-1 text-sm'>Alternative Title</label>
                                        <input
                                            id="alt_title"
                                            type="text"
                                            className="block w-full p-4 text-gray-900 border border-gray-300 rounded-md text-md focus:ring-blue-500 focus:border-blue-500"
                                            value={altTitle}
                                            maxLength={30}
                                            onChange={e => {
                                                setAltTitle(prev => {
                                                    return e.target.value
                                                })
                                            }}
                                            placeholder='Alternative Title'
                                        />
                                    </div> */}

                                    {/* ======= */}
                                    {/* <ContentExtra typeOf={typeOf} query={query} setQuery={setQuery}
                                prevTitle={watch("preview_title")} rule={ruleId} setRule={setRuleId}
                                name={name} defaultValue={defaultValue} register={register} errors={errors}
                                render={render} setTimeEvent={setTimeEvent}
                                setPassedContent={setPassedContent}
                            /> */}

                                    {/* <div className="py-4 space-y-3">
                                        <div className="grid grid-cols-1 gap-3">

                                            <AppInput label="Content Card" name="content_card" value={defaultValue?.content_template?.content_metadata[0]?.preview_title} disabled isFull extendClass='my-2' />

                                            <AppInput
                                                label="Content Tag"
                                                name="content_tag"
                                                value={defaultValue?.content_template?.content_metadata?.[0]?.tags?.[0]}
                                                disabled
                                                isFull
                                                extendClass='my-2'
                                            />

{defaultValue?.content_template?.content_metadata[0]?.cover_image ? <div className="cover__image-container">
                                                <img className='rounded-sm' alt='cover image' title='cover image' src={defaultValue?.content_template?.content_metadata[0]?.cover_image} />
                                                </div> : null}
                                        </div>
                                    </div>
                                    <AppInput label="Content Priority" type='number' min={0} name="content_priority" onChange={e => setContentPriority(e.target.valueAsNumber)} value={contentPriority.toString()} isFull extendClass='my-2' /> */}

                                    {/* <Condition
                                        errors={errors}
                                        register={register}
                                        query={query}
                                        setQuery={setQuery}
                                        isContent
                                        rule={ruleId}
                                        setRule={setRuleId}
                                        setTimeEvent={setTimeEvent}
                                        name={name}
                                        typeOf={typeOf}
                                        setPassedContent={setPassedContent}
                                    /> */}

{/* <Button
                                disabled={handleUpload.isLoading || createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading}
                                type="submit" label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>{t('Content.Update_Content')}</p>
                                    </div>
                                } extraClasses="w-fit px-3 py-3" />
                                </div>
                            </Fade> */}

                            {/*---------------------------------------------------------*/}
                            {/*Submit form to create the Checklist*/}
                            

                        </> : null
                    }




                    {
                        type === 'update' && isContentTemplate === false ? <>

                            {/*---------------------------------------------------------*/}
                            {/*Checklist Details and Content Details tabs*/}
                            <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab TabKey='Checklist Details' count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView()
                                    e.stopPropagation()
                                }} />} title={`${t('Content.Checklist_Details')}`}>{`${t('Content.Checklist_Details')}`}  </Tab>
                                <Tab TabKey='Content Details' count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView()
                                    e.stopPropagation()
                                }} />} title={`${t('Content.Content_Details')}`}>{`${t('Content.Content_Details')}`}</Tab>
                            </Tabs>

                            {/*---------------------------------------------------------*/}
                            {/*Checklist Details tab with form inputs*/}
                            <Fade in={tab === "Checklist Details"}
                                style={{ display: tab === "Checklist Details" ? '' : 'none' }}>
                                <div className="space-y-4">
                                    <Input
                                        register={() => register("title", { required: 'Title is required', maxLength: 140 })}
                                        name="title"
                                        placeholder={`${t('Content.CheckList_Title')}`}
                                        errors={errors}
                                        extraClasses="w-full" />
                                    {
                                        //to stop textarea causing too many re-renders
                                        tab === "Checklist Details" &&
                                        <TextArea
                                            register={() => register("description", { maxLength: 140 })}
                                            name="description"
                                            placeholder={`${t('Content.CheckList_Description')}`}
                                            errors={errors}
                                            extraClasses="w-full" />
                                    }

                                    <div className="p-4 space-y-2 bg-gray-200">
                                        <p className="text-lg">CheckList Items</p>
                                        {
                                            items.length ?
                                                <div className="flex flex-col space-y-2">
                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot): JSX.Element => (
                                                                <div {...provided.droppableProps} ref={provided.innerRef}
                                                                    className="pt-4 space-y-2">
                                                                    {items.map((item, index) => (
                                                                        <Draggable key={index} draggableId={index.toString()}
                                                                            index={index}>
                                                                            {(provided, snapshot): JSX.Element => (
                                                                                <div
                                                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                    <div
                                                                                        className="flex items-center w-full px-2 space-x-2 border border-gray-500">
                                                                                        <NestedContentListItem label={item} />
                                                                                        <MdModeEditOutline
                                                                                            onClick={() => handleEdit(item, index)}
                                                                                            className="text-2xl cursor-pointer" />
                                                                                        <AiFillDelete
                                                                                            onClick={() => handleRemove(index)}
                                                                                            className="text-2xl cursor-pointer" />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div> : null
                                        }

                                        <div className="flex py-1 space-x-10">
                                            <TextField label="Item" value={input} onChange={(e) => setInput(e.target.value)}
                                                type="text"
                                                className="w-full p-3 leading-tight text-gray-600 bg-white rounded-md appearance-none focus:outline-none" />
                                            <Button onClick={submitItem} label={
                                                <p className="text-white">{edit.isEdit ? "Update Item" : "Create Item"}</p>
                                            } extraClasses="w-1/3 px-3 py-3" />

                                        </div>
                                    </div>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }
                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Content Details tab with form inputs*/}
                            <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div>
                                    <ContentExtra typeOf={typeOf} query={query} setQuery={setQuery}
                                        prevTitle={watch("preview_title")} rule={ruleId} setRule={setRuleId}
                                        name={name} defaultValue={defaultValue} register={register} errors={errors}
                                        render={render} setTimeEvent={setTimeEvent}
                                        setPassedContent={setPassedContent}
                                    />

                                    {/*                    {*/}
                                    {/*                        (ruleId.event === 0) ?*/}
                                    {/*                            <>*/}
                                    {/*                                <h4>Query</h4>*/}
                                    {/*                                <pre>*/}
                                    {/*  <code>{formatQuery(query, 'json')}</code>*/}
                                    {/*</pre>*/}
                                    {/*                            </> : null*/}
                                    {/*                    }*/}
                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Submit form to create the Checklist*/}
                            <Button
                                disabled={handleUpload.isLoading || createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading}
                                type="submit" label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>{type === 'update' ? `${t('Content.Update_Content')}` : `${t('Content.Create_Content')}`}</p>
                                    </div>
                                } extraClasses="w-fit px-3 py-3" />
                        </> : null
                    }


                </form>
            </div>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for Checklist Page*/}
            <OverlayModal onClose={handlePathwayView} isOpen={modal.pathwayView}>
                <div className="relative flex p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md h-fit w-fit">
                    <Mobile>
                        <PreviewContentModal items={getValues("items")} description={getValues("description")}
                            tags={[getValues("tags") || ""]}
                            type={'checklist'}
                            title={title}
                            image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""} />
                    </Mobile>
                </div>
            </OverlayModal>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for Checklist Content Card*/}
            <OverlayModal onClose={handleContentView} isOpen={modal.contentView}>
                <div className="h-fit overflow-auto bg-gray-100 p-2 rounded-md flex text-gray-600 w-[40%] relative">
                    <ContentTypeCardPreview
                        type={'checklist'}
                        image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""}
                        tags={[getValues("tags") || ""] || ['']}
                        title={getValues("preview_title") || defaultValue?.preview_title || ""}
                    />
                </div>
            </OverlayModal>
        </div>
    );
});

export default ChecklistNew;
