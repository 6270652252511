// import Cookies from "js-cookie"

const PATHWAYS_TOKEN = 'pathways-token'
const PATHWAYS_ORG_NAME = 'pathways-organization'

export const getToken = () => {
    return window.localStorage.getItem(PATHWAYS_TOKEN)
}

export const setToken = (token: string) => {
    window.localStorage.setItem(PATHWAYS_TOKEN, token)
}

export const removeToken = () => {
    window.localStorage.removeItem(PATHWAYS_TOKEN)
}


export const setCurrentOrganization = (name:string) => localStorage.setItem(PATHWAYS_ORG_NAME, name)
export const getCurrentOrganization = () => localStorage.getItem(PATHWAYS_ORG_NAME)
export const removeOrganization = () => window.localStorage.removeItem(PATHWAYS_ORG_NAME)


export const removeAllLocalTokensAndCookies = () => {
    window.localStorage.clear()
}


// Function to save favorites to local storage
export const saveFavoritesToLocalStorage = (favorites:any) => {
    localStorage.setItem("favorites", JSON.stringify(favorites));
};
  
  // Function to load favorites from local storage
export const loadFavoritesFromLocalStorage = () => {
    const favorites = localStorage.getItem("favorites");
    return favorites ? JSON.parse(favorites) : [];
};