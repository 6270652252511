import React, {FC} from 'react';
import "./_devices.css"

type prop = {
    children?: JSX.Element
}

const Mobile: FC<prop> = ({children}) => {
    return (

        <div className="device device-iphone-x">
            <div className="device-frame">
                <div className="h-full w-full rounded-3xl overflow-hidden">
                    {children}
                </div>
            </div>
            <div className="device-stripe"></div>
            <div className="device-header"></div>
            <div className="device-sensors"></div>
            <div className="device-btns"></div>
            <div className="device-power"></div>
        </div>
    );
};

export default Mobile;