import { memo, useContext, useEffect, useMemo, useState } from "react";
import { AppButton, AppDivider, AppSearchableDropDown, CloseIcon, TransitionAnimation } from "../../../Theme";
import AppInput from "../../Shared/AppInput/AppInput";
import AppTextArea from "../../Shared/AppTextArea/AppTextArea";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import AppDropDown from "../../Shared/AppDropDown/AppDropDown";
import { createRule, getRuleOprands } from "../../../apiQuery/Condition/Rule.apis";
import { QueryBuilderContainer, deliverTimeAndUnitCalculator, formatterQuery, backendReplaceAllObjectKeys, ruleNameFormatter } from "../../Container";
import { IOperandsResponse } from "../../../Types/Operands/Operands";
import initialQuery from "../../../utils/InitialRules";
import { IRuleResponse } from "../../../Types/Rules/Rules";
import { Field, RuleGroupType } from "react-querybuilder";
import { State, State_R_Single } from "../../../Common/Types";
import { toast } from "../../../utils/Toast";
import { createDeliverBasedOnTimeWithRule, getAllStateRules } from "../../../apiQuery/Condition/State.Rules.api";
import { getState, updateState } from "../../../apiQuery/State/State.apis";
import { useParams } from "react-router-dom";
import AddTimeSince from "../../Conditions/AddTimeSince";
import { IStateTimeCondition } from "../../../Types/StateTimeCondition";
import { store } from "../../../GlobalState/store";
import { createTimeSince, deleteTimeSince, updateTimeSince } from "../../../apiQuery/Condition/TimeSince.apis";
import { ITimeSince } from "../../../Types/TimeSince";
import { UserContext } from "../../../Context/UserContext/UserContext";
import { renameRuleName } from "../../../utils/RuleHelper"

type StateConditionModalProps = {
  modalHandle: () => void;
  ContentOrAction: number | string
};


const StateIcon = (props: { isActive: boolean }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 96 960 960"
    className={`h-4 w-4 ${props.isActive ? 'text-white' : 'text-grayColor'}`}>
    <path
      fill="currentColor"
      d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
    />
  </svg>
);

interface Options {
  value: string
  label: string
}

type TimeDeliverType = 'minute' | 'hour' | 'days'

const initialTimeSince = {
  target_state: 0,
  max_time_state: 0,
  time_unit: "minute",
  display_unit: "minute"
};

const type = 'State'

const StateConditionalModal = memo(({ modalHandle, ContentOrAction }: StateConditionModalProps) => {
  const { t } = useTranslation();

  const { state: globalState, dispatch } = useContext(store);
  const { isAdmin } = useContext(UserContext);

  const params = useParams();

  const stateSlug = params.stateSlug || "";
  const pathwaySlug = params.slug || "";


  const [activeCondition, setActiveCondition] = useState<'Entry' | 'Exit'>('Entry');


  //  ===================== EVENT Condtions ==============================//
  const [activeEntryCondition, setActiveEntryCondition] = useState<'New Rule' | 'Exists Rule' | 'Remove'>('Exists Rule')
  const [activeEventConditionExitState, setActiveConditionExitState] = useState<'Time' | 'With Rule' | 'Remove' | 'Exists Rule'>('Time')



  const [selectedRuleEntryState, setSelectedRuleEntryState] = useState<string>('')

  const stateRules = useQuery<IRuleResponse, Error>(
    ["all-state-rules", 1],
    () => getAllStateRules(),
  );


  const stateRuleDropDownSearch: Options[] | null = useMemo(() => {
    if (stateRules?.data?.data) {
      const list: Options[] = stateRules.data.data.map(item => {
        return {
          label: item.rule_name.replaceAll('_', ' '),
          value: item.id.toString()
        }
      })
      return list
    }
    return null
  }, [stateRules])




  const { data: singleState, refetch } = useQuery<State_R_Single, Error>(["state", stateSlug], () => getState(pathwaySlug, stateSlug), {
    enabled: pathwaySlug && stateSlug ? true : false
  });



  useEffect(() => {
    if (singleState && singleState?.data?.state.rules_state_id !== null) {
      setSelectedRuleEntryState(singleState?.data?.state.rules_state_id.toString())
    }
  }, [singleState])

  const [json, setJson] = useState<any>({
    deliver_time: 0,
    deliver_unit: "minute",
    rules: []
  })


  useEffect(() => {
    if (selectedRuleEntryState) {

      let contentOrActionRule: any
      contentOrActionRule = stateRules.data?.data.find(item => item.id === parseInt(selectedRuleEntryState))
      const datas = [] as any[]

      if (contentOrActionRule) {

        // @ts-ignore
        const whenClause = contentOrActionRule?.rule_json[0].when
        // ============

        const com = Object.keys(whenClause)[0]

        if (whenClause?.[com][0]?.obj) {
          datas.push({
            operator: undefined,
            field: whenClause?.[com][0]?.obj,
            value: whenClause?.[com][1]?.const
          })
        } else {
          whenClause?.[com]?.map((item: any) => {

            const ss = Object.keys(item)[0]

            datas.push({
              operator: ss,
              field: item[ss][0]?.obj,
              value: item[ss][1]?.const ? item[ss][1]?.const : item[ss][1]
            })
          })
        }

        const obj = {
          combinator: Object.keys(whenClause)[0],
          rules: datas
        }

        setJson(() => {
          return {
            deliver_time: contentOrActionRule.deliver_time || 0,
            deliver_unit: contentOrActionRule.deliver_unit || 'minute',
            rules: obj.rules,
            combinator: obj.combinator
          }
        })
      }

    }
  }, [stateRules.data?.data, selectedRuleEntryState])


  const createAStateRule = useMutation((ruleBody: any) => createRule(ruleBody), {
    onSuccess: async () => {
      toast('State Rule has been created.', 'success')
      await refetch()
    },
    onError: (error: any) => {
      toast(error.response.data.message, 'error')
    }
  })

  const createDeliverBasedOnTimeWithRuleMutation = ((data: any) => createDeliverBasedOnTimeWithRule(data))

  // ==================================================================



  const [query, setQuery] = useState<RuleGroupType>(initialQuery);

  const [queryExitWithRule, setQueryExitWithRule] = useState<RuleGroupType>(initialQuery);


  const {
    isLoading,
    data: operands,
    error,
  } = useQuery<IOperandsResponse, Error>("operands", getRuleOprands);



  //  @ts-ignore
  const fields: Field[] = useMemo(() => {
    return operands?.data?.map((op) => {
      return {
        name: op.value,
        label: op.display,
        valueEditorType: op.data_type === "bool" ? "checkbox" : "text",
        inputType: op.display.includes("Date") ? "date" : "text",
        operators: [
          { name: "=", label: "=" },
          { name: "!=", label: "!=" },
        ],
      };
    });
  }, [operands?.data]);


  // =======================================  Deliver based on the Event =================================

  // Deliver based on the Event : Time Delay, Time Offest.
  const [deliverBasedOnEventActiveCondition, setDeliverBasedOnEventActiveCondition] = useState<'Add Time Delay' | 'Add Time Offset'>('Add Time Delay')


  const [deliver, setDeliver] = useState<{ deliver_time: number, deliver_unit: TimeDeliverType, type: 'Add Time Delay' | 'Add Time Offset' }>({
    deliver_time: 0,
    deliver_unit: "minute",
    type: 'Add Time Delay'
  });


  const [condtionEntryState, setConditionEntryState] = useState({
    name: '',
    description: ''
  })


  const [condtionExitState, setConditionExitState] = useState({
    name: '',
    description: ''
  })



  // ====================================
  const [timeSince, setTimeSince] = useState(initialTimeSince);

  const selectedTargetStateHandler = (targetState: number) => {

    setTimeSince((prev) => {
      return {
        ...prev,
        target_state: targetState,
      };
    });
  };


  const selectedTimeUntilTransitionHandler = (maxTimeState: number) => {
    setTimeSince((prev) => {
      return {
        ...prev,
        max_time_state: maxTimeState,
      };
    });
  };


  const selectedTimeUnitHandler = (timeUnit: string) => {
    setTimeSince((prev) => {
      return {
        ...prev,
        time_unit: timeUnit,
      };
    });
  };

  const selectedDisplayUnitHandler = (displayUnit: string) => {
    setTimeSince((prev) => {
      return {
        ...prev,
        display_unit: displayUnit
      };
    });
  };

  const stateId = useMemo(() => {
    return singleState?.data.state.id;
  }, [singleState?.data.state.id])


  const savedStateTimeCondition: null | IStateTimeCondition = useMemo(() => {
    return singleState?.data.state.state_time_conditions &&
      singleState?.data.state.state_time_conditions?.[0]?.display_unit === 'day'
      ? {
        ...singleState.data.state.state_time_conditions?.[0],
        max_time_state:
          singleState.data.state.state_time_conditions?.[0].max_time_state / 24,
        display_unit: 'day',
        time_unit: 'hour'
      }
      : singleState?.data.state.state_time_conditions?.[0]
  }, [singleState?.data.state.state_time_conditions])

  // if (singleState?.data.state.state_time_conditions?.[0]?.time_unit === 'day') {
  //   savedStateTimeCondition = {...singleState.data.state.state_time_conditions[0], max_time_state: ((singleState.data.state.state_time_conditions[0].max_time_state) / 24)}
  // }


  useEffect(() => {
    if (savedStateTimeCondition && savedStateTimeCondition?.rule_json === null) {
      setActiveConditionExitState('Time')
      setTimeSince((prev) => {
        return {
          ...prev,
          target_state: savedStateTimeCondition.target_state,
          max_time_state: savedStateTimeCondition.max_time_state,
          time_unit: savedStateTimeCondition.time_unit,
          display_unit: savedStateTimeCondition.display_unit
        };
      });
    }
  }, [savedStateTimeCondition])



  const deleteATimeSinceMutation = useMutation(
    () => deleteTimeSince(savedStateTimeCondition?.id as number)
    ,
    {
      onSuccess: async () => {
        await refetch()
      },
      onError: (error: any) => {
        const message =
          typeof error.response !== "undefined"
            ? error?.response?.data?.message
            : error?.message || "Cannot delete the time since";
        toast(message, "error");
      },
    }
  );


  const createTimeSinceForCurrentState = useMutation(
    (timeSince: ITimeSince) => createTimeSince({ ...timeSince }),
    {
      onSuccess: async () => {
        toast("Time Since has been added to this State", "success");
      },
      // state_id: Number(id)
      onError: (error: any) => {
        const message =
          typeof error.response !== "undefined"
            ? error?.response?.data?.message
            : error?.message || "Cannot update the state";
        toast(message, "error");
      },
    }
  );

  const updateTimeSinceForCurrentState = useMutation(
    (data: { timeSince: ITimeSince, id: number }) => updateTimeSince(data.timeSince, data.id),
    {
      onSuccess: async () => {
        toast("Time Since has been added to this State", "success");
      },
      onError: (error: any, variables: { timeSince: ITimeSince, id: number }) => {
        const message =
          typeof error.response !== "undefined"
            ? error?.response?.data?.message
            : error?.message || "Cannot update the state";
        toast(message, "error");
      },
    }
  );





  /// =========================== SAVE State

  // Entry ============================================================
  const saveSetStateEntryConditionSwitchRules = async () => {

    let state = singleState?.data.state
    if (state) {
      state.rules_state_id = parseInt(selectedRuleEntryState)
    }
    await updateState(pathwaySlug, stateSlug, state)

    const toastMessage = `Set State Entry has been updated.`
    toast(toastMessage, 'success', 3500)
    modalHandle()
  }


  const saveSetStateEntryConditionNewRule = async () => {

    const state = singleState?.data?.state as State
    if (query.rules.length === 0) {
      toast('There are not any rule created!', 'warning', 3000)
      return
    }

    const conditionName = condtionEntryState.name
    const conditionDescription = condtionEntryState.description

    const formattedQuery = await formatterQuery(query)
    const preparedForBackend = await backendReplaceAllObjectKeys(formattedQuery)

    const createdStateRuleMutation = createAStateRule.mutateAsync({
      rule_json: {
        when: preparedForBackend,
        desc: conditionDescription,
        name: renameRuleName(conditionName),
        salience: 0
      }
    })
    const response = await createdStateRuleMutation
    const ruleId = await response?.data?.id

    state.rules_state_id = Number(ruleId)
    await updateState(pathwaySlug, stateSlug, state)

    const toastMessage = `Set State Entry with new rule has been updated.`
    toast(toastMessage, 'success', 3500)
    modalHandle()
  }

  const saveSetStateEntryConditionRemoveCondition = async () => {
    const state = singleState?.data?.state as State
    if (!state)
      return

    // @ts-ignore
    state.rules_state_id = null
    await updateState(pathwaySlug, stateSlug, state)
    const toastMessage = `Set State Entry has been updated.`
    toast(toastMessage, 'success', 3200)
    modalHandle()
  }

  // EXIT ===============================================================
  const saveSetStateExitConditionTime = async () => {


    const updated_max =
      timeSince.display_unit === "day"
        ? timeSince.max_time_state * 24
        : timeSince.max_time_state;
    const updated_time_unit =
      timeSince.display_unit === "day" ? "hour" : timeSince.time_unit;

    const updated_display_unit = timeSince.display_unit


    let nothingChanged: boolean = false


    // validate if the new values have been changed or not?
    const exitsTimeCondition = singleState?.data?.state.state_time_conditions
    if (exitsTimeCondition) {
      const currentCondition = exitsTimeCondition[0]

      if (currentCondition.max_time_state === updated_max && timeSince.target_state === currentCondition.target_state && currentCondition.time_unit === updated_time_unit && currentCondition.display_unit === updated_display_unit) {
        nothingChanged = true
      }
    }


    if (nothingChanged) {
      return
    }

    // 
    if (timeSince.max_time_state <= 0) {
      if (timeSince.target_state)
        toast(
          'Max time Transition must be more than equal 0 or more than',
          'error'
        )
      return;
    }

    if (updated_max >= 0 && (timeSince.target_state === -1 || timeSince.target_state === 0)) {
      toast("Please select target state", "error");
      return;
    }

    // await deleteATimeSinceMutation.mutateAsync();
    // await refetch();



    const timeSinceObj: ITimeSince = {
      state_id: stateId as number,
      target_state: timeSince.target_state,
      max_time_state: updated_max,
      time_unit: updated_time_unit,
      display_unit: timeSince.display_unit
    };



    const isTimeConditionExists = singleState?.data.state.state_time_conditions

    let res = null

    // different mutations for PUT and POST
    if (isTimeConditionExists) {
      res = await updateTimeSinceForCurrentState.mutateAsync({ timeSince: timeSinceObj, id: singleState?.data.state.state_time_conditions[0]?.id });
    } else {
      res = await createTimeSinceForCurrentState.mutateAsync(timeSinceObj)
    }

    const toastMessage = `Set State Exit with delivered based on Time has been updated.`

    if (res?.status === 200) {
      toast(toastMessage, 'success', 3500)
      modalHandle()
    }

  }


  const saveSetStateExitConditionWithRule = async () => {


    if (timeSince.max_time_state <= 0) {
      toast(
        "Max time Transition must be more than equal 0 or more than",
        "error"
      );
      return;
    }

    if (timeSince.target_state === -1 || timeSince.target_state === 0) {
      toast("Please select target state", "error");
      return;
    }

    if (queryExitWithRule.rules.length === 0) {
      toast('There are not any rule created!', 'info')
      return
    }

    const conditionName = condtionExitState.name
    const conditionDescription = condtionExitState.description

    const formattedQuery = await formatterQuery(queryExitWithRule)
    const preparedForBackend = await backendReplaceAllObjectKeys(formattedQuery)

    const updated_max = timeSince.display_unit === "day" ? timeSince.max_time_state * 24 : timeSince.max_time_state;

    const updated_time_unit = timeSince.time_unit === "day" ? "hour                                          " : timeSince.time_unit;
    const updated_display_unit = timeSince.display_unit === "day" ? "minute" : timeSince.time_unit;


    const timeSinceObj: ITimeSince = {
      state_id: stateId as number,
      target_state: timeSince.target_state,
      max_time_state: updated_max,
      time_unit: updated_time_unit,
    };

    const request = createDeliverBasedOnTimeWithRuleMutation({
      "state_id": stateId,
      "target_state": timeSinceObj.target_state,
      "max_time_state": timeSinceObj.max_time_state,
      "time_unit": timeSinceObj.time_unit,
      "rule_json": {
        "desc": conditionDescription,
        "name": conditionName,
        "when": preparedForBackend,
        "salience": 0
      }
    })

  }



  const saveSetStateExitConditionRemoveCondition = async () => {
    await deleteATimeSinceMutation.mutateAsync()

    const state = singleState?.data?.state as State
    if (!state)
      return

    const toastMessage = `Set State Exit has been removed.`
    toast(toastMessage, 'success', 3200)
    modalHandle()
  }




  const save = async () => {


    if (!isAdmin()) {
      toast("You are not authorized to perform this action", "error");
      return;
    }

    const state = singleState?.data.state

    if (!state) {
      return
    }


    // Entry
    if (activeEntryCondition === "Exists Rule" && state.rules_state_id !== Number(selectedRuleEntryState)) {
      await saveSetStateEntryConditionSwitchRules()
    }

    if (activeEntryCondition === "New Rule") {
      await saveSetStateEntryConditionNewRule()
    }

    if (activeEntryCondition === "Remove") {
      await saveSetStateEntryConditionRemoveCondition()
    }

    // EXIT 


    if (activeEventConditionExitState === "Time") {
      await saveSetStateExitConditionTime()
    }

    if (activeEventConditionExitState === "Remove") {
      await saveSetStateExitConditionRemoveCondition();
    }

    // if(activeEventConditionExitState === "With Rule") {
    //   await saveSetStateExitConditionWithRule()
    // }

    await refetch()

  }

  // ==========================================================================================

  return (
    <div className="relative z-50 flex min-w-[70%] max-w-[90%] h-full mt-5 overflow-auto bg-white rounded-md shadow-md text-textDark edit-condition-modal">
      <div className="flex flex-col w-full p-5">
        <div className="flex justify-between">
          <div className="flex flex-col gap-5 mb-2">
            <div className="mb-3">
              <h2 className="text-2xl font-normal text-textDark">Add Conditions to Enter and Exit this state.</h2>
              {/* <p className="text-sm text-textGray">Manage how and when the {type} should be delivered to a Worker.</p> */}
            </div>
          </div>
          <button
            type="button"
            onClick={modalHandle}
            className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <CloseIcon className="w-4 h-4" />
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        <AppDivider bottom={30} top={10} />

        <div className={`flex gap-2 mb-1`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={`text-grayColor h-6 w-6`}
            viewBox="0 0 8.582 10"
          >
            <path
              fill="currentColor"
              d="M8.861 2.715V2h2.861v.715zm1.072 5.328h.715V5.3h-.715zM10.291 12a4.122 4.122 0 01-1.663-.34A4.354 4.354 0 016.34 9.372a4.239 4.239 0 010-3.325 4.354 4.354 0 012.288-2.289 4.122 4.122 0 011.663-.34 4.215 4.215 0 011.5.262 4.058 4.058 0 011.246.739l.608-.608.513.513-.608.608a4.4 4.4 0 01.733 1.156 3.948 3.948 0 01.3 1.621 4.122 4.122 0 01-.34 1.663 4.354 4.354 0 01-2.288 2.288 4.122 4.122 0 01-1.664.34z"
              transform="translate(-6 -2)"
            />
          </svg>

          <div className="flex flex-col">
            <h3 className="mb-1 text-darkColor">Set State Entry Condition</h3>
            {/* <p className="text-sm text-textGray">This option will set the {type} to be delivered to the Worker when the time interval set has passed after the Worker enters the state.</p> */}
          </div>
        </div>

        <div className={` my-2`}>

          <div className="flex items-center gap-2 p-4">
            <AppButton disabled={activeCondition !== 'Entry'} variant={activeEntryCondition === "Exists Rule" ? 'info' : 'primary'} type="button" size="xs"
              onClick={() => setActiveEntryCondition('Exists Rule')}>
              <div className="flex items-center gap-2"><StateIcon isActive={activeEntryCondition === 'Exists Rule'} />Add Existing Rule</div>
            </AppButton>
            <AppButton disabled={activeCondition !== 'Entry'} variant={activeEntryCondition === "New Rule" ? 'info' : 'primary'} type="button" size="xs"
              onClick={() => setActiveEntryCondition('New Rule')}>
              <div className="flex items-center gap-2"><StateIcon isActive={activeEntryCondition === 'New Rule'} />Create New Rule</div>
            </AppButton>

            <AppButton disabled={activeCondition !== 'Entry'} variant={activeEntryCondition === "Remove" ? 'danger' : 'primary'} type="button" size="xs"
              onClick={() => {
                setActiveEntryCondition('Remove')
              }}>
              <div className="flex items-center gap-2"><StateIcon isActive={activeEntryCondition === 'Remove'} />Remove Condition</div>
            </AppButton>
          </div>
          {/* ============================== */}


          {
            activeEntryCondition === 'New Rule' ? (<>


              <div className={`p-4`}>
                <p className="mb-2 text-sm text-textGray">Condition details</p>
                <div className="flex flex-col gap-1">
                  <div className="flex items-center ">
                    <span className="w-60">Condition Name</span>
                    <AppInput disabled={activeCondition !== 'Entry'} isFull type="text" placeholder={""} value={condtionEntryState.name} name='name'
                      onChange={e => {
                        setConditionEntryState(prev => {
                          return {
                            ...prev,
                            name: e.target.value
                          }
                        })
                      }} label='' extendClass='my-2' />
                  </div>

                  <div className="flex items-center ">
                    <span className="w-60">Condition Description</span>
                    <AppTextArea disabled={activeCondition !== 'Entry'} placeholder={""} rows={4} value={condtionEntryState.description} name='description'
                      onChange={
                        (e) => {
                          setConditionEntryState(prev => {
                            return {
                              ...prev,
                              description: e.target.value
                            }
                          })
                        }
                      } isFull />
                  </div>
                </div>
              </div>
              <hr className="w-[98%] self-center border border-gray-300 border-dashed " />

              <div className="p-4">
                <p className="mb-2 text-sm text-textGray">Condition Criteria</p>
              </div>

              <div className={`mb-2 ml-24 w-[89%]`}>
                <QueryBuilderContainer
                  fields={fields}
                  query={query}
                  setQuery={(q) => setQuery(q)}
                />
              </div>


              <hr className="w-[98%] self-center border border-gray-300 border-dashed " />

              <div className={`p-4 hidden`}>

                <p className="mb-2 text-sm text-textGray">Condition Time Delay, Time Offest.</p>
                <div className="flex items-center gap-2">
                  <AppButton variant={deliverBasedOnEventActiveCondition === "Add Time Delay" ? 'info' : 'primary'} type="button" size="xs"
                    onClick={() => {
                      setDeliverBasedOnEventActiveCondition('Add Time Delay')
                      setDeliver(prev => {
                        return {
                          deliver_time: 0,
                          deliver_unit: 'minute',
                          type: 'Add Time Delay'
                        }
                      })
                    }}>
                    <div className="flex items-center gap-2"><StateIcon isActive={deliverBasedOnEventActiveCondition === 'Add Time Delay'} />Add Time Delay</div>
                  </AppButton>
                  <AppButton variant={deliverBasedOnEventActiveCondition === "Add Time Offset" ? 'info' : 'primary'} type="button" size="xs"
                    onClick={() => {
                      setDeliverBasedOnEventActiveCondition('Add Time Offset')
                      setDeliver(prev => {
                        return {
                          deliver_time: 0,
                          deliver_unit: 'minute',
                          type: 'Add Time Offset'
                        }
                      })
                    }}>
                    <div className="flex items-center gap-2"><StateIcon isActive={deliverBasedOnEventActiveCondition === 'Add Time Offset'} />Add Time Offest</div>
                  </AppButton>
                </div>
                <div className="flex flex-col p-2 my-2 bg-white border rounded-md">
                  <div className="flex items-center gap-4 ">
                    <p className="text-sm text-textDark">

                      {
                        deliverBasedOnEventActiveCondition === 'Add Time Delay' ? `Deliver this ${type}` : 'Deliver this Content only if a Worker has met the criteria for this condition'
                      }
                    </p>

                    <AppInput type="number"
                      min={0} placeholder={""} value={deliver.deliver_time.toString()} name='time' customWidth="w-[10rem]"
                      onChange={e => {
                        setDeliver(prev => {
                          return {
                            ...prev,
                            deliver_time: Number(e.target.value)
                          }
                        })
                      }} label='' extendClass='my-2 w-32' />

                    <AppDropDown
                      extendClass="w-32"
                      label=""
                      name="time"
                      options={[
                        { label: "Minutes", value: "minute" },
                        { label: "Hours", value: "hour" },
                        { label: "Days", value: "days" },
                      ]}
                      value={deliver.deliver_unit}
                      onChange={(e) => {
                        setDeliver((prev) => {
                          return {
                            ...prev,
                            deliver_unit: e.target.value as TimeDeliverType,
                          };
                        });
                      }}
                    />
                    <p className="text-sm text-textDark">

                      {
                        deliverBasedOnEventActiveCondition === 'Add Time Delay' ? 'from the time the Worker has met the criteria for this condition.' :
                          'after entering this state.'
                      }
                    </p>
                  </div>
                  {
                    deliverBasedOnEventActiveCondition === 'Add Time Offset' ? (
                      <p className="text-xs text-textDark">When a time offset is used, the system will only check if the Worker meets the criteria for the condition once at the time interval set from when the Worker entered this State.</p>
                    ) : null
                  }
                </div>

              </div>

            </>) : (
              <div className={`${activeEntryCondition === 'Remove' ? 'opacity-60 cursor-not-allowed' : ''}`}>
                <div className="p-4">
                  {
                    stateRuleDropDownSearch ?
                      <AppSearchableDropDown
                        disabled={activeEntryCondition === 'Remove' ? true : false}
                        setSelectedItem={setSelectedRuleEntryState}
                        label={`Search State Rules`}
                        inputSearchPlaceholder={`Search Between State Rules`}
                        isFull
                        defaultValue={selectedRuleEntryState}
                        options={
                          stateRuleDropDownSearch
                        }
                      /> : null
                  }


                  <TransitionAnimation show={true} position="y-axis">
                    {json && json.rules ? (
                      <div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">
                        {/* has a time offset of */}A Worker enters this state
                        when{' '}
                        {deliverTimeAndUnitCalculator(
                          json?.deliver_time,
                          json?.deliver_unit
                        )}
                        <br />
                        {json.rules.map((item: any, index: number) => (
                          <div className="inline" key={index}>
                            {/* {' '} <strong>{ruleNameFormatter(item.field)}</strong> {' '} */}{' '}
                            <strong>{ruleNameFormatter(item.field)}</strong>{' '}
                            {item.operator === 'eq'
                              ? '='
                              : item.operator === 'not'
                                ? '!='
                                : json.combinator === 'eq'
                                  ? '='
                                  : json.combinator === 'not'
                                    ? '!='
                                    : null}
                            &nbsp;{String(item.value)}&nbsp;&nbsp;
                            {json.rules.length - 1 !== index
                              ? json?.combinator?.toUpperCase()
                              : null}
                            &nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">
                        <p className="font-bold">No State Entry condition has been set</p>
                      </div>
                    )}
                  </TransitionAnimation>
                </div>
              </div>)
          }


        </div>


        {/* ============== */}


        <AppDivider bottom={30} />

        <div className="flex gap-2 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={`text-grayColor h-6 w-6`}
            viewBox="0 0 8.582 10"
          >
            <path
              fill="currentColor"
              d="M8.861 2.715V2h2.861v.715zm1.072 5.328h.715V5.3h-.715zM10.291 12a4.122 4.122 0 01-1.663-.34A4.354 4.354 0 016.34 9.372a4.239 4.239 0 010-3.325 4.354 4.354 0 012.288-2.289 4.122 4.122 0 011.663-.34 4.215 4.215 0 011.5.262 4.058 4.058 0 011.246.739l.608-.608.513.513-.608.608a4.4 4.4 0 01.733 1.156 3.948 3.948 0 01.3 1.621 4.122 4.122 0 01-.34 1.663 4.354 4.354 0 01-2.288 2.288 4.122 4.122 0 01-1.664.34z"
              transform="translate(-6 -2)"
            />
          </svg>

          <div className={`flex flex-col`}>
            <h3 className="mb-1 text-darkColor">Set State Exit Condition</h3>
            {/* <p className="text-sm text-textGray">
              This option will set the {type} to be delivered to the Worker when the Worker has met the criteria for the Event condition. Time delays and time checks can also be added to Events.
            </p> */}
          </div>
        </div>


        <div className="flex items-center gap-2 p-4">
          <AppButton variant={activeEventConditionExitState === "Time" ? 'info' : 'primary'} type="button" size="xs"
            onClick={() => setActiveConditionExitState('Time')}>
            <div className="flex items-center gap-2"><StateIcon isActive={activeEventConditionExitState === 'Time'} />Deliver based on Time</div>
          </AppButton>

          <AppButton variant={activeEventConditionExitState === "Remove" ? 'danger' : 'primary'} type="button" size="xs"
            onClick={() => {
              setActiveConditionExitState('Remove')
            }}>
            <div className="flex items-center gap-2"><StateIcon isActive={activeEventConditionExitState === 'Remove'} />Remove Condition</div>
          </AppButton>


          {/* <AppButton variant={activeEventConditionExitState === "With Rule" ? 'info' : 'primary'} type="button" size="xs"
            onClick={() => setActiveConditionExitState('With Rule')}>
            <div className="flex items-center gap-2"><StateIcon isActive={activeEventConditionExitState === 'With Rule'} />Deliver based on Time with Rule</div>
          </AppButton> */}
        </div>
        {/* ============================== */}


        {
          activeEventConditionExitState === 'With Rule' ? (<div className="hidden">


            <div className={`p-4`}>
              <p className="mb-2 text-sm text-textGray">Condition details</p>
              <div className="flex flex-col gap-1">
                <div className="flex items-center ">
                  <span className="w-60">Condition Name</span>
                  <AppInput isFull type="text" placeholder={""} value={condtionExitState.name} name='name'
                    onChange={e => {
                      setConditionExitState(prev => {
                        return {
                          ...prev,
                          name: e.target.value
                        }
                      })
                    }} label='' extendClass='my-2' />
                </div>

                <div className="flex items-center ">
                  <span className="w-60">Condition Description</span>
                  <AppTextArea placeholder={""} rows={4} value={condtionExitState.description} name='description'
                    onChange={
                      (e) => {
                        setConditionExitState(prev => {
                          return {
                            ...prev,
                            description: e.target.value
                          }
                        })
                      }
                    } isFull />
                </div>
              </div>
            </div>
            <div className="w-[98%] self-center h-8 border border-gray-300 border-dashed " />

            <div className="p-4">
              <p className="mb-2 text-sm text-textGray">Condition Criteria</p>
            </div>


            <div className={`mb-2 ml-24 w-[89%]`}>
              <QueryBuilderContainer
                fields={fields}
                query={queryExitWithRule}
                setQuery={(q) => setQueryExitWithRule(q)}
              />
            </div>

            <div className="p-4">
              <AddTimeSince
                savedStateTimeCondition={savedStateTimeCondition}
                StatesList={
                  globalState.currentFlow.states?.filter(
                    (state) => state.slug !== stateSlug
                  ) as State[]
                }
                onSelectedTargetState={selectedTargetStateHandler}
                onSelectedTimeUntilTransition={
                  selectedTimeUntilTransitionHandler
                }
                onSelectedTimeUnit={selectedTimeUnitHandler}
                onSelectedDisplayUnit={selectedDisplayUnitHandler}
              />
            </div>

          </div>) : null
        }


        <div className={`p-4 ${activeEventConditionExitState === 'Remove' ? 'opacity-60 cursor-not-allowed' : ''}`}>
          <AddTimeSince
            isDisabled={activeEventConditionExitState === 'Remove'}
            savedStateTimeCondition={savedStateTimeCondition}
            StatesList={
              globalState.currentFlow.states?.filter(
                (state) => state.slug !== stateSlug
              ) as State[]
            }
            onSelectedTargetState={selectedTargetStateHandler}
            onSelectedTimeUntilTransition={
              selectedTimeUntilTransitionHandler
            }
            onSelectedTimeUnit={selectedTimeUnitHandler}
            onSelectedDisplayUnit={selectedDisplayUnitHandler}
          />
        </div>
        <TransitionAnimation show={true} position="y-axis">
          {singleState?.data?.state?.state_time_conditions?.[0] ? (
            <div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">
              {(() => {
                const time_conditions =
                  singleState?.data?.state?.state_time_conditions?.[0]
                const time = `${time_conditions.display_unit === 'day' ? time_conditions?.max_time_state / 24 : time_conditions?.max_time_state} ${time_conditions.display_unit
                  }${time_conditions.time_unit === 'day' ? time_conditions.max_time_state / 24 > 1 ? 's' : '' : time_conditions.max_time_state > 1 ? 's' : ''}`
                const id = singleState?.data?.state?.state_time_conditions?.[0].target_state
                const findState = globalState.currentFlow.states?.find(item => item.id === id)
                let name = ''
                if (findState)
                  name = findState.name
                return (
                  <p>
                    If a Worker is still in this State after{' '}
                    <span className="font-bold">{time}</span>,
                    transition them to{' '}
                    <span className="font-bold">"{name}"</span>
                  </p>
                )
              })()}

              <br />
            </div>
          ) : (
            <div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">
              <p className="font-bold">No State Exit condition has been set</p>
            </div>
          )}
        </TransitionAnimation>

        {/* ============================== */}


        <AppDivider />

        <div className="py-4 ">
          <AppButton
            variant="primary"
            type="submit"
            extendClass="px-3"
            size="lg"
            onClick={save}
          >
            <div className="flex items-center justify-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
              >
                <path
                  fill="currentColor"
                  d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"
                ></path>
              </svg>
              Save
            </div>
          </AppButton>
        </div>
      </div>
    </div>
  );
});

StateConditionalModal.displayName = "StateConditionalModal"
export default StateConditionalModal;