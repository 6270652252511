import API from "../index";
import { TFlow } from "../../Common/Types";

export const getFlowState = async (org: number, pageParam: string) => {
    const { data } = await API.get(`/admin/organizations/pathways/?limit=${20}${pageParam && `&next=${pageParam}`}`);
    return data;
};

export const createFlowState = async (org: number, datas: TFlow) => {
    const { data } = await API.post(`/admin/organizations/pathways/`, { ...datas });
    // const {data} = await API.post("/state-flows", {...datas});
    return data;
};

export const updateFlowState = async (org: number, datas: TFlow, id: string) => {
    const { data } = await API.put(`/admin/organizations/pathways/${id}`, { ...datas });
    // const {data} = await API.put(`/state-flows/${id}`, {...datas});
    
    return data;
};

export const deleteFlowState = async (pathway_slug: string) => {
    const { data } = await API.delete(`/admin/organizations/pathways/${pathway_slug}`);
    return data; 
};



