import { FC, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { AppButton, LanguageIcon, SaveIcon } from "../../../Theme";
import { getLangaugeLabel } from "../../../utils/Languages";
import AppInput from "../../../components/Shared/AppInput/AppInput";
import AppTextArea from "../../../components/Shared/AppTextArea/AppTextArea";
import { LanguageEnum } from "../../../Types/ContentTemplate/Langauge";
import { stripHtml } from "string-strip-html";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "../Layout";
import { sortTemplate } from "../template.utils";
import { ISurveySequence, ISurveySequenceContentTemplate, ISurveySequenceContentTemplateForm, initialSurveySequence } from "../../../Types/ContentTemplate/SurveySequence";
import { ISurveyForServer } from "../../../Types/ContentTemplate/Survey";
import { SurveySequenceJSONB } from "../../../Types/JsonB/jsonb.types";
import { toast } from "../../../utils/Toast";


interface Props {
  contentTemplate: ISurveySequenceContentTemplate
  priority: number,
  alternativeTitle: string,
  onUpdateFields: (priority: number, alternativeTitle: string, customJSONB?: SurveySequenceJSONB[] | null) => void,
  customJSONB?: SurveySequenceJSONB[] | null
}

export const SurveySequence: FC<Props> = (props) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, updateFormData] = useReducer((prev: ISurveySequenceContentTemplateForm, next: Partial<ISurveySequenceContentTemplateForm>) => {
    return { ...prev, ...next }
  },
  initialSurveySequence,
  )
  const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

  const [priority, setPriority] = useState<number>(0)
  const [alternativeTitle, setAlternativeTitle] = useState<string>('')


  const [customJSONBList, setCustomJSONBList] = useState<SurveySequenceJSONB[] | null>(null)

  console.log('customJSONBList',customJSONBList);
  
  
  useEffect(() => {
    if (currentLanguage.current === LanguageEnum.English) {
      updateFormData({
        survey_sequence: props.contentTemplate.survey_sequence.find((item) => item.language === LanguageEnum.English),
        content_metadata: props.contentTemplate.content_metadata.find((item) => item.language === LanguageEnum.English)
      })
    }
    setPriority(props.priority)
    setAlternativeTitle(props.alternativeTitle)
    if(props.customJSONB) {
      setCustomJSONBList(props.customJSONB)
    }
  }, [props.alternativeTitle, props.contentTemplate.survey_sequence, props.contentTemplate.content_metadata, props.priority, props.customJSONB])

  const sortedList = useMemo(() => sortTemplate<ISurveySequence[]>(props.contentTemplate.survey_sequence), [props.contentTemplate.survey_sequence])

  const updateFieldsHandler = () => {
     props.onUpdateFields(priority, alternativeTitle, customJSONBList)
  }

  const changeCustomJSONBHandler = (jsonb: SurveySequenceJSONB) => {
    if (customJSONBList) {
      const index = customJSONBList.findIndex((item) => item.survey_id === jsonb.survey_id)
      if (index === -1) {
        setCustomJSONBList([...customJSONBList, jsonb])
      } 
      else if(jsonb.alt_title === '') {
        // remove from list
        const newList = [...customJSONBList]
        newList.splice(index, 1)
        setCustomJSONBList(newList)
      }
      else {
        const newList = [...customJSONBList]
        newList[index] = jsonb
        setCustomJSONBList(newList)
      }
    } else {
      setCustomJSONBList([jsonb])
    }
  }


  return (
    <Layout>
      <div className="flex items-center gap-2 py-2.5">
        {
          sortedList.map((item, idx) => {
            return (
              <AppButton
                key={idx}
                variant={currentLanguage.current === item.language ? 'info' : 'primary'}
                type="button"
                size="xs"
                onClick={() => {
                  currentLanguage.current = item.language
                  updateFormData({ survey_sequence: item, content_metadata: props.contentTemplate.content_metadata.find((item) => item.language === currentLanguage.current) })
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <LanguageIcon className='w-3 h-3' />
                  <span className="text-xs">{getLangaugeLabel(item.language)}</span>
                </div>
              </AppButton>
            )
          })
        }
      </div>
      <div className="flex flex-col gap-1">
        <AppInput disabled tabIndex={-1} autoFocus placeholder={"Title"} value={formData.survey_sequence.title} name='title' isFull label='Title' required />

        <AppTextArea disabled placeholder={"Description"} rows={4} value={stripHtml(formData.survey_sequence.description).result || ""} name='description' label='Description' />
        <AppInput disabled placeholder={"Preview Title"} value={formData.content_metadata.preview_title} name='preview_title' isFull label='Preview Title' extendClass='my-2' maxLength={140} required />
        <AppInput disabled placeholder={"Tag"} value={formData.content_metadata.tags[0]} name='tags' isFull label='Tag' extendClass='my-2' />
        
        <h1 className="mt-2 text-xl font-bold text-darkColor">{t("SurveyItems")}</h1>
        {
            formData.survey_sequence.surveys.map((item, idx) => {
                return (
                  <SurveyList
                  item={item}
                  key={item.id || idx}
                  onChangeCustomJSONB={changeCustomJSONBHandler}
                  customJSONB={
                    customJSONBList
                      ? customJSONBList.find((jsonb) => jsonb.survey_id === item.id) || null
                      : null
                  }
                />
                )
            })
        }


        <AppInput type="number" min={0} placeholder={"Priority"} value={priority.toString()} onChange={e => {setPriority(parseInt(e.target.value)) }} name='Priority' isFull label='Priority' extendClass='my-2' />
        <AppInput placeholder={"Alternative Title"} value={alternativeTitle} onChange={e=> setAlternativeTitle(e.target.value)} name='alt_title' isFull label='Alternative Title' extendClass='my-2' />
        
        {formData.content_metadata.cover_image ? <>
          <div className="cover__image-container">
            <img alt="conver_image" src={formData.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
          </div>
        </> : null}

        <div className="flex gap-2 pb-2">
          <AppButton type='button' onClick={() => { navigate(`/content-templates/survey_sequence/view/${props.contentTemplate.id}`) }} size='md' variant="info">
            <div className='flex items-center justify-between gap-1'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
              </svg>
              <span>Edit Survey Sequence Template</span>
            </div>
          </AppButton>
          <AppButton variant="primary" type="button" extendClass="px-3" size='md' onClick={updateFieldsHandler}>
            <div className="flex items-center justify-center gap-1">
              <SaveIcon className="w-4 h-4 text-grayColor" />
              {t('update')}
          </div>
          </AppButton>
        </div>
      </div>
    </Layout>
  )
}


const SurveyList = ( {item, customJSONB, onChangeCustomJSONB } : {item: ISurveyForServer, customJSONB:SurveySequenceJSONB | null, onChangeCustomJSONB: (jsonb: SurveySequenceJSONB) => void}) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    return (
        <div className="p-3 my-5 overflow-hidden bg-gray-100 border rounded border-borderGray">

                <div className="flex items-center justify-between p-3">
				<div className="flex flex-col w-full py-2 pl-1">
					<span className="font-semibold text-darkColor">
						Survey Items Details
					</span>
					{isCollapsed ? (
						<>
							<p className="mt-2 text-sm text-textDark">
								<span className="font-bold">Title:</span> {item.title}
							</p>
						</>
					) : null}
				</div>
				<button
					onClick={(event) => {
						event.preventDefault()
						setIsCollapsed((prev) => !prev)
					}}
				>
					{!isCollapsed ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6"
							viewBox="0 -960 960 960"
						>
							<path
								fill="currentColor"
								d="M480-344L240-584l43-43 197 197 197-197 43 43-240 240z"
							></path>
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6"
							viewBox="0 -960 960 960"
						>
							<path
								fill="currentColor"
								d="M480-554L283-357l-43-43 240-240 240 240-43 43-197-197z"
							></path>
						</svg>
					)}
				</button>
			</div>

            <div className={`${isCollapsed ? 'hidden' : ''}`}>            
                        <div className="grid grid-cols-1 gap-2 mb-2">
                        <div className="w-11/12 p-1 m-1">
                            <AppInput disabled placeholder={"Title"} value={item.title} name='title' isFull label='Title' />
                            {
                              customJSONB ? 
                              <CustomJSONBComponent onChangeJSONB={onChangeCustomJSONB} customjson={customJSONB} /> : 
                              <div className="my-4">
                                <AppButton type="button" onClick={() => {
                                  onChangeCustomJSONB({
                                    alt_title: '',
                                    survey_id: item.id!,
                                    // @ts-ignore
                                    survey_sequence_id: item.owner_id
                                  })
                                }} variant="success" size="sm" >Add Alt Title</AppButton>
                              </div>
                            }
                            <AppTextArea
							rows={3}
							placeholder={'Description'}
							name="description"
							value={item.description}
							isFull
							label="Description" />
                        </div>
                        </div>
                        {
                            item.survey_items.map((item, idx) => {
                                return (
                                    <div key={item.id || idx} className="flex flex-col w-[90%] gap-2 p-2.5 m-1 bg-primary rounded shadow-sm">
                                    <p className="font-bold text-md">{item.label} </p>
                                    <p>{item.value}</p>
                                 </div>
                                )
                            })
                        }
                        </div>
                    </div>
    )
}


export const CustomJSONBComponent =  ({customjson, onChangeJSONB } :{ customjson: SurveySequenceJSONB, onChangeJSONB: (jsonb: SurveySequenceJSONB) => void } ) => {


  const [data, setData] = useState<SurveySequenceJSONB | null>(customjson);
  const [inputValue, setInputValue] = useState<string>(customjson.alt_title || '');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const createObject = () => {
    const newData: SurveySequenceJSONB = {
      alt_title: inputValue,
      survey_id: customjson.survey_id,
      survey_sequence_id: customjson.survey_sequence_id
    };
    setData(newData);
    onChangeJSONB(newData)
    // setJsonPreview(JSON.stringify(newData, null, 2));
  };

  const updateObject = () => {
    if (data) {
      setData({ ...data, alt_title: inputValue });
      onChangeJSONB({ ...data, alt_title: inputValue })
      // setJsonPreview(JSON.stringify({ ...data, value: inputValue }, null, 2));
    }
    toast('Alt title is updated','success')
  };

  const deleteObject = () => {
    setData(null);
		setInputValue('');
    onChangeJSONB({ ...customjson, alt_title: '' })
  };


  // Determine the placeholder based on whether inputValue is empty
  const placeholder = inputValue ? 'Edit value' : 'Enter a value';


  return <div className="flex flex-col gap-3 p-2 my-4 border border-gray-400 rounded-md">
  <AppInput
    name="alt_title"
    type="text"
    className="bg-white"
    isFull
    placeholder={placeholder}
    value={inputValue}
    onChange={handleInputChange}
    label="Alt title"
  />
  {data ? (
    <div className="flex gap-2">
      <AppButton type="button" size="sm" variant="primary" className="p-2 ml-2 text-white bg-blue-700 rounded-sm" onClick={updateObject}>
        Update
      </AppButton >
      <AppButton type="button" size="sm" variant="danger" className="p-2 ml-2 text-white bg-red-500 rounded-sm" onClick={deleteObject}>
        Delete
      </AppButton >
    </div>
  ) : (
    <div>
      <AppButton type="button" size="sm" variant="success"  className="p-2 text-white bg-green-500 rounded-sm" onClick={createObject}>
      Create
    </AppButton >
    </div>
  )}
</div>
}