import { FC, memo } from "react";

interface BackdropProps {
  onClose: () => void;
  children: JSX.Element[] | JSX.Element;
  isOpen: boolean;
}

export const BackDrop: FC<BackdropProps> = memo(
  ({ onClose, children, isOpen }) => {
    return <></>;
  }
);
