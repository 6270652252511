import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Input from "../../General/Form/Input";
import Button from "../../General/Button";
import { AiFillFile } from "react-icons/ai";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
    Conditions_T,
    emailActions,
    notificationActions, ruleBody, ruleIds, Single_Action_Single,
    smsActions,
    state_Edit_Action
} from "../../../Common/Types";
import PreviewActionModal from "../ActionPreview/PreviewActionModal";
import Condition from "../../Event/Condition";
import UseTab from "../../../Hook/UseTab";
import OverlayModal from "../../Layouts/OverlayModal";
import { BsFillEyeFill } from "react-icons/bs";
import Tab from "../../Tabs/Tab";
import Tabs from "../../Tabs/Tabs";
import { useMutation, useQueryClient } from "react-query";
import { createActionInState, updateAction } from "../../../apiQuery/Actions/Actions.apis";
import { store } from "../../../GlobalState/store";
import { useParams } from "react-router-dom";
// import { setSnackbar } from "../../../GlobalState/store.actions";
import {
    createTimeBaseEvent,
    deleteTimeCondition,
    updateTimeCondition
} from "../../../apiQuery/Condition/TimeBase.apis";
import WYSIWYGEditor from "../../General/Form/WYSIWYGEditor";
import { stripHtml } from "string-strip-html";
import { createActionRule, createContentRule } from "../../../apiQuery/Condition/Rule.apis";
import { formatQuery } from "react-querybuilder";
import initialQuery from "../../../utils/InitialRules";
import jsonHandler from "../../Utils/JsonHandler";
import { Fade } from "@mui/material";
import { createActionRuleCondition } from '../../../apiQuery/Actions/ActionRuleCondition.apis';
import { toast } from '../../../utils/Toast';
import { useTranslation } from "react-i18next";
import { EnumActionType, IActionMetadata, IActionTemplateFilterResponse } from '../../../Types/ActionTemplate/ActionTemplate';
import { getActionTemplateFilter, setATemplateToActionType } from '../../../apiQuery/ActionTemplate/ActionTemplate.apis';
import { getLangaugeLabel } from '../../../utils/Languages';
import AppDropDown from '../../Shared/AppDropDown/AppDropDown';
import AppInput from '../../Shared/AppInput/AppInput';
import AppTextArea from '../../Shared/AppTextArea/AppTextArea';
import { getStateActionTemplateFilter } from '../../../apiQuery/ActionTemplate/StateTemplate.apis';
import { AppLoader } from '../../Shared/AppLoader';
import moment from "moment";
import AppButton from '../../Shared/AppButton/AppButton';

const EmailNew: FC<state_Edit_Action> = memo(({ onClose, defaultValue, onSuccess, type }) => {
    const { t, i18n } = useTranslation();
    const {
        state: globalState,
        dispatch
    } = useContext(store);



    const organization_id = useMemo(() => globalState.organization_id, [globalState.organization_id])

    const queryClient = useQueryClient()

    const [ruleId, setRuleId] = useState<ruleIds>({
        event: undefined,
        time: undefined
    });
    const [query, setQuery] = useState(initialQuery);

    const params = useParams();
    // const id = params.stateId || ""
    const id = params.stateSlug || ""
    const pathwaySlug = params.slug || ""

    const {
        register,
        handleSubmit,
        getValues,
        trigger,
        setValue,
        control,
        formState: { errors }
    } = useForm<emailActions>({
        defaultValues: defaultValue ? defaultValue : {
            subject: "",
            body: "",
            action_type: "email"
        }
    });


    // convert this to a useMemo: const isContentTemplate
    const isContentTemplate = useMemo(() => defaultValue?.template_id ? true : false, [defaultValue?.template_id])

    const [timeEvent, setTimeEvent] = useState({
        isRuleAdded: false,
        isAdded: false,
    })

    const [passedContent, setPassedContent] = useState({
        targetField: '',
        targetFunction: '',
    })

    //@ts-ignore
    const timeValue = parseInt(getValues("timeValue")) as number

    //@ts-ignore
    const eventName = getValues("eventName") as string

    //@ts-ignore
    const typeOf = getValues("typeOf") as string

    //@ts-ignore
    const checkInValue = getValues("checkInValue") as string

    //@ts-ignore
    const target_func = getValues("target_func") || undefined as string


    // mutations


    // const setTemplateToArticle = useMutation((params: {
    //     pathway_id: number, state_id: number, data:
    //     { pathway_id: number, state_id: number, template_id: number, action_type: EnumActionType }
    // }) => setATemplateToActionType(params.pathway_id, params.state_id, params.data), {
    //     onSuccess: (data) => {
    //         toast('action template set successfully', 'success')
    //     },
    //     onError: (error) => {}
    // })



    const [previousPage, setPreviousPage] = useState<string>();
    const [nextPage, setNextPage] = useState<string>();
    const [searchContentTemplate, setSearchContentTemplate] = useState('')

    const { data: emailTemplateData, isLoading: emailTemplateIsLoading, isError: emailTemplateIsError, error: emailTemplateError, mutate } = useMutation(
        (pageDirection: 'next' | 'previous' | '') => getStateActionTemplateFilter<any, EnumActionType.EmailAction>
            (globalState.currentFlow.slug, id, EnumActionType.EmailAction, 8, '', nextPage, previousPage, pageDirection), {
        onSuccess(data) {
            if (data?.data) {
                const { next, previous } = data?.data.paginate
                setNextPage(next)
                setPreviousPage(previous)
            }
        },
    });

    useEffect(() => {
        mutate('')
    }, [mutate]);


    const flattedEmailTemplateOptions = useMemo(() => {

        const currentLanguage = i18n.language;
        const emailOptions = emailTemplateData && emailTemplateData.data.data.map((item: any) => {
            return item.action_metadata.map((email: any) => {
                return email
            })
        }).flatMap((item) => item).filter((item: any) => item.language === currentLanguage).map((item: any) => {
            return {
                email_id: item.id,
                template_id: item.owner_id,
                language: item.language,
                subject: item.subject,
                body: item.body,
                updated_at: item.updated_at,
            }
        });
        return emailOptions;
    }, [emailTemplateData, i18n.language]);


    const search_result = useMemo(() => {
        if (searchContentTemplate === '') return flattedEmailTemplateOptions
        return flattedEmailTemplateOptions &&
            flattedEmailTemplateOptions.filter(item => item.subject.toLowerCase().includes(searchContentTemplate.toLowerCase()) ||
                item.body.toLowerCase().includes(searchContentTemplate.toLowerCase()))
    }, [flattedEmailTemplateOptions, searchContentTemplate])


    const addActionTemplate = async (template: any) => {
        // let shapeData: emailActions;
        const _shape: emailActions = {
            subject: template.subject,
            body: template.body,
            is_time_base: timeEvent.isAdded,
            time_condition: timeEvent.isAdded ? undefined : null,
            action_type: "email",
        }

        // shapeData

        let ruleID = (timeEvent.isRuleAdded && ruleId.event !== 0) ? ruleId?.event || null : null
        // let rules_action_id: null | number = null;
        if (timeEvent.isRuleAdded && ruleId.event === 0) {
            if (query.rules.length !== 0) {
                if (passedContent.targetField && passedContent.targetFunction) {
                  
                    const { data } = await createActionRuleConditionWithDependOnField.mutateAsync();
                    ruleID = data?.id || null;
                } else {
                    const { data } = await createRuleCondition.mutateAsync();
                    ruleID = data?.id || null;
                }
                // ruleID = data?.id || null;
            }
        }
        await createActions.mutateAsync({ ..._shape, rules_action_id: ruleID })


        onClose()


    }


    const updateContentTemplate = async () => {

    }





    //===========================================================================================================================



    // const flattedEmailTemplateOptions = useMemo(() => {
    //     const newOption = { value: '0', label: 'No Template Content' };
    //     const currentLanguage = i18n.language;
    //     const emailOptions = emailTemplateData && emailTemplateData.data.data.map((item: any) => {
    //         const { id } = item;
    //         const actionMetadata: IActionMetadata[] = item.action_metadata;

    //         const list = actionMetadata.filter(c => c.language === currentLanguage);
    //         return list.map(l => {
    //             return {
    //                 value: String(l.id),
    //                 label: l.subject + ' - ' + getLangaugeLabel(l.language)
    //             }
    //         })
    //     }).flatMap((item) => item);

    //     if (emailOptions) {
    //         emailOptions.unshift(newOption); // Add new option to the beginning of the array
    //     } else {
    //         return [newOption]; // If emailOptions is undefined, return the new option as the only item in the array
    //     }

    //     return emailOptions;
    // }, [emailTemplateData, i18n.language]);



    const [selectedContentTemplate, setSelectedContentTemplate] = useState<any>('0')

    const [extractedEmailTemplate, setExtractedEmailTemplate] = useState<any>({
        email: {}
    })



    // const showChangedTemplateContent = (e: any) => {

    //     if (e.target.value !== '0') {

    //         const currentLanguage = i18n.language;

    //         const _temp = emailTemplateData && emailTemplateData.data.data
    //         const _filter: any = _temp?.map(item => {
    //             return [
    //                 ...item.action_metadata
    //             ]
    //         }).flat()
    //         const emailData = _filter.find((c: any) => c.id === parseInt(e.target.value))
    //         // @ts-ignore
    //         setExtractedEmailTemplate({
    //             email: emailData,
    //             // content_metadata: articleMetadataByLanguage
    //         })
    //     }
    //     setSelectedContentTemplate(e.target.value)
    // }






    const createTimeEvent = useMutation((timeData: Conditions_T) => createTimeBaseEvent(organization_id, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        },
        onError: (error: null | Error) => {
            // const message = error?.message || "Failed creating Time base Rule"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.message || `${t('actions.createTimeEvent')}`
            toast(message, 'error')
        }
    })
    const deleteCondition = useMutation((delId: number) => deleteTimeCondition(organization_id, delId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        }
    })
    const updateTimeEvent = useMutation((timeData: Conditions_T) => updateTimeCondition(organization_id, timeData.id || 0, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }))
    const createRuleCondition = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        // return createActionRule({...ruleData, deliver_time: deliver_time, deliver_unit: deliver_unit,target_func})
        return createActionRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-action-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore
            // const message = error?.response?.data?.message || "Failed creating Action Rule"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.response?.data?.message || `${t('actions.createRuleCondition')}`
            toast(message, 'error')
        }
    })

    const UpDateFunc = async (createdData: emailActions | smsActions | notificationActions) => {
        if (ruleId.event === 0) {
            let ruleId = null
            if (query.rules.length !== 0) {
                const { data } = await createRuleCondition.mutateAsync(
                    // {
                    //     organization_id: organization_id,
                    //     ...jsonHandler(timeValue, typeOf, eventName, query,target_func)
                    // }
                )
                ruleId = data?.id || null
            }

            await updateActionMutation.mutateAsync({ ...createdData, rules_action_id: ruleId })
        } else await updateActionMutation.mutateAsync({ ...createdData, rules_action_id: ruleId.event || null })
    }

    const createTimeRule = async (ID: number | undefined) => {
        const typeIs = typeOf === 'days' ? 'hour' : typeOf
        await createTimeEvent.mutateAsync({
            deliver_time: timeValue ? timeValue : 5,
            deliver_unit: typeIs || "minute",
            state_id: parseInt(id),
            action_id: ID
        })
    }

    const createActions = useMutation((newActions: emailActions | smsActions | notificationActions) => createActionInState(organization_id, pathwaySlug, id, { ...newActions }), {
        onSuccess: async (createdData: Single_Action_Single) => {
            if (timeEvent.isAdded) {
                await createTimeRule(createdData.data?.id)

            }
            // dispatch(setSnackbar({ isOpen: true, type: 'success', message: 'Action has been created.' }))
            toast(`${t('actions.created')}`, 'success')
            !!onSuccess && onSuccess()
            // onClose()
        },
        onError: (error: null | Error) => {
            // const message = error?.message || "Failed creating Action"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.message || `${t('actions.failed')}`
            toast(message, 'error')
        }
    })

    const updateActionMutation = useMutation((updateActionDetails: emailActions | smsActions | notificationActions) => updateAction({ ...updateActionDetails }, organization_id, pathwaySlug, id, updateActionDetails?.slug || ''), {
        onSuccess: async () => {
            if (defaultValue) {

                toast(`${t('actions.updated')}`, 'success')
                !!onSuccess && onSuccess()
                await queryClient.invalidateQueries(['action', defaultValue.id])
                onClose()
            }
        },
        onError: (error: null | Error) => {

            const message = error?.message || `${t('actions.updated_failed')}`
            toast(message, 'error')
        }
    })

    // Create Action rule mutation.


    const createActionRuleConditionWithDependOnField = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        const value = { organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func: passedContent.targetFunction, target_field: passedContent.targetField };
        return createActionRuleCondition(value)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-action-rules", organization_id])
        },
        onError: (error: null | Error) => {
            //@ts-ignore
            const message = error?.response?.data?.message || `${t('actions.failed_create_action_rule')}`
            toast(message, 'error')
        }
    })



    // ===================   Submit to backend  ( CREATE, UPDATE )  ====================
    const onSubmit: SubmitHandler<emailActions> = async data => {

        await trigger()

        let shapeData: emailActions;



        shapeData = {
            ...data,
            is_time_base: timeEvent.isAdded,
            time_condition: timeEvent.isAdded ? undefined : null,
        }

 
        if (type === 'create') {
            let ruleID = (timeEvent.isRuleAdded && ruleId.event !== 0) ? ruleId?.event || null : null
            // let rules_action_id: null | number = null;
            if (timeEvent.isRuleAdded && ruleId.event === 0) {
                if (query.rules.length !== 0) {
                    if (passedContent.targetField && passedContent.targetFunction) {
                      
                        const { data } = await createActionRuleConditionWithDependOnField.mutateAsync();
                        ruleID = data?.id || null;
                    } else {
                        const { data } = await createRuleCondition.mutateAsync();
                        ruleID = data?.id || null;
                    }
                    // ruleID = data?.id || null;
                }
            }
            await createActions.mutateAsync({ ...shapeData, rules_action_id: ruleID })


            onClose()
        }

        if (type === 'update') {
            const timeId = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined

            if (timeEvent.isAdded) {
                if (defaultValue?.time_condition && timeId) {
                    await updateTimeEvent.mutateAsync({
                        id: timeId,
                        deliver_time: timeValue ? timeValue : 5,
                        deliver_unit: typeOf || "minute",
                        state_id: parseInt(id),
                    })
                } else await createTimeRule(defaultValue.id)
            }
            if (!timeEvent.isAdded && defaultValue?.is_time_base) {
                if (id) await deleteCondition.mutateAsync(timeId)
            }

            await UpDateFunc(shapeData)
        }
    };

    const [modal, setModal] = useState({
        pathwayView: false
    })

    const [tab, setTab] = UseTab('Email Details')

    const handlePathwayView = useCallback(() => {
        setModal(prev => ({ ...prev, pathwayView: !prev.pathwayView }))
    }, [])


    const title = getValues("subject");
    const description = getValues("body");

    useEffect(() => {
        if (defaultValue) {
            const id = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined
            setRuleId({ time: id, event: defaultValue?.rules_action_id })

            if (defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.deliver_time) {
                //@ts-ignore
                setValue("timeValue", defaultValue?.time_condition[0]?.deliver_time)  //@ts-ignore
                setValue("typeOf", defaultValue?.time_condition[0]?.deliver_unit)
            }

        }
    }, [])

    return (
        <div className="flex items-center w-full">
            {/*---------------------------------------------------------*/}
            {/*New Email action container with tabs*/}
            <div className="flex w-full space-x-3">
                <form onSubmit={handleSubmit(onSubmit)}
                    className='w-full h-full space-y-3 overflow-auto text-gray-600 text-md'>


                    {
                        type === 'create' ? <>

                            {/* ======================================== CREATE:  choose between content template   ======================================================= */}
                            <AppInput placeholder='Search' value={searchContentTemplate} onChange={e => setSearchContentTemplate(e.target.value)} name='searchTemplate' />
                            <div className='flex items-center justify-center'>
                                {emailTemplateIsLoading ? <AppLoader isLoading size={35} /> : null}
                            </div>

                            <div className='grid grid-cols-2 gap-1'>
                                {search_result && search_result.map((item, index) => {
                                    return <div className='flex flex-col items-start justify-start p-4 m-1 transition-all delay-300 rounded-md bg-gray-50 hover:bg-white' key={index}>
                                        <span
                                            onClick={() => addActionTemplate(item)}
                                            className='px-2 text-xs border rounded hover:cursor-pointer hover:bg-sky-100 border-sky-500 bg-sky-50 text-sky-700'>Add +</span>
                                        <p className='my-1 font-medium text-md'>{item.subject}</p>
                                        <p className='flex-1 mb-3 text-xs'>
                                            {item.body}
                                        </p>
                                        {item.updated_at ? <p className="mt-1 text-xs">Last updated {moment(item.updated_at).fromNow() + '. on ' + moment(item.updated_at).format('MMM D, YYYY')
                                        }</p> : null}
                                    </div>
                                })}
                            </div>

                            <div className="flex">
                                <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
                                    onClick={() => {
                                        // activateSearch.current = true
                                        mutate('previous')
                                    }}
                                    extendClass="mx-1">Previous</AppButton>
                                <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
                                    onClick={() => {
                                        mutate('next')
                                        //    activateSearch.current = true
                                    }}
                                    extendClass="mx-1">Next</AppButton>
                            </div>

                        </> : null
                    }




                    {/*----------------------  Update the OLD Email Content   ----------------------------*/}
                    {
                        type === "update" && isContentTemplate === false ? <>

                            {/*---------------------------------------------------------*/}
                            {/*Email Details and Content Details tabs*/}
                            <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView()
                                    e.stopPropagation()
                                }} />} title="Email Details">Email
                                    Details</Tab>
                                <Tab title="Condition Details">Condition Details</Tab>
                            </Tabs>

                            {/*---------------------------------------------------------*/}
                            {/*Email Details tab with form inputs*/}
                            <Fade in={tab === "Email Details"}
                                style={{ display: tab === "Email Details" ? '' : 'none' }}>
                                <div className="space-y-4">

                                    {
                                        selectedContentTemplate === '0' ? (
                                            <>
                                                <Input
                                                    register={() => register("subject", { required: 'subject is required' })}
                                                    name="subject"
                                                    placeholder="Email subject line"
                                                    errors={errors}
                                                />

                                                <Controller
                                                    render={({ field }) => <WYSIWYGEditor {...field} placeholder={"Email Body"} />}
                                                    name="body"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        validate: {
                                                            required: (v) => (v && stripHtml(v).result.length > 0) || "body is required",
                                                            maxLength: (v) => (v && stripHtml(v).result.length <= 2000) || "Maximum character limit is 3000",
                                                        },
                                                    }}
                                                />

                                                <p className="text-red-500">{errors.body && <p>{errors.body.message}</p>}</p></>
                                        ) : (

                                            <div className="flex flex-col">
                                                {/* @ts-ignore */}
                                                <AppInput label='Title' name='title' value={extractedEmailTemplate.email?.subject} disabled isFull extendClass='my-2' />
                                                {/* @ts-ignore */}
                                                <AppTextArea rows={4} name='body' label='Body' value={extractedEmailTemplate.email?.body} disabled isFull extendClass='my-2' />
                                            </div>
                                        )
                                    }

                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Condition Details tab with form inputs*/}
                            <Fade in={tab === "Condition Details"}
                                style={{ display: tab === "Condition Details" ? '' : 'none' }}>
                                <div>
                                    {/* <Condition typeOf={typeOf}
                                        register={register}
                                        errors={errors}
                                        query={query}
                                        setQuery={setQuery}
                                        rule={ruleId}
                                        setRule={setRuleId}
                                        setTimeEvent={setTimeEvent}
                                        name={""}
                                        setPassedContent={setPassedContent}
                                        isAction
                                    /> */}
                                    {/*                    {*/}
                                    {/*                        (ruleId.event === 0) ?*/}
                                    {/*                            <>*/}
                                    {/*                                <h4>Query</h4>*/}
                                    {/*                                <pre>*/}
                                    {/*  <code>{formatQuery(query, 'json')}</code>*/}
                                    {/*</pre>*/}
                                    {/*                            </> : null*/}
                                    {/*                    }*/}

                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Submit form to create the Email*/}
                            <Button
                                disabled={createActions.isLoading || updateActionMutation.isLoading || createTimeEvent.isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading || createRuleCondition.isLoading}
                                type="submit"
                                label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>{type === 'update' ? 'Update Action' : 'Create Action'}</p>
                                    </div>
                                } extraClasses="w-fit px-3 py-3"
                            />
                        </> : null
                    }


                </form>
            </div>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for Email*/}
            <OverlayModal onClose={handlePathwayView} isOpen={modal.pathwayView}>
                <div className="relative flex p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md h-fit w-fit">
                    <PreviewActionModal
                        type="email"
                        title={title} description={description || ""}
                    />
                </div>
            </OverlayModal>

        </div>
    );
})

export default EmailNew;
