import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  deleteActionCondition,
  deleteContentCondition,
  deleteStateCondition,
} from "../../../apiQuery/Condition/Rule.apis";
import { store } from "../../../GlobalState/store";
import OverlayModal from "../../Layouts/OverlayModal";
import { withAuth } from "../../../hoc/withAuth";
import { useTranslation } from "react-i18next";
import { toast } from "../../../utils/Toast";
import {
  ActionTemplateIcons,
  AppButton,
  AppDivider,
  ArrowIcon,
  CloseIcon,
  CommandBar,
  ContentTemplateIcons,
  DeleteIcon,
  PathwayIcon,
  PopupModal,
  THead,
  TabBar,
  Table,
} from "../../../Theme";
import { ContentCondition } from "./ContentCondition";
import { ActionCondition } from "./ActionConditions";
import { StateCondition } from "./StateCondition";
import { IRule } from "../../../Types/Rules/Rules";
import { UserContext } from "../../../Context/UserContext/UserContext";
import { IFilterItem, SelectedModalEnum } from "./Rule.types";
import { EditRuleModal, NewRuleModal } from "./ModalRules";
import { Drawer } from "../../../Theme/Drawer";
import { EnumContentType } from "../../../Types/ContentTemplate/ContentType";
import { EnumActionType } from "../../../Types/ActionTemplate/ActionTemplate";
import AppSearch from "../../Shared/AppSearch/AppSearch";
import { useNavigate } from "react-router-dom";
import {RulesFilter} from "../../../Types/Rules/RulesFilter"
import { debounce } from "lodash";

const ContentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 9.879 9.638"
    className={"h-3 w-3 text-grayColor"}
  >
    <path
      id="icon_content"
      d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
      transform="translate(-4 -4)"
      fill="currentColor"
    />
  </svg>
);
const ActionIcon = () => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.878 9.878"
    className={"h-3 w-3 text-grayColor"}
  >
    <path
      id="icon_actions"
      d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
      transform="translate(-2 -2)"
      fill="currentColor"
    />
  </svg>
);

const StateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 96 960 960"
    aria-hidden="true"
    className={"h-4 w-4 text-grayColor"}
  >
    <path
      fill="currentColor"
      d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
    ></path>
  </svg>
);

// Filter ====================================
const filterItems: IFilterItem[] = [
  {
    id: 1,
    field: SelectedModalEnum.content,
    label: "Content",
    condition: "includes",
    value: "",
    type: "text",
    icon: <ContentIcon />,
  },
  {
    id: 1,
    field: SelectedModalEnum.action,
    label: "Action",
    condition: "includes",
    value: "",
    type: "text",
    icon: <ActionIcon />,
  },
  {
    id: 1,
    field: SelectedModalEnum.state,
    label: "State",
    condition: "includes",
    value: "",
    type: "text",
    icon: <StateIcon />,
  },
];

const initialFilterItem: IFilterItem = {
  id: 0,
  field: SelectedModalEnum.state,
  label: "",
  condition: "",
  value: "",
  type: "text",
  icon: <StateIcon />,
};


//* Start component 
const ConditionsPage: FC = () => {

  
//! States
// The initial state of the rules filter.
// The search terms are an array of strings that are used to filter the rules.
  const initialRulesFilter: RulesFilter = {
    search: [],
    filter: {
      name: ""
    },
  };

  const [filterKey, setFilterKey] =  useState<RulesFilter>(initialRulesFilter);

  //!Hooks 
  const { isAdmin } = useContext(UserContext);
  const queryClient = useQueryClient()
  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const { state } = useContext(store);


  //! Debounce function
 // Debounce to prevent frequent API requests.
  const debounceFunction = useMemo(() => {
   return debounce((item:any) => {
    setFilterKey(item)},700)
  }, [filterKey]);


  //! Get value from input search appSearch tag to filter rules
  // Handle the filter event from the input search appSearch tag. 
  const handleFilterEvent = (event: React.ChangeEvent<HTMLInputElement>) => {

  //! Helper function 
    const getValueFun=(prevState:any) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        name: event?.target?.value,
      },
    })

    debounceFunction(getValueFun)
  };

  const organization_id = useMemo(
    () => state.organization_id,
    [state.organization_id]
  );


  // Tabs
  const [tab, setTab] = useState<SelectedModalEnum>(SelectedModalEnum.state);

  const [refetchConditions, setRefetchConditions] = useState({
    state:false,
    actions:false,
    contents:false
  })

  const [refetch, setRefetch] = useState<{
    type: SelectedModalEnum,
    allow: boolean
  }>({
    type: SelectedModalEnum.none,
    allow: false
  })


  // =========================== New Content Template ====================================
  const [showNewContent, setShowNewContent] = useState(false);

  const buttonRuleRef = useRef<HTMLButtonElement>(null);
  const newRuleRef = useRef<HTMLDivElement>(null);

  const [selectedRuleModal, setSelectedRuleModal] = useState<SelectedModalEnum>(
    SelectedModalEnum.none
  );
  // ========================== navigation  ===============================
  const navigate = useNavigate();

  // ========================== DRAWER  ===============================
  const [showDrawer, setShowDrawer] = useState(false);

  const [selectedRuleDrawerItem, setSelectedRuleDrawerItem] = useState<{ rule: IRule | null, type: SelectedModalEnum }>({
    rule: null,
    type: SelectedModalEnum.none
  })

  const setOpenDrawerHandler = (item: IRule, type: SelectedModalEnum) => {
    setSelectedRuleDrawerItem({ rule: item, type })
    setShowDrawer(prev => true)
  }

  const [showEditModal, setShowEditModal] = useState(false)

  // const [searchField, setSearchField] = useState('');

  // const onSearchChange = (event: { target: { value: string; }; }) => {
  //   const searchFieldString = event.target.value.toLocaleLowerCase();
  //   setSearchField(searchFieldString);
  // }

  // =========================== COMMANDBAR  =============================================

  const [selectedRules, setSelectedRules] = useState<{ type: SelectedModalEnum, id: number }[]>([]);
  const [showCommandBar, setShowCommandBar] = useState(false);
  const closeCommandBar = () => setShowCommandBar((prev) => false)
  useEffect(() => {
    if (selectedRules.length > 0) {
      setShowCommandBar(true);
    } else {
      setShowCommandBar(false);
    }
  }, [selectedRules.length]);

  // ========================== POPUP Modal  ===============================

  const [showPopupDeleteRule, setShowPopupDeleteRule] = useState(false);

  // ===================================  Queries =====================================

  // const contentRules = useQuery<IRuleResponse, Error>(
  //   ["all-content-rules", organization_id],
  //   () => getAllRuleForContent(organization_id),
  //   {}
  // );
  // const actionsRules = useQuery<IRuleResponse, Error>(
  //   ["all-action-rules", organization_id],
  //   () => getAllRuleForAction(organization_id),
  //   {}
  // );
  // const stateRule = useQuery<IRuleResponse, Error>(
  //   ["all-state-rules", organization_id],
  //   () => getAllRule(organization_id),
  //   {}
  // );

  const deleteContentRule = useMutation(
    (delId: number) => deleteContentCondition(delId),
    {
      onSuccess: () => {
        // contentRules.refetch();
        setRefetch({
          type: SelectedModalEnum.content,
          allow: true
        })
        toast("Content rule has been deleted.", "success");
        closeCommandBar();
      },
      onError: (error: Error | any) => {
        toast(error.response.data.message, 'error')
      }
    }
  );

  const deleteActionRule = useMutation(
    (delId: number) => deleteActionCondition(delId),
    {
      onSuccess: () => {
        // actionsRules.refetch();
        setRefetch({
          type: SelectedModalEnum.action,
          allow: true
        })
        setTab(SelectedModalEnum.action)
        toast("Action rule has been deleted.", "success");
        closeCommandBar();
      },
      onError: (error: Error | any) => {
        toast(error.response.data.message, 'error')
      }
    }
  );

  const deleteStateRule = useMutation(
    (delId: number) => deleteStateCondition(delId),
    {
      onSuccess: () => {
        setRefetch({
          type: SelectedModalEnum.state,
          allow: true
        })
        toast("State rule has been deleted.", "success");
        closeCommandBar();
      },
      onError: (error: Error | any) => {
        toast(error.response.data.message, 'error')
      }
    }
  );


  const refetchByTab = useCallback(async () => {

   
    switch (tab) {
      case SelectedModalEnum.content:
        // setRefetch({
        //   type: SelectedModalEnum.content,
        //   allow: true
        // })
        await queryClient.invalidateQueries('content-rules')
        break;
      case SelectedModalEnum.action:
        // setRefetch({
        //   type: SelectedModalEnum.action,
        //   allow: true
        // })
        await queryClient.invalidateQueries('action-rules')
        break;
      case SelectedModalEnum.state:
        // setRefetch({
        //   type: SelectedModalEnum.state,
        //   allow: true
        // })
        await queryClient.invalidateQueries('state-rules')
        break;
    }
  },[queryClient, tab])


  // ========================== New Rule Modal  ===============================

  const newRuleModalHandler = useCallback(async () => {
    setSelectedRuleModal((prev) => SelectedModalEnum.none);
    closeCommandBar()
    setSelectedRules([])
    refetchByTab()
  }, []);


  // ========================== Edit Rule Modal  ===============================
  const editRuleModalHandler = useCallback(async () => {
    refetchByTab()
    setShowEditModal(prev => !prev)
  }, [tab]);


  // ========================== Filters  ====================================
  const buttonRef = useRef<HTMLButtonElement>(null);
  const applyFilterRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);
  // const [allFilters, setAllFilters] = useState<IFilterItem[]>(filterItems);
  const allFilters: IFilterItem[] = useMemo(() => filterItems, []);
  const [activeFilters, setActiveFilters] = useState<IFilterItem[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [applyFilter, setApplyFilter] = useState<{
    item: IFilterItem;
    show: boolean;
  }>({
    item: initialFilterItem,
    show: true,
  });

  useEffect(() => {
    // Save tabs to localStorage whenever it
    if (activeFilters.length > 0)
      localStorage.setItem("rulesFilters", JSON.stringify(activeFilters));
  }, [activeFilters]);

  useEffect(() => {
    // Load tabs from localStorage on component mount
    const savedFilters = localStorage.getItem("rulesFilters");

    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters || "") as IFilterItem[];

      let newFilter = {};
      if (parsedFilters) {
        parsedFilters.forEach((i) => {
          newFilter = { ...newFilter, [i.field]: i.value };
        });
      }
      setActiveFilters(parsedFilters);
    }
  }, []);

  const toggleFilterHandler = (item: IFilterItem, idx: number) => {
    const newActiveFilters = [...activeFilters];

    if (item.type === "text") {
      newActiveFilters[idx].condition =
        newActiveFilters[idx].condition === "includes"
          ? "excludes"
          : "includes";
      setActiveFilters(newActiveFilters);
    }
  };


  // close the fitler with ESC key
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowFilter(false);
        setShowNewContent(false)
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  // Close the filter by clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      const filterEl = filterRef.current;
      const buttonEl = buttonRef.current;
      const newRuleButtonEl = buttonRuleRef.current
      const newRuleEl = newRuleRef.current

      if (
        filterEl &&
        buttonEl &&
        !filterEl.contains(targetEl) &&
        !buttonEl.contains(targetEl)
      ) {
        setShowFilter(false);
      }


      if (newRuleButtonEl &&
        newRuleEl && !newRuleButtonEl.contains(targetEl) &&
        !newRuleEl.contains(targetEl)) {
        setShowNewContent(false)
      }


    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterRef, buttonRef, setShowFilter, setShowNewContent, buttonRuleRef, newRuleRef]);

  // ========================== Handlers  ====================================
  const onSelectedRulesChange = useCallback(
    (selectedRule: number, type: SelectedModalEnum) => {
      // const [selectedRules, setSelectedRules] = useState<{type: SelectedModalEnum,id: number}[]>([]);
      //search between items of ids in the {type: SelectedModalEnum,id: number}[] if the id is exists.
      const allIds = selectedRules.map((item) => item.id);
      if (allIds.includes(selectedRule)) {
        setSelectedRules((prev) => prev.filter((rule) => rule.id !== selectedRule));
      } else {
        // setSelectedRules((prev) => [...prev, selectedRule]);
        setSelectedRules((prev) => [...prev, { type: type, id: selectedRule }]);
      }
    },
    [selectedRules]
  );

  const confirmDeleteRules = async () => {
    if (selectedRules.length <= 0) {
      closeCommandBar();
      return;
    }
    const listOfContentToDelete = selectedRules.filter(item => item.type === SelectedModalEnum.content).map(item => item.id)
    const listOfActionToDelete = selectedRules.filter(item => item.type === SelectedModalEnum.action).map(item => item.id)
    const listOfStateToDelete = selectedRules.filter(item => item.type === SelectedModalEnum.state).map(item => item.id)

    if (listOfContentToDelete.length > 0) {
      await Promise.all(
        listOfContentToDelete.map((ruleId) => deleteContentRule.mutateAsync(ruleId))
      );
    }
    if (listOfActionToDelete.length > 0) {
      await Promise.all(
        listOfActionToDelete.map((ruleId) => deleteActionRule.mutateAsync(ruleId))
      );
    }
    if (listOfStateToDelete.length > 0) {
      await Promise.all(
        listOfStateToDelete.map((ruleId) => deleteStateRule.mutateAsync(ruleId))
      );
    }
    setSelectedRules([])
    closeCommandBar();
  };

  // let filteredActionRules: any;
  // if (searchField.length !== 0) {
  //   filteredActionRules = actionsRules?.data?.data.filter((item: any) => {
  //     let rule_name = item.rule_name	// const title = item?.action_metadata[0].text;
  //     return rule_name && rule_name.toLowerCase().includes(searchField.toLowerCase());
  //   });
  // } else {
  //   filteredActionRules = actionsRules?.data?.data;
  // }
  // let filteredStateRules: any;
  // if (searchField.length !== 0) {
  //   filteredStateRules = stateRule?.data?.data.filter((item: any) => {
  //     let rule_name = item.rule_name		// const title = item?.action_metadata[0].text;
  //     return rule_name && rule_name.toLowerCase().includes(searchField.toLowerCase());
  //   });
  // } else {
  //   filteredStateRules = stateRule?.data?.data;
  // }

  // let filteredContentRules: any;
  // if (searchField.length !== 0) {
  //   filteredContentRules = contentRules?.data?.data.filter((item: any) => {
  //     let rule_name = item.rule_name
  //     return rule_name && rule_name.toLowerCase().includes(searchField.toLowerCase());
  //   });
  // } else {
  //   filteredContentRules = contentRules?.data?.data;
  // }

 




  return (
    <Fragment>
      <section className="relative">
        <TabBar />
        <div className="border border-[borderGray] border-t-0 bg-white py-2.5 px-5 shadow-sm flex items-center gap-2">
          <div className="relative flex flex-wrap items-center justify-between gap-2">

            <AppButton
              size="xs"
              variant={tab === SelectedModalEnum.state ? 'info' : 'primary'}
              onClick={() => {
                setTab(SelectedModalEnum.state)
                closeCommandBar()
                setSelectedRules([])
              }}
              type="button"
            >
              <div className="flex items-center gap-1">
                <StateIcon />
                State
              </div>
            </AppButton>
            <AppButton
              size="xs"
              variant={tab === SelectedModalEnum.content ? 'info' : 'primary'}
              onClick={() => {
                setTab(SelectedModalEnum.content)
                closeCommandBar()
                setSelectedRules([])
              }}
              type="button"
            >
              <div className="flex items-center gap-1">
                <ContentIcon />
                Content
              </div>
            </AppButton>
            <AppButton
              size="xs"
              variant={tab === SelectedModalEnum.action ? 'info' : 'primary'}
              onClick={() => {
                setTab(SelectedModalEnum.action)
                closeCommandBar()
                setSelectedRules([])
              }}
              type="button"
            >
              <div className="flex items-center gap-1">
                <ActionIcon />
                Action
              </div>
            </AppButton>



            {/* <AppButton
              ref={buttonRef}
              variant="primary"
              type="button"
              size="xs"
              onClick={() => setShowFilter(!showFilter)}
            >
              <div className="flex items-center justify-center gap-1">
                <FilterIcon className="w-3 h-3" />
                <span className="text-xs">Filter</span>
                <span>+</span>
              </div>
            </AppButton> */}

            <AppButton
              variant="primary"
              size="xs"
              type="button"
              ref={buttonRuleRef}
              onClick={() => setShowNewContent(prev => !showNewContent)}
            >
              <div className="flex items-center gap-1">
                <span className="capitalize">New Rule</span>
                <span>+</span>
              </div>
            </AppButton>
             <AppSearch
              className={
                "search-box  block w-full h-8 rounded-lg border-transparent bg-primary p-2.5 text-sm text-textDark outline-none focus:border-transparent focus:ring-0"
              }
              placeHolder={"Search Rules"}
              onChangeHandler={(event)=> handleFilterEvent(event)}
            />


            {showNewContent ? (
              <div
                ref={newRuleRef}
                className="absolute top-0 z-50 p-1 bg-white border rounded shadow-md left-28 border-borderGray w-60"
              >
                <button type="button" onClick={() => setShowNewContent(false)} className="absolute right-0 flex justify-end p-3" >
                  <CloseIcon className="w-4 h-4" />
                </button>
                {allFilters.map((item, i) => (
                  <div
                    key={i}
                    className="flex items-center justify-between p-2 transition-all rounded-md cursor-pointer hover:bg-lightGrayColor"
                    onClick={() => {
                      setSelectedRules([])
                      setSelectedRuleModal(
                        SelectedModalEnum[item.field as SelectedModalEnum]
                      );
                      setShowNewContent((prev) => false);
                    }}
                  >

                    <div className="flex items-center gap-2 hover:cursor-pointer">
                      {item.icon}
                      <span className="text-sm transition-all text-textDark">
                        {item.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {showFilter ? (
              <div
                ref={filterRef}
                className="absolute top-0 left-0 z-50 p-1 bg-white border rounded shadow-md border-borderGray w-60"
              >
                {allFilters.map((item, i) => (
                  <div
                    key={i}
                    className="flex items-center justify-between p-2 transition-all rounded-md cursor-pointer hover:bg-lightGrayColor"
                    onClick={() => {
                      const isAlreadyAdded = activeFilters.find(
                        (filter) => filter.field === item.field
                      );
                      if (isAlreadyAdded) {
                        toast("Filter already added", "warning");
                        return;
                      }

                      setShowFilter(false);
                      setApplyFilter({ item: item, show: true });
                      setActiveFilters((prev) => [...prev, item]);
                    }}
                  >
                    <div className="flex items-center gap-2 hover:cursor-pointer">
                      {item.icon}
                      <span className="text-sm transition-all text-textDark">
                        {item.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>

        {activeFilters.length > 0 ? (
          <div className="border border-[borderGray] bg-white p-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30">
            <div className="relative flex items-center justify-between">
              <div className="flex gap-2">
                {activeFilters.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex items-center justify-start gap-2"
                    >
                      <div className="flex items-center justify-center h-full gap-1 px-1 bg-white">
                        <span className="px-2 py-1 text-xs rounded-sm bg-primary text-textDark">
                          Rule Type
                        </span>
                        <span
                          className="px-1 py-1 text-xs rounded-sm cursor-pointer bg-primary text-grayColor"
                          onClick={() => {
                            toggleFilterHandler(item, idx);
                          }}
                        >
                          {/* {item.condition} */}
                          {typeof item.value === "boolean"
                            ? item.value === true
                              ? "is"
                              : "is not"
                            : item.condition}
                        </span>
                        <span className="px-2 py-1 text-xs rounded-sm bg-primary text-textDark">
                          {item.label}
                        </span>
                        <div
                          className="flex items-center justify-center h-full px-1 py-1 rounded-sm bg-primary"
                          onClick={() => {
                            const newActiveFilters = [...activeFilters];
                            const findFilterItem = newActiveFilters.filter(
                              (_, i) => i === idx
                            )[0];
                            setActiveFilters((prev) => {
                              return prev.filter((_, i) => i !== idx);
                            });

                            if (findFilterItem.condition === "excludes") {
                              // @ts-ignore
                              delete newApplyQueries.filter[
                                `not_${findFilterItem.field}`
                              ];
                            } else {
                              // @ts-ignore
                              delete newApplyQueries.filter[
                                findFilterItem.field
                              ];
                            }
                          }}
                        >
                          <CloseIcon className="h-3" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <AppButton
                variant="primary"
                type="button"
                size="xs"
                onClick={() => {
                  setActiveFilters([]);
                  closeCommandBar()
                  setSelectedRules([])
                  localStorage.removeItem("rulesFilters");
                }}
              >
                <div className="flex items-center gap-1 flex-justify-center">
                  <span>Clear All Filters </span>
                  <CloseIcon className="w-3 h-3" />
                </div>
              </AppButton>
            </div>
          </div>
        ) : null}

        {/* ========================================================================================== */}
        <div className="pb-10">
          <div className="w-[97%] py-5 m-auto space-y-5">
            <Table outsideRef={tableRef}>
              <THead>
                <tr>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Rule
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Rule Type
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium">
                    References to Rule
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Display Condition
                  </th>
                </tr>
              </THead>
              <tbody>

                {(() => {
                  switch (tab) {
                    case SelectedModalEnum.state:
                      return <StateCondition
                        onDeleteContentRules={onSelectedRulesChange}
                        onOpenDrawer={setOpenDrawerHandler}
                        activeQuery={tab === SelectedModalEnum.state}
                        organizationId={organization_id}
                        allowRefetch={refetchConditions.state}
                        filterKey={filterKey}
                      />;
                    case SelectedModalEnum.action:
                      return <ActionCondition
                        onDeleteContentRules={onSelectedRulesChange}
                        onOpenDrawer={setOpenDrawerHandler}
                        activeQuery={tab === SelectedModalEnum.action}
                        organizationId={organization_id}
                        // allowRefetch={SelectedModalEnum.action === refetch.type && refetch.allow}
                        allowRefetch={refetchConditions.actions}
                        filterKey={filterKey}
                      />;
                    case SelectedModalEnum.content:
                      return <ContentCondition
                        onDeleteContentRules={onSelectedRulesChange}
                        onOpenDrawer={setOpenDrawerHandler}
                        activeQuery={tab === SelectedModalEnum.content}
                        organizationId={organization_id}
                        // allowRefetch={SelectedModalEnum.content === refetch.type && refetch.allow}
                        allowRefetch={refetchConditions.contents}
                        filterKey={filterKey}
                      />;
                    default:
                      return <></>;
                  }
                })()}
                {/* {activeFilters.length === 0 ? (
                  <>
                    <ContentCondition
                      data={filteredContentRules}
                      onDeleteContentRules={onSelectedRulesChange}
                      onOpenDrawer={setOpenDrawerHandler}
                    />
                    <StateCondition
                      data={filteredActionRules}
                      onDeleteContentRules={onSelectedRulesChange}
                      onOpenDrawer={setOpenDrawerHandler}
                      activeQuery={tab === SelectedModalEnum.state}
                    />

                    <ActionCondition
                      data={filteredStateRules}
                      onDeleteContentRules={onSelectedRulesChange}
                      onOpenDrawer={setOpenDrawerHandler}
                    />
                  </>
                ) : null} */}
                {/* {activeFilters.map((item, index: number) => {
                  if (
                    item.field === "content" &&
                    item.condition === "includes"
                  ) {
                    return (
                      <ContentCondition
                        key={index}
                        data={contentRules?.data?.data}
                        onDeleteContentRules={onSelectedRulesChange}
                        onOpenDrawer={setOpenDrawerHandler}
                      />
                    );
                  }
                  if (item.field === "state" && item.condition === "includes") {
                    return (
                      <StateCondition
                       activeQuery={tab === SelectedModalEnum.state}
                        key={index}
                        data={stateRule?.data?.data}
                        onDeleteContentRules={onSelectedRulesChange}
                        onOpenDrawer={setOpenDrawerHandler}
                      />
                    );
                  }
                  if (
                    item.field === "action" &&
                    item.condition === "includes"
                  ) {
                    return (
                      <ActionCondition
                        key={index}
                        data={actionsRules?.data?.data}
                        onDeleteContentRules={onSelectedRulesChange}
                        onOpenDrawer={setOpenDrawerHandler}
                      />
                    );
                  }
                })} */}
              </tbody>
            </Table>
          </div>
        </div>
        {/* ========================= */}
      </section>

      {/* =============== COMMANDBAR ====================== */}
      <CommandBar isOpen={showCommandBar} setOpen={closeCommandBar}>
        <div className="flex items-center gap-2">
          <span className="text-xs text-textGray">
            {selectedRules.length} Rule
            {selectedRules.length > 1 ? "s" : ""}&nbsp; Selected
          </span>



          {selectedRules.length === 1 ? (
            <AppButton
              type="button"
              variant="primary"
              onClick={() => {
                setShowEditModal(true)
                setShowNewContent(false);
              }}
            >
              <div className="flex items-center gap-1 mx-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  aria-hidden="true"
                  className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path
                    fill="currentColor"
                    d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
                  ></path>
                </svg>
                <span className="text-sm">
                  Edit Rule
                </span>
              </div>
            </AppButton>
          ) : null}

          <AppButton
            type="button"
            variant="primary"
            onClick={() => {
              if (!isAdmin) {
                toast("You are not  allowed to delete rules", "error");
                return;
              } else setShowPopupDeleteRule((prev) => !prev);
            }}
          >
            <div className="flex items-center">
              <DeleteIcon className="mr-1" />
              <span className="text-sm">Delete</span>
            </div>
          </AppButton>
        </div>

      </CommandBar>

      <OverlayModal
        onClose={newRuleModalHandler}
        isOpen={selectedRuleModal !== SelectedModalEnum.none && selectedRules.length === 0}
      >
        <NewRuleModal
          type={selectedRuleModal}
          modalHandle={newRuleModalHandler}
        />
      </OverlayModal>

      <OverlayModal
        onClose={async () => {
          setShowEditModal(prev => !prev)
          setRefetchConditions({ state:false, contents: false, actions:false })
          refetchByTab()
        }}
        isOpen={showEditModal}
      >
        {selectedRules.length > 0 ? (<EditRuleModal
          type={selectedRules[0].type}
          modalHandle={async () => {
            setShowEditModal(prev => !prev)
            refetchByTab()
          }}
          ruleId={selectedRules[0].id}
        />) : <></>}

      </OverlayModal>
      {/* ===================  POPUP Modal ======================== */}
      <PopupModal
        setOpen={setShowPopupDeleteRule}
        isOpen={showPopupDeleteRule}
        title="If you want to continue, confirm by clicking the delete button below."
        onConfirmClick={confirmDeleteRules}
      />

      {/* =============== DRAWER ==================== */}
      <Drawer
        setOpen={() => setShowDrawer(prev => false)}
        isOpen={showDrawer}
        title=""
        isFull
        outsideRef={tableRef}
        classNames="overflow-y-scroll"
      >
        <div className="flex flex-col justify-between h-full overflow-y-scroll">
          <div className="flex flex-col gap-2 p-4">
            {/* ========== */}
            <AppDivider top={10} />
            <div className="flex items-center justify-between">
              <span className="text-sm text-textGray">Rule Details</span>
            </div>
            <ul className="list-none">
              <li className="flex gap-2 my-2">
                <span className="w-20 text-sm text-textGray">Rule Type</span>
                <span className="text-sm capitalize text-textDark">
                  {selectedRuleDrawerItem.type}
                </span>
              </li>
              <li className="flex gap-2 my-2">
                <span className="w-20 text-sm text-textGray">Name</span>
                <span className="text-sm text-textDark">
                  {selectedRuleDrawerItem.rule?.rule_name.replaceAll('_', ' ')}
                </span>
              </li>
              <li className="flex gap-2 my-2">
                <span className="w-20 text-sm text-textGray">References</span>
                <span className="text-sm text-textDark">
                  {selectedRuleDrawerItem.type === SelectedModalEnum.content ? selectedRuleDrawerItem.rule?.contents?.length || 0 : ''}
                  {selectedRuleDrawerItem.type === SelectedModalEnum.state ? selectedRuleDrawerItem.rule?.pathway_states?.length || 0 : ''}
                  {selectedRuleDrawerItem.type === SelectedModalEnum.action ? selectedRuleDrawerItem.rule?.actions?.length || 0 : ''}
                </span>
              </li>

            </ul>
            <AppDivider />
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-textGray">List of References</span>
            </div>
            <div className="flex flex-col gap-5 ">
              {selectedRuleDrawerItem?.rule?.contents &&
                selectedRuleDrawerItem?.rule?.contents?.map((item: any, index) => {
                  return (
                    <div className="flex flex-col gap-2" key={index}>
                      <span className="flex items-center gap-2 cursor-pointer hover:bg-hoverLightBlue text-md text-textDark"
                        onClick={
                          () =>
                            navigate(
                              `/pathways/${item.pathway_slug}`
                            )
                        }
                      >
                        <PathwayIcon />
                        {item?.pathway_name || ''}
                      </span>
                      <span className="flex items-center justify-start gap-1 ml-6 text-sm cursor-pointer hover:bg-hoverLightBlue text-grayColor"
                        onClick={
                          () =>
                            navigate(
                              `/pathways/${item.pathway_slug}/state/${item.state_slug}`
                            )
                        }
                      >
                        <ArrowIcon className="w-3 h-3 hover:bg-hoverLightBlue " />
                        {item?.state_name
                          ? item?.state_name
                          : ""}
                      </span>
                      <div className="flex items-center text-sm cursor-pointer ml-9 hover:bg-hoverLightBlue text-grayColor" onClick={
                        () =>
                          navigate(`/content-templates/${item.content_type}/view/${item.content_id}`)

                      }>
                        <ArrowIcon className="w-3 h-3 mr-3 hover:bg-hoverLightBlue " />

                        <span className="w-5">
                          {ContentTemplateIcons[item.content_type as EnumContentType]}
                        </span>
                        <span className="mx-2 text-sm text-textDark">
                          {item?.preview_title || ''}
                        </span>
                      </div>
                    </div>
                  );
                })}
              {selectedRuleDrawerItem?.rule?.pathway_states &&
                selectedRuleDrawerItem?.rule?.pathway_states?.map((item: any, index) => {
                  return (
                    <div className="flex flex-col gap-2" key={index}>
                      <span className="flex items-center gap-2 cursor-pointer hover:bg-hoverLightBlue text-md text-textDark"
                        onClick={
                          () =>
                            navigate(
                              `/pathways/${item.pathway_slug}`
                            )
                        }
                      >
                        {
                          selectedRuleDrawerItem.type === SelectedModalEnum.state ?
                            <PathwayIcon /> : null
                        }
                        {item?.pathway_name || ''}
                      </span>
                      <span className="flex items-center justify-start gap-1 ml-5 text-sm cursor-pointer hover:bg-hoverLightBlue text-grayColor"
                        onClick={
                          () =>
                            navigate(
                              `/pathways/${item.pathway_slug}/state/${item.state_slug}`
                            )
                        }
                      >
                        <ArrowIcon className="w-3 h-3 hover:bg-hoverLightBlue " />
                        {item?.state_name
                          ? item?.state_name
                          : ""}
                      </span>

                    </div>
                  );
                })}
              {selectedRuleDrawerItem?.rule?.actions &&
                selectedRuleDrawerItem?.rule?.actions?.map((item: any, index) => {
                  return (
                    <div className="flex flex-col gap-2" key={index}>
                      <span className="flex items-center gap-2 cursor-pointer hover:bg-hoverLightBlue text-md text-textDark"
                        onClick={
                          () =>
                            navigate(
                              `/pathways/${item.pathway_slug}`
                            )
                        }
                      >
                        <PathwayIcon />
                        {item?.pathway_name || ''}
                      </span>
                      <span className="flex items-center justify-start gap-1 ml-6 text-sm cursor-pointer hover:bg-hoverLightBlue text-grayColor"
                        onClick={
                          () =>
                            navigate(
                              `/pathways/${item.pathway_slug}/state/${item.state_slug}`
                            )
                        }
                      >
                        <ArrowIcon className="w-3 h-3 hover:bg-hoverLightBlue " />
                        {item?.state_name
                          ? item?.state_name
                          : ""}
                      </span>

                      <div className="flex items-center text-sm cursor-pointer ml-9 hover:bg-hoverLightBlue text-grayColor" onClick={
                        () =>
                          navigate(`/action-templates/${item.action_type}/view/${item.action_id}`)

                      }>
                        <ArrowIcon className="w-3 h-3 mr-3 hover:bg-hoverLightBlue " />

                        <span className="w-5">
                          {ActionTemplateIcons[item.action_type as EnumActionType]}
                        </span>
                        <span className="mx-2 text-sm text-textDark">
                          {item?.text || item?.subject || item?.title || ''}
                        </span>
                      </div>



                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

ConditionsPage.displayName = "Conditions Page";

export default withAuth(ConditionsPage);
