import API from "../index";

export const getStateFlow = async (slug: string) => {
    const {data} = await API.get(`/admin/organizations/pathways/${slug}`);
    // const {data} = await API.get(`/admin/state-flows/${id}`);
    return data;
};






