import {useState} from "react";
import axios from "axios";
import {getToken} from "../utils/LocalStorage";

const UseUpload = (url: string) => {

    const [progress, setProgress] = useState(0);
    const [isLoading, setLoading] = useState(false);

    const uploadForm = async (formData: FormData) => {
        setLoading(true)
        try {
            const {data} = await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 50;
                    setProgress(progress);
                },
                onDownloadProgress: (progressEvent) => {
                    const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
              
                    setProgress(progress);
                },
            });
            setLoading(false)
            return data
            // Work with the response...
        } catch (err) {
            // Handle error
   
        }
    };

    return {uploadForm, progress, isLoading};
};

export default UseUpload