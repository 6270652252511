import React, {FC} from 'react';
import {ContCommon} from "../../../Common/Types";
import {MdAnnouncement} from "react-icons/md";

const AnnouncementPreview: FC<ContCommon> = (prop) => {
    return (
        <div className="whitespace-pre-wrap bg-gray-100 p-4 text-black shadow-md rounded-md relative mt-6">
            <div className="flex w-full justify-center items-center align-center">
                <div className="p-3 bg-organization-secondary absolute rounded-full text-white text-2xl shadow-md -top-5 left-auto right-auto">
                    <MdAnnouncement/>
                </div>
            </div>
            <div className="text-sm pt-10 pb-4 text-center" dangerouslySetInnerHTML={{ __html: prop.body || '' }} />
        </div>
    );
};

export default AnnouncementPreview;