import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";

// for the create and update the direction
// to create with direction content type id is going to be 0
export interface IDirection {
	id?: number
	title: string
	description: string
	address: string
	language: LanguageEnum
	updated_at?: string
}



export type IDirectionContentTemplate = {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	direction: IDirection[];
}

export type DirectionContentResponse = {
	data: IDirectionContentTemplate
	error: boolean
	message: string
	status: number
}



export type DirectionContentTemplate = {
	direction: IDirection
	content_metadata: IContentMetadata
}
export interface DirectionContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	direction: IDirection[],
	content_metadata: IContentMetadata[]
}

export const initialDirectionContent: DirectionContentTemplate = {
	direction: {
		id: 0,
		title: '',
		description: '',
		address: '',
		language: LanguageEnum.English
	},
	content_metadata: {
		id: 0,
		cover_image: '',
		preview_title: '',
		tags: [''],
		language: LanguageEnum.English,
		alt_title: '',
	}
}
