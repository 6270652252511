import React, { ChangeEvent, FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler, set, ChangeHandler } from "react-hook-form";
import Input from "../../General/Form/Input";
import Button from "../../General/Button";
import { AiFillFile } from "react-icons/ai";
import {
    article,
    Conditions_T,
    contents,
    ruleBody, ruleIds,
    Single_Content_Single,
    state_Edit_Article
} from "../../../Common/Types";
import ContentExtra from "../../Event/ContentExtra";
import UseUploadFile from "../../../Hook/UseUploadFile";
import { useMutation, useQueryClient } from "react-query";
import { uploadFile } from "../../../apiQuery/Common/Upload.api";
import Tabs from "../../TabsT/Tabs";
import Tab from "../../TabsT/Tab";
import UseTab from "../../../Hook/UseTab";
import { BsFillEyeFill } from "react-icons/bs";
import OverlayModal from "../../Layouts/OverlayModal";
import { createContentInState, updateContent } from "../../../apiQuery/Contents/Content.apis";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../../../GlobalState/store";
// import { setSnackbar } from "../../../GlobalState/store.actions";
import { updateArticle } from "../../../apiQuery/Contents/ContentType.apis";
import TextArea from "../../General/Form/TextArea";
import PreviewContentModal from "../ContentPreview/PreviewContentModal";
import ContentTypeCardPreview from "../ContentPreview/ContentTypeCardPreview";
import Mobile from "../ContentPreview/Mobile";
import { Controller } from "react-hook-form";
import WYSIWYGEditor from "../../General/Form/WYSIWYGEditor";
import { stripHtml } from "string-strip-html";
import {
    createTimeBaseEvent,
    deleteTimeCondition,
    updateTimeCondition
} from "../../../apiQuery/Condition/TimeBase.apis";
import { createContentRule } from "../../../apiQuery/Condition/Rule.apis";
// import { formatQuery } from "react-querybuilder";
import initialQuery from "../../../utils/InitialRules";
import jsonHandler from "../../Utils/JsonHandler";
import UseUpload from "../../../Hook/UseUpload";
import { baseURL } from "../../../apiQuery";
import UploadProgress from "../../General/UploadProgress";
import { Fade } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { toast } from "../../../utils/Toast";
import { EnumContentType } from '../../../Types/ContentTemplate/ContentType';
import { IArticle } from '../../../Types/ContentTemplate/Article';
import AppInput from '../../Shared/AppInput/AppInput';
// import AppTextArea from '../../Shared/AppTextArea/AppTextArea';
// import Condition from '../../Event/Condition';
import moment from "moment";
import AppButton from '../../Shared/AppButton/AppButton';
import { AppLoader } from '../../Shared/AppLoader';
import { getStateContentTemplateFilter } from '../../../apiQuery/ContentTemplate/StateContentTemplate.apis';
// import { IAttachTemplate, setATemplateToContentType } from '../../../apiQuery/ContentTemplate/ContentTemplate.apis';
import { CustomClipboard, AppButton as AppThemeButton } from '../../../Theme';
import { setAddContentTemplateModal } from '../../../GlobalState/store.actions';
import { LanguageEnum } from '../../../Types/ContentTemplate/Langauge';
import { Article } from '../../../@Core';


const ArticleNew: FC<state_Edit_Article> = memo(({ onClose, defaultValue, onSuccess, type }) => {
    const { t, i18n } = useTranslation();

    const queryClient = useQueryClient()
    const params = useParams();
    const id = params.stateSlug || ""
    const pathwaySlug = params.slug || ""

    const { state, dispatch } = useContext(store)
    const organization_id = useMemo(() => state.organization_id, [state.organization_id])


    // convert this to a useMemo: const isContentTemplate
    const isContentTemplate = useMemo(() => defaultValue?.template_id ? true : false, [defaultValue?.template_id])


    const {
        register,
        handleSubmit,
        setValue,
        watch,
        trigger,
        getValues,
        control,
        formState: { errors }
    } = useForm<article>({
        defaultValues: defaultValue ? defaultValue?.article : {}
    });




    const [altTitle, setAltTitle] = useState<string>("")


    const { uploadForm, progress, isLoading } = UseUpload(
        `${baseURL}upload`
    );

    //@ts-ignore
    const timeValue = parseInt(getValues("timeValue")) as number

    //@ts-ignore
    const eventName = getValues("eventName") as string

    //@ts-ignore
    const typeOf = getValues("typeOf") as string

    //@ts-ignore
    const checkInValue = getValues("checkInValue") as string

    //@ts-ignore
    const target_func = getValues("target_func") || undefined as string


    const [ruleId, setRuleId] = useState<ruleIds>({
        event: undefined,
        time: undefined
    });

    const [query, setQuery] = useState(initialQuery);

    const [timeEvent, setTimeEvent] = useState({
        isAdded: false,
        isRuleAdded: false
    })
    const [passedContent, setPassedContent] = useState({
        targetField: '',
        targetFunction: '',
    })

    // mutations

    const { data: articleTemplateData, isLoading: articleContentTempalteIsLoading, isError: articleTemplateIsError, error: arrtilceTemplateError, mutate } = useMutation(
        (pageDirection: 'next' | 'previous' | '') => getStateContentTemplateFilter<IArticle, EnumContentType.ArticleContent>
            (state.currentFlow.slug, id, EnumContentType.ArticleContent, 8, '', nextPage, previousPage, pageDirection), {
        onSuccess(data) {
            if (data?.data) {
                const { next, previous } = data?.data.paginate
                setNextPage(next)
                setPreviousPage(previous)
            }
        },
    });

    useEffect(() => {
        mutate('')
    }, [mutate]);

    const flattedArticleTemplateOptions = useMemo(() => {
        // const currentLanguage = i18n.language;

        const articleOptions = articleTemplateData && articleTemplateData.data.data.map((item) => {
            const { id } = item;
            return item.article.map((article) => {
                return {
                    template_id: id,
                    article_id: article.id,
                    title: article.title,
                    description: article.description,
                    updated_at: article.updated_at,
                    metaData: item.content_metadata,
                    language: article.language
                }
            })
        }).flatMap((item) => item).filter(item => item.language === LanguageEnum.English);
        return articleOptions;
    }, [articleTemplateData]);





    // ==========================================================================


    const createRuleTimePassedCondition = useMutation(({ targetField, targetFunction }: any) => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, targetFunction, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func: targetFunction, target_field: targetField })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore
            // const message = error?.response?.data?.message || "Failed creating content Rule"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            toast(message, 'error')
        }
    })

    const handleUpload = useMutation((formData: any) => uploadFile(formData), {})

    const [imageSrc, file, render, name] = UseUploadFile('Article')
    const [modal, setModal] = useState({
        pathwayView: false,
        contentView: false
    })

    const createTimeEvent = useMutation((timeData: Conditions_T) => createTimeBaseEvent(organization_id, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        },
        onError: (error: null | Error) => {
            // const message = error?.message || "Failed creating Time base Rule"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.message || `${t('content.create_time_base_rule_failed')}`
            toast(message, 'error')
        }
    })

    const deleteCondition = useMutation((delId: number) => deleteTimeCondition(organization_id, delId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        }
    })

    const updateTimeEvent = useMutation((timeData: Conditions_T) => updateTimeCondition(organization_id, timeData.id || 0, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }))

    const createRuleCondition = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        // const target_func = getValues("target_func") as string
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore
            // const message = error?.response?.data?.message || "Failed creating content Rule"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            toast(message, 'error')
        }
    })

    const UpDateFunc = async (createdData: contents) => {
        if (ruleId.event === 0) {
            let ruleId = null
            if (query.rules.length !== 0) {

                // const { data } = await createRuleCondition.mutateAsync(
                //     //     {
                //     //     organization_id: organization_id,
                //     //     ...jsonHandler(timeValue, typeOf, eventName, query, target_func)
                //     // }
                // )
                // ruleId = data?.id || null
                ruleId = await createRuleConditionForContent()
            }

            await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId })
        } else await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId.event || null })
        onClose()
    }

    const createTimeRule = async (ID: number | undefined) => {
        const typeIs = typeOf === 'days' ? 'hour' : typeOf
        await createTimeEvent.mutateAsync({
            deliver_time: timeValue ? timeValue : 5,
            deliver_unit: typeIs || "minute",
            state_id: parseInt(defaultValue.state_id),
            // state_id: parseInt(id),
            content_id: ID
        })
    }

    const createContents = useMutation((newContent: contents) => createContentInState(organization_id, pathwaySlug, id, { ...newContent }), {
        onSuccess: async (createdData: Single_Content_Single) => {
            if (timeEvent.isAdded) {
                await createTimeRule(createdData.data?.id)
            }

            toast(`${t('content.created')}`, 'success')
            !!onSuccess && onSuccess()
            // onClose()
        },
        onError: (error: null | Error) => {

            const message = error?.message || `${t('content.failed')}`
            toast(message, 'error')
        }
    })

    const updateContentMutation = useMutation((updateContentDetails: contents) => updateContent(organization_id, pathwaySlug, id, { ...updateContentDetails }, updateContentDetails.slug || ''), {
        onSuccess: async (data: Single_Content_Single) => {
            if (defaultValue) {
                if (data?.data?.article) {
                    await updateContentArticle.mutateAsync({
                        ...data?.data?.article
                    })
                }
                toast(`${t('content.updated')}`, 'success')
                !!onSuccess && onSuccess()
                await queryClient.invalidateQueries(['content', defaultValue.id])
                onClose()
            }
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const updateContentArticle = useMutation((articleDetails: article) => updateArticle(organization_id, { ...articleDetails }, articleDetails?.id || 0), {
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const [tab, setTab] = UseTab('Article Details')

    const handlePathwayView = useCallback(() => {
        setModal(prev => ({ ...prev, pathwayView: !prev.pathwayView }))
    }, [])

    const handleContentView = useCallback(() => {
        setModal(prev => ({ ...prev, contentView: !prev.contentView }))
    }, [])


    async function createRuleConditionForContent() {
        let data = null
        if (passedContent.targetField && passedContent.targetFunction) {

            const resultCondition = await createRuleTimePassedCondition.mutateAsync(
                {
                    targetField: passedContent.targetField,
                    targetFunction: passedContent.targetFunction
                }
            )
            data = resultCondition?.data
        } else {
            const resultRule = await createRuleCondition.mutateAsync()
            data = resultRule?.data
        }
        return data?.id || null
    }


    // ================ handle the submitted form ========
    const onSubmit: SubmitHandler<article> = async data => {
        await trigger()


        let fileUpload = {
            link: ''
        }
        if (file !== undefined) {
            const formData = new FormData();
            // @ts-ignore
            formData.append("file", file);
            const { link } = await uploadForm(formData)
            fileUpload.link = link
        }

        // isContentTemplate ?  contentPriority : Number(data.priority),

        let shapeData;


        if (isContentTemplate) {

     

            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "article",
                // cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                priority: contentPriority,
                // tags: data?.tags ? [data.tags] : [],
                // preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                // article: {
                //     title: data.title,
                //     description: data?.description || "",
                //     body: data.body || "",
                //     owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                // },
            }
           

        } else {
            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "article",
                cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                priority: contentPriority,
                tags: data?.tags ? [data.tags] : [],
                preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                article: {
                    title: data.title,
                    description: data?.description || "",
                    body: data.body || "",
                    owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                },
            }
        }

        if (type === 'update') {
            const timeId = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined

            if (timeEvent.isAdded) {
                if (defaultValue?.time_condition && timeId) {
                    await updateTimeEvent.mutateAsync({
                        id: timeId,
                        deliver_time: timeValue ? timeValue : 5,
                        deliver_unit: typeOf || "minute",
                        state_id: parseInt(defaultValue.state_id),
                    })
                } else await createTimeRule(defaultValue.id)
            }
            if (!timeEvent.isAdded && defaultValue?.is_time_base) {
                if (id) await deleteCondition.mutateAsync(timeId)
            }

  

            await UpDateFunc(shapeData)
            onClose()
        }
    };

    const body = getValues("body");
    const title = getValues("title");


    useEffect(() => {
        if (defaultValue) {
            const id = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined
            setRuleId({ time: id, event: defaultValue?.rules_content_id })

            if (defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.deliver_time) {
                //@ts-ignore
                setValue("timeValue", defaultValue?.time_condition[0]?.deliver_time)  //@ts-ignore
                setValue("typeOf", defaultValue?.time_condition[0]?.deliver_unit)
            }
            setContentPriority(defaultValue?.priority)

            setAltTitle(defaultValue?.alt_title)
        }
    }, [])

    const [searchContentTemplate, setSearchContentTemplate] = useState('')

    const search_result = useMemo(() => {
        if (searchContentTemplate === '') return flattedArticleTemplateOptions
        return flattedArticleTemplateOptions &&
            flattedArticleTemplateOptions.filter(item => item.title.toLowerCase().includes(searchContentTemplate.toLowerCase()) ||
                item.description.toLowerCase().includes(searchContentTemplate.toLowerCase()))
    }, [flattedArticleTemplateOptions, searchContentTemplate])




    // =================== Create content template =========================
    const addContentTemplate = async (item: any) => {



        const obj = {
            template_id: item.template_id as  number,
            state_slug: id as string,
            pathway_slug: pathwaySlug as string,
            is_template: true,
            content_type: EnumContentType.ArticleContent,
        }

        dispatch(setAddContentTemplateModal(obj))
        onClose()
    }


    // ================== content template ========================

    const [previousPage, setPreviousPage] = useState<string>();
    const [nextPage, setNextPage] = useState<string>();
    const [contentPriority, setContentPriority] = useState<number>(0);

    const onUpdateContentFields = async (priority:number, alternativeTitle:string) => {
        const shapeData  = {
            ...defaultValue,
            priority: priority,
            alt_title: alternativeTitle
        }
        await updateContentMutation.mutateAsync(shapeData)
    }

   
    const contentSlug = defaultValue?.content_template?.article[0]?.slug || defaultValue?.slug
    
    
    return (
        <div className="flex items-center w-full">

        
            <div className="flex flex-col w-full gap-4">
                    {
                    contentSlug ? 
                    <CustomClipboard showInfoIcon clipboardText={contentSlug } />
                    : null 
                    }
                    {
                        type === 'update' && isContentTemplate === true ? <>
                            <Article contentTemplate={defaultValue?.content_template} 
                            priority={contentPriority} 
                            alternativeTitle={altTitle}
                            onUpdateFields={onUpdateContentFields} />
                        </> : null
                    }
                <form onSubmit={handleSubmit(onSubmit)}
                                className='w-full h-full space-y-3 overflow-auto text-gray-600 text-md'>
                                    
                    {type === "create" ?
                        <>
                            {/* ======================================== CREATE:  choose between content template   ======================================================= */}
                            <AppInput placeholder='Search' value={searchContentTemplate} onChange={e => setSearchContentTemplate(e.target.value)} name='searchTemplate' />
                            <div className='flex items-center justify-center'>
                                {articleContentTempalteIsLoading ? <AppLoader isLoading size={35} /> : null}
                            </div>
                            <div className='grid grid-cols-2 gap-1'>
                                {search_result && search_result.map((item, index) => {
                                    return <div className='flex flex-col items-start justify-start p-4 m-1 transition-all delay-75 rounded-md shadow-sm bg-gray-50 hover:bg-white' key={index}>
                                        <span
                                            onClick={() => addContentTemplate(item)}
                                            className='px-2 text-xs border rounded hover:cursor-pointer hover:bg-sky-100 border-sky-500 bg-sky-50 text-sky-700'>Add +</span>
                                        <p className='my-1 font-medium text-md'>{item.title}</p>
                                        <p className='flex-1 mb-3 text-xs'>
                                            {item.description}
                                        </p>
                                        <p className='my-1 text-xs'>
                                        {/* @ts-ignore */}
                                        Languages: <span className='font-medium capitalize text-sky-700'>{item.metaData?.map((item: any) => item.language).join(', ')}</span>
                                        </p>
                                        {item.updated_at ? <p className="mt-1 text-xs">Last updated {moment(item.updated_at).fromNow() + '. on ' + moment(item.updated_at).format('MMM D, YYYY')
                                        }</p> : null}
                                    </div>
                                })}
                            </div>

                            <div className="flex">
                                <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
                                    onClick={() => {
                                        // activateSearch.current = true
                                        mutate('previous')
                                    }}
                                    extendClass="mx-1">Previous</AppButton>
                                <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
                                    onClick={() => {
                                        mutate('next')
                                        //    activateSearch.current = true
                                    }}
                                    extendClass="mx-1">Next</AppButton>
                            </div>
                        </> : null}

                    {
                        //  ========================== UPDATE:  OLD Content   ====================================
                        type === 'update' && isContentTemplate === false ? <>


                            <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab TabKey='Article Details' count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView()
                                    e.stopPropagation()
                                }} />} title={`${t('Content.Article_Details')}`}> {`${t('Content.Article_Details')}`} </Tab>
                                <Tab TabKey='Content Details' count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView()
                                    e.stopPropagation()
                                }} />} title={`${t('Content.Content_Details')}`}>`${t('Content.Content_Details')}` </Tab>
                            </Tabs>


                            {/*---------------------------------------------------------*/}

                            {/*Article Details tab with form inputs*/}

                            <Fade in={tab === "Article Details"}
                                style={{ display: tab === "Article Details" ? '' : 'none' }}>


                                <div className="space-y-4">
                                    <Input
                                        register={() => register("title", { required: 'Title is required' })}
                                        name="title"
                                        placeholder={`${t('Content.Article_Title')}`}
                                        errors={errors}
                                        autoFocus />
                                    {
                                        //to stop textarea causing too many re-renders
                                        tab === "Article Details" &&
                                        <TextArea
                                            register={() => register("description")}
                                            name="description"
                                            placeholder={`${t('Content.Article_Description')}`}
                                            errors={errors} />
                                    }

                                    <Controller
                                        render={({ field }) => <WYSIWYGEditor {...field} placeholder={`${t('Content.Article_Body')}`} />}
                                        name="body"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            validate: {
                                                required: (v) =>
                                                    (v && stripHtml(v).result.length > 0) ||
                                                    `${t('Content.Article_Body')} ${t('Content.is_required')}`,
                                                maxLength: (v) =>
                                                    (v && stripHtml(v).result.length <= 3000) ||
                                                    `${t('Content.Maximum_3000')}`,
                                            },
                                        }}
                                    />

                                    <p className="text-red-500">{errors.body && <p>{errors.body.message}</p>}</p>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }

                                </div>


                            </Fade>


                            {/*---------------------------------------------------------*/}
                            {/*Content Details tab with form inputs*/}
                            <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div>
                                    {/* <h1>this is here.</h1> */}
                                    {/* ===== */}

                                    <div>
                                        <label htmlFor='alt_title' className='mb-1 ml-1 text-sm'>Alternative Title</label>
                                        <input
                                            id="alt_title"
                                            type="text"
                                            className="block w-full p-4 text-gray-900 border border-gray-300 rounded-md text-md focus:ring-blue-500 focus:border-blue-500"
                                            value={altTitle}
                                            maxLength={30}
                                            onChange={e => {
                                                setAltTitle(prev => {
                                                    return e.target.value
                                                })
                                            }}
                                            placeholder='Alternative Title'
                                        />
                                    </div>

                                    {/* ======= */}

                                    <ContentExtra typeOf={typeOf} query={query} setQuery={setQuery}
                                        prevTitle={watch("preview_title")}
                                        rule={ruleId} setRule={setRuleId} name={name} defaultValue={defaultValue}
                                        register={register} errors={errors}
                                        render={render}
                                        setTimeEvent={setTimeEvent}
                                        setPassedContent={setPassedContent} />


                                    {/*                    {*/}
                                    {/*                        (ruleId.event === 0) ?*/}
                                    {/*                            <>*/}
                                    {/*                                <h4>Query</h4>*/}
                                    {/*                                <pre>*/}
                                    {/*  <code>{formatQuery(query, 'json')}</code>*/}
                                    {/*</pre>*/}
                                    {/*                            </> : null*/}
                                    {/*                    }*/}
                                </div>
                            </Fade>

                            {/*Submit form to update old Article's content */}
                            <Button
                                disabled={handleUpload.isLoading || createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || updateTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading || createRuleCondition.isLoading}
                                type="submit" label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>{type === 'update' ? `${t('Content.Update_Content')}` : `${t('Content.Create_Content')}`}</p>

                                    </div>
                                } extraClasses="w-fit px-3 py-3" />
                        </> : null
                    }

                </form>
            </div>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for Checklist Page*/}
            <OverlayModal onClose={handlePathwayView} isOpen={modal.pathwayView}>
                <div className="relative flex p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md h-fit w-fit">
                    <Mobile>
                        <PreviewContentModal description={getValues("description")} tags={[getValues("tags") || ""]}
                            type={'article'}
                            title={title} body={body || ""}
                            image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""}
                        />
                    </Mobile>
                </div>
            </OverlayModal>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for Checklist Content Card*/}
            <OverlayModal onClose={handleContentView} isOpen={modal.contentView}>
                <div
                    className="h-fit overflow-auto bg-gray-100 p-2 rounded-md flex justify-center text-gray-600 w-[40%] relative">
                    <ContentTypeCardPreview
                        type={'article'}
                        image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""}
                        tags={[getValues("tags") || ""] || ['']}
                        title={getValues("preview_title") || defaultValue?.preview_title || ""}
                    />
                </div>
            </OverlayModal>
        </div>
    );
});


ArticleNew.displayName = 'ArticleNew';


export default ArticleNew;
