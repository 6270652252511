import React, { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useRef, useState } from 'react'
import { TabActionLayout } from "../TabActionLayout";
import { LanguageEnum, IActionTemplate } from '../../../../Types/ActionTemplate/ActionTemplate';
import { useTranslation } from "react-i18next";
import { LanguagesOptions, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumActionType } from "../../../../Types/ActionTemplate/ActionTemplate";
import { IActionMetadata } from "../../../../Types/ActionTemplate/ActionTemplate";
import { useMutation, useQuery } from "react-query";
import {  deleteActionTemplateById, getActionTemplateById, updateActionTemplateById } from "../../../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import AllSMSActions from "./AllSmsAction";
import SMSItem from "./SmsItem";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import ViewActionTemplateHeader from '../../ViewActionTemplateHeader';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import { useNavigate } from 'react-router-dom';
export type Language = "en" | "fr" | "es"

export interface SMSAction {
	text: string
	language: Language
	importance: number
}

interface SMSActionCreateOrUpdateRequest {
	action_type: EnumActionType,
	action_metadata: IActionMetadata[],
	importance: number
}

const initialSMSAction: SMSAction = {
	text: '',
	language: "en",
	importance: 0
}
interface SMSTabActionProps {
	templateId: number,
}
const SMSTabAction: FC<SMSTabActionProps> = (props) => {

	const { currentOrganizationID } = useContext(UserContext);
	const navigate = useNavigate();


	const { t } = useTranslation(); // TODO: use this for language

	const [state, setState] = useState<SMSAction[]>([])


	const [smsAction, setSMSAction] = useState<SMSAction>(initialSMSAction)


	// const [currentSMSActionId, setCurrentSMSActionId] = useState<number>(0);

	const createOrUpdateButton = useRef<string>("Create")
	const allSMSActionsRef = useRef(null)
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')


	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);



	// ========================================================================================================================

	const { data: smsData, refetch } = useQuery<any, Error>
		(`sms-content-${props.templateId}`, () => getActionTemplateById(props.templateId));


	const deleteActionTemplateByID = useMutation((params: { id: number }) =>
	deleteActionTemplateById(params.id), {


	onSuccess: async (data) => {
		toast("Template deleted successfully", "success")

	},
	onError: (error: any) => {
		const message = error?.response?.data?.message || "Something went wrong!"
		toast(message, "error")
	}
	})



	// === ============================================================		
	useEffect(() => {
		if (smsData) {
			const newState = smsData?.data.action_metadata.map((item: any) => {
				return {
					text: item.text,
					language: item.language as Language
				}
			})
			setState(newState)
		}
	}, [smsData])

	useEffect(() => {
		if (selectedLanguage === '') return
		// get seleted index of the content template to be displayed as the current content
		const selectedIndex = smsData?.data.action_metadata.findIndex((item: any) => item.language === selectedLanguage) as number
		const sms = smsData?.data.action_metadata[selectedIndex]
		setSMSAction({
			text: sms.text,
			language: sms.language as Language,
			importance: smsData?.data.importance
		})
		createOrUpdateButton.current = "Update"
	}, [selectedLanguage])

	const deleteTemplateFromContentTemplateList = async () => {
		deleteActionTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/action-templates/sms', { state: { deleted: true } })
	}

	//=====================================================
	//make english default language for content
	useEffect(() => {
		const sms = smsData?.data?.action_metadata.find((item: any) => item.language === "en")
		if (sms) {
			setSMSAction({
				language: LanguageEnum.English,
				text: sms.text,
				importance: smsData?.data.importance

			})
			createOrUpdateButton.current = "Update"
			setSelectedLanguage("en")
		}
	}, [smsData])

	// ================================================== Mutations =========================================================	s
	const updateSMSActionMutation = useMutation(
		(params: { id: number, data: SMSActionCreateOrUpdateRequest }) => updateActionTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Action updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	// ======================================================== Handlers =========================================================
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setSMSAction(prev => ({ ...prev, [name]: value }))
	}

	// delete and edit
	const deleteSMSActionHandler = (language: Language) => {
		setState(prev => prev.filter(item => item.language !== language))
	}

	const editSMSActionHandler = (language: Language) => {
		const item = state.find(item => item.language === language)
		if (item) {
			setSMSAction(prev => {
				return { ...prev, text: item.text, language: language, importance: item.importance }
			})
			createOrUpdateButton.current = "Update"
		}
	}


	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		//  tODO: validation goes here
		if (smsAction.text === '') {
			return
		}

		if (createOrUpdateButton.current === "Create") {
			// Todo : check if the language is already exist
			if (state.some(item => item.language === smsAction.language)) {
				toast('This sms is already exist', 'error')
				return
			}

			// setState(prev => [...prev, smsAction])
			const newState = [...state, smsAction]

			await createOrUpdateContentTemplate(newState)


		} else {
			// update the state
			const newState = state.map(item => {
				if (item.language === smsAction.language) {
					return smsAction
				}
				return item
			})

			setState(newState)
			await createOrUpdateContentTemplate(newState)

			createOrUpdateButton.current = "Create"
		}

		// 	empty the fields
		setSMSAction({

			text: '',
			language: 'en',
			importance: 0
		})

	}

	const createOrUpdateContentTemplate = async (data: any) => {
		if (data.length === 0) {
			toast('Please add at least one sms template', 'info')
			return
		}

		const action_metadata: IActionMetadata[] = data.map((item: any) => {
			return {
				...item.action_metadata,
				organization_id: currentOrganizationID(),
				language: item.language as Language,
				text: item.text
			}
		})
		const shapeData: SMSActionCreateOrUpdateRequest = {
			action_type: EnumActionType.SMSAction,
			action_metadata: action_metadata,
			importance: smsAction.importance
		}


		await updateSMSActionMutation.mutateAsync({ id: props.templateId, data: shapeData })

		// save a template
		// if (currentSMSActionId === 0) {
		// 	await createSMSActionMutation.mutateAsync(shapeData)
		// } else if (currentSMSActionId > 0) {
		// 	// update a template
		// 	// alert('update')
		// 	await updateSMSActionMutation.mutateAsync({ id: props.templateId, data: shapeData })
		// }
		// await queryClient.invalidateQueries();
		// setCurrentSMSActionId(0)
		setState([])
		// @ts-ignore
		// allSMSActionsRef.current?.refresh()
		setSelectedLanguage('')
	}


	const editableSMSAction = (smsAction: IActionTemplate) => {
		const items: SMSAction[] = smsAction.action_metadata.map((item: any) => {
			return {
				text: item.text,
				language: item.language as Language,
				importance: item.importance
			}
		})
		setState(items)
		// setCurrentSMSActionId(smsAction.id as number)
	}


	return (
		<Fragment>

		
		<TabActionLayout name="sms">
			<div className="hidden px-5 py-2 my-2">
				<AllSMSActions
					ref={allSMSActionsRef}
					onEditClick={editableSMSAction} />
				<hr className="my-2 border border-blue-800" />
				{state.length > 0 ? (
					<div className="p-3 rounded bg-blue-50">

						<div>
							<AppButton type="button" size="sm" variant='secondary' extendClass="my-2" onClick={() => {
								setState([]);
								setSMSAction(initialSMSAction)
								createOrUpdateButton.current = "Create"
							}}>close</AppButton>
							<div className="grid grid-cols-3 gap-2">
								{state.map((item: SMSAction, idx: number) => {
									return (
										<SMSItem
											key={idx}
											item={item}
											onDeleteClick={deleteSMSActionHandler}
											onEditClick={editSMSActionHandler}
										/>
									)
								})}
							</div>
						</div>
					</div>
				) : null}
			</div>
			<ViewActionTemplateHeader
				title={smsData?.data?.action_metadata[0]?.text || ''}
				referencesCount={0}
			/>
			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{

					smsData?.data.action_metadata.map((item: any, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setSMSAction(initialSMSAction)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										editableSMSAction(smsData.data)
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					state.length < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setSMSAction(initialSMSAction)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								+ New Language
							</span>

						</AppButton>
					) : null
				}
			</div>



			{/* =========================== */}
			<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{smsAction.text || ''}</h1>
			<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">
				<div className='flex w-full gap-2 mb-3'>
					<AppButton size="xs" disabled={smsAction.text === '' || updateSMSActionMutation.isLoading || deleteActionTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Action Template</AppButton>
					<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>Delete Action Template</AppButton>
				</div>
				<AppInput autoFocus placeholder={"Text"} value={smsAction.text} name='text' isFull onChange={handleChange} label='Text' required />
				<AppDropDown
					extendClass="mt-1.5"
					isFull
					label="Importance"
					name="time"
					options={[
						{ label: "Standard", value: "0" },
						{ label: "Priority", value: "1" },
						{ label: "Urgent", value: "2" },
					]}
					value={smsAction.importance.toString()}
					onChange={
						(e) => {
							setSMSAction(prev => {
								return {
									...prev,
									importance: parseInt(e.target.value)
								}
							})
						}

					}
				/>

				<AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"} options={LanguagesOptions} value={smsAction.language} name="language" onChange={handleChange} />
			</form>
		</TabActionLayout>


		<PopupModal
			setOpen={setShowPopupDeleteConfirmation}
			isOpen={showPopupDeleteConfirmation}
			title="Are you sure you want to delete this SMS Action Template ?
			Deleting this Action Template will delete all Actions associated with this Template."
			onConfirmClick={deleteTemplateFromContentTemplateList}
		/>

		</Fragment>
	)
}


export default SMSTabAction
