import { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { IRule, IRuleResponse } from "../../../Types/Rules/Rules";
import { ArrowSubIcon } from "../../../Theme";
import { SelectedModalEnum } from "./Rule.types";
import { useInfiniteQuery } from "react-query";
import { getAllRule } from "../../../apiQuery/Condition/Rule.apis";
import { AppLoader } from "../../Shared/AppLoader";
import { useInView } from "react-intersection-observer";
import { RulesFilter } from "../../../Types/Rules/RulesFilter";
export interface IStateConditionProps {
  onDeleteContentRules: (selectedRule: number, type: SelectedModalEnum) => void;
  onOpenDrawer: (selectedRule: IRule, type: SelectedModalEnum) => void
  activeQuery: boolean
  organizationId: number
  allowRefetch: boolean
  filterKey: RulesFilter
}

//* Start component 
export const StateCondition: FC<IStateConditionProps> = (props) => {

  const fetchStateRules = ({ pageParam = "" }) => getAllRule(props.organizationId, pageParam, props?.filterKey);

  //! Queries
  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<IRuleResponse, Error>(['state-rules', props?.filterKey?.filter?.name], //! More appropriate index should be used for the query key
    fetchStateRules, {
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.data) {
        if (lastPage.paginate.next === "") return undefined;
        return lastPage.paginate.next;
      }
    },
    enabled: props.activeQuery || props.allowRefetch,
  });

  /// ============= Handlers
  const setCheckBoxHandler = async (item: IRule) => {
    props.onDeleteContentRules(item.id, SelectedModalEnum.state);
  };

  const setOpenDrawerHandler = (item: IRule) => {
    props.onOpenDrawer(item, SelectedModalEnum.state)
  }

  useEffect(() => {
    if(props.allowRefetch) 
    refetch()
  },[props.allowRefetch])

 /// pagination

 const { ref, inView } = useInView();

 useEffect(() => {
   if (inView) {
     fetchNextPage();
   }
 }, [inView]);


  return (
    <Fragment>
            {data?.pages?.map?.((infinitePage, i) => {
            return (
              <Fragment key={i}>
                {infinitePage?.data?.map((item, index: number) => {

                  return (
                    <tr
                    key={item.id}
                    className="transition-all delay-75 bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer "
                    >
                    <th
                      scope="row"
                      className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                    >
                      <div className="flex items-center justify-start">
                        <input
                          type="checkbox"
                          onClick={() => setCheckBoxHandler(item)}
                          className="checkbox-worker custom__checkbox"
                        />
                        <div className="flex flex-col items-start justify-center p-2"
                          role="button" onClick={() => setOpenDrawerHandler(item)}
                        >
                          <p className="mb-1 ml-1 text-lg whitespace-nowrap text-textDark dark:text-white">
                            {item?.rule_name.replaceAll('_', ' ')}
                          </p>
                        </div>
                      </div>
                    </th>
                    
                    <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                      <div className="flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 96 960 960"
                          aria-hidden="true"
                          className={"h-4 w-4 text-grayColor"}
                        >
                          <path
                            fill="currentColor"
                            d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
                          ></path>
                        </svg>
                        <span className="text-lg text-textDark">State</span>
                      </div>
                    </td>
                    
                    <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                      <div className="flex items-center gap-2">
                        <ArrowSubIcon />
                        <span className="text-lg text-textDark">{item?.pathway_states?.length || 0} References</span>
                      </div>
                    </td>
                    
                    <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                      <div className="flex items-center gap-2">
                        <ArrowSubIcon />
                        <span className="text-lg text-textDark">
                          No Display Condition
                        </span>
                      </div>
                    </td>
                    </tr>
                  );
                })}
              <tr ref={ref}></tr>
              </Fragment>
            );
          })}
        <tr>
        <td colSpan={4} className="mx-auto text-center ">
          <p className="inline-flex text-center">
          {hasNextPage  && (<AppLoader isLoading size={30} />)}
          </p>
        </td>
				</tr>
    </Fragment>
  );
};


