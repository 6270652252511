import { FC, useContext, useEffect, useReducer, useRef, useState } from "react";
import { TabContentLayout } from "../TabContentLayout";
import { ISelectorItem, IVariant, SelectorContentCreateOrUpdateRequest, SelectorContentTemplate, initialSelectorContent } from "../../../../Types/ContentTemplate/Selector";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import NewUseUploadFile from "../../../../Hook/NewUseUploadFile";
import UseUpload from "../../../../Hook/UseUpload";
import { baseURL } from "../../../../apiQuery";
import UploadProgress from "../../../General/UploadProgress";
import { toast } from "../../../../utils/Toast";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { createContentTemplate } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { useNavigate, useParams } from "react-router-dom";
import NestedContentListItem from "../../../Content/NestedContentListItem";
import { AiFillDelete } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { v4 as uuidv4 } from 'uuid'
import InlineSelectorVariant from "./InlineSelectorVariant"
import InlineSelectorItem from "./InlineSelectorItem"
import { useTranslation } from "react-i18next";

const initialVariant = {
	id: uuidv4(),
	content: ''
}

const NewSelector: FC = () => {
	const params = useParams<{ templateSlug: string }>()
	const navigate = useNavigate();

	const { t } = useTranslation();

	const { currentOrganizationID } = useContext(UserContext);

	const [selectorContent, setSelectorContent] = useState<SelectorContentTemplate>(initialSelectorContent)
	const [variants, setVariants] = useState<IVariant[]>([])
	const [showInput, setShowInput] = useState({ id: null, isInput: false });
	const [variant, setVariant] = useState<IVariant>(initialVariant)
	const [selectorItems, setSelectorItems] = useState<ISelectorItem[]>([])
	const [selectorItem, setSelectorItem] = useState<ISelectorItem>({
		id: 0,
		description: '',
		appId: uuidv4(),
		title: '',
		language: LanguageEnum.English,
		image: ''
	})



	const createOrUpdateVariantButton = useRef<string>("Add")
	const createOrUpdateSelectorItemButton = useRef<string>("Add")


	//=====================  Upload Cover  ========================
	// upload conver image
	const [src, selectorCoverImageFile, renderedTag, selectorCoverFileName] = NewUseUploadFile('Selector', selectorContent?.content_metadata?.cover_image)



	const [uploadState, setUploadState] = useState({
		selectedFile: selectorCoverImageFile,
		fileName: selectorCoverFileName,
	})

	useEffect(() => {
		if (selectorCoverFileName && selectorCoverImageFile) {
			setUploadState({
				selectedFile: selectorCoverImageFile,
				fileName: selectorCoverFileName,
			})
		}
	}, [selectorCoverImageFile, selectorCoverFileName])



	const [selectorImageSrc, selectorItemFile, selectorItemRender, selectorItemName, setItemCoverImageSrc] = NewUseUploadFile('Item')
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);




	const [uploadStateItem, setUploadStateItem] = useState({
		file: selectorItemFile,
		name: selectorItemName,
	})

	useEffect(() => {
		if (selectorItemName && selectorItemFile) {
			setUploadStateItem({
				file: selectorItemFile,
				name: selectorItemName,
			})
		}
	}, [selectorItemName, selectorItemFile])
	// ============================ Mutation ================================

	const createSelectorContentMutation = useMutation((param: SelectorContentCreateOrUpdateRequest) => createContentTemplate(param), {
		onSuccess: async (data) => {
			toast("Content created successfully", "success")
			const id = data?.data?.id
			if (id) {
				navigate(`/content-templates/${params.templateSlug}/view/${id}`)
			}
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	// ======================  Submit Selector  ========================
	const submitFormHandler = async (event: React.FormEvent) => {
		event.preventDefault();

		if (selectorContent.selector.title === '') {
			toast('Please enter a title', 'error')
			return
		}


		let _cover_imageLink = ''

		if (uploadState.selectedFile !== undefined) {
			const formData = new FormData();
			// @ts-ignore
			formData.append("file", uploadState.selectedFile);
			const { link } = await uploadForm(formData)
			_cover_imageLink = link
		}

		const selectorContentWithImage: SelectorContentTemplate = {
			selector: {
				...selectorContent.selector,
				variants: variants,
				items: selectorItems
			},
			content_metadata: {
				...selectorContent.content_metadata,
				cover_image: _cover_imageLink,
			}
		}

		const newState = [selectorContentWithImage]

		const shapeData: SelectorContentCreateOrUpdateRequest = {
			content_type: EnumContentType.SelectorContent,
			selector: newState.map(item => {
				return {
					id: 0,
					title: item.selector.title,
					description: item.selector.description,
					items: item.selector.items,
					variants: item.selector.variants.map(i => i.content),
					language: item.selector.language as LanguageEnum,
				}
			}),
			content_metadata: newState.map(item => {
				return {
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.content_metadata.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}

		await createSelectorContentMutation.mutateAsync(shapeData)
	}

	// ======================  Handlers  ========================

	const addItemToItems = async (event: any) => {
		event.preventDefault()
		event.stopPropagation()
		if (selectorItem.title === '') {
			toast('Please fill add a item title', 'info')
			return
		}

		let itemImageLink = ''


		if (uploadStateItem.file !== undefined) {
			const formData = new FormData();
			// @ts-ignore
			formData.append("file", uploadStateItem.file);
			const { link } = await uploadForm(formData)
			if (link) {
				setItemCoverImageSrc(null)
			}
			itemImageLink = link
		}

		const newSelectorItem: ISelectorItem = {
			id: 0,
			language: selectorItem.language, title: selectorItem.title, description: selectorItem.description, image: itemImageLink, appId: uuidv4()
		}
		setSelectorItems(prev => [...prev, newSelectorItem])


		setSelectorItem({
			id: 0,
			appId: uuidv4(),
			description: '',
			title: '',
			language: LanguageEnum.English,
			image: ''
		})

		setUploadStateItem({
			file: undefined,
			name: '',
		})

	}



	return (
		<TabContentLayout>
			<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
				<h1 className="pb-8 text-xl">New Selector Content Template</h1>

				<h1 className="mt-5 mb-2 ml-1 text-xl font-normal ppb-8 text-textDark">
					{selectorContent.selector.title || ''}
				</h1>

				<AppInput
					tabIndex={-1}
					autoFocus
					placeholder={'Title'}
					value={selectorContent.selector.title}
					name="title"
					isFull
					label="Title"
					required
					onChange={(e) =>
						setSelectorContent({
							...selectorContent,
							selector: { ...selectorContent.selector, title: e.target.value },
						})
					}
				/>
				<AppTextArea
					placeholder={'Description'}
					rows={4}
					value={selectorContent.selector.description}
					name="description"
					onChange={(e) =>
						setSelectorContent({
							...selectorContent,
							selector: { ...selectorContent.selector, description: e.target.value },
						})
					}
					label="Description"
				/>

				{/* <AppInput
					placeholder={'Alternative Title'}
					value={selectorContent.content_metadata.alt_title}
					name="alt_title"
					isFull
					onChange={(e) => {
						setSelectorContent({
							...selectorContent,
							content_metadata: {
								...selectorContent.content_metadata,
								alt_title: e.target.value,
							},
						})
					}}
					label="Alternative Title"
					maxLength={30}
					extendClass="my-2"
				/> */}

				<AppInput
					placeholder={'Preview Title'}
					value={selectorContent.content_metadata.preview_title}
					name="preview_title"
					isFull
					onChange={(e) => {
						setSelectorContent({
							...selectorContent,
							content_metadata: {
								...selectorContent.content_metadata,
								preview_title: e.target.value,
							},
						})
					}}
					label="Preview Title"
					extendClass="my-2"
					maxLength={140}
					required
				/>

				<AppInput
					placeholder={'Tag'}
					value={selectorContent.content_metadata.tags[0]}
					name="tags"
					isFull
					onChange={(e) => {
						setSelectorContent({
							...selectorContent,
							content_metadata: {
								...selectorContent.content_metadata,
								tags: [e.target.value],
							},
						})
					}}
					label="Tag"
					extendClass="my-2"
				/>

				<div className="w-full p-2 border rounded border-borderGray">
					<p className="mb-2 text-2xl font-semibold text-textDark">{t('Variants')}</p>
					{variants.map((variant, idx) => {
						return (
							<div key={variant.id}>
								<InlineSelectorVariant
									setSelectorContent={setSelectorContent}
									setShowInput={setShowInput}
									idx={idx}
									setVariant={setVariant}
									setVariants={setVariants}
									showInput={showInput}
									showInputId={showInput.id}
									item={variant}
								/>
							</div>
						)
					})}

					<AppInput
						placeholder={'Variant'}
						value={variant.content}
						name="variant"
						isFull
						onChange={(e) =>
							setVariant((prev) => ({ ...prev, content: e.target.value }))
						}
						label="Variant"
					/>
					<AppButton
						variant={'info'}
						type={'button'}
						onClick={() => {
							if (variant.content === '') {
								toast('Please fill the fields and answer fields', 'info')
								return
							}
							setVariants((prev) => [...prev, { ...variant, id: uuidv4() }])
							setVariant(initialVariant)
						}}
						extendClass="my-2"
					>
						Add Variant
					</AppButton>
				</div>

				<div className="w-full p-2 my-2 border rounded border-borderGray">
					<p className="mb-2 text-2xl font-semibold text-textDark">{t('Items')}</p>

					<div className="pt-2 mb-1 space-y-2">
						{selectorItems?.map((item, index) => (
							<div key={item.appId}>
								<InlineSelectorItem selectorItems={selectorItems} setSelectorItems={setSelectorItems} setSelectorItem={setSelectorItem} item={item} index={index} />
							</div>
						))}
					</div>

					<AppInput
						placeholder={'Title'}
						value={selectorItem.title}
						name="title"
						onChange={(e) =>
							setSelectorItem((prev) => {
								return { ...prev, title: e.target.value }
							})
						}
						extendClass=""
						isFull
					/>

					<AppTextArea
						rows={2}
						placeholder={'Description'}
						value={selectorItem.description}
						name="description"
						onChange={(e) =>
							setSelectorItem((prev) => {
								return { ...prev, description: e.target.value }
							})
						}
						extendClass=""
						isFull
					/>
					{selectorItemRender}
					<AppButton
						variant={'info'}
						type={'button'}
						onClick={addItemToItems}
						extendClass="mb-1 my-1"
					>
						{createOrUpdateSelectorItemButton.current} Item
					</AppButton>
				</div>

				<div className="my-4">
					{selectorContent.content_metadata.cover_image ? (
						<div className="cover__image-container">
							<img
								alt="conver_image"
								src={selectorContent.content_metadata.cover_image}
								className="my-2 rounded-sm cover-image"
							/>
						</div>
					) : null}
					{renderedTag}
					{uploadState.fileName !== undefined ? (
						<p>{uploadState.fileName}</p>
					) : null}
					{isLoading ? <UploadProgress progress={progress} /> : null}
				</div>
				<div className="flex w-full gap-2 mt-3 mb-3">
					<AppButton variant="primary" type="submit" extendClass="px-3">
						<div className="flex items-center justify-center gap-1">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 96 960 960"
								className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
							>
								<path
									fill="currentColor"
									d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"
								></path>
							</svg>
							Create Content Template{' '}
						</div>
					</AppButton>
				</div>
			</form>
		</TabContentLayout>
	)
}

export default NewSelector;