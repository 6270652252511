import React, { useContext, useMemo, useState, Fragment, FC, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Participants, Worker_details, Workers_R } from "../../Common/Types";
import {
  getParticipantDetails,
  getSingleWorker,
} from "../../apiQuery/Workers/Workers.apis";
import moment from "moment";
import { store } from "../../GlobalState/store";
import { withAuth } from "../../hoc/withAuth";
import {
  ActiveTimerIcon,
  AppInput,
  Loader,
  PathwayIcon,
  SearchIcon,
  TabBar,
} from "../../Theme";
import { Drawer } from "../../Theme/Drawer";
import { Link } from "react-router-dom";

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="h-3 w-3 flex-shrink-0 text-grayColor transition duration-100 hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white;"
    viewBox="0 0 5.284 5.284"
  >
    <path
      fill="currentColor"
      d="M280 336v3.473a.568.568 0 00.566.566h3.642l-.849.849.4.4 1.529-1.529-1.529-1.529-.4.4.849.849h-3.642V336z"
      transform="translate(-280 -336)"
    ></path>
  </svg>
);

const getItems = (state: any, selectedWorker: any, data: any) => {
  return [
    {
      label: "UUID",
      value: state?.uuid,
    },
    {
      label: "Phone Number",
      value: state?.phone_number,
    },
    {
      label: "Email Address",
      value: state?.email_address,
    },
    {
      label: "Pathway Title",
      value: selectedWorker?.PathwayName,
    },
    {
      label: "Job Application Reference",
      value: selectedWorker?.ParticipantJobRequisitionID,
    },
    {
      label: "Is Active",
      value: data?.data?.is_valid === true ? "True" : "False",
    },
    {
      label: "Is Worker",
      value: state?.is_worker ? "True" : "False",
    },
  ];
};

// ===================================================================
const WorkerPage: FC = () => {
  const navigate = useNavigate();
  const { state } = useContext(store);

  const params = useParams();
  const id = params.workerId || "";

  const queryClient = useQueryClient();

  // const [tab, setTab] = UseTab("All Pathways");
  const [search, setSearch] = useState("");

  // const [showWorkerDetails, setShowWorkerDetails] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState<Participants | null>(
    null
  );

  // ========================== DRAWER  ===============================
  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = () => setShowDrawer(false);

  // =========================================================

  const workers = queryClient.getQueryData(["workers", 1]) as Workers_R;

  const worker = useMemo(
    () => workers?.data?.find((item) => item.id === parseInt(id)),
    []
  );

  const { isLoading, isError, data, error, isFetching } = useQuery<
    Worker_details,
    Error
  >(["workers", id], () => getSingleWorker(parseInt(id)), {
    enabled: id !== "",
  });





  const userParticipantDetails = useQuery<any, Error>(["participant", id], () =>
    getParticipantDetails(state.organization_id, id)
  );

  function createData(name: string, value: string, updated: string) {
    return { name, value, updated };
  }

  //@ts-ignore
  const rows = useMemo(() => {
    // @ts-ignore
    return Object.keys(data?.data || {}).map((item) => {
      // @ts-ignore
      const name: any = data?.data[item]?.toString();
      return createData(item, name || "", "Last Updated 2 weeks ago");
    });
  }, [data]);


  const [searchTerm, setSearchTerm] = useState("");

  const items = getItems(data?.data, selectedWorker, data);

  return (
    <section className="relative">
      <TabBar />
      {/* {(userParticipantDetails.isFetching || isFetching) && <LinearProgress />} */}
      <div className="flex flex-col items-start justify-between p-5 bg-white border shadow-sm flex-nowrap border-borderGray">
        <span className="pb-1 mb-4 text-sm text-grayColor">
          <Link to="/workers">Workers</Link> &nbsp;&nbsp;/&nbsp;&nbsp;{" "}
          {data?.data?.first_name} {data?.data?.last_name}
        </span>
        <div className="flex">
          <div className="relative flex flex-col items-center self-center justify-center w-20 h-20 mr-2 bg-pink-500 rounded-md">
            <span className="text-2xl text-white uppercase dark:text-white">
              {`${data?.data?.first_name} ${data?.data?.last_name}`
                .split(" ") // split string into an array of words
                .map((word) => word.charAt(0)) // get first letter of each word
                .join("")}
            </span>
          </div>
          <div className="flex flex-col items-start justify-center p-2">
            <p className="mb-2 text-lg whitespace-nowrap text-textDark dark:text-white">
              {data?.data?.first_name} {data?.data?.last_name}
            </p>
            <div
              onClick={() => {
                // setShowWorkerDetails(!showWorkerDetails);
                setShowDrawer(!showDrawer);
              }}
              className="flex items-center justify-center gap-1 px-2 py-1 transition-all delay-75 rounded-md bg-primary hover:cursor-pointer hover:shadow-sm"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 11.071 7.778"
              >
                <path
                  fill="currentColor"
                  d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
                  transform="translate(-1.9 -8.95)"
                />
              </svg>
              <span className="text-sm text-textDark">View Worker Details</span>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="w-[97%] py-5 m-auto space-y-5">
          <div className="flex items-center justify-center">
            {/* {isFetching && <Loader isLoading size={35} />} */}
            <Loader isLoading={isLoading} size={35} />
          </div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg" ref={tableRef}>
            <table className="w-full text-sm text-left text-textDark ">
              <thead className="text-lg font-normal capitalize text-textDark bg-primary dark:bg-primary ">
                <tr>
                  <th scope="col" className="px-6 py-4 font-medium">
                    Pathway
                  </th>

                  <th scope="col" className="px-6 py-4 font-medium">
                    Current State
                  </th>
                  <th scope="col" className="px-6 py-4 font-medium">
                    Status                  </th>
                </tr>
              </thead>
              <tbody>
                {userParticipantDetails?.data?.data?.map(
                  (item: any, index: number) => {

                    if (
                      !item?.PathwayName.toLowerCase().includes(
                        search.toLowerCase()
                      )
                    )
                      return;
                    // const enteredState = moment(item?.ParticipantEntryDate)
                    //   .local()
                    //   .format("LLL");

                    let formattedParticipantEntryDate = "";
                    if (item.ParticipantEntryDate) {
                      // formattedParticipantEntryDate = moment(
                      //   item.ParticipantEntryDate,
                      //   "YYYY-MM-DD HH:mm:ss.SSS Z zz"
                      // ).fromNow();
                      formattedParticipantEntryDate = (moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                      ), 'days') > 0
                        ? moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                        ), 'days')
                        : moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                        ), 'hours') > 0
                          ? moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                          ), 'hours')
                          : moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                          ), 'minutes') > 0
                            ? moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                            ), 'minutes')
                            : moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                            ), 'seconds')).toString() + ' ' +
                        (moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                        ), 'days') < 1
                          ? moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                          ), 'hours') >= 1
                            ? moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                            ), 'hours') === 1
                              ? 'hour'
                              : 'hours'
                            : moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                            ), 'minutes') >= 1
                              ? moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"
                              ), 'minutes') === 1
                                ? 'minute'
                                : 'minutes'
                              : moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"), 'seconds') === 1
                                ? 'second'
                                : 'seconds'
                          : moment().diff(moment(item.ParticipantEntryDate, "YYYY-MM-DD HH:mm:ss.SSS Z"), 'days') === 1
                            ? 'day'
                            : 'days').toString() + ' ago'

                    }
                    return (
                      <tr
                        key={index}
                        className="transition-all delay-75 bg-white border-b hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer"
                        onClick={() => {
                          setSelectedWorker(item);
                          navigate(`/workers/${id}/pathway/${item.PathwaySlug}/participant/${item.ParticipantSlug}`)
                        }}
                      >
                        <td className="px-6 py-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                          <div className="flex flex-col">
                            <div className="flex gap-1">
                              <PathwayIcon />
                              <div className="flex flex-col">
                                <span className="text-md text-textDark">
                                  {/* {item?.PathwayName} -{" "}
                                  {item?.ParticipantJobRequisitionID}{" "} */}
                                  {item?.PathwayDisplayTitle}
                                </span>
                                <span className="flex gap-1 text-xs text-textGray">
                                  <ActiveTimerIcon className="w-3 h-3" />
                                  {formattedParticipantEntryDate}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                          <div className="flex flex-col gap-1">
                            <span className="text-sm text-textDark">
                              {item?.CurrentStateName}
                            </span>
                            <span className="flex gap-1 text-xs text-textGray">
                              {item?.ParticipantLastActivityDate ? (
                                <>
                                  <ActiveTimerIcon className="w-3 h-3" />
                                  {
                                    moment(
                                      item.ParticipantLastActivityDate, "YYYY-MM-DD HH:mm:ss.SSSS Z"
                                    ).format('MMM-DD-YYYY')
                                  }
                                </>
                              ) : null}{" "}
                            </span>
                          </div>
                        </td>
                        <td className="px-6 py-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                          <div className="flex flex-col gap-1">
                            <span className="text-sm text-textDark">
                              {item?.ParticipantActiveState === true ? "Active" : "Inactive"}
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Drawer outsideRef={tableRef} setOpen={closeDrawer} isOpen={showDrawer} title={``} isFull>
        <div className="flex flex-col justify-between h-full overflow-hidden">
          {/* p-5 */}
          <div className="h-full p-5 overflow-hidden">
            {/* <h5 className="text-sm text-textDark">
            User Details for {data?.data.first_name}{" "}
            {data?.data.last_name}
          </h5> */}

            {/*  */}
            <div className="relative flex flex-col h-full ">
              <div
                style={{ marginLeft: "-.5rem", marginBottom: ".5rem" }}
                className="sticky top-0 flex flex-col items-start justify-start w-full p-2 transition ease-in-out bg-white "
              >
                <h5 className="mb-2 text-lg text-textDark">
                  User Details for {data?.data.first_name}{" "}
                  {data?.data.last_name}
                </h5>
                <div className="flex items-center justify-start w-full rounded-lg bg-primary">
                  <SearchIcon className="ml-2" />
                  <AppInput
                    id="search"
                    value={searchTerm}
                    name="search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search User Details"
                  />
                </div>
              </div>
              <ul className="h-full overflow-y-scroll">
                {items
                  .filter((item) =>
                    item.label.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((item, index) => (
                    <li className="flex flex-col py-1" key={index}>
                      <span className="text-base text-textDark">
                        {item.label}
                      </span>
                      <span className="flex items-center justify-start gap-1 mb-2 ml-3 text-sm text-grayColor">
                        <ArrowIcon />
                        {item.value == null || "" ? "No data" : item.value}
                      </span>
                      <hr className="h-px border-1 border-borderGray dark:border-textDark" />
                    </li>
                  ))}

                {rows
                  .filter(
                    (item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      item.value
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                  .map((row, index) => (
                    <li className="flex flex-col py-1" key={index}>
                      <span className="text-base text-textDark">
                        {row.name}
                      </span>
                      <span className="flex items-center justify-start gap-1 mb-2 ml-3 text-sm text-grayColor">
                        <ArrowIcon />
                        {typeof row.value !== "object" && row.value}
                      </span>
                      <hr className="h-px border-1 border-borderGray dark:border-textDark" />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Drawer>
    </section>
  );
};

export default withAuth(WorkerPage);