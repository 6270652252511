import { FC, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { TabContentLayout } from "../TabContentLayout";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import UseUploadFile from "../../../../Hook/UseUploadFile";
import UseUpload from "../../../../Hook/UseUpload";
import { baseURL } from "../../../../apiQuery";
import UploadProgress from "../../../General/UploadProgress";
import { toast } from "../../../../utils/Toast";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation, useQuery } from "react-query";
import { createContentTemplate, getAllPathways } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { useNavigate, useParams } from "react-router-dom";
import { ConsentContentCreateOrUpdateRequest, ConsentContentTemplate, initialConsentContent } from "../../../../Types/ContentTemplate/Consent";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { useTranslation } from "react-i18next";

const NewConsent: FC = () => {



  const { t } = useTranslation();

  const params = useParams<{ templateSlug: string }>()
  const navigate = useNavigate();

  const { currentOrganizationID } = useContext(UserContext);

  const [formData, setConsentContent] = useState<ConsentContentTemplate>(initialConsentContent)


  //=====================  Upload Cover  ========================
  const [imageSrc, file, render, name] = UseUploadFile('Consent')
  const { uploadForm, progress, isLoading } = UseUpload(
    `${baseURL}upload`
  );


  const [uploadState, setUploadState] = useState({
    file,
    name,
  })

  useEffect(() => {
    if (name && file) {
      setUploadState({
        file,
        name,
      })
    }
  }, [name, file])

  // ============================ Mutation ================================

  const createConsentContentMutation = useMutation((param: ConsentContentCreateOrUpdateRequest) => createContentTemplate(param), {
    onSuccess: async (data) => {
      toast("Content created successfully", "success")
      const id = data?.data?.id
      if (id) {
        navigate(`/content-templates/${params.templateSlug}/view/${id}`)
      }
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message || "Something went wrong!"
      toast(message, "error")
    }
  })


  const allPathways = useQuery<any>('all-pathways-list', () => getAllPathways())

  //=======================================================================================================


  const pathwayList = useMemo(() => {
    const item = { label: 'Not Selected', value: '0' }
    const list: { label: string, value: string }[] = allPathways.data?.data && allPathways.data?.data?.map((item: any) => {
      return {
        label: item.name,
        value: item.id
      }
    })

    list && list.unshift(item)
    return list || []
  }, [allPathways.data?.data])

  // ======================  Submit Article  ========================
  const submitFormHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    if (formData.consent.title === '') {
      toast('Please enter a title', 'error')
      return
    }


    let _cover_imageLink = ''

    if (uploadState.file !== undefined) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", uploadState.file);
      const { link } = await uploadForm(formData)
      // setArticleContent(prev => ({...prev, cover_image: link}))
      _cover_imageLink = link
    }

    const consentContentWithImage: ConsentContentTemplate = {
      consent: formData.consent,
      content_metadata: {
        ...formData.content_metadata,
        cover_image: _cover_imageLink,
      }
    }

    const newState = [consentContentWithImage]
    const shapeData: ConsentContentCreateOrUpdateRequest = {
      content_type: EnumContentType.ConsentContent,
      consent: newState.map(item => {
        return {
          title: item.consent.title,
          description: item.consent.description,
          body: item.consent.body,
          subscribe_user_to_pathway_id: item.consent.subscribe_user_to_pathway_id,
          language: item.consent.language as LanguageEnum,
        }
      }),
      content_metadata: newState.map(item => {
        return {
          cover_image: item.content_metadata.cover_image,
          preview_title: item.content_metadata.preview_title,
          tags: item.content_metadata.tags,
          organization_id: currentOrganizationID(),
          language: item.content_metadata.language as LanguageEnum,
          alt_title: item.content_metadata.alt_title,
        }
      })
    }
    await createConsentContentMutation.mutateAsync(shapeData)
  }

  return <TabContentLayout>

    <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
      <h1 className="pb-8 text-xl">New Consent Content Template</h1>

      <h1 className="pb-8 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{formData.consent.title || ''}</h1>

      <AppInput tabIndex={-1} autoFocus placeholder={"Title"} value={formData.consent.title} name='title'
        isFull label='Title' required
        onChange={
          (e) => setConsentContent({ ...formData, consent: { ...formData.consent, title: e.target.value } })
        }
      />
      <AppTextArea placeholder={"Description"} rows={4} value={formData.consent.description} name='description'
        onChange={
          (e) => setConsentContent({ ...formData, consent: { ...formData.consent, description: e.target.value } })
        }
        label='Description' />
      <AppTextArea placeholder={"Body"} value={formData.consent.body} rows={6} name='body'
        onChange={
          (e) => setConsentContent({ ...formData, consent: { ...formData.consent, body: e.target.value } })
        }
        label='Body' />

      {/* <AppInput placeholder={"Alternative Title"} value={formData.content_metadata.alt_title} name='alt_title' isFull
        onChange={e => {
          setConsentContent({ ...formData, content_metadata: { ...formData.content_metadata, alt_title: e.target.value } })
        }} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

      <AppInput placeholder={"Preview Title"} value={formData.content_metadata.preview_title} name='preview_title' isFull
        onChange={e => {
          setConsentContent({ ...formData, content_metadata: { ...formData.content_metadata, preview_title: e.target.value } })
        }} label='Preview Title' extendClass='my-2' maxLength={140} required />

      <AppInput placeholder={"Tag"} value={formData.content_metadata.tags[0]} name='tags' isFull
        onChange={e => {
          setConsentContent({ ...formData, content_metadata: { ...formData.content_metadata, tags: [e.target.value] } })
        }} label='Tag' extendClass='my-2' />

      <AppDropDown label={`${t('Content.Subscribe_User_To_Pathway')}`} name='subscribe_user_to_pathway_id'
        options={pathwayList}
        onChange={e => {
          setConsentContent(prev => {
            return {
              ...prev,
              consent: {
                ...prev.consent,
                subscribe_user_to_pathway_id: Number(e.target.value)
              }
            }
          })
        }}
        value={formData.consent.subscribe_user_to_pathway_id.toString()}
        extendClass='my-2'
      />

      {formData.content_metadata.cover_image ?
        <div className="cover__image-container">
          <img alt="conver_image" src={formData.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
        </div> : null}
      {render}
      {uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}
      {isLoading ? <UploadProgress progress={progress} /> : null}
      <div className='flex w-full gap-2 mt-3 mb-3'>
        <AppButton disabled={isLoading || createConsentContentMutation.isLoading} variant="primary" type="submit" extendClass="px-3">
          <div className="flex items-center justify-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 96 960 960"
              className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
            >
              <path
                fill="currentColor"
                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
            </svg>
            Create Content Template</div>
        </AppButton>
      </div>
    </form>

  </TabContentLayout>
}

export default NewConsent;