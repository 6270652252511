import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Input from "../../General/Form/Input";
import Button from "../../General/Button";
import { AiFillFile } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import {
    alNotifiactionsActions,
    Conditions_T,
    emailActions,
    notificationActions, ruleBody, ruleIds,
    Single_Action_Single,
    smsActions,
    state_Edit_Action
} from "../../../Common/Types";
import PreviewActionModal from "../ActionPreview/PreviewActionModal";
import Condition from "../../Event/Condition";
import OverlayModal from "../../Layouts/OverlayModal";
import { BsFillEyeFill } from "react-icons/bs";
import UseTab from "../../../Hook/UseTab";
import Tab from "../../Tabs/Tab";
import Tabs from "../../Tabs/Tabs";
import { store } from "../../../GlobalState/store";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { createActionInState, updateAction } from "../../../apiQuery/Actions/Actions.apis";
// import { setSnackbar } from "../../../GlobalState/store.actions";
import {
    createTimeBaseEvent,
    deleteTimeCondition,
    updateTimeCondition
} from "../../../apiQuery/Condition/TimeBase.apis";
import { createActionRule, createContentRule } from "../../../apiQuery/Condition/Rule.apis";
import initialQuery from "../../../utils/InitialRules";
import jsonHandler from "../../Utils/JsonHandler";
import { Fade } from "@mui/material";
import { createActionRuleCondition } from '../../../apiQuery/Actions/ActionRuleCondition.apis';
import { toast } from '../../../utils/Toast';
import { useTranslation } from "react-i18next";
import { EnumActionType } from '../../../Types/ActionTemplate/ActionTemplate';
import { getStateActionTemplateFilter } from '../../../apiQuery/ActionTemplate/StateTemplate.apis';
import AppButton from '../../Shared/AppButton/AppButton';
import moment from "moment";
import { AppLoader } from '../../Shared/AppLoader';
import AppInput from '../../Shared/AppInput/AppInput';
import { setAddActionTemplateModal } from '../../../GlobalState/store.actions';
import { AppButton as AppThemeButton } from './../../../Theme'

const ALNotificationNew: FC<state_Edit_Action> = memo(({ onClose, defaultValue, setUpdate, onSuccess, type }) => {
    const { t, i18n } = useTranslation();
    const {
        state: globalState,
        dispatch
    } = useContext(store);
    const organization_id = useMemo(() => globalState.organization_id, [globalState.organization_id])


    const navigate = useNavigate();



    const queryClient = useQueryClient()

    const params = useParams();
    const id = params.stateSlug || ""
    const pathwaySlug = params.slug || ""

    const [ruleId, setRuleId] = useState<ruleIds>({
        event: undefined,
        time: undefined
    });
    const [query, setQuery] = useState(initialQuery);

    const { register, handleSubmit, getValues, setValue, trigger, formState: { errors } } = useForm<alNotifiactionsActions>({
        defaultValues: defaultValue ? defaultValue : {
            title: "",
            al_message: "",
            action_type: "al_notification",
        }
    });

    const [timeEvent, setTimeEvent] = useState({
        isAdded: false,
        isRuleAdded: false
    })
    const [passedContent, setPassedContent] = useState({
        targetField: '',
        targetFunction: '',
    })

    //@ts-ignore
    const timeValue = parseInt(getValues("timeValue")) as number

    //@ts-ignore
    const eventName = getValues("eventName") as string

    //@ts-ignore
    const typeOf = getValues("typeOf") as string

    //@ts-ignore
    const checkInValue = getValues("checkInValue") as string

    //@ts-ignore
    const target_func = getValues("target_func") || undefined as string

    //================================================================================================
    // convert this to a useMemo: const isContentTemplate
    const isContentTemplate = useMemo(() => defaultValue?.template_id ? true : false, [defaultValue?.template_id])


    const [previousPage, setPreviousPage] = useState<string>();
    const [nextPage, setNextPage] = useState<string>();
    const [searchContentTemplate, setSearchContentTemplate] = useState('')

    const { data: alTemplateData, isLoading: alTemplateIsLoading, isError: alTemplateIsError, error: alTemplateError, mutate } = useMutation(
        (pageDirection: 'next' | 'previous' | '') => getStateActionTemplateFilter<any, EnumActionType.ALNotificationAction>
            (pathwaySlug, id, EnumActionType.ALNotificationAction, 8, '', nextPage, previousPage, pageDirection), {
        onSuccess(data) {
            if (data?.data) {
                const { next, previous } = data?.data.paginate
                setNextPage(next)
                setPreviousPage(previous)
            }
        },
    });

    useEffect(() => {
        mutate('')
    }, [mutate]);

    const flattedalTemplateOptions = useMemo(() => {

        const t1 = alTemplateData && alTemplateData.data.data.map((item: any) => {
            return item.action_metadata.map((sms: any) => {
                return sms
            })
        })

        const t2 = t1?.flatMap(item => {
            return item.map((item: any) => {
                return item
            })
        }).filter(item => item.language === "en")

        return t2
    }, [alTemplateData]);


    const search_result = useMemo(() => {
        if (searchContentTemplate === '') return flattedalTemplateOptions
        return flattedalTemplateOptions &&
            flattedalTemplateOptions.filter(item => item.text.toLowerCase().includes(searchContentTemplate.toLowerCase()))
    }, [flattedalTemplateOptions, searchContentTemplate])


    const addActionTemplate = async (item: any) => {


        // const attach: IAttachActionTemplate = {
        //     template_id: item.owner_id,
        //     action_type: EnumActionType.SMSAction
        // }

        // await attachActionTemplate.mutateAsync(
        //     attach
        // )

        const obj = {
            template_id: item.owner_id as number,
            action_type: EnumActionType.ALNotificationAction,
        }

        dispatch(setAddActionTemplateModal(obj))

        onClose()
    }

    // =================================================================================================

    const createTimeEvent = useMutation((timeData: Conditions_T) => createTimeBaseEvent(organization_id, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('actions.createTimeEvent')}`
            toast(message, 'error')
        }
    })
    const deleteCondition = useMutation((delId: number) => deleteTimeCondition(organization_id, delId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        }
    })
    const updateTimeEvent = useMutation((timeData: Conditions_T) => updateTimeCondition(organization_id, timeData.id || 0, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }))
    const createRuleCondition = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        return createActionRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-action-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore

            const message = error?.response?.data?.message || `${t('actions.createRuleCondition')}`
            toast(message, 'error')
        }
    })

    const UpDateFunc = async (createdData: emailActions | smsActions | notificationActions | alNotifiactionsActions) => {
        if (ruleId.event === 0) {
            let ruleId = null
            if (query.rules.length !== 0) {
                const { data } = await createRuleCondition.mutateAsync(
                    // {
                    //     organization_id: organization_id,
                    //     ...jsonHandler(timeValue, typeOf, eventName, query, target_func)
                    // }
                )
                ruleId = data?.id || null
            }

            await updateActionMutation.mutateAsync({ ...createdData, rules_action_id: ruleId })
        } else await updateActionMutation.mutateAsync({ ...createdData, rules_action_id: ruleId.event || null })
    }

    const createTimeRule = async (ID: number | undefined) => {
        const typeIs = typeOf === 'days' ? 'hour' : typeOf
        await createTimeEvent.mutateAsync({
            deliver_time: timeValue ? timeValue : 5,
            deliver_unit: typeIs || "minute",
            state_id: parseInt(id),
            action_id: ID
        })
    }

    const createActions = useMutation((newActions: emailActions | smsActions | notificationActions | alNotifiactionsActions) => createActionInState(organization_id, pathwaySlug, id, { ...newActions }), {
        onSuccess: async (createdData: Single_Action_Single) => {
            if (timeEvent.isAdded) {
                await createTimeRule(createdData.data?.id)

            }
            toast(`${t('actions.created')}`, 'success')
            !!onSuccess && onSuccess()
            onClose()
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('actions.failed')}}`
            toast(message, 'error')
        }
    })

    const updateActionMutation = useMutation((updateActionDetails: emailActions | smsActions | notificationActions | alNotifiactionsActions) => updateAction({ ...updateActionDetails }, organization_id, pathwaySlug, id, updateActionDetails.slug || ''), {
        onSuccess: async () => {
            if (defaultValue) {

                toast(`${t('actions.updated')}`, 'success')
                !!onSuccess && onSuccess()
                await queryClient.invalidateQueries(['action', defaultValue.id])
                onClose()
            }
        },
        onError: (error: null | Error) => {

            const message = error?.message || `${t('actions.updated_failed')}`
            toast(message, 'error')
        }
    })

    // Create Action rule mutation.
    const createActionRuleConditionWithDependOnField = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        const value = { organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func: passedContent.targetFunction, target_field: passedContent.targetField };
        return createActionRuleCondition(value)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-action-rules", organization_id])
        },
        onError: (error: null | Error) => {
            //@ts-ignore
            // const message = error?.response?.data?.message || "Failed creating action Rule"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.response?.data?.message || `${t('actions.failed_create_action_rule')}`
            toast(message, 'error')
        }
    })

    // ==================  Submit to backend  ( CREATE, UPDATE )  ==================
    const onSubmit: SubmitHandler<alNotifiactionsActions> = async data => {

        await trigger()
        const setData = {
            ...data,
            is_time_base: timeEvent.isAdded,
            time_condition: timeEvent.isAdded ? undefined : null,
        }
        // Update SMS Action
        if (type === 'update') {
            const timeId = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined

            if (timeEvent.isAdded) {
                if (defaultValue?.time_condition && timeId) {
                    await updateTimeEvent.mutateAsync({
                        id: timeId,
                        deliver_time: timeValue ? timeValue : 5,
                        deliver_unit: typeOf || "minute",
                        state_id: parseInt(id),
                    })
                } else await createTimeRule(defaultValue.id)
            }
            if (!timeEvent.isAdded && defaultValue?.is_time_base) {
                if (id) await deleteCondition.mutateAsync(timeId)
            }
            await UpDateFunc(setData)
        }
    };

    const [modal, setModal] = useState({
        pathwayView: false
    })
    const [tab, setTab] = UseTab('AL Notifications Details')

    const handlePathwayView = useCallback(() => {
        setModal(prev => ({ ...prev, pathwayView: !prev.pathwayView }))
    }, [])

    const title = getValues("title");

    useEffect(() => {
        if (defaultValue) {
            const id = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined
            setRuleId({ time: id, event: defaultValue?.rules_action_id })

            if (defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.deliver_time) {
                //@ts-ignore
                setValue("timeValue", defaultValue?.time_condition[0]?.deliver_time)  //@ts-ignore
                setValue("typeOf", defaultValue?.time_condition[0]?.deliver_unit)
            }

        }
    }, [])



    return (
        <div className="flex items-center w-full">

            {/*---------------------------------------------------------*/}
            {/*New SMS Text action container with tabs*/}
            <div className="flex w-full space-x-3">
                <form onSubmit={handleSubmit(onSubmit)}
                    className='w-full h-full space-y-3 overflow-auto text-gray-600 text-md'>

                    {type === 'create' ? <>
                        {/* ======================================== CREATE:  choose between content template   ======================================================= */}
                        <AppInput placeholder='Search' value={searchContentTemplate} onChange={e => setSearchContentTemplate(e.target.value)} name='searchTemplate' />
                        <div className='flex items-center justify-center'>
                            {alTemplateIsLoading ? <AppLoader isLoading size={35} /> : null}
                        </div>

                        <div className='grid grid-cols-2 gap-1'>
                            {search_result && search_result.map((item, index) => {
                                return <div className='flex flex-col items-start justify-start p-4 m-1 transition-all delay-300 rounded-md bg-gray-50 hover:bg-white' key={index}>
                                    <span
                                        onClick={() => addActionTemplate(item)}
                                        className='px-2 text-xs border rounded hover:cursor-pointer hover:bg-sky-100 border-sky-500 bg-sky-50 text-sky-700'>Add +</span>
                                    <p className='my-1 font-medium text-md'>{" "}</p>
                                    <p className='flex-1 mb-3 text-xs'>
                                        {item.title}
                                    </p>
                                    <p className='flex-1 mb-3 text-xs'>
                                        {item.al_message5}
                                    </p>
                                    {item.updated_at ? <p className="mt-1 text-xs">Last updated {moment(item.updated_at).fromNow() + '. on ' + moment(item.updated_at).format('MMM D, YYYY')
                                    }</p> : null}
                                </div>
                            })}
                        </div>

                        <div className="flex">
                            <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
                                onClick={() => {
                                    // activateSearch.current = true
                                    mutate('previous')
                                }}
                                extendClass="mx-1">Previous</AppButton>
                            <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
                                onClick={() => {
                                    mutate('next')
                                    //    activateSearch.current = true
                                }}
                                extendClass="mx-1">Next</AppButton>
                        </div>
                    </> : null}

                    {type === 'update' && isContentTemplate === true ? <>
                        <Fade in={true}>
                            <div className="space-y-4">
                                <Input
                                    register={() => register("title")}
                                    name="title"
                                    // placeholder="SMS text content"
                                    errors={errors}
                                    value={defaultValue?.action_template?.action_metadata[0]?.title || ""}
                                    disabled />
                                <Input
                                    register={() => register("al_message")}
                                    name="al_message"
                                    // placeholder="SMS text content"
                                    errors={errors}
                                    value={defaultValue?.action_template?.action_metadata[0]?.al_message || ""}
                                    disabled />

                                <AppThemeButton type='button' onClick={() => { navigate(`/action-templates/al_notification/view/${defaultValue?.action_template?.id}`) }} size='md' variant="info">
                                    <div className='flex items-center justify-between gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                        </svg>
                                        <span>Edit AL Notification Template</span>
                                    </div>
                                </AppThemeButton>
                            </div>
                        </Fade>
                    </> : null}

                    {type === 'update' && isContentTemplate === false ? <>
                        {/*---------------------------------------------------------*/}
                        {/*SMS Text Details and Content Details tabs*/}
                        <Tabs selectedTab={tab} setSelectedTab={setTab}>
                            <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                handlePathwayView()
                                e.stopPropagation()
                            }} />} title="AL Notification Details">AL Notifications
                                Details</Tab>
                            <Tab title="Condition Details">Condition Details</Tab>
                        </Tabs>

                        {/*---------------------------------------------------------*/}
                        {/*SMS Text Details tab with form inputs*/}
                        <Fade in={tab === "AL Notifications Details"}
                            style={{ display: tab === "AL Notifications" ? '' : 'none' }}>
                            <div className="space-y-4">
                                <Input
                                    register={() => register("title", { required: ' title is required' })}
                                    name="title"
                                    placeholder="AL Notification title content"
                                    errors={errors} />
                            </div>
                        </Fade>

                        {/*---------------------------------------------------------*/}
                        {/*Condition Details tab with form inputs*/}
                        <Fade in={tab === "Condition Details"}
                            style={{ display: tab === "Condition Details" ? '' : 'none' }}>
                            <div>
                            </div>
                        </Fade>

                        {/*---------------------------------------------------------*/}
                        {/*Submit form to create the SMS Text action*/}
                        <Button
                            disabled={createActions.isLoading || updateActionMutation.isLoading || createTimeEvent.isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading || createRuleCondition.isLoading}
                            type="submit"
                            label={
                                <div className="flex items-center space-x-2 text-white">
                                    <AiFillFile className="text-3xl" />
                                    <p>{type === 'update' ? 'Update Action' : 'Create Action'}</p>
                                </div>
                            } extraClasses="w-fit px-3 py-3"
                        />

                    </> : null}
                </form>
            </div>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for SMS Text*/}
            <OverlayModal onClose={handlePathwayView} isOpen={modal.pathwayView}>
                <div className="relative flex p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md h-fit w-fit">
                    <PreviewActionModal
                        type="sms"
                        title={title} />
                </div>

            </OverlayModal>
        </div>
    );
});

export default ALNotificationNew;
