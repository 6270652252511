import React, {FC} from 'react';
import {ContCommon} from "../../../Common/Types";
import {RiCheckDoubleFill} from "react-icons/ri";
import Button from "../../General/Button";
import {MdCheckCircle} from "react-icons/md";
import {IoMdCloseCircle} from "react-icons/io";

const ConsentPreview: FC<ContCommon> = (prop) => {

    return (
        <>
            <div className="whitespace-pre-wrap bg-white p-4 text-black shadow-md rounded-md relative mt-6">
                <div className="flex w-full justify-center items-center align-center">
                    <div
                        className="p-3 bg-organization-secondary absolute rounded-full text-white text-2xl shadow-md -top-5 left-auto right-auto">
                        <RiCheckDoubleFill/>
                    </div>
                </div>
                <div className="text-sm pt-10 pb-4 text-center" dangerouslySetInnerHTML={{__html: prop.body || ''}}/>
            </div>

            <div className="flex space-x-2 pt-8 pb-8">
                <Button icon={<MdCheckCircle className="mx-1"/>} label={"Yes"}
                        extraClasses={"bg-[#9263BD] opacity-90"}/>
                <Button icon={<IoMdCloseCircle className="mx-1"/>} label={"No"}
                        extraClasses={"bg-[#9263BD] opacity-90"}/>
            </div>
        </>
    );
};

export default ConsentPreview;