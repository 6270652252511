import API from "../index";
import {contents, TFlow} from "../../Common/Types";

export const getAllStateContents = async () => {
    const {data} = await API.get('/contents');
    return data;
};

export const createContentInState = async (organ: number, pathway_slug: string, state_slug: string, values: contents) => {
    const dataToServer = {...values}
    delete dataToServer["organization_id"]; 

    const {data} = await API.post(`/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/contents/`, dataToServer);
    return data;
};

export const deleteContent = async (organ: number, pathway_slug: string, state_slug: string, content_slug: string) => {
    const {data} = await API.delete(`/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/contents/${content_slug}`);
    return data;
};

export const getContent = async (pathway_slug: string, state_slug: string, content_slug: string) => {
    const {data} = await API.get(`/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/contents/${content_slug}`);
    delete data["organization_id"]; 
    return data;
};

export const updateContent = async (organ: number, pathway_slug: string, state_slug: string, data: contents, content_slug: string) => {
    const response = await API.put(`/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/contents/${content_slug}`, {...data});
    return response.data;
};