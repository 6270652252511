//^ This component is for the time when the user clicks on the checkbox and a command bar is displayed for editing or deleting.

import { useTranslation } from "react-i18next";
import { AppButton, CommandBar, DeleteIcon } from "../../../Theme";
import { EditIcon } from "../../../Theme/Icons/EditIcon";

//! Type script 
interface IPropsCommandBar {
  showCommandBar: boolean;
  setShowCommandBar:(showCommandBar:boolean)=>void ;
  selectedPathways: string[];
  editPathwayModalHandler: () => void;
  setShowPopupDeletePathways: (showPopupDeletePathways: boolean | ((prev: boolean) => boolean)) => void
}

//^ Start component
const CommandBarFunction=({ showCommandBar, setShowCommandBar, selectedPathways, editPathwayModalHandler, setShowPopupDeletePathways}: IPropsCommandBar)=> {
const {t}=useTranslation()

  //! UI section 

  //! UI--> Edit Button
  const editButton = () => {
    return (
      <AppButton
        type="button"
        variant="primary"
        onClick={() => editPathwayModalHandler()}
      >
        <div className="flex items-center gap-1 mx-1">
          <EditIcon className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white" />
        </div>
        {t('PathwayPage.Edit_Pathway')}
      </AppButton>
    );
  };

  //! UI--> Delete Button
  const deleteButton = () => {
    return (
      <AppButton
        type="button"
        variant="primary"
        onClick={() => {
          setShowPopupDeletePathways((prev: boolean) => !prev);
        }}
      >
        <div className="flex items-center gap-1 mx-1">
          <DeleteIcon className="" />
          <span className="text-sm">
            {t('PathwayPage.Delete_Pathway')}{selectedPathways.length > 1 ? "s" : ""}{" "}
          </span>
        </div>
      </AppButton>
    );
  };

  //^ Main UI 
  return (
    <CommandBar isOpen={showCommandBar} setOpen={() => setShowCommandBar(!showCommandBar)}>
      <span className="text-xs text-textGray">
        {/* //! The number of selected pathways */}
        {selectedPathways?.length} Pathway
        {selectedPathways?.length > 1 ? "s" : ""} {t('PathwayPage.Selected')}
      </span>
      {
      selectedPathways?.length === 1 ? 
      <>{editButton()}</> : 
      null
      }
      {deleteButton()}
    </CommandBar>
  );
}

export default CommandBarFunction;
