import { ChangeEvent, FormEvent, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppButton, CloseIcon, LanguageIcon } from "../../../Theme";
import AppInput from "../../Shared/AppInput/AppInput";
import AppTextArea from "../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../Shared/AppDropDown/AppDropDown";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { useMutation, useQuery } from "react-query";
import { getStateFlow } from "../../../apiQuery/StateFlow/State.apis";
import { IPathwayResponse } from "../../../Types/Pathways/Pathways";
import { toast } from "../../../utils/Toast";
import { useParams } from "react-router-dom";
import { createStateLanguage, deleteStateLanguage, getState, updateState, updateStateLanguage } from "../../../apiQuery/State/State.apis";
import { IState, IStateLanguages, StateMessage } from "../../../Types/States/States";
import { getLangaugeLabel } from "../../../utils/Languages";

type props = {
  modalHandle: () => void;
  slug: string;
};

type NextPossibleState = {
  value: number;
  label: string;
};

export const LanguagesOptionsNewLanguges_EditPathway = [
  { value: "-", label: "Choose an option" },
  { value: "fr", label: "French" },
  { value: "es", label: "Spanish" }
]

const EditStateModal = ({ modalHandle, slug }: props) => {
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState<string>('en')
  const [dropDownSelectedLanguage, setdropDownSelectedLanguage] = useState("-")
  const params = useParams<{ slug: string }>();
  const pathwaySlug = params.slug || "";

  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedNextPossibleStates, setSelectedNextPossibleStates] = useState<NextPossibleState[]>([]);
  const [newForm, setNewForm] = useState(false)
  const [newStateLanguageData, setNewStateLanguageData] = useState<IStateLanguages>({
    name: '',
    description: '',
    language: 'fr',
  });

  //! Initial State Object
  const initialState: IState = {
    id: 0,
    slug: '',
    created_at: '',
    updated_at: '',
    deleted_at: null,
    pathway_id: 0,
    organization_id: 0,
    name: '',
    description: '',
    participant_count: 0,
    state_template_id: 0,
    visible_to_worker: false,
    is_template: false,
    rules_state_id: undefined,
    next_possible_states: null,
    participants: null,
    actions: null,
    contents: null,
    conditions: null,
    state_time_conditions: null,
    invalidate_user: false,
    disable_participant: false,
    state_languages: [] // You may need to provide an initial value for this as well
  };
  const [state, setState] = useState<IState>(initialState);

  //! UseQuery for all pathway states (for example this is used for finding nextPossible state names)
  const { data: allStatesData } = useQuery<IPathwayResponse, Error>(
    ["individual-state-flow", pathwaySlug],
    () => getStateFlow(pathwaySlug),
    { enabled: !!pathwaySlug }
  );

  //! UseQuery for the main state data
  const { data, refetch} = 
  useQuery<StateMessage, Error>(["state", slug], () => getState(pathwaySlug, slug), {
    cacheTime: 0,
    staleTime: 0,
    enabled: !!slug && !!pathwaySlug,
  });

  // //! UseEffect for the setState from the data
  useEffect(()=> {
    if (data?.data.state) {
      setState(data?.data.state);
    }
  }, [data])


  //! UseEffect for NextPossibleEffects
  useEffect(() => {

      // All available states in the pathway
      const allStates = allStatesData?.data?.states || [];

      // local array
      const nextPossibleStates: NextPossibleState[] = [];

      if (state.next_possible_states) {
        state.next_possible_states.forEach((state : any) => {

          // state is just the ID
          const stateName = allStates.find((s) => s.id === state);  
          if (stateName) {
            nextPossibleStates.push({value: stateName.id, label: stateName.name,});
          }
        });
      }
      setSelectedNextPossibleStates(nextPossibleStates);
  }, [state.next_possible_states])

  //! Updates the current state in backend
  const updateCurrentState = useMutation(
    (stateData: IState) =>
      updateState(pathwaySlug, slug, {
        ...stateData,
      }),
    {
      onSuccess: () => {
        refetch()
      },
      onError: (error: any) => {
        const message =
          typeof error.response !== "undefined"
            ? error?.response?.data?.message
            : error?.message || "Cannot update the State";
        toast(message, "error");
      },
    }
  );

  //! Updates the specific state language data in backend
  const updateStateLanguageMutation = useMutation((params: {
    pathwaySlug: string,
    pathwayLanguageId: number
    data: IStateLanguages
  }) => updateStateLanguage(params.pathwaySlug, params.pathwayLanguageId, params.data), {
    onSuccess: () => {
      refetch()
    }
  })

  //! Deletes the provided language ID in backend
  const deleteSelectedLanguage = async (
    pathwaySlug: string,
    stateLanguageId: number
  ) => {
    try {
      const deletedLanguage = await deleteStateLanguage(pathwaySlug, stateLanguageId);
      toast(
        `${'Current language Deleted Successfully'}`,
        "success"
      )
      refetch()

    } catch (error) {
      console.error('Error adding new language:', error);
      toast(
        `${error}`,
        "error"
      );
    }
  }

  //! Submit form (Save Button)
  const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newState = {
        ...state,
        next_possible_states: selectedNextPossibleStates.map((next_state) => +next_state.value),
      }
      await updateCurrentState.mutateAsync(newState);

    if (selectedLanguage === "en") {

      toast(
        `State was successfully updated`,
        "success"
      );
      modalHandle();
    } else {
      const stateData = state.state_languages ? state.state_languages.find(item => item.language === selectedLanguage) : null
      if (stateData) {
        await updateStateLanguageMutation.mutateAsync({
          pathwaySlug: slug,
          pathwayLanguageId: stateData.id as number,
          data: {
            ...stateData,
          }
        })
      }
      toast(
        `The Pathway Language was successfully updated`,
        "success"
      );
    }
	    
  };

  //! List of other pathway states (except the current one)
  const list = useMemo(() => allStatesData?.data?.states?.filter((item) => item.slug !== slug) || [], [allStatesData?.data?.states, slug])

  //! List of available next possible states 
  const availableNextPossibleStates = useMemo(() => {
    return list.map((item: any) => ({
      label: item.name,
      value: item.id.toString(),
    }));
  },[list])

  const listOfLanguages = useMemo(() => {
    if (state?.state_languages)
      return LanguagesOptionsNewLanguges_EditPathway.filter(item => state.state_languages?.find(sl => sl.language === item.value) === undefined)
    return LanguagesOptionsNewLanguges_EditPathway
  }, [state.state_languages])

  const newLanguage = () => {
    setNewStateLanguageData({
      name: '',
      description: '',
      language: '-',
    });
    setSelectedLanguage('')
    setNewForm(true)
  }

  const submitNewLanguage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createStateLanguage(pathwaySlug, slug, newStateLanguageData);
      await refetch()
      var currLanguage = newStateLanguageData.language
      toast(
        `New Language was successfully created`,
        "success"
      )
      setSelectedLanguage(currLanguage)
      setNewForm(false);
    } catch (error) {
      console.error('Error adding new language:', error);
      toast(
        `Couldn't Create New Language`,
        "error"
      );
    }
  };

//^ UI sections
//! App language buttons 
const AppLanguageButtons=()=>{
  return(
    <div className="sticky top-0 z-30 flex gap-2 px-2 py-2 bg-white dark:bg-bckDark sm:px-1">
      {/* //!English buttons */}
    <AppButton
      key={1}
      variant={selectedLanguage === "en" ? 'info' : 'primary'}
      type="button"
      size="xs"
      onClick={() => {
        setSelectedLanguage("en")
        setNewForm(false)
      }}>
      <div className="flex items-center justify-center gap-1">
        <LanguageIcon className='w-3 h-3' />
        <span className="text-xs">{getLangaugeLabel("English")}</span>
      </div>
    </AppButton>

      {/* //! Another languages */}
    {state.state_languages ? state.state_languages.map((item, idx) => (
      <AppButton
        key={idx}
        variant={selectedLanguage === item.language ? 'info' : 'primary'}
        type="button"
        size="xs"
        onClick={() => {
          setSelectedLanguage(item.language)
          setNewForm(false)

        }}
      >
        <div key={item.language} className="flex items-center justify-center gap-1">
          <LanguageIcon className='w-3 h-3' />
          <span className="text-xs">{getLangaugeLabel(item.language)}</span>
        </div>
      </AppButton>
    )) : null
    }

    {
      (state?.state_languages?.length || 0) < 2 ? (

        /* //! New Language Button */
        <AppButton
          variant={'primary'}
          type="button"
          size="xs"
          onClick={newLanguage}><span className="text-xs">{t('new_language')}</span></AppButton>

      ) : null
    }

    <AppButton
      variant={'danger'}
      type="button"
      size="xs"
      onClick={
        () => {
          const stateData = state.state_languages ? state.state_languages.find(item => item.language === selectedLanguage) : null
          if (typeof stateData?.id === 'number') {
            deleteSelectedLanguage(slug, stateData?.id);
            setSelectedLanguage('en')
            refetch()
          } else {
            toast('Cannot Delete Current Language', 'error')
          }
        }
      }
    >
      <span className="text-xs">
        Delete Selected Language
      </span>

    </AppButton>

  </div>
  )
}


//! App body inputs 
 const NewFormBodyInputs=()=>{
return(
      <>
          <AppInput
            isFull
            label="State Title"
            id="StateTitle"
            title="State Title"
            tabIndex={-1}
            placeholder="State Title"
            name="name"
            required
            onChange={(e) => {
              setNewStateLanguageData((prev) => ({ ...prev, name: e.target.value }));
              setNewStateLanguageData((prev) => ({ ...prev, display_title: e.target.value }));
            }}
            value={newStateLanguageData?.name || ''}
          />
          <AppTextArea
            placeholder={"State Description"}
            rows={4}
            name="description"
            onChange={(e) => {
              setNewStateLanguageData((prev) => ({ ...prev, description: e.target.value }));
            }}
            label="State Description"
            id="StateDescription"
            value={newStateLanguageData?.description || ""}
          />
</>
)
 }

//! Next possible state drop down and some switch buttons 
 const FormDetails=()=>{
return(
  <>
      <div className="my-2">
                <div className="p-1">
                  {/* show list of selected like badge */}
                  {selectedNextPossibleStates && selectedNextPossibleStates.map((item, idx) => (
                    <span key={item.value} className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-lightGrayColor text-textDark mr-1">
                      {item.label}
                      <button
                        onClick={() => {
                          setSelectedNextPossibleStates((prev) =>
                            prev.filter((i) => i.value !== item.value)
                          );
                        }}
                        type="button"
                        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-500 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
                      >
                        <span className="sr-only">Remove large option</span>
                        <svg
                          className="w-2 h-2"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 8 8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeWidth="1.5"
                            d="M1 1l6 6m0-6L1 7"
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>

                <span className="block mb-1 text-base font-normal text-black dark:text-white">
                  {" "}
                  Next Possible States{" "}
                </span>
                <button
                  onClick={() => setShowDropDown(!showDropDown)}
                  id="dropdownCheckboxButton"
                  data-dropdown-toggle="dropdownDefaultCheckbox"
                  className="w-full rounded-sm border border-borderGray bg-[#F5F5F5] placeholder:text-[#A4A5A5] p-2.5 text-md  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium  text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                >
                  <div className="flex items-center justify-between w-full">
                    Next Possible States
                    <svg
                      className="w-4 h-4 ml-2"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </button>

                {
                  availableNextPossibleStates.length > 0 ? (<>
                    <div
                      id="dropdownDefaultCheckbox"
                      className={`z-10 w-56 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 ${showDropDown ? "" : "hidden"
                        }`}
                    >
                      <ul
                        className="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownCheckboxButton"
                      >
                        {availableNextPossibleStates.map((item: any) => {
                          return (
                            <li key={item.value}>
                              <div className="flex items-center">
                                <input
                                  id={`checkbox-item-${item.value}`} // unique ID
                                  type="checkbox"
                                  value={item.value}
                                  checked={
                                    selectedNextPossibleStates.some(
                                      (selectedItem) => selectedItem.value === parseInt(item.value)
                                    )} // This is the condition for the checkbox to be checked
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    const value = parseInt(e.currentTarget.value);
                                    const isExists  = selectedNextPossibleStates.some(item => item.value === Number(value))
                                    if(isExists) {
                                      setSelectedNextPossibleStates(prev => prev.filter(item => item.value !== Number(value)))
                                    } else {
                                      setSelectedNextPossibleStates(prev => [...prev, { ...item, value: value }])
                                    }
                                  }}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                />
                                <label
                                  htmlFor={`checkbox-item-${item.value}`} // matching ID for the label
                                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {item.label}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                  </>) : null
                }

              </div>
              <div className="flex gap-5 mt-8">
              </div>
              <div className="flex gap-5 mt-5">
                <div className="flex justify-start">
                  <label
                    htmlFor="getCandidate_API"
                    className="mx-2 mb-2 text-xs text-textDark"
                  >
                    Visible to Worker
                  </label>
                  <Switch
                    checked={state.visible_to_worker}
                    onChange={() => {
                      setState((prev) => ({
                        ...prev,
                        visible_to_worker: !prev.visible_to_worker,
                      }));
                    }}
                    className={`${state.visible_to_worker === true
                      ? "bg-greenColor"
                      : "bg-teal-700"
                      }
                    relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Visible to Worker</span>
                    <span
                      aria-hidden="true"
                      className={`${state.visible_to_worker === true
                        ? "translate-x-3"
                        : "translate-x-0"
                        }
                  pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>

                <div className="flex justify-start">
                  <label
                    htmlFor="getCandidate_API"
                    className="mx-2 mb-2 text-xs text-textDark"
                  >
                    Deactivate Participants
                  </label>
                  <Switch
                    checked={state.disable_participant === true}
                    onChange={() => {
                      setState((prev) => ({
                        ...prev,
                        disable_participant: !state.disable_participant,
                      }));
                    }}
                    className={`${state.disable_participant === true
                      ? "bg-greenColor"
                      : "bg-teal-700"
                      }
                    relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Deactivate Participants</span>
                    <span
                      aria-hidden="true"
                      className={`${state.disable_participant === true
                        ? "translate-x-3"
                        : "translate-x-0"
                        }
                  pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
              </div>
              <div className="flex justify-start mt-3">
                <label
                  htmlFor="getCandidate_API"
                  className="mx-2 mb-2 text-xs text-textDark"
                >
                  Invalidate User (deletes WS1 and Auth0 records)
                </label>
                <Switch
                  checked={state?.invalidate_user === true}
                  onChange={() => {
                    setState((prev) => ({
                      ...prev,
                      invalidate_user: !state?.invalidate_user,
                    }));

                  }}
                  className={`${state?.invalidate_user === true ? "bg-greenColor" : "bg-teal-700"
                    }
                    relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">
                    Invalidate User (deletes WS1 and Auth0 records)
                  </span>
                  <span
                    aria-hidden="true"
                    className={`${state?.invalidate_user === true
                      ? "translate-x-3"
                      : "translate-x-0"
                      }
                  pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
  </>
)
 }

//! Main UI  
return (
  <div className="relative z-50 flex w-2/5 h-full mt-5 overflow-auto bg-white rounded-md shadow-md text-textDark state-edit-modal">
    <div className="flex flex-col w-full p-8">
      <div className="flex justify-between">
        <div className="flex flex-col gap-5 mb-2">
          <div>
            <h2 className="mb-1 text-2xl font-normal text-textDark">
              Edit State
            </h2>
            <p className="text-sm text-textDark">
              Edit the State name, description, and other details.
            </p>
          </div>
        </div>
        <button
          type="button"
          onClick={modalHandle}
          className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <CloseIcon className="w-4 h-4" />
          <span className="sr-only">Close menu</span>
        </button>
      </div>

      {/* //!App language buttons section */}
      {AppLanguageButtons()}
      
      <hr className="w-full h-px my-5 border-1 border-borderGray dark:border-textDark" />

      {/* //!Forms -> New and current forms */}
      {
        newForm ? (
            <form onSubmit={submitNewLanguage}>
          {NewFormBodyInputs()}
          {/* <AppDropDown label={"Language"} options={LanguagesOptionsNewLanguges_EditPathway} value={dropDownSelectedLanguage || ""} name="language"  */}
          <AppDropDown label={"Language"} options={listOfLanguages} value={dropDownSelectedLanguage || ""} name="language"
            onChange={(e) => {
              setdropDownSelectedLanguage(e.target.value);
              setNewStateLanguageData((prev) => ({ ...prev, language: e.target.value }));
            }} />

          <div className="mt-8">
            <AppButton variant="primary" type="submit">{`${t(
              "PathwayPage.Save_Changes"
            )}`}</AppButton>
          </div>
            </form>)
          : (<>
            < form onSubmit={submitFormHandler} className="w-full">
              <AppInput
                isFull
                label="State Title"
                id="StateTitle"
                title="State Title"
                tabIndex={-1}
                placeholder="State Title"
                name="name"
                required
                onChange={(e) => {
                  // update based on corresponding language
                  if (selectedLanguage !== 'en') {
                    const stateData = state?.state_languages?.find(item => item.language === selectedLanguage)
                    if (stateData) {
                      const updatedStateData = state?.state_languages?.map(item => {
                        if (item.language === selectedLanguage) {
                          return {
                            ...item,
                            name: e.target.value,
                          }
                        }
                        return item
                      })
                      setState((prev) => ({ ...prev, state_languages: updatedStateData }));
                    }
                  } else {
                    setState((prev) => ({ ...prev, name: e.target.value }));
                  }
                }}
                value={
                  selectedLanguage === 'en' ? state.name : state?.state_languages?.find(item => item.language === selectedLanguage)?.name || ''
                }
              />
              <AppTextArea
                placeholder={"State Description"}
                rows={4}
                name="description"
                onChange={(e) => {
                  // update based on corresponding language
                  if (selectedLanguage !== 'en') {
                    const stateData = state?.state_languages?.find(item => item.language === selectedLanguage)
                    if (stateData) {
                      const updatedStateData = state?.state_languages?.map(item => {
                        if (item.language === selectedLanguage) {
                          return {
                            ...item,
                            description: e.target.value
                          }
                        }
                        return item
                      })
                      setState((prev) => ({ ...prev, state_languages: updatedStateData }));
                    }
                  } else {
                    setState((prev) => ({ ...prev, description: e.target.value }));
                  }
                }}
                label="State Description"
                id="StateDescription"
                value={
                  (selectedLanguage === 'en'
                    ? state.description
                    : (state?.state_languages?.find(item => item.language === selectedLanguage)?.description || '')) as string
                }
              />
                 {FormDetails()}
              <div className="mt-8">
                <AppButton variant="primary" type="submit">{`${t(
                  "PathwayPage.Save_Changes"
                )}`}</AppButton>
              </div>
            </form> 
            </>)
      }

    </div>
  </div >
);
}
EditStateModal.displayName = "Edit State Modal"
export default EditStateModal;