import React, { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useRef, useState } from 'react'
import { TabActionLayout } from "../TabActionLayout";
import { LanguageEnum, IActionTemplate } from '../../../../Types/ActionTemplate/ActionTemplate';
import { useTranslation } from "react-i18next";
import { LanguagesOptions, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumActionType } from "../../../../Types/ActionTemplate/ActionTemplate";
import { IActionMetadata } from "../../../../Types/ActionTemplate/ActionTemplate";
import { useMutation, useQuery } from "react-query";
import { deleteActionTemplateById, getActionTemplateById, updateActionTemplateById } from "../../../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import ViewActionTemplateHeader from '../../ViewActionTemplateHeader';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import { useNavigate } from 'react-router-dom';
import AllALNotificationAction from './AllALNotificationAction';
import ALNotificationItem from './ALNotificationItem';
export type Language = "en" | "fr" | "es"

export interface ALNotificationAction {
    title: string
    language: Language
    al_message: string
}

interface ALNotificationActionCreateOrUpdateRequest {
    action_type: EnumActionType.ALNotificationAction,
    action_metadata: IActionMetadata[],
}

const initialALNotificationAction: ALNotificationAction = {
    title: '',
    language: "en",
    al_message: ''
}
interface ALNotificationTabActionProps {
    templateId: number,
}
const ALNotificationTabAction: FC<ALNotificationTabActionProps> = (props) => {

    const { currentOrganizationID } = useContext(UserContext);
    const navigate = useNavigate();


    const { t } = useTranslation(); // TODO: use this for language

    const [state, setState] = useState<ALNotificationAction[]>([])


    const [alNotificationAction, setalNotificationAction] = useState<ALNotificationAction>(initialALNotificationAction)



    const createOrUpdateButton = useRef<string>("Create")
    const allALNotificationActionsRef = useRef(null)
    const [selectedLanguage, setSelectedLanguage] = useState<string>('')


    // delete action template 
    const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);



    // ========================================================================================================================

    const { data: alNotificationData, refetch } = useQuery<any, Error>
        (`al_notification-action-${props.templateId}`, () => getActionTemplateById(props.templateId));


    const deleteActionTemplateByID = useMutation((params: { id: number }) =>
        deleteActionTemplateById(params.id), {


        onSuccess: async (data) => {
            toast("Template deleted successfully", "success")

        },
        onError: (error: any) => {
            const message = error?.response?.data?.message || "Something went wrong!"
            toast(message, "error")
        }
    })



    // === ============================================================		
    useEffect(() => {
        if (alNotificationData) {
            const newState = alNotificationData?.data.action_metadata.map((item: any) => {
                return {
                    title: item.title,
                    al_message: item.al_message,
                    language: item.language as Language
                }
            })
            setState(newState)
        }
    }, [alNotificationData])

    useEffect(() => {
        if (selectedLanguage === '') return
        // get seleted index of the content template to be displayed as the current content
        const selectedIndex = alNotificationData?.data.action_metadata.findIndex((item: any) => item.language === selectedLanguage) as number
        const alNotification = alNotificationData?.data.action_metadata[selectedIndex]
        setalNotificationAction({
            title: alNotification.title,
            language: alNotification.language as Language,
            al_message: alNotification.al_message
        })
        createOrUpdateButton.current = "Update"
    }, [alNotificationData?.data.action_metadata, selectedLanguage])

    const deleteTemplateFromContentTemplateList = async () => {
        deleteActionTemplateByID.mutateAsync({ id: props.templateId })
        navigate('/action-templates/al_notification', { state: { deleted: true } })
    }

    //=====================================================
    //make english default language for content
    useEffect(() => {
        const alNotification = alNotificationData?.data?.action_metadata.find((item: any) => item.language === "en")
        if (alNotification) {
            setalNotificationAction({
                language: LanguageEnum.English,
                title: alNotification.title,
                al_message: alNotification.al_message

            })
            createOrUpdateButton.current = "Update"
            setSelectedLanguage("en")
        }
    }, [alNotificationData])

    // ================================================== Mutations =========================================================	s
    const updateALNotificationActionMutation = useMutation(
        (params: { id: number, data: ALNotificationActionCreateOrUpdateRequest }) => updateActionTemplateById(props.templateId, params.data), {
        onSuccess: async (data) => {
            toast("Action updated successfully", "success")
            await refetch()
        },
        onError: (error: any) => {
            const message = error?.response?.data?.message || "Something went wrong!"
            toast(message, "error")
        }
    })

    // ======================================================== Handlers =========================================================
    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target
        setalNotificationAction(prev => ({ ...prev, [name]: value }))
    }

    // delete and edit
    const deleteALNotificationActionHandler = (language: Language) => {
        setState(prev => prev.filter(item => item.language !== language))
    }

    const editALNotificationActionHandler = (language: Language) => {
        const item = state.find(item => item.language === language)
        if (item) {
            setalNotificationAction(prev => {
                return { ...prev, title: item.title, language: language, al_message: item.al_message }
            })
            createOrUpdateButton.current = "Update"
        }
    }


    const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        //  tODO: validation goes here
        if (alNotificationAction.title === '') {
            return
        }

        if (createOrUpdateButton.current === "Create") {
            // Todo : check if the language is already exist
            if (state.some(item => item.language === alNotificationAction.language)) {
                toast('This Notification already exist', 'error')
                return
            }

            const newState = [...state, alNotificationAction]

            await createOrUpdateContentTemplate(newState)


        } else {
            // update the state
            const newState = state.map(item => {
                if (item.language === alNotificationAction.language) {
                    return alNotificationAction
                }
                return item
            })

            setState(newState)
            await createOrUpdateContentTemplate(newState)

            createOrUpdateButton.current = "Create"
        }

        // 	empty the fields
        setalNotificationAction({

            title: '',
            language: 'en',
            al_message: ''
        })

    }

    const createOrUpdateContentTemplate = async (data: any) => {
        if (data.length === 0) {
            toast('Please add at least one notification template', 'info')
            return
        }

        const action_metadata: IActionMetadata[] = data.map((item: any) => {
            return {
                ...item.action_metadata,
                organization_id: currentOrganizationID(),
                language: item.language as Language,
                title: item.title,
                al_message: item.al_message
            }
        })
        const shapeData: ALNotificationActionCreateOrUpdateRequest = {
            action_type: EnumActionType.ALNotificationAction,
            action_metadata: action_metadata,
        }


        await updateALNotificationActionMutation.mutateAsync({ id: props.templateId, data: shapeData })
        setState([])
        setSelectedLanguage('')
    }


    const editableALNotificationAction = (alNotificationAction: IActionTemplate) => {
        const items: ALNotificationAction[] = alNotificationAction.action_metadata.map((item: any) => {
            return {
                title: item.title,
                language: item.language as Language,
                al_message: item.al_message
            }
        })
        setState(items)
    }


    return (
        <Fragment>


            <TabActionLayout name="al_notification">
                <div className="hidden px-5 py-2 my-2">
                    <AllALNotificationAction
                        ref={allALNotificationActionsRef}
                        onEditClick={editableALNotificationAction} />
                    <hr className="my-2 border border-blue-800" />
                    {state.length > 0 ? (
                        <div className="p-3 rounded bg-blue-50">

                            <div>
                                <AppButton type="button" size="sm" variant='secondary' extendClass="my-2" onClick={() => {
                                    setState([]);
                                    setalNotificationAction(initialALNotificationAction)
                                    createOrUpdateButton.current = "Create"
                                }}>close</AppButton>
                                <div className="grid grid-cols-3 gap-2">
                                    {state.map((item: ALNotificationAction, idx: number) => {
                                        return (
                                            <ALNotificationItem
                                                key={idx}
                                                item={item}
                                                onDeleteClick={deleteALNotificationActionHandler}
                                                onEditClick={editALNotificationActionHandler}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <ViewActionTemplateHeader
                    title={alNotificationData?.data?.action_metadata[0]?.title || ''}
                    referencesCount={0}
                />
                <div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
                    {

                        alNotificationData?.data.action_metadata.map((item: any, idx: number) => {
                            return (
                                <AppButton
                                    key={idx}
                                    variant={selectedLanguage === item.language ? 'info' : 'primary'}
                                    type="button"
                                    size="xs"
                                    onClick={() => {
                                        if (selectedLanguage === item.language) {
                                            setalNotificationAction(initialALNotificationAction)
                                            setSelectedLanguage('')
                                            createOrUpdateButton.current = "Create"
                                        } else {
                                            editableALNotificationAction(alNotificationData.data)
                                            setSelectedLanguage(item.language)
                                        }
                                    }}
                                >
                                    <div className="flex items-center justify-center gap-1">
                                        <LanguageIcon className='w-3 h-3' />
                                        <span className="text-xs">{getLangaugeLabel(item.language)}</span>
                                    </div>
                                </AppButton>
                            )
                        })
                    }
                    {
                        state.length < 3 ? (
                            <AppButton
                                variant={'primary'}
                                type="button"
                                size="xs"
                                onClick={() => {
                                    setalNotificationAction(initialALNotificationAction)
                                    setSelectedLanguage('')
                                    createOrUpdateButton.current = "Create"
                                }}
                            >
                                <span className="text-xs">
                                    + New Language
                                </span>

                            </AppButton>
                        ) : null
                    }
                </div>



                {/* =========================== */}
                <h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{alNotificationAction.title || ''}</h1>
                <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">
                    <div className='flex w-full gap-2 mb-3'>
                        <AppButton size="xs" disabled={alNotificationAction.title === '' || updateALNotificationActionMutation.isLoading || deleteActionTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Action Template</AppButton>
                        <AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>Delete Action Template</AppButton>
                    </div>
                    <AppInput autoFocus placeholder={"Title"} value={alNotificationAction.title} name='title' isFull onChange={handleChange} label='Title' required />
                    <AppInput autoFocus placeholder={"Message"} value={alNotificationAction.al_message} name='al_message' isFull onChange={handleChange} label='Message' required />
                    <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"} options={LanguagesOptions} value={alNotificationAction.language} name="language" onChange={handleChange} />
                </form>
            </TabActionLayout>


            <PopupModal
                setOpen={setShowPopupDeleteConfirmation}
                isOpen={showPopupDeleteConfirmation}
                title="Are you sure you want to delete this AL Notification Action Template ?
			Deleting this Action Template will delete all Actions associated with this Template."
                onConfirmClick={deleteTemplateFromContentTemplateList}
            />

        </Fragment>
    )
}


export default ALNotificationTabAction
