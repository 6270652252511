// export function changeElementAtIndex(array: any, index: number, text: string) {
//     if (index >= 0 && index < array.length) {
//       array[index] = text;
//       return array;
//     } else {
//       console.error("Index out of range!");
//     }
//   }


export const updateById = (arr: any[], id: number, newQuestion: string) => {
  // Find the index of the object with the given id
  const indexToUpdate = arr.findIndex((item: any) => item.id === id);

  // If the id is not found, return the original array
  if (indexToUpdate === -1) {
    return arr;
  }

  // Create a new object with the updated question
  const updatedItem = {
    ...arr[indexToUpdate],
    content: newQuestion,
  };

  // Create a new array with the updated object
  const updatedArray = [
    ...arr.slice(0, indexToUpdate),
    updatedItem,
    ...arr.slice(indexToUpdate + 1),
  ];


  return updatedArray;
};