import { FC } from 'react'
import { useParams } from 'react-router-dom'
import EmailTabAction from './ActionTabs/EmailTabAction/EmailTabAction'
import SMSTabAciton from './ActionTabs/SmsTabAction/SmsTabAction'
import WS1NotificationTabAction from './ActionTabs/WS1TabAction/WS1TabAction'
import { EnumActionType } from '../../Types/ActionTemplate/ActionTemplate'
import { TabBar } from '../../Theme'
import ALNotificationTabAction from './ActionTabs/ALNotificationTabAction/ALNotificationTabAction'



const ViewActionTemplate: FC = () => {
    const params = useParams<{ actionType: string, actionId: string }>()
    const templateId = params.actionId ? parseInt(params.actionId) : 0

    // if (!templateId) return <div><h1>Invalid Template Id</h1></div>

    return <section className='relative'>
        <TabBar />
        {(() => {
            switch (params.actionType) {
                case EnumActionType.EmailAction:
                    return <EmailTabAction templateId={templateId} />;
                case EnumActionType.SMSAction:
                    return <SMSTabAciton templateId={templateId} />;
                case EnumActionType.Ws1notificationAction:
                    return <WS1NotificationTabAction templateId={templateId} />;
                case EnumActionType.ALNotificationAction:
                    return <ALNotificationTabAction templateId={templateId} />;
                default:
                    return <></>;
            }
        })()}


    </section>
}


export default ViewActionTemplate