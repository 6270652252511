import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MdClose } from "react-icons/md";
import { Operand_R } from "../../../Event/Comp/models";
import Button from "../../../General/Button";
import { AiFillInteraction } from "react-icons/ai";
import { MdOutlineExitToApp, MdTaskAlt } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createRule,
  getAllRule,
  getRuleOprands,
} from "../../../../apiQuery/Condition/Rule.apis";
import {
  FlowState,
  ruleBody,
  ruleIds,
  Rules_R,
  State,
  State_R_Single,
} from "../../../../Common/Types";
// import { setSnackbar } from "../../../../GlobalState/store.actions";
import { store } from "../../../../GlobalState/store";
import { getState, updateState } from "../../../../apiQuery/State/State.apis";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import StandardInput from "../../../General/Form/StandardInput";
import { materialControlElements } from "@react-querybuilder/material";
import { Field, QueryBuilder } from "react-querybuilder";
import CustomInput from "../../../Event/Comp/CustomInput";
import CustomSelect from "../../../Event/Comp/CustomSelect";
import initialQuery from "../../../../utils/InitialRules";
import UseQuery from "../../../../Hook/UseQuery";
import moment from "moment";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import AddTimeSince from "../../../Conditions/AddTimeSince";
import { RiTimerFill } from "react-icons/ri";
import { ITimeSince } from "../../../../Types/TimeSince";
import {
  createTimeSince,
  deleteTimeSince,
} from "../../../../apiQuery/Condition/TimeSince.apis";
import { createTimeWithRule } from "../../../../apiQuery/Condition/TimeSinceWithRule.apis";
import AddTimeWithRule from "../../../Conditions/AddTimeWithRule";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IStateTimeCondition } from "../../../../Types/StateTimeCondition";
import { renameRuleName } from "../../../../utils/RuleHelper";
import TimeWithRule from "../../../Conditions/TimeWithRule";
import { toast } from "../../../../utils/Toast";

type props = {
  onClose: () => void;
};

export enum ConditionsType {
  NONE = "",
  // ADD_CONDITION = "ADD_CONDITION",
  ADD_TIME = "ADD_TIME",
  ADD_TIME_WITH_RULE = "ADD_TIME_WITH_RULE",
}

const initialTimeSince = {
  target_state: 0,
  max_time_state: 0,
  time_unit: "minute",
};

const AddConditionModal: FC<props> = memo(({ onClose }) => {
  const params = useParams();

  const id = params.stateSlug || "";
  const pathwaySlug = params.slug || "";


  const { isAdmin } = useContext(UserContext);

  const [conditions, setConditions] = useState<ConditionsType>();

  const [updateStateCondition, setUpdateStateCondition] = useState<string>("");

  // ===== Time Since =====
  const [timeSince, setTimeSince] = useState(initialTimeSince);
  // ===== End Time Since =====

  // ==== Time with rule ====
  const [timeEventRule, setTimeEventRule] = useState({
    isAdded: false,
    isRuleAdded: false,
  });

  const [ruleId, setRuleId] = useState<ruleIds>({
    event: undefined,
    time: undefined,
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm();

  const eventName = getValues("eventName") as string;
  //==== End Time with rule ====

  const { state, dispatch } = useContext(store);



  const organization_id = useMemo(
    () => state.organization_id,
    [state.organization_id]
  );

  const [showNewCondition, setShowNewCondition] = useState(false);
  const [showCheckIn, setCheckIn] = useState(false);

  const [rule, setRule] = useState<number | null | undefined>(null);
  const [query, setQuery] = useState(initialQuery);
  const [isDeletingCondition, setIsDeletingCondition] =
    useState<boolean>(false);

  // selected rule json from the dropdown of the rules
  const [selectedRuleJson, setSelectedRuleJson] = useState<any>();

  const queryClient = useQueryClient();

  const [timeEvent, setTimeEvent] = useState({
    value: 0,
    type: "minute",
    isAdded: false,
    isRuleAdded: false,
    ruleName: "",
    name: "",
    idVal: "",
  });

  const stateData = useQuery<State_R_Single, Error>(
    ["state", id],
    () => getState(pathwaySlug, id),
    {}
  );

  const stateId = useMemo(() => {
    return stateData?.data?.data.state.id;
  },[stateData?.data?.data.state.id])


  const savedStateTimeCondition: null | IStateTimeCondition =
    stateData?.data?.data.state.state_time_conditions &&
    stateData?.data?.data.state.state_time_conditions[0];


  useEffect(() => {
    if (
      savedStateTimeCondition &&
      savedStateTimeCondition?.rule_json === null
    ) {
      setConditions(ConditionsType.ADD_TIME);
      setTimeSince((prev) => {
        return {
          ...prev,
          target_state: savedStateTimeCondition.target_state,
          max_time_state: savedStateTimeCondition.max_time_state,
          time_unit: savedStateTimeCondition.time_unit,
        };
      });
      setUpdateStateCondition("update_time_add_time_condition");

    }
    if (
      savedStateTimeCondition &&
      savedStateTimeCondition?.rule_json !== null
    ) {
  
      setConditions(ConditionsType.ADD_TIME_WITH_RULE);
      setTimeSince((prev) => {
        return {
          ...prev,
          target_state: savedStateTimeCondition.target_state,
          max_time_state: savedStateTimeCondition.max_time_state,
          time_unit: savedStateTimeCondition.time_unit,
        };
      });
      setUpdateStateCondition("update_time_add_time_condition_rule");
    }
  }, [savedStateTimeCondition]);

  const { isLoading, data, error } = useQuery<Rules_R, Error>(
    ["all-state-rules", organization_id],
    () => getAllRule(organization_id)
  );


  const {
    isLoading: operandsLoading,
    data: operands,
    error: operandsError,
  } = useQuery<Operand_R, Error>("operands", getRuleOprands);

  const createRuleCondition = useMutation(
    (ruleData: ruleBody) => createRule({ ...ruleData }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          "all-state-rules",
          organization_id,
        ]);
      },
      onError: (error: null | Error) => {
        const message = error?.message || "Failed creating State Rule";
        // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
        toast(message, "error");
      },
    }
  );

  const handleRule = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.value);
    if (id === 0) setShowNewCondition(true);
    setRule(id);
  };

  const updateCurrentState = useMutation(
    (stateData: State) =>
      updateState(pathwaySlug, id, {
        ...stateData,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["state", id]);
        // dispatch(setSnackbar({ isOpen: true, type: 'success', message: 'Rule has been added to this State' }))
        toast("Rule has been added to this State", "success");
        onClose();
      },
      onError: (error: any) => {
        const message =
          typeof error.response !== "undefined"
            ? error?.response?.data?.message
            : error?.message || "Cannot update the state";
        // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
        toast(message, "error");
      },
    }
  );
  // Time Since Mutation
  const createTimeSinceForCurrentState = useMutation(
    (timeSince: ITimeSince) => createTimeSince({ ...timeSince }),
    {
      onSuccess: async () => {
        // await queryClient.invalidateQueries(['state', id])
        // dispatch(setSnackbar({ isOpen: true, type: 'success', message: 'Time Since has been added to this State' }))
        toast("Time Since has been added to this State", "success");
        onClose();
      },
      // state_id: Number(id)
      onError: (error: any) => {
        const message =
          typeof error.response !== "undefined"
            ? error?.response?.data?.message
            : error?.message || "Cannot update the state";
        // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
        toast(message, "error");
      },
    }
  );

  const newEventCondition = useMemo(
    () => [
      {
        id: 0,
        rule_name: "New Condition",
      },
    ],
    []
  );

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTimeEvent &&
    setTimeEvent((prev) => ({
      ...prev,
      ruleName: e.target.value,
    }));

  const handleChangeIdVal = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTimeEvent &&
    setTimeEvent((prev) => ({
      ...prev,
      idVal: e.target.value,
    }));

  const { json } = UseQuery(rule, data?.data);

  const submit = async () => {
    // check is admin
    if (!isAdmin()) {
      // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'You are not authorized to perform this action' }))
      toast("You are not authorized to perform this action", "error");
      return;
    }

    // if (conditions === ConditionsType.ADD_CONDITION) {
    if (rule === 0 && timeEvent.ruleName === "")
      return alert("Please enter the name for the Rule");

    if (rule === undefined) return;
    if (rule === 0) {
      let value = undefined as any;
      const modifuObj = query?.rules?.map((item: any) => {
        if (
          [
            "OP.FirstDayOfWork",
            "OP.EffectiveDate",
            "OP.HireDateWorker",
          ].includes(item.field)
        ) {
          if (value === undefined) value = item?.field?.replaceAll("OP.", "");
        }
        return {
          ...item,
          value: [
            "OP.FirstDayOfWork",
            "OP.EffectiveDate",
            "OP.HireDateWorker",
          ].includes(item.field)
            ? moment(item.value).format("MM-DD-YYYY")
            : item.value,
        };
      });
      const deliver_time =
        timeEvent.type === "days" ? 24 * timeEvent.value : timeEvent.value || 5;
      const deliver_unit =
        timeEvent.type === "days" ? "hour" : timeEvent.type || "minute";

      const fullData = {
        deliver_time: deliver_time,
        deliver_unit: deliver_unit,
        rule_json: {
          desc: "rule delay " + renameRuleName(timeEvent.ruleName),
          name: renameRuleName(timeEvent.ruleName),
          when:
            query.rules.length === 1
              ? {
                  // @ts-ignore
                  [query.rules[0]?.operator || ""]: [
                    {
                      //@ts-ignore
                      obj: modifuObj[0]?.field
                        ? modifuObj[0]?.field === "OP.CheckInArray"
                          ? `OP.CheckInArray('${timeEvent.idVal}',OP.ManagementChainEmployeeIDs)`
                          : modifuObj[0]?.field
                        : "",
                    },
                    {
                      //@ts-ignore
                      const:
                        typeof modifuObj[0]?.value === "boolean"
                          ? modifuObj[0]?.value
                          : modifuObj[0]?.value || "",
                    },
                  ],
                }
              : {
                  [query.combinator]: modifuObj.map((item) => {
                    return {
                      //@ts-ignore
                      [item?.operator || ""]: [
                        {
                          //@ts-ignore
                          obj: item?.field
                            ? item?.field === "OP.CheckInArray"
                              ? `OP.CheckInArray('${timeEvent.idVal}',OP.ManagementChainEmployeeIDs)`
                              : item?.field
                            : "",
                        },
                        {
                          //@ts-ignore
                          const:
                            typeof item?.value === "boolean"
                              ? item?.value
                              : item?.value || "",
                        },
                      ],
                    };
                  }),
                },
          salience: 0,
        },
      };

      let ruleId = null;
      if (query.rules.length !== 0) {
        const { data } = await createRuleCondition.mutateAsync({
          // organization_id: organization_id,
          ...fullData,
        });
        ruleId = data?.id || null;
      }
      // @ts-ignore
      await updateCurrentState.mutateAsync({ rules_state_id: ruleId });
    } else {
      // @ts-ignore
      await updateCurrentState.mutateAsync({ rules_state_id: rule });
    }
    // }
  };

  const deleteATimeSinceMutation = useMutation(
    () => deleteTimeSince(savedStateTimeCondition?.id as number),
    {
      onSuccess: async () => {
        toast("Time Since has been deleted", "success");
      },
      onError: (error: any) => {
        const message =
          typeof error.response !== "undefined"
            ? error?.response?.data?.message
            : error?.message || "Cannot delete the time since";
        toast(message, "error");
      },
    }
  );

  // Exit State Submit

  const submitExitState = async () => {
    if (!isAdmin()) {
      toast("You are not authorized to perform this action", "error");
      return;
    }

    if (conditions === ConditionsType.ADD_TIME_WITH_RULE) {
      // ruleId === 0 means is it for the create new rule with time condition

      // ==================== create new rule with time condition =======================
      // alert('test' + ruleId.event === 0); => undifined
      if (timeWithRuleData.rule === 0) {
        // ====>  if there was a time condition before, delete it
        // if (savedStateTimeCondition) {
        // 	stateData.refetch()
        // 	await deleteATimeSinceMutation.mutateAsync();
        // }

        //================= Validate rule name =================
        const ruleName = timeWithRuleData.ruleName;

        if (ruleName === "") {
          // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Please enter a rule name' }))
          toast("Please enter a rule name", "error");
          return;
        }
        if (ruleName.length < 5) {
          // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Rule name must be at least 5 characters' }))
          toast("Rule name must be at least 5 characters", "error");
          return;
        }

        if (timeSince.target_state === -1 || timeSince.target_state === 0) {
          // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Please select target state' }))
          toast("Please select target state", "error");
          return;
        }

        if (timeSince.max_time_state < 0) {
          // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Please enter a valid Time Until Transition' }))
          toast("Please enter a valid Time Until Transition", "error");
          return;
        }
        // ==================== create new rule with time condition =======================

        let value = undefined as any;
        const modifuObj = query?.rules?.map((item: any) => {
          if (
            [
              "OP.FirstDayOfWork",
              "OP.EffectiveDate",
              "OP.HireDateWorker",
            ].includes(item.field)
          ) {
            if (value === undefined) value = item?.field?.replaceAll("OP.", "");
          }
          return {
            ...item,
            value: [
              "OP.FirstDayOfWork",
              "OP.EffectiveDate",
              "OP.HireDateWorker",
            ].includes(item.field)
              ? moment(item.value).format("MM-DD-YYYY")
              : item.value,
          };
        });

        const renamedRuleName = renameRuleName(ruleName);

        const fullData = {
          rule_json: {
            desc: "rule delay " + renamedRuleName,
            name: renamedRuleName,
            when:
              query.rules.length === 1
                ? {
                    // @ts-ignore
                    [query.rules[0]?.operator || ""]: [
                      {
                        //@ts-ignore
                        obj: modifuObj[0]?.field
                          ? modifuObj[0]?.field === "OP.CheckInArray"
                            ? `OP.CheckInArray('${timeEvent.idVal}',OP.ManagementChainEmployeeIDs)`
                            : modifuObj[0]?.field
                          : "",
                      },
                      {
                        //@ts-ignore
                        const:
                          typeof modifuObj[0]?.value === "boolean"
                            ? modifuObj[0]?.value
                            : modifuObj[0]?.value || "",
                      },
                    ],
                  }
                : {
                    [query.combinator]: modifuObj.map((item) => {
                      return {
                        //@ts-ignore
                        [item?.operator || ""]: [
                          {
                            //@ts-ignore
                            obj: item?.field
                              ? item?.field === "OP.CheckInArray"
                                ? `OP.CheckInArray('${timeEvent.idVal}',OP.ManagementChainEmployeeIDs)`
                                : item?.field
                              : "",
                          },
                          {
                            //@ts-ignore
                            const:
                              typeof item?.value === "boolean"
                                ? item?.value
                                : item?.value || "",
                          },
                        ],
                      };
                    }),
                  },
            salience: 0,
          },
        };

        const updated_max_state =
          timeSince.time_unit === "day"
            ? timeSince.max_time_state * 24
            : timeSince.max_time_state;
        const updated_time_unit =
          timeSince.time_unit === "day" ? "minute" : timeSince.time_unit;

        // TODO: check slug
        const shapeData = {
          // state_id: Number(id),
          state_id: stateId as number,
          target_state: timeSince.target_state,
          max_time_state: updated_max_state,
          time_unit: updated_time_unit,
          rule_json: {...fullData.rule_json, name: renameRuleName(fullData.rule_json.name) } ,
        };
        await createTimeSinceWithRuleReq.mutateAsync(shapeData);
      } else {
        // ================= delete the previous time condition =================
        // if a previous time condition exists, delete it
        if (savedStateTimeCondition) {
          stateData.refetch();
          await deleteATimeSinceMutation.mutateAsync();
        }

        if (timeSince.target_state === -1 || timeSince.target_state === 0) {
          // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Please select target state' }))
          toast("Please select target state", "error");
          return;
        }

        if (timeSince.max_time_state < 0) {
          // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Please enter a valid Time Until Transition' }))
          toast("Please enter a valid Time Until Transition", "error");
          return;
        }

        // choose from the existing rule
        let value = undefined as any;
        const modifuObj = selectedRuleJson?.rules?.map((item: any) => {
          if (
            [
              "OP.FirstDayOfWork",
              "OP.EffectiveDate",
              "OP.HireDateWorker",
            ].includes(item.field)
          ) {
            if (value === undefined) value = item?.field?.replaceAll("OP.", "");
          }
          return {
            ...item,
            value: [
              "OP.FirstDayOfWork",
              "OP.EffectiveDate",
              "OP.HireDateWorker",
            ].includes(item.field)
              ? moment(item.value).format("MM-DD-YYYY")
              : item.value,
          };
        });

        const operator: string = selectedRuleJson.combinator;

        const sendNameAsIdentifier = timeWithRuleData.rule
          ? data?.data.filter((item) => item.id === timeWithRuleData.rule)[0]
              .rule_name
          : "state_time_condition";

        const buildDescription = timeWithRuleData.rule.toString()
          ? timeWithRuleData.rule.toString()
          : "";

        // create a full data from selectedRuleJson
        const fullData = {
          rule_json: {
            desc: "rule delay " + buildDescription,
            name: sendNameAsIdentifier,
            when:
              selectedRuleJson.rules.length === 1
                ? {
                    // @ts-ignore
                    [selectedRuleJson.rules[0]?.operator || operator]: [
                      {
                        //@ts-ignore
                        obj: modifuObj[0]?.field
                          ? modifuObj[0]?.field === "OP.CheckInArray"
                            ? `OP.CheckInArray('${timeEvent.idVal}',OP.ManagementChainEmployeeIDs)`
                            : modifuObj[0]?.field
                          : "",
                      },
                      {
                        //@ts-ignore
                        const:
                          typeof modifuObj[0]?.value === "boolean"
                            ? modifuObj[0]?.value
                            : modifuObj[0]?.value || "",
                      },
                    ],
                  }
                : {
                    [operator]: modifuObj.map((item: any) => {
                      return {
                        //@ts-ignore
                        [item?.operator || ""]: [
                          {
                            //@ts-ignore
                            obj: item?.field
                              ? item?.field === "OP.CheckInArray"
                                ? `OP.CheckInArray('${timeEvent.idVal}',OP.ManagementChainEmployeeIDs)`
                                : item?.field
                              : "",
                          },
                          {
                            //@ts-ignore
                            const:
                              typeof item?.value === "boolean"
                                ? item?.value
                                : item?.value || "",
                          },
                        ],
                      };
                    }),
                  },
            salience: 0,
          },
        };

        const updated_max =
          timeSince.time_unit === "day"
            ? timeSince.max_time_state * 24
            : timeSince.max_time_state;
        const updated_time_unit =
          timeSince.time_unit === "day" ? "minute" : timeSince.time_unit;

          // check Slug
        const shapeData = {
          // state_id: Number(id),
          state_id: stateId as number,
          target_state: timeSince.target_state,
          max_time_state: updated_max,
          time_unit: updated_time_unit,
          rule_json: fullData.rule_json,
        };
        await createTimeSinceWithRuleReq.mutateAsync(shapeData);
      }
    }

    if (conditions === ConditionsType.ADD_TIME) {
      // if (savedStateTimeCondition) {
      // 	dispatch(setSnackbar({ isOpen: true, type: 'error', message: "You can't add more than one time condition" }))
      // 	return
      // }

      // check the max time state more equal 0 or more than 0
      if (timeSince.max_time_state <= 0) {
        // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Max time Transition must be more than equal 0 or more than' }))
        toast(
          "Max time Transition must be more than equal 0 or more than",
          "error"
        );
        return;
      }

      if (timeSince.target_state === -1 || timeSince.target_state === 0) {
        // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Please select target state' }))
        toast("Please select target state", "error");
        return;
      }

      if (updateStateCondition === "update_time_add_time_condition") {
        await deleteATimeSinceMutation.mutateAsync();
        await queryClient.invalidateQueries(["state", id]);
        stateData.refetch();
      }

      const updated_max =
        timeSince.time_unit === "day"
          ? timeSince.max_time_state * 24
          : timeSince.max_time_state;
      const updated_time_unit =
        timeSince.time_unit === "day" ? "minute" : timeSince.time_unit;

      // send request to backend with time since data.
      // TODO: check slug
      const timeSinceObj: ITimeSince = {
        state_id: stateId as number,
        target_state: timeSince.target_state,
        max_time_state: updated_max,
        time_unit: updated_time_unit,
      };
  
      await createTimeSinceForCurrentState.mutateAsync(timeSinceObj);
    }

    if (savedStateTimeCondition && isDeletingCondition) {
      await deleteATimeSinceMutation.mutateAsync();
    }

    await queryClient.invalidateQueries(["state", id]);
    onClose();
  };

  const createTimeSinceWithRuleReq = useMutation(
    (data: any) => createTimeWithRule(data),
    {
      onSuccess: (data) => {
        // dispatch(setSnackbar({isOpen: true, type: 'error', message: 'You are not authorized to perform this action'}))
  
        // dispatch(setSnackbar({ isOpen: true, type: 'success', message: 'Time with rule is set successfully' }))
        toast("Time with rule is set successfully", "success");
      },
      onError: (error) => {
    
        // @ts-ignore
        const message = error?.message || "Failed creating Rule";
        // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
        toast(message, "error");
      },
    }
  );

  //@ts-ignore
  const fields: Field[] = useMemo(() => {
    return operands?.data?.map((item) => {
      return {
        name: item.value,
        label: item.display,
        operators:
          item.data_type === "string"
            ? [
                { name: "eq", label: "=" },
                { name: "not", label: "!=" },
              ]
            : [{ name: "eq", label: "=" }],
        valueEditorType: item.data_type === "string" ? "text" : "checkbox",
        inputType:
          [
            "OP.FirstDayOfWork",
            "OP.EffectiveDate",
            "OP.HireDateWorker",
          ].includes(item.value) && "date",
        defaultValue: item.data_type === "string" ? "" : false,
      };
    });
  }, [operands?.data]);

  useEffect(() => {
    if (stateData?.data?.data?.state) {
      setRule(stateData?.data?.data?.state?.rules_state_id);
    }
  }, [stateData?.data]);

  useEffect(() => {
    if (query.rules.find((item: any) => item.field === "OP.CheckInArray"))
      setCheckIn(true);
    else setCheckIn(false);
  }, [query.rules]);

  // Time Since Selection Handlers Start Here
  const handleSelectedTargetState = (targetState: number) => {

    setTimeSince((prev) => {
      return {
        ...prev,
        target_state: targetState,
      };
    });
  };
  const handleSelectedTimeUntilTransition = (maxTimeState: number) => {
    setTimeSince((prev) => {
      return {
        ...prev,
        max_time_state: maxTimeState,
      };
    });
  };
  const handleSelectedTimeUnit = (timeUnit: string) => {
    setTimeSince((prev) => {
      return {
        ...prev,
        time_unit: timeUnit,
      };
    });
  };

  const handleSelectedDisplayUnit = (displayUnit: string) => {
    setTimeSince((prev) => {
      return {
        ...prev,
        time_unit: displayUnit,
      };
    });
  };

  const handleSelectedRuleDropDown = (rule: any) => {

    setSelectedRuleJson(rule);
  };

  // =================== Time With Rule ===========================================

  const [timeWithRuleData, setTimeWithRuleData] = useState({
    ruleName: "",
    rule: 0,
  });

  const handleSelectChooseRuleName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTimeWithRuleData((prev) => {
      return {
        ...prev,
        ruleName: e.target.value,
      };
    });
  };

  const handleSelectRule = (rule: number) => {
    setTimeWithRuleData((prev) => {
      return {
        ...prev,
        rule,
      };
    });
  };


  // =================== End Time With Rule ===========================================
  return (
    <div className="relative flex w-8/12 h-full p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md modal">
      {data?.data ? (
        <div className="w-full space-y-4">
          <div className="flex items-center justify-between w-full pb-4 text-lg">
            <p className="text-2xl ">
              Add Conditions to Enter and Exit this state.
            </p>
            <div
              onClick={onClose}
              className="p-1 bg-gray-400 rounded-full cursor-pointer"
            >
              <MdClose className="text-white" />
            </div>
          </div>

          <div className="py-3 px-3 border border-[#D8D8D8] bg-blue-100 rounded-md space-y-4">
            {/*{isAdmin() && (*/}
            <div className="flex">
              <Button
                // disabled={conditions !== ConditionsType.ADD_CONDITION && conditions !== ConditionsType.NONE}
                // disabled={conditions === ConditionsType.ADD_CONDITION ? false : true}
                onClick={() => {
                  // rule !== null ? setRule(null) : setRule(stateData?.data?.data?.state?.rules_state_id || 1)
                  if (rule !== null) {
                    // setConditions(ConditionsType.NONE)
                    setRule(null);
                  } else {
                    setRule(stateData?.data?.data?.state?.rules_state_id || 1);
                    // setConditions(ConditionsType.ADD_CONDITION)
                  }
                }}
                label={`${
                  rule === null ? "Add Condition" : "Remove Condition"
                } `}
                extraClasses="w-fit px-3 py-3 text-white mr-2"
              />
            </div>

            {rule !== null ? (
              <>
                {!showNewCondition ? (
                  <TextField
                    defaultValue={stateData?.data?.data?.state?.rules_state_id}
                    fullWidth
                    select
                    label="Select Rule"
                    value={rule}
                    onChange={handleRule}
                  >
                    {(data?.data
                      ? [...data?.data, ...newEventCondition]
                      : [...newEventCondition]
                    )?.map((option, ind) => (
                      <MenuItem key={ind} value={option.id}>
                        {option.rule_name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <div className="w-full p-2 rounded-md">
                    {operandsLoading && (
                      <div className="flex items-center justify-center w-full p-5">
                        <svg
                          className="w-6 h-6 text-blue-700 animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    )}
                    <div>
                      <div className="py-2">
                        <StandardInput
                          label="State condition name"
                          value={timeEvent.ruleName}
                          onChange={handleChangeName}
                        />
                      </div>
                      <QueryBuilder
                        controlElements={{
                          ...materialControlElements,
                          addGroupAction: () => null,
                        }}
                        fields={fields}
                        query={query}
                        onQueryChange={(q) => setQuery(q)}
                      />

                      {showCheckIn && (
                        <div className="grid w-full grid-cols-2 pt-2 gap-x-3">
                          <StandardInput
                            label="Value"
                            onChange={handleChangeIdVal}
                            value={timeEvent.idVal}
                          />

                          <CustomSelect
                            onChange={() => {}}
                            classNames="bg-white rounded-md pl-2"
                            value={"OP.ManagementChainEmployeeIDs"}
                            options={[
                              {
                                value: "OP.ManagementChainEmployeeIDs",
                                title: "OP.ManagementChainEmployeeIDs",
                              },
                            ]}
                          />
                        </div>
                      )}

                      <div className="flex items-center py-4 space-x-1">
                        <span>Deliver this content</span>
                        <div className="w-20">
                          <CustomInput
                            value={timeEvent.value}
                            type={"number"}
                            onChange={(value) =>
                              setTimeEvent &&
                              setTimeEvent((prev) => ({
                                ...prev,
                                value: parseInt(value),
                              }))
                            }
                          />
                        </div>

                        <CustomSelect
                          classNames="bg-white rounded-md pl-2"
                          value={timeEvent?.type || ""}
                          options={[
                            { value: "minute", title: "minutes" },
                            { value: "hour", title: "hours" },
                            { value: "days", title: "days" },
                          ]}
                          onChange={(value) =>
                            setTimeEvent &&
                            setTimeEvent((prev) => ({
                              ...prev,
                              type: value,
                            }))
                          }
                        />
                        <span>after a Worker enters this State</span>
                      </div>
                    </div>
                  </div>
                )}

                {json && rule ? (
                  <div>
                    This condition has {json?.deliver_time || "no"}{" "}
                    {json?.deliver_time ? json?.deliver_unit : null} time offset
                    and is delivered when{" "}
                    {json.rules?.map((item: any, index: number) => (
                      <span className="inline" key={index}>
                        {" "}
                        {item.field}{" "}
                        {item.operator === "eq"
                          ? "="
                          : item.operator === "not"
                          ? "!="
                          : item.operator === "not"
                          ? "!="
                          : json.combinator === "eq"
                          ? "="
                          : json.combinator === "not"
                          ? "!="
                          : null}{" "}
                        {String(item.value)}{" "}
                        {json.rules.length - 1 !== index
                          ? json.combinator.toUpperCase()
                          : null}
                      </span>
                    ))}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>

          <Button
            disabled={
              createRuleCondition.isLoading || updateCurrentState.isLoading
            }
            onClick={submit}
            label={
              <div className="flex items-center space-x-2 text-white">
                <MdTaskAlt className="text-3xl" />
                <p>Set State Entry Condition</p>
              </div>
            }
            extraClasses="w-fit px-3 py-3"
          />

          <br />
          <br />
          {/* EXIT STATE  */}

          <div className="py-3 px-3 border border-[#D8D8D8] bg-blue-100 rounded-md space-y-4">
            <div className="flex">
              <Button
                // disabled={conditions !== ConditionsType.ADD_TIME && conditions !== ConditionsType.NONE}
                onClick={() => {
                  if (conditions === ConditionsType.ADD_TIME) {
                    setConditions(ConditionsType.NONE);
                  } else {
                    setConditions(ConditionsType.ADD_TIME);
                  }
                }}
                label={
                  <div className="flex items-center space-x-2 text-white">
                    <RiTimerFill className="text-lg" />
                    {conditions === ConditionsType.ADD_TIME ? (
                      <p
                        onClick={async () => {
                          setConditions(ConditionsType.ADD_TIME);
                          setIsDeletingCondition(true);
            
                        }}
                      >
                        Remove Time Since Condition
                      </p>
                    ) : (
                      <p>Deliver based on Time</p>
                    )}
                  </div>
                }
                extraClasses="w-fit p-2"
              />

              <Button
                disabled={
                  conditions !== ConditionsType.ADD_TIME_WITH_RULE &&
                  conditions !== ConditionsType.NONE
                }
                onClick={() => {
                  if (conditions === ConditionsType.ADD_TIME_WITH_RULE) {
                    setConditions(ConditionsType.NONE);
                  } else {
                    setConditions(ConditionsType.ADD_TIME_WITH_RULE);
                  }
                }}
                label={
                  <div className="flex items-center space-x-2 text-white">
                    <AiFillInteraction className="text-lg" />
                    {conditions === ConditionsType.ADD_TIME_WITH_RULE ? (
                      <p
                        onClick={() => {
                          setConditions(ConditionsType.ADD_TIME_WITH_RULE);
                          setIsDeletingCondition(true);
                        }}
                      >
                        Remove Time With Rule Condition
                      </p>
                    ) : (
                      <p>Deliver based on Time With Rule</p>
                    )}
                  </div>
                }
                extraClasses="w-fit p-2 ml-2"
              />
            </div>

            {conditions === ConditionsType.ADD_TIME ? (
              <div className="py-3 px-3 border border-[#D8D8D8] bg-blue-100 rounded-md">
                <div className="w-full p-2 space-y-3 rounded-md">
                  <AddTimeSince
                    savedStateTimeCondition={savedStateTimeCondition}
                    StatesList={
                      state.currentFlow.states?.filter(
                        (state) => state.slug !== id
                      ) as State[]
                    }
                    onSelectedTargetState={handleSelectedTargetState}
                    onSelectedTimeUntilTransition={
                      handleSelectedTimeUntilTransition
                    }
                    onSelectedTimeUnit={handleSelectedTimeUnit}
                    onSelectedDisplayUnit={handleSelectedDisplayUnit}
                  />
                </div>
              </div>
            ) : null}

            {conditions === ConditionsType.ADD_TIME_WITH_RULE ? (
              <div className="py-3 px-3 border border-[#D8D8D8] bg-blue-100 rounded-md">
                <div className="w-full p-2 space-y-3 rounded-md">
                  {/* <AddTimeWithRule
												query={query}
												setQuery={setQuery}
												name={'TimeWithRule'}
												typeOf={'timeWithRule'}
												rule={ruleId}
												setRule={setRuleId}
												setTimeEvent={setTimeEventRule}
												isContent
												register={register}
												errors={errors}
												onSelectedJsonRule={handleSelectedRuleDropDown}
											/> */}

                  {/* ============================ TIME WITH RULE =================================== */}

                  <TimeWithRule
                    // stateId={id}
                    stateId={stateId?.toString() as string}
                    onChooseRuleName={handleSelectChooseRuleName}
                    onSelectRule={handleSelectRule}
                    onSelectedJsonRule={handleSelectedRuleDropDown}
                    query={query}
                    setQuery={setQuery}
                    isConditionCraetedBefore={
                      savedStateTimeCondition &&
                      savedStateTimeCondition?.rule_json !== null
                        ? true
                        : false
                    }
                  />

                  <div>
                    {/* {(savedStateTimeCondition && savedStateTimeCondition.rule_json !== null) ? <div>This condition
													when {savedStateTimeCondition.rule_json?.map((item: any, index: number) => {
														const operator = Object.keys(item.when)[0]
														const values: any = Object.values(item.when)[0]
														return <span className='inline' key={index}>
															{values.map((v: any, i: number) => {
																return Object.values(v).map((v: any, index: number) => {
																	return <span key={index}>
																		{v} {i === 0 ? (operator === "eq" ? " = " : operator === " not " ? " != " : "") : ' '}
																	</span>
																})
															})}
														</span>
													})}</div> : null} */}

                    {/* {timeWithRuleData.rule ? data?.data.filter(item => item.id === timeWithRuleData.rule)[0].rule_name : 'Noooo'} */}

                    {savedStateTimeCondition &&
                    savedStateTimeCondition.rule_json !== null ? (
                      <div>
                        <div>
                          {" "}
                          <span className="text-blue-600">Rule Name:</span>{" "}
                          <strong>
                            {savedStateTimeCondition.rule_json[0].name}
                          </strong>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* ==========================  END TIME WITH RULE ========================================== */}
                  <AddTimeSince
                    savedStateTimeCondition={savedStateTimeCondition}
                    StatesList={
                      state.currentFlow.states?.filter(
                        (state) => state.slug !== id
                      ) as State[]
                    }
                    onSelectedTargetState={handleSelectedTargetState}
                    onSelectedTimeUntilTransition={
                      handleSelectedTimeUntilTransition
                    }
                    onSelectedTimeUnit={handleSelectedTimeUnit}
                    onSelectedDisplayUnit={handleSelectedDisplayUnit}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <Button
            // disabled={createRuleCondition.isLoading || updateCurrentState.isLoading}
            onClick={submitExitState}
            label={
              <div className="flex items-center space-x-2 text-white">
                <MdOutlineExitToApp className="text-3xl" />
                <p>Set State Exit Condition</p>
              </div>
            }
            extraClasses="w-fit px-3 py-3"
          />

          {/*  EXIT STATE	*/}
        </div>
      ) : null}
    </div>
  );
});

export default AddConditionModal;
