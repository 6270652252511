import React, {useContext, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import "./_pathway.css";
import Input from "../General/Form/Input";
import TextArea from "../General/Form/TextArea";
import Switch from '@mui/material/Switch';
import Button from "../General/Button";
import {TFlow} from "../../Common/Types";
import {useMutation, useQueryClient} from "react-query";
import {createFlowState} from "../../apiQuery/StateFlow/StateFlow.apis";
import {SubmitHandler, useForm} from "react-hook-form";
import Tabs from "../TabsT/Tabs";
import Tab from "../TabsT/Tab";
import PageHeader from "../Layouts/PageHeader";
import UseTab from "../../Hook/UseTab";
import {store} from "../../GlobalState/store";
import {BiArrowBack} from "react-icons/bi";
import { withAuth } from '../../hoc/withAuth';
import { useTranslation } from 'react-i18next';

const NewPathway = () => {
    const { t, i18n } = useTranslation();

    const {
        state: globalState
    } = useContext(store);

    const organization_id = useMemo(() => globalState.organization_id, [globalState.organization_id])
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const {register, handleSubmit, formState: {errors}} = useForm<TFlow>();
    const onSubmit: SubmitHandler<TFlow> = data => addStateFlowMutation.mutate(data);
    const [tab, setTab] = UseTab('Details')

    const addStateFlowMutation = useMutation((newStateFlow: TFlow) => createFlowState(globalState.organization_id, {
        ...newStateFlow,
        // organization_id
    }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries('flow-state')
            navigate('/pathways')
        }
    })

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/*---------------------------------------------------------*/}
                {/*New Pathway page header with confirm/cancel actions*/}
                <PageHeader
                    title={`${t('PathwayPage.New_Pathway')}`}
                    tabs={
                        // @ts-ignore
                        <Tabs selectedTab={tab} setSelectedTab={setTab}>
                            <Tab TabKey='Details' title={`${t('PathwayPage.Details')}`}>{`${t('PathwayPage.Details')}`}</Tab>
                            <Tab TabKey='' title=""></Tab>
                        </Tabs>
                    }
                    button={
                        <div className="flex space-x-3">
                            <Button onClick={()=>navigate(-1)} icon={<BiArrowBack className="mr-2 text-xl"/>} label={`${t('PathwayPage.Go_Back')}`} extraClasses="w-fit px-3 py-3 bg-blue-400 text-white"/>
                            <Button disabled={addStateFlowMutation.isLoading} type="submit" label={`${t('PathwayPage.Create_Pathway')}`} extraClasses="w-fit px-3 py-3 bg-blue-400 text-white"/>
                        </div>
                    }
                />

                {/*---------------------------------------------------------*/}
                {/*New Pathway form inputs*/}
                <div className="w-11/12 py-5 m-auto space-y-5 text-gray-600 text-md">
                    <Input
                        register={() => register("name", {required: 'Pathway name is required'})}
                        name="name"
                        placeholder={`${t('PathwayPage.Pathway_Name')}`}
                        errors={errors}
                        extraClasses="w-2/5"/>
                    <TextArea
                        register={() => register("description")}
                        name="description"
                        placeholder={`${t('PathwayPage.Pathway_Description')}`}
                        errors={errors}
                        extraClasses="w-2/5"/>
                    <div className="flex flex-col space-y-2">
                        <span>{`${t('PathwayPage.Pathway_Active')}`}</span>
                        <Switch {...register("status")} color="primary"/>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <span>{`${t('PathwayPage.Allow_Multiple_Participants')}`}</span>
                        <Switch {...register("allow_multiple_participants")} color="primary"/>
                    </div>
                </div>

            </form>
        </>
    );
};

export default withAuth(NewPathway);