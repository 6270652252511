import { Conditions_T } from "../../Common/Types";
import API from "../index";

export const createTimeBaseEvent = async (organ: number, condition: Conditions_T) => {
    const { data } = await API.post(`/admin/organizations/conditions/`, { ...condition });
    return data;
};

export const getAllTimeEvent = async (organ: number) => {
    const { data } = await API.get(`admin/organizations/conditions/`);
    return data;
};

export const deleteTimeCondition = async (organ: number, time_condition_id: number) => {
    const { data } = await API.delete(`admin/organizations/conditions/${time_condition_id}`);
    return data;
};

export const updateTimeCondition = async (organ: number, time_condition_id: number, condition: Conditions_T) => {
    const { data } = await API.put(`admin/organizations/conditions/${time_condition_id}`, { ...condition });
    return data;
};