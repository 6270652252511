import React, { forwardRef } from "react";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import FedexLogo from "../../assets/fdx-logo.jpg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { Moment } from "moment";
// import Date from 'react-datepicker'

function generateCode(length: number) {
  let result = "";
  let characters = "0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result.toString();
}

enum CurrentFormState {
  "VERIFY_PHONE" = "Send SMS",
  "VERIFY_CODE" = "Verify Code",
  "EMPLOYEE_INFO" = "Submit",
}

interface Message {
  text: string;
  type: "success" | "error";
}

interface EmployeeState {
  code: string;
  verifyCode: string;
  employee_id: string;
  phone_number: string;
  start_date: string;
  state: CurrentFormState;
  isSubmitted: boolean;
}

// Method to get the year from a date object
const getYear = (date: any) => {
  return date.getFullYear();
};

// Method to generate an array of numbers in a given range
const range = (start: any, end: any, step = 1) => {
  const arr = [];
  for (let i = start; i < end; i += step) {
    arr.push(i);
  }
  return arr;
};
const years = range(1970, getYear(new Date()) + 1, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const getMonth = (date: any) => {
  return date.getMonth();
};

const getFormattedDate = (date: any) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Pad the month and day with leading zeros if necessary
  const formattedMonth = month.toString().padStart(2, "0");
  const formattedDay = day.toString().padStart(2, "0");

  return `${year}-${formattedMonth}-${formattedDay}`;
};

// @ts-ignore
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  // @ts-ignore
  <input
    inputMode="none"
    onClick={onClick}
    // @ts-ignore
    ref={ref}
    value={value}
    className="w-full py-2 pl-2 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-lg sm:text-base focus:outline-none focus:border-blue-400"
  ></input>
));

const Employee: React.FC = () => {

  const [state, setState] = useState<EmployeeState>({
    code: "",
    verifyCode: "",
    employee_id: "",
    phone_number: "",
    start_date: "",
    state: CurrentFormState.VERIFY_PHONE,
    isSubmitted: false,
  });

  const [message, setMessage] = useState<Message>({
    type: "success",
    text: "",
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (state.state === CurrentFormState.VERIFY_PHONE) {
      const smsCode = generateCode(6).toLowerCase();

      setState((prev) => {
        return {
          ...prev,
          verifyCode: smsCode,
        };
      });

      // check employee phone number have length 9 and are all numbers are digits
      if (
        state.phone_number.length !== 10 ||
        !/^\d+$/.test(state.phone_number)
      ) {
        setMessage({
          type: "error",
          text: "Please enter a valid phone number.",
        });
        return;
      }

      const reqUrl = process.env.REACT_APP_API_URL + "send-sms";
      const reqJson = {
        recipient_phone_number: state.phone_number,
        message: `${smsCode} is your Verification for Action Logics`,
      };
      setMessage({
        type: "success",
        text: `A verification has been sent to: ${state.phone_number}`,
      });

      setState((prev) => {
        return { ...prev, isSubmitted: true };
      });

      setMessage({
        type: "success",
        text: `Please wait; We are processing your request.`,
      });
      axios
        .post(reqUrl, reqJson)
        .then((response) => {
          if (response.status === 200) {
            setState((prev) => {
              return {
                ...prev,
                state: CurrentFormState.VERIFY_CODE,
                isSubmitted: false,
              };
            });
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            setMessage({
              type: "error",
              text: error.response?.data.message,
            });
          } else {
            setMessage({
              type: "error",
              text: "Something went wrong.",
            });
          }
        });
    }

    if (state.state === CurrentFormState.VERIFY_CODE) {
      if (state.code !== state.verifyCode) {
        setMessage({
          type: "error",
          text: "Your Entered Code is not valid.",
        });

      } else {
        setMessage((prev) => {
          return { ...prev, text: "" };
        });
        setState((prev) => {
          return { ...prev, state: CurrentFormState.EMPLOYEE_INFO };
        });
      }
    }

    if (state.state === CurrentFormState.EMPLOYEE_INFO) {
      const employeeData = {
        employee_id: state.employee_id,
        phone_number: state.phone_number,
        start_date: state.start_date,
        organization_id: 1,
      };

      setState((prev) => {
        return { ...prev, isSubmitted: true };
      });

      const provisionUrl =
        process.env.REACT_APP_API_URL + "admin/provision-existing-employee";
      axios
        .post(provisionUrl, employeeData)
        .then((response: any) => {
          setMessage({
            type: "success",
            text: response.data.message,
          });
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            setMessage({
              type: "error",
              text: error.response?.data.message,
            });
          } else {
            setMessage({
              type: "error",
              text: "Something went wrong.",
            });
          }
        });
    }
  };

  const editPhoneNumberHandler = () => {
    setState((prev) => {
      return { ...prev, state: CurrentFormState.VERIFY_PHONE };
    });
    setMessage((prev) => {
      return { ...prev, text: "" };
    });
  };

  return (
    <section className="h-screen">
      <div className="flex flex-col items-center justify-center min-h-screen px-4 bg-[#4D148C]">
        <div className="flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-md shadow-md sm:px-6 md:px-8 lg:px-10">
          <div className="self-center text-xl font-medium text-gray-800 uppercase sm:text-2xl"></div>
          <div className="flex items-center justify-center py-2 mt-6">
            <img className="h-12" src={FedexLogo} alt="FedEx" />
          </div>
          <div className="relative h-px mt-10 mb-2 bg-gray-300">
            <div className="absolute top-0 left-0 flex justify-center w-full -mt-2">
              <span className="px-4 text-xs text-gray-500 uppercase bg-white">
                Employee Verification
              </span>
            </div>

            {state.state === CurrentFormState.VERIFY_PHONE ? (
              <div className="absolute top-0 left-0 flex justify-center w-full my-4">
                <span className="px-4 text-xs text-center text-red-500 bg-white">
                  Please use ONLY your personal phone number, NOT your work
                  phone number when registering.{" "}
                </span>
              </div>
            ) : null}
          </div>
          <div
            className={
              state.state === CurrentFormState.VERIFY_PHONE ? "mt-12" : "mt-2"
            }
          >
            <form onSubmit={onSubmit}>
              {message.text && (
                <div
                  className={`w-full p-2 mt-1 mb-3 text-sm  text-gray-800 ${
                    message.type === "success" ? "bg-green-300" : "bg-red-300"
                  } rounded shadow-sm`}
                >
                  <h5>{message.text}</h5>
                </div>
              )}

              {state.state === CurrentFormState.VERIFY_PHONE && (
                <div className="flex flex-col mb-6">
                  <label
                    htmlFor="phone_number"
                    className="mb-1 text-xs tracking-wide text-gray-600 sm:text-sm"
                  >
                    Phone Number:
                  </label>
                  <div className="relative">
                    <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                        />
                      </svg>
                    </div>

                    <input
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value === "" ||
                          re.test(event.target.value)
                        ) {
                          setState((prev) => {
                            return {
                              ...prev,
                              phone_number: event.target.value,
                            };
                          });
                        }
                      }}
                      required
                      id="phone_number"
                      type="text"
                      name="phone_number"
                      className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-lg sm:text-base focus:outline-none focus:border-blue-400"
                      placeholder="Phone Number"
                      value={state.phone_number}
                      maxLength={10}
                      inputMode="tel"
                    />
                  </div>
                </div>
              )}

              {state.state === CurrentFormState.VERIFY_CODE && (
                <>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="code"
                      className="mb-1 text-xs tracking-wide text-gray-600 sm:text-sm"
                    >
                      Code:
                    </label>
                    <div className="relative">
                      <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                          />
                        </svg>
                      </div>
                      <input
                        onChange={(event) =>
                          setState((prev) => {
                            return { ...prev, code: event.target.value };
                          })
                        }
                        required
                        id="code"
                        type="number"
                        name="code"
                        className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-lg sm:text-base focus:outline-none focus:border-blue-400"
                        placeholder="Code"
                        value={state.code}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-center my-6">
                    <button
                      onClick={editPhoneNumberHandler}
                      className="inline-flex items-center text-xs font-bold text-center text-blue-500 hover:text-blue-700"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </span>
                      <span className="ml-1">Edit Phone Number</span>
                    </button>
                  </div>
                </>
              )}

              {state.state === CurrentFormState.EMPLOYEE_INFO && (
                <>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="employee_id"
                      className="mb-1 text-xs tracking-wide text-gray-600 sm:text-sm"
                    >
                      Employee ID:
                    </label>
                    <div className="relative">
                      <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                          />
                        </svg>
                      </div>
                      <input
                        onChange={(event) =>
                          setState((prev) => {
                            return { ...prev, employee_id: event.target.value };
                          })
                        }
                        required
                        id="employee_id"
                        type="text"
                        name="employee_id"
                        className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-lg sm:text-base focus:outline-none focus:border-blue-400"
                        placeholder="Employee ID"
                        value={state.employee_id}
                      />
                    </div>
                  </div>

                  {/* <div className="flex flex-col mb-6">
                                        <label htmlFor="email" className="mb-1 text-xs tracking-wide text-gray-600 sm:text-sm">Email Address:</label>
                                        <div className="relative">
                                            <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400">
                                                <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                                </svg>
                                            </div>
                                            <input
                                                onChange={event => setState(prev => { return { ...prev, email: event.target.value } })}
                                                required id="email" type="email" name="email"
                                                className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-lg sm:text-base focus:outline-none focus:border-blue-400"
                                                placeholder="Email" value={state.email} />
                                        </div>
                                    </div> */}

                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="start_date"
                      className="mb-1 text-xs tracking-wide text-gray-600 sm:text-sm"
                    >
                      Start Date:
                    </label>
                    <div className="relative">
                      {/* <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                          />
                        </svg>
                      </div> */}
                      <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                        }) => (
                          <div className="flex items-center justify-center gap-2">
                            <select
                              className="p-1 mx-1"
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              className="p-1 mx-1"
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(parseInt(value))
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        // popperPlacement="top-end"
                        showMonthDropdown
                        showYearDropdown
                        className="w-full py-2 pl-2 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-lg sm:text-base focus:outline-none focus:border-blue-400"
                        value={state.start_date}
                        onChange={(date) => {
                          // if (date === null) return;
                          // format date to YYYY-MM-DD
                          // const newDate = date.toISOString().split("T")[0];
                          // setState((prev) => {
                          //   return { ...prev, start_date: newDate };
                          // });

                          if (date === null) return;
                          const newDate = getFormattedDate(date);
                          setState((prev) => ({
                            ...prev,
                            start_date: newDate,
                          }));
                        }}
                        disabledKeyboardNavigation
                        customInput={<ExampleCustomInput />}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* ========= */}
              <div className="flex flex-col w-full">
                <button
                  disabled={state.isSubmitted}
                  type="submit"
                  className="flex items-center justify-center w-full py-2 text-sm text-white disabled:bg-gray-500 transition duration-150 ease-in bg-[#4D148C] opacity-90 rounded focus:outline-none sm:text-base hover:bg-blue-700"
                >
                  <span className="mr-2 uppercase">{state.state}</span>
                  <span></span>
                </button>
                {state.state === CurrentFormState.VERIFY_PHONE && (
                  <div>
                    <p className="px-2 mt-2 text-sm text-center text-gray-700">
                      {/*By going through the employee verification process, you are voluntarily registering for the FedEx START mobile Application.*/}
                      Please take a few seconds to verify your mobile number,
                      employee ID, and start date to voluntarily register for
                      the FedEx START app.
                    </p>
                    <hr className="mt-4 mb-4" />
                    <div className="text-xs italic text-center text-gray-600">
                      Message and data rates may apply.
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
          {/* <div className="flex items-center justify-center mt-6">
                        <button className="inline-flex items-center text-xs font-bold text-center text-blue-500 hover:text-blue-700">
                            <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
                            </span>
                            <span className="ml-2">Edit Phone Number</span>
                        </button>
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default Employee;
