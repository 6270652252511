import { FC, useContext, useReducer, useState } from "react";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import { toast } from "../../../../utils/Toast";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { createActionTemplate } from "../../../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { EnumActionType, IActionMetadata } from "../../../../Types/ActionTemplate/ActionTemplate";
import { TabActionLayout } from "../TabActionLayout";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";



export type EmailActionTemplate = {
    action_metadata: IActionMetadata,
    importance: number
}

export type EmailActionCreateOrUpdateRequest = {
    action_type: EnumActionType.EmailAction,
    action_metadata: IActionMetadata[],
    importance: number
}

export const initialEmailContent: EmailActionTemplate = {
    action_metadata: {
        id: 0,
        organization_id: 1,
        language: LanguageEnum.English,
        subject: '',
        body: '',
    },
    importance: 0
}

const NewEmail: FC = () => {
    const params = useParams<{ actionType: string }>()
    const navigate = useNavigate();

    const { currentOrganizationID } = useContext(UserContext);

    const [formData, updateFormData] = useReducer((prev: EmailActionTemplate, next: Partial<EmailActionTemplate>) => {
        return { ...prev, ...next }
    }, initialEmailContent)
    // useState(initialEmailContent)

    // ============================ Mutation ================================

    const createEmailActionMutation = useMutation((param: EmailActionCreateOrUpdateRequest) => createActionTemplate(param), {
        onSuccess: async (data) => {
            toast("Content created successfully", "success")
            const id = data?.data?.id
            if (id) {
                navigate(`/action-templates/${params.actionType}/view/${id}`)
            }
        },
        onError: (error: any) => {
            const message = error?.response?.data?.message || "Something went wrong!"
            toast(message, "error")
        }
    })

    // ======================  Submit Email  ========================
    const submitFormHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (formData.action_metadata.subject === '') {
            toast('Please enter a subject', 'error')
            return
        }


        const data = [formData]

        const action_metadata: IActionMetadata[] = data.map((item) => {
            return {
                id: 0,
                organization_id: currentOrganizationID(),
                language: LanguageEnum.English,
                subject: item.action_metadata.subject,
                body: item.action_metadata.body,
            }
        })

        const shapeData: EmailActionCreateOrUpdateRequest = {
            action_type: EnumActionType.EmailAction,
            action_metadata: action_metadata,
            importance: formData.importance
        }


        await createEmailActionMutation.mutateAsync(shapeData)
    }

    return <TabActionLayout>

        <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
            <h1 className="text-xl ">Create New Email Action Template</h1>
            <h1 className="pb-4 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{formData.action_metadata.subject || ''}</h1>
            <AppInput tabIndex={-1} autoFocus placeholder={"Subject"} value={formData.action_metadata.subject} name='subject'
                isFull label='Subject' required
                onChange={
                    (e) => updateFormData({ action_metadata: { ...formData.action_metadata, subject: e.target.value } })
                }
            />
            <AppTextArea placeholder={"Body"} value={formData.action_metadata.body!} rows={6} name='body'
                onChange={
                    (e) => updateFormData({ action_metadata: { ...formData.action_metadata, body: e.target.value } })
                }
                label='Body' />
            <AppDropDown
                extendClass="mt-1.5"
                isFull
                label=""
                name="time"
                options={[
                    { label: "Standard", value: "0" },
                    { label: "Priority", value: "1" },
                    { label: "Urgent", value: "2" },
                ]}
                value={formData.importance.toString()}
                onChange={
                    (e) => updateFormData({ importance: parseInt(e.target.value, 10) })
                }
            />
            <div className='flex w-full gap-2 mb-3'>
                <AppButton variant="primary" type="submit" extendClass="px-3">
                    <div className="flex items-center justify-center gap-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 96 960 960"
                            className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
                        >
                            <path
                                fill="currentColor"
                                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
                        </svg>
                        Create Action Template
                    </div>
                </AppButton>
            </div>
        </form>


    </TabActionLayout>
}

export default NewEmail;