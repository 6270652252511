import React, { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { LanguagesOptionsList, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery } from "react-query";
import { deleteContentTemplateById, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
// import ChecklistItem from './ChecklistItem';
// import AllChecklistContent from './AllChecklistContent';
import { ChecklistContentCreateOrUpdateRequest, ChecklistContentResponse, ChecklistContentTemplate, IChecklist, IChecklistItems, initialChecklistContent } from '../../../../Types/ContentTemplate/Checklist';
import UseUploadFile from '../../../../Hook/UseUploadFile';
import UseUpload from '../../../../Hook/UseUpload';
import { baseURL } from '../../../../apiQuery';
import UploadProgress from '../../../General/UploadProgress';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
// import { reorder } from '../../../Utils/Reorder';
import { useForm } from 'react-hook-form';
import { checklist } from '../../../../Common/Types';
import { InlineCheckListItem } from './InlineItem';
import { v4 as uuidv4 } from 'uuid';

export type Language = "en" | "fr" | "es"


interface ChecklistTabContentProps {
	templateId: number,
}


interface StringObject {
	id: string;
	content: string;
}

function convertStringsToObjects(strings: string[]): StringObject[] {
	const objectsArray: StringObject[] = strings.map((str) => ({
		id: uuidv4(),
		content: str,
	}));
	return objectsArray;
}

const ChecklistTabContent: FC<ChecklistTabContentProps> = (props) => {
	const navigate = useNavigate();


	//================== updoad cover image ==============================
	const [imageSrc, file, render, name] = UseUploadFile('Checklist')
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);

	const [uploadState, setUploadState] = useState({
		file,
		name,
	})

	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])

	//===================================================================================================================

	const { currentOrganizationID } = useContext(UserContext);
	const { t } = useTranslation();

	const [state, setState] = useState<ChecklistContentTemplate[]>([])
	const [checklistContent, setChecklistContent] = useState<ChecklistContentTemplate>(initialChecklistContent)


	const createOrUpdateButton = useRef<string>("Create")
	const createOrUpdateItemButton = useRef<string>("Create")
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')
	//====================================================================================================================
	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);
	const { register, handleSubmit, watch, setValue, trigger, getValues, formState: { errors } } = useForm<checklist>({
	});
	const { data: checklistData, refetch } = useQuery<ChecklistContentResponse, Error>
		(`checklistData-content-${props.templateId}`, () => getContentTemplateById(props.templateId));

	useEffect(() => {
		if (checklistData?.data) {
			editableChecklistContent(checklistData?.data)
		}
	}, [checklistData?.data])

	useEffect(() => {
		if (selectedLanguage === '') return
		// get seleted index of the content template to be displayed as the current content
		const selectedIndex = checklistData?.data['checklist'].findIndex((item: any) => item.language === selectedLanguage) as number
		const checklist: IChecklist = checklistData?.data['checklist'][selectedIndex] as IChecklist

		if (checklist) {


			const contentMetaData = checklistData?.data.content_metadata[selectedIndex] as IContentMetadata
			const newItems: IChecklistItems[] = checklist.items.map((item: any) => {
				return {
					content: item,
					id: uuidv4()
				}
			})

			const newList: IChecklist = {
				...checklist,
				items: newItems
			}

			setChecklistContent(prev => {
				return {
					...prev,
					checklist: newList,
					content_metadata: contentMetaData
				}
			})


		}



		createOrUpdateButton.current = "Update"
	}, [checklistData, selectedLanguage])

	//=====================================================
	//set english default language for content
	useEffect(() => {
		const checklist = checklistData?.data['checklist'].find(item => item.language === "en")
		const contentMetaData = checklistData?.data.content_metadata.find(item => item.language === "en")
		// const index = articleData?.data['article'].findIndex(item => item.language === "en") as number

		if (!checklist) {
			return
		}

		const newItems: IChecklistItems[] = checklist.items.map((item: any) => {
			return {
				content: item,
				id: uuidv4()
			}
		})

		const newList: IChecklist = {
			...checklist,
			items: newItems
		}


		setChecklistContent({
			checklist: newList,
			content_metadata: contentMetaData as IContentMetadata
		})
		createOrUpdateButton.current = "Update"
		setSelectedLanguage("en")

	}, [checklistData?.data])


	// useEffect(() => {
	// 	if(currentLanguage.current === LanguageEnum.English) {
	// 		setArticleContent({
	// 			article: articleData?.data.article.find((item: IArticle) => item.language === LanguageEnum.English) as IArticle,
	// 			content_metadata: articleData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
	// 		})
	// 		createOrUpdateButton.current = "Update"
	// 	}
	// } ,[articleData?.data.article, articleData?.data.content_metadata])

	// const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.checklist.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges

	}, [state])


	//=====================================================
	const deleteTemplateFromContentTemplateList = async () => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/checklist', { state: { deleted: true } })
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.checklist.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}


	// ========================================= Mutations ================================================================


	const updateChecklistContentMutation = useMutation(
		(params: { id: number, data: ChecklistContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Content updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
	//=====================================================
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	// ==================================================== Handlers =========================================================
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setChecklistContent(prev => {
			return {
				...prev,
				checklist: {
					...prev.checklist,
					[name]: value
				}
			}
		})
	}



	const onDragEnd = (result: DropResult): void => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const itemsData = Array.from(checklistContent?.checklist?.items || []);
		const [reorderedItem] = itemsData.splice(result.source.index, 1);
		itemsData.splice(result.destination.index, 0, reorderedItem);

		setChecklistContent(prev => ({
			...prev,
			checklist: {
				...prev.checklist,
				items: itemsData,
			}
		}));
	};
	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		//  tODO: validation goes here
		if (checklistContent.checklist.title === '') {
			return
		}


		if (createOrUpdateButton.current === "Create") {
			// Todo : check if the language is already exist
			if (state.some(item => item.checklist.language === checklistContent.checklist.language)) {
				toast('This checklist is already exist', 'error')
				return
			}

			let _cover_imageLink = ''

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				// setArticleContent(prev => ({...prev, cover_image: link}))
				_cover_imageLink = link
			}

			const checklistContentWithImage: ChecklistContentTemplate = {
				checklist: checklistContent.checklist,
				content_metadata: {
					...checklistContent.content_metadata,
					cover_image: _cover_imageLink,
				}
			}

			// setState(prev => [...prev, checklistContentWithImage])
			const newState = [...state, checklistContentWithImage]
			await createOrUpdateContentTemplate(newState)

		} else {


			let cover_image = checklistContent?.content_metadata?.cover_image || ''

			if (file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", file);
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}



			// update the state
			const newState = state.map(item => {
				if (item.checklist.language === checklistContent.checklist.language) {
					return {
						checklist: checklistContent.checklist,
						content_metadata: {
							...checklistContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})
			setState(newState)
			await createOrUpdateContentTemplate(newState)
		}


		createOrUpdateButton.current = "Create"
		// 	empty the fields
		setChecklistContent(initialChecklistContent)
		setUploadState({
			file: undefined,
			name: '',
		})

		setSelectedLanguage('')

	}

	const createOrUpdateContentTemplate = async (data: ChecklistContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one checklist template', 'info')
			return
		}


		const shapeData: ChecklistContentCreateOrUpdateRequest = {
			content_type: EnumContentType.ChecklistContent,

			checklist: data.map(item => {
				return {
					...item.checklist,
					title: item.checklist.title,
					description: item.checklist.description,
					items: item.checklist.items.map(item => item.content),
					language: item.checklist.language as LanguageEnum,
				}
			}),

			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item?.content_metadata?.cover_image || '',
					preview_title: item?.content_metadata?.preview_title || '',
					tags: item?.content_metadata?.tags || [],
					organization_id: currentOrganizationID(),
					language: item.checklist.language as LanguageEnum,
					alt_title: item?.content_metadata?.alt_title || '',
				}
			})
		}


		await updateChecklistContentMutation.mutateAsync({ id: props.templateId, data: shapeData })
		setState([])
		setChecklistContent(initialChecklistContent)
	}


	const editableChecklistContent = (checklistContentTemplate: any) => {

		const combinedData: any[] = [];
		// Loop through each article
		checklistContentTemplate.checklist.forEach((checklist: any) => {
			// Loop through each content metadata
			checklistContentTemplate.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (checklist.language === metadata.language) {
					// Combine the properties of the checklist and metadata objects
					const combinedObj = {
						checklist: {
							...checklist,
							items: checklist.items.map((item: any) => {
								return {
									content: item,
									id: uuidv4()
								}
							})
						},
						content_metadata: metadata
					};
					// Add the combined object to the final array
					combinedData.push(combinedObj);
				}
			});
		});
		setState(combinedData)
		// setCurrentTemplate(checklistContentTemplate.id as number)
	}


	const [item, setItem] = useState('')

	const addItemToChecklist = () => {
		if (item === '') {
			return
		}
		setChecklistContent(prev => {
			// return { ...prev, checklist: { ...prev.checklist, items: [...prev.checklist.items, item] } }
			return {
				...prev,
				checklist: {
					...prev.checklist,
					items: [
						...prev.checklist.items,
						{
							content: item,
							id: uuidv4()
						}
					]
				}
			}

		})
		setItem('')
		createOrUpdateItemButton.current = "Create"
	}


	const [showInput, setShowInput] = useState({ id: null, isInput: false });

	return (
		<Fragment>

			<ViewContentTemplateHeader
				title={checklistData?.data.checklist[0].title || ''}
				icon={<svg
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 7.573 8.414"
					className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
				>
					<path
						id="icon_pathways"
						d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
						transform="translate(-6 -4)"
						fill="currentColor"
					/>
				</svg>}
				referencesCount={checklistData?.data.linked_content_count || 0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{

					checklistData?.data.checklist.sort((a, b) => {
						// Define a language order
						const languageOrder = { en: 0, es: 1, fr: 2 };

						// Get the language order for each object
						const orderA = languageOrder[a.language] || Infinity;
						const orderB = languageOrder[b.language] || Infinity;

						// Compare the language order
						return orderB - orderA;
					}).map((item: IChecklist, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setChecklistContent(initialChecklistContent)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										editableChecklistContent(checklistData.data)
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					(checklistData?.data.checklist?.length ?? 0) < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setChecklistContent(initialChecklistContent)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								+ New Language
							</span>

						</AppButton>
					) : null
				}

			</div>





			<TabContentLayout>


				{/* =========================== */}
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{checklistContent?.checklist?.title || ""}</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">

					<div className='flex w-full gap-2 mb-8'>
						<AppButton size="xs" disabled={checklistContent?.checklist?.title === '' || updateChecklistContentMutation.isLoading || deleteContentTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Content Template</AppButton>
						<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>{t("content_template.delete_content_template")}</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>

					</div>
					<hr className="mb-8" />

					<AppInput autoFocus placeholder={"Title"} value={checklistContent?.checklist?.title || ""} name='title' isFull onChange={handleChange} label='Title' required />
					<AppTextArea placeholder={"Description"} rows={4} value={checklistContent?.checklist?.description || ""} name='description' onChange={handleChange}
						label='Description' />


					<div className='p-2 my-2 border border-gray-100 rounded'>
						<p className='py-2 font-semibold'>Checklist items</p>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided, snapshot) => (
									<div {...provided.droppableProps} ref={provided.innerRef} className="grid grid-cols-1 gap-2 mb-2">
										{checklistContent?.checklist?.items.map((item, idx: number) => {

											return (
												<Draggable key={idx} draggableId={`item-${idx}`} index={idx}>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className="flex flex-col "
														>
															<InlineCheckListItem
																key={item.id}
																item={item}
																setChecklistContent={setChecklistContent}
																showInputId={showInput.id}
																showInput={showInput}
																setShowInput={setShowInput}
															/>
														</div>
													)}
												</Draggable>
											)
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>


						<div className="flex flex-col items-start justify-start w-[90%] p-2 m-1 border rounded bg-hoverLightBlue gap-4">

							<AppInput placeholder={"Item"} value={item} name='item' onChange={(e) => setItem(e.target.value)} extendClass='' />
							<AppButton variant={'success'} type={"button"} size={"xs"} onClick={addItemToChecklist} extendClass="mx-2">{createOrUpdateItemButton.current} Item</AppButton>
						</div>
					</div>


					{/* ======================== */}


					{/* <AppInput placeholder={"Alternative Title"} value={checklistContent?.content_metadata?.alt_title || ''} name='alt_title' isFull
						onChange={e => {
							setChecklistContent({
								...checklistContent,
								content_metadata: {
									...checklistContent.content_metadata,
									alt_title: e.target.value
								}
							})
						}} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}


					<AppInput placeholder={"Preview Title"} value={checklistContent?.content_metadata?.preview_title || ""} name='preview_title' isFull
						onChange={e => {
							setChecklistContent(prev => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										preview_title: e.target.value
									}
								}
							})
						}} label='Preview Title' extendClass='my-2' maxLength={140} required />
					<AppInput
						placeholder={"Tag"}
						value={checklistContent?.content_metadata?.tags?.[0] || ""}
						name="tags"
						isFull
						onChange={(e) => {
							const tags = [e.target.value];
							setChecklistContent({
								...checklistContent,
								content_metadata: {
									...checklistContent.content_metadata,
									tags,
								},
							});
						}}
						label="Tag"
						extendClass="my-2"
					/>
					{checklistContent?.content_metadata?.cover_image ?
						<div className="cover__image-container">
							<img alt="conver_image" src={checklistContent?.content_metadata?.cover_image || ""} className='my-2 rounded-sm' />
						</div>
						: null}
					{render}


					{
						uploadState.name !== undefined ? <p>{uploadState.name}</p> : null
					}

					{
						isLoading ? <UploadProgress progress={progress} /> : null
					}

					{/* <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"} options={LanguagesOptions} value={checklistContent?.checklist?.language || ""} name="language" onChange={handleChange} /> */}
					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={checklistContent?.checklist?.language || ""} name="language" onChange={handleChange} />
					}
				</form>
			</TabContentLayout>

			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>


			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</Fragment>
	)
}


export default ChecklistTabContent
