import { FC } from 'react'
import { EnumContentType } from '../../Types/ContentTemplate/ContentType'
import { useParams } from 'react-router-dom'
import ArticleTabContent from './ContentTabs/ArticleTabContent/ArticleTabContent'
import VideoTabContent from './ContentTabs/VideoTabContent/VideosTabContent'
import AnnouncementTabContent from './ContentTabs/AnnouncementTabContent/AnnouncementTabContent'
import ChecklistTabContent from './ContentTabs/ChecklistTabContent/ChecklistTabContent'
import ConsentTabContent from './ContentTabs/ConsentTabContent/ConsentTabContent'
import FAQTabContent from './ContentTabs/FAQTabContent/FAQTabContent'
import SurveyTabContent from './ContentTabs/SurveyTabContent/SurveyTabContent'
import DirectionTabContent from './ContentTabs/DirectionTabContent/DirectionTabContent'
import SurveySequenceTabContent from './ContentTabs/SurveySequenceTabContent/SurveySequenceTabContent'
import SelectorTabContent from './ContentTabs/SelectorTabContent/SelectorTabContent'
import { TabBar } from '../../Theme'


const ViewContentTemplate: FC = () => {
  const params = useParams<{ templateSlug: string, templateId: string }>()
  const templateId = params.templateId ? parseInt(params.templateId) : 0
  // if (!templateId) return <div><h1>Invalid Template Id</h1></div>

  return <section className='relative'>
    <TabBar />
    {(() => {
      switch (params.templateSlug) {
        case EnumContentType.ArticleContent:
          return <ArticleTabContent templateId={templateId} />;
        case EnumContentType.AnnouncementContent:
          return <AnnouncementTabContent templateId={templateId} />
        case EnumContentType.VideoContent:
          return <VideoTabContent templateId={templateId} />;
        case EnumContentType.ChecklistContent:
          return <ChecklistTabContent templateId={templateId} />
        case EnumContentType.ConsentContent:
          return <ConsentTabContent templateId={templateId} />
        case EnumContentType.FAQContent:
          return <FAQTabContent templateId={templateId} />
        case EnumContentType.SurveyContent:
          return <SurveyTabContent templateId={templateId} />;
        case EnumContentType.DirectionContent:
          return <DirectionTabContent templateId={templateId} />;
        case EnumContentType.SurveySequenceContent:
          return <SurveySequenceTabContent templateId={templateId} />
        case EnumContentType.SelectorContent:
          return <SelectorTabContent templateId={templateId} />
        default:
          return <></>;
      }
    })()}


  </section>
}


export default ViewContentTemplate