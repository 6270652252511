import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";

// for the create and update the Consent
// to create with Consent content type id is going to be 0
export interface IConsent {
	id?: number
	title: string
	description: string
	body: string
	subscribe_user_to_pathway_id: number
	organization_id?: number
	language: LanguageEnum
	updated_at?: string
}


export type IConsentContentTemplate = {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	consent: IConsent[];
}

export type ConsentContentResponse = {
	data: IConsentContentTemplate
	error: boolean
	message: string
	status: number
}



export type ConsentContentTemplate = {
	consent: IConsent
	content_metadata: IContentMetadata
}

export interface ConsentContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	consent: IConsent[],
	content_metadata: IContentMetadata[]
}

export const initialConsentContent: ConsentContentTemplate = {
	consent: {
		id: 0,
		title: '',
		description: '',
		body: '',
		subscribe_user_to_pathway_id: 0,
		language: LanguageEnum.English,
	},
	content_metadata: {
		id: 0,
		cover_image: '',
		preview_title: '',
		tags: [''],
		language: LanguageEnum.English,
		alt_title: '',
	}
}