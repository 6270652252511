export const InfoIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={`h-4 w-4 flex-shrink-0 text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white
  ${className ? ` ${className}` : ""}`}
      viewBox="0 0 10.226 10.226"
    >
      <path
        fill="currentColor"
        d="M84.768 183.669h.767V180.6h-.767zm.345-4.014a.415.415 0 00.3-.118.389.389 0 00.121-.291.427.427 0 00-.121-.31.424.424 0 00-.6 0 .427.427 0 00-.121.31.39.39 0 00.121.291.415.415 0 00.3.119zm0 6.57a4.949 4.949 0 01-1.988-.4A5.164 5.164 0 0180.4 183.1a5.117 5.117 0 010-3.979 5.116 5.116 0 011.1-1.621 5.248 5.248 0 011.63-1.1 5.117 5.117 0 013.979 0 5.11 5.11 0 012.714 2.716 5.115 5.115 0 010 3.978 5.221 5.221 0 01-1.093 1.624 5.162 5.162 0 01-1.623 1.1 4.946 4.946 0 01-1.991.408z"
        transform="translate(-80 -176)"
      ></path>
    </svg>
  );
};

InfoIcon.defaultProps = {
  className: "",
};
