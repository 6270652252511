import { IPathwayLanguage } from "../../Types/Pathways/Pathways";
import { getToken } from "../../utils/LocalStorage";
import API from "../index";

export const getAllPathways = async (organ: number, pageParam: string, filter?: any) => {
  if (getToken()) {
    const { data } = await API.post(`/admin/organizations/pathways/filter?limit=${20}${pageParam && `&next=${pageParam}`}`, {
      ...filter
    });
    return data;
  }
  return []
};


export const getSinglePathway = async (pathwaySlug: string) => {
  const { data } = await API.get(`/admin/organizations/pathways/${pathwaySlug}`);
  return data;
}

export const createPathwayLanguage = async (pathwaySlug: string,data:IPathwayLanguage) => {
  const response = await API.post(`/admin/organizations/pathways/${pathwaySlug}/language`,data);
  return response.data;
}
export const updatePathwayLanguage = async (pathwayLanguageId: number,data:IPathwayLanguage) => {
  const response = await API.put(`/admin/organizations/pathways/language/${pathwayLanguageId}`,data);
  return response.data;
}
export const deletePathwayLanguage = async (pathwayLanguageId:number)=>{
  const response = await API.delete(`/admin/organizations/pathways/language/${pathwayLanguageId}`);
  return response.data;
}