export const ContentIcon = (props: { className?: string }) => {
  const { className } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 9.879 9.638'
      aria-hidden='true'
      className={`h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white
    ${className ? ` ${className}` : ''}`}
    >
      <path
        id='icon_content'
        d='M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z'
        transform='translate(-4 -4)'
        fill='currentColor'
      />
    </svg>
  )
}

ContentIcon.defaultProps = {
  className: ''
}
