import { FC, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { AppButton, LanguageIcon, SaveIcon } from "../../../Theme";
import { getLangaugeLabel } from "../../../utils/Languages";
import AppInput from "../../../components/Shared/AppInput/AppInput";
import AppTextArea from "../../../components/Shared/AppTextArea/AppTextArea";
import { LanguageEnum } from "../../../Types/ContentTemplate/Langauge";
import { stripHtml } from "string-strip-html";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "../Layout";

// ****
import {
  AnnouncementContentTemplate,
  IAnnouncement,
  IAnnouncementContentTemplate,
  initialAnnouncementContent,
} from "../../../Types/ContentTemplate/Announcement";
import { sortTemplate } from "../template.utils";

interface AnnouncementContentTemplateProps {
  contentTemplate: IAnnouncementContentTemplate;
  priority: number;
  alternativeTitle: string;
  onUpdateFields: (priority: number, alternativeTitle: string) => void;
}

export const Announcement: FC<AnnouncementContentTemplateProps> = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [formData, updateFormData] = useReducer(
    (
      prev: AnnouncementContentTemplate,
      next: Partial<AnnouncementContentTemplate>
    ) => {
      return { ...prev, ...next };
    },
    initialAnnouncementContent
  );
  const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English);

  const [priority, setPriority] = useState<number>(0);
  const [alternativeTitle, setAlternativeTitle] = useState<string>("");

  useEffect(() => {
    if (currentLanguage.current === LanguageEnum.English) {
      updateFormData({
        announcement: props.contentTemplate.announcement.find(
          (item) => item.language === LanguageEnum.English
        ),
        content_metadata: props.contentTemplate.content_metadata.find(
          (item) => item.language === LanguageEnum.English
        ),
      });
    }
    setPriority(props.priority);
    setAlternativeTitle(props.alternativeTitle);
  }, [
    props.alternativeTitle,
    props.contentTemplate.announcement,
    props.contentTemplate.content_metadata,
    props.priority,
  ]);

  const updateFieldsHandler = () => {
    props.onUpdateFields(priority, alternativeTitle);
  };

  const sortedList = useMemo(() => sortTemplate<IAnnouncement[]>(props.contentTemplate.announcement), [props.contentTemplate.announcement])

  return (
    <Layout>
      <div className="flex items-center gap-2 py-2.5">
      {
          sortedList.map((item, idx) => {
            return (
              <AppButton
                key={idx}
                variant={currentLanguage.current === item.language ? 'info' : 'primary'}
                type="button"
                size="xs"
                onClick={() => {
                  currentLanguage.current = item.language
                  updateFormData({ announcement: item, content_metadata: props.contentTemplate.content_metadata.find((item) => item.language === currentLanguage.current) })
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <LanguageIcon className='w-3 h-3' />
                  <span className="text-xs">{getLangaugeLabel(item.language)}</span>
                </div>
              </AppButton>
            )
          })
        }
      </div>
      <div className="flex flex-col gap-1">
      <AppInput disabled tabIndex={-1} autoFocus placeholder={"Title"} value={formData.announcement.title} name='title' isFull label='Title' required />
        <AppTextArea disabled placeholder={"Description"} rows={4} value={formData.announcement.description} name='description' label='Description' />
        <AppTextArea disabled placeholder={"Body"} rows={4} value={stripHtml(formData.announcement.body).result || ""} name='body' label='Body' />
        <AppInput disabled placeholder={"Alternative Title"} value={formData.content_metadata.alt_title} name='alt_title' isFull label='Alternative Title' maxLength={30} extendClass='my-2' />
        <AppInput disabled placeholder={"Preview Title"} value={formData.content_metadata.preview_title} name='preview_title' isFull label='Preview Title' extendClass='my-2' maxLength={140} required />
        <AppInput disabled placeholder={"Tag"} value={formData.content_metadata.tags[0]} name='tags' isFull label='Tag' extendClass='my-2' />
        <AppInput type="number" min={0} placeholder={"Priority"} value={priority.toString()} onChange={e => {setPriority(parseInt(e.target.value)) }} name='Priority' isFull label='Priority' extendClass='my-2' />
        <AppInput placeholder={"Alternative Title"} value={alternativeTitle} onChange={e=> setAlternativeTitle(e.target.value)} name='alt_title' isFull label='Alternative Title' extendClass='my-2' />
        
        {formData.content_metadata.cover_image ? <>
          <div className="cover__image-container">
            <img alt="conver_image" src={formData.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
          </div>
        </> : null}

        <div className="flex gap-2 pb-2">
          <AppButton type='button' onClick={() => { navigate(`/content-templates/announcement/view/${props.contentTemplate.id}`) }} size='md' variant="info">
            <div className='flex items-center justify-between gap-1'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
              </svg>
              <span>Edit Announcement Template</span>
            </div>
          </AppButton>
          <AppButton variant="primary" type="button" extendClass="px-3" size='md' onClick={updateFieldsHandler}>
            <div className="flex items-center justify-center gap-1">
              <SaveIcon className="w-4 h-4 text-grayColor" />
              {t('update')}
          </div>
          </AppButton>
        </div>

      </div>
    </Layout>
  );
};
