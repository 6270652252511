export const ArrowIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={`flex-shrink-0  transition duration-100 ${className || ""}`}
      viewBox="0 0 5.284 5.284"
    >
      <path
        fill="currentColor"
        d="M280 336v3.473a.568.568 0 00.566.566h3.642l-.849.849.4.4 1.529-1.529-1.529-1.529-.4.4.849.849h-3.642V336z"
        transform="translate(-280 -336)"
      ></path>
    </svg>
  );
};

ArrowIcon.defaultProps = {
  className: "",
};
