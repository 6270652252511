import { FC, useEffect, useState, memo, useMemo, useCallback } from "react";
import API from "../../../apiQuery";
import { useQuery } from "react-query";
import { IFavoritesResponse } from "../../../Types/Favorites/Favorites";
import { getAllFavorites } from "../../../apiQuery/Favorites/favorites.apis";

export interface FavoriteProps {
  favorite_owner: string;
  slug: string;
}

export const Favorite: FC<FavoriteProps> = memo((props) => {
  const { favorite_owner, slug } = props;
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const { data: favorites, refetch: refetchFav } = useQuery<IFavoritesResponse>(
    ["favorites"],
    () => getAllFavorites(),
    {}
  );

  const favoriteDataList = useMemo(() => {
    if (favorites) {
      return favorites.data;
    }
  }, [favorites]);

  useEffect(() => {
    const isFavorite = favoriteDataList?.find((favorite) => {
      if (
        favorite.action_template_id === parseInt(slug) ||
        favorite.worker_id === parseInt(slug) ||
        favorite.content_template_id === parseInt(slug)
      ) {
        return true;
      }
      return false;
    });
    setIsClicked(isFavorite ? true : false);
  }, [favoriteDataList, slug]);

  // useEffect(() => {
  //   // check if the current favorite is already exist, if yes then set the isClicked to true
  //   if (favoriteDataList) {
  //     favoriteDataList.forEach((favorite) => {
  //       if (favorite_owner === "Action" && favorite.action_slug === slug) {
  //         setIsClicked(true);
  //       } else if (favorite_owner === "Worker" && favorite.worker_uuid === slug) {
  //         setIsClicked(true);
  //       } else if (favorite_owner === "Content" && favorite.content_slug === slug) {
  //         setIsClicked(true);
  //       }
  //     }
  //     )
  //   }
  // }, [favoriteDataList, favorite_owner, slug])

  // if (favorite_owner === "Action") {
  //   action_slug = slug
  // } else if (favorite_owner === "Worker") {
  //   worker_uuid = slug
  // } else if (favorite_owner === "Content") {
  //   content_slug = slug
  // }
  const addToFavorite = useCallback(async () => {
    setIsClicked((prev) => !prev);
    let worker_id = 0
    let action_template_id = 0
    let content_template_id = 0


    if (favorite_owner === "Worker") {
      worker_id = parseInt(slug);
      await API.post(`/admin/organizations/favorites/`, {
        favorite_owner,
        worker_id,
      });
    }
    if (favorite_owner === "Action") {
      action_template_id = parseInt(slug);
      await API.post(`/admin/organizations/favorites/`, {
        favorite_owner,
        action_template_id,
      });
    }
    if (favorite_owner === "Content") {
      content_template_id = parseInt(slug);
      await API.post(`/admin/organizations/favorites/`, {
        favorite_owner,
        content_template_id,
      });
    }
    await refetchFav();
  }, [favorite_owner, refetchFav, slug]);

  const removeFromFavorite = useCallback(async () => {
    setIsClicked((prev) => !prev);
    if (favorite_owner === "Worker") {
      //get the favorite slug from the refetched data
      const filteredFavorite = favoriteDataList?.find((favorite) => favorite.worker_id === parseInt(slug));
      if (filteredFavorite) {
        await API.delete(`/admin/organizations/favorites/${filteredFavorite?.slug}`);
      } else {
      
      }
    }
    if (favorite_owner === "Action") {
      //get the favorite slug from the refetched data
      const filteredFavorite = favoriteDataList?.find((favorite) => favorite.action_template_id === parseInt(slug));
      if (filteredFavorite) {
        await API.delete(`/admin/organizations/favorites/${filteredFavorite?.slug}`);
      } else {
      
      }
    }
    if (favorite_owner === "Content") {
      //get the favorite slug from the refetched data
      const filteredFavorite = favoriteDataList?.find((favorite) => favorite.content_template_id === parseInt(slug));
      if (filteredFavorite) {
        await API.delete(`/admin/organizations/favorites/${filteredFavorite?.slug}`);
      } else {
        
      }
    }

    await refetchFav();
  }, [refetchFav, slug, favoriteDataList, favorite_owner]);

  return (
    <>
      {isClicked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          viewBox="0 96 960 960"
          fill="red"
          onClick={removeFromFavorite}
        >
          <path d="m480 908.46-31.769-28.923q-103.307-94.307-170.384-162.5-67.077-68.192-106.73-120.999-39.654-52.808-55.385-95.307-15.731-42.5-15.731-85.423 0-82.307 55.5-137.807 55.5-55.5 137.192-55.5 55.846 0 103.576 28.154Q444 278.309 480 332.002q40.461-55.923 86.884-82.962t100.423-27.039q81.692 0 137.192 55.5 55.5 55.5 55.5 137.807 0 42.923-15.731 85.423-15.731 42.499-55.385 95.307-39.653 52.807-106.73 120.999-67.077 68.193-170.384 162.5L480 908.46Z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          viewBox="0 96 960 960"
          onClick={addToFavorite}
        >
          <path d="m480 935-41-37q-105.768-97.121-174.884-167.561Q195 660 154 604.5T96.5 504Q80 459 80 413q0-90.155 60.5-150.577Q201 202 290 202q57 0 105.5 27t84.5 78q42-54 89-79.5T670 202q89 0 149.5 60.423Q880 322.845 880 413q0 46-16.5 91T806 604.5Q765 660 695.884 730.439 626.768 800.879 521 898l-41 37Zm0-79q101.236-92.995 166.618-159.498Q712 630 750.5 580t54-89.135q15.5-39.136 15.5-77.72Q820 347 778 304.5T670.225 262q-51.524 0-95.375 31.5Q531 325 504 382h-49q-26-56-69.85-88-43.851-32-95.375-32Q224 262 182 304.5t-42 108.816Q140 452 155.5 491.5t54 90Q248 632 314 698t166 158Zm0-297Z" />
        </svg>
      )}
    </>
  );
});
