export const EyeIcon =(props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className={`flex-shrink-0  transition duration-100 ${className || ""}`}
    viewBox="0 0 7.911 5.394"
  >
    <path
      fill="currentColor"
      d="M43.957 260.225a1.521 1.521 0 10-1.083-.445 1.471 1.471 0 001.083.445zm0-.521a.966.966 0 01-.713-.294.977.977 0 01-.292-.715.966.966 0 01.294-.713.977.977 0 01.715-.292.966.966 0 01.713.294.977.977 0 01.292.715.966.966 0 01-.294.713.977.977 0 01-.719.288zm0 1.69a4.029 4.029 0 01-2.373-.746A4.546 4.546 0 0140 258.7a4.546 4.546 0 011.582-1.951 4.148 4.148 0 014.747 0 4.546 4.546 0 011.582 1.951 4.546 4.546 0 01-1.582 1.951 4.029 4.029 0 01-2.373.743z"
      transform="translate(-40 -256)"
    ></path>
  </svg>
  )
}