import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";

// for the create and update the FAQ
// to create with FAQ content type id is going to be 0
export interface IFAQ {
	id?: number
	title: string
	description: string
	language: LanguageEnum
	questions: IFAQQuestion[]
	updated_at?: string
}
export interface IFAQForServer {
	id?: number
	title: string
	description: string
	language: LanguageEnum
	questions: IFAQQuestionForServer[]
	updated_at?: string
}


export interface IFAQQuestion {
	id: string
	order: number
	question: string
	answer: string
	faq_id: number
	language: LanguageEnum
}

export interface IFAQQuestionForServer {
	question: string
	order: number
	answer: string
	faq_id: number
	language: LanguageEnum
}


export type IFAQContentTemplate = {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	faq: IFAQ[];
}

export type fAQContentResponse = {
	data: IFAQContentTemplate
	error: boolean
	message: string
	status: number
}

export interface FAQContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	faq: IFAQForServer[],
	content_metadata: IContentMetadata[]
}

export type FAQContentTemplate = {
	faq: IFAQ
	content_metadata: IContentMetadata
}

export const initialFAQContent: FAQContentTemplate = {
	faq: {
		id: 0,
		title: '',
		description: '',
		language: LanguageEnum.English,
		questions: []
	},
	content_metadata: {
		id: 0,
		cover_image: '',
		preview_title: '',
		tags: [''],
		language: LanguageEnum.English,
		alt_title: '',
	}
}