/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, {
  FC,
  ButtonHTMLAttributes,
  ReactElement,
  ReactNode,
} from "react";
import AppButtonSpinner from "./AppButtonSpinner";

type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";
type Size = "sm" | "md" | "lg";
export type AppButtonProps = {
  variant: Variant;
  children: ReactElement | ReactNode | string;
  type?: "submit" | "reset" | "button";
  size?: Size;
  extendClass?: string;
  isLoading?: boolean;
  isFull?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const PrimaryClasses = " bg-blue-700 hover:bg-blue-600 text-white ";
const DangerClasses = " bg-red-500 hover:bg-red-600 text-white ";
const DefaultClasses =
  " bg-white hover:bg-gray-200 text-gray-500 border border-gray-200 ";
const SecondaryClasses =
  " bg-gray-700 hover:text-gray-700 hover:bg-white text-white border border-gray-200 ";
const InfoClasses = " bg-blue-500 hover:bg-blue-600 text-white ";
const SuccessClasses = " bg-green-500 hover:bg-green-600 text-white ";

const selectVariant = (variant: Variant) => {
  switch (variant) {
    case "primary":
      return PrimaryClasses;
    case "danger":
      return DangerClasses;
    case "light":
      return DefaultClasses;
    case "secondary":
      return SecondaryClasses;
    case "info":
      return InfoClasses;
    case "success":
      return SuccessClasses;
    default:
      return DefaultClasses;
  }
};

const selectSize = (size: Size) => {
  switch (size) {
    case "sm":
      return "px-2 py-1 text-xs";
    case "lg":
      return "px-4 py-2 text-lg";
    case "md":
      return "px-3 py-2 text-base";
    default:
      return "px-3 py-2 text-base";
  }
};

const btn =
  "w-35 flex items-center justify-center text-center transition-all ease-in-out rounded-lg outline-none focus:opacity-90" +
  " hover:opacity-100" +
  " disabled:opacity-30 disabled:cursor-not-allowed";
const AppButton: FC<AppButtonProps> = ({
  onClick,
  size,
  variant,
  type,
  children,
  extendClass,
  isLoading,
  isFull,
  ...otherProps
}) => {
  let classes = `${btn} ${size ? selectSize(size) : "px-5 py-2.5 "}
	${selectVariant(variant)} ${isLoading ? "cursor-not-allowed opacity-75 " : ""
    } ${extendClass || ""}`;
  classes += isFull ? " w-full" : "";
  return (
    <button
      onClick={onClick}
      type={type || "button"}
      disabled={isLoading}
      className={classes}
      {...otherProps}
    >
      {isLoading ? <AppButtonSpinner height={22} /> : <>{children}</>}
    </button>
  );
};

AppButton.defaultProps = {
  type: "button",
  size: "md",
  isLoading: false,
  isFull: false,
  extendClass: "",
  onClick: () => {},
};

export default AppButton;
