import { ChangeEvent, FC, FormEvent, useContext, useRef, useState, useEffect, Fragment, useMemo } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery } from "react-query";
import { deleteContentTemplateById, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { ArticleContentResponse, ArticleContentTemplate, IArticle, IArticleContentTemplate, initialArticleContent } from "../../../../Types/ContentTemplate/Article";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import UseUploadFile from '../../../../Hook/UseUploadFile';
import UseUpload from '../../../../Hook/UseUpload';
import { baseURL } from '../../../../apiQuery';
import UploadProgress from '../../../General/UploadProgress';
import { AppButton, LanguageIcon, PopupModal } from '../../../../Theme';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { useNavigate } from 'react-router-dom';
import WYSIWYGEditor from '../../../General/Form/WYSIWYGEditor';
import { useTranslation } from 'react-i18next';
import NewUseUploadFile from "../../../../Hook/NewUseUploadFile"

export type Language = "en" | "fr" | "es"


export const LanguagesOptionsList = [
	{ value: "-", label: "Choose an option" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]

interface ArticleContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	article: IArticle[],
	content_metadata: IContentMetadata[]
}

interface ArticleTabContentProps {
	templateId: number,
}


export const LanguagesOptions = [
	{ value: "en", label: "English"},
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]


const ArticleTabContent: FC<ArticleTabContentProps> = (props) => {

	const { t, i18n } = useTranslation();

	const { currentOrganizationID } = useContext(UserContext);

	const navigate = useNavigate();



	//=========================================================

	const [state, setState] = useState<ArticleContentTemplate[]>([])
	const [articleContent, setArticleContent] = useState<ArticleContentTemplate>(initialArticleContent)
	const [currentTemplateId, setTemplateId] = useState<number>(0);
	const createOrUpdateButton = useRef<string>("Create")
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')
	// const allArticleContentsRef = useRef(null)

	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);

	// =====================  Queries =====================
	const { data: articleData, refetch } = useQuery<ArticleContentResponse, Error>
		(`article-content-${props.templateId}`, () => getContentTemplateById(props.templateId));

	const updateArticleContentMutation = useMutation(
		(params: { id: number, data: ArticleContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Content updated successfully", "success")

			// setTemplateId(data.data.id)
			await refetch()
			// editableArticleContent(data?.data)
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
		// =====================  Upload Image =====================
		const [imageSrc, file, render, name, setImage] = NewUseUploadFile('Article')

	useEffect(() => {
		if (articleContent.content_metadata?.cover_image) {
			setImage(articleContent.content_metadata?.cover_image)			
		}
		}, [articleContent.content_metadata?.cover_image, setImage])
	
		// articleContent.content_metadata.cover_image
		const { uploadForm, progress, isLoading } = UseUpload(
			`${baseURL}upload`
		);
	
		const [uploadState, setUploadState] = useState({
			file,
			name,
		})
	
		useEffect(() => {
			if (name && file) {
				setUploadState({
					file,
					name,
				})
			}
		}, [name, file])
	
	//=====================================================
	
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
	//=====================================================
	//make english default language for content template
	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setArticleContent({
				article: articleData?.data.article.find((item: IArticle) => item.language === LanguageEnum.English) as IArticle,
				content_metadata: articleData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			setSelectedLanguage(LanguageEnum.English)
			createOrUpdateButton.current = "Update"
		}
	}, [articleData?.data.article, articleData?.data.content_metadata])

	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.article.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges

	}, [state])

	//=====================================================

	// handlers
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setArticleContent(prev => {
			return { ...prev, article: { ...prev.article, [name]: value } }
		})
	}

	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (articleContent.article.title === '') {
			toast('Please enter a title', 'error')
			return
		}

		if (createOrUpdateButton.current === "Create") {
			// Todo : check if the language is already exist
			if (state.some(item => item.article.language === articleContent.article.language)) {
				toast('This article is already exist', 'error')
				return
			}

			let _cover_imageLink = ''

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				// setArticleContent(prev => ({...prev, cover_image: link}))
				_cover_imageLink = link
			}

			const articleContentWithImage: ArticleContentTemplate = {
				article: articleContent.article,
				content_metadata: {
					...articleContent.content_metadata,
					cover_image: _cover_imageLink,
					language: articleContent.article.language
				}
			}

			// setState(prev => [...prev, articleContentWithImage])
			const newState = [...state, articleContentWithImage]
			await createOrUpdateContentTemplate(newState)
		}
		// =====================  Update =====================
		else {
			let cover_image = articleContent.content_metadata.cover_image

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}
			// update the state
			const newState = state.map(item => {
				if (item.article.language === articleContent.article.language) {
					return {
						article: articleContent.article,
						content_metadata: {
							...articleContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})
			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = "Create"
		}

		// 	empty the fields
		setArticleContent(initialArticleContent)
		// reset fields
		setUploadState({
			file: undefined,
			name: '',
		})
		setSelectedLanguage('')
	}

	const createOrUpdateContentTemplate = async (data: ArticleContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one article template', 'info')
			return
		}
		const shapeData: ArticleContentCreateOrUpdateRequest = {
			content_type: EnumContentType.ArticleContent,
			article: data.map(item => {
				return {
					...item.article,
					title: item.article.title,
					description: item.article.description,
					body: item.article.body,
					language: item.article.language as LanguageEnum,
				}
			}),
			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.article.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}
		await updateArticleContentMutation.mutateAsync({ id: currentTemplateId, data: shapeData })
		setArticleContent(initialArticleContent)
		setSelectedLanguage('')
	}

	const editableArticleContent = (articleContentTemplate: IArticleContentTemplate) => {
		const combinedData: any[] = [];

		// Loop through each article
		articleContentTemplate.article.forEach((article: any) => {
			// Loop through each content metadata
			articleContentTemplate.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (article.language === metadata.language) {
					// Combine the properties of the article and metadata objects
					console.log('lang',article)
					const combinedObj = {
						article: article,
						content_metadata: metadata
					};
					// Add the combined object to the final array
					combinedData.push(combinedObj);
				}
			});
		});

		setTemplateId(articleContentTemplate.id)
		setState(combinedData)
	}

	useEffect(() => {
		if (articleData?.data) {
			editableArticleContent(articleData?.data)
		}
	}, [articleData?.data])


	const deleteTemplateFromContentTemplateList = async () => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/article', { state: { deleted: true } })
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.article.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}


	// const laguageOptions = useMemo(() => {
	// 	// if the language is already on the state then remove it from the options
	// 	return LanguagesOptions.filter(item => !state.some(stateItem => stateItem.article.language === item.value))
	// }, [state])

	console.log('body',articleContent?.article?.body)

	return (
		<Fragment>
			<ViewContentTemplateHeader
				title={articleData?.data.article.filter(item => item.language === LanguageEnum.English)[0].title || ''}
				icon={<svg
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 7.573 8.414"
					className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
				>
					<path
						id="icon_pathways"
						d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
						transform="translate(-6 -4)"
						fill="currentColor"
					/>
				</svg>}
				referencesCount={articleData?.data.linked_content_count || 0}
			/>
			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{
					// props.data[props.data.content_type]
					articleData?.data.article.sort((a, b) => {
						// Define a language order
						const languageOrder = { en: 0, es: 1, fr: 2 };

						// Get the language order for each object
						const orderA = languageOrder[a.language] || Infinity;
						const orderB = languageOrder[b.language] || Infinity;

						// Compare the language order
						return orderB - orderA;
					}).map((item: IArticle, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setArticleContent(initialArticleContent)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										// editableArticleContent(articleData.data)

										createOrUpdateButton.current = "Update"
										currentLanguage.current = item.language as LanguageEnum
										const findArticle = articleData.data.article.find((article) => article.language === item.language) as IArticle
										setArticleContent({
											article: findArticle,
											content_metadata: articleData.data.content_metadata.find((metadata: IContentMetadata) => metadata.language === item.language) as IContentMetadata
										})
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}

				{
					(articleData?.data.article?.length ?? 0) < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								const newInitalArticleContent: ArticleContentTemplate = {
									content_metadata: {
										...initialArticleContent.content_metadata,
									},
									article: {
										...initialArticleContent.article,
										language: LanguagesOptions.find(item => !state.some(stateItem => stateItem.article.language === item.value))?.value as LanguageEnum
									}
								}

								console.log('newInitalArticleContent', newInitalArticleContent)
								setArticleContent(newInitalArticleContent)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								{t('new_language')}
							</span>

						</AppButton>
					) : null
				}
			</div>


				
			<TabContentLayout>
				{/* =========================== */}
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{articleContent?.article?.title || ""}</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">
					<div className='flex w-full gap-2 mb-8'>
						<AppButton size="xs" disabled={articleContent?.article?.title === '' || updateArticleContentMutation.isLoading || deleteContentTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Content Template</AppButton>
						<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>{t("content_template.delete_content_template")}</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>
					</div>
					<hr className="mb-8" />
					{/* ======================== */}
					<AppInput tabIndex={-1} autoFocus placeholder={"Title"} value={articleContent?.article?.title || ""} name='title' isFull onChange={handleChange} label='Title' required />
					<AppTextArea placeholder={"Description"} rows={4} value={articleContent?.article?.description || ""} name='description' onChange={handleChange} label='Description' />
					<WYSIWYGEditor onChange={
						(e) => {
							setArticleContent(prev => {
								return { ...prev, article: { ...prev.article, body: e } }
							})
						}} value={articleContent?.article?.body || ''} placeholder={"Body"}></WYSIWYGEditor>
					{/* <AppInput placeholder={"Alternative Title"} value={articleContent?.content_metadata?.alt_title || ''} name='alt_title' isFull
						onChange={e => {
							setArticleContent({
								...articleContent,
								content_metadata: {
									...articleContent.content_metadata,
									alt_title: e.target.value
								}
							})
						}} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}
					<AppInput placeholder={"Preview Title"} value={articleContent?.content_metadata?.preview_title || ''} name='preview_title' isFull
						onChange={e => {
							setArticleContent({
								...articleContent,
								content_metadata: {
									...articleContent.content_metadata,
									preview_title: e.target.value
								}
							})
						}} label='Preview Title' extendClass='my-2' maxLength={140} required />

					<AppInput
						placeholder={"Tag"}
						value={articleContent?.content_metadata?.tags?.[0] || ""}
						name="tags"
						isFull
						onChange={(e) => {
							const tags = [e.target.value];
							setArticleContent({
								...articleContent,
								content_metadata: {
									...articleContent.content_metadata,
									tags,
								},
							});
						}}
						label="Tag"
						extendClass="my-2"
					/>

					{/* {articleContent?.content_metadata?.cover_image ? <img alt="conver_image" src={articleContent.content_metadata.cover_image} className='my-2 rounded-sm' /> : null} */}

					{render}

					{uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}

					{isLoading ? <UploadProgress progress={progress} /> : null}
					{/* ======================== */}
					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={articleContent?.article?.language || ""} name="language" onChange={handleChange} />
					}
					{/* <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"} options={LanguagesOptions} value={articleContent?.article?.language || ""} name="language" onChange={handleChange} /> */}
				</form>

			</TabContentLayout>

			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</Fragment>
	)
}

export default ArticleTabContent
