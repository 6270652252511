import React, {FC, useState} from 'react';
import Button from "../../General/Button";
import {ContCommon} from "../../../Common/Types";

const VideoPage: FC<ContCommon> = (prop) => {

    const [isPlay, setPlay] = useState(false)
    const addDefaultSrc = (ev: any) => ev.target.src = "https://www.fedex.com/content/dam/fedex/us-united-states/about-us/images/2020/Q4/EU_EN_2020_1_web_jpg_NA_connect_possibilities_home_marketing_673415817_smaller.jpg"


    return (
        <div className="bg-transparent w-full h-full flex flex-col items-center justify-center">
            {
                isPlay ? <>
                    {
                        prop.video && <video controls className="rounded-md" autoPlay loop src={prop.video}></video>
                    }
                </> : <img className="w-full shadow-md rounded-t-md max-h-[15rem]" src={prop.image}
                           onError={addDefaultSrc} alt=""/>
            }

            <div
                className="whitespace-pre-wrap bg-white p-4 text-black shadow-md rounded-b-md relative mt-0 flex flex-col items-center w-full">
                <Button onClick={() => setPlay(prev => !prev)} label={isPlay ? 'Stop Video' : "Play Video"}
                        extraClasses="w-fit bg-organization-secondary text-white"/>
                {
                    prop.body != "" ? <p className="text-sm text-center mt-4">{prop.body}</p> : null
                }
            </div>

        </div>
    );
};

export default VideoPage;