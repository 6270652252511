import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";
import { v4 as uuidv4 } from 'uuid';


// for the create and update the survey
// to create with survey content type id is going to be 0
export interface ISurvey {
	id?: number
	appId: string
	title: string
	order: number
	alt_title: string,
	description: string
	language: LanguageEnum
	survey_items: ISurveyItem[]
	updated_at?: string
}

export interface ISurvey {
	id?: number
	appId: string
	title: string
	order: number
	alt_title: string,
	description: string
	language: LanguageEnum
	survey_items: ISurveyItem[]
	updated_at?: string
}


// to create with survey content type id is going to be 0
export interface ISurveyForServer {
	id?: number
	title: string
	alt_title: string
	order:number
	description: string
	language: LanguageEnum
	survey_items: ISurveyItemForServer[]
	updated_at?: string
}


export interface ISurveyItem {
	appId: string
	id?: number
	order: number,
	label: string
	value: number
	language: LanguageEnum
}
export interface ISurveyItemForServer {
	id?: number
	order: number
	label: string
	value: number
	language: LanguageEnum
}


export type ISurveyContentTemplateForServer = {
	id?: number;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	survey: ISurveyForServer[];
}
export type ISurveyContentTemplate = {
	id?: number;
	appId: string;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	survey: ISurveyForServer[];
}

export type SurveyContentResponse = {
	data: ISurveyContentTemplateForServer
	error: boolean
	message: string
	status: number
}

export type SurveyContentUpdatedResponse = {
	data: ISurveyContentTemplate
	error: boolean
	message: string
	status: number
}


export type SurveyContentTemplate = {
	survey: ISurvey
	content_metadata: IContentMetadata
}
export type ISurveyContentTemplateForm = {
	survey: ISurveyForServer
	content_metadata: IContentMetadata
}

export interface SurveyContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	survey: ISurveyForServer[],
	content_metadata: IContentMetadata[]
}


export const initialSurveyContent: SurveyContentTemplate = {
	survey: {
		id: 0,
		appId: uuidv4(),
		alt_title: '',
		order: 0,
		title: '',
		description: '',
		language: "en" as LanguageEnum,
		survey_items: []
	},
	content_metadata: {
		id: 0,
		cover_image: '',
		preview_title: '',
		tags: [''],
		language: LanguageEnum.English,
		alt_title: '',
	}
}