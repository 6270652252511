export const ArrowSubIcon = (props: { className?: string }) => {
  let _className = props.className
    ? props.className
    : " h-3 w-3 flex-shrink-0 text-grayColor transition duration-100 hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={_className}
      viewBox="0 0 5.284 5.284"
    >
      <path
        fill="currentColor"
        d="M280 336v3.473a.568.568 0 00.566.566h3.642l-.849.849.4.4 1.529-1.529-1.529-1.529-.4.4.849.849h-3.642V336z"
        transform="translate(-280 -336)"
      ></path>
    </svg>
  );
};
