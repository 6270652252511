import React, {FC, memo, ReactNode} from 'react';

type InputProps = {
    icon?: ReactNode
    label: string
    extraClasses?: string;
    onClick?: () => void;
}
const NestedContentListItem: FC<InputProps> = memo(({label, icon, extraClasses, onClick}) => {
    return (
        <div onClick={onClick} className="w-full rounded-sm cursor-pointer">
            <div className={`w-full p-1 flex items-center space-x-2 ${extraClasses}`}>
                {icon}
                <p className="">{label}</p>
            </div>
        </div>
    );
});

export default NestedContentListItem;