import { ITimeSince } from "../../Types/TimeSince";
import API from "../index";

export const createTimeSince = async (values: ITimeSince) => {
    const { data } = await API.post('/admin/organizations/state/time-condition/', { ...values });
    return data;
};
export const updateTimeSince = async (values: ITimeSince, id: number) => {
    const { data } = await API.put(`/admin/organizations/state/time-condition/${id}`, { ...values });
    return data;
};


export const deleteTimeSince = async (id: number) => {
    const { data } = await API.delete(`/admin/organizations/state/time-condition/${id}`);
    return data;
}