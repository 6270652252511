import React, { FC, FormEvent, useReducer } from "react";
import Button from "../../../General/Button";
import AppInput from "../../../Shared/AppInput/AppInput";
import { IUserDeleteAccount } from "../../../../Types/User/User";
import { useMutation } from "react-query";
import { deleteUser } from "../../../../apiQuery/User/User.apis";
import OverlayModal from "../../../Layouts/OverlayModal";
import Prompt from "../../../General/Prompt";

const DeleteAccount: FC = () => {
  const [formData, updateFormData] = useReducer(
    (prev: IUserDeleteAccount, next: Partial<IUserDeleteAccount>) => {
      const newData = { ...prev, ...next };
      const re = /^[0-9\b]+$/;
      if (next.phone_number) {
        if (next.phone_number === "" || re.test(next.phone_number)) {
          return newData;
        }
        return prev;
      }
      return newData;
    },
    {
      is_employee: false,
      phone_number: "",
      secret_code: "",
      user_identifier_id: "",
    }
  );

  const [confirmDelete, setConfirmDelete] = React.useState({
    isOpen: false,
    id: 0,
    callBack: () => {},
  });

  const closeModal = React.useCallback(
    () =>
      setConfirmDelete((prev) => ({
        ...prev,
        isOpen: !prev.isOpen,
        callBack: () => {},
      })),
    []
  );

  const [message, setMessage] = React.useState("");
  // Wipe delete user  with UUID auth|55555555555555555555555555\n failed to delete user in Auth0 ⛔\n user record deleted in WS1 Access ✅\n user record deleted in WS1 UEM ✅\n user record deleted in DB ✅"

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();
    // show modal to confirm
    setConfirmDelete((prev) => ({
      ...prev,
      isOpen: true,
    }));
  };

  const confirmDeleteUser = async () => {
    await deleteUserMutation.mutateAsync(formData);
  };

  const deleteUserMutation = useMutation(
    (data: IUserDeleteAccount) => deleteUser(data),
    {
      onSuccess: (response) => {

        setMessage(response.message);
      },
      onError: (error: any) => {
        setMessage(error.response.data.message);
      },
    }
  );

  return (
    <React.Fragment>
      <form className="space-y-4" onSubmit={submitHandler}>
        <div className="space-y-6">
          {/* <h1 className="font-normal">This action is non-reversible</h1> */}
          <div className="flex flex-col mt-1 mb-2">
            <div className="flex flex-col items-start justify-start mb-3">
              <label
                htmlFor="phone_number"
                className="block mb-1 ml-1 text-xs font-normal text-black"
              >
                Phone Number<span className="text-red-500">*</span>
              </label>
              <div className="flex items-center justify-center w-full">
                <span className="pr-2 text-md">+1</span>
                <input
                  name="phone_number"
                  type="text"
                  value={formData.phone_number}
                  onChange={(e) =>
                    updateFormData({ phone_number: e.target.value })
                  }
                  maxLength={10}
                  inputMode="tel"
                  className={`w-full block rounded-sm border border-borderGray bg-[#F5F5F5]  text-md text-gray-900
                  focus:border-blueColor focus:ring-blueColor placeholder:text-[#A4A5A5]
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-800
                `}
                  required
                />
              </div>
            </div>
            <AppInput
              label="ActionLogics id"
              name="user_identifier_id"
              type="text"
              value={formData.user_identifier_id}
              onChange={(e) =>
                updateFormData({ user_identifier_id: e.target.value })
              }
              extendClass=" my-2"
              isFull
              required
              showStar
            />
            <AppInput
              label="Secret Code"
              name="secret_code"
              type="text"
              value={formData.secret_code}
              onChange={(e) => updateFormData({ secret_code: e.target.value })}
              extendClass=" my-2"
              isFull
              required
              showStar
            />
            <label className="flex items-center">
              <input
                type="checkbox"
                className="w-4 h-4 text-blue-600 form-checkbox"
                checked={formData.is_employee}
                onChange={(e) =>
                  updateFormData({ is_employee: e.target.checked })
                }
              />
              <span className="ml-2 text-sm text-gray-700">Is Employee?</span>
            </label>
          </div>
          <Button
            disabled={deleteUserMutation.isLoading}
            type="submit"
            label={
              <div className="flex items-center space-x-2 text-sm text-white">
                Delete User Record
              </div>
            }
            extraClasses="w-fit px-3 py-3"
          />
          {message ? (
            <div className="p-3 transition-all delay-100 bg-white rounded shadow-md">
              <div
                className="text-red-500 text-md"
                dangerouslySetInnerHTML={{
                  __html: message.replace(/\n/g, "<br />") || "",
                }}
              />
            </div>
          ) : null}
        </div>
      </form>
      <OverlayModal onClose={closeModal} isOpen={confirmDelete.isOpen}>
        <Prompt
          title={`Delete User Record`}
          description={`This action is non-reversible.`}
          confirmButtonText={`Confirm Delete`}
          cancelButtonText={`Cancel`}
          onClickConfirm={() => {
            confirmDelete.callBack();
            confirmDeleteUser();
            closeModal();
          }}
          onClickCancel={closeModal}
        />
      </OverlayModal>
    </React.Fragment>
  );
};

export default DeleteAccount;
