import React, {ChangeEventHandler, useCallback, useState} from 'react';
import readFile from "../components/Utils/ReadFile";

const UseUploadFile = (title: string) => {

    const [imageSrc, setImageSrc] = useState<string>()
    const [file, setFile] = useState<any>(undefined)

    const onFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setFile(file)
            const imageDataUrl = URL.createObjectURL(file)
            //await readFile(file) as string
            setImageSrc(imageDataUrl)
            e.target.value = ""
        }
    }, [])

    return [imageSrc, file,
        <div>
            <label htmlFor="contained-button-file"
                   className="border-dashed border-2 bg-white border-gray-300 p-8 text-gray-400 cursor-pointer flex flex-col items-center justify-center">
                <span>{title} Cover Image</span>
                <span>(16:9 ratio, 25mb limit)</span>

            </label>

            <input onChange={onFileChange}
                   type="file"
                   accept="image/*"
                   style={{display: 'none'}}
                   id="contained-button-file"
            />
        </div>,file?.name
    ]
};

export default UseUploadFile;