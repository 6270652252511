import React from "react";

interface CustomInputPropsModel {
    value: string | number;
    type?: string;
    onChange: (value: any) => void;
    classNames?: string;
}

const CustomInput: React.FC<CustomInputPropsModel> = ({
                                                          value,
                                                          type,
                                                          onChange,
                                                          classNames,
                                                      }) => {
    return (
        <input
            type={type || "text"}
            value={value}
            className={`focus:outline-blue-400 w-20 rounded-md border-2 h-9 border-inherit px-2 py-1 flex-1 ${classNames}`}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

export default CustomInput;
