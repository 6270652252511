import React, { FC } from 'react';
import fed from "../../../assets/fed.png"

interface Props {
    children?: JSX.Element[] | JSX.Element
    handleModal?: (e: any) => void,
    type: string
    title: string,
    body?: string,
    description?: string,
    image?: string,
    video?: string,
    tags?: string[]
}

const ContentCardPreview: FC<Props> = ({ children, handleModal, ...prop }) => {

    const addDefaultSrc = (ev: any) => ev.target.src = "https://www.fedex.com/content/dam/fedex/us-united-states/about-us/images/2020/Q4/EU_EN_2020_1_web_jpg_NA_connect_possibilities_home_marketing_673415817_smaller.jpg"

    return (
        <div
            className={`w-full h-full overflow-hidden bg-organization-primary`}
        >
            <div className="w-full h-full px-4 pt-10 text-white">
                <div className="relative flex items-center justify-center w-full pt-2 pb-2">
                    <img src={fed} alt="fed" className="h-6" />
                </div>

                <div className="flex flex-col items-center h-full px-2 pt-8 overflow-auto">
                    <span className="text-white text-xs px-1 py-0.5 my-2 rounded-md bg-organization-secondary uppercase font-bold">{prop?.tags && prop?.tags[0]}</span>
                    <span className="pb-1 text-xl text-center">{prop.title}</span>

                    <span className="pb-2 text-sm text-center opacity-80">{prop.description}</span>

                    <hr className="border-[0.5px] border-gray-200 w-full my-5 opacity-10" />

                    {
                        prop.type !== 'announcement' && prop.type !== 'selector' && prop.type !== 'survey' && prop.type !== 'video' && prop.type !== 'survey-7' && prop.type !== 'direction' && prop.type !== 'consent' &&
                        <img className="w-full aspect-video shadow-md rounded-t-md max-h-[15rem]" onError={addDefaultSrc} src={prop.image} alt="" />
                    }

                    {children}
                </div>
            </div>

        </div>
    );
};

export default ContentCardPreview;