import { FlowState, Individuals, Workers } from "../Common/Types";
import { IPathways } from "../Types/Pathways/Pathways";
import { IWorker } from "../Types/Workers/Worker";
import { IAddActionTemplateModal, IAddContentTemplateModal } from "./store";
import { CurrentFlow, snackbar } from "./types";

export enum ActionType {
  set_pathways = "SET_PATHWAYS",
  set_individuals = "SET_INDIVIDUALS",
  set_current_pathway = "SET_CURRENT_PATHWAY", //state before
  set_organization = "SET_ORGANIZATION",
  set_loading = "SET_LOADING",
  set_snackbar = "SET_SNACKBAR",
  purge_state = "PURGE_STATE",
  set_currentWorker = "SET_CURRENT_WORKER",
  set_selected_pathway = "SET_SELECTED_PATHWAY",
  set_add_content_template_modal = "SET_ADD_CONTENT_TEMPLATE_MODAL",
  set_add_action_template_modal = "SET_ADD_ACTION_TEMPLATE_MODAL"
}


interface set_add_action_template_modal {
  type: ActionType.set_add_action_template_modal
  payload: IAddActionTemplateModal
}

interface set_add_content_template_modal {
  type: ActionType.set_add_content_template_modal
  payload: IAddContentTemplateModal
}

interface set_Pathways {
  type: ActionType.set_pathways;
  payload: FlowState[];
}

interface set_Individuals {
  type: ActionType.set_individuals;
  payload: Individuals[];
}

interface set_CurrentPathway {
  type: ActionType.set_current_pathway;
  payload: CurrentFlow;
}

interface set_Organization {
  type: ActionType.set_organization;
  payload: number;
}

interface set_Loading {
  type: ActionType.set_loading;
  payload: boolean;
}

interface set_Snackbar {
  type: ActionType.set_snackbar;
  payload: snackbar;
}

interface set_CurrentWorker {
  type: ActionType.set_currentWorker;
  payload: IWorker;
}
interface set_SelectedPathway {
  type: ActionType.set_selected_pathway;
  payload: IPathways;
}

export type Actions =
  | set_Pathways
  | set_Individuals
  | set_CurrentPathway
  | set_Organization
  | set_Loading
  | set_Snackbar
  | set_CurrentWorker
  | set_SelectedPathway
  | set_add_content_template_modal
  | set_add_action_template_modal;

export const setPathways = (value: FlowState[]): set_Pathways => ({
  type: ActionType.set_pathways,
  payload: value,
});

export const setIndividuals = (value: Individuals[]): set_Individuals => ({
  type: ActionType.set_individuals,
  payload: value,
});

export const setCurrentPathway = (value: CurrentFlow): set_CurrentPathway => ({
  type: ActionType.set_current_pathway,
  payload: value,
});

export const setOrganization = (value: number): set_Organization => ({
  type: ActionType.set_organization,
  payload: value,
});

export const setLoading = (value: boolean): set_Loading => ({
  type: ActionType.set_loading,
  payload: value,
});

export const setSnackbar = (value: snackbar): set_Snackbar => ({
  type: ActionType.set_snackbar,
  payload: value,
});

export const setCurrentWorker = (value: IWorker): set_CurrentWorker => ({
  type: ActionType.set_currentWorker,
  payload: value,
});

export const set_SelectedPathway = (value: IPathways): set_SelectedPathway => ({
  type: ActionType.set_selected_pathway,
  payload: value,
});


export const setAddContentTemplateModal = (value: IAddContentTemplateModal): set_add_content_template_modal => ({
  type: ActionType.set_add_content_template_modal,
  payload: value,
});


export const setAddActionTemplateModal = (value: IAddActionTemplateModal): set_add_action_template_modal => ({
  type: ActionType.set_add_action_template_modal,
  payload: value,
});