import { useState } from "react";
import { AppButton } from "../../../../../Theme";
import AppInput from "../../../../Shared/AppInput/AppInput";
import { updateById } from "./helper";
import { ChecklistContentTemplate } from "../../../../../Types/ContentTemplate/Checklist";
// import { changeElementAtIndex } from "./helper";

export const InlineCheckListItem = ({
  item,
  setChecklistContent,
  idx,
  setShowInput,
  showInputId,
  showInput,
}: any) => {


  const [inputChange, setInputChange] = useState(item.content);
  const [validation, setvalidation] = useState("");
  
  return (
    <div className="flex items-center w-[90%] gap-2 p-2 m-1 bg-primary rounded shadow-sm">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4.566 7.325"
        aria-hidden="true"
        className={
          "h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white "
        }
      >
        <path
          id="icon_drag"
          d="M14.893,15.325a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,15.325Zm2.758,0a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,17.651,15.325Zm-2.758-2.758a.879.879,0,0,1-.629-.252A.862.862,0,0,1,14,11.662a.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652A.913.913,0,0,1,14.893,12.566Zm2.758,0a.879.879,0,0,1-.629-.252.862.862,0,0,1-.263-.652.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652.913.913,0,0,1-.647.252ZM14.893,9.808a.85.85,0,0,1-.629-.269A.9.9,0,0,1,14.9,8a.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,9.808Zm2.758,0a.85.85,0,0,1-.629-.269A.9.9,0,0,1,17.662,8,.9.9,0,0,1,18.3,9.545.891.891,0,0,1,17.651,9.808Z"
          transform="translate(-14 -8)"
          fill="currentColor"
        />
      </svg>{" "}
      <div className="flex items-center justify-between w-full gap-2">
        {showInput.isInput && showInputId === item.id ? (
          <div className="flex flex-col w-full">
            <AppInput
              autoFocus
              name=""
              isFull
              onChange={(e) => {
                if (e?.target?.value.length > 0) {
                  setvalidation("");
                }
                setInputChange(e?.target?.value);
              }}
              type="text"
              extendClass=""
              value={inputChange}
            />

            {/* {validation ? (
              <p className="text-xs text-redColor">{validation}</p>
            ) : null} */}
          </div>
        ) : (
          <p className="text-sm text-black">{item.content}</p>
        )}
        <div className="flex items-center justify-center gap-1">
          
            <svg  
               
            onClick={() => {
              setChecklistContent((prev: any) => {
                const updatedItems = prev.checklist.items.filter(
                  (_: any, index: any) => _.id !== item.id
                );
                return {
                  ...prev,
                  checklist: {
                    ...prev.checklist,
                    items: updatedItems,
                  },
                };
              });
            }}
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
            className="w-5 h-5 cursor-pointer text-redColor">
              <path fill="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
          </svg>

   
          {showInput.isInput && showInputId === item.id ? (

            <div className="flex items-center gap-1">
          

            <svg

onClick={(e) => {
  if (inputChange.length < 1) {
    setvalidation("You can't save it as the empty");
  } else {
    setShowInput((prev: any) => {
      return { id: null, isInput: false };
    });



    setChecklistContent((prev: ChecklistContentTemplate) => {
      const arr = prev.checklist.items;
      const checklist_id = showInputId;
      const newValue = inputChange;
      const updatedArray = updateById(arr,checklist_id,newValue);
      return {
        ...prev,
        checklist: {
          ...prev.checklist,
          items: updatedArray,
        },
      }
    })
    }
  }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 96 960 960"
              className="w-5 h-5 text-[#27ae60] transition-all cursor-pointer "
            >
              <path
                fill="currentColor"
                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
            </svg>
      
            <svg
                onClick={(e) => {
                  setShowInput((prev: any) => {
                    return { id: null, isInput: false };
                  });
                }}
    className="flex-shrink-0 w-4 h-4 mr-2 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 7.637 7.637"
  >
    <path
      id="icon_close_remove"
      d="M6.386,9.651,7.818,8.219,9.25,9.651l.4-.4L8.219,7.818,9.651,6.386l-.4-.4L7.818,7.417,6.386,5.986l-.4.4L7.417,7.818,5.986,9.25Zm1.432,1.986a3.7,3.7,0,0,1-1.48-.3A3.847,3.847,0,0,1,4.3,9.3a3.814,3.814,0,0,1,0-2.969,3.816,3.816,0,0,1,.821-1.212A3.914,3.914,0,0,1,6.339,4.3a3.814,3.814,0,0,1,2.969,0,3.812,3.812,0,0,1,2.029,2.029,3.814,3.814,0,0,1,0,2.969,3.914,3.914,0,0,1-.816,1.217,3.816,3.816,0,0,1-1.212.821A3.718,3.718,0,0,1,7.818,11.637Z"
      transform="translate(-4 -4)"
      fill="currentColor"
    />
  </svg>
              </div>
          ) : (


<svg onClick={() => {
              setShowInput((prev: any) => {
                return { id: item.id, isInput: true };
              });
            }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
            className="w-5 h-5 text-blue-500 transition-all cursor-pointer hover:text-blue-600">
  <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
  <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
</svg>

          )}
        </div>
      </div>
    </div>
  );
};