/* eslint-disable no-lone-blocks */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
  FC,
  ReactNode,
} from "react";

import { useInfiniteQuery } from "react-query";
import {
  deleteWorker,
  getAllWorkers,
} from "../../apiQuery/Workers/Workers.apis";
import { store } from "../../GlobalState/store";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authenticate } from "../../services/AuthService";
import { getToken, setToken } from "../../utils/LocalStorage";
import { UserContext } from "../../Context/UserContext/UserContext";
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';

import {
  ActiveTimerIcon,
  AppButton,
  AppDivider,
  CloseIcon,
  CommandBar,
  DeleteIcon,
  EmailIcon,
  FilterIcon,
  InfoIcon,
  Loader,
  PathwayIcon,
  PhoneCallIcon,
  PopupModal,
  TabBar,
} from "../../Theme";
import { Drawer } from "../../Theme/Drawer";

import { setCurrentWorker } from "./../../GlobalState/store.actions";
import { IWorkerData, IWorkerResponse } from "../../Types/Workers/Worker";
import { Tooltip } from "flowbite-react";
import { useInView } from "react-intersection-observer";
import { ITab, TabContext } from "../../Context/TabContext/TabContext";
import { Favorite } from "../Container";
import { AppLoader } from "../Shared/AppLoader";

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="h-3 w-3 flex-shrink-0 text-grayColor transition duration-100 hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white;"
    viewBox="0 0 5.284 5.284"
  >
    <path
      fill="currentColor"
      d="M280 336v3.473a.568.568 0 00.566.566h3.642l-.849.849.4.4 1.529-1.529-1.529-1.529-.4.4.849.849h-3.642V336z"
      transform="translate(-280 -336)"
    ></path>
  </svg>
);

const filterItems: IFilterItem[] = [
  {
    id: 1,
    field: "first_name",
    label: "First Name",
    condition: "includes",
    value: "",
    type: "text",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
        aria-hidden="true"
        viewBox="0 0 40 40"
      >
        <path
          fill="currentColor"
          d="M7 44a2.878 2.878 0 01-2.1-.9A2.878 2.878 0 014 41V17a2.878 2.878 0 01.9-2.1A2.878 2.878 0 017 14h11.85V7a2.878 2.878 0 01.9-2.1 2.878 2.878 0 012.1-.9h4.35a2.878 2.878 0 012.1.9 2.878 2.878 0 01.9 2.1v7H41a3.076 3.076 0 013 3v24a3.076 3.076 0 01-3 3zm4.6-8.35h11.95v-.7a2.894 2.894 0 00-.45-1.6 2.259 2.259 0 00-1.15-.95 20.074 20.074 0 00-2.5-.725 9.161 9.161 0 00-1.75-.175 9.9 9.9 0 00-2.025.225 22.078 22.078 0 00-2.425.675 2.24 2.24 0 00-1.2.95 2.894 2.894 0 00-.45 1.6zm16.8-3.35h8.5v-2.5h-8.5zm-10.7-2.5a2.648 2.648 0 10-1.925-.775 2.614 2.614 0 001.925.775zm10.7-3.15h8.5v-2.5h-8.5zM21.85 17h4.35V7h-4.35z"
          transform="translate(-4 -4)"
        />
      </svg>
    ),
  },
  {
    id: 12,
    field: "last_name",
    label: "Last Name",
    condition: "includes",
    value: "",
    type: "text",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
        aria-hidden="true"
        viewBox="0 0 40 40"
      >
        <path
          fill="currentColor"
          d="M7 44a2.878 2.878 0 01-2.1-.9A2.878 2.878 0 014 41V17a2.878 2.878 0 01.9-2.1A2.878 2.878 0 017 14h11.85V7a2.878 2.878 0 01.9-2.1 2.878 2.878 0 012.1-.9h4.35a2.878 2.878 0 012.1.9 2.878 2.878 0 01.9 2.1v7H41a3.076 3.076 0 013 3v24a3.076 3.076 0 01-3 3zm4.6-8.35h11.95v-.7a2.894 2.894 0 00-.45-1.6 2.259 2.259 0 00-1.15-.95 20.074 20.074 0 00-2.5-.725 9.161 9.161 0 00-1.75-.175 9.9 9.9 0 00-2.025.225 22.078 22.078 0 00-2.425.675 2.24 2.24 0 00-1.2.95 2.894 2.894 0 00-.45 1.6zm16.8-3.35h8.5v-2.5h-8.5zm-10.7-2.5a2.648 2.648 0 10-1.925-.775 2.614 2.614 0 001.925.775zm10.7-3.15h8.5v-2.5h-8.5zM21.85 17h4.35V7h-4.35z"
          transform="translate(-4 -4)"
        />
      </svg>
    ),
  },
  {
    id: 3,
    field: "phone_number",
    label: "Phone Number",
    condition: "includes",
    value: "",
    type: "text",
    icon: <PhoneCallIcon />,
  },
  {
    id: 4,
    field: "email_address",
    label: "Email Address",
    condition: "includes",
    value: "",
    type: "text",
    icon: <EmailIcon />,
  },
  {
    id: 5,
    field: "candidate_id",
    label: "Candidate ID",
    condition: "includes",
    value: "",
    type: "text",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0 w-5 h-5 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
        aria-hidden="true"
        viewBox="0 0 20 20"
      >
        <path
          fill="currentColor"
          d="M18.5 3h-17C.67 3 0 3.67 0 4.5v11c0 .83.67 1.5 1.5 1.5h17c.83 0 1.5-.67 1.5-1.5v-11c0-.83-.67-1.5-1.5-1.5zM7 7a2.5 2.5 0 010 5 2.5 2.5 0 010-5zm-5.36 8.5C2.93 13.97 4.85 13 7 13s4.07.97 5.36 2.5H1.64zm15.78-6.18l-.5.87-1.42-.82V11h-1V9.37l-1.42.82-.5-.87L14 8.5l-1.42-.82.5-.87 1.42.82V6h1v1.63l1.42-.82.5.87L16 8.5l1.42.82z"
        ></path>
      </svg>
    ),
  },
  {
    id: 6,
    field: "employee_id",
    label: "Employee ID",
    condition: "includes",
    value: "",
    type: "text",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 96 960 960"
        className="flex-shrink-0 w-5 h-5 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
        aria-hidden="true"
      >
        <path
          fill="currentColor"
          d="M400 696V496h320v200H400zM140 896q-24 0-42-18t-18-42V316q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140zm0-60h680V400H140v436z"
        />
      </svg>
    ),
  },
  {
    id: 7,
    field: "is_employee",
    label: "Worker Type",
    condition: "is",
    value: "",
    type: "select",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
        aria-hidden="true"
        viewBox="0 0 36 35.6"
      >
        <path
          fill="currentColor"
          d="M24 41.5l-18-14 2.5-1.85L24 37.7l15.5-12.05L42 27.5zm0-7.6l-18-14 18-14 18 14z"
          transform="translate(-6 -5.9)"
        ></path>
      </svg>
    ),
  },
  {
    id: 8,
    field: "is_valid",
    label: "Status",
    condition: "is",
    value: true,
    type: "select",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
        aria-hidden="true"
        viewBox="0 0 36 35.6"
      >
        <path
          fill="currentColor"
          d="M24 41.5l-18-14 2.5-1.85L24 37.7l15.5-12.05L42 27.5zm0-7.6l-18-14 18-14 18 14z"
          transform="translate(-6 -5.9)"
        ></path>
      </svg>
    ),
  },
];

interface IFilterItem {
  id: number;
  field: string;
  label: string;
  condition: string;
  value: string | boolean;
  type: "text" | "date" | "select";
  icon: ReactNode;
}

const initialFilterItem: IFilterItem = {
  id: 0,
  field: "",
  label: "",
  condition: "",
  value: "",
  type: "text",
  icon: <EmailIcon />,
};


type itemType = {
  item: any
}
const RenderFilterCondition: React.FC<itemType> = (props) => {
  let content;

  if (typeof props.item.value === 'boolean') {
    if (props.item.field === 'is_employee') {
      content = props.item.value === true ? 'is' : 'is not';
    } else if (props.item.field === 'is_valid') {
      content = props.item.value === true ? 'is' : 'is';
    } else {
      content = props.item.condition;
    }
  } else {
    content = props.item.condition;
  }

  return <div>{content}</div>;
};


const WorkersPage: FC = () => {
  const { t, i18n } = useTranslation();

  const { globalTabs, updateTab, } =
    useContext(TabContext);

  const { updateCurrentUser, currentOrganizationID } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const workerSectionPageRef = useRef<HTMLElement>(null)

  // ====================================================================
  /*
  in the next few lines of code, we are checking if the user is authenticated and if not, we are redirecting him to the login page
  so to achive this and working well with Auth0 we are using this approach to hadle the authentication
  with Auth0 we are using the code and state params to authenticate the user.
  */

  const codeParam = searchParams.get("code");
  const stateParam = searchParams.get("state");

  const errorParam = searchParams.get("error");
  const errorsParam = searchParams.get("errors");

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        if (codeParam && stateParam) {
          const token: any = await authenticate(codeParam, stateParam);
          if (token) {
            setToken(token);
            updateCurrentUser({ isAuthenticated: true });
            navigate("/workers");
          }
        }
      } catch (error) {

        // handle error and provide feedback to the user
        navigate("/login");
      }
    };

    authenticateUser();
  }, [codeParam, stateParam, updateCurrentUser, navigate]);

  useEffect(() => {
    if (errorParam || errorsParam) navigate("/login");
  }, [errorParam, errorsParam, navigate]);

  // useEffect(() => {
  //   if (errorsParam) navigate("/login");
  // }, [errorsParam, navigate]);

  // ====================================================================

  const { dispatch } = useContext(store);


  const [applyQueries, setApplyQueries] = useState({
    filter: {},
  });


  const [selectedWorker, setSelectedWorker] = useState<IWorkerData | null>(
    null
  );

  const [selectedWorkers, setSelectedWorkers] = useState<number[]>([]);

  // ========================== DRAWER  ===============================
  const [showDrawer, setShowDrawer] = useState(false);

  const closeDrawer = useCallback(
    () => setShowDrawer(prev => false),
    []
  );
  // ========================== COMMANDBAR  ==================================
  const [showCommandBar, setShowCommandBar] = useState(false);
  // const closeCommandBar = useCallback(() => setShowDrawer((prev) => !prev), []);
  const closeCommandBar = () => setShowCommandBar((prev) => false)

  // ========================== POPUP Modal  ===============================

  const [showPopupDeleteWorker, setShowPopupDeleteWorker] = useState(false);

  const confirmDeleteWorker = async () => {

    if (selectedWorkers.length <= 0) return;
    try {
      // Map selected workers to an array of promises that delete them
      const deletePromises = selectedWorkers.map((worker) =>
        deleteWorker(worker)
      );

      // Wait for all delete promises to settle
      // const results = await Promise.allSettled(deletePromises);
      deletePromises.forEach((promise) =>
        toast.promise(promise, {
          pending: 'Deleting worker',
          success: 'Worker Deleted Successfully ',
          error: 'Worker Couldn\'t be deleted'
        })

      )

      // Check for any failed promises and display error messages as toasts
      // const failedPromises = results.filter(
      //   (result) => result.status === "rejected"
      // );
      // failedPromises.forEach((promise) =>
      //   // toast(promise.reason.message, "error")
      //   toast.pr("Error deleting worker", "error")
      // );
      // const successPromises = results.filter(
      //   (result) => result.status === "fulfilled"
      // )
      // successPromises.forEach((promise) =>
      //   toast("Worker deleted successfully", "success")

      // )

      setSelectedWorkers(prev => [])
      setSelectedWorker(null)

      refetch();
    } catch (error) {
      setSelectedWorkers(prev => [])
      setSelectedWorker(null)


    }
  };

  // ====================================================================
  useEffect(() => {
    if (codeParam && stateParam) {
      authenticate(codeParam, stateParam)
        .then((token: any) => {
          setToken(token as string);
          updateCurrentUser({ isAuthenticated: true });
          navigate("/workers");
        })
        .catch((error: any) => {
          // navigate('/login')
        });
    }
  }, [codeParam, stateParam, updateCurrentUser, navigate]);

  useEffect(() => {
    if (errorParam) navigate("/login");
  }, [errorParam, navigate]);

  useEffect(() => {
    if (errorsParam) navigate("/login");
  }, [errorsParam, navigate]);

  //======================================================================

  // const selectWorkerCheckboxHandler = () => {
  //   if (selectedWorkers.includes(item.id)) {
  //     setSelectedWorkers(
  //       selectedWorkers.filter((worker) => worker !== item.id)
  //     );
  //   } else {
  //     setSelectedWorkers([...selectedWorkers, item.id]);
  //   }
  // };

  useEffect(() => {
    if (selectedWorkers.length > 0) {
      setShowCommandBar(true);
    } else {
      setShowCommandBar(false);
    }
  }, [selectedWorkers.length]);

  const showDrawerHandler = useCallback(
    (workerData: IWorkerData) => {
      if (!showDrawer) setShowDrawer(true);
      setSelectedWorker(workerData);
    },
    [showDrawer]
  );

  // ========================== INFINITE QUERY  ===============================
  const organization_id = currentOrganizationID();
  const fetchWorkers = ({ pageParam = "" }) =>
    getAllWorkers(organization_id, pageParam, { ...applyQueries });

  // Worker_R
  const {
    isLoading,
    isError,
    data,
    error,
    refetch,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IWorkerResponse, Error>([applyQueries],
    fetchWorkers, {
    getNextPageParam: (lastPage, all) => {
      if (lastPage && lastPage.data) {
        if (lastPage.paginate.next === "") return undefined;
        return lastPage.paginate.next;
      }
    },
    enabled: getToken() !== null,
  });

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    // {data?.pages?.[0]?.data?.length || 0} results

    let count = 0;
    data?.pages.forEach((page) => {
      if (!page?.data?.length) return;
      count += page.data.length;
    });
    setTotalCount(count);
  }, [data]);

  // =========================== START Scroll Query  ==============================

  const tableRef = useRef<HTMLTableSectionElement | null>(null);

  useEffect(() => {
    // get table client.y
    const tableElement = tableRef.current;
    const tableClientY = tableElement?.getBoundingClientRect().y;
    const tableHeight = tableElement?.clientHeight;
    const windowHeight = window.innerHeight;
    // check if table height is more than window height
    if (
      tableHeight &&
      tableClientY &&
      windowHeight &&
      data &&
      data?.pages.length > 0
    ) {
      if (tableClientY + tableHeight < windowHeight) {

        fetchNextPage();
      }

    }
  }, [data, fetchNextPage]);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  // =========================== END Scroll Query  ==============================

  // ========================== FILTER  =====================================
  // const {activeFilters, setActiveFilters } = useContext(FilterContext)
  const [allFitlers, setAllFilters] = useState<IFilterItem[]>(filterItems);
  const [activeFilters, setActiveFilters] = useState<IFilterItem[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchFitlerText, setSearchFilterText] = useState("");

  const filterRef = useRef<HTMLDivElement>(null);
  const applyFilterRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [applyFilter, setApplyFilter] = useState<{
    item: IFilterItem;
    show: boolean;
  }>({
    item: initialFilterItem,
    show: false,
  });

  useEffect(() => {
    // Save tabs to localStorage whenever it
    if (activeFilters.length > 0)
      localStorage.setItem("workerFilters", JSON.stringify(activeFilters));
  }, [activeFilters]);

  useEffect(() => {
    // Load tabs from localStorage on component mount
    const savedFilters = localStorage.getItem("workerFilters");

    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters || "") as IFilterItem[];

      let newFilter = {};
      if (parsedFilters) {
        parsedFilters.forEach((i) => {
          newFilter = { ...newFilter, [i.field]: i.value };
        });
      }

      setApplyQueries((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            ...newFilter,
          },
        };
      });
      setActiveFilters(parsedFilters);
    }
  }, []);

  // Search in filters base on label
  const searchFilterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(e.target.value);
    if (e.target.value !== "") {
      const newFilters = allFitlers.filter((item) =>
        item.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setAllFilters(newFilters);
    } else {
      setAllFilters(filterItems);
    }
  };

  const applyInputFilterHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const inputElement = event.target as HTMLInputElement; // type assertion
      // if the input is empty return
      if (inputElement.value === "") {
        return;
      }

      // if the same field and value is already in the active filter return
      const isExist = activeFilters.find((item) => {
        return (
          item.field === applyFilter.item.field ||
          item.value === inputElement.value
        );
      });
      if (isExist) {
        toast.info("This filter is already applied");
        return;
      }

      // add new filter to active filter
      const newItem: IFilterItem = {
        ...applyFilter.item,
        value: inputElement.value,
      };
      setActiveFilters((prev) => [...prev, newItem]);

      // create a new fitler for apply filter
      const newFitler = {
        [applyFilter.item.field]: inputElement.value,
      };
      // setApplyQueries((prev) => ({ ...prev, ...newFitler }));
      setApplyQueries((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            ...newFitler,
          },
        };
      });

      // close the filter
      setApplyFilter({ item: initialFilterItem, show: false });
    }
  };

  const applyFilterWorkerTypeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IFilterItem
  ) => {
    // const value = event.target.value === "worker" ? false : true;
    let value = false
    if (event.target.name === "is_employee" || event.target.name === "is_candidate") {
      value = event.target.value === "worker" ? false : true;
    }

    if (event.target.name === "active" || event.target.name === 'inactive') {
      value = event.target.value === "false" ? false : true;
    }

    // check filter is exists or not
    const isExist = activeFilters.find((item) => {
      return item.field === applyFilter.item.field || item.value === value;
    });
    if (isExist) {
      toast.info("This filter is already applied");
      return;
    }

    // add new filter to active filter
    const newItem: IFilterItem = {
      ...applyFilter.item,
      value: value,
      condition: value === false ? "is" : "is not",
    };

    setActiveFilters((prev) => [...prev, newItem]);

    // create a new fitler for apply filter
    const newFitler = {
      [applyFilter.item.field]: value,
    };

    setApplyQueries((prev) => {
      return {
        ...prev,
        filter: {
          ...prev.filter,
          ...newFitler,
        },
      };
    });

    // close the filter
    setApplyFilter({ item: initialFilterItem, show: false });
  };

  const toggleFilterHandler = (item: IFilterItem, idx: number) => {
    // set the condition to excludes
    const newActiveFilters = [...activeFilters];
    if (item.type === "text") {
      newActiveFilters[idx].condition =
        newActiveFilters[idx].condition === "includes"
          ? "excludes"
          : "includes";
      setActiveFilters(newActiveFilters);
      if (newActiveFilters[idx].condition === "excludes") {
        // find the field name based  on the index and the name of the field
        let fieldName = Object.keys(applyQueries.filter)[idx];
        // create a new fitler for apply filter
        const excludeFieldName = `not_${fieldName}`;
        const newFitler = {
          [excludeFieldName]: newActiveFilters[idx].value,
        };
        const newApplyQueries = { ...applyQueries };
        // remove the previous filename from the applyQueries
        // @ts-ignore
        delete newApplyQueries.filter[fieldName];

        // add the new filter to the applyQueries
        setApplyQueries((prev) => {
          return {
            ...prev,
            filter: {
              ...prev.filter,
              ...newFitler,
            },
          };
        });
      } else {
        // find the field name based  on the index and the name of the field
        let fieldName = Object.keys(applyQueries.filter)[idx];

        const newApplyQueries = { ...applyQueries };

        for (const [key, value] of Object.entries(newApplyQueries.filter)) {
          if (key === fieldName) {
            // @ts-ignore
            delete newApplyQueries.filter[key];
            // @ts-ignore
            newApplyQueries.filter[fieldName.replace("not_", "")] = value;
          }
        }

        setApplyQueries(newApplyQueries);
      }
    }

    // ======================  Select ======================
    if (item.type === "select" && item.field === "is_employee") {
      // {item.value === "employee" ? "Worker" : "Employee"}
      newActiveFilters[idx].condition =
        newActiveFilters[idx].value === true ? "is" : "is not";
      newActiveFilters[idx].value =
        newActiveFilters[idx].value === true ? false : true;
      setActiveFilters(newActiveFilters);

      // return
      // find the field name based  on the index and the name of the field
      const newFitler = {
        is_employee: newActiveFilters[idx].value,
      };


      const newApplyQueries = { ...applyQueries };
      // remove the previous filename from the applyQueries based on field name status and value true
      // @ts-ignore
      delete newApplyQueries.filter["is_employee"];
      // add the new filter to the applyQueries
      setApplyQueries((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            ...newFitler,
          },
        };
      });
    }
    if (item.type === "select" && item.field === "is_valid") {
      // {item.value === "employee" ? "Worker" : "Employee"}
      newActiveFilters[idx].condition =
        newActiveFilters[idx].value === true ? "is" : "is";

      newActiveFilters[idx].value =
        newActiveFilters[idx].value === true ? false : true;
      setActiveFilters(newActiveFilters);

      // return
      // find the field name based  on the index and the name of the field
      const newFitler = {
        is_valid: newActiveFilters[idx].value,
      };

      const newApplyQueries = { ...applyQueries };
      // remove the previous filename from the applyQueries based on field name status and value true
      // @ts-ignore
      delete newApplyQueries.filter["is_valid"];
      // add the new filter to the applyQueries
      setApplyQueries((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            ...newFitler,
          },
        };
      });
    }
  };

  // close the fitler with ESC key
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowFilter(false);
        closeDrawer()
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  // Close the filter by clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      const filterEl = filterRef.current;
      const buttonEl = buttonRef.current;

      if (
        filterEl &&
        buttonEl &&
        !filterEl.contains(targetEl) &&
        !buttonEl.contains(targetEl)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterRef, buttonRef, setShowFilter]);

  // ========================================================================

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      if (
        applyFilterRef.current &&
        !applyFilterRef.current.contains(targetEl) &&
        applyFilter.show === true
      ) {
        setApplyFilter((prev) => ({
          ...prev,
          show: false,
        }));
      }
    };

    setTimeout(() =>
      document.addEventListener("click", handleClickOutside, false)
    );
    // document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [applyFilter.show, applyFilterRef, setApplyFilter]);

  // ========================================================================

  const navigateToWorker = (worker: IWorkerData) => {
    // create a tab object
    const newTab: ITab = {
      index: globalTabs.tabs.activeTab.index,
      path: `/workers/${worker.user.id}`,
      name: `Worker - ${worker.user.first_name} ${worker.user.last_name}`,
    };
    updateTab(newTab);
    dispatch(setCurrentWorker(worker.user));
    navigate(`${worker.user.id}`);
  };

  if (isError)
    return <span>Error: {error?.message || "Something went wrong!"}</span>;

  return (

    <section className="relative" ref={workerSectionPageRef}>
      {/* ===================== TAB ======================*/}
      <TabBar />
      {/* ===========================================*/}
      <div className="border border-[borderGray] bg-white border-l-0 border-t-0 px-2 py-2.5 dark:bg-bckDark sm:px-4">
        {/* Filtering Operation */}
        <div className="relative flex flex-wrap items-center justify-between">
          <AppButton
            ref={buttonRef}
            variant="primary"
            type="button"
            size="xs"
            onClick={() => setShowFilter(!showFilter)}
          >
            <div className="flex items-center justify-center gap-1">
              <FilterIcon className="w-3 h-3" />
              <span className="text-xs">Filter</span>
              <span>+</span>
            </div>
          </AppButton>
          {applyFilter.show ? (
            <div
              ref={applyFilterRef}
              className="absolute top-0 left-0 z-50 p-2 bg-white border rounded shadow-md border-borderGray w-60 "
            >
              {applyFilter.item.type === "text" ? (
                <input
                  className="block w-full p-2 text-sm border-transparent rounded-lg outline-none text-textDark focus:border-transparent focus:ring-0"
                  type="text"
                  id="fitler_search"
                  name="filter_search"
                  placeholder={`Search ${applyFilter.item?.label}`}
                  autoFocus
                  onKeyDown={applyInputFilterHandler}
                />
              ) : null}
              {applyFilter.item.type === "select" && applyFilter.item.field === 'is_employee' ? (
                <>
                  <div className="flex flex-col gap-2">
                    <label className="flex items-center p-1" htmlFor="employee">
                      <input
                        type="checkbox"
                        id="employee"
                        name="is_employee"
                        value="employee"
                        onChange={(e) => applyFilterWorkerTypeHandler(e, applyFilter.item)}
                        className="w-4 h-4 mr-2 custom__checkbox"
                      />
                      <span className="text-sm text-textDark"> Employee</span>
                    </label>

                    <label
                      className="flex items-center p-1 text-sm text-textDark"
                      htmlFor="worker"
                    >
                      <input
                        type="checkbox"
                        id="worker"
                        name="is_candidate"
                        value="worker"
                        className="w-4 h-4 mr-2 custom__checkbox"
                        onChange={(e) => applyFilterWorkerTypeHandler(e, applyFilter.item)}
                      />
                      <span className="text-sm text-textDark">Candidate</span>
                    </label>
                  </div>
                </>
              ) : null}
              {applyFilter.item.type === "select" && applyFilter.item.field === 'is_valid' ? (
                <>
                  <div className="flex flex-col gap-2">
                    <label className="flex items-center p-1" htmlFor="active">
                      <input
                        type="checkbox"
                        id="active"
                        name="active"
                        value="true"
                        onChange={(e) => applyFilterWorkerTypeHandler(e, applyFilter.item)}
                        className="w-4 h-4 mr-2 custom__checkbox"
                      />
                      <span className="text-sm text-textDark">Active</span>
                    </label>

                    <label
                      className="flex items-center p-1 text-sm text-textDark"
                      htmlFor="Inactive"
                    >
                      <input
                        type="checkbox"
                        id="Inactive"
                        name="inactive"
                        value="false"
                        className="w-4 h-4 mr-2 custom__checkbox"
                        onChange={(e) => applyFilterWorkerTypeHandler(e, applyFilter.item)}
                      />
                      <span className="text-sm text-textDark">Inactive</span>
                    </label>
                  </div>
                </>
              ) : null}
            </div>
          ) : null}

          {showFilter ? (
            <div
              ref={filterRef}
              className="absolute top-0 left-0 z-50 p-1 bg-white border rounded shadow-md border-borderGray w-60"
            >
              <input
                type="text"
                className="block w-full p-2 text-sm border-transparent rounded-lg outline-none text-textDark focus:border-transparent focus:ring-0"
                placeholder="Filter"
                value={searchFitlerText}
                onChange={searchFilterHandler}
              />
              <hr className="h-px border-1 border-borderGray dark:border-textDark" />

              {allFitlers.map((item, i) => (
                <div
                  key={i}
                  className="flex items-center justify-between p-2 transition-all rounded-md cursor-pointer hover:bg-lightGrayColor"
                  onClick={() => {
                    setShowFilter(false);
                    setApplyFilter({ item: item, show: true });
                  }}
                >
                  <div className="flex items-center gap-2 hover:cursor-pointer">
                    {item.icon}
                    <span className="text-sm transition-all text-textDark">
                      {item.label}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      {/* ===================== */}
      {activeFilters.length > 0 ? (
        <div className="border border-[borderGray] bg-white p-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30">
          <div className="relative flex items-center justify-between">
            {/* =============================  Show Filters ==================== */}
            <div className="flex gap-2">
              {activeFilters.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex items-center justify-start gap-2"
                  >
                    <div className="flex items-center justify-center h-full gap-1 px-1 bg-white">
                      <span className="px-2 py-1 text-xs rounded-sm bg-primary text-textDark">
                        {item.label}
                      </span>
                      <span
                        className="px-1 py-1 text-xs rounded-sm cursor-pointer bg-primary text-grayColor"
                        onClick={() => toggleFilterHandler(item, idx)}
                      >
                        {/* {item.condition} */}
                        {/* {typeof item.value === "boolean"
                          ? item.value === true
                            ? "is"
                            : "is not"
                          : item.condition} */}
                        {/* { typeof item.value === "boolean" && item.field === 'is_employee' ? item.value === true ? 'is' : 'is not' : item.condition }
                          { typeof item.value === "boolean" && item.field === 'is_valid' ? item.value === true ? 'is' : 'is' : item.condition } */}
                        {/* {item.condition} */}
                        <RenderFilterCondition item={item} />

                      </span>
                      <span className="px-2 py-1 text-xs rounded-sm bg-primary text-textDark">
                        {/* {item.value} */}
                        {item.type === "select" &&
                          item.field === "is_employee" ? (
                          <>
                            {item.value === "employee" ? "Worker" : "Employee"}
                          </>
                        ) : null}
                        {item.type === "select" &&
                          item.field === "is_valid" ? (
                          <>
                            {item.value === true ? 'Active' : 'Inactive'}
                          </>
                        ) : null}
                        {item.type === "text" ? <> {item.value} </> : null}
                      </span>
                      <div
                        className="flex items-center justify-center h-full px-1 py-1 rounded-sm bg-primary"
                        onClick={() => {
                          const newActiveFilters = [...activeFilters];
                          const findFilterItem = newActiveFilters.filter(
                            (_, i) => i === idx
                          )[0];

                          setActiveFilters((prev) => {
                            return prev.filter((_, i) => i !== idx);
                          });

                          // remove the filter from the applyQueries
                          const newApplyQueries = { ...applyQueries };
                          if (findFilterItem.condition === "excludes") {
                            // @ts-ignore
                            delete newApplyQueries.filter[
                              `not_${findFilterItem.field}`
                            ];
                          } else {
                            // @ts-ignore
                            delete newApplyQueries.filter[findFilterItem.field];
                          }
                          setApplyQueries(newApplyQueries);
                        }}
                      >
                        <CloseIcon className="h-3" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* ================================================================ */}
            <div className="flex items-center justify-center gap-2 w-60">
              <span className="text-sm text-textGray">
                <span className="text-sm text-textGray">
                  {totalCount} results
                </span>
              </span>
              <AppButton
                variant="primary"
                type="button"
                size="xs"
                onClick={() => {
                  setActiveFilters([]);
                  setApplyQueries({ filter: {} });
                  localStorage.removeItem("workerFilters");
                  refetch();
                }}
              >
                <div className="flex items-center gap-1 flex-justify-center">
                  <span>Clear All Filters </span>
                  <CloseIcon className="w-3 h-3" />
                </div>
              </AppButton>
            </div>
          </div>
        </div>
      ) : null}

      {/* ==================== */}
      <div className="pb-10">
        <div className="w-[97%] py-5 m-auto space-y-5">
          <div className="flex items-center justify-center">
            {/* {isFetching && <Loader isLoading size={35} />} */}
            <Loader isLoading={isLoading} size={35} />
          </div>
          <div className="relative shadow-md sm:rounded-lg" ref={tableRef}>
            <table id="daniTable" className="w-full overflow-x-scroll text-sm text-left text-textDark">
              <thead className="text-lg font-normal capitalize text-textDark bg-primary dark:bg-primary">
                <tr>
                  <th scope="col" className="py-3" />
                  <th scope="col" className="py-3 font-medium">
                    Worker Name
                  </th>
                  <th scope="col" className="py-3 font-medium">
                    Contact
                  </th>
                  <th scope="col" className="py-3 font-medium text-left">
                    Worker Type
                  </th>
                  <th scope="col" className="py-3 font-medium text-left">
                    Pathways
                  </th>
                  <th scope="col" className="py-3 font-medium text-left">
                    Status
                  </th>
                </tr>
              </thead>
              {/* ====================================== */}
              <tbody className="overflow-y-hidden">
                {data?.pages?.map?.((infinitePage, i) => {
                  return (
                    <React.Fragment key={i}>
                      {infinitePage?.data?.map((item, index: number) => {
                        const tooltipList =
                          item.worker_pathway_details
                        //  &&
                        // item.worker_pathway_details.filter(
                        //   (d) => d.pathway_status === true
                        // );

                        return (
                          <tr
                            className="transition-all bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer"
                            key={index}
                          >
                            <td
                              className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                              width={40}
                            >
                              <input
                                type="checkbox"
                                checked={selectedWorkers.length > 0 ? selectedWorkers.includes(item.user.id) : false}
                                onChange={() => {
                                  if (selectedWorkers.includes(item.user.id)) {
                                    setSelectedWorkers(
                                      selectedWorkers.filter(
                                        (worker) => worker !== item.user.id
                                      )
                                    );
                                  } else {
                                    setSelectedWorkers([
                                      ...selectedWorkers,
                                      item.user.id,
                                    ]);
                                  }
                                }}
                                className="w-4 h-4 text-blue-600 bg-transparent bg-gray-200 border-2 border-gray-300 rounded-sm cursor-pointer checkbox-worker focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                              />
                            </td>
                            <td
                              onClick={() => showDrawerHandler(item)}
                              className="py-3 pr-6 font-medium text-textDark whitespace-nowrap dark:text-white"
                              width={350}
                            >
                              <div className="flex">
                                <div className="relative flex flex-col items-center self-center justify-center w-12 h-12 bg-pink-500 rounded-md p-3bg-pink-500">
                                  <span className="text-white uppercase text-md dark:text-white">
                                    {item.user &&
                                      item.user.first_name &&
                                      item.user.last_name ? (
                                      <>
                                        {`${item.user.first_name} ${item.user.last_name}`
                                          .split(" ")
                                          .map((word) => word.charAt(0))
                                          .join("")}
                                      </>
                                    ) : null}
                                  </span>
                                </div>
                                <div className="flex flex-col items-start justify-center p-2 ml-2">
                                  <p className="mb-2 text-lg whitespace-nowrap text-textDark dark:text-white">
                                    {item.user &&
                                      item.user.first_name &&
                                      item.user.last_name ? (
                                      <>
                                        {item.user.first_name +
                                          " " +
                                          item.user.last_name}
                                      </>
                                    ) : null}
                                  </p>
                                  <p className="flex gap-1 text-xs whitespace-nowrap text-grayColor dark:text-white">
                                    {item.user && item.user.last_login ? (
                                      <>
                                        {item.user.last_login ? (
                                          <>
                                            <ActiveTimerIcon className="w-3 h-3" />
                                            {moment(item.user.last_login).isValid() ? (
                                              <span>
                                                {moment().diff(moment(item.user.last_login), 'days') > 0
                                                  ? moment().diff(moment(item.user.last_login), 'days')
                                                  : moment().diff(moment(item.user.last_login), 'hours') > 0
                                                    ? moment().diff(moment(item.user.last_login), 'hours')
                                                    : moment().diff(moment(item.user.last_login), 'minutes') > 0
                                                      ? moment().diff(moment(item.user.last_login), 'minutes')
                                                      : moment().diff(moment(item.user.last_login), 'seconds')}
                                                {" "}
                                                {moment().diff(moment(item.user.last_login), 'days') < 1
                                                  ? moment().diff(moment(item.user.last_login), 'hours') >= 1
                                                    ? moment().diff(moment(item.user.last_login), 'hours') === 1
                                                      ? 'hour'
                                                      : 'hours'
                                                    : moment().diff(moment(item.user.last_login), 'minutes') >= 1
                                                      ? moment().diff(moment(item.user.last_login), 'minutes') === 1
                                                        ? 'minute'
                                                        : 'minutes'
                                                      : moment().diff(moment(item.user.last_login), 'seconds') === 1
                                                        ? 'second'
                                                        : 'seconds'
                                                  : moment().diff(moment(item.user.last_login), 'days') === 1
                                                    ? 'day'
                                                    : 'days'}
                                                {" "}
                                                since last login
                                              </span>
                                            ) : (
                                              item.user.last_login
                                            )}
                                          </>

                                        ) : null}
                                        {/* "MMM DD YYYY h:mm:ss A" */}
                                      </>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td
                              onClick={() => showDrawerHandler(item)}
                              className="py-3 text-textDark"
                              width={350}
                            >
                              {(item.user && item.user.email_address) ||
                                item?.user?.phone_number ? (
                                <>
                                  {item.user.email_address === "" &&
                                    item?.user?.phone_number === "" ? (
                                    <span className="text-textDark dark:text-white">
                                      No Contact Information
                                    </span>
                                  ) : (
                                    <>
                                      {item?.user?.phone_number ? (
                                        <div className="flex items-center justify-start gap-2 mb-1">
                                          <PhoneCallIcon className="w-5 h-5 pr-1" />
                                          <span className="text-textDark">
                                            {item?.user?.phone_number || ""}
                                          </span>
                                        </div>
                                      ) : null}
                                      {item?.user?.email_address ? (
                                        <div className="flex items-center justify-start gap-2 ">
                                          <EmailIcon className="w-5 h-5 pr-1" />
                                          <span className="text-textDark">
                                            {item?.user?.email_address || ""}
                                          </span>
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              ) : null}
                            </td>
                            <td
                              onClick={() => showDrawerHandler(item)}
                              className="py-3 text-left text-textDark"
                              width={150}
                            >
                              {item.user && item.user.is_worker ? (
                                <>
                                  {item.user.is_worker && item.user.is_employee
                                    ? "Employee"
                                    : "Candidate"}
                                </>
                              ) : null}
                            </td>
                            <td className="py-3 text-textDark">
                              <div className="flex items-center justify-start gap-1 ">
                                <span className="mr-1">
                                  {item.user ? (
                                    <> {item?.user.participants?.length || 0}</>
                                  ) : null}{" "}
                                  Pathways
                                </span>
                                {item.user && tooltipList ? (
                                  <Tooltip
                                    placement="right"
                                    style="light"
                                    animation={false}
                                    content={tooltipList.map((item, index) => {
                                      return (
                                        <p
                                          key={index}
                                          className="flex items-center justify-start gap-2 mx-2 my-1 text-xs text-textDark"
                                        >
                                          <svg
                                            aria-hidden="true"
                                            className="h-3 w-3 flex-shrink-0 text-grayColor transition duration-0 hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white;"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 7.573 8.414"
                                          >
                                            <path
                                              id="icon_pathways"
                                              d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                                              transform="translate(-6 -4)"
                                              fill="currentColor"
                                            />
                                          </svg>
                                          {item.pathway_name
                                            ? `${item.pathway_name} ${item.pathway_participant_status
                                              ? "(active)"
                                              : "(inactive)"
                                            }`
                                            : "No Title"}
                                        </p>
                                      );
                                    })}
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                ) : null}
                              </div>
                            </td>
                            <td
                              className="py-3 text-left text-textDark"
                              width={70}
                            >
                              {item.user && item.user.is_worker ? (
                                <>
                                  {item.user.is_valid
                                    ? "Active"
                                    : "Inactive"}
                                </>
                              ) : null}
                            </td>
                            <tr ref={ref}></tr>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>

              {/* ====================================== */}
            </table>
          </div>
          <div className="flex items-center justify-center">
            {isFetchingNextPage && <AppLoader isLoading size={25} />}
          </div>
        </div>
      </div>

      {/* =============== DRAWER ==================== */}
      <Drawer
        setOpen={closeDrawer}
        isOpen={showDrawer}
        title=""
        isFull
        outsideRef={tableRef}
        classNames="overflow-y-scroll"
      >
        {selectedWorker ? (
          <div className="flex flex-col justify-between h-full overflow-y-scroll">
            <div className="flex flex-col gap-2 p-4">
              {/* ========== */}
              <div className="flex">
                <div className="relative flex flex-col items-center self-center justify-center w-16 h-16 bg-pink-500 rounded-md">
                  <span className="text-xl text-white uppercase dark:text-white">
                    {`${selectedWorker?.user.first_name} ${selectedWorker?.user.last_name}`
                      .split(" ") // split string into an array of words
                      .map((word) => word.charAt(0)) // get first letter of each word
                      .join("")}
                  </span>
                </div>
                <div className="flex flex-col items-start justify-center p-2">
                  <p className="flex items-center gap-4 mb-2 text-lg whitespace-nowrap text-textDark dark:text-white">
                    <span>
                      {" "}
                      {selectedWorker?.user.first_name +
                        " " +
                        selectedWorker?.user.last_name}
                    </span>

                    <Favorite
                      favorite_owner={"Worker"}
                      slug={selectedWorker?.user.id.toString()}
                    />
                  </p>

                  <p className="flex gap-1 text-xs whitespace-nowrap text-grayColor dark:text-white">
                    {selectedWorker?.user.last_login ? (
                      <>
                        <ActiveTimerIcon className="w-3 h-3" />
                        {moment(selectedWorker?.user.last_login).isValid()
                          ? moment(selectedWorker?.user.last_login).format('MMM-DD-YYYY')
                          : selectedWorker?.user.last_login}
                      </>
                    ) : // MMM DD YYYY h:mm:ss A
                      null}
                  </p>
                </div>
              </div>

              {/* ========== */}
              <div className="flex items-center justify-between">
                {/* <span className="text-xs text-textGray">Worker Notes</span> */}
                <span className="text-xs text-textGray">
                  Last updated{" "}
                  {moment(selectedWorker.user.updated_at).format('MMM-DD-YYYY')}
                </span>
              </div>
              {/* ========== */}
              <AppDivider />
              <div className="flex items-center justify-between">
                <span className="text-sm text-textGray">Worker Details</span>
              </div>
              <ul className="list-none">
                <li className="flex gap-2 my-2">
                  <span className="w-20 text-sm text-textGray">UUID</span>
                  <span className="text-sm text-textDark">
                    {selectedWorker.user.uuid}
                  </span>
                </li>
                <li className="flex gap-2 my-2">
                  <span className="w-20 text-sm text-textGray">Phone</span>
                  <span className="text-sm text-textDark">
                    {selectedWorker.user.phone_number}
                  </span>
                </li>
                <li className="flex gap-2 my-2">
                  <span className="w-20 text-sm text-textGray">Email</span>
                  <span className="text-sm text-textDark">
                    {selectedWorker?.user.email_address}
                  </span>
                </li>
                <li className="flex gap-2 my-2">
                  <span className="w-20 text-sm text-textGray">Type</span>
                  <span className="text-sm text-textDark">
                    {selectedWorker.user.is_worker &&
                      selectedWorker.user.is_employee
                      ? "Employee"
                      : "Candidate"}
                  </span>
                </li>
              </ul>
              <AppDivider />
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm text-textGray">Worker Pathways</span>
              </div>
              <div className="flex flex-col gap-5 ">
                {selectedWorker?.worker_pathway_details &&
                  selectedWorker?.worker_pathway_details?.map((item, index) => {
                    return (
                      <div className="flex flex-col gap-1" key={index}>
                        <span className="flex items-center gap-2 text-md text-textDark">
                          <PathwayIcon />
                          {item?.pathway_name ? item?.pathway_name : "No Title"}
                        </span>
                        <span className="flex items-center justify-start gap-1 ml-5 text-sm text-grayColor">
                          <ArrowIcon />
                          Current State:{" "}
                          {item?.pathway_current_state_name
                            ? item?.pathway_current_state_name
                            : ""}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="pb-5">
              <div
                onClick={() => navigateToWorker(selectedWorker)}
                className="flex items-center justify-center w-full py-6 bg-blueColor btn-drawer hover:cursor-pointer "
              >
                <span className="flex items-center justify-center gap-2 text-sm text-white">
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 w-4 h-4 text-white transition duration-100 hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 11.071 7.778"
                  >
                    <path
                      fill="currentColor"
                      d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
                      transform="translate(-1.9 -8.95)"
                    />
                  </svg>{" "}
                  View Worker{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </Drawer>
      {/* =============== COMMANDBAR ==================== */}
      <CommandBar isOpen={showCommandBar} setOpen={closeCommandBar}>
        <span className="text-xs text-textGray">
          {selectedWorkers.length} Worker{selectedWorkers.length > 1 ? "s" : ""}{" "}
          Selected
        </span>
        <AppButton
          type="button"
          variant="primary"
          onClick={() => {
            setShowPopupDeleteWorker((prev) => !prev)
            workerSectionPageRef?.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          <div className="flex items-center">
            <DeleteIcon className="mr-1" />
            <span className="text-sm">Delete</span>
          </div>
        </AppButton>
      </CommandBar>
      {/* ===================  POPUP Modal ======================== */}
      <PopupModal
        isParentTop
        extraClasses="mt-12"
        setOpen={setShowPopupDeleteWorker}
        isOpen={showPopupDeleteWorker}
        title="If you want to continue, confirm by clicking the delete button below."
        onConfirmClick={confirmDeleteWorker}
      />
    </section>
  );
};

WorkersPage.displayName = "Workers Page";

export default WorkersPage;