import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { IOrganizationResponse, IOrganizationSetting } from "../../../../../Types/Organization/OrganizationSettings";
import { getOrganization, updateOrganizationSettingsWorkers } from "../../../../../apiQuery/Organization/Organization.apis";
import AppInput from "../../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../../Theme";
import WYSIWYGEditor from "../../../../General/Form/WYSIWYGEditor";
import { object, string } from 'yup';
import { toast } from "../../../../../utils/Toast";



let workerSchema = object({
  employee_support_link: string().url(),
  candidate_support_link: string().url(),
  terms_of_service: string()
});


const SupportLinks: React.FC = () => {

  const [formData, updateFormData] = useState<IOrganizationSetting>()

  const { refetch } = useQuery<IOrganizationResponse, Error>(['organization-data'], () => getOrganization(), {
    onSuccess: (data) => {
      updateFormData(data.data)
    }
  })

  const updateOrganizationSettings = useMutation((data: IOrganizationSetting) => updateOrganizationSettingsWorkers(data), {
    onSuccess: () => {
      toast('Organization Settings Updated Successfully', 'success')
      refetch()
    }
  })


  const submitFormHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      // await workerSchema.validate(formData!, { abortEarly: false })
      // if the validation is successful then we can call the api to update the organization
      updateOrganizationSettings.mutate(formData!)
    }
    catch (err: any) {
      // if the error is a yup error, then we can extract the errors and log them to the console
      if (err.inner) {
        console.log('errors', err.inner)
        //  if the error container employee_support_link or candidate_support_link show the alert of the error to the user
        if (err.inner.find((error: any) => error.path === 'employee_support_link' || error.path === 'candidate_support_link')) {
          toast('link is not valid', 'error')
        }
      }
    }
  }


  return (
    <div className="flex flex-col gap-5 mb-12">
      <div className="mb-3">
        <h2 className="text-2xl font-normal text-textDark">Support Links</h2>
      </div>
      <hr className="h-px border-1 border-borderGray dark:border-textDark" />
      <form onSubmit={submitFormHandler} className="flex flex-col gap-4">
        <AppInput tabIndex={-1} autoFocus placeholder="Employee Support Link"
          onChange={e => {
            updateFormData({ ...formData!, employee_support_link: e.target.value })
          }}
          value={formData?.employee_support_link || ''} name='employee_support_link' isFull label='Employee Support Link' />
        <AppInput tabIndex={0}
          onChange={e => {
            updateFormData({ ...formData!, candidate_support_link: e.target.value })
          }}
          placeholder="Candidate Support Link" value={formData?.candidate_support_link || ''} name='candidate_support_link' isFull label='Candidate Support Link' />
        <WYSIWYGEditor onChange={e => {
          updateFormData({ ...formData!, terms_of_service: e })
        }}
          value={formData?.terms_of_service || ''} placeholder={"Terms of Use"}></WYSIWYGEditor>
        <div>
          <AppButton size="md" variant="primary" type="submit" extendClass="px-3">
            <div className="flex items-center justify-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
              >
                <path
                  fill="currentColor"
                  d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
              </svg>
              Save</div>
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default SupportLinks;
