import React, { ChangeEventHandler, FC, useCallback, useContext, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { useMutation, useQueryClient } from "react-query";
import { uploadFile } from "../../../../../apiQuery/Common/Upload.api";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { updateOrganization } from "../../../../../apiQuery/Organization/Organization.apis";
import { jobProfile, organization_T } from "../../../../../Common/Types";
import { UserContext } from "../../../../../Context/UserContext/UserContext";
import { store } from "../../../../../GlobalState/store";
import { toast } from "../../../../../utils/Toast";
import { AppButton } from "../../../../../Theme";

type props = {
  organization?: organization_T
}

const GeneralTab: FC<props> = ({ organization }) => {
  const handleUpload = useMutation((formData: any) => uploadFile(formData), {})
  const queryClient = useQueryClient()
  const [imageSrc, setImageSrc] = useState<string>()
  const [file, setFile] = useState<any>(undefined)
  const { t } = useTranslation();

  useContext(store);
  // const organization_id = useMemo(() => state.organization_id, [state.organization_id])
  const { currentOrganizationID } = useContext(UserContext)
  const organization_id = currentOrganizationID()

  const { handleSubmit, watch, setValue } = useForm<organization_T>({
    defaultValues: { ...organization }
  });

  // const items = watch("data.job_profiles") || []
  const items = organization?.data.job_profiles || []

  const [input, setInput] = useState('')
  const [baseColor, setBaseColor] = useState('')
  const [baseBackgroundColor, setBaseBackgroundColor] = useState('');

  useEffect(() => {
    if (organization?.data) {
      setBaseColor(organization.data.base_color);
      setBaseBackgroundColor(organization.data.base_background_color);
    }
  }, [organization]);

  const handleBackgroundColorChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setBaseBackgroundColor(event.target.value);
  };

  const handleColorChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setBaseColor(event.target.value);
  };

  const submitItem = () => {
    if (input === '') return
    setValue('data.job_profiles', [...items, input]);
    setInput('')
  }

  const handleRemove = (ind: number) => setValue('data.job_profiles', items.filter((_, index) => ind !== index))



  const handleUpdate = useMutation((jdata: jobProfile) => updateOrganization(organization_id, { ...jdata }), {
    onSuccess: async () => {
      setImageSrc(undefined)
      setFile(undefined)
      await queryClient.invalidateQueries(["organization", organization_id])
      // dispatch(setSnackbar({ isOpen: true, type: 'success', message: 'Organization has been updated' }))
      toast(t('organization.updated_organization'), 'success')
    },
    onError: (error: null | Error) => {
      const message = error?.message || `${t('organization.failed_to_update_organization')}`
      // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
      toast(message, 'error')
    }
  })

  const onSubmit: SubmitHandler<organization_T> = async data => {

    // let logo = data.data.logo || ""
    let logo = organization?.data?.logo || "";

    if (imageSrc) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", file);
      const { link } = await handleUpload.mutateAsync(formData)
      logo = link
    }


    await handleUpdate.mutateAsync({
      // job_profiles: data.data.job_profiles,
      job_profiles: items,
      logo: logo,
      base_color: baseColor,
      base_background_color: baseBackgroundColor
    })
  }

  const onFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      setFile(file)
      const imageDataUrl = URL.createObjectURL(file)
      setImageSrc(imageDataUrl)
      e.target.value = ""
    }
  }, [])

  const [enabled, setEnabled] = useState(false);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="flex flex-col gap-5 mb-12">
        <div className="mb-3">
          <h2 className="mb-1 text-2xl font-normal text-textDark">General</h2>
          <p className="text-sm text-textDark">
            Manage your organization settings.
          </p>
        </div>
        <hr className="h-px border-1 border-borderGray dark:border-textDark" />
        <div>
          <div className='flex'>
            <div className="flex flex-col">
              <span className='text-textDark text-md'>Logo</span>
              <img src={imageSrc ? imageSrc : organization?.data?.logo} alt="logo" className="h-full rounded-sm w-44" />

              <div className="pt-8">
                <label htmlFor="contained-button-file"
                  className="px-3 py-1.5 flex items-center justify-center rounded-lg text-center text-sm outline-none transition-all ease-in-out hover:opacity-100 focus:opacity-90 disabled:cursor-not-allowed disabled:opacity-30 hover:cursor-pointer bg-primary hover:shadow-sm">
                  <span>{t('Upload_logo')}</span>
                </label>

                <input onChange={onFileChange}
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                />

              </div>
            </div>
          </div>
          <p className="mt-2 mb-4 text-sm text-textDark">Pick a logo for your organization. Recommended size is 256x256px.</p>
        </div>
        <hr className="h-px border-1 border-borderGray dark:border-textDark" />
        <div>
          <h2 className="mt-2 mb-4 text-xl">Branding</h2>
          <div className="hidden">
            <div className="mb-5">
              <p className="mb-1 text-base text-textDark">Organization name</p>
              <input
                type="text"
                className="w-8/12 p-1 border rounded-md border-borderGray"
              />
            </div>
            <div className="mb-5">
              <p className="mb-1 text-base text-textDark">Organization URL</p>
              <input
                type="text"
                className="w-8/12 p-1 border rounded-md border-borderGray"
              />
            </div>
          </div>

          <div className="mb-5">
            <div className='flex items-center justify-start p-3 mb-2'>
              <span className="w-56 mr-2 text-base text-textDark">{t('organization.primary_color')}:</span>
              <input type="color" value={baseBackgroundColor} onChange={handleBackgroundColorChange
              } />
            </div>
            <p className="mt-2 mb-4 text-sm text-textDark">This color is used for backgrounds.</p>
          </div>

          <div className="mb-5">
            <div className='flex items-center justify-start p-3 mb-2'>
              <span className="w-56 mr-2 text-base text-textDark">{t('organization.secondary_color')}:</span>
              <input type="color" value={baseColor} onChange={handleColorChange} />
            </div>
            <p className="mt-2 mb-4 text-sm text-textDark">This color is used for buttons, icons, and other accent elements.</p>
          </div>
          {/* <Button
            disabled={handleUpdate.isLoading || handleUpload.isLoading}
            type="submit" label={
              <div className="flex items-center space-x-2 text-white">
                <AiFillFile className="text-3xl" />
                <p>{t('Update')}</p>
              </div>
            } extraClasses="w-fit px-3 py-3" /> */}
          <AppButton disabled={handleUpdate.isLoading || handleUpload.isLoading}
            variant="primary" type="submit" size="lg" >{t('Update')}</AppButton>
        </div>
        <hr className="h-px border-1 border-borderGray dark:border-textDark" />
        <div>
          <h2 className="mb-3 text-xl font-normal text-textDark">
            API Endpoints
          </h2>
          <div className="px-4">
            <div className="flex flex-col gap-2 p-4 border rounded-md border-borderGray">
              <p className="text-xs text-textDark">
                Set which API Endpoints should be active when pulling data from
                your Workday instance.
              </p>
              <div className="flex flex-col mb-2">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="getCandidate_API"
                    className="mb-2 text-xs text-textDark"
                  >
                    GetCandidate API
                  </label>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={`${enabled ? "bg-greenColor" : "bg-teal-700"}
          relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">API Endpoints</span>
                    <span
                      aria-hidden="true"
                      className={`${enabled ? "translate-x-3" : "translate-x-0"}
            pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
                <input
                  type="text"
                  className="w-8/12 p-1 border rounded-md border-borderGray"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default GeneralTab;
