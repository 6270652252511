import {  useState, useRef, useEffect , useImperativeHandle , forwardRef } from "react";
import { useMutation } from "react-query";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { Data } from "../../../../Types/ContentTemplate/ContentTemplate";
import AppButton from "../../../Shared/AppButton/AppButton";
import AppInput from "../../../Shared/AppInput/AppInput";
import { deleteContentTemplateById, getContentTemplateFilter } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import { toast } from "../../../../utils/Toast";
import { ISelector } from '../../../../Types/ContentTemplate/Selector';
import { getLangaugeLabel } from "../../../../utils/Languages";


interface AllSelectorContentProps {
	onEditClick: (selectorContent: Data<ISelector, EnumContentType.SelectorContent>) => void
}

const AllSelectorContents = (props : AllSelectorContentProps , ref : any) => {
	
	const [ search, setSearch ] = useState<string>("");
	const activateSearch = useRef<boolean>(false)
	const [ previousPage, setPreviousPage ] = useState<string>();
	const [nextPage, setNextPage] = useState<string>();
	

	// TODO: add type & cache data
//<IContentTemplateFilterResponse<ISelector, EnumContentType.SelectorContent>, Error>
	const {data, isLoading, isError, error , mutate} = useMutation(
		(pageDirection: 'next' | 'previous' | '') => getContentTemplateFilter<ISelector, EnumContentType.SelectorContent>
		([ EnumContentType.SelectorContent ], 3, search, nextPage, previousPage, pageDirection), {onSuccess(data) {
			if(data?.data) {
					const { next, previous } = data?.data.paginate
					setNextPage(next)
					setPreviousPage(previous)
				}
			},});
			

	useImperativeHandle(ref, () => ({
			refresh: () => {
				mutate('')
			}
	}));
	
	useEffect(() => {
		mutate('')
	}, [mutate]);
	
	
	const searchHandler = () => {
		activateSearch.current = true
		mutate('')

	}


	const deleteContentTemplate = useMutation( (params: { id:number }) => deleteContentTemplateById(params.id), {
		onSuccess: () => {
				toast("Content template deleted successfully", "success")
				mutate('')
		 },
		onError: (error:any) => { 
			
		},
		})

	const cofirmDeleteModal = (item :any) => {
		const contentTemplateId = item.id as number
		deleteContentTemplate.mutateAsync({ id: contentTemplateId })
		mutate('')
	}
	
	if ( isLoading ) return <h3>Loading...</h3>;
	if ( isError )
		return (
			<>
				<h3>Oops, something went wrong</h3>
			</>
		);
	
	return (
		<div className="">
			
			<div className="flex items-center justify-start">
				<AppInput placeholder={"Search"} value={search} name='search' onChange={(e) => setSearch(e.target.value)} extendClass="mr-5"/>
				<AppButton variant="primary" type={"button"} size={"md"} onClick={searchHandler} extendClass="mx-2">Search</AppButton>
			</div>
			<div className="flex items-center justify-between">
				<h1 className="mx-2 my-2 font-semibold">All Saved Selector Templates</h1>
				<div className="flex">
						 <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
						onClick={() => {
							activateSearch.current = true
							mutate('previous')
						           }}
						           extendClass="mx-1">Previous Template</AppButton> 
						 <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
						onClick={() => {
							mutate('next')
							           activateSearch.current = true
						           }}
						           extendClass="mx-1">Next Template</AppButton> 
				</div>
			</div>
			<div className="grid grid-cols-3 gap-2">
				{data?.data.data.map((item, idx) => {
					return (
						<div className="p-2 border rounded border-gray-200 bg-[#e5e5e5]" key={idx}>
								<p>Content template: {item.selector.map((item, idx,array) => (<span key={idx} className="mr-1 font-semibold">{getLangaugeLabel(item.language)}{array.length > idx +1 ? ',':null}</span>))}</p>
							{item.selector.map((item: ISelector, idx: number) => {
								return  (
									
									<div key={idx}>
										{ item.language === "en" ? (
											<div>
												<AppInput value={item.title} name={"title"} disabled isFull/>
												<AppTextArea value={item.description} name={"description"} disabled/>
										</div>) : null }
									</div>
									)
							})}
							<div className="flex">
								<AppButton variant="info" type={"button"} size={"sm"} onClick={() => props.onEditClick(item)} extendClass="mr-2">Edit Template</AppButton>
								<AppButton variant="danger" type={"button"} size={"sm"} onClick={() => cofirmDeleteModal(item)}>Delete Template</AppButton>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}


AllSelectorContents.displayName ="All Selector Contents"
export default forwardRef(AllSelectorContents)
