import { FC, ReactNode } from "react";

export interface TheadProps {
  children: ReactNode;
}

export const THead: FC<TheadProps> = (props) => {
  return (
    <thead className="text-lg font-normal text-textDark capitalize bg-primary dark:bg-primary ">
      {props.children}
    </thead>
  );
};
