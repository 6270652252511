// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ApexChart from "react-apexcharts";

// ** Third Party Imports
import { ApexOptions } from "apexcharts";
// ** Util Import
import { hexToRGBA } from "../../utils/hex-to-rgba";
import { FC } from "react";

interface SeriesData {
  name: string;
  data: number[];
}

interface ChartProps {
  series: SeriesData[];
  totalActivity: number;
}

const ActivityChart: FC<ChartProps> = ({ series, totalActivity }) => {
  const options: ApexOptions = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
    },
    grid: {
      padding: {
        top: -15,
        left: -14,
        right: -4,
        bottom: -15,
      },
      yaxis: {
        lines: { show: false },
      },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    colors: [hexToRGBA("#71DE33", 1), hexToRGBA("#6F68DA", 1)],
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "48%",
        // startingShape: 'rounded'
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    xaxis: {
      labels: { show: false },
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories: ["Jan", "Feb", "Mar", "Apr"],
    },
    yaxis: {
      labels: { show: false },
    },
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
          <Typography variant="h6" sx={{ mr: 1.5 }}>
            {totalActivity}
          </Typography>
          {/* <Typography variant='subtitle2' sx={{ color: 'error.main' }}>
            -22%
          </Typography> */}
        </Box>
        <Typography variant="body2">Chart Of Activates</Typography>
        <ApexChart type="bar" height={165} options={options} series={series} />
      </CardContent>
    </Card>
  );
};

export default ActivityChart;
