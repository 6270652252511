import React from "react";

import {SelectOptionModel} from "./models";

interface CustomSelectPropsModel {
    value: string;
    onChange: (value: string) => void;
    classNames?: string;
    options: SelectOptionModel[];
    clearFieldValue?: () => void;
}

const CustomSelect: React.FC<CustomSelectPropsModel> = ({
                                                            value,
                                                            onChange,
                                                            classNames,
                                                            options,
                                                        }) => {
    return (
        <select
            onChange={(e) => onChange(e.target.value)}
            className={`h-9 mr-2 border-2 bg-white rounded-md pl-2 border-inherit focus:outline-blue-400 ${classNames}`}
        >
            {options.map((item: SelectOptionModel, index: number) => (
                <option key={index} value={item.value}>
                    {item.title}
                </option>
            ))}
        </select>
    );
};

export default CustomSelect;
