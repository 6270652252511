export interface IFavorite {
    id: number;
    slug: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    organization_id: number;
    name: string;
    title: string;
    user_uuid:string;
    favorite_owner:string;
    worker_id:number | undefined;
    action_template_id:number | undefined;
    content_template_id:number | undefined;
  }
  export interface IFavoritesResponse {
    data : IFavorite[];
    error: boolean;
    message: string;
    status: number;
  }
  export const favoriteOwnerType = {

    action : "Action",
    worker : "Worker",
    content :"Content"

  }