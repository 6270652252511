import React, {FC, useState} from 'react';
import {FiMinusCircle, FiPlusCircle} from "react-icons/fi";
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import {AccordionProps, Fade, styled} from "@mui/material";
import {ContCommon, faqQuestions} from "../../../Common/Types";

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const FaqPreview:FC<ContCommon> = ({questions}) => {


    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            <div className="w-full flex flex-col justify-center">
                {
                    questions?.map((_,index)=><Accordion key={index} expanded={expanded === index.toString()}
                                                 onChange={handleChange(index.toString())}>
                        <AccordionSummary
                            expandIcon={expanded === index.toString() ?
                                <FiMinusCircle className="h-5 w-5"/> :
                                <FiPlusCircle className="h-5 w-5"/>}
                        >

                            <p>{_.question}</p>
                        </AccordionSummary>
                        <AccordionDetails className="bg-gray-100">
                            <p dangerouslySetInnerHTML={{ __html: _.answer || '' }} />
                        </AccordionDetails>
                    </Accordion>)
                }
            </div>
        </>
    );
};

export default FaqPreview;