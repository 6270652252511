export const PhoneCallIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.933 8.933"
      aria-hidden="true"
      className={`h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white
  ${className ? ` ${className}` : ""}`}
    >
      <path
        fill="currentColor"
        d="M128.375 224.933a6.692 6.692 0 01-3.009-.744 10.326 10.326 0 01-4.622-4.622 6.692 6.692 0 01-.744-3.009.551.551 0 01.558-.558h1.742a.439.439 0 01.3.118.548.548 0 01.167.316L123.1 218a.892.892 0 01-.006.316.478.478 0 01-.13.242l-1.241 1.253a10.3 10.3 0 001.557 2.01 8.605 8.605 0 001.954 1.452l1.179-1.216a.686.686 0 01.285-.192.573.573 0 01.323-.019l1.476.323a.542.542 0 01.434.534v1.675a.551.551 0 01-.558.558z"
        transform="translate(-120 -216)"
      ></path>
    </svg>
  );
};

PhoneCallIcon.defaultProps = {
  className: "",
};
