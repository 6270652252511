import { FC, useEffect, useRef, ForwardedRef, ReactNode, forwardRef, MouseEvent } from "react";
import { CloseIcon } from "../Icons";
import { TransitionAnimation } from "../Transition";

export interface DrawerProps {
  title?: string;
  isFull?: boolean;
  classNames?: string;
  isOpen: boolean;
  children: ReactNode;
  setOpen: (isOpen: boolean) => void;
  outsideRef?: ForwardedRef<HTMLDivElement>;  // ref passed in through props
}

export const Drawer: FC<DrawerProps> = forwardRef(
  (props: DrawerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { title, isFull, isOpen, setOpen, children, classNames, outsideRef } = props;

    // Reference to the drawer
    const drawerRef = useRef<HTMLDivElement | null>(null);

    // Click outside the drawer to close it
    
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
          // Do not close the drawer if the clicked element is inside the outsideRef
          if (outsideRef && 'current' in outsideRef && outsideRef.current && !outsideRef.current.contains(event.target as Node)) {
            setOpen(false);
          }
        }
      }
      // Bind the event listener
      // @ts-ignore
      document.addEventListener("mousedown", handleClickOutside);
      return () => {

      // @ts-ignore

        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [setOpen, outsideRef]);
    

    return (
      <TransitionAnimation show={isOpen} position="x-axis">
        <div
          ref={drawerRef} // Use the ref here
          id="drawer-right-example"
          className={`fixed outline-none duration-300 ease-in-out top-0 right-0 z-[45] h-screen w-[32rem] border-borderGray bg-white shadow-xl transition-transform ${
            isOpen ? "" : "hidden"
          } ${classNames || ""}}`}
          tabIndex={-1}
          aria-labelledby="drawer-right-label"
        >


          <h5
            id="drawer-right-label"
            className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
          >
            {/* <svg
          className='w-5 h-5 mr-2'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
            clipRule='evenodd'
          />
        </svg> */}
            {title || ""}
          </h5>
          <button
            type="button"
            data-drawer-hide="drawer-right-example"
            aria-controls="drawer-right-example"
            className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={() => setOpen(isOpen)}
          >
            <CloseIcon className="w-4 h-4" />
            <span className="sr-only">Close menu</span>
          </button>
          {/* <div className={`${isFull ? "h-[97%]" : "p-1"}`}>{children}</div> */}
          <div className="flex flex-col h-full">{children}</div>
          
          
        </div>
      </TransitionAnimation>
    );
  }
);
