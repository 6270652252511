export enum UserRoles {
    Member = 'Member',
    Admin = 'Admin',
    System = 'System',
}

export interface UserType {
    currentOrganization?: string
    user?: any,
    token?: string
    isAuthenticated?: boolean,
    userRole?: [UserRoles] | []
}
