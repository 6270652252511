import {useCallback, useState} from 'react';

const UseTab = (initial: string) => {

    const [tab, setSelectedTab] = useState<string>(initial)

    const setTab = useCallback((tabItem:string) => setSelectedTab(tabItem), [])

    return [tab, setTab];
};

export default UseTab;