import React from "react";
import { getToken } from "../utils/LocalStorage";

export function withAuth<P>( WrappedComponent: React.ComponentType<P> ): (Props: P) => JSX.Element {
    const ComponentWithExtraInfo = (Props: P) => {

        if (typeof window !== "undefined") {
            const token: string | null  = getToken()
            if (!token) {
            window.location.href ="/login"
            }
        }
        // @ts-ignore
        return <WrappedComponent {...Props} />;
    };

    return ComponentWithExtraInfo;
}

