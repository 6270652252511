/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-fragments */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import {
  FC,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import Balancer from "react-wrap-balancer";
import cravety from "./../../assets/action.png";
import { UserContext } from "../../Context/UserContext/UserContext";
import OverlayModal from "../../components/Layouts/OverlayModal";
import Index from "./../../components/Modal/ModalContainer/Setting/Index";
import {
  getToken,
  removeAllLocalTokensAndCookies,
} from "../../utils/LocalStorage";
import DropdownLanguage from "../../components/DropdownLanguage";
import { useTranslation } from "react-i18next";
import { TabContext } from "../../Context/TabContext/TabContext";
import { getOrganization } from "../../apiQuery/Organization/Organization.apis";
import { getAllFavorites } from "../../apiQuery/Favorites/favorites.apis";
import {
  IFavorite,
  IFavoritesResponse,
  favoriteOwnerType,
} from "../../Types/Favorites/Favorites";
import API from "../../apiQuery";
import { AppLoader } from "../../components/Shared/AppLoader";
import { useQuery } from "react-query";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { reorder } from "../../components/Utils/Reorder";

const WorkerIcon = () => {
  // const isActive = useLocation().pathname.includes("/workers");
  const isActive = useMatch("/workers");
  return (
    <svg
      aria-hidden="true"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.071 7.778"
    >
      <path
        fill="currentColor"
        d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
        transform="translate(-1.9 -8.95)"
      />
    </svg>
  );
};

const DashboardIcon = () => {
  const isActive = useMatch("/dashboard");
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`flex-shrink-0 w-4 h-5 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
    ${isActive ? "text-blueColor" : ""}
    `}
    viewBox="0 -960 960 960"
  >
    <path  fill="currentColor" d="M100.001-140.001V-200h759.998v59.999H100.001zm40-115.385v-244.613H240v244.613h-99.999zm193.077 0v-444.613h99.998v444.613h-99.998zm193.461 0v-324.613h99.998v324.613h-99.998zm193.461 0v-564.613h99.999v564.613H720z"></path>
  </svg>
  )
}

const WorkerIconFav = (props: { link: string }) => {
  const isActive = props.link === useLocation().pathname;
  return (
    <svg
      aria-hidden="true"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.071 7.778"
    >
      <path
        fill="currentColor"
        d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
        transform="translate(-1.9 -8.95)"
      />
    </svg>
  );
};

const PathwayIcon = () => {
  // const isActive = useLocation().pathname.includes("/pathways");
  const isActive = useMatch("/pathways");
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.573 8.414"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
    >
      <path
        id="icon_pathways"
        d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
        transform="translate(-6 -4)"
        fill="currentColor"
      />
    </svg>
  );
};

const ContentIcon = () => {
  // const isActive = useLocation().pathname.includes("/content-templates");
  const isActive = useMatch("/content-templates");
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 9.879 9.638"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
    >
      <path
        id="icon_content"
        d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
        transform="translate(-4 -4)"
        fill="currentColor"
      />
    </svg>
  );
};
const ContentIconFav = (props: { link: string }) => {
  const isActive = props.link === useLocation().pathname;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 9.879 9.638"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
    >
      <path
        id="icon_content"
        d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
        transform="translate(-4 -4)"
        fill="currentColor"
      />
    </svg>
  );
};

const ActionsIcons = () => {
  // const isActive = useLocation().pathname.includes("/action-templates");
  const isActive = useMatch("/action-templates");

  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.878 9.878"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
    >
      <path
        id="icon_actions"
        d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
        transform="translate(-2 -2)"
        fill="currentColor"
      />
    </svg>
  );
};

const ActionsIconsFav = (props: { link: string }) => {
  const isActive = props.link === useLocation().pathname;

  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.878 9.878"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
    >
      <path
        id="icon_actions"
        d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
        transform="translate(-2 -2)"
        fill="currentColor"
      />
    </svg>
  );
};

const RulesIcons = () => {
  // const isActive = useLocation().pathname.includes("/conditions");
  const isActive = useMatch("/conditions");
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.422 8.375"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
    >
      <path
        id="icon_rules"
        d="M8.12,16.375,6,14.255l2.12-2.12.55.55L7.492,13.863h3.743v.785H7.492L8.67,15.825Zm4.292-1.727a.4.4,0,0,1-.393-.393.37.37,0,0,1,.118-.281.388.388,0,0,1,.275-.111.38.38,0,0,1,.393.393.388.388,0,0,1-.111.275A.37.37,0,0,1,12.412,14.648Zm1.57,0a.4.4,0,0,1-.393-.393.37.37,0,0,1,.118-.281.388.388,0,0,1,.275-.111.38.38,0,0,1,.393.393.388.388,0,0,1-.111.275A.37.37,0,0,1,13.982,14.648ZM13.3,12.24l-.55-.55,1.178-1.178H10.188V9.727H13.93L12.752,8.55,13.3,8l2.12,2.12ZM7.439,10.512a.4.4,0,0,1-.393-.393.37.37,0,0,1,.118-.281.388.388,0,0,1,.275-.111.38.38,0,0,1,.393.393.388.388,0,0,1-.111.275A.37.37,0,0,1,7.439,10.512Zm1.57,0a.4.4,0,0,1-.393-.393.37.37,0,0,1,.118-.281.388.388,0,0,1,.275-.111.38.38,0,0,1,.393.393.388.388,0,0,1-.111.275A.37.37,0,0,1,9.01,10.512Z"
        transform="translate(-6 -8)"
        fill="currentColor"
      />
    </svg>
  );
};

const AnalyticsIcons = () => {
  // const isActive = useLocation().pathname.includes("/analytics");
  const isActive = useMatch("/analytics");

  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      className={`flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white 
      ${isActive ? "text-blueColor" : ""}
      `}
      viewBox="0 96 960 960"
    >
      <path
        fill="currentColor"
        d="M284 779h60V574h-60v205zm332 0h60V359h-60v420zm-166 0h60V661h-60v118zm0-205h60v-60h-60v60zM180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180z"
      ></path>
    </svg>
  );
};

const RemoveSidebarIcon = () => (
  <svg
    className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 7.637 7.637"
  >
    <path
      id="icon_close_remove"
      d="M6.386,9.651,7.818,8.219,9.25,9.651l.4-.4L8.219,7.818,9.651,6.386l-.4-.4L7.818,7.417,6.386,5.986l-.4.4L7.417,7.818,5.986,9.25Zm1.432,1.986a3.7,3.7,0,0,1-1.48-.3A3.847,3.847,0,0,1,4.3,9.3a3.814,3.814,0,0,1,0-2.969,3.816,3.816,0,0,1,.821-1.212A3.914,3.914,0,0,1,6.339,4.3a3.814,3.814,0,0,1,2.969,0,3.812,3.812,0,0,1,2.029,2.029,3.814,3.814,0,0,1,0,2.969,3.914,3.914,0,0,1-.816,1.217,3.816,3.816,0,0,1-1.212.821A3.718,3.718,0,0,1,7.818,11.637Z"
      transform="translate(-4 -4)"
      fill="currentColor"
    />
  </svg>
);

const ReorderIcon = (props: any) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.566 7.325"
      aria-hidden="true"
      className={`h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white ${className}`}
    >
      <path
        id="icon_drag"
        d="M14.893,15.325a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,15.325Zm2.758,0a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,17.651,15.325Zm-2.758-2.758a.879.879,0,0,1-.629-.252A.862.862,0,0,1,14,11.662a.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652A.913.913,0,0,1,14.893,12.566Zm2.758,0a.879.879,0,0,1-.629-.252.862.862,0,0,1-.263-.652.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652.913.913,0,0,1-.647.252ZM14.893,9.808a.85.85,0,0,1-.629-.269A.9.9,0,0,1,14.9,8a.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,9.808Zm2.758,0a.85.85,0,0,1-.629-.269A.9.9,0,0,1,17.662,8,.9.9,0,0,1,18.3,9.545.891.891,0,0,1,17.651,9.808Z"
        transform="translate(-14 -8)"
        fill="currentColor"
      />
    </svg>
  );
};

const SideBarItem = (props: {
  name: string;
  svg: ReactNode;
  link: string;
  onClick: (name: string, path: string) => void;
}) => {
  const currentRoute = useLocation().pathname;
  // const isActive = currentRoute.includes(props.link);
  const isActive = useMatch(props.link);
  const navigate = useNavigate();
  const { name, svg, link } = props;
  return (
    <li className="mt-0 ml-5">
      <div
        onClick={() => {
          navigate(link);
          props.onClick(name, link);
        }}
        className={`flex items-center px-2 py-1 rounded-sm cursor-pointer group hover:bg-hoverLightBlue ${isActive ? "bg-hoverLightBlue" : ""
          }`}
      >
        {isActive ? <div className="text-blueColor">{svg}</div> : svg}

        <span
          className={`flex-1 ml-2 transition-all whitespace-nowrap text-md text-textDark hover:text-blueColor group-hover:text-blueColor dark:text-white
          ${isActive ? "text-blueColor" : ""}
          `}
        >
          <Balancer>{name}</Balancer>
        </span>
      </div>
    </li>
  );
};

const SideBarFavItem = (props: {
  link: string;
  name: string;
  svg: ReactNode;
  onClickHandler: VoidFunction;
}) => {
  // const isActive = currentRoute.includes(props.);

  const { link, name, svg, onClickHandler } = props;
  const isActive = useLocation().pathname === link;
  return (
    <div
      className={`flex items-center justify-between flex-1 mt-0 ml-1 rounded-sm group hover:cursor-pointer hover:bg-hoverLightBlue
      ${isActive ? "bg-hoverLightBlue" : ""}
      `}
      onClick={onClickHandler}
    >
      <span className="flex items-center px-2 py-1 rounded-sm group hover:bg-hoverLightBlue">
        {svg}
        <span
          className={`flex-1 ml-2 transition-all whitespace-nowrap text-md text-textDark hover:text-blueColor group-hover:text-blueColor dark:text-white
          ${isActive ? "text-blueColor" : ""}
          `}
        >
          {name.length > 31 ? <Tooltip
            placement="left"
            overlay={<span>            {name}    </span>}
          >
            <Balancer>
              {name.length > 31 ? `${name.slice(0, 31)}...` : name}
            </Balancer>
          </Tooltip> :
            <Balancer>
              {name}
            </Balancer>

          }

        </span>
      </span>
      {/* <span className="hidden mr-1 group-hover:block" onClick={onClickHandler}>
        <RemoveSidebarIcon />
      </span> */}
    </div>
  );
};

export const Sidebar: FC = () => {
  const { i18n } = useTranslation();

  // Tab functionalities
  const { setTab, closeAllTabs } = useContext(TabContext);

  const [userMenu, setUserMenu] = useState(false);

  const userMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(targetEl) &&
        userMenu === true
      ) {
        setUserMenu(false);
      }
    };

    setTimeout(() =>
      document.addEventListener("click", handleClickOutside, false)
    );
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [userMenuRef, userMenu]);

  // ================== Favorites ==================

  const { data: organizationData, refetch } = useQuery(
    ["organization"],
    () => getOrganization(),
    {
      enabled: getToken() !== null,
    }
  );

  const {
    data: favorites,
    refetch: refetchFav,
    isLoading: isLoadingFavorites,
  } = useQuery<IFavoritesResponse>(["favorites"], () => getAllFavorites(), {
    enabled: getToken() !== null,
  });

  const [workerFavorites, setWorkerFavorites] = useState<IFavorite[]>([]);
  const [contentFavorites, setContentFavorites] = useState<IFavorite[]>([]);
  const [actionFavorites, setActionFavorites] = useState<IFavorite[]>([]);

  useEffect(() => {
    if (favorites?.data) {
      setWorkerFavorites(
        favorites?.data.filter(
          (item) => item.favorite_owner === favoriteOwnerType.worker
        )
      );
      setContentFavorites(
        favorites?.data.filter(
          (item) => item.favorite_owner === favoriteOwnerType.content
        )
      );
      setActionFavorites(
        favorites?.data.filter(
          (item) => item.favorite_owner === favoriteOwnerType.action
        )
      );
    }
  }, [favorites]);

  const onDragEndWorkers = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const updatedFavorites = reorder(
      workerFavorites,
      result.source.index,
      result.destination.index
    );

    // @ts-ignore
    setWorkerFavorites(updatedFavorites);
  };

  const onDragEndContent = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const updatedFavorites = reorder(
      contentFavorites,
      result.source.index,
      result.destination.index
    );

    // @ts-ignore
    setContentFavorites(updatedFavorites);
  };
  const onDragEndActions = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const updatedFavorites = reorder(
      actionFavorites,
      result.source.index,
      result.destination.index
    );

    // @ts-ignore
    setActionFavorites(updatedFavorites);
  };

  const handleDelete = async (favorite_slug: string) => {
    await API.delete(`/admin/organizations/favorites/${favorite_slug}`);
    refetchFav();
  };

  const tab = (name: string, link: string) => {
    setTab({ name, path: link });
  };
  // =====================

  const { currentUser, updateCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [profileModal, setProfileModal] = useState(false);

  const handleProfileModal = async () => {
    setProfileModal((prev) => !prev);
    await refetch();
  };

  const logoutHandler = () => {
    // close all tabs before logging out
    closeAllTabs()
    localStorage.clear()
    removeAllLocalTokensAndCookies();
    updateCurrentUser({ isAuthenticated: false, userRole: [], user: {} });
    let url =
      "https://" +
      process.env.REACT_APP_AUTH0_DOMAIN +
      "/v2/logout?client_id=" +
      process.env.REACT_APP_AUTH0_CLIENT +
      "&returnTo=" +
      window.location.origin;

    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      url =
        "https://" +
        process.env.REACT_APP_AUTH0_DOMAIN +
        "/v2/logout?client_id=" +
        process.env.REACT_APP_AUTH0_CLIENT +
        "&returnTo=" +
        window.location.origin;
    }
    if (process.env.REACT_APP_ENVIRONMENT === "staging") {
      url =
        "https://" +
        process.env.REACT_APP_AUTH0_DOMAIN +
        "/v2/logout?client_id=" +
        process.env.REACT_APP_AUTH0_CLIENT +
        "&returnTo=" +
        window.location.origin;
    }

    window.open(url, "_self");

    // navigate("/login");

    // handleClose();
  };

  if (!currentUser.isAuthenticated) {
    return null;
  }
  return (
    <Fragment>
      <aside
        id="separator-sidebar"
        className="top-0 left-0 z-40 h-screen w-[30rem] -translate-x-full scroll-auto transition-transform sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full flex flex-col justify-between overflow-y-auto scroll-auto overflow-x-hidden border-0 border-r border-[textGray] bg-white px-3 dark:bg-bckDark">
          <div>
            <ul className="">
              <li>
                <div
                  className="flex items-center justify-between rounded-lg cursor-pointer soft-hover"
                  onClick={async () => {
                    handleProfileModal();
                    await refetch();
                  }}
                >
                  <div className="flex items-center px-2 py-4 cursor-pointer">
                    <img
                      src={organizationData?.data?.logo}
                      alt={organizationData?.data?.name}
                      onError={(
                        event: React.SyntheticEvent<HTMLImageElement>
                      ) => {
                        const imgElement = event.target as HTMLImageElement;
                        imgElement.src = cravety; // Set the fallback image source when there's an error
                      }}
                      className="h-6 ml-2"
                    />
                    <p className="flex-1 ml-3 text-sm font-normal cursor-pointer text-textDark dark:text-white">
                      {organizationData?.data?.name === "FedEx"
                        ? "Federal Express Corporation"
                        : organizationData?.data?.name}
                    </p>
                  </div>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 m-3 cursor-pointer"
                    viewBox="0 0 3.879 7.715"
                  >
                    <path
                      id="icon_expand"
                      d="M16.939,13.915,15,11.975l.474-.474,1.465,1.465L18.4,11.5l.474.474Zm-1.465-5.3L15,8.139,16.939,6.2l1.939,1.939-.474.474L16.939,7.148Z"
                      transform="translate(-15 -6.2)"
                      fill="#9eaac3"
                    />
                  </svg>
                </div>

                <hr className="h-px border-1 border-borderGray dark:border-textDark" />
              </li>
              <li className="mt-5 mb-1 ml-7">
                <span className="flex-1 text-sm whitespace-nowrap text-textGray dark:text-textGray">
                  <Balancer>Menu</Balancer>
                </span>
              </li>

              {[
                // {
                //   id:0,
                //   name:"Dashboard",
                //   link: "/dashboard/",
                //   svg: <DashboardIcon />
                // },
                {
                  id: 1,
                  name: "Workers",
                  svg: <WorkerIcon />,
                  link: "/workers/",
                },
                {
                  id: 2,
                  name: "Pathways",
                  svg: <PathwayIcon />,
                  link: "/pathways/",
                },
                {
                  id: 3,
                  name: "Content",
                  svg: <ContentIcon />,
                  link: "/content-templates/",
                },
                {
                  id: 4,
                  name: "Actions",
                  svg: <ActionsIcons />,
                  link: "/action-templates/",
                },
                {
                  id: 5,
                  name: "Rules",
                  svg: <RulesIcons />,
                  link: "/conditions/",
                },
                {
                  id: 6,
                  name: "Analytics",
                  svg: <AnalyticsIcons />,
                  link: "/analytics/",
                },
                
              ].map((item) => {
                return (
                  <SideBarItem
                    link={item.link}
                    name={item.name}
                    svg={item.svg}
                    key={item.id}
                    onClick={() => tab(item.name, item.link)}
                  />
                );
              })}
            </ul>
            <DragDropContext onDragEnd={onDragEndWorkers}>
              <ul className="pt-1 mt-10 mb-10">
                <li>
                  <hr className="h-px border-1 border-borderGray dark:border-textDark" />
                </li>
                <li className="mt-3 mb-1 ml-7">
                  <span className="flex-1 ml-2 text-sm whitespace-nowrap text-grayColor dark:text-textGray">
                    <Balancer>Worker Favorites</Balancer>
                  </span>
                </li>

                {isLoadingFavorites ? (
                  <li className="flex items-center justify-center">
                    <AppLoader isLoading size={25} />
                  </li>
                ) : (
                  workerFavorites.map((item, index) => (
                    <Droppable droppableId={item.id.toString()} key={item.id}>
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <Draggable
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex items-center group"
                              >
                                <span className="w-5">
                                  <ReorderIcon className="hidden group-hover:block" />
                                </span>

                                <SideBarFavItem
                                  link={`/workers/${item.worker_id}`}
                                  onClickHandler={() => {
                                    const link = `/workers/${item.worker_id}`;
                                    const tabTitle = `${item.name} - ${item.title}`;
                                    tab(tabTitle, link);
                                    navigate(link);
                                  }}
                                  name={item.name}
                                  svg={
                                    <WorkerIconFav
                                      link={`/workers/${item.worker_id}`}
                                    />
                                  }
                                />
                                <div
                                  className="hidden mr-1 group-hover:block"
                                  onClick={() => {
                                    handleDelete(item.slug);
                                  }}
                                >
                                  <RemoveSidebarIcon />
                                </div>
                              </div>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </li>
                      )}
                    </Droppable>
                  ))
                )}
              </ul>
            </DragDropContext>


            <DragDropContext onDragEnd={onDragEndContent}>
              <ul className="pt-1 mt-10 mb-10">
                <li>
                  <hr className="h-px border-1 border-borderGray dark:border-textDark" />
                </li>
                <li className="mt-3 mb-1 ml-7">
                  <span className="flex-1 ml-2 text-sm whitespace-nowrap text-grayColor dark:text-textGray">
                    <Balancer>Content Favorites</Balancer>
                  </span>
                </li>
                {isLoadingFavorites ? (
                  <li className="flex items-center justify-center">
                    <AppLoader isLoading size={25} />
                  </li>
                ) : (
                  contentFavorites.map((item, index) => (
                    <Droppable droppableId={item.id.toString()} key={item.id}>
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <Draggable
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex items-center group"
                              >
                                <span className="w-5">
                                  <ReorderIcon className="hidden group-hover:block" />
                                </span>
                                <SideBarFavItem
                                  link={`/content-templates/${item.name}/view/${item.content_template_id}`}
                                  onClickHandler={() => {
                                    const link = `/content-templates/${item.name}/view/${item.content_template_id}`;
                                    const tabTitle = `${item.name} - ${item.title}`;
                                    tab(tabTitle, link);
                                    navigate(link);
                                  }}
                                  name={item.title}
                                  svg={
                                    <ContentIconFav
                                      link={`/content-templates/${item.name}/view/${item.content_template_id}`}
                                    />
                                  }
                                />
                                <div
                                  className="hidden mr-1 group-hover:block"
                                  onClick={() => {
                                    handleDelete(item.slug);
                                  }}
                                >
                                  <RemoveSidebarIcon />
                                </div>
                              </div>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </li>
                      )}
                    </Droppable>
                  ))
                )}
              </ul>
            </DragDropContext>

            <DragDropContext onDragEnd={onDragEndActions}>
              <ul className="pt-1 mt-10 mb-10">
                <li>
                  <hr className="h-px border-1 border-borderGray dark:border-textDark" />
                </li>
                <li className="mt-3 mb-1 ml-7">
                  <span className="flex-1 ml-2 text-sm whitespace-nowrap text-grayColor dark:text-textGray">
                    <Balancer>Action Favorites</Balancer>
                  </span>
                </li>
                {isLoadingFavorites ? (
                  <li className="flex items-center justify-center">
                    <AppLoader isLoading size={25} />
                  </li>
                ) : (
                  actionFavorites.map((item, index) => (
                    <Droppable droppableId={item.id.toString()} key={item.id}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <Draggable
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex items-center group"
                              >
                                <span className="w-5">
                                  <ReorderIcon className="hidden group-hover:block" />
                                </span>
                                <SideBarFavItem
                                  link={`/action-templates/${item.name}/view/${item.action_template_id}`}
                                  onClickHandler={() => {
                                    const link = `/action-templates/${item.name}/view/${item.action_template_id}`;
                                    const tabTitle = `${item.name} - ${item.title}`;
                                    tab(tabTitle, link);
                                    navigate(link);
                                  }}
                                  name={item.title}
                                  svg={
                                    <ActionsIconsFav
                                      link={`/action-templates/${item.name}/view/${item.action_template_id}`}
                                    />
                                  }
                                />
                                <div
                                  className="hidden mr-1 group-hover:block"
                                  onClick={() => {
                                    handleDelete(item.slug);
                                  }}
                                >
                                  <RemoveSidebarIcon />
                                </div>
                              </div>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ))
                )}
              </ul>
            </DragDropContext>




          </div>

          <div>
            {userMenu ? (
              <div
                className="p-2 mb-1 transition-all delay-100 rounded-md bg-primary"
                ref={userMenuRef}
              >
                <div className="flex flex-col p-1">
                  <p
                    className="flex items-center justify-start gap-1 mb-1 text-sm transition-all delay-100 cursor-pointer text-textDark hover:text-blueColor"
                    onClick={logoutHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                      />
                    </svg>
                    Logout
                  </p>
                  <div className="flex items-center justify-start gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
                      />
                    </svg>

                    {/* <span className="mr-2 text-sm text-textDark">Language</span> */}
                    <DropdownLanguage CurrentLang={i18n.language} />
                  </div>
                </div>
              </div>
            ) : null}
            <ul className="self-end">
              <li>
                <hr className="h-px border-1 border-borderGray dark:border-textDark" />
                <div
                  className="flex items-center justify-between rounded-lg cursor-pointer soft-hover"
                  onClick={() => setUserMenu(!userMenu)}
                >
                  <div
                    className={
                      "flex items-center cursor-pointer px-2 py-4" +
                      (userMenu ? " text-blueColor" : "")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>

                    <p className="flex-1 ml-3 text-sm font-normal cursor-pointer text-textDark dark:text-white">
                      {currentUser.user?.name}
                    </p>
                  </div>
                  <svg
                    onClick={() => setUserMenu(!userMenu)}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    className={
                      " m-3 h-3 cursor-pointer" +
                      (userMenu ? " text-blueColor" : "")
                    }
                    viewBox="0 0 3.879 7.715"
                  >
                    <path
                      id="icon_expand"
                      d="M16.939,13.915,15,11.975l.474-.474,1.465,1.465L18.4,11.5l.474.474Zm-1.465-5.3L15,8.139,16.939,6.2l1.939,1.939-.474.474L16.939,7.148Z"
                      transform="translate(-15 -6.2)"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </aside>
      <OverlayModal onClose={handleProfileModal} isOpen={profileModal}>
        <Index modalHandle={handleProfileModal} />
      </OverlayModal>
    </Fragment>
  );
};
