import React, { ChangeEvent, FC, FormEvent, useContext, useRef, useState, useEffect, Fragment, useMemo } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { LanguagesOptionsList, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery } from "react-query";
import { deleteContentTemplateById, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { AnnouncementContentResponse, AnnouncementContentTemplate, IAnnouncement, IAnnouncementContentTemplate, initialAnnouncementContent } from '../../../../Types/ContentTemplate/Announcement';
import UseUploadFile from '../../../../Hook/UseUploadFile';
import UseUpload from '../../../../Hook/UseUpload';
import { baseURL } from '../../../../apiQuery';
import UploadProgress from '../../../General/UploadProgress';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import { useNavigate } from 'react-router-dom';
import WYSIWYGEditor from '../../../General/Form/WYSIWYGEditor';
import { t } from "i18next"

export type Language = "en" | "fr" | "es"


// export type AnnouncementContentTemplate = {
// 	announcement: IAnnouncement
// 	content_metadata: IContentMetadata
// }

interface AnnouncementContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	announcement: IAnnouncement[],
	content_metadata: IContentMetadata[]
}

interface AnnouncementTabContentProps {
	templateId: number,
}

const AnnouncementTabContent: FC<AnnouncementTabContentProps> = (props) => {
	const navigate = useNavigate();
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);


	// ========================= updoad cover image =========================
	const [imageSrc, file, render, name] = UseUploadFile('Announcement')
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);


	const [uploadState, setUploadState] = useState({
		file,
		name,
	})


	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])

	//=========================================================================

	const { currentOrganizationID } = useContext(UserContext);

	const [state, setState] = useState<AnnouncementContentTemplate[]>([])


	const [announcementContent, setAnnouncementContent] = useState<AnnouncementContentTemplate>(initialAnnouncementContent)


	// const [_, setCurrentTemplateId] = useState<number>(0);

	const createOrUpdateButton = useRef<string>("Create")
	// const allAnnouncementContentsRef = useRef(null)

	// mutations
	// =====================================================================
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')

	const { data: announcementData, refetch } = useQuery<AnnouncementContentResponse, Error>
		(`announcement-content-${props.templateId}`, () => getContentTemplateById(props.templateId));

	useEffect(() => {
		if (announcementData?.data) {
			editableAnnouncementContent(announcementData?.data)
		}
	}, [announcementData?.data])

	const deleteTemplateFromContentTemplateList = async (language: string) => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/announcement')
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.announcement.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}


	//=====================================================
	//make english default language for content

	//make english default language for content template
	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setAnnouncementContent({
				announcement: announcementData?.data.announcement.find((item: IAnnouncement) => item.language === LanguageEnum.English) as IAnnouncement,
				content_metadata: announcementData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			createOrUpdateButton.current = "Update"
		}
	}, [announcementData?.data.announcement, announcementData?.data.content_metadata])

	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.announcement.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges

	}, [state])

	// =====================================================================

	const updateAnnouncementContentMutation = useMutation(
		(params: { id: number, data: AnnouncementContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Content updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
	//=====================================================
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	// handlers
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setAnnouncementContent(prev => {
			return {
				...prev,
				announcement: {
					...prev.announcement,
					[name]: value
				}
			}
		})
	}

	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (announcementContent.announcement.title === '') {
			return
		}

		if (createOrUpdateButton.current === "Create") {

			// Todo : check if the language is already exist
			if (state.some(item => item.announcement.language === announcementContent.announcement.language)) {
				toast('This article is already exist', 'error')
				return
			}

			let _cover_imageLink = ''

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				_cover_imageLink = link
			}

			const announcementContentWithImage: AnnouncementContentTemplate = {
				announcement: announcementContent.announcement,
				content_metadata: {
					...announcementContent.content_metadata,
					cover_image: _cover_imageLink,
					language: announcementContent.announcement.language
				}
			}

			// setState(prev => [...prev, announcementContentWithImage])
			const newState = [...state, announcementContentWithImage]
			await createOrUpdateContentTemplate(newState)

		} else {
			// =====================  Update =====================
			let cover_image = announcementContent?.content_metadata?.cover_image || ''

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}
			// update the state
			const newState = state.map(item => {
				if (item.announcement.language === announcementContent.announcement.language) {
					return {
						announcement: announcementContent.announcement,
						content_metadata: {
							...announcementContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})
			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = "Create"

		}

		// 	empty the fields
		setAnnouncementContent(initialAnnouncementContent)

		// reset fields
		setUploadState({
			file: undefined,
			name: '',
		})
		setSelectedLanguage('')
	}

	const createOrUpdateContentTemplate = async (data: AnnouncementContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one announcement template', 'info')
			return
		}
		const shapeData: AnnouncementContentCreateOrUpdateRequest = {
			content_type: EnumContentType.AnnouncementContent,
			announcement: data.map(item => {
				return {
					...item.announcement,
					title: item.announcement.title,
					description: item.announcement.description,
					body: item.announcement.body,
					language: item.announcement.language as LanguageEnum,
				}
			}),
			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.announcement.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}
		await updateAnnouncementContentMutation.mutateAsync({ id: props.templateId, data: shapeData })
		setAnnouncementContent(initialAnnouncementContent)
		setSelectedLanguage('')
	}


	const editableAnnouncementContent = (announcementContentTemplate: IAnnouncementContentTemplate) => {
		const combinedData: any[] = [];
		announcementContentTemplate.announcement.forEach((announcement: any) => {
			// Loop through each content metadata
			announcementContentTemplate.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (announcement.language === metadata.language) {
					// Combine the properties of the announcement and metadata objects
					const combinedObj = {
						announcement: announcement,
						content_metadata: metadata
					};
					// Add the combined object to the final array
					combinedData.push(combinedObj);
				}
			});
		});
		// setCurrentTemplateId(announcementContentTemplate.id)
		setState(combinedData)
	}

	return (
		<Fragment>
			<ViewContentTemplateHeader
				title={announcementData?.data.announcement[0].title || ''}
				icon={<svg
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 7.573 8.414"
					className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
				>
					<path
						id="icon_pathways"
						d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
						transform="translate(-6 -4)"
						fill="currentColor"
					/>
				</svg>}
				referencesCount={announcementData?.data.linked_content_count || 0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{
					// props.data[props.data.content_type]
					announcementData?.data.announcement.sort((a, b) => {
						// Define a language order
						const languageOrder = { en: 0, es: 1, fr: 2 };

						// Get the language order for each object
						const orderA = languageOrder[a.language] || Infinity;
						const orderB = languageOrder[b.language] || Infinity;

						// Compare the language order
						return orderB - orderA;
					}).map((item: IAnnouncement, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setAnnouncementContent(initialAnnouncementContent)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										// editableAnnouncementContent(announcementData.data)
										createOrUpdateButton.current = "Update"
										currentLanguage.current = item.language as LanguageEnum
										setAnnouncementContent({
											announcement: announcementData.data.announcement.find((announcement) => announcement.language === item.language) as IAnnouncement,
											content_metadata: announcementData.data.content_metadata.find((metadata: IContentMetadata) => metadata.language === item.language) as IContentMetadata
										})
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					state.length < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setAnnouncementContent(initialAnnouncementContent)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								{t('new_language')}
							</span>

						</AppButton>
					) : null
				}
			</div>


			<TabContentLayout>
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{announcementContent?.announcement?.title || ""}</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">

					<div className='flex w-full gap-2 mb-3'>
						<AppButton size="xs" disabled={announcementContent?.announcement?.title === '' || updateAnnouncementContentMutation.isLoading || deleteContentTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Content Template</AppButton>
						<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => deleteTemplateFromContentTemplateList(selectedLanguage)}>Delete Content Template</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>
					</div>
					{/* ======================== */}

					<AppInput autoFocus placeholder={"Title"} value={announcementContent?.announcement?.title || ""} name='title' isFull onChange={handleChange} label='Title' required />
					<AppTextArea placeholder={"Description"} rows={4} value={announcementContent?.announcement?.description || ''} name='description' onChange={handleChange}
						label='Description' />
					<WYSIWYGEditor onChange={
						(e) => {
							setAnnouncementContent(prev => {
								return { ...prev, announcement: { ...prev.announcement, body: e } }
							})
						}} value={announcementContent?.announcement?.body || ""} placeholder={"Body"}></WYSIWYGEditor>

					{/* <AppInput placeholder={"Alternative Title"} value={announcementContent?.content_metadata?.alt_title || ''} name='alt_title' isFull
						onChange={e => {
							setAnnouncementContent({
								...announcementContent,
								content_metadata: {
									...announcementContent.content_metadata,
									alt_title: e.target.value
								}
							})
						}} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

					<AppInput placeholder={"Preview Title"} value={announcementContent?.content_metadata?.preview_title || ''} name='preview_title' isFull

						onChange={e => {
							const preview_title = e.target.value
							setAnnouncementContent({ ...announcementContent, content_metadata: { ...announcementContent.content_metadata, preview_title } })
						}} label='Preview Title' extendClass='my-2' maxLength={140} required />
					<AppInput
						placeholder={"Tag"}
						value={announcementContent?.content_metadata?.tags?.[0] || ""}
						name="tags"
						isFull
						onChange={(e) => {
							const tags = [e.target.value];
							setAnnouncementContent({
								...announcementContent,
								content_metadata: {
									...announcementContent.content_metadata,
									tags,
								},
							});
						}}
						label="Tag"
						extendClass="my-2"
					/>

					{/* {announcementContent?.content_metadata?.cover_image ? <img alt="conver_image" src={announcementContent.content_metadata.cover_image} className='my-2 rounded-sm' /> : null} */}
					{announcementContent?.content_metadata?.cover_image ? <div className='cover__image-container'>
						<img alt="conver_image" src={announcementContent.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
					</div> : null}
					{render}
					{
						uploadState.name !== undefined ? <p>{uploadState.name}</p> : null
					}

					{
						isLoading ? <UploadProgress progress={progress} /> : null
					}

					{/* <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"} options={LanguagesOptions} value={announcementContent?.announcement?.language || ""} name="language" onChange={handleChange} /> */}
					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={announcementContent?.announcement?.language || ""} name="language" onChange={handleChange} />
					}
				</form>

			</TabContentLayout>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</Fragment>
	)
}


export default AnnouncementTabContent
