import API from "../index";


export const getAllNotifications = async (limit:number = 100) => {
    const { data } = await API.get(`/admin/notification/?limit=${limit}`)
    return data
}

export const getAllUserUnviewedNotification = async (limit:number = 25) => {
    const { data } = await API.get(`/admin/notification/admin/unviewed?limit=${limit}`)
    return data
}

export const getAllUserNotification = async (limit:number = 100) => {
    const { data } = await API.get(`/admin/notification/admin?limit=${limit}`)
    return data
}

export const setUserNotificationsAsViewed = async (notification_ids: number[]) => {
    const {data} = await API.post(`/admin/notification/admin/viewed`, {
        notification_ids
    })
    return data
}

export const setAllUserNotificationAsViewed = async () => { 
    const {data} = await API.post(`/admin/notification/admin/all_viewed`)
    return data
}