export const SaveIcon = (props: { className?: string }) => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 96 960 960"
        className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
      >
        <path
          fill="currentColor"
          d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
      </svg>
    )
}