import React, {FC} from 'react';
import {ContCommon} from "../../../Common/Types";

const ArticlePage: FC<ContCommon> = (prop) => {

    return (
        <div className="whitespace-pre-wrap bg-white p-4 text-black rounded-b-md">
            <div dangerouslySetInnerHTML={{ __html: prop.body || '' }} />
        </div>


    );
};

export default ArticlePage;