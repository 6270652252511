import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageHeader from "../../Layouts/PageHeader";
import Button from "../../General/Button";
import Input from "../../General/Form/Input";
import TextArea from "../../General/Form/TextArea";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import {
    ContentTemplates_T,
    StateTemplate_R,
    StateTemplates_T,
    stateUI,
    update_StateTemplates_T
} from "../../../Common/Types";
import {
    createStateTemplates,
    getSingleStateTemplate,
    updateStateTemplate
} from "../../../apiQuery/State/Template.apis";
import UseTab from "../../../Hook/UseTab";
import Tabs from "../../Tabs/Tabs";
import Tab from "../../Tabs/Tab";
import { Fade } from "@mui/material";
import OverlayModal from "../../Layouts/OverlayModal";
import Content from "../../Content/ContentCreate/NewContentModal";
import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import Search from "../../General/Form/Search";
import CardUi from "../../List/CardUI";
import { store } from "../../../GlobalState/store";
// import { setSnackbar } from "../../../GlobalState/store.actions";
import { withAuth } from '../../../hoc/withAuth';
import { toast } from '../../../utils/Toast';

const NewStateTemplate = () => {

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const params = useParams();
    const id = params.stateTempId

    // const {globalState, dispatch} = useContext(globalContext);
    const {
        state: globalState,
        dispatch
    } = useContext(store);
    const [tab, setTab] = UseTab('Details')

    const organization_id = useMemo(() => globalState.organization_id, [globalState.organization_id])
    const [modal, setModal] = useState(false)

    const [state, setState] = useState<stateUI>({
        contents: [],
        actions: []
    })

    const [removeContent, setRemovedContent] = useState<number[]>([])
    const [contentTemp, setContentTemp] = useState<ContentTemplates_T[]>([])

    const [search, setSearch] = useState('')

    const handleNewContent = useCallback(() => setModal(prev => !prev), [])

    const {
        isLoading,
        isError,
        status,
        data,
        error,
        refetch,
        isFetching
    } = useQuery<StateTemplate_R, Error>(['state-template', id], () => getSingleStateTemplate(organization_id, id ? parseInt(id) : 0), {
        enabled: !!id
    })

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<StateTemplates_T>();
    const onSubmit: SubmitHandler<StateTemplates_T> = data => {

        let typeOfMutate
        if (id) typeOfMutate = editStateTemplate
        else typeOfMutate = addStateTemplate

        // @ts-ignore
        typeOfMutate.mutate({
            ...data,
            content_templates: [...state.contents.map((item) => {
                const content_template_type = item.content_type
                return {
                    content_template_type,
                    owner_type: "organizations",
                    owner_id: organization_id,
                    video: item.video,
                    article: item.article,
                    checklist: item.checklist,
                    direction: item.direction,
                    selector: item.selector,
                    picture: item.picture,
                    announcement: item.announcement,
                    faq: item.faq,
                    survey: item.survey
                }
            }), ...contentTemp.map(item => item)]
        });
    }

    const addStateTemplate = useMutation((newStateTemplate: StateTemplates_T) => createStateTemplates(globalState.organization_id, {
        ...newStateTemplate,
        // organization_id
    }), {
        onSuccess: async () => {
            // dispatch(setSnackbar({isOpen: true, type: 'success', message: 'Successfully created new state Template'}))
            toast('Successfully created new state Template', 'success')

            await queryClient.invalidateQueries('state-templates')
            await queryClient.invalidateQueries('available-state-template')
            navigate('/state-templates')
        }
    })

    const editStateTemplate = useMutation((newStateTemplate: update_StateTemplates_T) => updateStateTemplate(globalState.organization_id, {
        name: newStateTemplate.name,
        description: newStateTemplate.description,
        content_templates: [...newStateTemplate.content_templates, ...(data?.data?.content_templates?.filter(item => !removeContent.includes(item?.id || -1)) || []).map((item) => item)]
    }, id ? id : ''), {
        onSuccess: async () => {
            await queryClient.invalidateQueries('state-templates')
            await queryClient.invalidateQueries(['state-template', id])
            if (data?.data?.connected_flows?.length) {
                await Promise.all(data?.data?.connected_flows?.map(async (item) => {
                    await queryClient.invalidateQueries(['individual-state-flow', item?.ID?.toString()])
                }))
            }
            navigate('/state-templates')
        }
    })

    const handleRemoveStateContent = useCallback((ind: number) => setState(prev => ({
        ...prev,
        contents: prev.contents.filter((_, index) => ind !== index)
    })), [])

    useEffect(() => {
        if (id && data) {
            setValue("name", data.data.name)
            setValue("description", data.data.description)
        }
        // if (id && data && data.content_templates?.length) {
        //     setState({
        //         ...state,
        //         contents: data?.content_templates?.map((item) => {
        //             return {
        //                 ID: item.ID,
        //                 content_type: item.content_template_type,
        //                 article: item.article,
        //                 video: item.video,
        //                 checklist: item.checklist,
        //                 direction: item.direction
        //             }
        //         })
        //     })
        // }
    }, [data])

    const optionsOfStateContent = useMemo(() => [
        {
            icon: <AiFillDelete />,
            text: 'Remove Content',
            onClick: (ID: number) => handleRemoveStateContent(ID)
        }
    ], [])

    const optionsOfDataContent = useMemo(() => [
        {
            icon: <AiFillDelete />,
            text: 'Remove Content',
            onClick: (ID: number) => setRemovedContent(prev => [...prev, ID])
        }
    ], [])

    const optionsOfTemplateContent = useMemo(() => [
        {
            icon: <AiFillDelete />,
            text: 'Remove Content',
            onClick: (ID: number) => setContentTemp(prev => prev?.filter(item => item.id !== ID))
        }
    ], [])

    const RenderPage = () => <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
            title={`${id ? 'Edit' : 'Create'} State Template`}
            tabs={
                <Tabs selectedTab={tab} setSelectedTab={setTab}>
                    <Tab title='Details'>Details</Tab>
                    <Tab title="Content">Content</Tab>
                </Tabs>
            }
            button={<Button disabled={addStateTemplate.isLoading || editStateTemplate.isLoading} type="submit"
                label="Save Changes"
                extraClasses="w-fit px-3 py-3 bg-blue-400 text-white" />}
        />
        <div className="w-11/12 py-5 m-auto text-gray-600 text-md">
            <Fade in={tab === "Details"} style={{ display: tab === "Details" ? '' : 'none' }}>
                <div className="space-y-5">
                    <Input
                        register={() => register("name", { required: 'name is required' })}
                        name="name"
                        placeholder="State Template Title"
                        errors={errors}
                        extraClasses="w-2/5" />
                    <TextArea
                        register={() => register("description")}
                        name="description"
                        placeholder="State Template Description"
                        errors={errors}
                        extraClasses="w-2/5" />
                </div>
            </Fade>

            <Fade in={tab === "Content"} style={{ display: tab === "Content" ? '' : 'none' }}>
                <div className="space-y-8">
                    <div className="flex items-center w-full h-12 space-x-2">

                        <Button icon={<AiOutlinePlusCircle />} onClick={handleNewContent}
                            label={<p>Add Content</p>}
                            extraClasses="bg-blue-600 w-1/6 text-white h-12" />

                        <Search search={search} onSearch={(e) => setSearch(e.target.value)}
                            placeholder={"Search Contents"} />
                    </div>
                    <div className="grid grid-cols-2 gap-5">
                        {
                            state?.contents?.map((item, index) => {
                                const title = item.video?.title || item.article?.title || item.checklist?.title || item.direction?.title || item.selector?.title || item?.picture?.title || item.announcement?.title || item.faq?.title || item.survey?.title || ""
                                if (!title.toLowerCase().includes(search.toLowerCase())) return
                                return <CardUi key={index} title={title} willTemplateLinked={true}
                                    dotOptionElm={optionsOfStateContent}
                                    type={item.content_type}
                                    id={index} />
                            })
                        }

                        {
                            data?.data?.content_templates?.map((item, index) => {
                                const title = item.video?.title || item.article?.title || item.checklist?.title || item.direction?.title || item.selector?.title || item?.picture?.title || item.announcement?.title || item.faq?.title || item.survey?.title || ""
                                if (!title.toLowerCase().includes(search.toLowerCase())) return
                                if (item.id && removeContent.includes(item.id)) return
                                return <CardUi key={index} id={item.id}
                                    dotOptionElm={optionsOfDataContent}
                                    type={item.content_template_type}
                                    title={title} />
                            })
                        }

                        {
                            contentTemp.map((item, index) => {
                                const title = item.video?.title || item.article?.title || item.checklist?.title || item.direction?.title || item.selector?.title || item?.picture?.title || item.announcement?.title || item.faq?.title || item.survey?.title || ""
                                if (!title.toLowerCase().includes(search.toLowerCase())) return
                                return <CardUi key={index} id={item.id} dotOptionElm={optionsOfTemplateContent}
                                    type={item.content_template_type} title={title}
                                    isTemplate={true} />
                            })
                        }
                    </div>
                </div>
            </Fade>
        </div>
    </form>

    return (
        <>{
            id ?
                isLoading ? (
                    "Loading..."
                ) :
                    isError ? (
                        <span>Error: {error?.message || "Something went wrong!"}</span>
                    ) :
                        data ? RenderPage() : null
                : RenderPage()
        }

            <OverlayModal onClose={handleNewContent} isOpen={modal}>
                <Content onClose={handleNewContent} onSuccess={() => { }} setState={setState} setContentTemp={setContentTemp}
                    contentTemp={contentTemp} />
            </OverlayModal>

        </>
    );
};

export default withAuth(NewStateTemplate);
