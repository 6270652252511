import React, { memo, useCallback, useContext, useMemo } from 'react';
import ArticleNew from "../ContentCreate/ArticleNew";
import { MdClose } from "react-icons/md";
import { article, num, Single_Content_R, State, video } from "../../../Common/Types";
import VideoNew from "../ContentCreate/VideoNew";
import ChecklistNew from "../ContentCreate/ChecklistNew";
import DirectionNew from "../ContentCreate/DirectionNew";
import SelectorNew from "../ContentCreate/SelectorNew";
import AnnouncementContent from "../ContentCreate/AnnouncementNew";
import FAQNew from "../ContentCreate/FAQNew";
import { store } from "../../../GlobalState/store";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getContent } from "../../../apiQuery/Contents/Content.apis";
import ConsentNew from "../ContentCreate/ConsentNew";
import SurveyNew from "../ContentCreate/SurveyNew";
import SurveySequence from "../ContentCreate/Survey_Sequence";
// import { IContentRule } from '../../../Types/ContentRule';

type props = {
    modalHandle: () => void,
    content_slug: string,
    onSuccess: () => void
}
const EditContentModal = memo(({
    modalHandle,
    content_slug,
    onSuccess
}: props) => {

    const {
        state: globalState,
    } = useContext(store);
    // const organization_id = useMemo(() => globalState.organization_id, [globalState.organization_id])
    const params = useParams();
    const id = params.stateSlug || ""

    const {
        isLoading,
        isError,
        status,
        data,
        error,
        refetch,
        isFetching
    } = useQuery<Single_Content_R, Error>(['content', content_slug], () => getContent(globalState.currentFlow.slug, id, content_slug), {
        enabled: !!content_slug
    })

    return (
        <div
            className="relative flex w-2/3 h-full p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md modal">
            <div className="w-full">
                <div className="flex items-center justify-between w-full pb-4 text-lg">
                    <p className="text-2xl ">Edit Content</p>
                    <div onClick={modalHandle} className="p-1 bg-gray-400 rounded-full cursor-pointer">
                        <MdClose className="text-white" />
                    </div>
                </div>

                {
                    data ?
                        <>
                            {(() => {
                                switch (data?.data?.content?.content_type) {
                                    // || data?.data?.content_template_type) {
                                    case 'article':
                                        return <ArticleNew type={'update'}
                                            onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            onClose={modalHandle} />
                                    case 'video':
                                        return <VideoNew type={'update'} onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            onClose={modalHandle} />
                                    case 'checklist':
                                        return <ChecklistNew type={'update'} onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            onClose={modalHandle} />
                                    case 'direction':
                                        return <DirectionNew type={'update'} onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            onClose={modalHandle} />
                                    case 'selector':
                                        return <SelectorNew type={'update'} onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            onClose={modalHandle} />
                                    case 'announcement':
                                        return <AnnouncementContent
                                            type={'update'}
                                            onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            // contentRule={data?.data.rule as IContentRule}
                                            onClose={modalHandle} />
                                    case 'consent':
                                        return <ConsentNew type={'update'} onSuccess={onSuccess}
                                            defaultValue={data?.data?.content} onClose={modalHandle} />

                                    case 'faq':
                                        return <FAQNew type={'update'}
                                            onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            onClose={modalHandle} />
                                    case 'survey':
                                        return <SurveyNew type={'update'}
                                            onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            onClose={modalHandle} />

                                    case 'survey_sequence':
                                        return <SurveySequence type={'update'}
                                            onSuccess={onSuccess}
                                            defaultValue={data?.data?.content}
                                            onClose={modalHandle} />

                                    // if (data?.data?.content?.survey?.is_2_value) {
                                    //     return <Survey2ValueNew type={'update'}
                                    //                             onSuccess={onSuccess}
                                    //                             defaultValue={data?.data?.content}
                                    //                             onClose={modalHandle}/>
                                    // } else {
                                    //     return <Survey10ValueNew type={'update'}
                                    //                              onSuccess={onSuccess}
                                    //                              defaultValue={data?.data?.content}
                                    //                              onClose={modalHandle}/>
                                    // }

                                    default:
                                        return null
                                }
                            })()}
                        </> : null
                }


            </div>
        </div>
    );
});

export default EditContentModal;