import { FC, useContext, useReducer } from "react";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import { toast } from "../../../../utils/Toast";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { createActionTemplate } from "../../../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { EnumActionType, IActionMetadata } from "../../../../Types/ActionTemplate/ActionTemplate";
import { TabActionLayout } from "../TabActionLayout";
import { WS1NotificationActionCreateOrUpdateRequest, WS1NotificationActionTemplate, initialWS1NotificationContent } from "../../../../Types/ActionTemplate/WS1Notification";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";



export type NotificationActionCreateOrUpdateRequest = {
    action_type: EnumActionType.Ws1notificationAction,
    action_metadata: IActionMetadata[],
    importance: number
}


const NewWS1Notification: FC = () => {
    const params = useParams<{ actionType: string }>()
    const navigate = useNavigate();

    const { currentOrganizationID } = useContext(UserContext);

    const [formData, updateFormData] = useReducer((prev: WS1NotificationActionTemplate, next: Partial<WS1NotificationActionTemplate>) => {
        return { ...prev, ...next }
    }, initialWS1NotificationContent)

    // ============================ Mutation ================================

    const createWS1NotificationActionMutation = useMutation((param: NotificationActionCreateOrUpdateRequest) => createActionTemplate(param), {
        onSuccess: async (data) => {
            toast("Content created successfully", "success")
            const id = data?.data?.id
            if (id) {
                navigate(`/action-templates/${params.actionType}/view/${id}`)
            }
        },
        onError: (error: any) => {
            const message = error?.response?.data?.message || "Something went wrong!"
            toast(message, "error")
        }
    })

    // ======================  Submit Article  ========================
    const submitFormHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (formData.action_metadata.title === '') {
            toast('Please enter a title', 'error')
            return
        }


        const ws1NotificationAction: WS1NotificationActionTemplate = {
            notification: formData.notification,
            action_metadata: {
                ...formData.action_metadata,
                language: formData.notification.language
            },
            importance: formData.importance
        }
        const newState = [ws1NotificationAction]

        const data = [newState]

        const action_metadata: IActionMetadata[] = data.map((item) => {
            return {
                id: 0,
                organization_id: currentOrganizationID(),
                language: LanguageEnum.English,
                title: item[0].action_metadata.title,
                subtitle: item[0].action_metadata.subtitle,
                description: item[0].action_metadata.description
            }
        })
        const shapeData: NotificationActionCreateOrUpdateRequest = {
            action_type: EnumActionType.Ws1notificationAction,
            action_metadata: action_metadata,
            importance: formData.importance
        }
        await createWS1NotificationActionMutation.mutateAsync(shapeData)



        // const shapeData: WS1NotificationActionCreateOrUpdateRequest = {
        //     action_type: EnumActionType.Ws1notificationAction,
        //     notification: newState.map(item => {
        //         return {
        //             language: item.notification.language as LanguageEnum,
        //         }
        //     }),
        //     action_metadata: newState.map(item => {
        //         return {
        //             organization_id: currentOrganizationID(),
        //             language: item.action_metadata.language as LanguageEnum,
        //             title: item.action_metadata.title,
        //             subtitle: item.action_metadata.subtitle,
        //             description: item.action_metadata.description

        //         }
        //     })
        // }
        // await createWS1NotificationActionMutation.mutateAsync(shapeData)
    }

    return <TabActionLayout>

        <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
            <h1 className="text-xl">Create New Notification Action Template</h1>
            <h1 className="pb-4 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{formData.action_metadata.title || ''}</h1>


            <AppInput tabIndex={-1} autoFocus placeholder={"Title"} value={formData.action_metadata.title} name='subject'
                isFull label='Title' required
                onChange={
                    (e) => updateFormData({ action_metadata: { ...formData.action_metadata, title: e.target.value } })
                }
            />
            <AppInput autoFocus placeholder={"Subtitle"} value={formData.action_metadata.subtitle!} name='body' isFull
                onChange={
                    (e) => updateFormData({ action_metadata: { ...formData.action_metadata, subtitle: e.target.value } })
                }
                label='Subtitle' />
            <AppTextArea placeholder={"description"} value={formData.action_metadata.description!} rows={6} name='body'
                onChange={
                    (e) => updateFormData({ action_metadata: { ...formData.action_metadata, description: e.target.value } })
                }
                label='description' />
            <AppDropDown
                extendClass="mt-1.5"
                isFull
                label=""
                name="time"
                options={[
                    { label: "Standard", value: "0" },
                    { label: "Priority", value: "1" },
                    { label: "Urgent", value: "2" },
                ]}
                value={formData.importance.toString()}
                onChange={
                    (e) => updateFormData({ importance: parseInt(e.target.value, 10) })
                }
            />
            <div className='flex w-full gap-2 mb-3'>
                <AppButton disabled={createWS1NotificationActionMutation.isLoading} variant="primary" type="submit" extendClass="px-3">
                    <div className="flex items-center justify-center gap-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 96 960 960"
                            className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
                        >
                            <path
                                fill="currentColor"
                                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
                        </svg>
                        Create Action Template
                    </div>
                </AppButton>
            </div>
        </form>

    </TabActionLayout>
}

export default NewWS1Notification;