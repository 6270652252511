import API from "../index";
import { ruleBody } from "../../Common/Types";
import { RulesFilter } from "../../Types/Rules/RulesFilter";

export const getRuleOprands = async () => {
    const { data } = await API.get('/admin/rules/operands');
    return data;
};

export const getRuleOperators = async () => {
    const { data } = await API.get('/admin/rules/operators');
    return data;
};

export const createContentRule = async (values: ruleBody) => {
    
    let _temp: any = values
  
    if (values.target_func && values.target_field) {
        _temp.is_scheduled_delay = false;
       
    }

    const { data } = await API.post('/admin/content/rules/', { ..._temp });
    return data;
};

export const createActionRule = async (values: ruleBody) => {
    const { data } = await API.post('/admin/action/rules/', { ...values });
    return data;
};

// this is for state entry
export const createRule = async (values: ruleBody) => {
    const { data } = await API.post('/admin/state/rules/', { ...values });
    return data;
};

export const getAllRule = async (organ?: number, pageParam?:string, rulesFilter?: RulesFilter) => {
     
    const {data} = await API.post(`/admin/state/rules/organizations/filter?limit=15&
        ${pageParam && `&next=${pageParam}`}`, rulesFilter)

 
    return data;
};

export const getAllRuleForContent = async (organ?: number, pageParam?:string, rulesFilter?: RulesFilter) => {
    const { data } = await API.post(`admin/content/rules/organizations/filter?limit=15&${
        pageParam && `&next=${pageParam}`
      }`, rulesFilter);
    return data;
};

export const getAllRuleForAction = async (organ: number, pageParam?:string, rulesFilter?: RulesFilter) => {
    const { data } = await API.post(`admin/action/rules/organizations/filter?limit=15&${
        pageParam && `&next=${pageParam}`
      }`, rulesFilter);
    return data;
};

export const deleteContentCondition = async (rulId: number) => {
    const { data } = await API.delete(`admin/content/rules/${rulId}`);
    return data;
};

export const deleteActionCondition = async (rulId: number) => {
    const { data } = await API.delete(`admin/action/rules/${rulId}`);
    return data;
};

export const deleteStateCondition = async (rulId: number) => {
    const { data } = await API.delete(`admin/state/rules/${rulId}`);
    return data;
};