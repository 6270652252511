import { FC } from "react";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: FC<LayoutProps> = ({children}) => {
  return (
    <div className="w-full py-2 m-auto space-y-2">
      <div className="relative px-4 overflow-x-auto overflow-y-hidden bg-gray-50 sm:rounded-lg">
        {children}
      </div>
    </div>
  );
};
