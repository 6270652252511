import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./GlobalState/store";
import { UserProvider } from "./Context/UserContext/UserContext";
import { TabProvider } from "./Context/TabContext/TabContext";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <UserProvider>
      <AppProvider>
        <TabProvider>
          <App />
        </TabProvider>
      </AppProvider>
    </UserProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
