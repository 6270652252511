import API from "../index";
import { jobProfile } from "../../Common/Types";
import { ILink, IOrganizationSetting } from "../../Types/Organization/Organization";
import { IOrganizationSetting as  IOrganizationSettingsWorker } from './../../Types/Organization/OrganizationSettings'

import axios from "axios";
export const getAllOrganization = async () => {
  const { data } = await API.get("/organizations/");
  return data;
};

export const getOrganization = async (id?: number) => {
  const { data } = await API.get(`/admin/organizations/`);
  return data;
};

export const updateOrganization = async (
  id: number,
  jobProfile: jobProfile
) => {
  // const {data} = await API.put(`/admin/organizations/${id}`,{...jobProfile});
  const { data } = await API.put(`/admin/organizations/`, { ...jobProfile });
  return data;
};

export const updateOrganizationSettingsWorkers = async (data: IOrganizationSettingsWorker) => {
  const response = await API.put(`/admin/organizations/`, data);
  return response.data;
}

// ================== Organization Setting ====================================

export const getOrganizationSetting = async () => {
  const { data } = await API.get(`/admin/organizations/setting`);
  return data;
};

export const setOrganizationSetting = async (data: IOrganizationSetting) => {
  const response = await API.post(`/admin/organizations/setting`, data);
  return response.data;
};

export const updateOrganizationSetting = async (data: IOrganizationSetting) => {
  const response = await API.put(`/admin/organizations/setting`, data);
  return response.data;
};  
export const getAllOrganizationLinks = async () => {
  const { data } = await API.get(`/admin/organizations/link/catalogue/list`);
  return data;
};
export const getSingleOrganizationLinks = async (linkID: number) => {
  const { data } = await API.get(`/admin/organizations/link/catalogue/${linkID}`);
  return data;
};
export const UpdateOrgLink = async (linkID: number,datas: ILink) => {
  const { data } = await API.put(`/admin/organizations/link/catalogue/${linkID}`,{...datas});
  return data;
};

export const CreateOrgLink = async (datas: ILink) => {
  const { data } = await API.post(`/admin/organizations/link/catalogue`,{...datas});
  return data;
};
export const deleteOrgLink = async (id: number): Promise<any> => {
	const response = await API.delete(`/admin/organizations/link/catalogue/${id}`);
	return response.data;
}

export const getAllOrgnizationsIdentifiers = async () => {
  const url = process.env.REACT_APP_API_URL + 'organizations';
  const { data } = await axios.get(url);
  return data;
}