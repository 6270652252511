
import API from "../index";


export const getOneActionRule = async (ruleId: number) => {
    const { data } = await API.get(`/admin/action/rules/${ruleId}`);
    return data;
};

export const updateActionRule = async (ruleId:number,rule:any) => {
    const {data} = await API.put(`/admin/action/rules/${ruleId}`, {...rule});
    return data;
}




interface ICreateTimeCoditionForAction {
    state_id: number
    action_id: number
    deliver_time:number
    deliver_unit:string
    display_unit:string
}
export const createTimeCoditionForAction = async (values: ICreateTimeCoditionForAction) => {
    const {data} = await API.post(`/admin/organizations/conditions/`, values);
    return data;
}