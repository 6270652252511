interface IProps{
    icon?:any
}
export const PathwayFilterIcon = ({icon}:IProps) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={`custom__icon`}
      viewBox={icon==="pathway" ?"0 0 8.582 10": "0 0 36 35.6"} 
    >
   {icon === "pathway" ? 
   (
    <path
    id="icon_pathways"
    d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
    transform="translate(-6 -4)"
    fill="currentColor"
  />
   ):(

    <path
    fill="currentColor"
    d="M24 41.5l-18-14 2.5-1.85L24 37.7l15.5-12.05L42 27.5zm0-7.6l-18-14 18-14 18 14z"
    transform="translate(-6 -5.9)"
  />
   )
   }
    </svg>
  );
};
