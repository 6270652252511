import React, {
	FC,
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import UseTab from "../../Hook/UseTab";
import {
	useMutation,
	useQuery,
	useInfiniteQuery,
	useQueryClient,
} from "react-query";
import {
	deleteState,
	getAllActionInState,
	getAllActionInStateFilter,
	getAllContentsInState,
	getAllContentsInStateFilter,
	getAllParticipantInState,
	getState,
} from "../../apiQuery/State/State.apis";
import {
	Rules_R,
	serachTypes,
	State_Actions_R,
	State_Contents_R,
} from "../../Common/Types";
import Content from "../Content/ContentCreate/NewContentModal";
import OverlayModal from "../Layouts/OverlayModal";
import { deleteContent } from "../../apiQuery/Contents/Content.apis";
import Action from "../Action/ActionCreate/NewActionModal";
import { deleteAction } from "../../apiQuery/Actions/Actions.apis";
import Prompt from "../General/Prompt";
import ViewContentModal from "../Content/ViewContentModal";
import ViewActionModal from "../Action/ViewActionModal";
import EditContentModal from "../Content/ContentEdit/EditContentModal";
import EditActionModal from "../Action/ActionEdit/EditActionModal";
import AddConditionModal from "../Modal/ModalContainer/Condition/AddConditionModal";
import { store } from "../../GlobalState/store";
import UseQuery from "../../Hook/UseQuery";
import { getAllRule } from "../../apiQuery/Condition/Rule.apis";
import moment from "moment";
import useOnClickOutside from "../../Hook/UseOutsideHook";
import { withAuth } from "../../hoc/withAuth";
import { UserContext } from "../../Context/UserContext/UserContext";
import { useTranslation } from "react-i18next";
import { toast } from "../../utils/Toast";
import {
	ActionTemplateIcons,
	ActiveTimerIcon,
	AppButton,
	CloseEyeIcon,
	CommandBar,
	ContentTemplateIcons,
	CustomClipboard,
	DeleteIcon,
	EmailIcon,
	EyeIcon,
	Loader,
	PhoneCallIcon,
	PopupModal,
	THead,
	TabBar,
	Table,
	TransitionAnimation,
} from "../../Theme";
import { Link } from "react-router-dom";
import { EnumContentType } from "../../Types/ContentTemplate/ContentType";
import { EnumActionType } from "../../Types/ActionTemplate/ActionTemplate";
import EditConditionModal from "./Modal/EditConditionModal";
import { TabContext } from "../../Context/TabContext/TabContext";
import AppSearch from "../Shared/AppSearch/AppSearch";
import StateConditionalModal from "./Modal/StateConditionModal";
import _ from "lodash"
import { deliverTimeAndUnitCalculator, ruleNameFormatter } from "../Container"
import AddContentTemplateModal from "./Modal/AddContentTemplateModal";
import AddActionTemplateModal from "./Modal/AddActionTemplateModal";
import { toast as reactToastify } from 'react-toastify';
import { AppLoader } from "../Shared/AppLoader";
import { useInView } from "react-intersection-observer";

const retriveContentTitle = (item: any) => {
	let title: string = item[item.content_type]?.title || ''

	if (item.template_id) {
		title =
			item.content_template[
				item.content_type
			][0].title;
	}
	return title;
};


const ArrowIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
		className="h-3 w-3 flex-shrink-0 text-grayColor transition duration-100 hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white;"
		viewBox="0 0 5.284 5.284"
	>
		<path
			fill="currentColor"
			d="M280 336v3.473a.568.568 0 00.566.566h3.642l-.849.849.4.4 1.529-1.529-1.529-1.529-.4.4.849.849h-3.642V336z"
			transform="translate(-280 -336)"
		></path>
	</svg>
);

const StateDetails: FC = () => {
	const { t, i18n } = useTranslation();

	const { state: globalState, dispatch } = useContext(store);

	const { setTab: setTabToTabbar } = useContext(TabContext);

	const organization_id = useMemo(
		() => globalState.organization_id,
		[globalState.organization_id]
	);

	const params = useParams();
	const queryClient = useQueryClient();
	const id = params.stateSlug || "";
	const pathwaySlug = params.slug || "";


	const navigate = useNavigate();

	const { isAdmin } = useContext(UserContext);

	const [showAddContentTemplateModal, setShowAddContentTemplateModal] = useState<boolean>(false)
	const [showAddActionTemplateModal, setShowAddActionTemplateModal] = useState<boolean>(false)

	// ========================== POPUP Modal  ===============================

	const [showPopupDeleteRow, setShowPopupDeleteRow] = useState(false);

	const [modal, setModal] = useState({
		addContentModal: false,
		addActionModal: false,
		addConditionModal: false,
		showContent: false,
		showAction: false,
		isEditContent: false,
		isEditAction: false,
		id: 0,
		slug: "",
	});

	const [confirmDelete, setConfirmDelete] = useState({
		isOpen: false,
		id: "",
		callBack: () => { },
	});

	const allRule = useQuery<Rules_R, Error>(
		["all-state-rules", organization_id],
		() => getAllRule(organization_id),
		{
			cacheTime: 0,
		}
	);

	const [search, setSearch] = useState("");
	const [query, setQuery] = useState("");
	const [searchQ, setSearchQ] = useState<serachTypes>({
		phone_number: "",
		email_address: "",
		first_name: "",
		last_name: "",
		candidate_id: "",
		is_employee: null,
	});
	const [applyQueries, setApplyQueries] = useState<serachTypes>({
		phone_number: "",
		email_address: "",
		first_name: "",
		last_name: "",
		candidate_id: "",
		is_employee: null,
	});
	const [selected, setSelected] = useState<string[]>([]);

	const [rule, setRule] = useState<number | null | undefined>(null);
	const { json, setJson } = UseQuery(rule, allRule?.data?.data);

	const handleSelectFilter = useCallback(
		(filter: string) => {

			if (selected.includes(filter))
				return setSelected((prev) => prev.filter((item) => item !== filter));
			setSelected((prev) => [...prev, filter]);
		},
		[selected]
	);


	const [tabs, setTabs] = useState<"Contents" | "Actions" | "Workers" | "State Conditions">("Contents");


	const {
		isLoading,
		isError,
		data: req,
		error,
		refetch,
		isFetching,
	} = useQuery<any, Error>(["state", id], () => getState(pathwaySlug, id), {
		enabled: !!pathwaySlug && !!id,
		// staleTime: 300 * 1000
	});
	// console.log('req', req)
	//   console.log('states',globalState.currentFlow.states)


	const fetchContentsData = ({ pageParam = "" }) =>
		getAllContentsInState(organization_id, pathwaySlug, id, pageParam);
	const fetchActionsData = ({ pageParam = "" }) =>
		getAllActionInState(organization_id, pathwaySlug, id, pageParam);
	const fetchParticipantData = ({ pageParam = "" }) =>
		getAllParticipantInState(organization_id, pathwaySlug, id, pageParam, {
			...applyQueries,
		});
	const fetchContentsDataFilter = ({ pageParam = "" }) =>
		getAllContentsInStateFilter(
			organization_id,
			pathwaySlug,
			id,
			pageParam,
			selected,
			query
		);
	const fetchActionsDataFilter = ({ pageParam = "" }) =>
		getAllActionInStateFilter(
			organization_id,
			pathwaySlug,
			id,
			pageParam,
			selected,
			query
		);

	// ['state-contents', id] => state-contents
	const {
		isLoading: isContentsLoading,
		isError: isContentsError,
		data: ContentsData,
		error: contentsError,
		refetch: refetchStateContents,
		isFetching: isFetchingContents,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useInfiniteQuery<State_Contents_R, Error>(["state-contents", id], fetchContentsData,
		{
			getNextPageParam: (lastPage) => {
				// if (lastPage.paginate.next === "") return undefined;
				// return lastPage.paginate.next;
				if (lastPage && lastPage.data) {
					if (lastPage.paginate.next === "") return undefined;
					return lastPage.paginate.next;
				}
			},
			enabled: tabs === "Contents",
			cacheTime: 0,
		}
	);

	// ['state-actions', id]
	const actionData = useInfiniteQuery<State_Actions_R, Error>(
		["state-actions", id],
		fetchActionsData,
		{
			getNextPageParam: (lastPage) => {
				if (lastPage.paginate.next === "") return undefined;
				return lastPage.paginate.next;
			},
			enabled: tabs === "Actions",
			cacheTime: 0,
		}
	);

	const handleNewContent = useCallback(
		async () => {
			setModal((prev) => ({
				...prev,
				addContentModal: !prev.addContentModal,
			}))
			//   setShowAddContentTemplateModal(prev => true)
			// load contents
			await refetchStateContents()
		},
		[refetchStateContents]
	);
	const handleNewActions = useCallback(
		async () => {
			setModal((prev) => ({
				...prev,
				addActionModal: !prev.addActionModal,
			}))
			// load actions
			await actionData.refetch()
		},
		[actionData]
	);


	const handleNewConditions = async () => {
		await queryClient.invalidateQueries(["state", id]);
		await queryClient.invalidateQueries(["all-state-rules", organization_id]);
		setModal((prev) => ({
			...prev,
			addConditionModal: !prev.addConditionModal,
		}));
	};

	const handleCloseItemConfirmation = useCallback(
		() =>
			setConfirmDelete((prev) => ({
				...prev,
				isOpen: !prev.isOpen,
				callBack: () => { },
			})),
		[]
	);

	const handleShowContent = useCallback((id: number = 0) => {
		setModal((prev) => ({
			...prev,
			showContent: !prev.showContent,
			id,
		}));
	}, []);

	const handleShowAction = useCallback((id: number = 0) => {
		setModal((prev) => ({
			...prev,
			showAction: !prev.showAction,
			id,
		}));
	}, []);

	const editContentHandler = useCallback(
		(slug: string = "") =>
			setModal((prev) => ({
				...prev,
				isEditContent: !prev.isEditContent,
				slug,
			})),
		[]
	);


	const editActionHandler = useCallback(
		(slug: string = "") =>
			setModal((prev) => ({
				...prev,
				isEditAction: !prev.isEditAction,
				slug,
			})),
		[]
	);



	const handleSuccessCreateContents = useCallback(
		() => refetchStateContents(),
		[]
	);
	const handleSuccessCreateActions = useCallback(
		() => actionData.refetch(),
		[]
	);


	const onTheParticipantEnd = async () => {
		if (participantData.hasNextPage || !participantData.isFetchingNextPage)
			await participantData.fetchNextPage();
	};

	const handleRemoveFilter = () => {
		setSearchQ({
			phone_number: "",
			email_address: "",
			first_name: "",
			last_name: "",
			candidate_id: "",
			is_employee: null,
		});

		setApplyQueries({
			phone_number: "",
			email_address: "",
			first_name: "",
			last_name: "",
			candidate_id: "",
			is_employee: null,
		});

		setTimeout(() => {
			participantData.refetch();
			setShowOption(false);
		}, 500);
	};

	const [showOption, setShowOption] = useState(false);

	const optionRef = useRef() as any;
	useOnClickOutside(optionRef, () => setShowOption(false));

	useEffect(() => {
		if (!showOption) setSearchQ(applyQueries);
	}, [showOption]);

	useEffect(() => {
		if (req?.data?.state) {
			setRule(req?.data?.state?.rules_state_id);
		}
	}, [req]);

	// =========================== WORKER TAB ==================================

	// =========================== START Scroll Query  ==============================

	const participantData = useInfiniteQuery<any, Error>(
		["state-participant", pathwaySlug, id],
		fetchParticipantData,
		{
			getNextPageParam: (lastPage) => {
				if (lastPage.paginate.next === "") return undefined;
				return lastPage.paginate.next;
			},
			enabled: tabs === "Workers",
			cacheTime: 0,
		}
	);

	const tableRef = useRef<HTMLTableSectionElement | null>(null);

	useEffect(() => {
		// get table client.y
		const tableElement = tableRef.current;
		const tableClientY = tableElement?.getBoundingClientRect().y;
		const tableHeight = tableElement?.clientHeight;
		const windowHeight = window.innerHeight;
		// check if table height is more than window height
		if (
			tableHeight &&
			tableClientY &&
			windowHeight &&
			participantData.data &&
			participantData.data?.pages.length > 0
		) {
			if (tableClientY + tableHeight < windowHeight) {
				participantData.fetchNextPage();
			}

		}
	}, [participantData.fetchNextPage, participantData.data, participantData]);



	useEffect(() => {
		if (tabs === "State Conditions" || tabs === "Workers") {
			closeCommandBar()
		}
	}, [tabs])

	// ========================== COMMANDBAR  ==================================

	const [selectedRows, setSelectedRows] = useState<any[]>([]);

	const [showCommandBar, setShowCommandBar] = useState(false);
	const closeCommandBar = useCallback(
		() => setShowCommandBar(false),
		[]
	);

	useEffect(() => {
		if (selectedRows.length > 0) {
			setShowCommandBar(true);
		} else {
			setShowCommandBar(false);
		}
	}, [selectedRows.length]);
	// ========================================================================

	const openEditActionModal = useCallback((slug: string) => {
		editActionHandler(slug);
		setModal((prev) => {
			return {
				...prev,
				isEditAction: true,
			};
		});
	}, []);

	const openEditContentModal = useCallback((slug: string) => {
		editContentHandler(slug);
		setModal((prev) => {
			return {
				...prev,
				isEditContent: true,
			};
		});
	}, []);

	// ========================== Edit Condition Modal  =====================================

	const [editConditionModal, setEditConditionModal] = useState(false);
	const editConditionModalHandler = useCallback(
		async () => {
			setEditConditionModal((prev) => !prev)
			if (tabs === 'Contents') {
				await refetchStateContents()
			}
			if (tabs === 'Actions') {
				await actionData.refetch()
			}
			closeCommandBar()
		},
		[actionData, closeCommandBar, refetchStateContents, tabs]
	);

	// ========================== POPUP Modal  ===============================

	//   const [showPopupDeleteRow, setShowPopupDeleteRow] = useState(false);

	const confirmDeleteRow = async () => {
		if (selectedRows.length <= 0) return;
		try {
			// Map selected rows to an array of promises that delete them
			const deletePromises = selectedRows.map((row) => {
				if (tabs === "Contents") {

					return deleteContent(1, pathwaySlug, id, row.slug);
				}
				else if (tabs === "Actions") {
					return deleteAction(1, pathwaySlug, id, row.slug);
				}
			}) as Promise<any>[]



			deletePromises.forEach((promise) =>
				reactToastify.promise(promise, {
					pending: 'Deleting',
					success: 'Deleted Successfully ',
					error: 'Couldn\'t be deleted'
				}))
			setSelectedRows([]);
			closeCommandBar()
			setShowPopupDeleteRow(false);
		} catch (error) {
			setSelectedRows([]);
			closeCommandBar()
		}

		if (tabs === 'Contents') {
			// TODO: this later should be solved in backend (temp solution)
			setTimeout(() => refetchStateContents(), 500)
		} else if (tabs === 'Actions') {
			// TODO: this later should be solved in backend (temp solution)
			await setTimeout(() => actionData.refetch(), 500)
		}
	};


	const [showStateConditionalModal, setShowStateConditionalModal] = useState<boolean>(false)


	const [searchTerm, setSearchTerm] = useState("");
	const [searchTermAction, setSearchTermAction] = useState("");

	//=========================================================================

	const { ref, inView } = useInView();

	useEffect(() => {
		if (inView) {
			fetchNextPage();
		}
	}, [inView]);


	const tableContentRef = useRef<HTMLTableSectionElement | null>(null);
	const tableActionRef = useRef<HTMLTableSectionElement | null>(null);
	const tableWorkerRef = useRef<HTMLTableSectionElement | null>(null);

	useEffect(() => {
		// get table client.y
		const tableElement = tableContentRef.current;
		const tableClientY = tableElement?.getBoundingClientRect().y;
		const tableHeight = tableElement?.clientHeight;
		const windowHeight = window.innerHeight;
		// check if table height is more than window height	  

		if (!hasNextPage) {
			return
		}

		if (
			tableHeight &&
			tableClientY &&
			windowHeight &&
			ContentsData &&
			ContentsData?.pages.length > 0
		) {
			if (tableClientY + tableHeight < windowHeight) {
				fetchNextPage();
			}

		}
	}, [ContentsData, fetchNextPage]);

	useEffect(() => {
		// get table client.y
		const tableElement = tableActionRef.current;
		const tableClientY = tableElement?.getBoundingClientRect().y;
		const tableHeight = tableElement?.clientHeight;
		const windowHeight = window.innerHeight;

		if (!actionData.hasNextPage) {
			return
		}
		// check if table height is more than window height	  
		const length = actionData?.data?.pages?.length as number || 0
		if (
			tableHeight &&
			tableClientY &&
			windowHeight &&
			actionData &&
			length > 0
		) {
			if (tableClientY + tableHeight < windowHeight) {
				actionData.fetchNextPage();
			}
		}
	}, [actionData]);

	useEffect(() => {
		// get table client.y
		const tableElement = tableWorkerRef.current;
		const tableClientY = tableElement?.getBoundingClientRect().y;
		const tableHeight = tableElement?.clientHeight;
		const windowHeight = window.innerHeight;

		if (!participantData?.hasNextPage) {
			return
		}

		// check if table height is more than window height	  
		const length = participantData?.data?.pages?.length as number || 0
		if (
			tableHeight &&
			tableClientY &&
			windowHeight &&
			participantData &&
			length > 0
		) {
			if (tableClientY + tableHeight < windowHeight) {
				participantData.fetchNextPage();
			}
		}
	}, [participantData]);




	// ========================================================================

	if (isError) {
		return <span>Error: {error?.message || "Something went wrong!"}</span>;
	}


	return (
		<Fragment>
			<Fragment>
				<section className="relative">
					<TabBar />
					<div className="flex flex-col items-start justify-between p-5 bg-white border shadow-sm border-borderGray">
						<span className="pb-1 mb-4 text-sm text-grayColor">
							<Link to="/pathways">Pathways</Link> &nbsp;&nbsp;/&nbsp;&nbsp;{' '}
							<Link to={`/pathways/${globalState.selectedPathway?.slug}`}>
								{globalState?.selectedPathway
									? globalState?.selectedPathway.name
									: ''}
							</Link>
							&nbsp;&nbsp;/&nbsp;&nbsp;{' '}
							<Link to={`/pathways/${pathwaySlug}`}>
								{req?.data?.state?.name || ''}
							</Link>{' '}
						</span>
						<div className="flex my-1">
							<svg
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 7.573 8.414"
								className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
							>
								<path
									id="icon_pathways"
									d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
									transform="translate(-6 -4)"
									fill="currentColor"
								/>
							</svg>
							<div className="flex flex-col items-start p-2">
								<p className="text-xl whitespace-nowrap text-textDark">
									{req?.data?.state?.name || ''}
								</p>
								<div className="flex items-center gap-3 px-2 py-1 transition-all delay-75 rounded-md hover:cursor-pointer hover:shadow-sm">
									<div className="flex items-center gap-1">
										<EyeIcon className="w-4 h-4 text-grayColor" />
										<span className="inline text-xs text-grayColor">
											Active
										</span>
									</div>

									<div className="flex items-center gap-1">
										<svg
											aria-hidden="true"
											className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 11.071 7.778"
										>
											<path
												fill="currentColor"
												d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
												transform="translate(-1.9 -8.95)"
											/>
										</svg>
										<span className="text-xs text-grayColor">
											{req?.data?.state.participant_count || 0} Workers
										</span>
									</div>
									<CustomClipboard
										showClipboardText={false}
										showInfoIcon={false}
										clipboardText={id}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="border border-[borderGray] border-t-0 bg-white py-2.5 px-5 shadow-sm flex items-center gap-2">
						<AppButton
							variant={tabs === 'Contents' ? 'info' : 'primary'}
							onClick={() => {
								setTabs('Contents')
								setSelectedRows([])
							}}
							type="button"
						>
							<div className="flex items-center gap-1">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
									viewBox="0 0 9.879 9.638"
									className={
										'h-3 w-3 ' +
										(tabs === 'Contents' ? ' text-white' : 'text-grayColor')
									}
								>
									<path
										id="icon_content"
										d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
										transform="translate(-4 -4)"
										fill="currentColor"
									/>
								</svg>
								{`${t('StatePage.Contents')}`}
							</div>
						</AppButton>

						<AppButton
							variant={tabs === 'Actions' ? 'info' : 'primary'}
							onClick={() => {
								setTabs('Actions')
								setSelectedRows([])
							}}
							type="button"
						>
							<div className="flex items-center gap-1">
								<svg
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 9.878 9.878"
									className={
										'h-3 w-3 ' +
										(tabs === 'Actions' ? ' text-white' : 'text-grayColor')
									}
								>
									<path
										id="icon_actions"
										d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
										transform="translate(-2 -2)"
										fill="currentColor"
									/>
								</svg>
								{`${t('StatePage.Actions')}`}
							</div>
						</AppButton>

						<AppButton
							variant={tabs === 'Workers' ? 'info' : 'primary'}
							onClick={() => {
								setTabs('Workers')
								setSelectedRows([])
							}}
							type="button"
						>
							<div className="flex items-center gap-1">
								<svg
									aria-hidden="true"
									className={
										'h-3 w-3 ' +
										(tabs === 'Workers' ? ' text-white' : 'text-grayColor')
									}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 11.071 7.778"
								>
									<path
										fill="currentColor"
										d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
										transform="translate(-1.9 -8.95)"
									/>
								</svg>
								{`${t('StatePage.Workers')}`}
							</div>
						</AppButton>

						<AppButton
							variant={tabs === 'State Conditions' ? 'info' : 'primary'}
							onClick={() => {
								setTabs('State Conditions')
								setSelectedRows([])
							}}
							type="button"
						>
							<div className="flex items-center gap-1">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 96 960 960"
									aria-hidden="true"
									className={
										'h-4 w-4 ' +
										(tabs === 'State Conditions'
											? ' text-white'
											: 'text-grayColor')
									}
								>
									<path
										fill="currentColor"
										d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
									></path>
								</svg>
								{`${t('StatePage.State_Conditions')}`}
							</div>
						</AppButton>
					</div>

					<div
						className={`${tabs === 'Contents' || tabs === 'Actions' ? '' : 'hidden'
							} border border-[borderGray] border-t-0 bg-white py-2.5 px-5 shadow-sm flex items-center gap-2`}
					>
						{tabs === 'Contents' ? (
							<div className="flex items-center gap-2">
								<AppButton
									variant="primary"
									onClick={handleNewContent}
									type="button"
								>
									<div className="flex items-center gap-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
											viewBox="0 0 9.879 9.638"
											className={'h-3 w-3 text-grayColor'}
										>
											<path
												id="icon_content"
												d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
												transform="translate(-4 -4)"
												fill="currentColor"
											/>
										</svg>
										Add Content +
									</div>
								</AppButton>
								<AppSearch
									className={
										'bg-primary block w-96 h-8 border-transparent p-2 text-sm text-textDark outline-none focus:border-transparent focus:ring-0'
									}
									placeHolder={'Search Contents'}
									onChangeHandler={(e) =>
										setSearchTerm(e.target.value.toLowerCase())
									}
									value={searchTerm}
								/>
							</div>
						) : null}

						{tabs === 'Actions' ? (
							<div className="flex items-center gap-2">
								<AppButton
									variant="primary"
									onClick={handleNewActions}
									type="button"
								>
									<div className="flex items-center gap-1">
										<svg
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 9.878 9.878"
											className={'h-3 w-3 text-grayColor'}
										>
											<path
												id="icon_actions"
												d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
												transform="translate(-2 -2)"
												fill="currentColor"
											/>
										</svg>
										Add Actions +
									</div>
								</AppButton>
								<AppSearch
									className={
										'search-box  block w-full h-8 rounded-lg border-transparent bg-primary p-2.5 text-sm text-textDark outline-none focus:border-transparent focus:ring-0'
									}
									placeHolder={'Search Actions'}
									onChangeHandler={(e) =>
										setSearchTermAction(e.target.value.toLowerCase())
									}
									value={searchTermAction}
								/>
							</div>
						) : null}
					</div>

					{/* ==================================================================== */}
					<div className="pb-10">
						<div className="w-[97%] py-5 m-auto space-y-5" >
							<div className="flex items-center justify-center">
								{(isFetchingContents ||
									isFetching ||
									actionData.isFetching ||
									participantData.isFetching) && <Loader isLoading size={35} />}
							</div>
							{/* ========================================================= */}

							{tabs === 'Contents' ? (
								<div className="relative shadow-md sm:rounded-lg" ref={tableContentRef}>
									<table id="daniTable" className="w-full overflow-x-scroll text-sm text-left text-textDark">
										<THead>
											<tr>
												<th scope="col" className="px-2 py-3" />
												<th scope="col" className="px-2 py-3 font-medium">
													Content
												</th>
												<th scope="col" className="px-2 py-3 font-medium">
													Content Type
												</th>
												<th scope="col" className="px-2 py-3 font-medium">
													Display Type
												</th>
											</tr>
										</THead>
										<tbody>
											{ContentsData?.pages?.map((infinitePage, i) => {
												return (
													<Fragment key={i}>
														{infinitePage?.data
															?.sort(
																(a: any, b: any) => a?.priority - b?.priority
															)
															?.filter((item: any) => {
																return retriveContentTitle(item)
																	.toLowerCase()
																	.includes(searchTerm.toLowerCase())
															})
															?.map((item: any, index: number) => {

																let title: string =
																	item[item.content_type]?.title || ''

																if (item.template_id) {
																	const findEnglish = item.content_template[item.content_type].find((item: any) => item.language === "en")
																	title = findEnglish ? findEnglish.title : ''
																}
																if (title === '') {
																	title = item.preview_title
																}
																return (
																	<Fragment key={index}>
																		<tr className="capitalize transition-all bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer">
																			<td
																				width={20}
																				className="py-3 pl-2 font-medium text-center text-textDark whitespace-nowrap"
																			>
																				<input
																					type="checkbox"
																					checked={selectedRows.length > 0 ? selectedRows.find((sr: any) => sr.slug === item.slug) : false}
																					onChange={() => {
																						if (
																							selectedRows.find((i) => {
																								return i.slug === item.slug
																							})
																						) {
																							setSelectedRows(
																								selectedRows.filter(
																									(row) =>
																										row.slug !== item.slug
																								)
																							)
																						} else {
																							setSelectedRows([
																								...selectedRows,
																								item,
																							])
																						}
																					}}
																					className="w-4 h-4 text-blue-600 bg-transparent bg-gray-200 border-2 border-gray-300 rounded-sm cursor-pointer checkbox-worker focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
																				/>
																			</td>
																			<td
																				onClick={() =>
																					openEditContentModal(item.slug)
																				}
																				width={400}
																				className="py-3 pl-2 font-medium text-textDark"
																			>
																				<div className="flex flex-col justify-center gap-2">
																					<div className="flex items-center gap-2 mb-1">
																						<span className="text-lg text-textDark">
																							{title.length > 90
																								? title.slice(0, 90) + '...'
																								: title}
																						</span>
																					</div>

																					<div className="flex gap-5 pl-5">
																						<span className="flex items-center gap-1 text-grayColor">
																							{item.is_active ? (
																								<EyeIcon className="w-4 h-4 text-grayColor" />
																							) : (
																								<CloseEyeIcon className="w-4 h-4 text-grayColor" />
																							)}{' '}
																							&nbsp;
																							{item.is_active
																								? 'Active'
																								: 'Inactive'}
																						</span>
																						<span className="flex items-center gap-1 text-grayColor">
																							<EyeIcon className="w-4 h-4 text-grayColor" />
																							&nbsp; Priority: {item.priority}
																						</span>
																					</div>
																				</div>
																			</td>
																			<td
																				onClick={() =>
																					openEditContentModal(item.slug)
																				}
																				width={70}
																				className="py-3 pl-2 font-medium text-textDark whitespace-nowrap"
																			>
																				<div className="flex items-center gap-2">
																					<div className="w-5 h-5 text-grayColor">
																						{
																							ContentTemplateIcons[
																							item.content_type as EnumContentType
																							]
																						}
																					</div>
																					<span className="capitalize text-md text-textDark">
																						{item.content_type.replaceAll('_', ' ')}
																					</span>
																				</div>
																			</td>
																			<td
																				onClick={() =>
																					openEditContentModal(item.slug)
																				}
																				width={120}
																				className="py-3 pl-2 font-medium"
																			>
																				<div className="flex items-center gap-2">
																					<ArrowIcon />
																					<span className="text-textDark">
																						{item.rules_content_id !== null
																							? 'Deliver based on an Event'
																							: ''}
																						{item.is_time_base
																							? 'Deliver based on Time'
																							: ''}
																						{item.rules_content_id === null &&
																							item.is_time_base === false
																							? 'Deliver Immediately'
																							: ''}
																					</span>
																				</div>
																			</td>
																		</tr>
																		<tr ref={ref}></tr>
																	</Fragment>
																)
															})}
													</Fragment>
												)
											})}
										</tbody>
									</table>

									<div className="flex items-center justify-center">
										{hasNextPage ? (
											<>
												<AppLoader isLoading size={30} />
											</>
										) : null}
									</div>
								</div>
							) : null}




							{/* ========================================================= */}
							{tabs === 'Actions' ? (
								<div className="relative shadow-md sm:rounded-lg" ref={tableActionRef}>
									<Table>
										<THead>
											<tr>
												<th scope="col" className="px-2 py-3" />
												<th scope="col" className="px-2 py-3 font-medium">
													Action
												</th>
												<th scope="col" className="px-2 py-3 font-medium">
													Action Type
												</th>
												<th scope="col" className="px-2 py-3 font-medium">
													Display Type
												</th>
											</tr>
										</THead>
										<tbody>
											{actionData.data?.pages.map((infinitePage, i) => {
												return (
													<Fragment key={i}>
														{infinitePage?.data?.map(
															(item: any, index: number) => {
																let title = ''

																if (item.template_id) {
																	title =
																		item?.action_template?.action_metadata.filter((item: any) => item.language === "en")[0]
																			.text ||
																		item?.action_template?.action_metadata.filter((item: any) => item.language === "en")[0]
																			.title ||
																		''
																} else {
																	// @ts-ignore
																	title =
																		item?.subject ||
																		item?.text ||
																		item?.title ||
																		''
																}
																return (
																	<Fragment key={index}>
																		<tr className="capitalize transition-all bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer">
																			<td
																				width={20}
																				className="py-3 pl-2 font-medium text-center text-textDark whitespace-nowrap"
																			>
																				<input
																					checked={selectedRows.length > 0 ? selectedRows.find((sr: any) => sr.slug === item.slug) : false}
																					type="checkbox"
																					onChange={() => {
																						if (
																							selectedRows.find((i) => {
																								return i.slug === item.slug
																							})
																						) {
																							setSelectedRows(
																								selectedRows.filter(
																									(row) =>
																										row.slug !== item.slug
																								)
																							)
																						} else {
																							setSelectedRows([
																								...selectedRows,
																								item,
																							])
																						}
																					}}
																					className="w-4 h-4 text-blue-600 bg-transparent bg-gray-200 border-2 border-gray-300 rounded-sm cursor-pointer checkbox-worker focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
																				/>
																			</td>

																			<td
																				onClick={() =>
																					openEditActionModal(item.slug)
																				}
																				width={400}
																				className="py-3 pl-2 font-medium text-textDark"
																			>
																				<div className="flex flex-col justify-center gap-2">
																					<div className="flex items-center gap-2 mb-1">
																						{/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              aria-hidden="true"
                                              viewBox="0 0 9.879 9.638"
                                              className="w-3 h-3 text-grayColor"
                                            >
                                              <path
                                                id="icon_content"
                                                d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
                                                transform="translate(-4 -4)"
                                                fill="currentColor"
                                              />
                                            </svg> */}
																						<span className="text-md text-textDark">
																							{title.length > 80
																								? title.slice(0, 80) + '...'
																								: title}
																						</span>
																					</div>

																					<div className="flex gap-5 pl-5">
																						<span className="flex items-center gap-1 text-grayColor">
																							{item.is_active ? (
																								<EyeIcon className="w-4 h-4 text-grayColor" />
																							) : (
																								<CloseEyeIcon className="w-4 h-4 text-grayColor" />
																							)}{' '}
																							&nbsp;
																							{item.is_active
																								? 'Active'
																								: 'Inactive'}
																						</span>
																						{/* <span className="flex items-center gap-1 text-grayColor">
                                              <EyeIcon className="w-4 h-4 text-grayColor" />
                                              &nbsp; Proirity: {item.priority}
                                            </span> */}
																					</div>
																				</div>
																			</td>

																			<td
																				onClick={() =>
																					openEditActionModal(item.slug)
																				}
																				width={70}
																				className="py-3 pl-2 font-medium text-textDark whitespace-nowrap"
																			>
																				<div className="flex items-center gap-2">
																					<div className="w-5 h-5 text-grayColor">
																						{
																							ActionTemplateIcons[
																							item.action_type as EnumActionType
																							]
																						}
																					</div>
																					<span className="capitalize text-md text-textDark">
																						{item.action_type === 'al_notification' ? "AL Notification" : item.action_type}
																					</span>
																				</div>
																			</td>

																			<td
																				onClick={() =>
																					openEditActionModal(item.slug)
																				}
																				width={120}
																				className="py-3 pl-2 font-medium"
																			>
																				<div className="flex items-center gap-2">
																					<ArrowIcon />
																					<span className="text-textDark">
																						{item.rules_action_id !== null
																							? 'Deliver based on an Event'
																							: ''}
																						{item.is_time_base
																							? 'Deliver based on Time'
																							: ''}
																						{item.rules_action_id === null &&
																							item.is_time_base === false
																							? 'Deliver Immediately'
																							: ''}
																					</span>
																				</div>
																			</td>
																		</tr>
																		<tr ref={ref}></tr>
																	</Fragment>
																)
															}
														)}
													</Fragment>
												)
											})}
										</tbody>
									</Table>

									<div className="flex items-center justify-center">
										{actionData.hasNextPage ? (
											<>
												<AppLoader isLoading size={30} />
											</>
										) : null}
									</div>
								</div>
							) : null}
							{/* ========================================================= */}
							{tabs === 'Workers' ? (
								<div className="relative shadow-md sm:rounded-lg" ref={tableActionRef}>
									<Table>
										<THead>
											<tr>
												<th scope="col" className="py-3" />
												<th scope="col" className="py-3 font-medium">
													Worker Name
												</th>
												<th scope="col" className="py-3 font-medium">
													Contact
												</th>
												<th scope="col" className="py-3 font-medium text-left">
													Worker Type
												</th>
											</tr>
										</THead>
										{/* ====================================== */}
										<tbody ref={tableRef} className="overflow-y-hidden">
											{participantData?.data?.pages?.map?.((infinitePage, i) => {
												return (
													<React.Fragment key={i}>
														{infinitePage?.data?.map(
															(item: any, index: number) => {
																return (
																	<tr
																		onClick={() =>
																		// open a new tab and navigate to the worker details page.
																		{
																			setTabToTabbar({
																				name: `Worker - ${item.first_name} ${item.last_name}`,
																				path: `/workers/${item.user_id}`,
																			})
																			navigate(`/workers/${item.user_id}`)
																		}
																		}
																		className="transition-all bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer"
																		key={index}
																	>
																		<td
																			className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
																			width={40}
																		></td>
																		<td
																			// onClick={() => showDrawerHandler(item)}
																			className="py-3 pr-6 font-medium text-textDark whitespace-nowrap dark:text-white"
																			width={350}
																		>
																			<div className="flex">
																				<div className="relative flex flex-col items-center self-center justify-center w-12 h-12 bg-pink-500 rounded-md p-3bg-pink-500">
																					<span className="text-white uppercase text-md dark:text-white">
																						{item &&
																							item.first_name &&
																							item.last_name ? (
																							<>
																								{`${item.first_name} ${item.last_name}`
																									.split(' ')
																									.map((word) => word.charAt(0))
																									.join('')}
																							</>
																						) : <>
																							{
																								item.legal_first_name || item.legal_last_name ? `${item.legal_first_name} ${item.legal_last_name}`.split(' ')
																									.map((word) => word.charAt(0)).join('') : ''
																							}
																						</>}
																					</span>
																				</div>
																				<div className="flex flex-col items-start justify-center p-2 ml-2">
																					<p className="mb-2 text-lg whitespace-nowrap text-textDark dark:text-white">
																						{item &&
																							item.first_name &&
																							item.last_name ? (
																							<>
																								{item.first_name +
																									' ' +
																									item.last_name}
																							</>
																						) : <>
																							{`${item.legal_first_name} ${item.legal_last_name}`}
																						</>}
																					</p>
																					<p className="flex gap-1 text-xs whitespace-nowrap text-grayColor dark:text-white">
																						{item && item.last_login ? (
																							<>
																								{item.last_login ? (
																									<>
																										<ActiveTimerIcon className="w-3 h-3" />
																										{moment(
																											item.last_login
																										).isValid()
																											? moment(
																												item.last_login
																											).format('MMM-DD-YYYY')
																											: item.last_login}
																									</>
																								) : null}
																								{/* "MMM DD YYYY h:mm:ss A" */}
																							</>
																						) : null}
																					</p>
																				</div>
																			</div>
																		</td>

																		<td
																			// onClick={() => showDrawerHandler(item)}
																			className="py-3 text-textDark"
																			width={350}
																		>
																			{(item && item.email_address) ||
																				item?.phone_number ? (
																				<>
																					{item.email_address === '' &&
																						item?.phone_number === '' ? (
																						<span className="text-textDark dark:text-white">
																							No Contact Information
																						</span>
																					) : (
																						<>
																							{item?.phone_number ? (
																								<div className="flex items-center justify-start gap-2 mb-1">
																									<PhoneCallIcon className="w-5 h-5 pr-1" />
																									<span className="text-textDark">
																										{item?.phone_number || ''}
																									</span>
																								</div>
																							) : null}
																							{item?.email_address ? (
																								<div className="flex items-center justify-start gap-2 ">
																									<EmailIcon className="w-5 h-5 pr-1" />
																									<span className="text-textDark">
																										{item?.email_address || ''}
																									</span>
																								</div>
																							) : null}
																						</>
																					)}
																				</>
																			) : null}
																		</td>
																		<td
																			// onClick={() => showDrawerHandler(item)}
																			className="py-3 text-left text-textDark"
																			width={200}
																		>
																			Candidate
																		</td>

																		{/* <tr ref={ref}></tr> */}
																	</tr>

																)
															}
														)}
													</React.Fragment>
												)
											})}
											<tr ref={ref}></tr>
										</tbody>

										{/* ====================================== */}
									</Table>
									<div className="flex items-center justify-center">
										{participantData.hasNextPage ? (
											<>
												<AppLoader isLoading size={30} />
											</>
										) : null}
									</div>
								</div>
							) : null}
							{/* ========================================================= */}
							{tabs === 'State Conditions' ? (
								<>
									<h3>State Entry Condition:</h3>

									<TransitionAnimation show={true} position="y-axis">
										{json && json.rules && req?.data?.rule ? (
											<div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">
												{/* has a time offset of */}A Worker enters this state
												when{' '}
												{deliverTimeAndUnitCalculator(
													json?.deliver_time,
													json?.deliver_unit
												)}
												<br />
												{json.rules.map((item: any, index: number) => (
													<div className="inline" key={index}>
														{/* {' '} <strong>{ruleNameFormatter(item.field)}</strong> {' '} */}{' '}
														<strong>{ruleNameFormatter(item.field)}</strong>{' '}
														{item.operator === 'eq'
															? '='
															: item.operator === 'not'
																? '!='
																: json.combinator === 'eq'
																	? '='
																	: json.combinator === 'not'
																		? '!='
																		: null}
														&nbsp;{String(item.value)}&nbsp;&nbsp;
														{json.rules.length - 1 !== index
															? json?.combinator?.toUpperCase()
															: null}
														&nbsp;&nbsp;
													</div>
												))}
											</div>
										) : (
											<div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">
												<p className="font-bold">No State Entry condition has been set</p>
											</div>
										)}
									</TransitionAnimation>

									<h3>State Exit Condition:</h3>

									<TransitionAnimation show={true} position="y-axis">
										{req?.data?.state?.state_time_conditions?.[0] ? (
											<div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">
												{(() => {
													const time_conditions =
														req?.data?.state?.state_time_conditions?.[0]
													const time = `${time_conditions.display_unit === 'day' ? time_conditions?.max_time_state / 24 : time_conditions?.max_time_state} ${time_conditions.display_unit
														}${time_conditions.display_unit === 'day' ? time_conditions.max_time_state / 24 > 1 ? 's' : '' : time_conditions.max_time_state > 1 ? 's' : ''}`
													const findState = globalState.currentFlow.states?.find(item => item.id === time_conditions.target_state)
													let name = ''
													if (findState)
														name = findState.name
													return (
														<p>
															If a Worker is still in this State after{' '}
															<span className="font-bold">{time}</span>,
															transition them to{' '}
															<span className="font-bold">"{name}"</span>
														</p>
													)
												})()}

												<br />
											</div>
										) : (
											<div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">
												<p className="font-bold">No State Exit condition has been set</p>
											</div>
										)}
									</TransitionAnimation>

									{isAdmin() ? (
										<>
											<AppButton
												variant="info"
												onClick={() => {
													setShowStateConditionalModal(true)
													setSelectedRows([])
													closeCommandBar()
												}}
												type="button"
												size="xl"
											>
												<div className="flex items-center gap-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 96 960 960"
														aria-hidden="true"
														className={
															'h-4 w-4 ' +
															(tabs === 'State Conditions'
																? ' text-white'
																: 'text-grayColor')
														}
													>
														<path
															fill="currentColor"
															d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
														></path>
													</svg>
													{<p>{`${t('StatePage.Define_condition')}`}</p>}
												</div>
											</AppButton>

											{/* {isAdmin() && (
                  <Button
                    icon={<AiOutlinePlusCircle />}
                    onClick={handleNewConditions}
                    label={<p>{`${t("StatePage.Define_condition")}`}</p>}
                    extraClasses="bg-blue-600 w-fit text-white h-12"
                  />
                )} */}
										</>
									) : (
										<p className="text-md text-textDark">
											You do not have a permission to{' '}
											{`${t('StatePage.Define_condition')}`}
										</p>
									)}
								</>
							) : null}

							{/* <div className="flex items-center justify-center">
							{hasNextPage || actionData.hasNextPage || participantData.hasNextPage ? (
							<>
								<AppLoader isLoading size={30} />
							</>
							) :null}
						</div> */}
						</div>
					</div>
				</section>
			</Fragment>

			{/* ===================  POPUP Modal ======================== */}
			<PopupModal
				setOpen={setShowPopupDeleteRow}
				isOpen={showPopupDeleteRow}
				title="If you want to continue, confirm by clicking the delete button below."
				onConfirmClick={confirmDeleteRow}
			/>

			{/* =============== COMMANDBAR ==================== */}
			<CommandBar
				isOpen={showCommandBar}
				setOpen={() => setShowCommandBar(!showCommandBar)}
			>
				<span className="text-xs text-textGray">
					{selectedRows.length} Selected
				</span>
				{selectedRows.length === 1 ? (
					<AppButton
						type="button"
						variant="primary"
						onClick={() => {
							setModal((prev) => {
								return {
									...prev,
									slug: selectedRows[0].slug,
								}
							})
							if (tabs === 'Contents') {
								editContentHandler(selectedRows[0].slug)
							} else if (tabs === 'Actions') {
								editActionHandler(selectedRows[0].slug)
							}
						}}
					>
						<div className="flex items-center gap-1 mx-1">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 96 960 960"
								className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
							>
								<path
									fill="currentColor"
									d="M794 390L666 262l42-42q17-17 42.5-16.5T793 221l43 43q17 17 17 42t-17 42l-42 42zm-42 42L248 936H120V808l504-504 128 128z"
								></path>
							</svg>
						</div>
						Edit {tabs === 'Contents' ? 'Content' : 'Action'}
					</AppButton>
				) : null}
				{selectedRows.length === 1 ? (
					<AppButton
						type="button"
						variant="primary"
						onClick={() => editConditionModalHandler()}
					>
						<div className="flex items-center gap-1 mx-1">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 96 960 960"
								aria-hidden="true"
								className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
							>
								<path
									fill="currentColor"
									d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
								></path>
							</svg>
							<span className="text-sm">
								Edit {tabs === 'Contents' ? 'Content' : 'Action'} Condition
							</span>
						</div>
					</AppButton>
				) : null}

				<AppButton
					type="button"
					variant="primary"
					onClick={() => setShowPopupDeleteRow((prev) => !prev)}
				>
					<div className="flex items-center gap-1 mx-1">
						<DeleteIcon className="" />
						<span className="text-sm">Delete</span>
					</div>
				</AppButton>
			</CommandBar>

			{/* =================================================================================================== */}
			{/*---------------------------------------------------------*/}
			{/*Modal display when attempting to delete*/}
			<OverlayModal
				onClose={handleCloseItemConfirmation}
				isOpen={confirmDelete.isOpen}
			>
				<Prompt
					title={`${t('StatePage.Delete_Item')}`}
					description={`${t('StatePage.Delete_description')}`}
					confirmButtonText={`${t('StatePage.Delete_Item')}`}
					cancelButtonText={`${t('StatePage.Cancel')}`}
					onClickConfirm={() => {
						confirmDelete.callBack()
						handleCloseItemConfirmation()
					}}
					onClickCancel={handleCloseItemConfirmation}
				/>
			</OverlayModal>
			<OverlayModal onClose={editContentHandler} isOpen={modal.isEditContent}>
				<EditContentModal
					onSuccess={handleSuccessCreateContents}
					modalHandle={editContentHandler}
					content_slug={modal.slug}
				/>
			</OverlayModal>
			<OverlayModal onClose={editActionHandler} isOpen={modal.isEditAction}>
				<EditActionModal
					modalHandle={editActionHandler}
					action_slug={modal.slug}
					onSuccess={handleSuccessCreateActions}
				/>
			</OverlayModal>
			<OverlayModal onClose={handleShowContent} isOpen={modal.showContent}>
				<ViewContentModal
					modalHandle={handleShowContent}
					content_slug={modal.slug}
				// currentSlug={modal.slug}
				/>
			</OverlayModal>
			<OverlayModal onClose={handleShowAction} isOpen={modal.showAction}>
				<ViewActionModal
					modalHandle={handleShowAction}
					action_slug={modal.slug}
				/>
			</OverlayModal>

			<OverlayModal onClose={() => {
				handleNewContent()
				setShowAddContentTemplateModal(true)
			}} isOpen={modal.addContentModal}>
				<Content
					onClose={() => {
						handleNewContent()
						setShowAddContentTemplateModal(true)
					}}
					onSuccess={() => {
						handleSuccessCreateContents()
						refetchStateContents()
					}}
				/>
			</OverlayModal>

			<OverlayModal onClose={() => {
				handleNewActions()
				setShowAddActionTemplateModal(true)
			}} isOpen={modal.addActionModal}>
				<Action
					onSuccess={() => {
						handleSuccessCreateActions()
						actionData.refetch()
					}}
					onClose={() => {
						handleNewActions()
						setShowAddActionTemplateModal(true)
					}}
				/>
			</OverlayModal>

			<OverlayModal
				onClose={handleNewConditions}
				isOpen={modal.addConditionModal}
			>
				<AddConditionModal onClose={handleNewConditions} />
			</OverlayModal>

			{/* ============ Edit Condition Modal =========== */}
			<OverlayModal
				onClose={() => {
					editConditionModalHandler()
					setSelectedRows([])
					closeCommandBar()
				}}
				isOpen={editConditionModal}
			>
				<EditConditionModal
					ContentOrActionRecord={selectedRows[0]}
					modalHandle={() => {
						editConditionModalHandler()
						setSelectedRows([])
						closeCommandBar()
					}}
					type={tabs === 'Contents' ? 'Content' : 'Action'}
				/>
			</OverlayModal>

			{/* ============ State Condition Modal =========== */}
			<OverlayModal
				onClose={() => {
					setShowStateConditionalModal((prev) => false)
				}}
				isOpen={showStateConditionalModal}
			>
				<StateConditionalModal
					ContentOrAction={selectedRows[0]}
					modalHandle={() => {
						setShowStateConditionalModal(false)
					}}
				/>
			</OverlayModal>

			{/* ============ Add Content Template Modal =========== */}
			{/* showAddContentTemplateModal */}

			<OverlayModal
				onClose={() => {
					setShowAddContentTemplateModal((prev) => false)
					refetchStateContents()
				}}
				isOpen={showAddContentTemplateModal}
			>
				<AddContentTemplateModal modalHandle={() => {
					setShowAddContentTemplateModal(false)
					refetchStateContents()
				}} />
			</OverlayModal>

			<OverlayModal
				onClose={() => {
					setShowAddActionTemplateModal((prev) => false)
					actionData.refetch()
				}}
				isOpen={showAddActionTemplateModal}
			>
				<AddActionTemplateModal modalHandle={() => {
					setShowAddActionTemplateModal(prev => false)
					actionData.refetch()
				}} />
			</OverlayModal>


		</Fragment>
	)
};

StateDetails.displayName = "State Details";

export default withAuth(StateDetails);
