import React, {FC, useState} from 'react';
import {ContCommon} from "../../../Common/Types";
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


const SelectorPreview: FC<ContCommon> = ({selectorItems,variants}) => {

    const [selectedIndex, setIndex] = useState<number>(0)

    return (
        <div>
            <Swiper
                navigation={{
                    nextEl: '.review-swiper-button-next',
                    prevEl: '.review-swiper-button-prev',
                }}
                slidesPerView={6}
                centeredSlides={true}
                className="mySwiper"
            >
                {
                    selectorItems?.map((item, index) => <SwiperSlide key={index} onClick={() => setIndex(index)}>
                            <div className="h-14 w-14">
                                <img src={item?.image} alt="img" className="w-full h-full  rounded-full"/>
                            </div>
                        </SwiperSlide>
                    )
                }
            </Swiper>

            {

                <div className="flex items-center justify-center py-5">
                    {
                        selectorItems &&
                        <img src={selectorItems[selectedIndex]?.image} alt="selectedIndex" className="h-80 w-80 aspect-square shadow-md rounded-md"/>

                    }
                </div>
            }

            <div className="flex items-center justify-center flex-wrap">
                {
                    variants?.map((item, index) =>
                        <div key={index} className="bg-white text-black pt-2 pb-2 pl-3 pr-3 ml-1 mr-1 rounded-md">
                            <p>{item}</p>
                        </div>)
                }
            </div>
        </div>
    );
};

export default SelectorPreview;