import React, {FC, memo, useRef} from 'react';
import {Fade} from "@mui/material";
import { TransitionAnimation } from '../../Theme';

type InputProps = {
    isOpen: boolean
    onClose: () => void;
    children: JSX.Element[] | JSX.Element
}

const OverlayModal: FC<InputProps> = memo(({onClose, children, isOpen}) => {
    const ref = useRef(null);

    const closeModal = (e: any) => {
        if (ref.current === e.target) {
            onClose()
        }
    }
    return (
        <TransitionAnimation position='y-axis' show={isOpen}>
            <div className="overlay" ref={ref} onClick={closeModal}>
                {isOpen && children}
            </div>
        </TransitionAnimation>
    );
});

export const OverlayModal2: FC<InputProps> = memo(({onClose, children, isOpen}) => {
    const ref = useRef(null);

    const closeModal = (e: any) => {
        if (ref.current === e.target) {
            onClose()
        }
    }
    return (
        <Fade in={isOpen}>
            <div className="overlay-2" ref={ref} onClick={closeModal}>
                {isOpen && children}
            </div>
        </Fade>
    );
});

export default OverlayModal;