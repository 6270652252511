import { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { LanguagesOptions, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery, } from "react-query";
import { deleteContentTemplateById, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from "../../../../Context/UserContext/UserContext";
// import AllSurveyContent from './AllSurveyContent';
// import SurveyItem from './SurveyItem';
import { ISurvey, ISurveyItem, SurveyContentCreateOrUpdateRequest, SurveyContentResponse, SurveyContentTemplate, SurveyContentUpdatedResponse, initialSurveyContent } from '../../../../Types/ContentTemplate/Survey';
import UseUploadFile from '../../../../Hook/UseUploadFile';
import UseUpload from '../../../../Hook/UseUpload';
import { baseURL } from '../../../../apiQuery';
import UploadProgress from '../../../General/UploadProgress';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { useNavigate } from 'react-router-dom';
import { InlineSurveyItem } from './InlineItem';
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd"
import { survey_items } from "../../../../Common/Types"
import { reorder } from "../../../Utils/Reorder"

export type Language = "en" | "fr" | "es"



export const LanguagesOptionsList = [
	{ value: "-", label: "Choose an option" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]

interface SurveyTabContentProps {
	templateId: number,
}
const SurveyTabContent: FC<SurveyTabContentProps> = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();


	// ================================================== Updoad cover image ==================================================
	const [imageSrc, file, render, name] = UseUploadFile('Survey')
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);

	const [uploadState, setUploadState] = useState({
		file,
		name,
	})

	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])

	// ================================================== States ==================================================
	const { currentOrganizationID } = useContext(UserContext);


	const [state, setState] = useState<SurveyContentTemplate[]>([])

	const [surveyContent, setSurveyContent] = useState<SurveyContentTemplate>(initialSurveyContent)
	const createOrUpdateButton = useRef<string>("Create")
	const allSurveyContentsRef = useRef(null)
	const createOrUpdateItemButton = useRef<string>("Create")
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')

	const [showInput, setShowInput] = useState({ appId: null, isInput: false });

	//====================================================================================================================


	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);
	const [surveyData, setSurveyData] = useState<SurveyContentUpdatedResponse>()

	const { data: surveyDataWithoutAppId, refetch } = useQuery<SurveyContentResponse, Error>
		(`surveyData-content-${props.templateId}`, () => getContentTemplateById(props.templateId), {onSuccess(data) {
			const surveyWithAppId = data.data.survey.map((survey) => {
				return {
						...survey,
						survey_items: survey.survey_items.map((surveyItem) => ({...surveyItem, appId: uuidv4()}) ),
						appId: uuidv4(),
				} as ISurvey
		}) as ISurvey[];
		
		const dataWithAppId = {
				...data, 
				data: { 
						...data.data, 
						survey: surveyWithAppId,
						appId: uuidv4() 
				}
		} as SurveyContentUpdatedResponse;
		
		setSurveyData(dataWithAppId);
		
		},});

	useEffect(() => {
		if (surveyData?.data) {
			editableSurveyContent(surveyData?.data)
		}
	}, [surveyData?.data])

	const deleteTemplateFromContentTemplateList = async (
	) => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/survey', { state: { deleted: true } })
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.survey.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}
	//=====================================================
	//set english default language for content


	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setSurveyContent({
				survey: surveyData?.data.survey.find((item) => item.language === LanguageEnum.English) as ISurvey,
				content_metadata: surveyData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			createOrUpdateButton.current = "Update"
		}
	}, [surveyData?.data.content_metadata, surveyData?.data.survey])



	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.survey.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges
	}, [state])


	// ================================================== Mutations ==================================================
	const updateSurveyContentMutation = useMutation(
		(params: { id: number, data: SurveyContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Content updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	//=====================================================
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	// ====================================== Handlers =========================================
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setSurveyContent(prev => {
			return {
				...prev,
				survey: {
					...prev.survey,
					[name]: value
				}
			}
		})
	}

	const onDragEnd = (result: DropResult): void => {
		if (!result.destination) {
			return
		}
		const survey_item = surveyContent.survey.survey_items || []
		let itemsData = reorder(
			survey_item,
			result.source.index,
			result.destination.index
		) as survey_items[]

		itemsData = itemsData.map((item, ind) => ({
			...item,
			order: ind
		}))
		// @ts-ignore
		// setWorkerFavorites(updatedFavorites);
		setSurveyContent((prev) => {
			return {
				...prev,
				survey: {
					...prev.survey,
					survey_items: itemsData,
				},
			}
		})

		// setSurveyContent(prev => {
		//   return {
		//     ...prev,
		//     checklist: {
		//       ...prev.checklist,
		//       items: updatedFavorites
		//     }
		//   }
		// })
	}



	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (surveyContent.survey.title === '') {
			toast('Please fill the title', 'error')
			return
		}

		if (createOrUpdateButton.current === "Create") {
			// Todo : check if the language is already exist
			if (state.some(item => item.survey.language === surveyContent.survey.language)) {
				toast('This survey is already exist', 'error')
				return
			}

			if (surveyContent.survey.survey_items.length === 0) {

				toast('Please add at least one survey item', 'error')
				return
			}


			let _cover_imageLink = ''

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				// setArticleContent(prev => ({...prev, cover_image: link}))
				_cover_imageLink = link
			}

			const consentContentWithImage: SurveyContentTemplate = {
				survey: surveyContent.survey,
				content_metadata: {
					...surveyContent.content_metadata,
					cover_image: _cover_imageLink,
				}
			}

			// setState(prev => [...prev, consentContentWithImage])
			const newState = [...state, consentContentWithImage]
			await createOrUpdateContentTemplate(newState)

		} else {

			let cover_image = surveyContent?.content_metadata?.cover_image || ''

			if (file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", file);
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}

			// update the state
			const newState = state.map(item => {
				if (item.survey.language === surveyContent.survey.language) {
					return {
						survey: surveyContent.survey,
						content_metadata: {
							...surveyContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})

			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = "Create"

		}

		// 	empty the fields
		setSurveyContent(initialSurveyContent)
		setUploadState({
			file: undefined,
			name: '',
		})
		setSelectedLanguage('')
	}

	const createOrUpdateContentTemplate = async (data: SurveyContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one survey template', 'info')
			return
		}

		const shapeData: SurveyContentCreateOrUpdateRequest = {
			content_type: EnumContentType.SurveyContent,
			survey: data.map(item => {
				return {
					...item.survey,
					title: item.survey.title,
					description: item.survey.description,
					survey_items: item.survey.survey_items,
					language: item.survey.language as LanguageEnum,
				}
			}),
			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.survey.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}
		await updateSurveyContentMutation.mutateAsync({ id: props.templateId, data: shapeData })

		setState([])
		setSurveyContent(initialSurveyContent)

	}


	const editableSurveyContent = (surveyContentTemplate: any) => {
		const combinedData: any[] = [];
		// Loop through each article
		surveyContentTemplate.survey.forEach((survey: any) => {
			// Loop through each content metadata
			surveyContentTemplate.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (survey.language === metadata.language) {
					// Combine the properties of the survey and metadata objects
					const combinedObj = {
						survey: survey,
						content_metadata: metadata
					};
					// Add the combined object to the final array
					combinedData.push(combinedObj);
				}
			});
		});

		setState(combinedData)
	}




	const [item, setItem] = useState<ISurveyItem>({
		value: 0,
		label: '',
		order: 0,
		language: surveyContent?.survey?.language,
		appId: uuidv4()
	})

	const addItemToSurveyItems = () => {
		if (item.label === '') {
			toast('Please enter a label', 'error')
			return
		}

		setSurveyContent(prev => {
			return {
				...prev,
				survey: {
					...prev.survey,
					survey_items: [...prev.survey.survey_items, { ...item, language: surveyContent?.survey?.language, order: prev.survey.survey_items.length }]
				}
			}
		})

		setItem({
			value: 0,
			label: '',
			order: 0,
			language: surveyContent?.survey?.language,
			appId: uuidv4()
		})
		createOrUpdateItemButton.current = "Create"
	}



	return (
		<Fragment>


			<ViewContentTemplateHeader
				title={surveyData?.data.survey[0].title || ''}
				icon={<svg
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 7.573 8.414"
					className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
				>
					<path
						id="icon_pathways"
						d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
						transform="translate(-6 -4)"
						fill="currentColor"
					/>
				</svg>}
				referencesCount={surveyData?.data.linked_content_count || 0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{

					surveyData?.data.survey.sort((a, b) => {
						// Define a language order
						const languageOrder = { en: 0, es: 1, fr: 2 };

						// Get the language order for each object
						const orderA = languageOrder[a.language] || Infinity;
						const orderB = languageOrder[b.language] || Infinity;

						// Compare the language order
						return orderB - orderA;
					}).map((item, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setSurveyContent(initialSurveyContent)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										// editableSurveyContent(surveyData.data)
										currentLanguage.current = item.language as LanguageEnum
										setSurveyContent({
											survey: surveyData?.data.survey.find(s => s.language === item.language) as ISurvey,
											content_metadata: surveyData?.data?.content_metadata.find(cm => cm.language === item.language) as IContentMetadata
										})
										createOrUpdateButton.current = "Update"
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					(surveyData?.data.survey?.length ?? 0) < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setSurveyContent(initialSurveyContent)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								{t('new_language')}
							</span>
						</AppButton>
					) : null
				}
			</div>


			<TabContentLayout>

				{/* =========================== */}
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{surveyContent?.survey?.title || ""}</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] flex flex-col gap-4">

					<div className='flex w-full gap-2 mb-4'>
						<AppButton size="xs" disabled={surveyContent?.survey?.title === '' || updateSurveyContentMutation.isLoading || deleteContentTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Content Template</AppButton>
						<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>{t("content_template.delete_content_template")}</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>
					</div>
					<hr className="mb-4" />

					<AppInput autoFocus placeholder={"Title"} value={surveyContent?.survey?.title || ""} name='title' isFull onChange={handleChange} label='Title' required />
					
					<AppInput placeholder={"Alternative Title"} value={surveyContent?.survey?.alt_title || ""} name='alt_title' isFull onChange={handleChange} label='Alternative Title' />

					<AppTextArea placeholder={"Description"} rows={4} value={surveyContent?.survey?.description || ''} name='description' onChange={handleChange}
						label='Description' />

					{/* Survey Items */}

					<div className='p-2 my-2 border border-gray-200 rounded shadow-sm '>
						<p className='py-2 pl-1 font-semibold text-textDark'>Questions</p>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided, snapshot) => (
									<div {...provided.droppableProps} ref={provided.innerRef} className="grid grid-cols-1 gap-2 mb-2">
										{surveyContent?.survey?.survey_items?.sort((a, b) => a.order - b.order)?.map((item: ISurveyItem, idx: number) => (
											<Draggable key={item.appId} draggableId={`item-${item.appId}`} index={idx}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className="flex flex-col "
													>
														<InlineSurveyItem
															surveys={surveyContent}
															item={item}
															setSurvey={setSurveyContent}
															showInputAppId={showInput.appId}
															showInput={showInput}
															setShowInput={setShowInput}
														/>

													</div>)}
											</Draggable>


										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>

						{/* ADD new Question */}

						<div className='flex flex-col items-start justify-start w-[90%] p-2 m-1 border rounded bg-hoverLightBlue gap-4'>
							<p className='py-2 pl-1 font-semibold'>Survey Items</p>
							{/*  Survey Items */}
							<div className="flex flex-col items-start justify-start w-5/6 p-2">
								<AppInput label="Label" placeholder={"Label"} value={item.label} name='label'
									onChange={e => setItem(prev => { return { ...prev, label: e.target.value } })} extendClass='mb-2' isFull />
								<AppInput label="Value" type="number" placeholder={"Value"} value={String(item.value)} name='value'
              		onChange={e => setItem(prev => { return { ...prev, value: Number(e.target.value) } })}  isFull />
								<AppDropDown label={"Language"} isFull disabled={true}
									options={LanguagesOptions} value={surveyContent?.survey?.language || ""} name="language"
									onChange={e => setItem(prev => { return { ...prev, language: e.target.value as LanguageEnum } })} extendClass='mb-2' />

								<AppButton variant={createOrUpdateItemButton.current === 'Create' ? 'success' : 'info'} type={"button"}
									size={"sm"} onClick={addItemToSurveyItems} extendClass="mx-2">{createOrUpdateItemButton.current} Survey Item</AppButton>
							</div>
						</div>
					</div>
					{/* Survey Items */}

					{/* <AppInput placeholder={"Alternative Title"} value={surveyContent?.content_metadata?.alt_title || ''} name='alt_title' isFull
						onChange={e => {
							setSurveyContent({
								...surveyContent,
								content_metadata: {
									...surveyContent.content_metadata,
									alt_title: e.target.value
								}
							})
						}} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

					<AppInput placeholder={"Preview Title"} value={surveyContent?.content_metadata?.preview_title || ''} name='preview_title' isFull
						onChange={e => {
							setSurveyContent(prev => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										preview_title: e.target.value
									}
								}
							})
						}} label='Preview Title' extendClass='my-2' maxLength={140} required />
					<AppInput placeholder={"Tag"} value={surveyContent?.content_metadata?.tags?.[0] || ""} name='tags' isFull onChange={e => {
						setSurveyContent(prev => {
							return {
								...prev,
								content_metadata: {
									...prev.content_metadata,
									tags: [e.target.value]
								}
							}
						})
					}} label='Tag' extendClass='my-2' />
					{surveyContent?.content_metadata?.cover_image ? <div className='cover__image-container'><img alt="conver_image" src={surveyContent.content_metadata.cover_image} className='my-2 rounded-sm' /></div> : null}
					{render}
					{uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}
					{isLoading ? <UploadProgress progress={progress} /> : null}


					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={surveyContent?.survey?.language || ""} name="language" onChange={(e) => {
								setSurveyContent(prev => {
									return {
										...prev,
										survey: {
											...prev.survey,
											language: e.target.value as LanguageEnum,
											survey_items: prev.survey.survey_items.map(item => {
												return {
													...item,
													language: e.target.value as LanguageEnum
												}
											})

										}
									}
								})
							}} />
					}
					{/* <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"}
						options={LanguagesOptions} value={surveyContent?.survey?.language || ""} name="language" onChange={
							(e) => {
								setSurveyContent(prev => {
									return {
										...prev,
										survey: {
											...prev.survey,
											language: e.target.value as LanguageEnum,
											survey_items: prev.survey.survey_items.map(item => {
												return {
													...item,
													language: e.target.value as LanguageEnum
												}
											})

										}
									}
								})
							}

						} /> */}
				</form>
			</TabContentLayout>

			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</Fragment>
	)
}


export default SurveyTabContent
