import React, { FC } from 'react';
import { ContCommon } from "../../../Common/Types";
import ContentCardPreview from "./ContentCardPreview";
import ArticlePage from "./ArticlePreview";
import VideoPage from "./VideoPreview";
import ChecklistPage from "./ChecklistPreview";
import FaqPreview from "./FaqPreview";
import AnnouncementPreview from "./AnnouncementPreview";
import SelectorPreview from "./SelectorPreview";
import DirectionPreview from "./DirectionPreview";
import ConsentPreview from "./ConsentPreview";
import SurveyPreview from "./SurveyPreview";

const PreviewContentModal: FC<ContCommon> = (prop) => {

    return (
        <ContentCardPreview {...prop}>
            <div className="w-full mb-10 rounded-b-md">
                {(() => {
                    switch (prop.type) {
                        case 'article':
                            return <ArticlePage {...prop} />
                        case 'video':
                            return <VideoPage {...prop} />
                        case 'checklist':
                            return <ChecklistPage {...prop} />
                        case 'faq':
                            return <FaqPreview {...prop} />
                        case 'direction':
                            return <DirectionPreview {...prop} />
                        case 'announcement':
                            return <AnnouncementPreview {...prop} />
                        case 'consent':
                            return <ConsentPreview {...prop} />
                        case 'selector':
                            return <SelectorPreview {...prop} />
                        case 'survey':
                            return <SurveyPreview {...prop} />
                        default:
                            return null
                    }
                })()}
            </div>
        </ContentCardPreview>

    );
};

export default PreviewContentModal;