import React from "react";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppButton from "../../../Shared/AppButton/AppButton";
import { ALNotificationAction, Language } from "./ALNotificationTabAction";

const ALNotificationItem = (props: {
    item: ALNotificationAction,
    onDeleteClick: (language: Language) => void,
    onEditClick: (language: Language) => void
}) => {
    return <div className="p-1 m-1 border border-blue-500 rounded shadow-sm">
        <h2 className="mx-2 text-md">Language: <span className="font-semibold">{props.item.language}</span></h2>
        <form action="src/components/ActionTemplate/ActionTabs" onSubmit={e => e.stopPropagation()}>
            <AppInput value={props.item.title} name={"title"} disabled isFull />
            <div className="flex mx-2">
                {props.item.language === "en" ? null : (<AppButton variant={"danger"} type={"button"} size={"sm"}
                    onClick={() => props.onDeleteClick(props.item.language)}>Delete</AppButton>)}
                <AppButton variant={"info"} type={"button"} size={"sm"} extendClass="mx-2"
                    onClick={() => props.onEditClick(props.item.language)}>Edit</AppButton>
            </div>
        </form>
    </div>;
}


export default ALNotificationItem
