import { FC, Fragment, useEffect } from "react";
import { IRule, IRuleResponse } from "../../../Types/Rules/Rules";
import { ArrowSubIcon } from "../../../Theme";
import { SelectedModalEnum } from "./Rule.types";
import { useInfiniteQuery } from "react-query";
import { getAllRuleForAction } from "../../../apiQuery/Condition/Rule.apis";
import { AppLoader } from "../../Shared/AppLoader";
import { useInView } from "react-intersection-observer";
import { RulesFilter } from "../../../Types/Rules/RulesFilter";

export interface IActionConditionProps {
  onDeleteContentRules: (selectedRule: number, type:SelectedModalEnum) => void;
  onOpenDrawer: (selectedRule: IRule, type: SelectedModalEnum) => void
  activeQuery: boolean
  organizationId: number
  allowRefetch: boolean
  filterKey: RulesFilter
}

export const ActionCondition: FC<IActionConditionProps> = (props) => {



  const fetchActionRules = ({ pageParam = "" }) => getAllRuleForAction(props.organizationId, pageParam, props.filterKey);
  /// ============= Queries
  const {
    isLoading,
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<IRuleResponse, Error>(['action-rules', props.filterKey.filter.name], //! More appropriate index should be used for the query key
    fetchActionRules, {
    getNextPageParam: (lastPage, all) => {
      if (lastPage && lastPage.data) {
        if (lastPage.paginate.next === "") return undefined;
        return lastPage.paginate.next;
      }
    },
    enabled: props.activeQuery || props.allowRefetch
  });

  const setCheckBoxHandler = async (item: IRule) => {
    props.onDeleteContentRules(item.id, SelectedModalEnum.action);
  };

  const setOpenDrawerHandler = (item:IRule) => {
    props.onOpenDrawer(item, SelectedModalEnum.action)
  }

  useEffect(() => {
    if(props.allowRefetch) 
    refetch()
  },[props.allowRefetch])


   /// pagination
  const { ref, inView } = useInView();
 useEffect(() => {
   if (inView) {
     fetchNextPage();
   }
 }, [inView]);

  return (
    <Fragment>

{data?.pages?.map?.((infinitePage, i) => {
            return (
              <Fragment key={i}>
                {infinitePage?.data?.map((item, index: number) => {

                  return (
                    <tr
                    key={item.id}
                    className="transition-all delay-75 bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer "
                    >
                    <th
                      scope="row"
                      className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                    >
                      <div className="flex items-center justify-start">
                        <input
                          type="checkbox"
                          onClick={() => setCheckBoxHandler(item)}
                          className="checkbox-worker custom__checkbox"
                        />
                        <div className="flex flex-col items-start justify-center p-2"
                          role="button" onClick={() => setOpenDrawerHandler(item)}
                        >
                          <p className="mb-1 ml-1 text-lg whitespace-nowrap text-textDark dark:text-white">
                            {item.rule_name.replaceAll('_', ' ')}
                          </p>
                        </div>
                      </div>
                    </th>
                    
                    <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                      <div className="flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 96 960 960"
                          aria-hidden="true"
                          className={"h-4 w-4 text-grayColor"}
                        >
                          <path
                            fill="currentColor"
                            d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
                          ></path>
                        </svg>
                        <span className="text-lg text-textDark">Action</span>
                      </div>
                    </td>
                    
                    <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                      <div className="flex items-center gap-2">
                        <ArrowSubIcon />
                        <span className="text-lg text-textDark">{item?.actions?.length || 0} References</span>
                      </div>
                    </td>
                    
                    <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                      <div className="flex items-center gap-2">
                        <ArrowSubIcon />
                        <span className="text-lg text-textDark">
                          No Display Condition
                        </span>
                      </div>
                    </td>
                    </tr>
                  );
                })}
              <tr ref={ref}></tr>
              </Fragment>
            );
          })}
        <tr>
        <td colSpan={4} className="mx-auto text-center ">
          <p className="inline-flex text-center">
          {hasNextPage  && (<AppLoader isLoading size={30} />)}
          </p>
        </td>
				</tr>
      {/* {props.data
        ? props.data.map((item) => {
            return (
              <tr
                key={item.id}
                className="transition-all delay-75 bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer "
              >
                <th
                  scope="row"
                  className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                >
                  <div className="flex items-center justify-start">
                    <input
                      type="checkbox"
                      onClick={() => setCheckBoxHandler(item)}
                      className="checkbox-worker custom__checkbox"
                    />
                    <div className="flex flex-col items-start justify-center p-2"
                    role="button" onClick={() => setOpenDrawerHandler(item)}>
                      <p className="mb-1 ml-1 text-lg whitespace-nowrap text-textDark dark:text-white">
                        {item.rule_name.replaceAll('_', ' ')}
                      </p>
                    </div>
                  </div>
                </th>
                <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                  <div className="flex items-center gap-2">
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 9.878 9.878"
                      className={"h-3 w-3 text-grayColor"}
                    >
                      <path
                        id="icon_actions"
                        d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
                        transform="translate(-2 -2)"
                        fill="currentColor"
                      />
                    </svg>
                    <span className="text-lg text-textDark">Action</span>
                  </div>
                </td>

                <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                  <div className="flex items-center gap-2">
                    <ArrowSubIcon />
                    <span className="text-lg text-textDark">{item?.actions?.length || 0} References</span>
                  </div>
                </td>

                <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                  <div className="flex items-center gap-2">
                    <ArrowSubIcon />
                    <span className="text-lg text-textDark">
                      No Display Condition
                    </span>
                  </div>
                </td>
              </tr>
            );
          })
        : null} */}
    </Fragment>
  );
};
