/* eslint-disable jsx-a11y/heading-has-content */
import { FC } from "react";

// Each divider should have a margin top of 30px and a margin bottom of 10px

interface AppDividerProps {
  top?: number;
  bottom?: number;
 }

export const AppDivider: FC<AppDividerProps> = (props) => {
  return <hr className="border-1 h-px border-borderGray dark:border-textDark"
    style={{ marginTop: `${props.top}px`, marginBottom:`${props.bottom}px`  }}
  />
}

AppDivider.defaultProps = {
  top: 30,
  bottom: 10,
};
