import React, { FC, memo, useState } from 'react';
import Input from "../General/Form/Input";
import { PropContent, QueryModel } from "./Comp/models";
import Condition from "./Condition";

export type ContentExtraProps = PropContent & {
    setPassedContent: React.Dispatch<React.SetStateAction<{ targetField: string, targetFunction: string }>>
}

const ContentExtra: FC<ContentExtraProps> = memo(({
    register,
    errors,
    defaultValue,
    setTimeEvent,
    render,
    name,
    rule,
    setRule,
    prevTitle,
    query,
    typeOf,
    setQuery,
    setPassedContent
}) => {

    return (
        <div className="py-4 space-y-3">
            <div className="grid grid-cols-3 gap-3">
                <Input
                    defaultValue={defaultValue?.tags[0]!}
                    register={() => register("tags")}
                    name="tags"
                    placeholder="Content Tag"
                    errors={errors} />
                <Input
                    defaultValue={defaultValue?.priority}
                    register={() => register("priority", { min: 0 })}
                    name="priority"
                    placeholder="Content Priority"
                    errors={errors} type={"number"} />
            </div>
            {render}

            {
                name !== undefined ? <p>{name}</p> :
                    defaultValue ?
                        (defaultValue.cover_image !== "") ?
                            <span>{new URL(defaultValue.cover_image)?.pathname?.split('/').pop()}</span> : null : null
            }

            <Input
                defaultValue={defaultValue?.preview_title}
                register={() => register("preview_title", { maxLength: 140 })}
                name="preview_title"
                placeholder="Content Card"
                errors={errors}
            />

            {
                prevTitle && <p>{140 - prevTitle?.length} character left.</p>
            }


            {/* <Condition
                errors={errors}
                register={register}
                query={query}
                setQuery={setQuery}
                isContent
                rule={rule}
                setRule={setRule}
                setTimeEvent={setTimeEvent}
                name={name}
                typeOf={typeOf}
                setPassedContent={setPassedContent}
            /> */}

        </div>
    );
});


ContentExtra.displayName = "ContentExtra";
export default ContentExtra;
