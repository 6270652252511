import { FC, ReactNode } from "react";
import { ContentTemplateIcons, EyeIcon } from "../../Theme";
import { Link, useParams } from "react-router-dom";

interface IViewContentTemplateHeaderProps {
  title: string;
  icon?: ReactNode;
  referencesCount?: number;
}

const ViewContentTemplateHeader: FC<IViewContentTemplateHeaderProps> = (
  props
) => {
  const params = useParams<{ templateSlug: string }>();

  return (
    <div className="flex flex-col items-start justify-between p-5 bg-white border shadow-sm border-borderGray">
      <span className="pb-1 mb-4 text-sm text-grayColor">
        <Link to="/content-templates">Content</Link> &nbsp;&nbsp;/&nbsp;&nbsp;{" "}
        <Link
          to={`/content-templates/${params.templateSlug}/filterParam=allData`}
          className="capitalize"
        >
          {params.templateSlug?.replaceAll('_',' ')}
        </Link>
        &nbsp;&nbsp; /&nbsp;&nbsp; {props.title}
      </span>

      <div className="flex items-center my-1">
        {/* {props.icon} */}
        <div className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor">
          {
            ContentTemplateIcons[
              params.templateSlug as keyof typeof ContentTemplateIcons
            ]
          }
        </div>

        <div className="flex flex-col items-start justify-center p-2">
          <p className="text-xl whitespace-wrap text-textDark dark:text-white">
            {props.title}
          </p>
          <div className="flex items-center justify-center gap-3 px-2 py-1 transition-all delay-75 rounded-md hover:cursor-pointer hover:shadow-sm">
            <div className="flex items-center gap-1">
              <EyeIcon className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white" />
              <span className="text-xs text-grayColor">
                {props.referencesCount} Content References
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewContentTemplateHeader;
