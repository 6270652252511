import { FC, useContext, useEffect, useReducer, useState } from "react";
import { TabContentLayout } from "../TabContentLayout";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import UseUploadFile from "../../../../Hook/UseUploadFile";
import UseUpload from "../../../../Hook/UseUpload";
import { baseURL } from "../../../../apiQuery";
import UploadProgress from "../../../General/UploadProgress";
import { toast } from "../../../../utils/Toast";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { createContentTemplate } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { useNavigate, useParams } from "react-router-dom";
import { VideoContentCreateOrUpdateRequest, VideoContentTemplate, initialVideoContent } from "../../../../Types/ContentTemplate/Video";
import { getUploadUrl, uploadFile } from "../../../../apiQuery/Common/Upload.api";
import axios from "axios";

const NewVideo: FC = () => {
  const params = useParams<{ templateSlug: string }>()
  const navigate = useNavigate();

  const { currentOrganizationID } = useContext(UserContext);

  const [formData, updateFormData] = useReducer((prev: VideoContentTemplate, next: Partial<VideoContentTemplate>) => {
    return { ...prev, ...next }
  }, initialVideoContent)


  //=====================  Upload Cover & Upload Video  ========================
  const handleUpload = useMutation((formData: any) => uploadFile(formData), {})
  const [imageSrc, file, render, name] = UseUploadFile('Video')
  const { uploadForm, progress, isLoading } = UseUpload(`${baseURL}upload`);
  const [video, setVideo] = useState<any>(undefined)
  const [uploadedVideo, setUploadFile] = useState<File>()
  const getUpload = useMutation(() => getUploadUrl(uploadedVideo?.name || ""), {})


  const [uploadState, setUploadState] = useState({
    file,
    name,
  })

  useEffect(() => {
    if (name && file) {
      setUploadState({
        file,
        name,
      })
    }
  }, [name, file])

  // ============================ Mutation =====================================

  const createVideoContentMutation = useMutation((param: VideoContentCreateOrUpdateRequest) => createContentTemplate(param), {
    onSuccess: async (data) => {
      toast("Content created successfully", "success")
      const id = data?.data?.id
      if (id) {
        navigate(`/content-templates/${params.templateSlug}/view/${id}`)
      }
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message || "Something went wrong!"
      toast(message, "error")
    }
  })

  // ======================  Submit Video  ========================
  const submitFormHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    let coverImage = {
      link: ''
    }
    if (file !== undefined) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", file);
      const { link } = await uploadForm(formData)
      coverImage.link = link
    }

    let videoUpload = {
      link: ''
    }

    if (uploadedVideo !== undefined) {
      const { upload_link, link } = await getUpload.mutateAsync()

      videoUpload.link = link

      axios.put(upload_link, uploadedVideo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    }

    const videoContentWithImageAndVideo: VideoContentTemplate = {
      video: {
        ...formData.video,
        file: videoUpload.link,
      },
      content_metadata: {
        ...formData.content_metadata,
        cover_image: coverImage.link,
      }
    }
    const newState = [videoContentWithImageAndVideo]

    const shapeData: VideoContentCreateOrUpdateRequest = {
      content_type: EnumContentType.VideoContent,
      video: newState.map(item => {
        return {
          title: item.video.title,
          description: item.video.description,
          body: item.video.body,
          file: item.video.file,
          language: item.video.language as LanguageEnum,
        }
      }),
      content_metadata: newState.map(item => {
        return {
          cover_image: item.content_metadata.cover_image,
          preview_title: item.content_metadata.preview_title,
          tags: item.content_metadata.tags,
          organization_id: currentOrganizationID(),
          language: item.content_metadata.language as LanguageEnum,
          alt_title: item.content_metadata.alt_title,
        }
      })
    }

    await createVideoContentMutation.mutateAsync(shapeData)
  }


  const videoChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    return new Promise((resolve, reject) => {
      const file = event.currentTarget.files![0];
      setUploadFile(file)
      window.URL = window.webkitURL || window.URL;
      const reader = new FileReader();
      // If error occurs, reject the promise
      reader.onerror = () => {
        reject("Err")
      }
      reader.onload = () => {
        if (reader.result != undefined) {
          // @ts-ignore
          resolve(window.URL.revokeObjectURL(reader.result))
        } else {
          reject("Err")
        }
      }
      const rr = window.URL.createObjectURL(file)
      setVideo(rr)
    });
  }

  //================================== Render ========================================
  return <TabContentLayout>

    <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
      <h1 className="pb-8 text-xl">New Video Content Template</h1>
      <h1 className="mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{formData.video.title || ''}</h1>


      <AppInput tabIndex={-1} autoFocus placeholder={"Title"} value={formData.video.title} name='title'
        isFull label='Title' required
        onChange={
          (e) => updateFormData({ video: { ...formData.video, title: e.target.value } })
        }
      />
      <AppTextArea placeholder={"Description"} rows={4} value={formData.video.description} name='description'
        onChange={
          (e) => updateFormData({ video: { ...formData.video, description: e.target.value } })
        }
        label='Description' />
      <AppTextArea placeholder={"Body"} value={formData.video.body} rows={6} name='body'
        onChange={
          (e) => updateFormData({ video: { ...formData.video, body: e.target.value } })
        }
        label='Body' />

      {/* <AppInput placeholder={"Alternative Title"} value={formData.content_metadata.alt_title} name='alt_title' isFull
        onChange={e => {
          updateFormData({ content_metadata: { ...formData.content_metadata, alt_title: e.target.value } })
        }} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

      <AppInput placeholder={"Preview Title"} value={formData.content_metadata.preview_title} name='preview_title' isFull
        onChange={e => {
          updateFormData({ content_metadata: { ...formData.content_metadata, preview_title: e.target.value } })
        }} label='Preview Title' extendClass='my-2' maxLength={140} required />

      <AppInput placeholder={"Tag"} value={formData.content_metadata.tags[0]} name='tags' isFull
        onChange={e => {
          updateFormData({ content_metadata: { ...formData.content_metadata, tags: [e.target.value] } })
        }} label='Tag' extendClass='my-2' />

      <div className="mt-2 mb-3">
        <label htmlFor="contained-button-video-file"
          className="flex flex-col items-center justify-center p-8 text-gray-400 bg-white border-2 border-gray-300 border-dashed cursor-pointer">
          <span>Select Video</span>
          <span>(16:9 ratio, 25mb limit)</span>
        </label>

        <input onChange={videoChangeHandler}
          type="file"
          accept="video/*"
          style={{ display: 'none' }}
          id="contained-button-video-file" />
      </div>

      {formData.video.file ? (<a className="my-6 text-blue-500" href={formData.video.file}>Video Link</a>) : null}
      {uploadedVideo ? <p>{uploadedVideo.name}</p> : null}

      {formData?.content_metadata?.cover_image ?
        <div className="cover__image-container">

          <img alt="conver_image" src={formData.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
        </div> : null}
      {render}
      {uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}

      <div className='flex w-full gap-2 mt-3 mb-3'>
        <AppButton disabled={isLoading || createVideoContentMutation.isLoading} variant="primary" type="submit" extendClass="px-3">
          <div className="flex items-center justify-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 96 960 960"
              className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
            >
              <path
                fill="currentColor"
                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
            </svg>
            Create Content Template          </div>
        </AppButton>
      </div>
    </form>

  </TabContentLayout>
}

export default NewVideo;