import React, {FC, InputHTMLAttributes} from "react";
import {TextField} from "@mui/material";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    errors?: any;
    register?: any;
    extraClasses?: string;
    rows?: number
}

const TextArea: FC<InputProps> = ({
                                      register,
                                      name,
                                      errors,
                                      extraClasses,
                                      rows,
                                      ...rest
                                  }) => {
    return (
        <div>
            <TextField
                label={rest.placeholder}
                error={!!errors[name]}
                aria-invalid={!!errors[name] ? "true" : "false"}
                multiline
                fullWidth
                {...register()}
                rows={rows ? rows : 4}
                className={`appearance-none rounded-md text-gray-600 w-full bg-white leading-tight focus:outline-none ${extraClasses}`}
                {...rest}
                placeholder=""
            />
            {
                errors[name]?.type === "required" && <p className="text-red-500">{errors[name]?.message}</p>
            }
            {
                errors[name]?.type === "maxLength" && <p className="text-red-500">Max length exceeded</p>
            }
        </div>
    );
};

export default TextArea;