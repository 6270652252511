import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import {Tooltip} from "flowbite-react";
import {InfoIcon} from "../../../../../Theme";

const ConditionsTab: React.FC = () => {
  const [enabled, setEnabled] = useState(false);
  return (
    <div className="flex flex-col gap-5 mb-12">
      <div className="mb-3">
        <h2 className="text-textDark text-2xl font-normal mb-1">Set Content Condition</h2>
        <p className="text-sm text-textDark">
          Manage how and when the Content should be delivered to a Worker.
        </p>
      </div>

      <hr className="border-1 h-px border-borderGray dark:border-textDark" />

    <div className="flex flex-row">
        <button className="mr-2 flex text-left rounded-sm bg-primary px-4 py-3 text-sm text-gray-400 hover:bg-blueColor hover:text-white">Deliver Content Immediately</button>
        <button className="mr-2 flex text-left rounded-sm bg-primary px-4 py-3 text-sm text-gray-400 hover:bg-blueColor hover:text-white">Deliver Content based on Time</button>
        <button className="mr-2 flex text-left rounded-sm bg-primary px-4 py-3 text-sm text-gray-400 hover:bg-blueColor hover:text-white">Deliver Content based on an Event</button>
    </div>


      <hr className="border-1 h-px border-borderGray dark:border-textDark" />

      <div>
        <h2 className="text-xl mb-2 mt-2">Deliver Content based on Time</h2>
        <p className="text-sm text-textDark mt-2 mb-6">This option will set the Content to be delivered to the Worker when the time interval set has passed after the Worker enters this state.</p>
        <div className="mb-5 border rounded-sm border-gray-200 p-3">
          <p className="text-base text-textDark mb-1">Deliver this Content 'x' time after a Worker enters this State.</p>
        </div>
      </div>

      <hr className="border-1 h-px border-borderGray dark:border-textDark" />

      <div>
        <h2 className="text-xl mb-2 mt-2">Deliver Content based on an Event</h2>
        <p className="text-sm text-textDark mt-2 mb-6">This option will set the Content to be delivered to the Worker when the Worker has met the criteria for the Event condition. Time delays and time checks can also be added to Events.</p>
        <div className="mb-5 border rounded-sm border-gray-200 p-3">
          <p className="text-base text-textDark mb-1">rule stuff here</p>
        </div>
      </div>

    </div>
  );
};

export default ConditionsTab;
