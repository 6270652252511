import API from "../index";
import { IActionTemplateFilterResponse } from "../../Types/ActionTemplate/ActionTemplate";
import { EnumActionType } from "../../Types/ActionTemplate/ActionTemplate";



export const getActionTemplateById = async (id: number) => {
	const response = await API.get(`/admin/organizations/action-templates/${id}`)
	return response.data
}

export const createActionTemplate = async (data: any): Promise<any> => {
	const response = await API.post("/admin/organizations/action-templates/", { ...data });
	return response.data;
}

export const getAllActionTemplates = async (search:string="") => {
	// const response = await API.get("/admin/organizations/action-templates/")
	// return response.data
	const data= await API.post(`/admin/organizations/action-templates/filter?limit=100000000`,
	{
		"search": search
	})
	return data;
}

//* Get action template and filter/pagination
export const getActionTemplateFilterByActionTypePagination = async (action_type: string,pageParam:string, search:string = '') => {
	return await API.post(`/admin/organizations/action-templates/filter?limit=15${pageParam && `&next=${pageParam}`}`,
		{
			"action_type": [action_type],
			"search": search
		})
}

export const getActionTemplateFilterByActionType = async (action_type: string[], limit: number = 100, search: string = '', next: string = '', previous: string = '', pageDirection: string = '') => {

	const nextParams = next ? `&next=${next}` : ''
	const previousParams = previous ? `&previous=${previous}` : ''

	let params = ''
	if (pageDirection === 'next') {
		params = nextParams
	} else if (pageDirection === 'previous') {
		params = previousParams
	}


	return await API.post(`/admin/organizations/action-templates/filter?limit=${limit}${params}`,
		{
			"action_type": action_type,
			"search": search
		})

}
export const getActionTemplateFilter = async <T, R extends EnumActionType>
	(action_type: EnumActionType[], limit: number = 5, search: string = '', next: string = '', previous: string = '', pageDirection: string = '')
	: Promise<IActionTemplateFilterResponse> => {

	const nextParams = next ? `&next=${next}` : ''
	const previousParams = previous ? `&previous=${previous}` : ''

	let params = ''
	if (pageDirection === 'next') {
		params = nextParams
	} else if (pageDirection === 'previous') {
		params = previousParams
	}

	const response: IActionTemplateFilterResponse = await API.post(`/admin/organizations/action-templates/filter?limit=${limit}${params}`,
		{
			"action_type": action_type,
			"search": search
		})

	return response;
}


export const getActionTemplateBySlug = async (slug: string): Promise<any> => {
	const response = await API.delete(`/admin/organizations/action-templates/${slug}`);
	return response.data;
}
export const deleteActionTemplateById = async (id: number): Promise<any> => {
	const response = await API.delete(`/admin/organizations/action-templates/${id}`);
	return response.data;
}

export const updateActionTemplateById = async (id: number, data: any): Promise<any> => {
	const response = await API.put(`/admin/organizations/action-templates/${id}`, { ...data });
	return response.data;
}


export interface IAttachActionTemplate {
	// pathway_id: number;
	// state_id: number;
	template_id: number;
	action_type: EnumActionType;
	// is_template: boolean;
}

export const setATemplateToActionType = async (pathwaySlug: string, stateSlug: string, data: IAttachActionTemplate) => {
	const response = await API.post(`/admin/organizations/pathways/${pathwaySlug}/states/${stateSlug}/actions/`, { ...data });
	return response.data;
}