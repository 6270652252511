import API from "../index";
import { IContentTemplateFilterResponse } from "../../Types/ContentTemplate/ContentTemplate";
import { EnumContentType } from "../../Types/ContentTemplate/ContentType";



export const getAllContentTemplates = async () => {
	const response = await API.get("/admin/organizations/content-templates/")
	return response.data
}

// export const getContentTemplateById = async <T, R extends EnumContentType>(id: number): Promise<ISingleContentTemplateResponse<T, R>> => {
// 	const response = await API.get(`/admin/organizations/content-templates/${id}`)
// 	return response.data
// }
export const getContentTemplateById = async (id: number) => {
	const response = await API.get(`/admin/organizations/content-templates/${id}`)
	return response.data
}

export const getContentTemplateFilterByContentType = async (content_type: string[], limit: number = 100, search: string = '', next: string = '', previous: string = '', pageDirection: string = '') => {

	const nextParams = next ? `&next=${next}` : ''
	const previousParams = previous ? `&previous=${previous}` : ''

	let params = ''
	if (pageDirection === 'next') {
		params = nextParams
	} else if (pageDirection === 'previous') {
		params = previousParams
	}

	return await API.post(`/admin/organizations/content-templates/filter?limit=${limit}${params}`,
		{
			"content_type": content_type,
			"search": search
		})

}

export const getContentTemplateBySearch = async (search: string = '') => {
	return await API.post(`/admin/organizations/content-templates/filter?limit=100000`,
		{
			"search": search
		})
}


// ?limit=15&
        
export const getContentTemplateFilterByContentTypePagination = async (content_type: string,pageParam:string, search:string = '') => {
	return await API.post(`/admin/organizations/content-templates/filter?limit=20${pageParam && `&next=${pageParam}`}`,
		{
			"content_type": [content_type],
			"search": search
		})
}



export const createContentTemplate = async (data: any): Promise<any> => {
	const response = await API.post("/admin/organizations/content-templates/", { ...data });
	return response.data;
}

export const getContentTemplateFilter = async <T, R extends EnumContentType>
	(content_type: EnumContentType[], limit: number = 5, search: string = '', next: string = '', previous: string = '', pageDirection: string = '')
	: Promise<IContentTemplateFilterResponse<T, R>> => {

	const nextParams = next ? `&next=${next}` : ''
	const previousParams = previous ? `&previous=${previous}` : ''

	let params = ''
	if (pageDirection === 'next') {
		params = nextParams
	} else if (pageDirection === 'previous') {
		params = previousParams
	}

	const response: IContentTemplateFilterResponse<T, R> = await API.post(`/admin/organizations/content-templates/filter?limit=${limit}${params}`,
		{
			"content_type": content_type,
			"search": search
		})

	return response;
}



export const deleteContentTemplateById = async (content_template_id: number): Promise<any> => {
	const response = await API.delete(`/admin/organizations/content-templates/${content_template_id}`);
	return response.data;
}

export const updateContentTemplateById = async (id: number, data: any): Promise<any> => {
	const response = await API.put(`/admin/organizations/content-templates/${id}`, { ...data });
	return response.data;
}


export interface IAttachTemplate {
	pathway_id: string;
	state_id: string;
	template_id: number;
	content_type: EnumContentType;
	is_template: boolean;
}
export const setATemplateToContentType = async (pathwaySlug: string, stateSlug: string, data: IAttachTemplate) => {
	const response = await API.post(`/admin/organizations/pathways/${pathwaySlug}/states/${stateSlug}/contents/`, {
		template_id: data.template_id,
		content_type: data.content_type,
		is_template: data.is_template,
		pathway_id:1,
		state_id: 1
	});
	return response.data;
}



export const getAllPathways = async () => {
	const { data } = await API.get(`/admin/organizations/pathways/`);
	return data
}
