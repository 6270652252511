import { FC, useState } from "react";
import { updateById } from "./helper";
import { AppButton, AppIconButton } from "../../../../../Theme";
import AppInput from "../../../../Shared/AppInput/AppInput";
import { ISurvey, ISurveyItem } from "../../../../../Types/ContentTemplate/Survey";

type IInlineSurveyItemProps = {
  item: ISurveyItem
  setSurvey: any
  dragHandleProps: any
  setShowInput: any
  showInputAppId: string | null
  showInput: any
  surveys: any
}

export const InlineSurveyItem: FC<IInlineSurveyItemProps> = ({
  item,
  setSurvey,
  dragHandleProps,
  setShowInput,
  showInputAppId,
  showInput,
  surveys
}: any) => {
  
  const [inputChange, setInputChange] = useState({label: item.label, value: item.value});
  const [validation, setvalidation] = useState("");

  const resetToDefaults = () => {
    setInputChange({label: item.label, value: item.value})
      setShowInput((prev: any) => {
        return { id: null, isInput: false };
      });
    }
  
  return (
    <div className="flex items-center w-[90%] gap-2 p-2.5 m-1 bg-primary rounded shadow-sm">
      <button  {...dragHandleProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4.566 7.325"
        aria-hidden="true"
        className={
          "h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white "
        }
      >
        <path
          id="icon_drag"
          d="M14.893,15.325a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,15.325Zm2.758,0a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,17.651,15.325Zm-2.758-2.758a.879.879,0,0,1-.629-.252A.862.862,0,0,1,14,11.662a.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652A.913.913,0,0,1,14.893,12.566Zm2.758,0a.879.879,0,0,1-.629-.252.862.862,0,0,1-.263-.652.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652.913.913,0,0,1-.647.252ZM14.893,9.808a.85.85,0,0,1-.629-.269A.9.9,0,0,1,14.9,8a.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,9.808Zm2.758,0a.85.85,0,0,1-.629-.269A.9.9,0,0,1,17.662,8,.9.9,0,0,1,18.3,9.545.891.891,0,0,1,17.651,9.808Z"
          transform="translate(-14 -8)"
          fill="currentColor"
        />
      </svg>{" "}
      </button>
      <div className="flex items-center justify-between w-full gap-2">
        {showInput.isInput && showInputAppId === item.appId ? (
          <div className="flex flex-col w-full">
            <AppInput
              label="Label"
              autoFocus
              name=""
              isFull
              onChange={(e) => {
                if (e?.target?.value.length > 0) {
                  setvalidation("");
                }
                setInputChange((prevState) => ({ ...prevState, label: e?.target?.value}))
              }}
              type="text"
              extendClass="mb-2"
              value={inputChange.label}
            />
            <AppInput
              label="Value"
              autoFocus
              name=""
              isFull
              onChange={(e) => {
                if (e?.target?.value.length > 0) {
                  setvalidation("");
                }
                setInputChange((prevState) => ({ ...prevState, value: e?.target?.value}))

              }}
              type="number"
              extendClass=""
              value={inputChange?.value}
            />

            {/* {validation ? (
              <p className="text-xs text-redColor">{validation}</p>
            ) : null} */}
          </div>
        ) : (
          <p className="text-sm text-black">{item.label}</p>
        )}
        <div className="flex items-center justify-center gap-2">
          <AppIconButton
            variant="danger"
            type="button"
            onClick={() => {
              setSurvey((prev: ISurvey) => {
                const updatedItems = prev.survey_items.filter(
                  (_: any, index: any) => _.appId !== item.appId
                );
                return { ...prev, survey_items: updatedItems };
              });
            }}
          />
          {showInput.isInput && showInputAppId === item.appId ? (

            <>
            <AppIconButton
              variant="success"
              type="button"
              onClick={(e) => {
                if (inputChange.label.length < 1) {
                  setvalidation("You can't save it as the empty");
                } else {
                  setShowInput((prev: any) => {
                    return { appId: null, isInput: false };
                  });
                  setSurvey((prev: ISurvey) => {
                    const surveyItems = prev.survey_items;
                    const surveyAppId = showInputAppId;
                    const label = inputChange.label;
                    const value = Number(inputChange.value);
                    const updatedSurveyItems = surveyItems.map((surveyItem) => {
                      if (surveyItem.appId === surveyAppId) {
                        return {
                          ...surveyItem,
                          label,
                          value
                        }
                      }
                      return surveyItem
                    })
                      return { ...prev, survey_items: updatedSurveyItems }
                    });
                  }
                }}
                />
            
              <AppIconButton
                variant="default"
                type="button"
                onClick={resetToDefaults}
              />
              </>
          ) : (
            <AppIconButton
              variant="info"
              type="button"
              onClick={() => {
                setShowInput((prev: any) => {
                  return { appId: item.appId, isInput: true };
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};