import {FC, memo} from 'react'

type ButtonProps = {
    label: any;
    icon?: JSX.Element
    disabled?: boolean;
    extraClasses?: string;
    onClick?: () => void;
    type?: 'submit' | 'reset' | 'button';
}

const Button: FC<ButtonProps> = memo(({label, onClick, disabled, extraClasses, icon, type = "button"}) => {

    return (
        <button
            type={type}
            className={`btn ` + extraClasses}
            disabled={disabled}
            onClick={onClick}
        >
            {icon && icon}
            {label}
        </button>
    )
})

export default Button