/* eslint-disable react/require-default-props */
export const EditIcon = ((props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      className={`${className ?? ""}`}
    >
      <path
        fill="currentColor"
        d="M794 390L666 262l42-42q17-17 42.5-16.5T793 221l43 43q17 17 17 42t-17 42l-42 42zm-42 42L248 936H120V808l504-504 128 128z"
      ></path>
    </svg>
  );
});
