import React from "react";

const NotificationsTab: React.FC = () => {
  return (
    <div className="flex flex-col gap-5 mb-12">
      <div className="mb-3">
        <h2 className="text-textDark text-xl font-normal">Notifications</h2>
        <p className="text-sm text-textDark">
          Integrate Workday asa data source for your organization.....
        </p>
      </div>
      <hr className="border-1 h-px border-borderGray dark:border-textDark" />
      <div>
        <h2 className="text-textDark text-xl mb-3 font-normal">
          API Endpoints.....
        </h2>
      </div>
    </div>
  );
};

export default NotificationsTab;
