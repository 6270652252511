import ActivityChart from "./ActivityChart";
import ActiveUsers from "./ActiveUsers";
import StateChart from "./StateChart";
import UserLogin from "./UserLogin";
import { FC } from "react";
import { useQuery } from "react-query";
import { Box, Grid } from "@mui/material";
import { AppLoader } from "../Shared/AppLoader";
import { getAllCharts } from "../../apiQuery/Dashboard";
import { IChartResponse } from "../../Types/Dashboard/Dashboard.types";

export const DashboardPage: FC = () => {
  //Fetch Data
  const { data, isLoading } = useQuery<IChartResponse, Error>(
    "get-all-charts",
    () => getAllCharts()
  );

  // Get participants data
  const parByPathway = data?.data?.participants_by_pathway ?? [];
  const parByState = data?.data?.participants_by_state ?? [];
  const totalLogins = data?.data.seven_days.users_logged;
  const logPerDay = data?.data.seven_days.users_logged_group_by_day ?? [];
  const candPerDay = data?.data.seven_days.candidates_group_by_day ?? [];
  const empPerDay = data?.data.seven_days.employees_group_by_day ?? [];

  // Create an array of the last 7 days, including today and going back in time
  const today = new Date();
  const lastSevenDays = Array.from({ length: 7 }, (_, index) => {
    const date = new Date(today);
    date.setDate(today.getDate() - index);
    return date.toISOString().split("T")[0]; // Extract YYYY-MM-DD part
  });

  // Create an object to store the data for each day
  const logDayRecord: Record<string, number> = {};
  logPerDay?.forEach((obj) => {
    const date = obj.Log_date.split("T")[0];
    logDayRecord[date] = obj.total;
  });

  const candDayRecord: Record<string, number> = {};
  candPerDay?.forEach((obj) => {
    const date = obj.created_date.split("T")[0];
    candDayRecord[date] = obj.total;
  });

  const empDayRecord: Record<string, number> = {};
  empPerDay?.forEach((obj) => {
    const date = obj.created_date.split("T")[0];
    empDayRecord[date] = obj.total;
  });

  // Map the data to the lastSevenDays array, filling in zeros for missing data
  // Last Seven days: Logged Users Count
  const lastSevenDaysLogins: number[] = lastSevenDays.map(
    (date) => logDayRecord[date] || 0
  );

  // Last seven days: Activate Candidates Count
  const lastSevenDaysCand: number[] = lastSevenDays.map(
    (date) => candDayRecord[date] || 0
  );

  // Last seven days: Activate Employers Count
  const lastSevenDaysEmp: number[] = lastSevenDays.map(
    (date) => empDayRecord[date] || 0
  );

  // Series Data
  const activitySeries = [
    {
      name: "Candidates",
      data: lastSevenDaysCand,
    },
    {
      name: "Employees",
      data: lastSevenDaysEmp,
    },
  ];

  // Calculate the sums for each series
  const totalActivity: number = activitySeries.reduce(
    (sum, item) =>
      sum + item.data.reduce((arraySum, value) => arraySum + value, 0),
    0
  );

  // When data fetch is delayed
  if (isLoading) {
    return (
      <Box
        sx={{
          padding: "1rem",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        alignContent={"center"}
        alignItems={"center"}
      >
        <AppLoader isLoading={isLoading} size={25} />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "1rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={4} md={4}>
          <ActiveUsers
            candidates={data?.data.candidates ?? 0}
            employees={data?.data.employees ?? 0}
          />
        </Grid>
        <Grid item xs={3} sm={4} md={4}>
          <ActivityChart
            series={activitySeries}
            totalActivity={totalActivity}
          />
        </Grid>
        <Grid item xs={3} sm={4} md={4}>
          <UserLogin
            totalLogins={totalLogins ?? 0}
            data={lastSevenDaysLogins ?? []}
            name={"User Logins"}
          />
        </Grid>
        <Grid item xs={3} sm={4} md={4}>
          <StateChart
            title={"Participants By pathway"}
            data={parByPathway.map((item) => item.total_participants)}
            labels={parByPathway.map((item) => item.pathway_name)}
          />
        </Grid>
        <Grid item xs={3} sm={4} md={4}>
          <StateChart
            title={"Participants By State"}
            data={parByState.map((item) => item.total_participants)}
            labels={parByState.map((item) => item.state_name)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
