import React, {ReactElement} from 'react'

type Props = {
    title: string,
    count?:string | ReactElement
    children?:JSX.Element[] | JSX.Element | any
}

const Tab: React.FC<Props> = ({ children }) => {
    return <div>{children}</div>
}

export default Tab