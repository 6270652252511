import { memo, useEffect, useMemo, useState } from "react";
import { AppButton, AppDivider, AppSearchableDropDown, CloseIcon, TransitionAnimation } from "../../../Theme";
import AppInput from "../../Shared/AppInput/AppInput";
import AppTextArea from "../../Shared/AppTextArea/AppTextArea";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import AppDropDown from "../../Shared/AppDropDown/AppDropDown";
import { createActionRule, createContentRule, getAllRuleForAction, getAllRuleForContent, getRuleOprands } from "../../../apiQuery/Condition/Rule.apis";
import { QueryBuilderContainer, backendReplaceAllObjectKeys, deliverTimeAndUnitCalculator, formatterQuery, ruleNameFormatter } from "../../Container";
import { IOperandsResponse } from "../../../Types/Operands/Operands";
import initialQuery from "../../../utils/InitialRules";
import { IRuleResponse } from "../../../Types/Rules/Rules";
import { Field, RuleGroupType } from "react-querybuilder";
import { Single_Action_R, Single_Content_R, actions, contents, ruleBody } from "../../../Common/Types";
import { toast } from "../../../utils/Toast";
import { getContent, updateContent } from "../../../apiQuery/Contents/Content.apis";
import { useParams } from "react-router-dom";
import { deleteTimeCondition } from "../../../apiQuery/Condition/TimeBase.apis";
import { createTimeCoditionForContent as createTimeConditionForContent } from "../../../apiQuery/Condition/Content.Rules.api";
import { getAction, updateAction } from "../../../apiQuery/Actions/Actions.apis";
import { createTimeCoditionForAction } from "../../../apiQuery/Condition/Action.Rules.api";
import { renameRuleName } from "../../../utils/RuleHelper"

type EditConditionModalProps = {
  modalHandle: () => void;
  type: "Content" | "Action"
  ContentOrActionRecord: any
};


const ContentIcon = (props: { isActive: boolean }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 9.879 9.638"
    className={`h-3 w-3 ${props.isActive ? 'text-white' : 'text-grayColor'}`}
  >
    <path
      id="icon_content"
      d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
      transform="translate(-4 -4)"
      fill="currentColor"
    />
  </svg>
);

interface Options {
  value: string
  label: string
}

type TimeDeliverType = 'minute' | 'hour' | 'day'



const EditConditionModal = memo(({ modalHandle, type, ContentOrActionRecord }: EditConditionModalProps) => {
  const { t } = useTranslation();


  const params = useParams();

  const stateSlug = params.stateSlug || "";
  const pathwaySlug = params.slug || "";




  const [activeCondition, setActiveCondition] = useState<'Time' | 'Event' | 'Immediately'>('Immediately');


  //  ===================== EVENT Condtions ==============================//
  const [activeEventCondition, setActiveEventCondition] = useState<'New Rule' | 'Exists Rule'>('Exists Rule')


  // ==================================== Deliver based on time ====================================

  const [deliverBasedOnTime, setDeliverBasedOnTime] = useState<{ deliver_time: number, deliver_unit: TimeDeliverType, display_unit: TimeDeliverType }>({
    deliver_time: 0,
    deliver_unit: "minute",
    display_unit: "minute"
  })

  // =======================================  Deliver based on the Event : Time Delay, Time Offest.  =================================

  // Deliver based on the Event : Time Delay, Time Offest.
  const [deliverBasedOnEventActiveCondition, setDeliverBasedOnEventActiveCondition] = useState<'Add Time Delay' | 'Add Time Offset'>('Add Time Delay')


  const [deliver, setDeliver] = useState<{
    deliver_time: number
    deliver_unit: TimeDeliverType
    display_unit: TimeDeliverType
    type: 'Add Time Delay' | 'Add Time Offset'
  }>({
    deliver_time: 0,
    deliver_unit: 'minute',
    display_unit: 'minute',
    type: 'Add Time Delay',
  })

  const [condtion, setCondition] = useState({
    name: '',
    description: ''
  })

  // ==============================


  const contentRules = useQuery<IRuleResponse, Error>(
    ["all-content-rules", 1],
    () => getAllRuleForContent(1),
    {
      enabled: type === 'Content'
    }
  );




  const singleContent = useQuery<Single_Content_R, Error>(['content', ContentOrActionRecord.slug || ''], () => getContent(pathwaySlug, stateSlug, ContentOrActionRecord.slug), {
    enabled: type === 'Content',
    onSuccess: (data) => {
      const content = data.data.content
      const rule = data.data.rule
      // rules_content_id =>  event
      if (content.rules_content_id) {
        setActiveCondition('Event')
        setSelectedRule(content.rules_content_id.toString())
      }

      // is_time_base && time_condition => time
      if (content.is_time_base && content.time_condition) {
        setActiveCondition('Time')
        setDeliverBasedOnTime({
          deliver_time: Number(content.time_condition[0].display_unit === 'day' ? content.time_condition[0].deliver_time / 24 : content.time_condition[0].deliver_time),
          deliver_unit: content.time_condition[0].deliver_unit as TimeDeliverType,
          display_unit: content.time_condition[0].display_unit as TimeDeliverType
        })
      }

      // LEGACY: Added for legacy data shape on the backend
      if (content.is_time_base && !content.time_condition && rule.deliver_time !== undefined && rule.deliver_unit !== undefined) {
        setActiveCondition('Time')
        setDeliverBasedOnTime({
          deliver_time: Number(rule.deliver_time),
          deliver_unit: rule.deliver_unit as TimeDeliverType,
          display_unit: rule.display_unit as TimeDeliverType
        })
      }

    }
  },)


  const singleAction = useQuery<Single_Action_R, Error>(['action', ContentOrActionRecord.slug || ''], () => getAction(1, pathwaySlug, stateSlug, ContentOrActionRecord.slug), {
    enabled: type === 'Action',
    onSuccess: (data) => {
      const action = data.data.action



      // rules_action_id =>  event
      if (action.rules_action_id) {
        setActiveCondition('Event')
        setSelectedRule(action.rules_action_id.toString())
      }

      // is_time_base && time_condition => time
      if (action.is_time_base && action.time_condition) {
        setActiveCondition('Time')
        setDeliverBasedOnTime({
          deliver_time: Number(action.time_condition[0].display_unit === 'day' ? action.time_condition[0].deliver_time / 24 : action.time_condition[0].deliver_time),
          deliver_unit: action.time_condition[0].deliver_unit as TimeDeliverType,
          display_unit: action.time_condition[0].display_unit as TimeDeliverType
        })
      }


    }
  })

  const contentRuleDropDownSearch: Options[] | null = useMemo(() => {
    if (contentRules?.data?.data) {
      const list: Options[] = contentRules.data.data.map(item => {
        return {
          label: item.rule_name.replaceAll('_', ' '),
          value: item.id.toString()
        }
      })
      return list
    }
    return null
  }, [contentRules])


  // =========================== Actions

  const actionsRules = useQuery<IRuleResponse, Error>(
    ["all-action-rules", 1],
    () => getAllRuleForAction(1),
    {
      enabled: type === 'Action'
    }
  );

  const actionRuleDropDownSearch: Options[] | null = useMemo(() => {
    if (actionsRules?.data?.data) {
      const list: Options[] = actionsRules.data.data.map(item => {
        return {
          label: item.rule_name.replaceAll('_', ' '),
          value: item.id.toString()
        }
      })
      return list
    }
    return null
  }, [actionsRules])




  const [selectedRule, setSelectedRule] = useState<string>('')

  const [json, setJson] = useState<any>({
    deliver_time: 0,
    deliver_unit: "minute",
    rules: []
  })


  useEffect(() => {
    if (selectedRule) {

      let contentOrActionRule: any
      if (type === "Content") {
        contentOrActionRule = contentRules.data?.data.find(item => item.id === parseInt(selectedRule))
      }
      if (type === "Action") {

        contentOrActionRule = actionsRules.data?.data.find(item => item.id === parseInt(selectedRule))
      }


      const datas = [] as any[]

      if (contentOrActionRule) {




        const whenClause = contentOrActionRule?.rule_json[0].when


        const com = Object.keys(whenClause)[0]

        if (whenClause?.[com][0]?.obj) {
          datas.push({
            operator: undefined,
            field: whenClause?.[com][0]?.obj,
            value: whenClause?.[com][1]?.const !== undefined ? whenClause?.[com][1]?.const : whenClause?.[com][1]
          })
        } else {
          whenClause?.[com]?.map((item: any) => {

            const ss = Object.keys(item)[0]

            datas.push({
              operator: ss,
              field: item[ss][0]?.obj,
              value: item[ss][1]?.const ? item[ss][1]?.const : item[ss][1]
            })
          })
        }

        const obj = {
          combinator: Object.keys(whenClause)[0],
          rules: datas
        }

        setJson(() => {
          return {
            deliver_time: contentOrActionRule?.deliver_time || 0,
            deliver_unit: contentOrActionRule?.deliver_unit || 'minute',
            rules: obj.rules,
            combinator: obj.combinator
          }
        })

      }

    }
  }, [actionsRules.data?.data, contentRules.data?.data, selectedRule, type])





  const createAContentRule = useMutation((ruleBody: ruleBody) => createContentRule(ruleBody), {
    onError: (error: any) => {

      toast(error.response.data.message, 'error')
    }
  })

  const createAActionRule = useMutation((ruleBody: ruleBody) => createActionRule(ruleBody), {
    onError: (error: any) => {

      toast(error.response.data.message, 'error')
    }
  })

  // ==================================================================


  const [query, setQuery] = useState<RuleGroupType>(initialQuery);

  const {
    isLoading,
    data: operands,
    error,
  } = useQuery<IOperandsResponse, Error>("operands", getRuleOprands);



  //  @ts-ignore
  const fields: Field[] = useMemo(() => {
    return operands?.data?.map((op) => {
      return {
        name: op.value,
        label: op.display,
        valueEditorType: op.data_type === "bool" ? "checkbox" : "text",
        inputType: op.display.includes("Date") ? "date" : "text",
        operators: [
          { name: "=", label: "=" },
          { name: "!=", label: "!=" },
        ],
      };
    });
  }, [operands?.data]);



  const saveDeliverContentOrActionImmediatly = async () => {
    // delete time_base condition by API call | delete event => rule_content_id to NULL by updating content
    if (type === "Content") {
      const content = singleContent?.data?.data.content as contents

      // rules_content_id =>  event
      if (content.rules_content_id) {
        content.rules_content_id = null
        await updateContent(1, pathwaySlug, stateSlug, content, content.slug as string)
      }

      // is_time_base && time_condition => time
      if (content.is_time_base && content.time_condition) {
        await deleteTimeCondition(1, content.time_condition[0].id as number)
      }
    }

    if (type === 'Action') {
      const action = singleAction?.data?.data.action as actions

      // rules_content_id =>  event
      if (action.rules_action_id) {
        action.rules_action_id = null
        await updateAction(action, 1, pathwaySlug, stateSlug, action.slug as string)
      }
      // is_time_base && time_condition => time
      if (action.is_time_base && action.time_condition) {
        await deleteTimeCondition(1, action.time_condition[0].id as number)
      }
    }

    const toastMessage = `Deliver ${type} immediately has been applied.`
    toast(toastMessage, 'success', 4000)
    modalHandle()
  }

  const saveDeliverBasedOnTime = async () => {
    // if it have the rules_content_id and remove it 
    if (type === "Content") {
      const content = singleContent?.data?.data.content as contents

      if (content.rules_content_id) {
        content.rules_content_id = null
        await updateContent(1, pathwaySlug, stateSlug, content, content.slug as string)
      }


      // if the condition was applied before delete it.
      if (content.is_time_base && content.time_condition) {
        await deleteTimeCondition(1, content.time_condition[0].id as number)
      }
      // create a new time condition
      let deliver_time = deliverBasedOnTime.display_unit === "day" ? deliverBasedOnTime.deliver_time * 24 : deliverBasedOnTime.deliver_time
      let deliver_unit = (deliverBasedOnTime.display_unit === "day" ? 'hour' : deliverBasedOnTime.deliver_unit) as string
      let display_unit = deliverBasedOnTime.display_unit as TimeDeliverType

      await createTimeConditionForContent({
        state_id: content.state_id as number,
        content_id: content.id as number,
        deliver_time,
        deliver_unit,
        display_unit
      })
    }

    if (type === "Action") {
      const action = singleAction?.data?.data.action as actions

      if (action.rules_action_id) {

        action.rules_action_id = null
        await updateAction(action, 1, pathwaySlug, stateSlug, action.slug as string)
      }

      // if the condition was applied before delete it.
      if (action.is_time_base && action.time_condition) {
        await deleteTimeCondition(1, action.time_condition[0].id as number)
      }
      // create a new time condition
      let deliver_time = deliverBasedOnTime.display_unit === "day" ? deliverBasedOnTime.deliver_time * 24 : deliverBasedOnTime.deliver_time
      let deliver_unit = (deliverBasedOnTime.display_unit === "day" ? 'hour' : deliverBasedOnTime.display_unit) as string

      await createTimeCoditionForAction({
        state_id: action.state_id as number,
        action_id: action.id as number,
        deliver_time,
        deliver_unit,
        display_unit: deliverBasedOnTime.display_unit as TimeDeliverType
      })
    }


    const toastMessage = `Deliver ${type} based on time has been applied.`.toLowerCase()
    toast(toastMessage, 'success', 4000)

    modalHandle()

  }

  const saveDeliverBasedOnAnEvent = async () => {

    if (type === "Content") {

      const content = singleContent?.data?.data.content as contents
      // is_time_base && time_condition => time
      if (content.is_time_base && content.time_condition) {
        await deleteTimeCondition(1, content.time_condition[0].id as number)
      }

      // update existing rule.
      if (activeEventCondition === "Exists Rule") {

        if (content.rules_content_id !== Number(selectedRule)) {
          content.rules_content_id = Number(selectedRule)
          await updateContent(1, pathwaySlug, stateSlug, content, content.slug as string)
        }

      }

      // create a new rule for with the rule builder.

      if (activeEventCondition === 'New Rule') {

        if (query.rules.length === 0) {
          toast('There are not any rule created!', 'info')
          return
        }

        const conditionName = condtion.name
        const conditionDescription = condtion.description

        const formattedQuery = await formatterQuery(query)
        const preparedForBackend = await backendReplaceAllObjectKeys(formattedQuery)



        
        console.log('formattedQuery', formattedQuery)
        


        console.log('preparedForBackend', preparedForBackend)
       

        const createdContentRuleMutation = createAContentRule.mutateAsync({
          deliver_time: deliver.deliver_time,
          deliver_unit: deliver.deliver_unit,
          rule_json: {
            when: preparedForBackend,
            desc: conditionDescription,
            name: renameRuleName(conditionName),
            salience: 0
          }
        })
        // createAContentRule.data

        const response = await createdContentRuleMutation
        const ruleId = await response?.data?.id

        content.rules_content_id = Number(ruleId)
        await updateContent(1, pathwaySlug, stateSlug, content, content.slug as string)

      }

    }




    if (type === "Action") {

      const action = singleAction?.data?.data.action as actions
      // is_time_base && time_condition => time
      if (action.is_time_base && action.time_condition) {
        await deleteTimeCondition(1, action.time_condition[0].id as number)
      }

      // update existing rule.
      if (activeEventCondition === "Exists Rule") {

        if (action.rules_action_id !== Number(selectedRule)) {
          action.rules_action_id = Number(selectedRule)
          await updateAction(action, 1, pathwaySlug, stateSlug, action.slug as string)
        }

      }

      // create a new rule for with the rule builder.

      if (activeEventCondition === 'New Rule') {

        if (query.rules.length === 0) {
          toast('There are not any rule created!', 'warning', 3000)
          return
        }

        const conditionName = condtion.name
        const conditionDescription = condtion.description

        const formattedQuery = await formatterQuery(query)
        const preparedForBackend = await backendReplaceAllObjectKeys(formattedQuery)

        const createdActionRuleMutation = createAActionRule.mutateAsync({
          deliver_time: deliver.deliver_time,
          deliver_unit: deliver.deliver_unit,
          rule_json: {
            when: preparedForBackend,
            desc: conditionDescription,
            name: renameRuleName(conditionName),
            salience: 0
          }
        })
        // createAContentRule.data

        const response = await createdActionRuleMutation
        const ruleId = await response?.data?.id

        action.rules_action_id = Number(ruleId)
        await updateAction(action, 1, pathwaySlug, stateSlug, action.slug as string)
      }

    }

    const toastMessage = `Deliver ${type} based on an event has been applied.`
    toast(toastMessage, 'success', 4000)
    modalHandle()
  }


  const save = async () => {
    if (activeCondition === 'Immediately') {
      await saveDeliverContentOrActionImmediatly()
    }

    if (activeCondition === 'Time') {
      await saveDeliverBasedOnTime()
    }

    if (activeCondition === 'Event') {
      await saveDeliverBasedOnAnEvent()
    }
  }


  return (
    <div className="relative z-50 flex min-w-[70%] h-full mt-5 overflow-auto bg-white rounded-md shadow-md text-textDark edit-condition-modal">
      <div className="flex flex-col w-full p-5">
        <div className="flex justify-between">
          <div className="flex flex-col gap-5 mb-2">
            <div className="mb-3">
              <h2 className="text-2xl font-normal text-textDark">Edit {type} Condition</h2>
              <p className="text-sm text-textGray">Manage how and when the {type} should be delivered to a Worker.</p>
            </div>
          </div>
          <button
            type="button"
            onClick={modalHandle}
            className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <CloseIcon className="w-4 h-4" />
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        <AppDivider top={10} />
        <div className="flex items-center gap-2 mt-5">
          <AppButton variant={activeCondition === "Immediately" ? 'info' : 'primary'} type="button" size="lg" onClick={() => {
            setActiveCondition('Immediately')
            setDeliverBasedOnTime({
              deliver_time: 0,
              deliver_unit: 'minute',
              display_unit: 'minute'
            })
          }}>
            <div className="flex items-center gap-2"><ContentIcon isActive={activeCondition === 'Immediately'} />Deliver {type} immediately</div>
          </AppButton>
          <AppButton variant={activeCondition === "Time" ? 'info' : 'primary'} type="button" size="lg" onClick={() => setActiveCondition('Time')}>
            <div className="flex items-center gap-2"><ContentIcon isActive={activeCondition === 'Time'} />Deliver {type} based on Time</div>
          </AppButton>
          <AppButton variant={activeCondition === "Event" ? 'info' : 'primary'} type="button" size="lg" onClick={() => {
            setActiveCondition('Event')
            setDeliverBasedOnTime({
              deliver_time: 0,
              deliver_unit: 'minute',
              display_unit: 'minute'
            })
          }}>
            <div className="flex items-center gap-2"><ContentIcon isActive={activeCondition === 'Event'} />Deliver {type} based on an Event</div>
          </AppButton>
        </div>
        <AppDivider bottom={30} />
        <div className={`flex gap-2 mb-1 ${activeCondition !== 'Time' ? 'opacity-60 cursor-not-allowed' : ''}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={`text-grayColor h-6 w-6`}
            viewBox="0 0 8.582 10"
          >
            <path
              fill="currentColor"
              d="M8.861 2.715V2h2.861v.715zm1.072 5.328h.715V5.3h-.715zM10.291 12a4.122 4.122 0 01-1.663-.34A4.354 4.354 0 016.34 9.372a4.239 4.239 0 010-3.325 4.354 4.354 0 012.288-2.289 4.122 4.122 0 011.663-.34 4.215 4.215 0 011.5.262 4.058 4.058 0 011.246.739l.608-.608.513.513-.608.608a4.4 4.4 0 01.733 1.156 3.948 3.948 0 01.3 1.621 4.122 4.122 0 01-.34 1.663 4.354 4.354 0 01-2.288 2.288 4.122 4.122 0 01-1.664.34z"
              transform="translate(-6 -2)"
            />
          </svg>

          <div className="flex flex-col">
            <h3 className="mb-1 text-darkColor">Deliver {type} based on the Time</h3>
            <p className="text-sm text-textGray">This option will set the {type} to be delivered to the Worker when the time interval set has passed after the Worker enters the state.</p>
          </div>
        </div>

        <div className={`flex items-center gap-4 p-2 mx-6 my-2 bg-white border rounded-md ${activeCondition !== 'Time' ? 'opacity-60 cursor-not-allowed' : ''}`}>
          <p className="text-sm text-textDark">Deliver this {type}</p>
          <AppInput type="number" disabled={activeCondition !== 'Time'} min={0} placeholder={""} value={deliverBasedOnTime.display_unit === 'day' ? String(deliverBasedOnTime.deliver_time) : deliverBasedOnTime.deliver_time.toString()} name='time' customWidth="w-[10rem]"
            onChange={e => {
              setDeliverBasedOnTime(prev => {
                return {
                  ...prev,
                  deliver_time: Number(e.target.value)
                }
              })
            }} label='' extendClass='my-2' />

          <AppDropDown
            disabled={activeCondition !== 'Time'}
            extendClass="w-32"

            label=""
            name="time"
            options={[
              { label: "Minutes", value: "minute" },
              { label: "Hours", value: "hour" },
              { label: "Days", value: "day" },
            ]}
            value={deliverBasedOnTime.display_unit}
            onChange={(e) => {
              setDeliverBasedOnTime((prev) => {
                return {
                  ...prev,
                  deliver_unit: e.target.value as TimeDeliverType,
                  display_unit: e.target.value as TimeDeliverType,
                };
              });
            }}
          />
          <p className="text-sm text-textDark">after a Worker enters this State.</p>
        </div>


        <AppDivider bottom={30} />

        <div className="flex gap-2 mb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={`text-grayColor h-6 w-6`}
            viewBox="0 0 8.582 10"
          >
            <path
              fill="currentColor"
              d="M8.861 2.715V2h2.861v.715zm1.072 5.328h.715V5.3h-.715zM10.291 12a4.122 4.122 0 01-1.663-.34A4.354 4.354 0 016.34 9.372a4.239 4.239 0 010-3.325 4.354 4.354 0 012.288-2.289 4.122 4.122 0 011.663-.34 4.215 4.215 0 011.5.262 4.058 4.058 0 011.246.739l.608-.608.513.513-.608.608a4.4 4.4 0 01.733 1.156 3.948 3.948 0 01.3 1.621 4.122 4.122 0 01-.34 1.663 4.354 4.354 0 01-2.288 2.288 4.122 4.122 0 01-1.664.34z"
              transform="translate(-6 -2)"
            />
          </svg>

          <div className={`flex flex-col ${activeCondition !== 'Event' ? 'opacity-60 cursor-not-allowed' : ''}`}>
            <h3 className="mb-1 text-darkColor">Deliver {type} based on an Event</h3>
            <p className="text-sm text-textGray">
              This option will set the {type} to be delivered to the Worker when the Worker has met the criteria for the Event condition. Time delays and time checks can also be added to Events.
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 p-4">
          <AppButton disabled={activeCondition !== 'Event'} variant={activeEventCondition === "Exists Rule" ? 'info' : 'primary'} type="button" size="xs"
            onClick={() => setActiveEventCondition('Exists Rule')}>
            <div className="flex items-center gap-2"><ContentIcon isActive={activeEventCondition === 'Exists Rule'} />Add Existing Rule</div>
          </AppButton>
          <AppButton disabled={activeCondition !== 'Event'} variant={activeEventCondition === "New Rule" ? 'info' : 'primary'} type="button" size="xs"
            onClick={() => setActiveEventCondition('New Rule')}>
            <div className="flex items-center gap-2"><ContentIcon isActive={activeEventCondition === 'New Rule'} />Create New Rule</div>
          </AppButton>
        </div>
        {/* ============================== */}


        {
          activeEventCondition === 'New Rule' ? (<TransitionAnimation show position="y-axis">


            <div>
              <div className={`p-4 ${activeCondition !== 'Event' ? 'opacity-60 cursor-not-allowed' : ''}`}>
                <p className="mb-2 text-sm text-textGray">Condition details</p>
                <div className="flex flex-col gap-1">
                  <div className="flex items-center ">
                    <span className="w-60">Condition name</span>
                    <AppInput disabled={activeCondition !== 'Event'} isFull type="text" placeholder={""} value={condtion.name} name='name'
                      onChange={e => {
                        setCondition(prev => {
                          return {
                            ...prev,
                            name: e.target.value
                          }
                        })
                      }} label='' extendClass='my-2' />
                  </div>

                  <div className="flex items-center ">
                    <span className="w-60">Condition description</span>
                    <AppTextArea disabled={activeCondition !== 'Event'} placeholder={""} rows={4} value={condtion.description} name='description'
                      onChange={
                        (e) => {
                          setCondition(prev => {
                            return {
                              ...prev,
                              description: e.target.value
                            }
                          })
                        }
                      } isFull />
                  </div>
                </div>
              </div>
              <hr className="w-[98%] self-center border border-gray-300 border-dashed " />

              <div className="p-4">
                <p className="mb-2 text-sm text-textGray">Condition Criteria</p>
              </div>


              <div className={`mb-2 ml-24 w-[89%] ${activeCondition !== 'Event' ? 'opacity-60 cursor-not-allowed pointer-events-none' : ''}`}>
                <QueryBuilderContainer
                  fields={fields}
                  query={query}
                  setQuery={(q) => setQuery(q)}
                />
              </div>




              <hr className="w-[98%] self-center border border-gray-300 border-dashed " />

              <div className={`p-4`}>
                <p className="mb-2 text-sm text-textGray">Condition Time Delay, Time Offest.</p>
                <div className="flex items-center gap-2">
                  <AppButton variant={deliverBasedOnEventActiveCondition === "Add Time Delay" ? 'info' : 'primary'} type="button" size="xs"
                    onClick={() => {
                      setDeliverBasedOnEventActiveCondition('Add Time Delay')
                      setDeliver(prev => {
                        return {
                          deliver_time: 0,
                          deliver_unit: 'minute',
                          display_unit: 'minute',
                          type: 'Add Time Delay'
                        }
                      })
                    }}>
                    <div className="flex items-center gap-2"><ContentIcon isActive={deliverBasedOnEventActiveCondition === 'Add Time Delay'} />Add Time Delay</div>
                  </AppButton>
                  <AppButton variant={deliverBasedOnEventActiveCondition === "Add Time Offset" ? 'info' : 'primary'} type="button" size="xs"
                    onClick={() => {
                      setDeliverBasedOnEventActiveCondition('Add Time Offset')
                      setDeliver(prev => {
                        return {
                          deliver_time: 0,
                          deliver_unit: 'minute',
                          display_unit: 'minute',
                          type: 'Add Time Offset'
                        }
                      })
                    }}>
                    <div className="flex items-center gap-2"><ContentIcon isActive={deliverBasedOnEventActiveCondition === 'Add Time Offset'} />Add Time Offest</div>
                  </AppButton>
                </div>
                <div className="flex flex-col p-2 my-2 bg-white border rounded-md">
                  <div className="flex items-center gap-4 ">
                    <p className="text-sm text-textDark">

                      {
                        deliverBasedOnEventActiveCondition === 'Add Time Delay' ? `Deliver this ${type}` : 'Deliver this Content only if a Worker has met the criteria for this condition'
                      }
                    </p>

                    <AppInput type="number"
                      min={0} placeholder={""} value={deliver.deliver_time.toString()} name='time' customWidth="w-[10rem]"
                      onChange={e => {
                        setDeliver(prev => {
                          return {
                            ...prev,
                            deliver_time: Number(e.target.value)
                          }
                        })
                      }} label='' extendClass='my-2 w-32' />

                    <AppDropDown
                      extendClass="w-32"
                      label=""
                      name="time"
                      options={[
                        { label: "Minutes", value: "minute" },
                        { label: "Hours", value: "hour" },
                        { label: "Days", value: "day" },
                      ]}
                      value={deliver.deliver_unit}
                      onChange={(e) => {
                        setDeliver((prev) => {
                          return {
                            ...prev,
                            deliver_unit: e.target.value as TimeDeliverType,
                          };
                        });
                      }}
                    />
                    <p className="text-sm text-textDark">

                      {
                        deliverBasedOnEventActiveCondition === 'Add Time Delay' ? 'from the time the Worker has met the criteria for this condition.' :
                          'after entering this state.'
                      }
                    </p>
                  </div>
                  {
                    deliverBasedOnEventActiveCondition === 'Add Time Offset' ? (
                      <p className="text-xs text-textDark">When a time offset is used, the system will only check if the Worker meets the criteria for the condition once at the time interval set from when the Worker entered this State.</p>
                    ) : null
                  }
                </div>

              </div>

            </div>

          </TransitionAnimation>) : (
            <>
              <div className="p-4">
                <AppSearchableDropDown
                  disabled={activeCondition !== 'Event'}
                  setSelectedItem={setSelectedRule}
                  label={`Search ${type} Rules`}
                  inputSearchPlaceholder={`Search Between ${type} Rules`}
                  isFull
                  defaultValue={selectedRule}
                  options={
                    type === 'Content' ? contentRuleDropDownSearch || [] : actionRuleDropDownSearch || []
                  }
                />

                <TransitionAnimation show={selectedRule && json.rules ? true : false} position="y-axis">
                  {
                    selectedRule && json.rules ? (<div className="p-2 my-3 text-sm border border-gray-300 rounded-sm shadow-sm bg-primary">

                      {/* has a time offset of */}
                      This condition has a time delay of {deliverTimeAndUnitCalculator(json?.deliver_time, json?.deliver_unit)}
                      {' '}and is delivered when
                      <br />

                      {json.rules.map((item: any, index: number) =>
                        <div
                          className="inline"
                          key={index}>
                          {' '} <strong>{ruleNameFormatter(item.field)}</strong> {' '}

                          {item.operator === "eq" ? "=" : item.operator === "not" ? "!=" : json.combinator === "eq" ? "=" : json.combinator === "not" ? "!=" : null}
                          &nbsp;{String(item.value)}&nbsp;&nbsp;{json.rules.length - 1 !== index ? json?.combinator?.toUpperCase() : null}&nbsp;&nbsp;</div>
                      )}

                    </div>) : null
                  }
                </TransitionAnimation>
              </div>
            </>)
        }

        {/* ============================== */}


        <AppDivider />

        <div className="py-4 ">
          <AppButton
            variant="primary"
            type="submit"
            extendClass="px-3"
            size="lg"
            onClick={save}
          >
            <div className="flex items-center justify-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
              >
                <path
                  fill="currentColor"
                  d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"
                ></path>
              </svg>
              Save and Apply
            </div>
          </AppButton>
        </div>
      </div>
    </div>
  );
});

EditConditionModal.displayName = "EditConditionModal"
export default EditConditionModal;