import axios from 'axios'
import { getToken, removeOrganization, removeToken } from '../utils/LocalStorage'

const localEnv = process.env.NODE_ENV === 'development' ? '&callback=local' : ''

export const authenticate = (code: string, state: string) => {


    const backendUrl = process.env.REACT_APP_API_URL + "organization/auth?"
    const reqUrl = backendUrl + `code=${code}&state=${state}${localEnv}`

    return new Promise((resolve, reject) => {
        axios.post(reqUrl)
            .then((response: any) => {
                const { data } = response
                if (data?.token) {
                    resolve(data.token)
                }

            }).catch((error: any) => {
                const statusCode = error.response.status
                if (statusCode === 401) {
                    reject(statusCode)
                }
            })

    })
}



export const validateAuthentication = () => {
    const reqUrl = process.env.REACT_APP_API_URL + "organization/logged"
    return new Promise((resolve, reject) => {
        const token = getToken()
        const config = { headers: { Authorization: `Bearer ${token}` } };
        axios.get(reqUrl, config).then((response: any) => {
            const { data } = response;
            resolve(data)
        }).catch((error: any) => {
            reject(error.response.status)
        })
    })
}




// 'Access-Control-Allow-Origin': '*',    
export const logoutFromServer = () => {
    const reqUrl = process.env.REACT_APP_API_URL + "organization/logout"
    // const reqUrl = 'https://cravetydev-admin.us.auth0.com/v2/logout?client_id=YQJAvQHZc84nWXPXEaYW4ALmA6zjA7Es'
    const config = {
        // withCredentials: true,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": 'true',
            Authorization: `Bearer ${getToken()}`
        }
    };
    return new Promise((resovle, reject) => {
        axios.get(reqUrl, config).then((response) => {
       
            if (response.status === 200) {
                resovle(response)
            }
        }).catch((error) => {
            reject(error)
        })
    })

}