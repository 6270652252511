import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    Conditions_T,
    contents, faqQuestions,
    ruleIds,
    Single_Content_Single,
    state_Edit_Survey2Value, state_Edit_survey_sequence,
    survey, survey_items, survey_sequence, surveys
} from "../../../Common/Types";
import { useMutation, useQueryClient } from "react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import UseUpload from "../../../Hook/UseUpload";
import { baseURL } from "../../../apiQuery";
import initialQuery from "../../../utils/InitialRules";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../../../GlobalState/store";
import UseUploadFile from "../../../Hook/UseUploadFile";
import { createTimeBaseEvent, deleteTimeCondition, updateTimeCondition } from "../../../apiQuery/Condition/TimeBase.apis";
// import { setSnackbar } from "../../../GlobalState/store.actions";
import { createContentRule } from "../../../apiQuery/Condition/Rule.apis";
import jsonHandler from "../../Utils/JsonHandler";
import {
    createFaqQuestion, createSurveyInSurveySeq,
    createSurveyItem, deleteFaqQuestion, deleteSurvey,
    updateSurvey2,
    updateSurveyItems,
    updateSurveySeq
} from "../../../apiQuery/Contents/ContentType.apis";
import UseTab from "../../../Hook/UseTab";
import { createContentInState, updateContent } from "../../../apiQuery/Contents/Content.apis";
import Tabs from "../../Tabs/Tabs";
import Tab from "../../Tabs/Tab";
import { BsFillEyeFill } from "react-icons/bs";
import { Fade, TextField } from "@mui/material";
import Input from "../../General/Form/Input";
import TextArea from "../../General/Form/TextArea";
import NestedContentListItem from "../NestedContentListItem";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete, AiFillFile } from "react-icons/ai";
import Button from "../../General/Button";
import UploadProgress from "../../General/UploadProgress";
import ContentExtra from "../../Event/ContentExtra";
import { OverlayModal2 } from "../../Layouts/OverlayModal";
import Mobile from "../ContentPreview/Mobile";
import PreviewContentModal from "../ContentPreview/PreviewContentModal";
import ContentTypeCardPreview from "../ContentPreview/ContentTypeCardPreview";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { reorder } from "../../Utils/Reorder";
import { GrDrag } from "react-icons/gr";
import { getDifference } from "../../../utils/General";
import { toast } from "../../../utils/Toast";
import { useTranslation } from 'react-i18next';
import { EnumContentType } from '../../../Types/ContentTemplate/ContentType';
import { ISurveySequence } from '../../../Types/ContentTemplate/SurveySequence';
import { getStateContentTemplateFilter } from '../../../apiQuery/ContentTemplate/StateContentTemplate.apis';
import AppButton from '../../Shared/AppButton/AppButton';
import { AppLoader } from '../../Shared/AppLoader/AppLoader';
import AppInput from '../../Shared/AppInput/AppInput';
import moment from "moment";
// import Condition from '../../Event/Condition';
// import { IAttachTemplate, setATemplateToContentType } from '../../../apiQuery/ContentTemplate/ContentTemplate.apis';
import { CustomClipboard, AppButton as AppThemeButton } from '../../../Theme';
import { setAddContentTemplateModal } from '../../../GlobalState/store.actions';
import { LanguageEnum } from '../../../Types/ContentTemplate/Langauge';
import { SurveySequence as SurveySequenceMultiLanguage  } from '../../../@Core';
import { SurveySequenceJSONB } from '../../../Types/JsonB/jsonb.types';


type updateSurvey = {
    id?: number
    Survey_id: number
    label: string
    order?: number
    altTitle: string
}

const SurveySequence: FC<state_Edit_survey_sequence> = memo(({
    onClose,
    defaultValue,
    type,
    onSuccess
}) => {


    const navigate = useNavigate();

    const queryClient = useQueryClient()
    const { t, i18n } = useTranslation();
    const [altTitle, setAltTitle] = useState<string>("")

    const { register, handleSubmit, watch, getValues, trigger, setValue, formState: { errors } } = useForm<survey_sequence>({
        defaultValues: defaultValue ? {
            ...defaultValue?.survey_sequence
        } : { surveys: [{ title: '', description: '', alt_title: '', survey_items: [{ label: '', value: 1, alt_title: '' }], isCollapsed: false }] }
    });

    const { uploadForm, progress, isLoading } = UseUpload(
        `${baseURL}upload`
    );

    const [timeEvent, setTimeEvent] = useState({
        isAdded: false,
        isRuleAdded: false,
    })

    //@ts-ignore
    const timeValue = parseInt(getValues("timeValue")) as number

    //@ts-ignore
    const eventName = getValues("eventName") as string

    //@ts-ignore
    const typeOf = getValues("typeOf") as string

    //@ts-ignore
    const checkInValue = getValues("checkInValue") as string

    //@ts-ignore
    const target_func = getValues("target_func") || undefined as string

    const [query, setQuery] = useState(initialQuery);

    const params = useParams();
    const id = params.stateSlug || ""
    const pathwaySlug = params.slug || ""

    const {
        state,
        dispatch
    } = useContext(store);
    const organization_id = useMemo(() => state.organization_id, [state.organization_id])

    const [imageSrc, file, render, name] = UseUploadFile('Survey')

    const [modal, setModal] = useState({
        pathwayView: false,
        contentView: false
    })

    const [ruleId, setRuleId] = useState<ruleIds>({
        event: undefined,
        time: undefined
    });

    const [passedContent, setPassedContent] = useState({
        targetField: '',
        targetFunction: '',
    })
    // mutations


    // mutaitons
    // =====================================================================================================
    const [previousPage, setPreviousPage] = useState<string>();
    const [nextPage, setNextPage] = useState<string>();
    const [contentPriority, setContentPriority] = useState<number>(0);
    const [searchContentTemplate, setSearchContentTemplate] = useState('')

    const isContentTemplate = useMemo(() => defaultValue?.template_id ? true : false, [defaultValue?.template_id])

    const { data: ssTemplateData, isLoading: ssCcontentTempalteIsLoading, isError: ssTemplateIsError, error: ssTemplateError, mutate } = useMutation(
        (pageDirection: 'next' | 'previous' | '') => getStateContentTemplateFilter<ISurveySequence, EnumContentType.SurveySequenceContent>
            (pathwaySlug, id, EnumContentType.SurveySequenceContent, 8, '', nextPage, previousPage, pageDirection), {
        onSuccess(data) {
            if (data?.data) {
                const { next, previous } = data?.data.paginate
                setNextPage(next)
                setPreviousPage(previous)
            }
        },
    });

    useEffect(() => {
        mutate('')
    }, [mutate]);





    const flattedSSTemplateOptions = useMemo(() => {
        // const newOption = { value: '0', title: 'No Template Content' };
        const currentLanguage = i18n.language;

        const options = ssTemplateData && ssTemplateData.data.data.map((item) => {
            const { id } = item;
            return item.survey_sequence.map((ann) => {
                return {
                    template_id: id,
                    direction_id: ann.id,
                    title: ann.title,
                    description: ann.description,
                    updated_at: ann.updated_at,
                    metaData: item.content_metadata,
                    surveys: ann.surveys,
                    language: ann.language
                }
            })
        }).flatMap((item) => item).filter(item => item.language === LanguageEnum.English)
        return options;
    }, [ssTemplateData, i18n.language]);




    const search_result = useMemo(() => {
        if (searchContentTemplate === '') return flattedSSTemplateOptions
        return flattedSSTemplateOptions &&
            flattedSSTemplateOptions.filter(item => item.title.toLowerCase().includes(searchContentTemplate.toLowerCase()) ||
                item.description.toLowerCase().includes(searchContentTemplate.toLowerCase()))
    }, [flattedSSTemplateOptions, searchContentTemplate])



    // =================== Create content template =========================
    const addContentTemplate = async (item: any) => {

        const obj = {
            template_id: item.template_id as number,
            state_slug: id as string,
            pathway_slug: pathwaySlug as string,
            is_template: true,
            content_type: EnumContentType.SurveySequenceContent,
        }

        dispatch(setAddContentTemplateModal(obj))
        onClose()
    }


    const createTimeEvent = useMutation((timeData: Conditions_T) => createTimeBaseEvent(organization_id, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        },
        onError: (error: null | Error) => {
            // const message = error?.message || "Failed creating Time base Rule"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.message || `${t('content.create_time_base_rule_failed')}`
            toast(message, 'error')
        }
    })
    const deleteCondition = useMutation((delId: number) => deleteTimeCondition(organization_id, delId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        }
    })
    const updateTimeEvent = useMutation((timeData: Conditions_T) => updateTimeCondition(organization_id, timeData.id || 0, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }))
    const createRuleCondition = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        // const target_func = getValues("target_func") as string
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore
            // const message = error?.response?.data?.message || "Failed creating content Rule"
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            toast(message, 'error')
        }
    })

    const UpDateFunc = async (createdData: contents) => {
        if (ruleId.event === 0) {
            let ruleId = null
            if (query.rules.length !== 0) {
                // const { data } = await createRuleCondition.mutateAsync(
                //     // {
                //     //     organization_id: organization_id,
                //     //     ...jsonHandler(timeValue, typeOf, eventName, query,target_func)
                //     // }
                // )
                // ruleId = data?.id || null
                ruleId = await createRuleConditionForContent();
            }
            await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId })
        } else await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId.event || null })
    }

    const createTimeRule = async (ID: number | undefined) => {
        const typeIs = typeOf === 'days' ? 'hour' : typeOf
        await createTimeEvent.mutateAsync({
            deliver_time: timeValue ? timeValue : 5,
            deliver_unit: typeIs || "minute",
            state_id: parseInt(defaultValue.state_id),
            content_id: ID
        })
    }

    const deleteSurveys = useMutation((id: any) => deleteSurvey(organization_id, id || 0), {})
    const createSurveys = useMutation((items: surveys) => createSurveyInSurveySeq(organization_id, items?.id || 0, { ...items, id: undefined }), {})


    const updateSurveyLabel = useMutation((items: updateSurvey) => updateSurveyItems(organization_id, items.Survey_id, items?.id || 0, items.label, items.altTitle, items.order), {})
    const createSurveyLabel = useMutation((items: updateSurvey) => createSurveyItem(organization_id, items.Survey_id, items.label, items.altTitle, items.order), {})

    const updateContentSurvey = useMutation((surveyDetails: survey) => updateSurvey2(organization_id, { ...surveyDetails }, surveyDetails?.id || 0), {})

    const handlePathwayView = useCallback(() => {
        setModal(prev => ({ ...prev, pathwayView: !prev.pathwayView }))
    }, [])

    const handleContentView = useCallback(() => {
        setModal(prev => ({ ...prev, contentView: !prev.contentView }))
    }, [])

    const [tab, setTab] = UseTab("Survey Details")

    const createContents = useMutation((newContent: contents) => createContentInState(organization_id, pathwaySlug, id, { ...newContent }), {
        onSuccess: async (createdData: Single_Content_Single) => {
            if (timeEvent.isAdded) {
                await createTimeRule(createdData.data?.id)
            }
            toast(`${t('content.created')}`, 'success')
            !!onSuccess && onSuccess()
            onClose()
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.failed')}`
            toast(message, 'error')
        }
    })

    const handleDelete = (index: number) => {
        const surveysAll = survey.filter((_, i) => i !== index);
        setValue("surveys", surveysAll)
    }

    const updateContentMutation = useMutation((updateContentDetails: contents) => updateContent(organization_id, pathwaySlug, id, { ...updateContentDetails }, updateContentDetails.slug || ''), {
        onSuccess: async (data: Single_Content_Single) => {
            if (defaultValue) {
                if (data?.data?.survey_sequence) {
                    await updateContentSurveySeq.mutateAsync({
                        ...data?.data?.survey_sequence
                    })
                }
                toast(`${t('content.updated')}`, 'success')
                !!onSuccess && onSuccess()
                await queryClient.invalidateQueries(['content', defaultValue.id])
                onClose()
            }
        },
        onError: (error: null | Error) => {

            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const updateContentSurveySeq = useMutation((surveySeq: survey_sequence) => updateSurveySeq(organization_id, { ...surveySeq }, surveySeq?.id || 0), {
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const createRuleTimePassedCondition = useMutation(({ targetField, targetFunction }: any) => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, targetFunction, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func: targetFunction, target_field: targetField })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => {
            //@ts-ignore
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            toast(message, 'error')
        }
    })


    async function createRuleConditionForContent() {
        let data = null
        if (passedContent.targetField && passedContent.targetFunction) {


            const resultCondition = await createRuleTimePassedCondition.mutateAsync(
                {
                    targetField: passedContent.targetField,
                    targetFunction: passedContent.targetFunction
                }
            )
            data = resultCondition?.data
        } else {
            const resultRule = await createRuleCondition.mutateAsync()
            data = resultRule?.data
        }
        return data?.id || null
    }




    /// ===================
    const onSubmit: SubmitHandler<survey_sequence> = async data => {
        await trigger()
        let fileUpload = {
            link: ''
        }
        if (file !== undefined) {
            const formData = new FormData();
            // @ts-ignore
            formData.append("file", file);
            const { link } = await uploadForm(formData)
            fileUpload.link = link
        }

        let shapeData;
        if (isContentTemplate) {
            shapeData = {
                ...defaultValue,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                priority: contentPriority,
            }

        } else {

            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "survey_sequence",
                priority: isContentTemplate ? contentPriority : Number(data.priority),
                tags: data?.tags ? [data.tags] : [],
                cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                preview_description: data?.preview_description,
                preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                survey_sequence: {
                    title: data.title,
                    description: data.description,
                    is_2_value: true,
                    surveys: data?.surveys?.map((item, index) => {
                        return {
                            ...item,
                            order: item?.order || index,
                            organization_id: organization_id,
                            title: item.title,
                            description: item.description,
                            is_2_value: true,
                            answer: null,
                            survey_items: item?.survey_items?.map((name, ind) => {
                                const orderItem = name?.order || ind;
                                name["value"] = ind + 1;
                                return { ...name, order: orderItem };
                            })
                        }
                    }),
                    owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                }
            }
        }


        if (type === 'update') {

            if (!isContentTemplate) {
                const deleteFaqs = getDifference(defaultValue?.survey_sequence?.surveys, shapeData?.survey_sequence?.surveys)
                await Promise.all(deleteFaqs?.map(async (item) => await deleteSurveys.mutateAsync(item?.id)))

                await Promise.all(shapeData?.survey_sequence?.surveys?.map(async (item: survey) => {
                    if (item?.id) {
                        item?.survey_items?.map(async (surItem) => {
                            if (surItem?.id) {
                                await updateSurveyLabel.mutateAsync({
                                    Survey_id: item?.id || 0,
                                    id: surItem?.id,
                                    altTitle: surItem.alt_title,
                                    label: surItem?.label || "",
                                    order: surItem?.order
                                })
                            } else {
                                await createSurveyLabel.mutateAsync({
                                    Survey_id: item?.id || 0,
                                    label: surItem?.label || "",
                                    altTitle: surItem.alt_title,
                                    order: surItem?.order
                                })
                            }
                        })
                        await updateContentSurvey.mutateAsync(item)
                    } else {
                        // await createSurveys.mutateAsync({
                        //     id: defaultValue?.survey_sequence?.id || 0,
                        //     order: 0,
                        //     ...item
                        // })
                    }
                }))
            }

            const timeId = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined

            if (timeEvent.isAdded) {
                if (defaultValue?.time_condition && timeId) {
                    await updateTimeEvent.mutateAsync({
                        id: timeId,
                        deliver_time: timeValue ? timeValue : 5,
                        deliver_unit: typeOf || "minute",
                        state_id: parseInt(defaultValue.state_id),
                    })
                } else await createTimeRule(defaultValue.id)
            }
            if (!timeEvent.isAdded && defaultValue?.is_time_base) {
                if (id) await deleteCondition.mutateAsync(timeId)
            }

            await UpDateFunc(shapeData)
        }
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }
        const itemsData = reorder(
            survey,
            result.source.index,
            result.destination.index
        ) as surveys[]
        itemsData.map((item, ind) => {
            item.order = ind
        })
        setValue("surveys", itemsData)
    };

    const onDragEndItems = (result: DropResult, index: number): void => {
        if (!result.destination) {
            return;
        }


        const itemsData = reorder(
            survey[index].survey_items,
            result.source.index,
            result.destination.index
        ) as survey_items[]

        const surveysAll = [...survey];
        surveysAll[index].survey_items = itemsData

        itemsData.map((item, ind) => {
            item.order = ind
        })

        setValue("surveys", surveysAll)
    };

    const handleNewSurveyItem = (ind: number) => {
        const surveysAll = [...survey]; //@ts-ignore
        const lastIndexOrder = surveysAll[ind]?.survey_items[surveysAll[ind]?.survey_items?.length - 1]?.order || 0
        surveysAll[ind]?.survey_items?.push({
            label: '',
            value: 1,
            order: lastIndexOrder + 1,
            alt_title: ''
        })
        setValue("surveys", surveysAll)
    }

    const handleDeleteSurveyItem = (fIndex: number, ind: number) => {
        const surveysAll = [...survey];
        surveysAll[fIndex]?.survey_items?.splice(ind, 1);
        setValue("surveys", surveysAll)
    }

    const handleCollapse = (index: number) => {
        const surveysAll = [...survey];
        surveysAll[index].isCollapsed = !surveysAll[index].isCollapsed
        setValue("surveys", surveysAll)
    }

    // const survey_item = watch("survey_items") || []
    const survey = watch("surveys") || []

    const addNewSurvey = () => {
        setValue("surveys", [...survey, { title: '', description: '', alt_title: '', survey_items: [{ label: '', value: 1, alt_title: '' }], isCollapsed: false, order: 0 }])
    }

    const title = getValues("title");

    useEffect(() => {
        if (defaultValue) {
            const surveysAll = [...survey].sort((a, b) => a.order - b.order);
            surveysAll.forEach((item) => {
                return {
                    ...item, //@ts-ignore
                    survey_items: item?.survey_items?.sort((a, b) => a?.order - b?.order)
                }
            })

            setValue("surveys", surveysAll)

            const id = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined
            setRuleId({ time: id, event: defaultValue?.rules_content_id })

            if (defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.deliver_time) {
                //@ts-ignore
                setValue("timeValue", defaultValue?.time_condition[0]?.deliver_time)  //@ts-ignore
                setValue("typeOf", defaultValue?.time_condition[0]?.deliver_unit)
            }

            setContentPriority(defaultValue?.priority)

            setAltTitle(defaultValue?.alt_title)

        }
    }, [])


    const onUpdateContentFields = async (priority:number, alternativeTitle:string, customJSONB?: SurveySequenceJSONB[] | null) => {
        const shapeData  = {
            ...defaultValue,
            priority: priority,
            alt_title: alternativeTitle,
            custom_json: customJSONB
        }
        await updateContentMutation.mutateAsync(shapeData)
    }



    const contentSlug = defaultValue?.content_template?.survey_sequence[0]?.slug || defaultValue?.slug

    return (
        <>
            <div className="flex flex-col w-full gap-4 space-x-3">
                {
                    contentSlug ?
                        <CustomClipboard showInfoIcon clipboardText={contentSlug} />
                        : null
                }
                {
                        type === 'update' && isContentTemplate === true ? <>
                            <SurveySequenceMultiLanguage 
                            customJSONB={defaultValue?.custom_json} 
                            contentTemplate={defaultValue?.content_template} 
                            priority={contentPriority} 
                            alternativeTitle={altTitle}
                            onUpdateFields={onUpdateContentFields} />
                        </> : null
                }
                <form onSubmit={handleSubmit(onSubmit)}
                    className='w-full h-full pb-2 space-y-3 overflow-auto text-gray-600 text-md'>



                    {type === 'create' ? <>
                        <AppInput placeholder='Search' value={searchContentTemplate} onChange={e => setSearchContentTemplate(e.target.value)} name='searchTemplate' />
                        <div className='flex items-center justify-center'>
                            {ssCcontentTempalteIsLoading ? <AppLoader isLoading size={35} /> : null}
                        </div>

                        <div className='grid grid-cols-2 gap-1'>
                            {search_result?.length === 0 ? <p className='my-2 text-md'>There isn't any template to show.</p> : search_result && search_result.map((item, index) => {
                                return <div className='flex flex-col items-start justify-start p-4 m-1 transition-all delay-75 rounded-md shadow-sm bg-gray-50 hover:bg-white' key={index}>
                                    <span
                                        onClick={() => addContentTemplate(item)}
                                        className='px-2 text-xs border rounded hover:cursor-pointer hover:bg-sky-100 border-sky-500 bg-sky-50 text-sky-700'>Add +</span>
                                    <p className='my-1 font-medium text-md'>{item.title}</p>
                                    <p className='flex-1 mb-3 text-xs'>
                                        {item.description}
                                    </p>
                                    <p className='my-1 text-xs'>
                                        {/* @ts-ignore */}
                                        Languages: <span className='font-medium uppercase text-sky-700'>{item.metaData?.map((item: any) => item.language).join(', ')}</span>
                                    </p>
                                    {item.updated_at ? <p className="mt-1 text-xs">Last updated {moment(item.updated_at).fromNow() + '. on ' + moment(item.updated_at).format('MMM D, YYYY')
                                    }</p> : null}
                                </div>
                            })}
                        </div>

                        <div className="flex">
                            <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
                                onClick={() => {
                                    mutate('previous')
                                }}
                                extendClass="mx-1">Previous</AppButton>
                            <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
                                onClick={() => {
                                    mutate('next')
                                }}
                                extendClass="mx-1">Next</AppButton>
                        </div>



                    </> : null}

                    {
                        type === 'update' && isContentTemplate === true ? <>
                            {/*---------------------------------------------------------*/}
                            {/*Survey Details and Content Details tabs*/}
                            {/* <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView && handlePathwayView()
                                    e.stopPropagation()
                                }} />} title={
                                    "Survey Details"
                                }>2-Value Survey2Preview Details</Tab>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView && handleContentView()
                                    e.stopPropagation()
                                }} />} title="Content Details">Content Details</Tab>
                            </Tabs> */}

                            {/*---------------------------------------------------------*/}
                            {/*Survey Details tab with form inputs*/}
                            {/* <Fade in={tab === "Survey Details"}
                                style={{ display: tab === "Survey Details" ? '' : 'none' }}>
                                <div className="space-y-4">
                                    <Input
                                        register={() => register("title")}
                                        name="title"
                                        placeholder="Survey Preview Question"
                                        errors={errors}
                                        value={defaultValue?.content_template?.survey_sequence[0]?.title || ''}
                                        disabled
                                        required={false}
                                    />
                                    {
                                        tab === "Survey Details" &&
                                        <TextArea
                                            register={() => register("description")}
                                            name="description"
                                            placeholder="Survey Preview Description"
                                            errors={errors}
                                            disabled
                                            value={defaultValue?.content_template?.survey_sequence[0]?.description || ''}
                                        />
                                    } */}

                                    {/* <p className="text-lg">{'Add Surveys to the survey sequence'}</p>

                                    <div className="flex flex-col w-full space-y-8"> */}

                                        {/* <Button onClick={addNewSurvey} label={
                                        <p className="text-white">Add New Survey</p>
                                    } extraClasses="w-1/3 px-3 py-3" disabled /> */}

                                        {/* <DragDropContext onDragEnd={onDragEnd}>



                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot): JSX.Element => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}
                                                        className="space-y-2">
                                                        {defaultValue?.content_template?.survey_sequence[0]?.surveys && defaultValue?.content_template?.survey_sequence[0]?.surveys.map((item: any, index: number) => (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                                {(provided, snapshot): JSX.Element => (
                                                                    <div
                                                                        ref={provided.innerRef} {...provided.draggableProps} className="p-4 border border-[#D8D8D8] bg-gray-200">
                                                                        <>
                                                                            <div className="flex justify-between">
                                                                                <div className="flex items-center w-full space-x-2">
                                                                                    <div {...provided.dragHandleProps}>
                                                                                        <GrDrag className="text-2xl" />
                                                                                    </div>

                                                                                    <Button disabled label={"Delete"} extraClasses="bg-red-600 text-white w-24" onClick={() => handleDelete(index)} />
                                                                                </div>
                                                                                {item.isCollapsed ? <BiUpArrow className="text-3xl cursor-pointer" onClick={() => handleCollapse(index)} /> :
                                                                                    <BiDownArrow className="text-3xl cursor-pointer" onClick={() => handleCollapse(index)} />}

                                                                            </div>

                                                                            <p>Survey Sequence Details</p>

                                                                            {
                                                                                !item.isCollapsed &&
                                                                                <div className="pt-5 space-y-4">
                                                                                    <Input
                                                                                        disabled
                                                                                        register={() => register(`surveys.${index}.title`)}
                                                                                        name={`surveys.${index}.title`}
                                                                                        value={item.title}
                                                                                        errors={errors} />

                                                                                    {
                                                                                        tab === "Survey Details" &&
                                                                                        <TextArea
                                                                                            disabled
                                                                                            register={() => register(`surveys.${index}.description`)}
                                                                                            name={`surveys.${index}.description`}
                                                                                            value={item.description}
                                                                                            errors={errors} />
                                                                                    }

                                                                                    <p>Survey Items</p>

                                                                                    <DragDropContext onDragEnd={(re) => onDragEndItems(re, index)}>
                                                                                        <Droppable droppableId="droppableItem">
                                                                                            {(providedItem, snapshot): JSX.Element => (
                                                                                                <div {...providedItem.droppableProps} ref={providedItem.innerRef} className="space-y-3">

                                                                                                    {item?.survey_items?.map((item: any, i: number) => (

                                                                                                        <Draggable key={i} draggableId={i.toString()} index={i}>
                                                                                                            {(providedItem, snapshot): JSX.Element => (
                                                                                                                <div
                                                                                                                    ref={providedItem.innerRef} {...providedItem.draggableProps} className="flex items-center w-full space-x-2">


                                                                                                                    <div {...providedItem.dragHandleProps}>
                                                                                                                        <GrDrag className="text-2xl" />
                                                                                                                    </div>
                                                                                                                    <Input
                                                                                                                        disabled
                                                                                                                        register={() => register(`surveys.${index}.survey_items.${i}.label`)}
                                                                                                                        name={`surveys.${index}.survey_items.${i}.label`}
                                                                                                                        value={item.label}
                                                                                                                        extraClasses={"w-[40rem]"}
                                                                                                                        errors={errors} />

                                                                                                                    <AiFillDelete onClick={() => { handleDeleteSurveyItem(index, i) }}
                                                                                                                        className="text-2xl cursor-pointer" />


                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Draggable>


                                                                                                    ))}

                                                                                                    {providedItem.placeholder}
                                                                                                </div>
                                                                                            )}
                                                                                        </Droppable>
                                                                                    </DragDropContext>




                                                                                </div>
                                                                            }



                                                                        </>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>



                                        </DragDropContext> */}


                                        {/*<div className="flex py-1 space-x-10">*/}
                                        {/*    <TextField label="Item" value={input} onChange={(e) => setInput(e.target.value)}*/}
                                        {/*               type="text"*/}
                                        {/*               className="w-full p-3 leading-tight text-gray-600 bg-white rounded-md appearance-none focus:outline-none"/>*/}
                                        {/*    <Button onClick={submitItem} label={*/}
                                        {/*        <p className="text-white">{edit.isEdit ? "Update Item" : "Create Item"}</p>*/}
                                        {/*    } extraClasses="w-1/3 px-3 py-3"/>*/}

                                        {/*</div>*/}

                                        {/*{*/}
                                        {/*    (Array.from(new Array(is2Value ? 2 : 7))).map((item, index) => <div key={index}*/}
                                        {/*                                                                        className="flex w-full space-x-4">*/}
                                        {/*        <div*/}
                                        {/*            className="flex items-center px-6 py-3 text-xl border border-gray-400 rounded">{index + 1}</div>*/}
                                        {/*        <div className="w-full">*/}
                                        {/*            <Input*/}
                                        {/*                register={() => register(`survey_items.${index}.label`)}*/}
                                        {/*                name={`survey_items.${index}.label`}*/}
                                        {/*                placeholder="label"*/}
                                        {/*                errors={errors}/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>)*/}
                                        {/*}*/}
                                    {/* </div>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }


                                    <AppThemeButton type='button' onClick={() => { navigate(`/content-templates/survey_sequence/view/${defaultValue?.content_template?.id}`) }} size='md' variant="info">
                                        <div className='flex items-center justify-between gap-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                            </svg>
                                            <span>Edit Survey Sequence Template</span>
                                        </div>
                                    </AppThemeButton>
                                </div>
                            </Fade> */}

                            {/*---------------------------------------------------------*/}
                            {/*Content Details tab with form inputs*/}
                            {/* <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div> */}
                                    {/* <ContentExtra typeOf={typeOf} query={query} setQuery={setQuery}
                                    prevTitle={watch("preview_title")} rule={ruleId} setRule={setRuleId} name={name}
                                    defaultValue={defaultValue}
                                    register={register} errors={errors}
                                    render={render} setTimeEvent={setTimeEvent}
                                    setPassedContent={setPassedContent}
                                /> */}

                                    {/* <div className="py-4 space-y-3">
                                        <div className="grid grid-cols-1 gap-3">


                                            <AppInput label="Alternative Title" name="alt_title" onChange={e => {
                                                setAltTitle(prev => {
                                                    return e.target.value
                                                })
                                            }}

                                                maxLength={30} value={altTitle} isFull extendClass='my-2' />


                                            <AppInput label="Content Card" name="content_card" value={defaultValue?.content_template?.content_metadata[0]?.preview_title} disabled isFull extendClass='my-2' />

                                            <AppInput
                                                label="Content Tag"
                                                name="content_tag"
                                                value={defaultValue?.content_template?.content_metadata?.[0]?.tags?.[0]}
                                                disabled
                                                isFull
                                                extendClass='my-2'
                                            />

                                            {defaultValue?.content_template?.content_metadata[0]?.cover_image ? <div className="cover__image-container">
                                                <img className='rounded-sm' alt='cover image' title='cover image' src={defaultValue?.content_template?.content_metadata[0]?.cover_image} />
                                            </div> : null}

                                        </div>
                                    </div>
                                    <AppInput label="Content Priority" type='number' min={0} name="content_priority" onChange={e => setContentPriority(e.target.valueAsNumber)} value={contentPriority.toString()} isFull extendClass='my-2' /> */}
                                    {/* 
                                <Condition
                                    errors={errors}
                                    register={register}
                                    query={query}
                                    setQuery={setQuery}
                                    isContent
                                    rule={ruleId}
                                    setRule={setRuleId}
                                    setTimeEvent={setTimeEvent}
                                    name={name}
                                    typeOf={typeOf}
                                    setPassedContent={setPassedContent}
                                /> */}

                                    {/*Submit form to create the Survey*/}
                                    {/* <Button
                                        disabled={createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading || createSurveyLabel.isLoading || updateSurveyLabel.isLoading || createSurveys.isLoading || deleteSurveys.isLoading || updateContentSurvey.isLoading}
                                        type="submit" label={
                                            <div className="flex items-center space-x-2 text-white">
                                                <AiFillFile className="text-3xl" />
                                                <p>Update</p>
                                            </div>
                                        } extraClasses="w-fit px-3 py-3" />
                                </div>
                            </Fade> */}

                            {/*---------------------------------------------------------*/}

                        </> : null
                    }


                    {
                        type === 'update' && isContentTemplate === false ? <>

                            {/*---------------------------------------------------------*/}
                            {/*Survey Details and Content Details tabs*/}
                            <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView && handlePathwayView()
                                    e.stopPropagation()
                                }} />} title={
                                    "Survey Details"
                                }>2-Value Survey2Preview Details</Tab>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView && handleContentView()
                                    e.stopPropagation()
                                }} />} title="Content Details">Content Details</Tab>
                            </Tabs>

                            {/*---------------------------------------------------------*/}
                            {/*Survey Details tab with form inputs*/}
                            <Fade in={tab === "Survey Details"}
                                style={{ display: tab === "Survey Details" ? '' : 'none' }}>
                                <div className="space-y-4">
                                    <Input
                                        register={() => register("title", { required: 'title is required', maxLength: 140 })}
                                        name="title"
                                        placeholder="Survey Preview Question"
                                        errors={errors} />
                                    {
                                        //to stop textarea causing too many re-renders
                                        tab === "Survey Details" &&
                                        <TextArea
                                            register={() => register("description")}
                                            name="description"
                                            placeholder="Survey Preview Description"
                                            errors={errors} />
                                    }

                                    <p className="text-lg">{'Add Surveys to the survey sequence'}</p>

                                    <div className="flex flex-col w-full space-y-8">

                                        <Button onClick={addNewSurvey} label={
                                            <p className="text-white">Add New Survey</p>
                                        } extraClasses="w-1/3 px-3 py-3" />

                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot): JSX.Element => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}
                                                        className="space-y-2">
                                                        {survey
                                                            .map((item, index) => (
                                                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                                                    {(provided, snapshot): JSX.Element => (
                                                                        <div
                                                                            ref={provided.innerRef} {...provided.draggableProps} className="p-4 border border-[#D8D8D8] bg-gray-200">
                                                                            <>
                                                                                <div className="flex justify-between">
                                                                                    <div className="flex items-center w-full space-x-2">
                                                                                        <div {...provided.dragHandleProps}>
                                                                                            <GrDrag className="text-2xl" />
                                                                                        </div>

                                                                                        <Button label={"Delete"} extraClasses="bg-red-600 text-white w-24" onClick={() => handleDelete(index)} />
                                                                                    </div>
                                                                                    {item.isCollapsed ? <BiUpArrow className="text-3xl cursor-pointer" onClick={() => handleCollapse(index)} /> :
                                                                                        <BiDownArrow className="text-3xl cursor-pointer" onClick={() => handleCollapse(index)} />}

                                                                                </div>

                                                                                <p>Survey Sequence Details</p>

                                                                                {
                                                                                    !item.isCollapsed &&
                                                                                    <div className="pt-5 space-y-4">
                                                                                        <Input
                                                                                            register={() => register(`surveys.${index}.title`, { required: 'title is required', maxLength: 140 })}
                                                                                            name={`surveys.${index}.title`}
                                                                                            placeholder="Survey Title"
                                                                                            errors={errors} />


                                                                                        <Input
                                                                                            register={() => register(`surveys.${index}.alt_title`, { required: 'alt title is required', maxLength: 140 })}
                                                                                            name={`surveys.${index}.alt_title`}
                                                                                            placeholder="Survey Alt Title"
                                                                                            errors={errors} />



                                                                                        {
                                                                                            tab === "Survey Details" &&
                                                                                            <TextArea
                                                                                                register={() => register(`surveys.${index}.description`)}
                                                                                                name={`surveys.${index}.description`}
                                                                                                placeholder="Survey Description"
                                                                                                errors={errors} />
                                                                                        }

                                                                                        <p>Survey Items</p>

                                                                                        <DragDropContext onDragEnd={(re) => onDragEndItems(re, index)}>
                                                                                            <Droppable droppableId="droppableItem">
                                                                                                {(providedItem, snapshot): JSX.Element => (
                                                                                                    <div {...providedItem.droppableProps} ref={providedItem.innerRef} className="space-y-3">

                                                                                                        {item?.survey_items?.map((item, i) => (

                                                                                                            <Draggable key={i} draggableId={i.toString()} index={i}>
                                                                                                                {(providedItem, snapshot): JSX.Element => (
                                                                                                                    <div
                                                                                                                        ref={providedItem.innerRef} {...providedItem.draggableProps} className="flex items-center w-full space-x-2">


                                                                                                                        <div {...providedItem.dragHandleProps}>
                                                                                                                            <GrDrag className="text-2xl" />
                                                                                                                        </div>
                                                                                                                        <div className='className="flex flex-col items-center w-full space-y-2"'>
                                                                                                                            <Input
                                                                                                                                register={() => register(`surveys.${index}.survey_items.${i}.label`, { required: 'title is required', maxLength: 140 })}
                                                                                                                                name={`surveys.${index}.survey_items.${i}.label`}
                                                                                                                                placeholder="Survey item label"
                                                                                                                                extraClasses={"w-[40rem]"}
                                                                                                                                errors={errors} />
                                                                                                                            <div className="mb-1"></div>

                                                                                                                            <Input
                                                                                                                                register={() => register(`surveys.${index}.survey_items.${i}.alt_title`, { maxLength: 140 })}
                                                                                                                                name={`surveys.${index}.survey_items.${i}.alt_label`}
                                                                                                                                placeholder="Survey item Alt Title"
                                                                                                                                extraClasses={"w-[40rem]"}
                                                                                                                                errors={errors} />
                                                                                                                        </div>
                                                                                                                        <AiFillDelete onClick={() => { handleDeleteSurveyItem(index, i) }}
                                                                                                                            className="text-2xl cursor-pointer" />


                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Draggable>


                                                                                                        ))}

                                                                                                        {providedItem.placeholder}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Droppable>
                                                                                        </DragDropContext>



                                                                                        <Button label={"Add new Survey Item"} extraClasses=" text-white w-48" onClick={() => handleNewSurveyItem(index)} />

                                                                                    </div>
                                                                                }



                                                                            </>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>


                                        {/*<div className="flex py-1 space-x-10">*/}
                                        {/*    <TextField label="Item" value={input} onChange={(e) => setInput(e.target.value)}*/}
                                        {/*               type="text"*/}
                                        {/*               className="w-full p-3 leading-tight text-gray-600 bg-white rounded-md appearance-none focus:outline-none"/>*/}
                                        {/*    <Button onClick={submitItem} label={*/}
                                        {/*        <p className="text-white">{edit.isEdit ? "Update Item" : "Create Item"}</p>*/}
                                        {/*    } extraClasses="w-1/3 px-3 py-3"/>*/}

                                        {/*</div>*/}

                                        {/*{*/}
                                        {/*    (Array.from(new Array(is2Value ? 2 : 7))).map((item, index) => <div key={index}*/}
                                        {/*                                                                        className="flex w-full space-x-4">*/}
                                        {/*        <div*/}
                                        {/*            className="flex items-center px-6 py-3 text-xl border border-gray-400 rounded">{index + 1}</div>*/}
                                        {/*        <div className="w-full">*/}
                                        {/*            <Input*/}
                                        {/*                register={() => register(`survey_items.${index}.label`)}*/}
                                        {/*                name={`survey_items.${index}.label`}*/}
                                        {/*                placeholder="label"*/}
                                        {/*                errors={errors}/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>)*/}
                                        {/*}*/}
                                    </div>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }
                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Content Details tab with form inputs*/}
                            <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div>
                                    {/* ===== */}

                                    <div>
                                        <label htmlFor='alt_title' className='mb-1 ml-1 text-sm'>Alternative Title</label>
                                        <input
                                            id="alt_title"
                                            type="text"
                                            className="block w-full p-4 text-gray-900 border border-gray-300 rounded-md text-md focus:ring-blue-500 focus:border-blue-500"
                                            value={altTitle}
                                            maxLength={30}
                                            onChange={e => {
                                                setAltTitle(prev => {
                                                    return e.target.value
                                                })
                                            }}
                                            placeholder='Alternative Title'
                                        />
                                    </div>

                                    {/* ======= */}
                                    <ContentExtra typeOf={typeOf} query={query} setQuery={setQuery}
                                        prevTitle={watch("preview_title")} rule={ruleId} setRule={setRuleId} name={name}
                                        defaultValue={defaultValue}
                                        register={register} errors={errors}
                                        render={render} setTimeEvent={setTimeEvent}
                                        setPassedContent={setPassedContent}
                                    />

                                    {/*    {*/}
                                    {/*        (ruleId.event === 0) ?*/}
                                    {/*            <>*/}
                                    {/*                <h4>Query</h4>*/}
                                    {/*                <pre>*/}
                                    {/*<code>{formatQuery(query, 'json')}</code>*/}
                                    {/*</pre>*/}
                                    {/*            </> : null*/}
                                    {/*    }*/}
                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Submit form to create the Survey*/}
                            <Button
                                disabled={createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading || createSurveyLabel.isLoading || updateSurveyLabel.isLoading || createSurveys.isLoading || deleteSurveys.isLoading || updateContentSurvey.isLoading}
                                type="submit" label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>{type === 'update' ? 'Update Content' : 'Create Content'}</p>
                                    </div>
                                } extraClasses="w-fit px-3 py-3" />

                        </> : null
                    }

                </form>
            </div>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for the Survey*/}
            {/*<OverlayModal2 onClose={handlePathwayView} isOpen={modal.pathwayView}>*/}
            {/*    <div className="relative flex p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md h-fit w-fit">*/}
            {/*        <Mobile>*/}
            {/*            <PreviewContentModal survey={getValues("survey_items")}*/}
            {/*                                 description={getValues("description")}*/}
            {/*                                 tags={[getValues("tags") || ""]}*/}
            {/*                                 type={'survey'}*/}
            {/*                                 title={title}*/}
            {/*                                 image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""}/>*/}
            {/*        </Mobile>*/}
            {/*    </div>*/}
            {/*</OverlayModal2>*/}

            {/*---------------------------------------------------------*/}
            {/*Preview modal for Survey Content Card*/}
            <OverlayModal2 onClose={handleContentView} isOpen={modal.contentView}>
                <div className="h-fit overflow-auto bg-gray-100 p-2 rounded-md flex text-gray-600 w-[40%] relative">
                    <ContentTypeCardPreview
                        type={'survey'}
                        image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""}
                        tags={[getValues("tags") || ""] || ['']}
                        title={getValues("preview_title") || defaultValue?.preview_title || ""}
                    />
                </div>
            </OverlayModal2>
        </>
    );
});

export default SurveySequence;
