import React, {FC, InputHTMLAttributes} from "react";
import {MenuItem, TextField} from "@mui/material";

type option = {
    value: string | number,
    text: string
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    errors?: any;
    register?: any;
    value?: any
    extraClasses?: string;
    options?: option[]
    onChange?: (event: any) => void;
}

const SelectInput: FC<InputProps> = ({
                                         register,
                                         name,
                                         errors,
                                         extraClasses,
                                         onChange,
                                         value,
                                         options,
                                         ...rest
                                     }) => {
    return (
        <div>
            <TextField
                select
                fullWidth
                className={`appearance-none rounded-md text-gray-600 w-full bg-white leading-tight focus:outline-none p-3 ${extraClasses}`}
                {...register()}
                error={!!errors[name]}
                label={rest.placeholder}
                {...rest}
            >
                {options?.map((option, index) =>
                    <MenuItem key={index} value={option.value}>
                        {option.text}
                    </MenuItem>
                )}
            </TextField>
            <p className="text-red-500">{errors[name]?.message}</p>
        </div>
    );
};

export default SelectInput;