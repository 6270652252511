import React, { useEffect, useState } from "react";
import OverlayModal from "../../components/Layouts/OverlayModal";
import { CloseIcon } from "../Icons";
import { AppButton } from "../Shared";
// import OverlayModal from '../../../modal/OverlayModal';
// import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
// import AppButton from '../AppButton/AppButton';

export interface IAppPopupModalProps {
  isOpen: boolean;
  title: string;
  setOpen: (isOpen: boolean) => void;
  isParentTop?: boolean
  extraClasses?:string
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirmClick: () => void;
  onCancelClick?: () => void;
}

export const PopupModal: React.FC<IAppPopupModalProps> = (props) => {
  const {
    isOpen,
    title,
    confirmButtonText,
    cancelButtonText,
    onConfirmClick,
    onCancelClick,
    setOpen,
    isParentTop,
    extraClasses
  } = props;

  const onClose = () => {
    setOpen(false);
  };

  const handleConfirmClick = () => {
    onConfirmClick();
    onClose();
  };

  const handleCancelClick = () => {
    if (typeof onCancelClick === "function") onCancelClick();
    onClose();
  };

    // close the fitler with ESC key
    useEffect(() => {
      const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          setOpen(false);
        }
      };
  
      document.addEventListener("keydown", handleEscapeKey);
  
      return () => {
        document.removeEventListener("keydown", handleEscapeKey);
      };
    }, [setOpen]);

    let modalClasses = `fixed flex p-4 overflow-x-hidden overflow-y-auto overlayModal -50 md:inset-0 h-modal md:h-full justify-center ${isParentTop ? ' ' : ' items-center  '} ${extraClasses}` 

  return (
    <OverlayModal isOpen={isOpen} onClose={onClose}>
      <div
        tabIndex={-1}
        className={modalClasses}
      >
        <div className="relative w-full h-full max-w-md md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              onClick={onClose}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            >
              <CloseIcon className="w-3 h-3" aria-hidden="true" />
              <span className="sr-only">Close</span>
            </button>
            <div className="p-6 text-center">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-12 mx-auto mb-4 text-gray-400 w-14 dark:text-gray-300"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg> */}

              <h3 className="mt-4 mb-5 font-normal text-md text-textDark dark:text-gray-400">
                {title}
              </h3>
              <div className="flex justify-center gap-3">
                <AppButton
                  type="button"
                  onClick={handleConfirmClick}
                  variant="danger"
                >
                  <span className="px-3">{confirmButtonText ? confirmButtonText : "Yes, I'm sure"}</span>
                </AppButton>
                <AppButton
                  type="button"
                  variant="primary"
                  onClick={handleCancelClick}
                >
                  <span className="px-3"> {cancelButtonText ? cancelButtonText : "No, cancel"}</span>
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OverlayModal>
  );
};
