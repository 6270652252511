import { useNavigate, useParams } from "react-router-dom";
import { EnumContentType } from "../../Types/ContentTemplate/ContentType";
import NewArticle from "./ContentTabs/ArticleTabContent/NewArticle";
import NewAnnouncement from "./ContentTabs/AnnouncementTabContent/NewAnnouncement";
import NewVideo from "./ContentTabs/VideoTabContent/NewVideo";
import NewChecklist from "./ContentTabs/ChecklistTabContent/NewChecklist";
import NewConsent from "./ContentTabs/ConsentTabContent/NewConsent";
import NewFAQ from "./ContentTabs/FAQTabContent/NewFAQ";
import NewSurvey from "./ContentTabs/SurveyTabContent/NewSurvey";
import NewDirection from "./ContentTabs/DirectionTabContent/NewDirection";
import NewSurveySequence from "./ContentTabs/SurveySequenceTabContent/NewSurveySequence";
import NewSelector from "./ContentTabs/SelectorTabContent/NewSelector";
import { AppButton } from "../../Theme";

const NewContentTemplatePage = () => {

  const navigate = useNavigate()
  const params = useParams<{ templateSlug: string }>()


  const navigateBackToContentTemplates = () => navigate(`/content-templates/${params.templateSlug as string}`)

  return (
    <section className='relative'>
      <div className="border border-[borderGray] bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
          <AppButton variant='primary' size='xs' type='button' onClick={navigateBackToContentTemplates}>
            <div className="flex items-center gap-1">
              <span className="text-xs capitalize">Back to {params.templateSlug}s</span>
            </div>
          </AppButton>
      </div>
      {(() => {
        switch (params.templateSlug) {
          case EnumContentType.ArticleContent:
            return <NewArticle />;
          case EnumContentType.AnnouncementContent:
            return <NewAnnouncement />
          case EnumContentType.VideoContent:
            return <NewVideo />;
          case EnumContentType.ChecklistContent:
            return <NewChecklist />
          case EnumContentType.ConsentContent:
            return <NewConsent />
          case EnumContentType.FAQContent:
            return <NewFAQ />
          case EnumContentType.SurveyContent:
            return <NewSurvey />;
          case EnumContentType.DirectionContent:
            return <NewDirection />;
          case EnumContentType.SurveySequenceContent:
            return <NewSurveySequence />
          case EnumContentType.SelectorContent:
            return <NewSelector />
          default:
            return <></>;
        }
      })()}
    </section>
  )
}

export default NewContentTemplatePage;