import React, { memo, useContext, useMemo } from 'react';
import { useQuery } from "react-query";
import { Single_Content_R } from "../../Common/Types";
import { getContent } from "../../apiQuery/Contents/Content.apis";
import { MdClose } from "react-icons/md";
import TypeIcon from "../List/TypeIcon";
import { useParams } from "react-router-dom";
import { store } from "../../GlobalState/store";
import Mobile from "./ContentPreview/Mobile";
import PreviewContentModal from "./ContentPreview/PreviewContentModal";
import ViewEvent from "../Event/ViewEvent";

type props = {
    modalHandle: () => void,
    content_slug: string
}

const ViewContentModal = memo(({ modalHandle, content_slug }: props) => {

    const {
        state: globalState,
    } = useContext(store);
    // const organization_id = useMemo(() => globalState.organization_id, [globalState.organization_id])
    const params = useParams();
    const id = params.stateSlug || ""
    const pathwaySlug = params.slug || ""

    const {
        isLoading,
        isError,
        status,
        data,
        error,
        refetch,
        isFetching
    } = useQuery<Single_Content_R, Error>(['content', content_slug], () => getContent(globalState.currentFlow.slug, id, content_slug), {
        enabled: !!content_slug
    })

    const title = useMemo(() => data?.data?.content?.video?.title || data?.data?.content?.article?.title || data?.data?.content?.checklist?.title || data?.data?.content?.direction?.title || data?.data?.content?.selector?.title || data?.data?.content?.faq?.title || data?.data?.content?.announcement?.title || data?.data?.content?.survey?.title || data?.data?.content?.consent?.title || "", [data])


    const description = useMemo(() => data?.data?.content?.article?.description || data?.data?.content?.video?.description || data?.data?.content?.checklist?.description || data?.data?.content?.direction?.description || data?.data?.content?.selector?.description || data?.data?.content?.faq?.description || data?.data?.content?.announcement?.description || data?.data?.content?.survey?.description || data?.data?.content?.consent?.description || "", [data])

    // const altTitle = useMemo(() => data?.data?.content?.alt_title || data?.data?.content?.alt_title || data?.data?.content?.checklist?.alt_title || data?.data?.content?.direction?.alt_title || data?.data?.content?.selector?.alt_title || data?.data?.content?.faq?.alt_title || data?.data?.content?.announcement?.alt_title || data?.data?.content?.survey?.alt_title || data?.data?.content?.consent?.alt_title || "", [data])

    const survey_items = useMemo(() => data?.data?.content?.survey?.survey_items || [], [data])

    return (
        <div
            className="relative flex w-8/12 h-full p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md modal">
            {
                isLoading ? (
                    "Loading..."
                ) :
                    isError ? (
                        <span>Error: {error?.message || "Something went wrong!"}</span>
                    ) :
                        <div className="w-full space-y-1">
                            <div className="flex items-center justify-between w-full text-lg">
                                <div />
                                <div onClick={modalHandle} className="p-1 bg-gray-400 rounded-full cursor-pointer">
                                    <MdClose className="text-white" />
                                </div>

                            </div>

                            <div className="flex h-full space-x-5">
                                <div className="w-7/12 divide-y-2 divide-[#D8D8D8] px-8">

                                    <div className="space-y-4">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-1 text-blue-600">
                                                <TypeIcon type={data?.data?.content?.content_type?.toLowerCase()} />
                                                <span
                                                    className="uppercase">{data?.data?.content?.content_type}</span>
                                            </div>
                                        </div>

                                        <p className="text-2xl ">{title}</p>
                                        <p className="text-lg ">{description}</p>
                                        <p><span>Alternative Title: </span><span>{data?.data.content.alt_title}</span></p>

                                        <hr className="border-gray-300" />

                                        {/*{*/}
                                        {/*    data?.data?.content?.is_template !== undefined &&*/}
                                        {/*    <div>Content{*/}
                                        {/*        data?.data?.content?.is_template ?*/}
                                        {/*            <span className="inline text-blue-600"> linked </span> :*/}
                                        {/*            <span className="inline text-orange-400"> not linked </span>*/}
                                        {/*    }to a template*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                    </div>

                                    <ViewEvent rules_id={data?.data?.content?.rules_content_id || undefined}
                                        is_time_base={data?.data?.content?.is_time_base}
                                        time_condition={data?.data?.content?.time_condition}
                                        rule_name={data?.data?.rule?.rule_name || ""} />

                                </div>

                                <hr className="border border-[#D8D8D8] h-4/5" />

                                <Mobile>
                                    <PreviewContentModal
                                        address={data?.data?.content?.direction?.address}
                                        survey={survey_items}
                                        video={data?.data?.content?.video?.file}
                                        body={data?.data?.content?.article?.body || data?.data?.content?.announcement?.body || data?.data?.content?.video?.body || data?.data?.content?.consent?.body}
                                        variants={data?.data?.content?.selector?.variants}
                                        description={description}
                                        selectorItems={data?.data?.content?.selector?.items}
                                        questions={data?.data?.content?.faq?.questions}
                                        items={data?.data?.content?.checklist?.items}
                                        tags={data?.data?.content?.tags}
                                        image={data?.data?.content?.cover_image}
                                        type={data?.data?.content?.content_type || ""}
                                        // type={data?.data?.content?.survey ? data?.data?.content?.survey?.is_2_value ? 'survey' : 'survey-7' : data?.data?.content?.content_type || ""}
                                        title={title} />
                                </Mobile>


                            </div>

                        </div>
            }

        </div>
    );
});

export default ViewContentModal;