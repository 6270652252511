// Custom imports
import Button from "./Button";

// Style imports
import "./_general.css";
import React, { FC, ReactNode } from "react";
import { MdClose } from "react-icons/md";

/// -----------------------------------------------------------------------------------------------------------------------
/// The Prompt component is used any where a simple input or action prompt should display
/// -----------------------------------------------------------------------------------------------------------------------

type promptType = {
  title: string;
  description: string;
  confirmButtonText: string;
  cancelButtonText: string;
  confirmButtonIcon?: ReactNode;
  cancelButtonIcon?: ReactNode;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  children?: JSX.Element;
};

const Prompt: FC<promptType> = ({
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  confirmButtonIcon,
  cancelButtonIcon,
  onClickConfirm,
  onClickCancel,
  children,
}) => {
  return (
    <>
      <div className="prompt flex flex-col">
        <div className="prompt-header flex justify-between">
          <div>
            <p>{title}</p>
          </div>
          <div
            onClick={onClickCancel}
            className="bg-gray-400 cursor-pointer rounded-full p-1 hover:opacity-80"
          >
            <MdClose className="text-white" />
          </div>
        </div>
        <div className="prompt-body">
          <p>{description}</p>
          {children}
          <div className="flex flex-row justify-end">
            <Button
              label={cancelButtonText}
              onClick={onClickCancel}
              extraClasses="bg-gray-600-alt text-sm pl-3 pr-3 pt-2 pb-2"
            />
            {onClickConfirm && (
              <Button
                label={confirmButtonText}
                onClick={onClickConfirm}
                extraClasses={
                  confirmButtonText === "Confirm Delete"
                    ? "bg-red-600 text-sm pl-3 pr-3 pt-2 pb-2"
                    : "bg-gray-600-alt text-sm pl-3 pr-3 pt-2 pb-2"
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Prompt;
