import {Box, LinearProgress, LinearProgressProps} from "@mui/material";
import React from "react";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <p>{`${Math.round(
                props.value,
            )}%`}</p>
        </Box>
    );
}

export default LinearProgressWithLabel
