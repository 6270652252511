import { FC, useEffect, useState } from "react";
import { CopyIcon, InfoIcon } from "../Icons";
import { TransitionAnimation } from "../Transition";

interface CustomClipboardProps {
    clipboardText: string
    showInfoIcon: boolean
    showClipboardText?:boolean
}

export const CustomClipboard: FC<CustomClipboardProps> = (props) => {
    const { clipboardText, showInfoIcon, showClipboardText } = props
    const [isCopied, setIsCopied] = useState<boolean>(false)
    
    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false)
            }, 3000)
        }
    }, [isCopied])


    return (
        <>
            <div className='flex items-center gap-1'>
                {
                    showInfoIcon ? <InfoIcon /> : null
                }
                <p className="text-sm text-textDark">{showClipboardText ? clipboardText : null}</p>
                <button type="button" onClick={() => {
                    setIsCopied(prev => true)
                    if (typeof window !== "undefined") {
                        window.navigator.clipboard.writeText(clipboardText)
                    }
                }}>
                    <CopyIcon className="w-4 h-4 cursor-pointer text-grayColor" />
                </button>
                <TransitionAnimation show={isCopied} position="x-axis">
                    <p className="text-xs text-blueColor">Copied!</p>
                </TransitionAnimation>
            </div>
        </>
    )
}