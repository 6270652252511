import React, {FC, useEffect, useState} from 'react';
import {ContCommon} from "../../../Common/Types";
import {GoogleMap, useLoadScript, Marker} from "@react-google-maps/api";
import {getGeocode, getLatLng} from "use-places-autocomplete";
import {MdLocationPin} from "react-icons/md";

const DirectionPreview: FC<ContCommon> = (prop) => {

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    });

    if (!isLoaded) return <div>Loading...</div>;
    return <Map {...prop}/>;
};

const Map: FC<ContCommon> = ({address}) => {

    const [marker, setMarker] = useState({lat: 44, lng: -80})

    useEffect(() => {
        const getCordinate = async () => {
            const results = await getGeocode({address});
            const {lat, lng} = await getLatLng(results[0]);

            setMarker({lat, lng})
        }
        getCordinate()

    }, []);

    return (
        <>
            <GoogleMap zoom={10} center={marker} mapContainerClassName="map-container">
                <Marker position={marker}/>
            </GoogleMap>
            <br/>
            <span onClick={() => window.open(`https://maps.google.com/?q=${address}`, "_blank")}
                  className="flex items-center justify-center bg-organization-secondary pl-4 pr-4 pt-2 pb-2 rounded-md mt-4 cursor-pointer">
                <MdLocationPin className="mr-2"/>
                Navigate to this address
            </span>
        </>
    );
}

export default DirectionPreview