import { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { LanguagesOptionsList, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteContentTemplateById, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { ISelector, ISelectorItem, IVariant, SelectorContentCreateOrUpdateRequest, SelectorContentResponse, SelectorContentTemplate, initialSelectorContent } from '../../../../Types/ContentTemplate/Selector';
import AllSelectorContent from './AllSelectorContent';
import SelectorItem from './SelectorItem';
import NewUseUploadFile from '../../../../Hook/NewUseUploadFile';
import { baseURL } from '../../../../apiQuery/index';
import UseUpload from '../../../../Hook/UseUpload';
import UploadProgress from '../../../General/UploadProgress';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { useNavigate } from 'react-router-dom';
import InlineSelectorVariant from "./InlineSelectorVariant"
import { v4 as uuidv4 } from 'uuid'
import InlineSelectorItem from "./InlineSelectorItem";


export type Language = "en" | "fr" | "es"

const initialVariant = {
	id: uuidv4(),
	content: ''
}




interface SelectorSequenceTabContentProps {
	templateId: number,
}
const SelectorTabContent: FC<SelectorSequenceTabContentProps> = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [showInput, setShowInput] = useState({ id: null, isInput: false });
	const [selectorContent, setSelectorContent] = useState<SelectorContentTemplate>(initialSelectorContent)


	const queryClient = useQueryClient()

	// upload conver image
	const [src, selectorCoverImageFile, renderedTag, selectorCoverFileName] = NewUseUploadFile('Selector', selectorContent?.content_metadata?.cover_image)
	// const { uploadForm: uploadSelectorForm, itemCoverImageFile: uploadSelectorProgress, isLoading: isSelectorLoading } = UseUpload(
	// 	`${baseURL}upload`
	// );

	const [uploadState, setUploadStateSelector] = useState({
		selectedFile: selectorCoverImageFile,
		fileName: selectorCoverFileName,
	})

	useEffect(() => {
		if (selectorCoverFileName && selectorCoverImageFile) {
			setUploadStateSelector({
				selectedFile: selectorCoverImageFile,
				fileName: selectorCoverFileName,
			})
		}
	}, [selectorCoverImageFile, selectorCoverFileName])




	const [imageSrc, itemCoverImageFile, render, itemCoverImageName, setItemCoverImageSrc] = NewUseUploadFile('Item')
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);




	const [uploadStateItem, setUploadStateItem] = useState({
		file: itemCoverImageFile,
		name: itemCoverImageName,
	})

	useEffect(() => {

		if (itemCoverImageName && itemCoverImageFile) {
			setUploadStateItem({
				file: itemCoverImageFile,
				name: itemCoverImageName,
			})
		}
	}, [itemCoverImageName, itemCoverImageFile])


	const { currentOrganizationID } = useContext(UserContext);



	const [state, setState] = useState<SelectorContentTemplate[]>([])



	const createOrUpdateButton = useRef<string>("Create")
	const allSelectorContentsRef = useRef(null)
	const createOrUpdateItemButton = useRef<string>("Create")
	const createOrUpdateVariantButton = useRef<string>("Add")
	const createOrUpdateSelectorItemButton = useRef<string>("Add")
	// ==================================================================================================================

	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);


	const [selectedLanguage, setSelectedLanguage] = useState<string>('')

	const { data: selectorData, refetch } = useQuery<SelectorContentResponse, Error>
		(`selectorData-content-${props.templateId}`, () => getContentTemplateById(props.templateId));

	useEffect(() => {
		if (selectorData?.data) {
			editableSelectorContent(selectorData?.data)
		}
	}, [selectorData?.data])

	// useEffect(() => {
	// 	if (selectedLanguage === '') return
	// 	// get seleted index of the content template to be displayed as the current content
	// 	const selectedIndex = selectorData?.data['selector'].findIndex((item: any) => item.language === selectedLanguage) as number
	// 	const checklist: ISelector = selectorData?.data['selector'][selectedIndex] as ISelector
	// 	const contentMetaData = selectorData?.data.content_metadata[selectedIndex] as IContentMetadata
	// 	setSelectorContent({
	// 		selector: checklist,
	// 		content_metadata: contentMetaData
	// 	})
	// 	createOrUpdateButton.current = "Update"
	// }, [selectedLanguage])

	const deleteTemplateFromContentTemplateList = async () => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/selector', { state: { deleted: true } })
	}
	const deleteLanguageTemplateFromContentTemplate = async () => {
		console.log(state, selectedLanguage, 'selectortabcontent')
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.selector.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}

	//=====================================================
	//set english default language for content
	// useEffect(() => {
	// 	const selector = selectorData?.data['selector'].find(item => item.language === "en")
	// 	const contentMetaData = selectorData?.data.content_metadata.find(item => item.language === "en")
	// 	// const index = selectorData?.data['article'].findIndex(item => item.language === "en") as number


	// 	setSelectorContent({
	// 		selector: selector as ISelector,
	// 		content_metadata: contentMetaData as IContentMetadata
	// 	})
	// 	createOrUpdateButton.current = "Update"
	// 	setSelectedLanguage("en")

	// }, [selectorData?.data])

	//make english default language for content template
	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setSelectorContent({
				selector: selectorData?.data.selector.find((item: ISelector) => item.language === LanguageEnum.English) as ISelector,
				content_metadata: selectorData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			createOrUpdateButton.current = "Update"
		}
	}, [selectorData?.data.selector, selectorData?.data.content_metadata])

	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.selector.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges

	}, [state])



	// ============================================= Mutations ===========================================================
	const updateSelectorContentMutation = useMutation(
		(params: { id: number, data: SelectorContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Content updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
	//=====================================================
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})


	// handlers
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target

		setSelectorContent(prev => {
			return {
				...prev,
				selector: {
					...prev.selector,
					[name]: value
				}
			}
		})
	}

	// delete and edit
	const deleteSelectorContentHandler = (language: Language) => {
		setState(prev => prev.filter(item => item.selector.language !== language))
	}

	const editSelectorContentHandler = (language: Language) => {
		const item = state.find(item => item.selector.language === language)
		if (item) {

			setVariants(item.selector.variants.map(i => ({ ...i, id: uuidv4() })))
			setSelectorItems(item.selector.items.map(i => ({ ...i, appId: uuidv4() })))
			setSelectorContent(item)
			createOrUpdateButton.current = "Update"
		}
	}


	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		// validation
		if (selectorContent.selector.title === '') {
			toast('Title is required', 'error')
			return
		}


		// ================================================ CREATE ================================================
		if (createOrUpdateButton.current === "Create") {
			// check if the language is already exist
			if (state.some(item => item.selector.language === selectorContent.selector.language)) {
				toast('This Selector is already exist', 'error')
				return
			}

			let _cover_imageLink = ''

			if (uploadState.fileName !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.selectedFile);
				const { link } = await uploadForm(formData)
				_cover_imageLink = link
			}

			const selectorContentWithImage: SelectorContentTemplate = {
				selector: {
					...selectorContent.selector,
					variants: variants,
					items: selectorItems
				},
				content_metadata: {
					...selectorContent.content_metadata,
					cover_image: _cover_imageLink,
				}
			}
			// setState(prev => [...prev, selectorContentWithImage])
			const newState = [...state, selectorContentWithImage]
			await createOrUpdateContentTemplate(newState)


		} else {
			// ================================================ UPDATE ================================================

			let cover_image = selectorContent?.content_metadata?.cover_image || ''

			if (uploadState.fileName !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.selectedFile);
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}

			// update the state
			const newState = state.map(item => {
				if (item.selector.language === selectorContent.selector.language) {
					return {
						selector: {
							...selectorContent.selector,
							variants: variants.map(i => ({ ...i, id: uuidv4() })),
							items: selectorItems/* .map(i => ({id: i.id, description: i.description, image: i.image, language: item.selector.language, title: i.title})) */
						},
						content_metadata: {
							...selectorContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})


			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = "Create"
		}

		// 	empty the fields
		setSelectorContent(initialSelectorContent)
		setUploadStateSelector({
			fileName: undefined,
			selectedFile: '',
		})
		setUploadStateItem({
			file: undefined,
			name: '',
		})

	}

	const createOrUpdateContentTemplate = async (data: SelectorContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one Selector template', 'info')
			return
		}

		const shapeData: SelectorContentCreateOrUpdateRequest = {
			content_type: EnumContentType.SelectorContent,
			selector: data.map((item, index) => {
				return {
					...item.selector,
					title: item.selector.title,
					description: item.selector.description,
					items: item.selector.items.map(i => ({ id: i.id, description: i.description, image: i.image, language: item.selector.language, title: i.title })),
					variants: item.selector.variants.map(v => (v.content)),
					language: item.selector.language as LanguageEnum,
				}
			}),
			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.selector.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}
		await updateSelectorContentMutation.mutateAsync({ id: props.templateId, data: shapeData })
		setState([])
		// setVariants([])
		setVariant(initialVariant)
		// setSelectorItems([])
		setSelectedLanguage("")
		// // @ts-ignore
		// allSelectorContentsRef.current?.refresh()
	}


	const editableSelectorContent = (selectorContentTemplate: any) => {
		const combinedData: { selector: ISelector, content_metadata: IContentMetadata }[] = [];

		// Loop through each selector
		selectorContentTemplate.selector.forEach((selector: ISelector) => {
			// Loop through each content metadata
			selectorContentTemplate.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (selector.language === metadata.language) {
					// Combine the properties of the selector and metadata objects
					const combinedObj = {
						selector: selector,
						content_metadata: metadata
					};
					// Add the combined object to the final array
					combinedData.push(combinedObj);

				}
			});

		});

		const selectedSelector = combinedData.find(d => d.selector.language === selectedLanguage)?.selector
		const selectedVariants = selectedSelector?.variants
		if (selectedVariants) {
			setVariants(selectedVariants.map((i: any) => ({ content: i, id: uuidv4() })))
		}


		const selectedItems = selectedSelector?.items
		if (selectedItems) {
			setSelectorItems(selectedItems.map((i: any) => ({ ...i, appId: uuidv4() })))
		}


		setState(combinedData)
	}


	const [item, setItem] = useState<ISelectorItem>({
		id: 0,
		appId: uuidv4(),
		description: '',
		title: '',
		language: LanguageEnum.English,
		image: ''
	})



	const [variants, setVariants] = useState<IVariant[]>([])
	const [variant, setVariant] = useState<IVariant>({ id: null, content: '' })



	const [selectorItem, setSelectorItem] = useState<ISelectorItem>({
		id: 0,
		appId: uuidv4(),
		description: '',
		title: '',
		language: LanguageEnum.English,
		image: ''
	})

	const [selectorItems, setSelectorItems] = useState<ISelectorItem[]>([])

	const addItemToItems = async (event: any) => {
		event.preventDefault()
		event.stopPropagation()
		if (selectorItem.title === '') {
			toast('Please fill add a item title', 'info')
			return
		}

		let itemImageLink = ''


		if (uploadStateItem.file !== undefined) {
			const formData = new FormData();
			// @ts-ignore
			formData.append("file", uploadStateItem.file);
			const { link } = await uploadForm(formData)
			if (link) {
				setItemCoverImageSrc(null)
			}
			itemImageLink = link
		}

		const newSelectorItem: ISelectorItem = {
			id: 0,
			language: selectorItem.language, title: selectorItem.title, description: selectorItem.description, image: itemImageLink, appId: uuidv4()
		}
		setSelectorItems(prev => [...prev, newSelectorItem])


		setSelectorItem({
			id: 0,
			appId: uuidv4(),
			description: '',
			title: '',
			language: LanguageEnum.English,
			image: ''
		})

		setUploadStateItem({
			file: undefined,
			name: '',
		})

	}


	return (
		<Fragment>
			<ViewContentTemplateHeader
				title={selectorData?.data.selector[0].title || ''}
				referencesCount={selectorData?.data.linked_content_count || 0}
			/>
			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{
					// props.data[props.data.content_type]
					selectorData?.data.selector.sort((a, b) => {
						// Define a language order
						const languageOrder = { en: 0, es: 1, fr: 2 };

						// Get the language order for each object
						const orderA = languageOrder[a.language] || Infinity;
						const orderB = languageOrder[b.language] || Infinity;

						// Compare the language order
						return orderB - orderA;
					}).map((item: any, idx: number) => {
						return (
							<div key={item.id || item.language}>
								<AppButton
									key={idx}
									variant={
										selectedLanguage === item.language ? 'info' : 'primary'
									}
									type="button"
									size="xs"
									onClick={() => {
										if (selectedLanguage === item.language) {
											setSelectorContent(initialSelectorContent)
											setSelectedLanguage('')
											createOrUpdateButton.current = 'Create'
										} else {
											// editableSelectorContent(selectorData.data)
											// setVariants(item.variants)
											// setSelectorItems(item.items)

											createOrUpdateButton.current = "Update"
											currentLanguage.current = item.language as LanguageEnum
											const foundSelector = selectorData.data.selector.find((selector) => selector.language === item.language) as ISelector
											setSelectorContent({
												selector: foundSelector,
												content_metadata: selectorData.data.content_metadata.find((metadata: IContentMetadata) => metadata.language === item.language) as IContentMetadata
											})
											setVariants(foundSelector.variants.map((i: any) => ({ content: i, id: uuidv4() })))
											setSelectorItems(foundSelector.items.map((i: any) => ({ ...i, appId: uuidv4() })))
											setSelectedLanguage(item.language)
										}
									}}
								>
									<div className="flex items-center justify-center gap-1">
										<LanguageIcon className="w-3 h-3" />
										<span className="text-xs">
											{getLangaugeLabel(item.language)}
										</span>
									</div>
								</AppButton>
							</div>
						)
					})
				}
				{state.length < 3 ? (
					<AppButton
						variant={'primary'}
						type="button"
						size="xs"
						onClick={() => {
							setSelectorContent(initialSelectorContent)
							setVariants([])
							setSelectedLanguage('')
							setSelectorItems([])
							createOrUpdateButton.current = 'Create'
						}}
					>
						<span className="text-xs">{t('new_language')}</span>
					</AppButton>
				) : null}
			</div>

			<TabContentLayout>
				<div className="hidden px-5 py-2 my-2">
					<AllSelectorContent
						ref={allSelectorContentsRef}
						onEditClick={editableSelectorContent}
					/>
					<hr className="my-2 border border-blue-800" />
					{state.length > 0 ? (
						<div className="p-3 rounded bg-blue-50">
							<div>
								<AppButton
									type="button"
									size="sm"
									variant="secondary"
									extendClass="my-2"
									onClick={() => {
										setState([])
										setSelectorContent(initialSelectorContent)
										setUploadStateSelector({
											fileName: undefined,
											selectedFile: '',
										})
										setUploadStateItem({
											file: undefined,
											name: '',
										})
										createOrUpdateButton.current = 'Create'
									}}
								>
									close
								</AppButton>
								<div className="grid grid-cols-3 gap-2">
									{state.map((item, idx: number) => {
										return (
											<SelectorItem
												key={idx}
												item={item.selector}
												onDeleteClick={deleteSelectorContentHandler}
												onEditClick={editSelectorContentHandler}
											/>
										)
									})}
								</div>
							</div>
						</div>
					) : null}
				</div>

				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">
					{selectorContent?.selector?.title}
				</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">
					<div className="flex w-full gap-2 mb-8">
						<AppButton
							size="xs"
							disabled={
								selectorContent?.selector?.title === '' ||
									updateSelectorContentMutation.isLoading ||
									deleteContentTemplateByID.isLoading
									? true
									: false
							}
							variant="primary"
							type="submit"
						>
							{createOrUpdateButton.current} Content Template
						</AppButton>
						<AppButton
							disabled={selectedLanguage ? false : true}
							size="xs"
							variant="primary"
							type="button"
							onClick={() => setShowPopupDeleteConfirmation(true)}
						>
							{t('content_template.delete_content_template')}
						</AppButton>

						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>
					</div>
					<hr className="mb-8" />

					<AppInput
						autoFocus
						placeholder={'Title'}
						value={selectorContent?.selector?.title}
						name="title"
						isFull
						onChange={handleChange}
						label="Title"
						required
					/>
					<AppTextArea
						placeholder={'Description'}
						rows={4}
						value={selectorContent?.selector?.description}
						name="description"
						onChange={handleChange}
						label="Description"
						required
					/>

					<div className="my-4 border rounded border-borderGray">
						{/* ADD new items */}
						<div className="flex flex-col items-start justify-start p-2">
							<div className="w-full p-2 rounded">
								<p className="p-2 mt-2 mb-2 text-2xl font-semibold">{t('Variants')}</p>
								{variants.map((variant, idx) => {
									return (
										<div
											key={variant.id}
										>
											<InlineSelectorVariant
												setSelectorContent={setSelectorContent}
												setShowInput={setShowInput}
												idx={idx}
												setVariant={setVariant}
												setVariants={setVariants}
												showInput={showInput}
												showInputId={showInput.id}
												item={variant}
											/>
										</div>
									)
								})}

								<AppInput
									placeholder={'Variant'}
									value={variant.content}
									name="variant"
									isFull
									onChange={(e) => setVariant({ ...variant, content: e.target.value })}
									label="Variant"
									extendClass='my-2'
								/>
								<AppButton
									variant={'info'}
									type={'button'}
									onClick={() => {
										if (variant.content === '') {
											toast('Please fill the fields and answer fields', 'info')
											return
										}
										setVariants((prev) => [...prev, { ...variant, id: uuidv4() }])
										setVariant(initialVariant)
									}}
									extendClass="my-2"
								>
									Add Variant
								</AppButton>
							</div>

							<div className="w-full p-2 my-2 rounded">
								<p className="mb-2 text-2xl font-semibold">{t('Items')}</p>

								<div>
									{selectorItems?.map((item, index) => (
										<div key={item.appId}>
											<InlineSelectorItem selectorItems={selectorItems} setSelectorItems={setSelectorItems} setSelectorItem={setSelectorItem} item={item} index={index} />
										</div>
									))}
								</div>

								{/* <div className="pt-2 mb-1 space-y-2">
									{selectorItems?.map((item, index) => (
										<div
											className="flex items-center w-full space-x-2"
											key={index}
										>
											<div className="flex items-center w-full space-x-4">
												<NestedContentListItem label={item.title} />
												{item.image ? (
													<img
														className="h-10"
														src={item.image}
														alt="itemImage"
													/>
												) : null}
												<MdModeEditOutline
													onClick={() => {
														setSelectorItem(item)
														setSelectorItems((prev) => {
															return prev.filter((_, i) => i !== index)
														})
														createOrUpdateSelectorItemButton.current = 'Update'
													}}
													className="text-2xl cursor-pointer"
												/>
											</div>
											<AiFillDelete
												onClick={() => {
													setSelectorItems((prev) => {
														return prev.filter((_, i) => i !== index)
													})
													createOrUpdateSelectorItemButton.current = 'Create'
												}}
												className="text-3xl cursor-pointer"
											/>
										</div>
									))}
								di</div> */}

								<AppInput
									placeholder={'Title'}
									value={selectorItem.title}
									label="Title"
									name="title"
									onChange={(e) =>
										setSelectorItem((prev) => {
											return { ...prev, title: e.target.value }
										})
									}
									extendClass=""
									isFull
								/>

								<AppTextArea
									label="Description"
									rows={2}
									placeholder={'Description'}
									value={selectorItem.description}
									name="description"
									onChange={(e) =>
										setSelectorItem((prev) => {
											return { ...prev, description: e.target.value }
										})
									}
									extendClass=""
									isFull
								/>
								{render}
								<AppButton
									variant={'info'}
									type={'button'}
									onClick={addItemToItems}
									extendClass="mb-1 my-1"
								>
									Add Item
								</AppButton>
							</div>
						</div>
					</div>

					{/* ================= EXTRA FEILDS ================ */}

					{/* <AppInput
						placeholder={'Alternative Title'}
						value={selectorContent?.content_metadata?.alt_title || ''}
						name="alt_title"
						isFull
						onChange={(e) => {
							setSelectorContent({
								...selectorContent,
								content_metadata: {
									...selectorContent.content_metadata,
									alt_title: e.target.value,
								},
							})
						}}
						label="Alternative Title"
						maxLength={30}
						extendClass="my-2"
					/> */}

					<AppInput
						placeholder={'Preview Title'}
						value={selectorContent?.content_metadata?.preview_title || ''}
						name="preview_title"
						isFull
						onChange={(e) => {
							setSelectorContent((prev) => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										preview_title: e.target.value,
									},
								}
							})
						}}
						label="Preview Title"
						required
						extendClass="my-2"
						maxLength={140}
					/>
					<AppInput
						placeholder={'Tag'}
						value={selectorContent?.content_metadata?.tags?.[0] || ""}
						name="tags"
						isFull
						onChange={(e) => {
							setSelectorContent((prev) => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										tags: [e.target.value],
									},
								}
							})
						}}
						label="Tag"
						required
						extendClass="my-2"
					/>
					{/* {selectorContent?.content_metadata?.cover_image ? (
						<div className="cover__image-container">
							<img
								alt="cover_image"
								src={selectorContent?.content_metadata?.cover_image}
								className="my-2 rounded-sm"
							/>
						</div>
					) : null} */}

					<div className="cover__image-container">

						{renderedTag}
					</div>
					{uploadState.fileName !== undefined ? (
						<p>{uploadState.fileName}</p>
					) : null}
					{isLoading ? <UploadProgress progress={progress} /> : null}
					{/* <AppDropDown
						disabled={createOrUpdateButton.current === 'Update' ? true : false}
						label={'Language'}
						options={LanguagesOptions}
						value={selectorContent?.selector?.language || ''}
						name="language"
						onChange={handleChange}
					/> */}
					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages}
							value={selectorContent?.selector?.language || ''}
							name="language" onChange={handleChange} />
					}
				</form>
			</TabContentLayout>

			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</Fragment>
	)
}


export default SelectorTabContent
