import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";
import { ISurvey, ISurveyForServer } from "./Survey";
import { v4 as uuidv4 } from "uuid";

// for the create and update the survey sequence
// to create with survey sequnce content type id is going to be 0



export interface ISurveySequence {
  id?: number
  appId: string
  title: string
  description: string
  language: LanguageEnum
  surveys: ISurvey[],
  updated_at?: string
}

export interface ISurveySequenceForServer {
  id?: number
  title: string
  description: string
  language: LanguageEnum
  surveys: ISurveyForServer[],
  updated_at?: string
}

export interface ISurveySequenceContentTemplateForm {
  survey_sequence: ISurveySequenceForServer
  content_metadata: IContentMetadata
}

export type ISurveySequenceContentTemplate = {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  organization_id: number;
  content_type: string;
  linked_content: any;
  linked_content_count: number;
  content_metadata: IContentMetadata[];
  survey_sequence: ISurveySequence[];
}

export type ISurveySequenceContentTemplateForServer = {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  organization_id: number;
  content_type: string;
  linked_content: any;
  linked_content_count: number;
  content_metadata: IContentMetadata[];
  survey_sequence: ISurveySequenceForServer[];
}


export type SurveySequenceContentResponse = {
  data: ISurveySequenceContentTemplateForServer
  error: boolean
  message: string
  status: number
}
export type SurveySequenceContentUpdatedResponse = {
  data: ISurveySequenceContentTemplate
  error: boolean
  message: string
  status: number
}



export type SurveySequenceContentTemplate = {
  survey_sequence: ISurveySequence;
  content_metadata: IContentMetadata;
}


export interface SurveySequenceContentCreateOrUpdateRequest {
  content_type: EnumContentType;
  survey_sequence: ISurveySequenceForServer[];
  content_metadata: IContentMetadata[];
}

export const initialSurveySequence: SurveySequenceContentTemplate = {
  survey_sequence: {
    id: 0,
    appId: uuidv4(),
    title: "",
    description: "",
    language: LanguageEnum.English,
    surveys: [],
  },
  content_metadata: {
    id: 0,
    cover_image: '',
    preview_title: '',
    tags: [''],
    language: LanguageEnum.English,
    alt_title: '',
  }
};

