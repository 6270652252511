import React, {memo, useContext, useMemo} from 'react';
import {useQuery} from "react-query";
import {Single_Action_R} from "../../Common/Types";
import {MdClose} from "react-icons/md";
import {getAction} from "../../apiQuery/Actions/Actions.apis";
import TypeIcon from "../List/TypeIcon";
import PreviewActionModal from "./ActionPreview/PreviewActionModal";
import {useParams} from "react-router-dom";
import {store} from "../../GlobalState/store";
import ViewEvent from "../Event/ViewEvent";
import {CircularProgress} from "@mui/material";

type props = {
    modalHandle: () => void,
    action_slug: string
}

const ViewActionModal = memo(({modalHandle, action_slug}: props) => {

    const {
        state: globalState
    } = useContext(store);
    const organization_id = useMemo(() => globalState.organization_id, [globalState.organization_id])
    const params = useParams();
    const id = params.stateSlug || ""

    const {
        isLoading,
        isError,
        data,
        error,
    } = useQuery<Single_Action_R, Error>(['action', action_slug], () => getAction(organization_id, globalState.currentFlow.slug, id, action_slug), {
        enabled: !!action_slug
    })

    // @ts-ignore
    const title = useMemo(() => data?.data?.action?.title || data?.data?.action?.subject || data?.data?.action?.text ||  "", [data])
    // @ts-ignore
    const description = useMemo(() => data?.data?.action?.description || data?.data?.action?.body || "", [data])
    // @ts-ignore
    const subtitle = useMemo(() => data?.data?.action?.subtitle || "", [data])

    return (
        <div
            className="relative flex w-8/12 h-full p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md modal">
            {
                isLoading ? (
                        <CircularProgress/>
                    ) :
                    isError ? (
                            <span>Error: {error?.message || "Something went wrong!"}</span>
                        ) : data ?
                        <div className="w-full space-y-6">
                            <div className="flex items-center justify-between w-full text-lg">
                                {/*// @ts-ignore*/}
                                <p className="text-2xl ">{data?.data?.title || data?.data?.text || data?.data?.subject || ""}</p>
                                <div onClick={modalHandle} className="p-1 bg-gray-400 rounded-full cursor-pointer">
                                    <MdClose className="text-white"/>
                                </div>

                            </div>


                            <div className="flex h-full space-x-5">
                                <div className="w-7/12 divide-y-2 divide-[#D8D8D8] px-8 space-y-2">
                                    <div className="space-y-4">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-1 text-blue-600">
                                                <TypeIcon type={data?.data?.action?.action_type.toLowerCase()}/>
                                                <span className="uppercase">{data?.data?.action?.action_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>

                                    <ViewEvent rules_id={data?.data?.action?.rules_action_id || undefined}
                                               is_time_base={data?.data?.action?.is_time_base}
                                               time_condition={data?.data?.action?.time_condition || undefined}
                                               rule_name={data?.data?.rule?.rule_name || ""}/>

                                </div>
                                <hr className="border border-[#D8D8D8] h-4/5"/>

                                <PreviewActionModal
                                    description={description}
                                    subTitle={subtitle}
                                    type={data?.data?.action?.action_type || ""}
                                    title={title}/>
                            </div>

                        </div> : null
            }

        </div>
    );
});

export default ViewActionModal;