import React, {
  lazy,
  useCallback,
  useContext,
  useMemo,
  useState,
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useRef,
} from "react";

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import { withAuth } from "../../../../../hoc/withAuth";
import { useTranslation } from "react-i18next";
import {
  AppButton,
  ArrowIcon,
  CloseIcon,
  EmailIcon,
  EyeIcon,
  FilterIcon,
  Loader,
  CloseEyeIcon,
  THead,
  Table,
  CommandBar,
  DeleteIcon,
  AppDivider,
  TabBar,
  PopupModal,
} from "../../../../../Theme";
import { toast } from "../../../../../utils/Toast"
import {
  getAllOrganizationLinks,
  getSingleOrganizationLinks,
  deleteOrgLink
} from "../../../../../apiQuery/Organization/Organization.apis";
import { Drawer } from "../../../../../Theme/Drawer";
import { ILink, IOrgLInksResponse } from "../../../../../Types/Organization/Organization";
import { useInView } from "react-intersection-observer";
import { TabContext } from "../../../../../Context/TabContext/TabContext";
import OverlayModal from "../../../../Layouts/OverlayModal";
import EditLinkModal from "./Modal/EditLinkModal";
import NewLinkModal from "./Modal/NewLinkModal";
import { getToken } from "../../../../../utils/LocalStorage";

// =============================================================================


//================================================================================

const LinksPage: FC = () => {
  const { globalTabs, updateTab } = useContext(TabContext);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

 

  // =============================================================================

  const fetchLinks = () =>
    getAllOrganizationLinks();


  // const {data} = useQuery<IOrgLInksResponse, Error>(['links'], () => fetchLinks(), { 
  //   enabled: getToken() !== null,
  // })

  const {
    isLoading,
    isError,
    data,
    error,
    refetch,
    isFetching,

  } = useQuery<IOrgLInksResponse, Error>(
    [],
    fetchLinks,);


  // const {
  //   isLoading,
  //   isError,
  //   data,
  //   error,
  //   refetch,
  //   isFetching,
  //   fetchNextPage,
  //   hasNextPage,
  //   isFetchingNextPage,
  // } = useInfiniteQuery<IOrgLInksResponse, Error>(
  //   [],
  //   fetchLinks,
  //   {
  //     onSuccess: (data) => {
  //       const arr = [] as any;
  //       data?.pages?.forEach((item) => item.data?.map((ds) => arr.push(ds)));
  //     },
  //   }
  // );

  // const [tab, setTab] = UseTab("All Links");
  // const [search, setSearch] = useState("");
  const [confirmDelete, setConfirmDelete] = useState({
    isOpen: false,
    id: 0,
  });

  const handleConfirmation = useCallback(
    (id = 0) =>
      setConfirmDelete((prev) => ({
        ...prev,
        isOpen: !prev.isOpen,
        id,
      })),
    []
  );

  
  // ========================== HOOKS =======================================

  const { ref, inView } = useInView();
  const [selectedOrgLinks, setSelectedOrgLinks] = useState<number[]>([]);

  // ========================== New Modal  =====================================

  const [newLinkModal, setNewLinkModal] = useState(false);
  const newLinkModalHandler = useCallback(
    () => setNewLinkModal((prev) => !prev),
    []
  );

  // ========================== Edit Modal  =====================================
  const [selectedLink, setSelectedLink] = useState<ILink>();

  const [editLinkModal, setEditLinkModal] = useState(false);
  const editLinkModalHandler = useCallback(
    (selectedLink:ILink) => {
      setSelectedLink(selectedLink);

      setEditLinkModal((prev) => !prev)}
      ,
    []
  );
  const deleteContentTemplate = useMutation( (params: { id:number }) => deleteOrgLink(params.id), {
		onSuccess: () => {
      RefetchView()
				toast("Link deleted successfully", "success")

		 },
		onError: (error:any) => { 
			
		},
		})

	const cofirmDeleteModal = (item :ILink) => {
		const contentTemplateId = item.id as number
		deleteContentTemplate.mutateAsync({ id: contentTemplateId })
	}
const RefetchView =()=>{
  setTimeout(() => {
    refetch();
  }, 1200);
}
  // ==================================================================
  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 1200);
  }, []);

  const buttonRef = useRef<HTMLButtonElement>(null);
 


  //================================================================================

  const { data: singleLinkData } = useQuery(["get-single-Link", selectedLink?.id],
    () => getSingleOrganizationLinks(selectedLink?.id as number),
    {
      enabled: !!selectedLink?.id,
    }
  );

  const singleLinkVar: any = singleLinkData?.data;


  return (
    <section className="w-full">
      <section className="relative">

        {/* ======================== Filtering Operation START ======================================== */}

        <div className="border border-[borderGray] bg-white border-l-0 border-t-0 px-2 py-2.5 dark:bg-bckDark sm:px-4">
          {/* Filtering Operation */}
          <div className="relative flex flex-wrap items-center justify-between">
            <div className="flex items-center gap-2">
              <AppButton
                ref={buttonRef}
                variant="primary"
                type="button"
                size="xs"
                onClick={() => {
                  newLinkModalHandler();
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 7.573 8.414"
                    className="w-3 h-3 text-grayColor"
                  >
                    <path
                      id="icon_Links"
                      d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                      transform="translate(-6 -4)"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="text-xs">New Link</span>
                  <span>+</span>
                </div>
              </AppButton>
          
            </div>
          
          </div>
        </div>


        <div className="pb-10">
          <div className="w-[97%] py-5 m-auto space-y-5">
            <div className="flex items-center justify-center">
              <Loader isLoading={isLoading} size={35} />
            </div>
            {/* =============== */}
            <Table>
              <THead>
                <tr>
                  <th scope="col" className="py-3 font-medium px-14">
                    Title
                  </th>
                  <th scope="col" className="py-3 font-medium">
                    URL
                  </th>
                  <th scope="col" className="py-3 font-medium">
                    Seen BY
                  </th>
                </tr>
              </THead>
              <tbody>
                {data?.data.map((item: ILink, idx: number) => {
                  return (
                    <tr
                      className="transition-all delay-75 bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer "
                      key={idx}
                    >
                      <th
                        scope="row"
                        className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                      >
                        <div className="flex items-center justify-start">
                          <div className="flex flex-col items-start justify-center p-2">
                            <p
                              className="mb-1 ml-1 text-lg whitespace-nowrap text-textDark dark:text-white">
                              {item?.title}
                            </p>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="row"
                        className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                      >
                        <div className="flex items-center justify-start">
                          <div className="flex flex-col items-start justify-center p-2">
                            <p
                              className="mb-1 ml-1 text-lg whitespace-nowrap text-textDark dark:text-white">
                              {item.url}
                            </p>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="row"
                        className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                      >
                        <div className="flex items-center justify-start">
                          <div className="flex flex-col items-start justify-center p-2">
                            <p
                              className="mb-1 ml-1 text-lg whitespace-nowrap text-textDark dark:text-white">
                              {item.link_seen_by}
                            </p>
                          </div>
                        </div>
                      </th>
                      <td
                      
                        className="py-3 pr-6 font-medium text-textDark whitespace-nowrap dark:text-white">
                        <div className="flex items-center gap-1">


                        <AppButton
                  
                          variant="info"
                          type="button"
                          size={"sm"}
                          onClick={() => {
                            editLinkModalHandler(item);
                          }}
                        >
                          <div className="flex items-center justify-center gap-1">
                            <span className="text-xs">Edit</span>
                          </div>
                        </AppButton>
          
                        <AppButton
                  
                  variant="danger"
                  type="button"
                  size={"sm"}
                  onClick={() => {
                    cofirmDeleteModal(item);
                  }}
                >
                  <div className="flex items-center justify-center gap-1">
                    <span className="text-xs">Delete</span>
                  </div>
                </AppButton>
                        </div>
                      </td>

                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {/* =============== */}
          </div>
        </div>

      
      </section>

        {/* <OverlayModal onClose={newLinkModalHandler} isOpen={newLinkModal}> */}
        {
          newLinkModal ?
          <NewLinkModal modalHandle={newLinkModalHandler}   RefetchView={RefetchView}
          />
          : null
        }
      {/* </OverlayModal> */}
      {editLinkModal?
        <EditLinkModal
          modalHandle={()=>editLinkModalHandler(selectedLink as ILink)}
          RefetchView={RefetchView}
          Link={selectedLink as ILink }
        />:null
      }
    </section>
  );
};

export default withAuth(LinksPage);
