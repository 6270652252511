// import {useAuth0} from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { getToken } from "../../utils/LocalStorage";
// import axios from "axios";

const Authentication = () => {
    // const {isLoading, isAuthenticated, user, loginWithRedirect, logout} = useAuth0();
    const navigation = useNavigate()

    // useEffect(() => {
    //     async function checkUser() {
    //         if (isAuthenticated) return navigation("/workers")
    //         if (!isLoading) loginWithRedirect();
    //     }

    //     checkUser();
    // }, [isAuthenticated,isLoading])


    useEffect(() => {
        const token = getToken()
        if (token) {
            navigation("/workers")
        } else {
            window.location.href = "/login"
        }
    }, [navigation])


    return (

        <div className="flex items-center justify-center flex-1 w-full h-full">
            <CircularProgress />
        </div>
    );
};

export default Authentication;