export const EmailIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.933 7.146"
      aria-hidden="true"
      className={`h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white
  ${className ? ` ${className}` : ""}`}
    >
      <path
        fill="currentColor"
        d="M80.67 263.146a.687.687 0 01-.67-.67v-5.806a.687.687 0 01.67-.67h7.593a.687.687 0 01.67.67v5.806a.687.687 0 01-.67.67zm3.8-3.372l3.8-2.49v-.614l-3.8 2.434-3.8-2.434v.614z"
        transform="translate(-80 -256)"
      ></path>
    </svg>
  );
};

EmailIcon.defaultProps = {
  className: "",
};
