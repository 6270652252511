/* eslint-disable no-unused-vars */

import React, { ChangeEvent, FC, InputHTMLAttributes } from "react";

type IAppTextFieldProps = {
  value?: string;
  disabled?: boolean;
  id: string;
  // kir?: "sm" | "md" | "lg";
  error?: string;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactNode;
  isFull?:boolean
} & InputHTMLAttributes<HTMLInputElement>;

const selectSize = (size: "sm" | "md" | "lg") => {
  switch (size) {
    case "sm":
      return "py-1 text-xs";
    case "md":
      return "py-2.5 text-lg";
    case "lg":
      return "py-3 text-lg";
    default:
      return "py-2 text-base";
  }
};

let classes =
  "block w-full rounded-lg border-transparent bg-primary p-2.5 text-sm text-textDark outline-none focus:border-transparent focus:ring-0";

export const AppInput: FC<IAppTextFieldProps> = (props) => {
  const { icon, error, value, disabled, id, type, onChange, isFull, onBlur, ...rest } =
    props;

  return (
    <div className={`${isFull ? 'w-full': '' } flex flex-col` }>
      <input
        type={type}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChange}
        id={id}
        className={classes}
        placeholder=""
        {...rest}
      />
      {error && <span className="mt-2 text-xs text-red-500">{error}</span>}
    </div>
  );
};

AppInput.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  value: "",
  type: "text",
  disabled: false,
  error: "",
  icon: null,
};

// import React, { InputHTMLAttributes } from 'react'

//
// export type AppInputProps = {
//   value: string
//   name: string
//   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
//   onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
//   isFull?: boolean
//   type?: string
//   label?: string
//   error?: string
//   extendClass?: string
//   disabled?: boolean
// } & InputHTMLAttributes<HTMLInputElement>
//
// const AppInput: React.FC<AppInputProps> = props => {
//   const { label, name, type, value, onChange, error, onBlur, isFull, disabled, extendClass, ...rest } = props
//   return (
//     <div className={`flex flex-col space-y-1 my-2 ${isFull ? 'w-full' : ''}`}>
//       {label && (
//         <label htmlFor={name} className='block mb-1 ml-1 text-xs font-normal text-black dark:text-white'>
//           {label}
//         </label>
//       )}
//       <input
//         name={name}
//         type={type || 'text'}
//         value={value}
//         onChange={onChange}
//         onBlur={onBlur}
//         disabled={disabled}
//         className={`
// 				 block rounded-lg border border-gray-300 bg-gray-50 p-2.5
// 				 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:cursor-not-allowed
// 				 disabled:border-gray-300 disabled:bg-gray-200 disabled:text-gray-800 disabled:opacity-50 dark:border-gray-600
// 				 disabled:opacity-50
// 				 disabled:cursor-not-allowed
// 				 disabled:bg-gray-200
//          disabled:border-gray-300
//          disabled:text-gray-800
// 				 ${extendClass || ''}`}
//         {...rest}
//       />
//       {error && <span className='mt-2 text-xs text-red-500'>{error}</span>}
//     </div>
//   )
// }
//
//
// AppInput.defaultProps = {
//   isFull: false,
//   disabled: false
// }
//
// export default AppInput
