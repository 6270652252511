import { EnumActionType, IActionTemplateFilterResponse } from "../../Types/ActionTemplate/ActionTemplate"
import API from "../index";


export const getStateActionTemplateFilter = async <T, R extends EnumActionType>
	(
		pathway_slug: string,
		state_slug: string,
		action_type: EnumActionType, limit: number = 5, search: string = '', next: string = '', previous: string = '', pageDirection: string = '')
	: Promise<IActionTemplateFilterResponse> => {

	const nextParams = next ? `&next=${next}` : ''
	const previousParams = previous ? `&previous=${previous}` : ''

	let params = ''
	if (pageDirection === 'next') {
		params = nextParams
	} else if (pageDirection === 'previous') {
		params = previousParams
	}


	const response: IActionTemplateFilterResponse = await API.post(`/admin/organizations/pathways/${pathway_slug}/states/not/${state_slug}/action-template/filter?limit=${limit}${params}`,
		{
			"action_type": action_type,
			"search": search
		})
	return response;
}
