import {useEffect, useState} from "react";

const UseQuery = (rule: number | undefined | null, data: any) => {

    const [json, setJson] = useState<any>();

    useEffect(() => {
        if (rule) {
            const finder = data?.find((item: any) => item?.id === rule) as any
            const datas = [] as any[]

            if (finder?.rule_json[0]?.when) {
                const com = Object.keys(finder?.rule_json[0]?.when)[0]

                if (finder?.rule_json[0]?.when?.[com][0]?.obj) {
                    datas.push({
                        operator: undefined,
                        field: finder?.rule_json[0]?.when?.[com][0]?.obj,
                        value: finder?.rule_json[0]?.when?.[com][1]?.const
                    })
                } else {
                    finder?.rule_json[0]?.when?.[com]?.map((item: any) => {

                        const ss = Object.keys(item)[0]
                        datas.push({
                            operator: ss,
                            field: item[ss][0]?.obj,
                            value: item[ss][1]?.const
                        })
                    })
                }

                const obj = {
                    combinator: Object.keys(finder?.rule_json[0]?.when)[0],
                    deliver_time: finder?.deliver_time,
                    deliver_unit: finder?.deliver_unit,
                    rules: datas
                }

                setJson(obj)
            }
        }
    }, [rule, data])

    return {
        json,
        setJson
    }

};

export default UseQuery