import React, { useCallback, useContext, useMemo, useState } from 'react';
import Tabs from "../TabsT/Tabs";
import Tab from "../TabsT/Tab";
import PageHeader from "../Layouts/PageHeader";
import UseTab from "../../Hook/UseTab";
import Button from "../General/Button";
import Input from "../General/Form/Input";
import TextArea from "../General/Form/TextArea";
import { useMutation, useQueryClient } from "react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { addStateTem, contents, ContentTemplates_T, State, State_R, stateUI, valueAdd } from "../../Common/Types";
import { useNavigate, useParams } from "react-router-dom";
import { createState } from "../../apiQuery/State/State.apis";
import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { Fade } from "@mui/material";
import { addContentFromContentTemplate } from "../../apiQuery/Contents/Template.api";
import { store } from "../../GlobalState/store";
import { createContentInState } from "../../apiQuery/Contents/Content.apis";
// import { setSnackbar } from "../../GlobalState/store.actions";
import Switch from "@mui/material/Switch";
import { withAuth } from '../../hoc/withAuth';
import { useTranslation } from 'react-i18next';
import { toast } from '../../utils/Toast';

const NewState = () => {
    const { t, i18n } = useTranslation();

    // const {globalState} = useContext(globalContext);
    const { state: stateGlobal, dispatch } = useContext(store);

    const currentFlow = useMemo(() => stateGlobal.currentFlow, [stateGlobal.currentFlow])

    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const [tab, setTab] = UseTab('Details')

    const [modal, setModal] = useState({
        addContentModal: false,
        addActionModal: false
    })

    const [state, setState] = useState<stateUI>({
        contents: [],
        actions: []
    })

    const [search, setSearch] = useState('')

    const [contentTemp, setContentTemp] = useState<ContentTemplates_T[]>([])

    const handleNewContent = useCallback(() => setModal(prev => ({
        ...prev,
        addContentModal: !prev.addContentModal
    })), [])

    const handleNewActions = useCallback(() => setModal(prev => ({
        ...prev,
        addActionModal: !prev.addActionModal
    })), [])

    const addContentFromContTemp = useMutation((newContentTem: valueAdd) => addContentFromContentTemplate(newContentTem), {})

    const { register, handleSubmit, formState: { errors } } = useForm<State>({
        defaultValues: {
            visible_to_worker: true,
            invalidate_user: false,
            disable_participant: false
        }
    });
    const onSubmit: SubmitHandler<State> = data => {
        if (data.name === "") { // @ts-ignore
            return setTab("Details")
        }
        addStateFlowMutation.mutate(data)
    };

    const createContents = useMutation((newContent: contents) => createContentInState(stateGlobal.organization_id, currentFlow.slug, newContent.slug || '', { ...newContent }), {
        onSuccess: () => {

        }
    })

    const addStateFlowMutation = useMutation((newState: State) => createState(stateGlobal.organization_id, currentFlow.id, {
        ...newState,
        pathway_id: currentFlow.id,
    }), {
        onSuccess: async (data: State_R) => {
            const creatingContent = Promise.all(state.contents.map(async (item) => await createContents.mutateAsync({
                ...item,
                state_id: data.data.id || 0
            })
            ))
            const creatingContentTemp = Promise.all(contentTemp.map(async (item) => await addContentFromContTemp.mutateAsync({
                content_template_id: item.id || 0,
                state_id: data.data.id || 0
            })
            ))

            Promise.all([creatingContent, creatingContentTemp]).then(async () => {
                await queryClient.invalidateQueries('flow-state')
                await queryClient.invalidateQueries(['individual-state-flow', currentFlow.id.toString()])
                navigate(`/pathways/${currentFlow.id}`)
            })

            // dispatch(setSnackbar({ isOpen: true, type: 'success', message: 'StateTemplate has been created.' }))
            toast('StateTemplate has been created.', 'success')

        },
        onError: () => {
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Error Creating a new StateTemplate.' }))
            toast('Error Creating a new StateTemplate.', 'error')
        }
    })

    const handleRemoveStateContent = useCallback((ind: number) => setState(prev => ({
        ...prev,
        contents: prev.contents.filter((_, index) => ind !== index)
    })), [])

    const handleRemoveStateAction = useCallback((ind: number) => setState(prev => ({
        ...prev,
        actions: prev.actions.filter((_, index) => ind !== index)
    })), [])

    const optionsOfStateContent = useMemo(() => [
        {
            icon: <AiFillDelete />,
            text: t('StatePage.Delete_Content'),
            onClick: (ID: number) => handleRemoveStateContent(ID)
        }
    ], [])

    const optionsOfStateContentTemplate = useMemo(() => [
        {
            icon: <AiFillDelete />,
            text: t('StatePage.Delete_Content_Template'),
            onClick: (ID: number) => setContentTemp(prev => prev.filter(item => item.id !== ID))
        }
    ], [])

    const optionsOfStateAction = useMemo(() => [
        {
            icon: <AiFillDelete />,
            text: t('StatePage.Delete_Action'),
            onClick: (ID: number) => handleRemoveStateAction(ID)
        }
    ], [])

    return (
        <>
            <PageHeader
                title={`${t('StatePage.New_State')}`}
                tabs={
                    <Tabs selectedTab={tab} setSelectedTab={setTab}>
                        <Tab title={`${t('StatePage.Details')}`} TabKey='Details'>{`${t('StatePage.Details')}`} </Tab>
                        <Tab title="" TabKey=''></Tab>
                    </Tabs>
                }
                // dotOptionElm={options}
                button={<Button
                    disabled={addStateFlowMutation.isLoading || addContentFromContTemp.isLoading || createContents.isLoading}
                    onClick={handleSubmit(onSubmit)} label={`${t('StatePage.Save_Changes')}`}
                    extraClasses="w-fit px-3 py-3 bg-blue-400 text-white" />}
            />

            <div className="w-11/12 py-5 mx-auto">
                <Fade in={tab === "Details"} style={{ display: tab === "Details" ? '' : 'none' }}>
                    <form className="space-y-5 text-gray-600 text-md">
                        <Input
                            register={() => register("name", { required: 'name is required' })}
                            name="name"
                            placeholder={`${t('StatePage.State_Name')}`}
                            errors={errors}
                            extraClasses="w-2/5" />
                        <TextArea
                            register={() => register("description")}
                            name="description"
                            placeholder={`${t('StatePage.State_Description')}`}
                            errors={errors}
                            extraClasses="w-2/5" />

                        <div className="flex flex-col space-y-2">
                            <span>{`${t('StatePage.Visible_to_Worker')}`} </span>
                            <Switch defaultChecked {...register("visible_to_worker")} color="primary" />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <span>{t('StatePage.Invalidate_User')} </span>
                            <Switch {...register("invalidate_user")} color="primary" />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <span>{t('StatePage.Deactivate_Participants')} </span>
                            <Switch {...register("disable_participant")} color="primary" />
                        </div>

                    </form>
                </Fade>
            </div>

        </>
    );
};

export default withAuth(NewState);
