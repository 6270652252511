import React, {FC, memo, ReactNode, useContext, useMemo, useRef, useState} from 'react';
import {Breadcrumbs, Fade} from "@mui/material";
import {BsThreeDots} from "react-icons/bs";
import {Link, useMatch, useParams, useLocation} from "react-router-dom";
import useOnClickOutside from "../../Hook/UseOutsideHook";
import {Worker_details} from "../../Common/Types";
import {useQueryClient} from "react-query";
import {store} from "../../GlobalState/store";
import {CurrentFlow_R} from "../../GlobalState/types";
import { UserContext } from '../../Context/UserContext/UserContext';
import { useTranslation } from 'react-i18next';

type options = {
    icon: ReactNode,
    text: string,
    onClick: () => void
}

type props = {
    title: string,
    tabs?: ReactNode,
    dotOptionElmDisplay?: boolean
    dotOptionElm?: options[],
    button?: ReactNode
}

const PageHeader: FC<props> = memo(({title, tabs, dotOptionElmDisplay, dotOptionElm, button}) => {
    const { t, i18n } = useTranslation();

    const { isAdmin } = useContext(UserContext)

    const {
        state: globalState
    } = useContext(store);
    const queryClient = useQueryClient()

    const params = useParams();
    const location = useLocation()
    const id = params.stateId || ""
    const workerId = params.workerId || ""
    const pathwayId = params.pathwayId || ""
    const participantId = params.participantId || ""

    const currentState = useMemo(() => globalState.currentFlow?.states?.find(item => item.id === parseInt(id)), [globalState.currentFlow])

    const currentWorker = queryClient.getQueryData(['workers', workerId]) as Worker_details
    const pathwayDetails = queryClient.getQueryData(['individual-state-flow', pathwayId.toString()]) as CurrentFlow_R

    const [showOption, setShowOption] = useState(false)
    const optionRef = useRef() as any;
    useOnClickOutside(optionRef, () => setShowOption(false))

    {/*---------------------------------------------------------*/
    }
    {/*Path variables to match route*/
    }
    const pathwaysMatches = useMatch("/pathways/*");
    const workersMatches = useMatch("/workers/*");
    const workersIdMatches = useMatch("/workers/:workerId/*");
    const workersPathwaysMatches = useMatch("/workers/:workerId/pathway/:pathwayId/participant/:participantId");
    const flowNewMatch = useMatch("/pathways/new/stateFlow");
    const flowStateIdMatch = useMatch("/pathways/:id/*");
    const stateIdMatch = useMatch("/pathways/:id/state/:id");
    const stateTemplateMatch = useMatch("/state-templates/*");
    const createStateTemplateMatch = useMatch("/state-templates/create-state-template");
    const editStateTemplateMatch = useMatch("/state-templates/edit-state-template/:id");
    const contentTemplateMatch = useMatch("/content-templates/*");
    const newStateMatch = useMatch("/pathways/:id/newState");
    const conditionMatch = useMatch("/conditions/*");


    return (
        <div className="w-full bg-white border-b border-gray-300">

            {/*---------------------------------------------------------*/}
            {/*Container for the Page Header*/}
            <div className="w-11/12 py-5 m-auto">

                {/*---------------------------------------------------------*/}
                {/*Page Header breadcrumbs*/}
                <Breadcrumbs aria-label="breadcrumb">
                    {
                        pathwaysMatches &&
                        <Link color="inherit" to="/pathways">
                            {t('PageHeader.Pathways')}
                        </Link>
                    }

                    {
                        location.pathname === '/pathways/new/stateFlow' &&
                        <Link color="inherit" to={`/pathways/new/stateFlow`}>
                            {flowNewMatch ?  `${t('PageHeader.New_Pathway')}` : ''}
                        </Link>
                    }

                    {
                        (location.pathname !== '/pathways/new/stateFlow' && flowStateIdMatch) &&
                        <Link className="line-clamp-1" color="inherit" to={`/pathways/${flowStateIdMatch?.params.id}`}>
                             {t('PageHeader.Pathways')}: {globalState.currentFlow.name}
                        </Link>
                    }


                    {
                        (stateIdMatch) &&
                        <Link className="line-clamp-1" color="inherit"
                              to={`/pathways/${flowStateIdMatch?.params.id}/state/${stateIdMatch.params.id}`}>
                           {t('PageHeader.State')} : {currentState?.name}
                        </Link>
                    }

                    {
                        stateTemplateMatch &&
                        <Link color="inherit" to='/state-templates'>
                            State Templates
                        </Link>
                    }

                    {
                        createStateTemplateMatch &&
                        <Link color="inherit" to='/state-templates/create-state-template'>
                            Create New State Template
                        </Link>
                    }

                    {
                        editStateTemplateMatch &&
                        <Link color="inherit"
                              to={`/state-templates/edit-state-template/${editStateTemplateMatch.params.id}`}>
                            Edit State Template
                        </Link>
                    }

                    {
                        contentTemplateMatch &&
                        <Link color="inherit" to='/content-templates'>
                            Content Templates
                        </Link>
                    }

                    {
                        newStateMatch &&
                        <Link color="inherit" to={`/pathways/${flowStateIdMatch?.params.id}/newState`}>
                             {t('PageHeader.New_State')}
                        </Link>
                    }

                    {
                        workersMatches &&
                        <Link color="inherit" to={'/workers'}>
                            {t('PageHeader.Workers')}
                        </Link>
                    }

                    {
                        workersIdMatches &&
                        <Link color="inherit" to={`/workers/${workersIdMatches.params.workerId}`}>
                            Worker: {currentWorker?.data?.first_name + " " + currentWorker?.data?.last_name}
                        </Link>
                    }

                    {
                        workersPathwaysMatches && workersIdMatches &&
                        <Link color="inherit"
                              to={`/workers/${workersIdMatches.params.workerId}/pathway/${pathwayId}/participant/${participantId}`}>
                            Pathway: {pathwayDetails?.data?.name || ""}
                        </Link>
                    }

                    {
                        conditionMatch &&
                        <Link color="inherit" to='/conditions'>
                            
                            {t('PageHeader.Conditions')}
                        </Link>
                    }
                </Breadcrumbs>

                {/*---------------------------------------------------------*/}
                {/*Page Header title and action menu*/}
                <div className="flex items-center justify-between mt-12 mb-6">
                    <div className="flex items-center space-x-4">
                        <p className="max-w-6xl text-2xl font-semibold line-clamp-1">{title}</p>
                        { isAdmin() && <div ref={optionRef}>
                            {dotOptionElm &&
                                <BsThreeDots className="cursor-pointer" onClick={() => setShowOption(prev => !prev)}/>
                            }
                            <Fade in={showOption && !!dotOptionElm}>
                                <div className="absolute px-4 py-3 text-sm bg-white border shadow top-44 right-1/5">
                                    <div className="space-y-2">
                                        {dotOptionElm?.map((item, index) => <div key={index} onClick={() => {
                                            item.onClick()
                                            setShowOption(false)
                                        }} className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                                            {item.icon}
                                            <p>{item.text}</p>
                                        </div>)}
                                    </div>
                                </div>
                            </Fade>
                        </div> }
                    </div>
                    {button}
                </div>
                {tabs}
            </div>
        </div>
    );
});

export default PageHeader;