import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { PrivateRoutes, publicRoutes } from "./components/Routes/AllRoutes";
import SnackbarMsg from "./components/General/SnackbarMsg";
import {  Suspense } from "react";
import "./index.css";
import { Slide, ToastContainer } from "react-toastify";
import { Header, Sidebar } from "./Theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: 60 * 1000 * 3,
      retry: 3,
      retryDelay: 5000,
    },
  },
});
// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div className="flex items-center justify-center">
      {/* <AppLoader isLoading /> */}
      <p>Loading</p>
    </div>
  </div>
);
function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <ToastContainer
          position="top-right"
          autoClose={3200}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Slide}
        />
        {/* <Navigation /> */}
        <div className="flex">
          {/* <Sidebar /> */}
          <Sidebar />
          {/* <SnackbarMsg /> */}
          <div className="flex flex-col w-full h-screen overflow-auto overflow-y-scroll">
            <Header />
            <Routes>
              {publicRoutes.map((item, index) => {
                return (
                  <Route key={index} path={item.path} element={item.element} />
                );
              })}
              {PrivateRoutes.map((item, index) => {
                return (
                  <Route key={index} path={item.path} element={item.element} />
                );
              })}
            </Routes>
          </div>
        </div>
        {/* <ReactQueryDevtools initialIsOpen /> */}
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;