import { EnumActionType, LanguageEnum } from "./ActionTemplate";
import { IActionMetadata } from "./ActionTemplate";





export interface IWS1Notification {
	language: LanguageEnum

}
export interface WS1NotificationActionCreateOrUpdateRequest {
	action_type: EnumActionType,
	notification: IWS1Notification[],
	action_metadata: IActionMetadata[],
	importance:number
}

export type WS1NotificationActionTemplate = {
	notification: IWS1Notification,
	action_metadata: IActionMetadata,
	importance:number
}

export const initialWS1NotificationContent: WS1NotificationActionTemplate = {
	notification: {
		language: LanguageEnum.English,
	},
	action_metadata: {
		id: 0,
		organization_id: 0,
		language: LanguageEnum.English,
	    title: '',
    	subject: '',
	    body: '',
	    subtitle: '',
	    description: '',
	},
	importance:0
}