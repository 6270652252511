
import API from "../index";



export const getAllStateRules = async () => { 
    const { data } = await API.get("/admin/state/rules/organizations");
    return data;
}

export const getOneStateRule = async (ruleId: number) => {
    const { data } = await API.get(`/admin/state/rules/${ruleId}`);
    return data;
};

export const updateStateRule = async (ruleId:number,rule:any) => {
    const {data} = await API.put(`/admin/state/rules/${ruleId}`, {...rule});
    return data;
}


export const createDeliverBasedOnTimeWithRule = async (data: any) => {
    const response = await API.post('/admin/organizations/state/time-condition/',data )
    return response.data
}

export const removeRuleStateConditionEntry = async (ruleId: number) => {
    const response = await API.delete(`/admin/state/rules/${ruleId}`)
    return response.data
}