import { FC, useContext, useEffect, useReducer, useRef, useState } from "react";
import { TabContentLayout } from "../TabContentLayout";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import UseUploadFile from "../../../../Hook/UseUploadFile";
import UseUpload from "../../../../Hook/UseUpload";
import { baseURL } from "../../../../apiQuery";
import UploadProgress from "../../../General/UploadProgress";
import { toast } from "../../../../utils/Toast";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { createContentTemplate } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { useNavigate, useParams } from "react-router-dom";
import { DirectionContentCreateOrUpdateRequest, DirectionContentTemplate, initialDirectionContent } from "../../../../Types/ContentTemplate/Direction";
import { useTranslation } from "react-i18next";
// Maps Imports
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Autocomplete, Fade, TextField } from "@mui/material";


const NewDirection: FC = () => {
  const params = useParams<{ templateSlug: string }>()
  const navigate = useNavigate();
  const { t } = useTranslation(); // TODO: use this for language

  const { currentOrganizationID } = useContext(UserContext);
  const [formData, setDirectionContent] = useState<DirectionContentTemplate>(initialDirectionContent)


  //=====================  Upload Cover Image  =================================
  const [imageSrc, file, render, name] = UseUploadFile('Direction')
  const { uploadForm, progress, isLoading } = UseUpload(
    `${baseURL}upload`
  );

  const [uploadState, setUploadState] = useState({
    file,
    name,
  })

  useEffect(() => {
    if (name && file) {
      setUploadState({
        file,
        name,
      })
    }
  }, [name, file])
  //=====================  Load Map Script & Handlers ================================

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries: ["places"],
  });
  const [marker, setMarker] = useState({ lat: 44, lng: -80 })

  const [val, setVal] = useState()

  const createOrUpdateButton = useRef<string>("Create")

  const onChooseAddressHandler = (e: any) => {
    // setDirectionContent(prev => ({ ...prev, address: e }))
    setDirectionContent(prev => {
      return { ...prev, direction: { ...prev.direction, address: e } }
    })
  }

  // ============================ Mutation ================================

  const createArticleContentMutation = useMutation((param: DirectionContentCreateOrUpdateRequest) => createContentTemplate(param), {
    onSuccess: async (data) => {
      toast("Content created successfully", "success")
      const id = data?.data?.id
      if (id) {
        navigate(`/content-templates/${params.templateSlug}/view/${id}`)
      }
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message || "Something went wrong!"
      toast(message, "error")
    }
  })

  // ======================  Submit Article  ========================
  const submitFormHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    if (formData.direction.title === '') {
      toast('Please enter a title', 'error')
      return
    }


    let _cover_imageLink = ''

    if (uploadState.file !== undefined) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", uploadState.file);
      const { link } = await uploadForm(formData)
      // setArticleContent(prev => ({...prev, cover_image: link}))
      _cover_imageLink = link
    }

    const directionContentWithImage: DirectionContentTemplate = {
      direction: formData.direction,
      content_metadata: {
        ...formData.content_metadata,
        cover_image: _cover_imageLink,
        language: formData.direction.language
      }
    }
    const newState = [directionContentWithImage]
    const shapeData: DirectionContentCreateOrUpdateRequest = {
      content_type: EnumContentType.DirectionContent,
      direction: newState.map(item => {
        return {
          title: item.direction.title,
          description: item.direction.description,
          address: item.direction.address,
          language: item.direction.language as LanguageEnum,
        }
      }),
      content_metadata: newState.map(item => {
        return {
          cover_image: item.content_metadata.cover_image,
          preview_title: item.content_metadata.preview_title,
          tags: item.content_metadata.tags,
          organization_id: currentOrganizationID(),
          language: item.content_metadata.language as LanguageEnum,
          alt_title: item.content_metadata.alt_title,
        }
      })
    }

    await createArticleContentMutation.mutateAsync(shapeData)
  }

  return <TabContentLayout>

    <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
      <h1 className="pb-8 text-xl">New Direction Content Template</h1>

      <h1 className="pb-8 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{formData.direction.title || ''}</h1>

      <AppInput tabIndex={-1} autoFocus placeholder={"Title"} value={formData.direction.title} name='title'
        isFull label='Title' required
        onChange={
          (e) => setDirectionContent({ ...formData, direction: { ...formData.direction, title: e.target.value } })
        }
      />
      <AppTextArea placeholder={"Description"} rows={4} value={formData.direction.description} name='description'
        onChange={
          (e) => setDirectionContent({ ...formData, direction: { ...formData.direction, description: e.target.value } })
        }
        label='Description' />

      {/* <AppInput placeholder={"Alternative Title"} value={formData.content_metadata.alt_title} name='alt_title' isFull
        onChange={e => {
          setDirectionContent({ ...formData, content_metadata: { ...formData.content_metadata, alt_title: e.target.value } })
        }} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

      <AppInput placeholder={"Preview Title"} value={formData.content_metadata.preview_title} name='preview_title' isFull
        onChange={e => {
          setDirectionContent({ ...formData, content_metadata: { ...formData.content_metadata, preview_title: e.target.value } })
        }} label='Preview Title' extendClass='my-2' maxLength={140} required />

      <AppInput placeholder={"Tag"} value={formData.content_metadata.tags[0]} name='tags' isFull
        onChange={e => {
          setDirectionContent({ ...formData, content_metadata: { ...formData.content_metadata, tags: [e.target.value] } })
        }} label='Tag' extendClass='my-2' />


      {
        !isLoaded ? <div>Loading</div> :
          <>
            <div className="places-container">
              <PlacesAutocomplete setMarker={setMarker} setVal={setVal}
                defaultValue={{
                  direction: {
                    address: formData.direction.address
                  }
                }} onChooseAddress={onChooseAddressHandler} />
            </div>

            <GoogleMap zoom={5} center={marker} mapContainerClassName="map-container">
              <Marker position={marker} />
            </GoogleMap>
          </>
      }


      <div className="my-4">
        {formData.content_metadata.cover_image ?
          <div className="cover__image-container">

            <img alt="conver_image" src={formData.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
          </div> : null}
        {render}
        {uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}
        {isLoading ? <UploadProgress progress={progress} /> : null}
      </div>
      <div className='flex w-full gap-2 mt-3 mb-3'>
        <AppButton disabled={isLoading || createArticleContentMutation.isLoading} variant="primary" type="submit" extendClass="px-3">
          <div className="flex items-center justify-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 96 960 960"
              className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
            >
              <path
                fill="currentColor"
                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
            </svg>
            Create Content Template          </div>
        </AppButton>
      </div>
    </form>

  </TabContentLayout>
}


type PlacesAutoComplete = {
  setVal: any
  defaultValue: any
  setMarker: any
  onChooseAddress: (address: string) => void
}

const PlacesAutocomplete: FC<PlacesAutoComplete> = ({ setVal, defaultValue, setMarker, onChooseAddress }) => {

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address: any) => {
    setValue(address, false);
    setVal('address', address)
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setMarker({ lat, lng })
  };

  useEffect(() => {
    if (defaultValue?.direction?.address) {
      setValue(defaultValue.direction.address);
      handleSelect(defaultValue.direction.address);
    }
  }, [defaultValue]);

  return (
    <Autocomplete
      fullWidth
      sx={{ width: '100%' }}
      disabled={!ready}
      value={value}
      onChange={(event: any, newValue: string | null) => {
        if (newValue) handleSelect(newValue);
      }}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue)
        onChooseAddress(newInputValue)
      }}
      options={status === "OK" ? data.map(({ place_id, description }) => description) : []}
      renderInput={(params) => <TextField {...params} label="Search Address" className="my-2 bg-white" defaultValue={defaultValue?.direction?.address} />}
    />
  );
};

export default NewDirection;