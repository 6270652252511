import React, { ChangeEvent, FC, FormEvent, useContext, useRef, useState, useEffect, Fragment, useMemo, useCallback } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { LanguagesOptionsList, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery } from "react-query";
import { deleteContentTemplateById, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { DirectionContentCreateOrUpdateRequest, DirectionContentResponse, DirectionContentTemplate, IDirection, initialDirectionContent } from '../../../../Types/ContentTemplate/Direction';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import { Autocomplete, TextField } from "@mui/material";
import UseUploadFile from '../../../../Hook/UseUploadFile';
import UseUpload from '../../../../Hook/UseUpload';
import { baseURL } from '../../../../apiQuery';
import UploadProgress from '../../../General/UploadProgress';
import { AppButton, LanguageIcon, PopupModal } from '../../../../Theme';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { useNavigate } from 'react-router-dom';

export type Language = "en" | "fr" | "es"



interface DirectionTabContentProps {
	templateId: number,
}
const DirectionTabContent: FC<DirectionTabContentProps> = (props) => {
	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions,
	} = usePlacesAutocomplete();



	const { t } = useTranslation();
	const navigate = useNavigate();

	const { currentOrganizationID } = useContext(UserContext);

	// ======================================== Updoad cover image ========================================
	const [imageSrc, file, render, name] = UseUploadFile('Direction')
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);

	const [uploadState, setUploadState] = useState({
		file,
		name,
	})

	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])
	// ============================================================================================================================================
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
		libraries: ["places"],
	});
	const [marker, setMarker] = useState({ lat: 25.7616798, lng: -80.1917902 })


	const [state, setState] = useState<DirectionContentTemplate[]>([])
	const [directionContent, setDirectionContent] = useState<DirectionContentTemplate>(initialDirectionContent)
	const createOrUpdateButton = useRef<string>("Create")
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')

	//====================================================================================================================


	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);

	const { data: directionData, refetch } = useQuery<DirectionContentResponse, Error>
		(`directionData-content-${props.templateId}`, () => getContentTemplateById(props.templateId));


	useEffect(() => {
		if (directionData?.data) {
			editableDirectionContent(directionData?.data)
		}
	}, [directionData?.data])

	// useEffect(() => {
	// 	if (selectedLanguage === '') return
	// 	// get seleted index of the content template to be displayed as the current content
	// 	const selectedIndex = directionData?.data['direction'].findIndex((item: any) => item.language === selectedLanguage) as number
	// 	const direction: IDirection = directionData?.data['direction'][selectedIndex] as IDirection
	// 	const contentMetaData = directionData?.data.content_metadata[selectedIndex] as IContentMetadata
	// 	setDirectionContent({
	// 		direction: direction,
	// 		content_metadata: contentMetaData
	// 	})
	// 	createOrUpdateButton.current = "Update"
	// }, [selectedLanguage])

	const deleteTemplateFromContentTemplateList = async () => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/direction', { state: { deleted: true } })
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.direction.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}

	//======================================================

	//make english default language for content template
	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setDirectionContent({
				direction: directionData?.data.direction.find((item: IDirection) => item.language === LanguageEnum.English) as IDirection,
				content_metadata: directionData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			createOrUpdateButton.current = "Update"
		}
	}, [directionData?.data.direction, directionData?.data.content_metadata])

	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.direction.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges

	}, [state])

	//======================================================

	// ================================================ Mutations ================================================

	const updateDirectionContentMutation = useMutation(
		(params: { id: number, data: DirectionContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Content updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
	//=====================================================
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	// ================================================ Handlers ================================================

	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setDirectionContent(prev => {
			return { ...prev, direction: { ...prev.direction, [name]: value } }
		})
	}


	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		//  tODO: validation goes here
		if (directionContent.direction.title === '') {
			toast('Please fill all the fields', 'error')
			return
		}




		if (createOrUpdateButton.current === "Create") {
			// Todo : check if the language is already exist
			if (state.some(item => item.direction.language === directionContent.direction.language)) {
				toast('This direction is already exist', 'error')
				return
			}

			let _cover_imageLink = ''

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				// setArticleContent(prev => ({...prev, cover_image: link}))
				_cover_imageLink = link
			}

			const directionContentWithImage: DirectionContentTemplate = {
				direction: directionContent.direction,
				content_metadata: {
					...directionContent.content_metadata,
					cover_image: _cover_imageLink,
				}
			}
			// setState(prev => [...prev, directionContentWithImage])
			const newState = [...state, directionContentWithImage]
			await createOrUpdateContentTemplate(newState)

			// =======================
		} else {
			// update the state
			let cover_image = directionContent.content_metadata.cover_image

			if (file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", file);
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}

			// update the state
			const newState = state.map(item => {
				if (item.direction.language === directionContent.direction.language) {
					return {
						direction: directionContent.direction,
						content_metadata: {
							...directionContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})

			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = "Create"

		}

		setUploadState({
			file: undefined,
			name: '',
		})

		// 	empty the fields
		setDirectionContent(initialDirectionContent)
		setSelectedLanguage('')
	}

	const createOrUpdateContentTemplate = async (data: DirectionContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one directtion template', 'info')
			return
		}

		const shapeData: DirectionContentCreateOrUpdateRequest = {
			content_type: EnumContentType.DirectionContent,
			direction: data.map(item => {
				return {
					...item.direction,
					title: item.direction.title,
					description: item.direction.description,
					address: item.direction.address,
					language: item.direction.language as LanguageEnum,
				}
			}),
			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.direction.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}
		await updateDirectionContentMutation.mutateAsync({ id: props.templateId, data: shapeData })
		setState([])
		setDirectionContent(initialDirectionContent)
	}


	const editableDirectionContent = (directionContentTemplate: any) => {
		const combinedData: any[] = [];

		if (!directionContentTemplate) {
			console.error('Direction content template is undefined');
			return;
		}

		// Loop through each article
		if (Array.isArray(directionContentTemplate.direction)) {
			directionContentTemplate.direction.forEach((direction: any) => {
				// Loop through each content metadata
				if (Array.isArray(directionContentTemplate.content_metadata)) {
					directionContentTemplate.content_metadata.forEach((metadata: any) => {
						// Check if the language matches
						if (direction.language === metadata.language) {
							// Combine the properties of the direction and metadata objects
							const combinedObj = {
								direction: direction,
								content_metadata: metadata
							};
							// Add the combined object to the final array
							combinedData.push(combinedObj);
						}
					});
				} else {
					console.error('Content metadata is not an array');
				}
			});
		} else {
			console.error('Direction is not an array');
		}

		setState(combinedData);
	};


	// const [val, setVal] = useState<any>()


	const onChooseAddressHandler = (e: any) => {
		// setDirectionContent(prev => ({ ...prev, address: e }))
		setDirectionContent(prev => {
			return { ...prev, direction: { ...prev.direction, address: e } }
		})
	}


	console.log('dir', directionContent?.direction?.address)

	return (
		<Fragment>



			<ViewContentTemplateHeader
				title={directionData?.data?.direction?.[0]?.title || ''}
				icon={<svg
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 7.573 8.414"
					className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
				>
					<path
						id="icon_pathways"
						d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
						transform="translate(-6 -4)"
						fill="currentColor"
					/>
				</svg>}
				referencesCount={directionData?.data.linked_content_count || 0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{

					directionData?.data && directionData?.data?.direction?.sort((a, b) => {
						// Define a language order
						const languageOrder = { en: 0, es: 1, fr: 2 };

						// Get the language order for each object
						const orderA = languageOrder[a.language] || Infinity;
						const orderB = languageOrder[b.language] || Infinity;

						// Compare the language order
						return orderB - orderA;
					}).map((item: IDirection, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setDirectionContent(initialDirectionContent)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										// editableDirectionContent(directionData.data)

										createOrUpdateButton.current = "Update"
										currentLanguage.current = item.language as LanguageEnum
										setDirectionContent({
											direction: directionData.data.direction.find((direction) => direction.language === item.language) as IDirection,
											content_metadata: directionData.data.content_metadata.find((metadata: IContentMetadata) => metadata.language === item.language) as IContentMetadata
										})
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					(directionData?.data.direction?.length ?? 0) < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setDirectionContent(initialDirectionContent)
								setSelectedLanguage('')
								setMarker({ lat: 44, lng: -80 })
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								{t('new_language')}
							</span>

						</AppButton>
					) : null
				}
			</div>


			<TabContentLayout>
				{/* =========================== */}

				{/* =========================== */}
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{directionContent?.direction?.title || ""}</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">

					<div className='flex w-full gap-2 mb-8'>
						<AppButton size="xs" disabled={directionContent?.direction?.title === '' || updateDirectionContentMutation.isLoading || deleteContentTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Content Template</AppButton>
						<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>{t("content_template.delete_content_template")}</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>
					</div>
					<hr className="mb-8" />



					<AppInput autoFocus placeholder={"Title"} value={directionContent?.direction?.title || ""} name='title' isFull onChange={handleChange} label='Title' required />
					<AppTextArea placeholder={"Description"} rows={4} value={directionContent?.direction?.description || ""} name='description' onChange={handleChange}
						label='Description' />
					{
						!isLoaded ? <div>Loading</div> :
							<div className='my-2'>
								<div className="places-container">
									<PlacesAutocomplete setMarker={setMarker}
										defaultValue={{
											direction: {
												address: directionContent?.direction?.address || ""
											}
										}} onChooseAddress={onChooseAddressHandler} />
								</div>


										{/* TODO remove for test only */}
								{
									(() => {
										console.log(marker, 'mms')
										return 'test'
									})()}
										{/* TODO for test only */}
								
								
								<div className='my-2'>
									<GoogleMap zoom={5} center={marker} mapContainerClassName="map-container">
										<Marker position={marker} />
									</GoogleMap>
								</div>
							</div>
					}


					{/* <AppInput placeholder={"Alternative Title"} value={directionContent?.content_metadata?.alt_title || ''} name='alt_title' isFull
						onChange={e => {
							setDirectionContent({
								...directionContent,
								content_metadata: {
									...directionContent.content_metadata,
									alt_title: e.target.value
								}
							})
						}} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

					<AppInput placeholder={"Preview Title"} value={directionContent?.content_metadata?.preview_title || ""} name='preview_title' isFull
						onChange={e => {
							setDirectionContent(prev => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										preview_title: e.target.value
									}
								}
							})
						}} label='Preview Title' extendClass='my-2' maxLength={140} required />
					<AppInput placeholder={"Tag"} value={directionContent?.content_metadata?.tags[0]} name='tags' isFull onChange={e => {
						setDirectionContent(prev => {
							return {
								...prev,
								content_metadata: {
									...prev.content_metadata,
									tags: [e.target.value]
								}
							}
						})
					}} label='Tag' extendClass='my-2' />
					{directionContent?.content_metadata?.cover_image ?
						<div className='cover__image-container'>
							<img alt="conver_image" src={directionContent.content_metadata.cover_image} className='my-2 rounded-sm' />
						</div>
						: null}
					{render}
					{uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}

					{
						isLoading ? <UploadProgress progress={progress} /> : null
					}
					{/* <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"}
					
						options={LanguagesOptions} value={directionContent?.direction?.language || ""} name="language" onChange={handleChange} /> */}

					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={directionContent?.direction?.language || ""} name="language" onChange={handleChange} />
					}
				</form>

			</TabContentLayout>


			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</Fragment>
	)
}


type PlacesAutoComplete = {
	// setVal: any
	defaultValue: any
	setMarker: any
	onChooseAddress: (address: string) => void
}

const PlacesAutocomplete: FC<PlacesAutoComplete> = ({ defaultValue, setMarker, onChooseAddress }) => {

	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions,
	} = usePlacesAutocomplete({
		debounce: 300,
	});

	const handleSelect = useCallback(async (address: any) => {
		// setVal('address', address)
		setValue(address, false);
		clearSuggestions();

		const results = await getGeocode({ address });
		const { lat, lng } = getLatLng(results[0]);
		setMarker({ lat, lng })
	}, [clearSuggestions, setMarker, setValue]);


	useEffect(() => {
		if (defaultValue?.direction?.address) {
			setValue(defaultValue.direction.address || 'Miami, FL, USA');
			handleSelect(defaultValue.direction.address || 'Miami, FL, USA');
		}
	}, [defaultValue.direction.address, handleSelect, setValue]);

	useEffect(() => {
		console.log(defaultValue.direction.address)
		setValue(defaultValue.direction.address)
	}, [defaultValue, setValue])

	return (
		<Autocomplete
			fullWidth
			sx={{ width: '100%' }}
			disabled={!ready}
			value={value}
			onChange={(event: any, newValue: string | null) => {
				if (newValue) {
					setValue(newValue);
					handleSelect(newValue);
				}
			}}
			inputValue={value}
			onInputChange={(event, newInputValue) => {
				setValue(newInputValue)
				onChooseAddress(newInputValue)
			}}
			options={status === "OK" ? data.map(({ place_id, description }) => description) : []}
			renderInput={(params) => <TextField {...params} label="Search Address" className="my-2 bg-white" defaultValue={defaultValue?.direction?.address} />}
		/>
	);
};




// const PlacesAutocomplete: FC<PlacesAutoComplete> = ({ setVal, defaultValue, setMarker, onChooseAddress }) => {

// 	const {
// 		ready,
// 		value,
// 		setValue,
// 		suggestions: { status, data },
// 		clearSuggestions,
// 	} = usePlacesAutocomplete();

// 	const handleSelect = async (address: any) => {
// 		setValue(address, false);
// 		setVal('address', address)
// 		clearSuggestions();

// 		const results = await getGeocode({ address });
// 		const { lat, lng } = await getLatLng(results[0]);
// 		setMarker({ lat, lng })
// 	};

// 	useEffect(() => {
// 		handleSelect(defaultValue?.direction?.address)
// 	}, []);


// 	return (
// 		<Autocomplete
// 			fullWidth
// 			sx={{ width: '100%' }}
// 			disabled={!ready}
// 			value={value}
// 			onChange={(event: any, newValue: string | null) => {
// 				if (newValue) handleSelect(newValue);
// 			}}
// 			inputValue={value}
// 			onInputChange={(event, newInputValue) => {

// 				setValue(newInputValue)
// 				onChooseAddress(newInputValue)
// 			}}
// 			options={status === "OK" ? data.map(({ place_id, description }) => description) : []}
// 			renderInput={(params) => <TextField {...params} label="Search Address" className="my-2 bg-white" />}
// 		/>
// 	);
// };


export default DirectionTabContent
