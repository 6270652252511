import React, { FC } from "react";
// import PageHeader from "../Layouts/PageHeader";
import AppButton from "../Shared/AppButton/AppButton";
import { TabBar } from "../../Theme";

const BI = () => {
  return window.location.href.search("staging") > 0 ? (
    <iframe
      title="Test_Power-BI"
      className="w-full h-5/6"
      src="https://app.powerbi.com/reportEmbed?reportId=05f48fd1-67c1-4156-b26d-9b970f76d8a7&autoAuth=true&ctid=a7855274-9102-4d97-aedd-77c275db8f59"
      frameBorder="0"
      allowFullScreen
    ></iframe>
  ) : (
    <iframe
      title="PBI_PROD_AL"
      className="w-full h-5/6"
      src="https://app.powerbi.com/reportEmbed?reportId=c3525a97-042e-4068-bb1c-8ae7a42ea112&autoAuth=true&ctid=a7855274-9102-4d97-aedd-77c275db8f59"
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
};

const Analytics: FC = () => {
  const [fullscreen, setFullScreen] = React.useState<boolean>(true);

  return (
    <section className="relative">
      <TabBar />
      {/* <PageHeader title="Analytics" /> */}
      <div className="h-screen overflow-hidden bg-white">
        <div id="myTabContent" className="h-full shadow-sm">
          <div
            // className="flex flex-col items-center justify-center p-4 bg-white"
            role="tabpanel"
            className={`flex overflow-hidden flex-col items-center justify-center bg-white ${fullscreen ? "h-full" : ""
              }`}
          >
            <BI />
            <div className="flex flex-col justify-center p-1 h-1/6">
              <AppButton
                extendClass="self-end"
                onClick={() => setFullScreen(!fullscreen)}
                variant="primary"
              >
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                    />
                  </svg>
                  <span>{fullscreen ? "Minimize" : "Full Screen"}</span>
                </>
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Analytics;
