import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../../General/Form/Input";
import TextArea from "../../General/Form/TextArea";
import Button from "../../General/Button";
import { AiFillDelete, AiFillFile } from "react-icons/ai";
import {
    Conditions_T,
    contents, ruleBody, ruleIds,
    selector,
    Single_Content_Single,
    state_Edit_Selector
} from "../../../Common/Types";
import StandardInput from "../../General/Form/StandardInput";
import NestedContentListItem from "../NestedContentListItem";
import ContentExtra from "../../Event/ContentExtra";
import readFile from "../../Utils/ReadFile";
import { MdModeEditOutline } from "react-icons/md";
import UseTab from "../../../Hook/UseTab";
import Tab from "../../Tabs/Tab";
import { BsFillEyeFill } from "react-icons/bs";
import Tabs from "../../Tabs/Tabs";
import OverlayModal from "../../Layouts/OverlayModal";
import { useMutation, useQueryClient } from "react-query";
import { createContentInState, updateContent } from "../../../apiQuery/Contents/Content.apis";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../../../GlobalState/store";
// import { setSnackbar } from "../../../GlobalState/store.actions";
import { updateSelector } from "../../../apiQuery/Contents/ContentType.apis";
import UseUploadFile from "../../../Hook/UseUploadFile";
import ContentTypeCardPreview from "../ContentPreview/ContentTypeCardPreview";
import Mobile from "../ContentPreview/Mobile";
import PreviewContentModal from "../ContentPreview/PreviewContentModal";
import {
    createTimeBaseEvent,
    deleteTimeCondition,
    updateTimeCondition
} from "../../../apiQuery/Condition/TimeBase.apis";
import { createContentRule } from "../../../apiQuery/Condition/Rule.apis";
import { formatQuery } from "react-querybuilder";
import initialQuery from "../../../utils/InitialRules";
import jsonHandler from "../../Utils/JsonHandler";
import UseUpload from "../../../Hook/UseUpload";
import { baseURL } from "../../../apiQuery";
import UploadProgress from "../../General/UploadProgress";
import { Fade } from "@mui/material";
import { toast } from "../../../utils/Toast";
import { useTranslation } from 'react-i18next';
import { IAttachTemplate, getContentTemplateFilter, setATemplateToContentType } from '../../../apiQuery/ContentTemplate/ContentTemplate.apis';
import { ISelector } from '../../../Types/ContentTemplate/Selector';
import { EnumContentType } from '../../../Types/ContentTemplate/ContentType';
import moment from "moment";
import { getStateContentTemplateFilter } from '../../../apiQuery/ContentTemplate/StateContentTemplate.apis';
import AppButton from '../../Shared/AppButton/AppButton';
import AppInput from '../../Shared/AppInput/AppInput';
import { AppLoader } from '../../Shared/AppLoader';
import Condition from '../../Event/Condition';
import { CustomClipboard, AppButton as AppThemeButton } from '../../../Theme';
import { setAddContentTemplateModal } from '../../../GlobalState/store.actions';
import { LanguageEnum } from '../../../Types/ContentTemplate/Langauge';
import { Selector } from '../../../@Core';

const SelectorNew: FC<state_Edit_Selector> = memo(({ onClose, defaultValue, type, onSuccess }) => {

    const queryClient = useQueryClient()

    const navigate = useNavigate();

    const [altTitle, setAltTitle] = useState<string>("")
    const { t, i18n } = useTranslation();

    const { register, handleSubmit, setValue, watch, getValues, trigger, formState: { errors } } = useForm<selector>({
        defaultValues: defaultValue ? defaultValue?.selector : {}
    });

    const { uploadForm, progress, isLoading } = UseUpload(
        `${baseURL}upload`
    );

    const [timeEvent, setTimeEvent] = useState({
        isAdded: false,
        isRuleAdded: false
    })

    //@ts-ignore
    const timeValue = parseInt(getValues("timeValue")) as number

    //@ts-ignore
    const eventName = getValues("eventName") as string

    //@ts-ignore
    const typeOf = getValues("typeOf") as string

    //@ts-ignore
    const checkInValue = getValues("checkInValue") as string

    //@ts-ignore
    const target_func = getValues("target_func") || undefined as string

    const [query, setQuery] = useState(initialQuery);

    const params = useParams();
    const id = params.stateSlug || ""
    const pathwaySlug = params.slug || ""

    const {
        state,
        dispatch
    } = useContext(store);
    const organization_id = useMemo(() => state.organization_id, [state.organization_id])

    const [input, setInput] = useState('')
    const [itemDesc, setItemDesc] = useState('')
    const [variants, setVariants] = useState('')
    const [imageData, setImageSrc] = useState<any>()
    const [imageName, setImageName] = useState("")

    const [modal, setModal] = useState({
        pathwayView: false,
        contentView: false
    })

    const [tab, setTab] = UseTab('Selector Details')

    const handlePathwayView = useCallback(() => {
        setModal(prev => ({ ...prev, pathwayView: !prev.pathwayView }))
    }, [])

    const handleContentView = useCallback(() => {
        setModal(prev => ({ ...prev, contentView: !prev.contentView }))
    }, [])

    const title = getValues("title");
    const items = getValues("items") || []
    const variant = getValues("variants") || []

    const [edit, setEdit] = useState({
        isEdit: false,
        index: 0
    })

    const [imageSrc, file, render, name] = UseUploadFile('Selector')

    const [editItem, setEditItem] = useState({
        isEditItem: false,
        indexItem: 0
    })

    const [ruleId, setRuleId] = useState<ruleIds>({
        event: undefined,
        time: undefined
    });

    const [passedContent, setPassedContent] = useState({
        targetField: '',
        targetFunction: ''
    })

    // mutations
    //====================================================================================================

    const [previousPage, setPreviousPage] = useState<string>();
    const [nextPage, setNextPage] = useState<string>();
    const [contentPriority, setContentPriority] = useState<number>(0);
    const [searchContentTemplate, setSearchContentTemplate] = useState('')

    const isContentTemplate = useMemo(() => defaultValue?.template_id ? true : false, [defaultValue?.template_id])

    const { data: selectorTemplateData, isLoading: selectorCcontentTempalteIsLoading, isError: selectorTemplateIsError, error: selectorTemplateError, mutate } = useMutation(
        (pageDirection: 'next' | 'previous' | '') => getStateContentTemplateFilter<ISelector, EnumContentType.SelectorContent>
            (state.currentFlow.slug, id, EnumContentType.SelectorContent, 8, '', nextPage, previousPage, pageDirection), {
        onSuccess(data) {
            if (data?.data) {
                const { next, previous } = data?.data.paginate
                setNextPage(next)
                setPreviousPage(previous)
            }
        },
    });

    useEffect(() => {
        mutate('')
    }, [mutate]);





    const flattedSelectorTemplateOptions = useMemo(() => {
        // const newOption = { value: '0', title: 'No Template Content' };
        const currentLanguage = i18n.language;

        const options = selectorTemplateData && selectorTemplateData.data.data.map((item) => {
            const { id } = item;
            return item.selector.map((ann) => {
                return {
                    template_id: id,
                    selector_id: ann.id,
                    title: ann.title,
                    description: ann.description,
                    updated_at: ann.updated_at,
                    metaData: item.content_metadata,
                    items: ann.items,
                    variants: ann.variants,
                    language: ann.language
                }
            })
        }).flatMap((item) => item).filter(item => item.language === LanguageEnum.English)
        return options;
    }, [selectorTemplateData, i18n.language]);



   

    const search_result = useMemo(() => {
        if (searchContentTemplate === '') return flattedSelectorTemplateOptions
        return flattedSelectorTemplateOptions &&
            flattedSelectorTemplateOptions.filter(item => item.title.toLowerCase().includes(searchContentTemplate.toLowerCase()) ||
                item.description.toLowerCase().includes(searchContentTemplate.toLowerCase()))
    }, [flattedSelectorTemplateOptions, searchContentTemplate])




    // =================== Create content template =========================
    const addContentTemplate = async (item: any) => {

        const obj = {
            template_id: item.template_id as  number,
            state_slug: id as string,
            pathway_slug: pathwaySlug as string,
            is_template: true,
            content_type: EnumContentType.SelectorContent,
        }

        dispatch(setAddContentTemplateModal(obj))
        onClose()
    }




    //====================================================================================================  

    const createTimeEvent = useMutation((timeData: Conditions_T) => createTimeBaseEvent(organization_id, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        },
        onError: (error: null | Error) => {
            // const message = error?.message || "Failed creating Time base Rule"
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            const message = error?.message || `${t('content.create_time_base_rule_failed')}`
            toast(message, 'error')
        }
    })
    const deleteCondition = useMutation((delId: number) => deleteTimeCondition(organization_id, delId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        }
    })
    const updateTimeEvent = useMutation((timeData: Conditions_T) => updateTimeCondition(organization_id, timeData.id || 0, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }))
    const createRuleCondition = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        // const target_func = getValues("target_func") as string
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore
            // const message = error?.response?.data?.message || "Failed creating content Rule"
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
            toast(message, 'error')
        }
    })

    const UpDateFunc = async (createdData: contents) => {
        if (ruleId.event === 0) {
            let ruleId = null
            if (query.rules.length !== 0) {
                // const { data } = await createRuleCondition.mutateAsync(
                //     // {
                //     //     organization_id: organization_id,
                //     //     ...jsonHandler(timeValue, typeOf, eventName, query,target_func)
                //     // }
                // )
                // ruleId = data?.id || null
                ruleId = await createRuleConditionForContent();
            }
            await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId })
        } else await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId.event || null })
    }

    const createTimeRule = async (ID: number | undefined) => {
        const typeIs = typeOf === 'days' ? 'hour' : typeOf
        await createTimeEvent.mutateAsync({
            deliver_time: timeValue ? timeValue : 5,
            deliver_unit: typeIs || "minute",
            state_id: parseInt(defaultValue.state_id),
            content_id: ID
        })
    }

    const createContents = useMutation((newContent: contents) => createContentInState(organization_id, pathwaySlug, id, { ...newContent }), {
        onSuccess: async (createdData: Single_Content_Single) => {
            if (timeEvent.isAdded) {
                await createTimeRule(createdData.data?.id)

            }

            toast(`${t('content.created')}`, 'success')
            !!onSuccess && onSuccess()
            onClose()
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.failed')}`
            toast(message, 'error')
        }
    })

    const updateContentMutation = useMutation((updateContentDetails: contents) => updateContent(organization_id, pathwaySlug, id, { ...updateContentDetails }, updateContentDetails.slug || ''), {
        onSuccess: async (data: Single_Content_Single) => {
            if (defaultValue) {
                if (data?.data?.selector) {
                    await updateContentSelector.mutateAsync({
                        ...data?.data?.selector
                    })
                }

                toast(`${t('content.updated')}`, 'success')
                !!onSuccess && onSuccess()
                await queryClient.invalidateQueries(['content', defaultValue.id])
                onClose()
            }
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const updateContentSelector = useMutation((selectorDetails: selector) => updateSelector(organization_id, { ...selectorDetails }, selectorDetails?.id || 0), {
        onError: (error: null | Error) => {

            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const handleEdit = (item: string, ind: number) => {
        setEdit({ isEdit: true, index: ind })
        setVariants(item)
    }

    const handleEditItem = (ind: number) => {
        const selectedItem = items[ind]
        setEditItem({ isEditItem: true, indexItem: ind })
        setInput(selectedItem.title)
        setItemDesc(selectedItem.description || "")
    }

    // const createRuleTimePassedCondition = useMutation((targetField:string, targetFunction:string ) =>  {
    //     const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
    //     const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
    //     return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func: targetFunction, target_field: targetField })
    // }, {
    //     onSuccess: async () => {
    //         await queryClient.invalidateQueries(["all-content-rules", organization_id])
    //     },
    //     onError: (error: null | Error) => { //@ts-ignore
    //         const message = error?.response?.data?.message || "Failed creating content Rule"
    //         dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
    //     }
    // })


    // @ts-ignore
    const createRuleTimePassedCondition = useMutation(({ targetField, targetFunction }: any) => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, targetFunction, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func: targetFunction, target_field: targetField })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => {
            //@ts-ignore
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            toast(message, 'error')
        }
    })


    async function createRuleConditionForContent() {
        let data = null
        if (passedContent.targetField && passedContent.targetFunction) {

            const resultCondition = await createRuleTimePassedCondition.mutateAsync(
                {
                    targetField: passedContent.targetField,
                    targetFunction: passedContent.targetFunction
                }
            )
            data = resultCondition?.data
        } else {
            const resultRule = await createRuleCondition.mutateAsync()
            data = resultRule?.data
        }
        return data?.id || null
    }

    const onSubmit: SubmitHandler<selector> = async data => {
        await trigger()

        let fileUpload = {
            link: ''
        }
        if (file !== undefined) {
            const formData = new FormData();
            // @ts-ignore
            formData.append("file", file);
            const { link } = await uploadForm(formData)
            fileUpload.link = link
            // fileUpload = await uploadFile(formData)
        }

        let shapeData;
        if (isContentTemplate) {
            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "selector",
                priority: contentPriority,
                // tags: data?.tags ? [data.tags] : [],
                // cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                // preview_description: data?.preview_description,
                // preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                // selector: {
                //     title: data.title,
                //     description: data.description,
                //     items: data.items || [],
                //     variants: data.variants || [],
                //     owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                // }
            }

        } else {
            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "selector",
                priority: isContentTemplate ? contentPriority : Number(data.priority),
                tags: data?.tags ? [data.tags] : [],
                cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                preview_description: data?.preview_description,
                preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                selector: {
                    title: data.title,
                    description: data.description,
                    items: data.items || [],
                    variants: data.variants || [],
                    owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                }
            }
        }

        if (type === 'update') {
            const timeId = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined

            if (timeEvent.isAdded) {
                if (defaultValue?.time_condition && timeId) {
                    await updateTimeEvent.mutateAsync({
                        id: timeId,
                        deliver_time: timeValue ? timeValue : 5,
                        deliver_unit: typeOf || "minute",
                        state_id: parseInt(defaultValue.state_id),
                    })
                }
                // else if (passedContent.targetField && passedContent.targetFunction) {
                //     await createRuleTimePassedCondition.mutateAsync(
                //         {
                //             targetField: passedContent.targetField,
                //             targetFunction: passedContent.targetFunction
                //         }
                //     )
                // }
                else await createTimeRule(defaultValue.id)
            }

            if (!timeEvent.isAdded && defaultValue?.is_time_base) {
                if (id) await deleteCondition.mutateAsync(timeId)
            }

            await UpDateFunc(shapeData)
        }
        // if (type === 'create') {
        //     let ruleID = (timeEvent.isRuleAdded && ruleId.event !== 0) ? ruleId?.event || null : null
        //     if (timeEvent.isRuleAdded && ruleId.event === 0) {
        //         if (query.rules.length !== 0) {
        //             ruleID = await createRuleConditionForContent();
        //             // if(passedContent.targetField && passedContent.targetFunction) {
        //             //     const {data} = await createRuleTimePassedCondition.mutateAsync()
        //             // } else {
        //             //     const {data} = await createRuleCondition.mutateAsync()
        //             // }
        //             // ruleID = data?.id || null
        //         }
        //     }
        //     await createContents.mutateAsync({
        //         ...shapeData,
        //         state_id: parseInt(id),
        //         organization_id: organization_id,
        //         pathway_id: state.currentFlow.id,
        //         rules_content_id: ruleID
        //     })
        // }
    };


    const onFileChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setImageName(file.name)
            const imageDataUrl = await readFile(file)
            setImageSrc(imageDataUrl)
            e.target.value = ""
        }
    }

    const handleAddItem = () => {
        if (editItem.isEditItem) {
            const updatedItem = [...items];
            updatedItem[editItem.indexItem] = updatedItem[editItem.indexItem] = {
                title: input, description: itemDesc, image: imageData || updatedItem[editItem.indexItem].image
            };
            setValue('items', updatedItem);
            setImageSrc("")
            setInput("")
            setImageName("")
            setItemDesc("")
            setEditItem({ isEditItem: false, indexItem: 0 })
            return;
        }
        setValue("items", [...items, { title: input, image: imageData }])
        setImageSrc("")
        setInput("")
        setImageName("")
        setItemDesc("")
    }

    const handleRemove = (ind: number) => setValue('items', items.filter((_, index) => ind !== index))

    const handleAddVariants = () => {
        if (edit.isEdit) {
            const updatedItem = [...variant];
            updatedItem[edit.index] = updatedItem[edit.index] = variants;
            setValue('variants', updatedItem);
            setVariants('')
            setEdit({ isEdit: false, index: 0 })
            return;
        }
        setValue("variants", [...variant, variants])
        setVariants("")
    }

    const handleRemoveVariants = (ind: number) => setValue('variants', variant.filter((_, index) => ind !== index))

    useEffect(() => {
        if (defaultValue) {
            const id = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined
            setRuleId({ time: id, event: defaultValue?.rules_content_id })

            if (defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.deliver_time) {
                //@ts-ignore
                setValue("timeValue", defaultValue?.time_condition[0]?.deliver_time)  //@ts-ignore
                setValue("typeOf", defaultValue?.time_condition[0]?.deliver_unit)
            }
            setContentPriority(defaultValue?.priority)
            setAltTitle(defaultValue?.alt_title)

        }
    }, [])

    
    const onUpdateContentFields = async (priority:number, alternativeTitle:string) => {
        const shapeData  = {
            ...defaultValue,
            priority: priority,
            alt_title: alternativeTitle
        }
        await updateContentMutation.mutateAsync(shapeData)
    }


    const contentSlug = defaultValue?.content_template?.selector[0]?.slug || defaultValue?.slug

    return (
        <div className="flex items-center w-full">
        <div className="flex flex-col w-full gap-4">
                {
                    contentSlug ? 
                    <CustomClipboard showInfoIcon clipboardText={contentSlug} />
                    : null
                }

                    {
                        type === 'update' && isContentTemplate === true ? <>
                            <Selector contentTemplate={defaultValue?.content_template} 
                            priority={contentPriority} 
                            alternativeTitle={altTitle}
                            onUpdateFields={onUpdateContentFields} />
                        </> : null
                    }

                <form onSubmit={handleSubmit(onSubmit)}
                    className='w-full h-full pb-2 space-y-3 overflow-auto text-gray-600 text-md'>
                    

                    {type === 'create' ? <>
                        <AppInput placeholder='Search' value={searchContentTemplate} onChange={e => setSearchContentTemplate(e.target.value)} name='searchTemplate' />
                        <div className='flex items-center justify-center'>
                            {selectorCcontentTempalteIsLoading ? <AppLoader isLoading size={35} /> : null}
                        </div>

                        <div className='grid grid-cols-2 gap-1'>
                            {search_result?.length === 0 ? <p className='my-2 text-md'>There isn't any template to show.</p> : search_result && search_result.map((item, index) => {
                                return <div className='flex flex-col items-start justify-start p-4 m-1 transition-all delay-75 rounded-md shadow-sm bg-gray-50 hover:bg-white' key={index}>
                                    <span
                                        onClick={() => addContentTemplate(item)}
                                        className='px-2 text-xs border rounded hover:cursor-pointer hover:bg-sky-100 border-sky-500 bg-sky-50 text-sky-700'>Add +</span>
                                    <p className='my-1 font-medium text-md'>{item.title}</p>
                                    <p className='flex-1 mb-3 text-xs'>
                                        {item.description}
                                    </p>
                                    <p className='my-1 text-xs'>
                                        {/* @ts-ignore */}
                                        Languages: <span className='font-medium uppercase text-sky-700'>{item.metaData?.map((item: any) => item.language).join(', ')}</span>
                                    </p>
                                    {item.updated_at ? <p className="mt-1 text-xs">Last updated {moment(item.updated_at).fromNow() + '. on ' + moment(item.updated_at).format('MMM D, YYYY')
                                    }</p> : null}
                                </div>
                            })}
                        </div>

                        <div className="flex">
                            <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
                                onClick={() => {
                                    mutate('previous')
                                }}
                                extendClass="mx-1">Previous</AppButton>
                            <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
                                onClick={() => {
                                    mutate('next')
                                }}
                                extendClass="mx-1">Next</AppButton>
                        </div>

                    </> : null}

                    {
                        type === 'update' && isContentTemplate === true ? <>

                            {/*---------------------------------------------------------*/}
                            {/*Selector Details and Content Details tabs*/}
                            {/* <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView()
                                    e.stopPropagation()
                                }} />} title="Selector Details">Selector
                                    Details</Tab>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView()
                                    e.stopPropagation()
                                }} />} title="Content Details">Content Details</Tab>
                            </Tabs> */}

                            {/*---------------------------------------------------------*/}
                            {/*Selector Details tab with form inputs*/}
                            {/* <Fade in={tab === "Selector Details"}
                                style={{ display: tab === "Selector Details" ? '' : 'none' }}>
                                <div className="space-y-4">
                                    <Input
                                        register={() => register("title")}
                                        name="title"
                                        placeholder="Selector Title"
                                        disabled
                                        value={defaultValue?.content_template?.selector[0]?.title || ''}
                                        errors={errors} />
                                    {
                                        
                                        tab === "Selector Details" &&
                                        <TextArea
                                            register={() => register("description")}
                                            name="description"
                                            placeholder="Selector Description"
                                            disabled
                                            value={defaultValue?.content_template?.selector[0]?.description || ''}
                                            errors={errors} />
                                    }

                                    <div className="py-1 space-y-2">
                                        <span>Items</span> */}
                                        {/* <div className="space-y-2">
                                    <div className="flex space-x-2">
                                        <StandardInput label="Item Name" value={input}
                                            onChange={(e) => setInput(e.target.value)} disabled />
                                        <StandardInput label="Item Description" value={itemDesc}
                                            onChange={(e) => setItemDesc(e.target.value)} disabled />
                                    </div>
                                    <div className="py-2">
                                        <label htmlFor="contained-button-file"
                                            className="p-3 bg-gray-300 rounded cursor-pointer">
                                            Upload Image
                                        </label>
                                        <p className="py-2">{imageName}</p>
                                        <input onChange={onFileChange}
                                             disabled
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="contained-button-file"
                                        />
                                    </div>
                                </div>
                                <Button onClick={handleAddItem}
                                    disabled={!editItem.isEditItem && imageName === "" || input === ""}
                                    label={
                                        <div className="flex items-center space-x-2 text-white">
                                            <p>{editItem.isEditItem ? 'Update Item' : 'Add Item'}</p>
                                        </div>
                                    } extraClasses="w-fit p-2" /> */}

                                        {/* <div className="pt-2 space-y-2">
                                            {
                                                defaultValue?.content_template?.selector[0]?.items && defaultValue?.content_template?.selector[0]?.items?.map((item: any, index: number) => <div className="flex items-center w-full space-x-2"
                                                    key={index}>
                                                    <div className="flex items-center w-full space-x-4">
                                                        <NestedContentListItem label={item.title} />
                                                        {item.image ? <><img className="h-10" src={item.image} alt="itemImage" />
                                                            <MdModeEditOutline onClick={() => handleEditItem(index)}
                                                                className="text-2xl cursor-pointer" /></> : null}

                                                    </div>
                                                    {item.image ? <AiFillDelete onClick={() => handleRemove(index)}
                                                        className="text-3xl cursor-pointer" /> : null}

                                                </div>)
                                            }
                                        </div>
                                    </div> */}


                                    {/* <div className="space-y-2">
                                <span>Variants</span>
                                <StandardInput disabled label="Item Variant" value={variants}
                                    onChange={(e) => setVariants(e.target.value)} />
                                <Button onClick={handleAddVariants} disabled label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <p>{edit.isEdit ? "Update Variant" : "Add Variant"}</p>
                                    </div>
                                } extraClasses="w-fit p-2" />
                            </div> */}

                                    {/* <div className="pt-4 space-y-2">
                                        <span>Variants</span>
                                        {
                                            defaultValue?.content_template?.selector[0]?.variants && defaultValue?.content_template?.selector[0]?.variants?.map((item: any, index: number) => <div className="flex items-center w-full space-x-2"
                                                key={index}>
                                                <NestedContentListItem label={item} /> */}
                                                {/* <MdModeEditOutline onClick={() => handleEdit(item, index)}
                                            className="text-2xl cursor-pointer" />
                                        <AiFillDelete onClick={() => handleRemoveVariants(index)}
                                            className="text-3xl cursor-pointer" /> */}
                                            {/* </div>)
                                        }
                                    </div>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }


<AppThemeButton  type='button' onClick={() => { navigate(`/content-templates/selector/view/${defaultValue?.content_template?.id}`)  }} size='md' variant="info">
                                        <div className='flex items-center justify-between gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
</svg>
<span>Edit Selector Template</span>
                                        </div>
                                        </AppThemeButton> 
                                </div>
                            </Fade> */}

                            {/*---------------------------------------------------------*/}
                            {/*Content Details tab with form inputs*/}
                            {/* <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div> */}
                                    {/* <ContentExtra query={query} setQuery={setQuery} prevTitle={watch("preview_title")}
                                rule={ruleId} setRule={setRuleId} name={name} defaultValue={defaultValue}
                                register={register} errors={errors}
                                render={render} typeOf={typeOf} setTimeEvent={setTimeEvent}
                                setPassedContent={setPassedContent}
                            /> */}

                                    {/* <div className="py-4 space-y-3">
                                        <div className="grid grid-cols-1 gap-3">
                                            <AppInput label="Alternative Title" name="alt_title" onChange={e => {
                                                setAltTitle(prev => {
                                                    return e.target.value
                                                })
                                            }}

                                                maxLength={30} value={altTitle} isFull extendClass='my-2' />

                                            <AppInput label="Content Card" name="content_card" value={defaultValue?.content_template?.content_metadata[0]?.preview_title || ''} disabled isFull extendClass='my-2' />

                                            <AppInput
                                                label="Content Tag"
                                                name="content_tag"
                                                value={defaultValue?.content_template?.content_metadata?.[0]?.tags?.[0]}
                                                disabled
                                                isFull
                                                extendClass='my-2'
                                            />

{defaultValue?.content_template?.content_metadata[0]?.cover_image ? <div className="cover__image-container">
                                                <img className='rounded-sm' alt='cover image' title='cover image' src={defaultValue?.content_template?.content_metadata[0]?.cover_image} />
                                                </div> : null}
                                        </div>
                                    </div>
                                    <AppInput label="Content Priority" type='number' min={0} name="content_priority" onChange={e => setContentPriority(e.target.valueAsNumber)} value={contentPriority.toString()} isFull extendClass='my-2' /> */}

                                    {/* <Condition
                                        errors={errors}
                                        register={register}
                                        query={query}
                                        setQuery={setQuery}
                                        isContent
                                        rule={ruleId}
                                        setRule={setRuleId}
                                        setTimeEvent={setTimeEvent}
                                        name={name}
                                        typeOf={typeOf}
                                        setPassedContent={setPassedContent}
                                    /> */}

                                    

                                      {/*Submit form to create the Selector*/}
                            {/* <Button
                                disabled={createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading}
                                type="submit" label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>Update</p>
                                    </div>
                                } extraClasses="w-fit px-3 py-3"
                            />
                                </div>
                            </Fade> */}

                            {/*---------------------------------------------------------*/}
                          
                        </> : null}

                    {
                        type === 'update' && isContentTemplate === false ? <>

                            {/*---------------------------------------------------------*/}
                            {/*Selector Details and Content Details tabs*/}
                            <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView()
                                    e.stopPropagation()
                                }} />} title="Selector Details">Selector
                                    Details</Tab>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView()
                                    e.stopPropagation()
                                }} />} title="Content Details">Content Details</Tab>
                            </Tabs>

                            {/*---------------------------------------------------------*/}
                            {/*Selector Details tab with form inputs*/}
                            <Fade in={tab === "Selector Details"}
                                style={{ display: tab === "Selector Details" ? '' : 'none' }}>
                                <div className="space-y-4">
                                    <Input
                                        register={() => register("title", { required: 'title is required', maxLength: 140 })}
                                        name="title"
                                        placeholder="Selector Title"
                                        errors={errors} />
                                    {
                                        //to stop textarea causing too many re-renders
                                        tab === "Selector Details" &&
                                        <TextArea
                                            register={() => register("description", { maxLength: 140 })}
                                            name="description"
                                            placeholder="Selector Description"
                                            errors={errors} />
                                    }

                                    <div className="py-1 space-y-2">
                                        <span>Items</span>
                                        <div className="space-y-2">
                                            <div className="flex space-x-2">
                                                <StandardInput label="Item Name" value={input}
                                                    onChange={(e) => setInput(e.target.value)} />
                                                <StandardInput label="Item Description" value={itemDesc}
                                                    onChange={(e) => setItemDesc(e.target.value)} />
                                            </div>
                                            <div className="py-2">
                                                <label htmlFor="contained-button-file"
                                                    className="p-3 bg-gray-300 rounded cursor-pointer">
                                                    Upload Image
                                                </label>
                                                <p className="py-2">{imageName}</p>
                                                <input onChange={onFileChange}
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="contained-button-file"
                                                />
                                            </div>
                                        </div>
                                        <Button onClick={handleAddItem}
                                            disabled={!editItem.isEditItem && imageName === "" || input === ""}
                                            label={
                                                <div className="flex items-center space-x-2 text-white">
                                                    <p>{editItem.isEditItem ? 'Update Item' : 'Add Item'}</p>
                                                </div>
                                            } extraClasses="w-fit p-2" />

                                        <div className="pt-2 space-y-2">
                                            {
                                                items?.map((item, index) => <div className="flex items-center w-full space-x-2"
                                                    key={index}>
                                                    <div className="flex items-center w-full space-x-4">
                                                        <NestedContentListItem label={item.title} />
                                                        <img className="h-10" src={item.image} alt="itemImage" />
                                                        <MdModeEditOutline onClick={() => handleEditItem(index)}
                                                            className="text-2xl cursor-pointer" />
                                                    </div>
                                                    <AiFillDelete onClick={() => handleRemove(index)}
                                                        className="text-3xl cursor-pointer" />
                                                </div>)
                                            }
                                        </div>
                                    </div>


                                    <div className="space-y-2">
                                        <span>Variants</span>
                                        <StandardInput label="Item Variant" value={variants}
                                            onChange={(e) => setVariants(e.target.value)} />
                                        <Button onClick={handleAddVariants} disabled={variants === ""} label={
                                            <div className="flex items-center space-x-2 text-white">
                                                <p>{edit.isEdit ? "Update Variant" : "Add Variant"}</p>
                                            </div>
                                        } extraClasses="w-fit p-2" />
                                    </div>

                                    <div className="pt-4 space-y-2">
                                        {
                                            variant?.map((item, index) => <div className="flex items-center w-full space-x-2"
                                                key={index}>
                                                <NestedContentListItem label={item} />
                                                <MdModeEditOutline onClick={() => handleEdit(item, index)}
                                                    className="text-2xl cursor-pointer" />
                                                <AiFillDelete onClick={() => handleRemoveVariants(index)}
                                                    className="text-3xl cursor-pointer" />
                                            </div>)
                                        }
                                    </div>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }
                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Content Details tab with form inputs*/}
                            <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div>

                                    {/* ===== */}

                                    <div>
                                        <label htmlFor='alt_title' className='mb-1 ml-1 text-sm'>Alternative Title</label>
                                        <input
                                            id="alt_title"
                                            type="text"
                                            className="block w-full p-4 text-gray-900 border border-gray-300 rounded-md text-md focus:ring-blue-500 focus:border-blue-500"
                                            value={altTitle}
                                            maxLength={30}
                                            onChange={e => {
                                                setAltTitle(prev => {
                                                    return e.target.value
                                                })
                                            }}
                                            placeholder='Alternative Title'
                                        />
                                    </div>

                                    {/* ======= */}

                                    <ContentExtra query={query} setQuery={setQuery} prevTitle={watch("preview_title")}
                                        rule={ruleId} setRule={setRuleId} name={name} defaultValue={defaultValue}
                                        register={register} errors={errors}
                                        render={render} typeOf={typeOf} setTimeEvent={setTimeEvent}
                                        setPassedContent={setPassedContent}
                                    />

                                    {/*                    {*/}
                                    {/*                        (ruleId.event === 0) ?*/}
                                    {/*                            <>*/}
                                    {/*                                <h4>Query</h4>*/}
                                    {/*                                <pre>*/}
                                    {/*  <code>{formatQuery(query, 'json')}</code>*/}
                                    {/*</pre>*/}
                                    {/*                            </> : null*/}
                                    {/*                    }*/}
                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Submit form to create the Selector*/}
                            <Button
                                disabled={createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading}
                                type="submit" label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>Update</p>
                                    </div>
                                } extraClasses="w-fit px-3 py-3"
                            />

                        </> : null}



                </form>
            </div>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for Selector Page*/}
            <OverlayModal onClose={handlePathwayView} isOpen={modal.pathwayView}>
                <div className="relative flex p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md h-fit w-fit">
                    <Mobile>
                        <PreviewContentModal
                            variants={variant}
                            selectorItems={items}
                            description={getValues("description")}
                            tags={[getValues("tags") || ""]}
                            type={'selector'}
                            title={title}
                            image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""} />
                    </Mobile>
                </div>
            </OverlayModal>


            {/*---------------------------------------------------------*/}
            {/*Preview modal for Video Content Card*/}
            <OverlayModal onClose={handleContentView} isOpen={modal.contentView}>
                <div
                    className="h-fit overflow-auto bg-gray-100 p-2 rounded-md flex text-gray-600 w-[40%] relative">
                    <ContentTypeCardPreview
                        type={'selector'}
                        image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""}
                        tags={[getValues("tags") || ""] || ['']}
                        title={getValues("preview_title") || defaultValue?.preview_title || ""}
                    />
                </div>
            </OverlayModal>
        </div>
    );
});

export default SelectorNew;
