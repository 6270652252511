import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../Context/UserContext/UserContext";
import { setCurrentOrganization } from "../../utils/LocalStorage";
import { useTranslation } from "react-i18next";
import DropdownLanguage from "../DropdownLanguage";
import loginLogoSrc from "./../../assets/login_logo.png";
import miniLogo from "./logo.png";
import authBg from "./auth-bg.jpg";
import { AppButton, TransitionAnimation } from "../../Theme";
import { useQuery } from "react-query";
import { getAllOrgnizationsIdentifiers } from "../../apiQuery/Organization/Organization.apis";
import { IOrganizationIdentifier } from "../../Types/Organization/Organization";
import { AppLoader } from "../Shared/AppLoader";
import { toast } from "../../utils/Toast";
import { useNavigate, useParams } from "react-router-dom";

const localEnv =
  process.env.NODE_ENV === "development" ? "?callback=local" : "";

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const { updateCurrentUser } = useContext(UserContext);

  const params = useParams<{ company: string }>()
  const navigate = useNavigate()
  
  const [organization, setOrganization] = useState({
    domain_name: "",
    display_name: ""
  });

  const { data, isLoading, isFetched } = useQuery<IOrganizationIdentifier[], Error>(
    ["organizations-all-identifiers"],
    () => getAllOrgnizationsIdentifiers()
  );


  useEffect(() => {
    if(isFetched && data) {
        setOrganization(prev=> {
          return {
            domain_name: data[0].domain_name,
            display_name: data[0].display_name
          }
        })
    }
  },[isFetched, data])



  const onSumbitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if(organization.domain_name === "") {
      toast('Please choose your workspace','info')
      return
    }

    
    setCurrentOrganization(organization.domain_name);
    updateCurrentUser({ currentOrganization: organization.display_name });
    
    let loginUrl =
    process.env.REACT_APP_API_URL +
    `organization/${organization.domain_name}/login` +
    localEnv;
  

    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      loginUrl =
        process.env.REACT_APP_API_URL + `organization/${organization}/login`;
    }
    if (process.env.REACT_APP_ENVIRONMENT === "staging") {
      loginUrl =
        process.env.REACT_APP_API_URL + `organization/${organization}/login`;
    }
  
    window.open(loginUrl, "_self");
  };



  const [companyExist, setCompanyExist ] = useState<boolean>(false)

  useEffect(() => {
    if(isFetched && data) {
      if (data.find((org) => org.domain_name === params.company)) {
        setCompanyExist(true)
        setOrganization(prev=> {
          return {
            domain_name: params.company as string,
            display_name: data.find((org) => org.domain_name === params.company)?.display_name as string
          } 
        })
      }
      else {
        navigate('/login', { replace :true})
      }
    }
  }, [data, isFetched, navigate, params.company])

  return (
    <div className="grid lg:grid-cols-[1.4fr_1fr] bg-white min-h-screen overflow-x-hidden">
      <div className="relative items-center justify-center hidden p-5 lg:flex ">
        <img
          src={authBg}
          alt="ActionLogics"
          className="absolute object-cover w-full h-full"
          loading="lazy"
        />
        <div className="absolute w-full h-full bg-gradient-to-r from-violet-600 to-indigo-600 opacity-40"></div>
        <img
          src={loginLogoSrc}
          alt="ActionLogics"
          className="relative z-10 object-contain w-96 "
        />
      </div>
      <div className="flex flex-col justify-center w-8/12 max-w-md py-10 mx-auto lg:px-16 xl:px-28 lg:max-w-full lg:w-full lg:shadow-xl lg:relative">
        <div className="flex flex-col justify-center h-full">
          <div className="bg-white">
           <TransitionAnimation position="y-axis" show>
           <div className="flex items-center justify-center gap-2 ">
              <img src={miniLogo} alt="Actionlogics" className="w-10" />
              <span className="text-lg text-textDark">
                <strong>Action</strong>logics
              </span>
            </div>
           </TransitionAnimation>
           <div className="mt-4">
              <h4 className="mb-8 text-3xl font-medium text-center text-textDark">
                {t("loginPage.Sign_in_to_your_workspace")}
              </h4>
              <form onSubmit={onSumbitHandler}>
                <div className="flex flex-col items-center mb-3">
                { 
            companyExist ? (<>
            
            {isLoading ? (
                    <AppLoader isLoading size={25} />
                  ) : (
                    <select
                      name="organization"
                      id="organization"
                      className="block cursor-pointer w-full p-3 mb-2 text-sm text-textDark border border-borderGray rounded-sm bg-[#F5F5F5] focus:ring-blueColor focus:border-blueColor  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blueColor dark:focus:border-blueColor"
                      defaultValue={params.company}
                    >
                             <option
                                value={params.company}
                              >
                                {organization.display_name}
                              </option>
                    </select>
                  )}
            
            
            
            
            </>) : (<>
              {isLoading ? (
                    <AppLoader isLoading size={25} />
                  ) : (
                    <select
                      onChange={(el) =>
                        setOrganization(prev=> {
                          return {
                            display_name: data?.find(item => item.domain_name === el.target.value)?.display_name || '',
                            domain_name: el.target.value.toString()
                          }
                        })
                      }
                      name="organization"
                      id="organization"
                      className="block cursor-pointer w-full p-3 mb-2 text-sm text-textDark border border-borderGray rounded-sm bg-[#F5F5F5] focus:ring-blueColor focus:border-blueColor  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blueColor dark:focus:border-blueColor"
                      defaultValue={organization.domain_name}
                    >
                      {data
                        ? data?.map((item) => {
                            return (
                              <option
                                value={item.domain_name}
                                key={item.identifier}
                              >
                                {item.display_name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  )}
              </>)
            }
                  
                </div>

                <div className="flex items-center justify-center text-center lg:text-center">
                  <AppButton disabled={isLoading} type="submit" variant="primary" size="md" isFull>
                    <span className="text-lg text-textDark">
                      {t("loginPage.Login")}
                    </span>
                  </AppButton>
                </div>
              </form>
            </div>


          </div>
        </div>
        <p className="text-sm font-thin text-center text-textGray">
          All Rights Reserved By Actionlogics &copy; {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
