import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react'

const NewUseUploadFile = (title: string, src?: any) => {
	const [imageSrc, setImageSrc] = useState<string>()
	const [file, setFile] = useState<any>(undefined)
    const uniqueId = `contained-button-file-${Math.random()}`
    
    // useEffect(() => {
    //     console.log(file, 'fili')
    // }, [file])

	const onFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		async (e: any) => {
			if (e.target.files && e.target.files.length > 0) {
				const file = e.target.files[0]
				setFile(file)
				const imageDataUrl = URL.createObjectURL(file)
				setImageSrc(imageDataUrl)
				e.target.value = ''
			}
		},
		[]
	)

	return [
		imageSrc,
		file,
		<div>
			{!(imageSrc || src) && <label
				htmlFor={uniqueId}
				className="flex flex-col items-center justify-center w-full h-full p-8 text-gray-400 bg-white border-2 border-gray-300 border-dashed cursor-pointer"
			>
				<span>{title} Cover Image</span>
				<span>(16:9 ratio, 25mb limit)</span>
			</label>}

            <div>
                <label htmlFor={uniqueId}>
                <div className='cover__image-container'>
				{(imageSrc || src) && <img className="cursor-pointer " src={imageSrc || src} alt="user uploaded file" />}
				</div>
                </label>
				<input
					onChange={onFileChange}
					type="file"
					accept="image/*"
					style={{ display: 'none', height: '100%', width: '100%' }}
					id={uniqueId}
				/>
			</div>
		</div>,
    file?.name,
    setImageSrc
	]
}

export default NewUseUploadFile
