import React, {FC, memo} from 'react';
import {Job_T} from "../../Common/Types";

const JobList: FC<Job_T> = memo(({
                                     name,
                                     weekday,
                                     hour,
                                     minute,
                                     offset_hour,
                                     offset_minute,
                                     offset,
                                     weekday_name,
                                     func_name
                                 }) => {
    return (
        <div className="border border-[#D8D8D8] rounded bg-white font-light min-[60rem] h-auto p-4">
            <ul>
                <ul>
                    name:{name}
                </ul>
                <ul>
                    weekday:{weekday}
                </ul>
                <ul>
                    hour:{hour}
                </ul>
                <ul>
                    minute:{minute}
                </ul>
                <ul>
                    offset_hour:{offset_hour}
                </ul>
                <ul>
                    offset_minute:{offset_minute}
                </ul>
                <ul>
                    offset:{offset}
                </ul>
                <ul>
                    weekday_name:{weekday_name}
                </ul>
                <div className="flex">
                    <p>func_name:</p>
                    <div className="flex flex-wrap">
                        {func_name?.map((item,index)=><p className="" key={index}>{item}/</p>)}
                    </div>
                </div>
            </ul>
        </div>
    )
})

export default JobList;