import axios from "axios";
import {getToken} from "../utils/LocalStorage";

let url = process.env.REACT_APP_SHEDULER_API

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    url = process.env.REACT_APP_SHEDULER_API
}
if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    url = process.env.REACT_APP_SHEDULER_API
}

export const baseURL = url

const SCHEDULERAPI = axios.create({
    baseURL
});

SCHEDULERAPI.interceptors.request.use(async (config) => {
    // const token = getToken()
    // if (config.headers) {
    //     config.headers['Authorization'] = `Bearer ${token}`
    // }
    return config;
}, (error) => {
    return Promise.reject(error);
});

SCHEDULERAPI.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default SCHEDULERAPI