import React from "react";

const MembersTab: React.FC = () => {
  return (
    <div className="flex flex-col gap-5 mb-12">
      <div className="mb-3">
        <h2 className="text-textDark text-2xl font-normal">Members</h2>
        <p className="text-sm text-textDark">
          Manage who has access to this organization.
        </p>
      </div>
      <hr className="border-1 h-px border-borderGray dark:border-textDark" />
        <div>
            <h2 className="text-xl mt-2">Manage members</h2>
            <p className="text-sm text-textDark mt-2 mb-6">On the Free plan all members in an organization are administrators. Upgrade to the Standard plan to add the ability to assign or remove administrator roles.</p>
            <input
                type="text"
                className="border border-borderGray rounded-md p-1 w-8/12 mb-4"
                placeholder="Search by name or email"
            />
            <table className="table-fixed text-left">
                <tbody>
                    <tr>
                        <td width={350}>
                            <p className="text-base text-textDark">Kristian Bouw</p>
                            <p className="text-base text-textGray">kristian.bouw@cravety.com</p>
                        </td>
                        <td width={200}>
                            <p className="text-base text-textDark">Admin</p>
                        </td>
                        <td>
                            <p className="text-base text-textDark">(edit)</p>
                        </td>
                    </tr>
                    <div className="my-3"></div>
                    <tr>
                        <td width={350}>
                            <p className="text-base text-textDark">JG Staal</p>
                            <p className="text-base text-textGray">jg@cravety.com</p>
                        </td>
                        <td width={200}>
                            <p className="text-base text-textDark">Admin</p>
                        </td>
                        <td>
                            <p className="text-base text-textDark">(edit)</p>
                        </td>
                    </tr>
                    <div className="my-3"></div>
                    <tr>
                        <td width={350}>
                            <p className="text-base text-textDark">Mostafa Bestamy</p>
                            <p className="text-base text-textGray">mostafa.bestamy@cravety.com</p>
                        </td>
                        <td width={200}>
                            <p className="text-base text-textDark">Admin</p>
                        </td>
                        <td>
                            <p className="text-base text-textDark">(edit)</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  );
};

export default MembersTab;
