export const DeleteIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      aria-hidden="true"
      className={`h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white
  ${className ? ` ${className}` : ""}`}
    >
      <path
        fill="currentColor"
        d="M261 936q-24 0-42-18t-18-42V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261zm106-146h60V391h-60v399zm166 0h60V391h-60v399z"
      ></path>
    </svg>
  );
};

DeleteIcon.defaultProps = {
  className: "",
};
