import React, {FC, memo, useCallback, useState} from 'react';
import {MdAnnouncement, MdArticle, MdChecklistRtl, MdClose, MdDirections} from "react-icons/md";
import ArticleNew from "./ArticleNew";
import VideoNew from "./VideoNew";
import ChecklistNew from "./ChecklistNew";
import DirectionNew from "./DirectionNew";
import {RiCheckDoubleFill} from "react-icons/ri";
import {state_props} from "../../../Common/Types";
import Tabs from "../../TabsT/Tabs";
import Tab from "../../TabsT/Tab";
import UseTab from "../../../Hook/UseTab";
import {Fade} from "@mui/material";
import {RiSurveyFill, RiVideoFill} from "react-icons/ri";
import {BsFillLayersFill, BsFillQuestionSquareFill} from "react-icons/bs";
import SelectorNew from "./SelectorNew";
import AnnouncementContent from "./AnnouncementNew";
import FAQNew from "./FAQNew";
import ConsentNew from "./ConsentNew";
import SurveyNew from "./SurveyNew";
import SurveySequence from "./Survey_Sequence";
import {VscGraph} from "react-icons/vsc";
import { useTranslation } from 'react-i18next';
import { ContentTemplateIcons } from '../../../Theme';

const Content: FC<state_props> = memo(({onClose, setState, onSuccess}) => {
    const { t, i18n } = useTranslation();

    const [selected, setSelected] = useState('Article')

    const onCLickHandle = useCallback((item: string) => setSelected(item), [selected])

    const [tab, setTab] = UseTab('Create New Content')

    return (
        <>

            {/*---------------------------------------------------------*/}
            {/*Create New Content modal with Content type tabs*/}
            <div className="relative flex w-9/12 h-full p-8 overflow-auto text-gray-600 bg-white rounded-md modal max-w-screen-2xl">
                <div className="w-full">
                    <div className="flex items-center justify-between w-full pb-4 text-lg">
                        <p className="text-2xl ">{t('Content.Add_Content')}</p>
                        <div onClick={onClose} className="p-1 bg-gray-400 rounded-full cursor-pointer">
                            <MdClose className="text-white"/>
                        </div>
                    </div>

                    {/*---------------------------------------------------------*/}
                    {/*Create New Content or Add From Template tabs*/}
                    <Tabs selectedTab={tab} setSelectedTab={setTab}>
                        <Tab title={`${t('Content.Create_New_Content')}`}  TabKey='Create New Content'>{`${t('Content.Create_New_Content')}`}</Tab>
                        <Tab title=""  TabKey=''></Tab>

                        {/*<Tab title="Add Content From Template">Add Content From Template</Tab>*/}
                    </Tabs>

                    {/*---------------------------------------------------------*/}
                    {/*Content container for each Content Type*/}
                    <div className="flex border border-[#D8D8D8] rounded bg-white w-full my-5">
                        <div className="w-3/12 text-lg border-r border-[#D8D8D8] rounded">
                        {/* {ContentTemplateIcons[content_type as EnumContentType]} */}
                            {
                                [
                                    {icon: ContentTemplateIcons.article, text:  `${t('Content.Article')}`, key: 'Article'},
                                    {icon: ContentTemplateIcons.announcement, text:`${t('Content.Announcement')}` , key: 'Announcement'},
                                    {icon: ContentTemplateIcons.checklist, text: `${t('Content.Checklist')}` , key: 'Checklist'},
                                    {icon: ContentTemplateIcons.consent, text:`${t('Content.Consent')}`, key: 'Consent'},
                                    {icon: ContentTemplateIcons.direction, text:`${t('Content.Directions')}`, key: 'Directions'},
                                    {icon: ContentTemplateIcons.faq, text:`${t('Content.FAQ')}` , key: 'FAQ'},
                                    {icon: ContentTemplateIcons.selector, text: `${t('Content.Selector')}`, key: 'Selector'},
                                    {icon: ContentTemplateIcons.survey, text:`${t('Content.Survey')}`, key: 'Survey'},
                                    {icon: ContentTemplateIcons.video, text:`${t('Content.Video')}` , key: 'Video'},
                                    {icon: ContentTemplateIcons.survey_sequence, text: `${t('Content.Survey_Sequence')}`, key: 'survey_sequence'},
                                ].map((item, index) => {
                                    return <div key={index}
                                                // className={`${selected === item.key && 'bg-blue-600 text-white'}`}>
                                                className={`${selected === item.key && 'bg-blueColor'}`}>
                                        <div
                                            className={`flex items-center p-3 cursor-pointer space-x-2`}
                                            onClick={() => onCLickHandle(item.key)}>
                                            <div className={`w-6 h-6 ${selected === item.key ? 'text-white' : 'text-grayColor'}`}>
                                            {item.icon}
                                            </div>
                                            <span className={`${selected === item.key ? 'text-white' : 'text-gray-600'}`}>{item.text}</span>
                                        </div>
                                    </div>
                                })
                            }
                        </div>

                        {/*---------------------------------------------------------*/}
                        {/*Content type list when creating new Content*/}
                        <Fade in={tab === "Create New Content"} style={{display: tab === "Create New Content" ? '' : 'none'}}>
                            <div className="flex w-full py-5 bg-gray-100">
                                <div className="w-full px-4 space-y-2">
                                    {(() => {
                                        switch (selected) {
                                            case 'Article':
                                                return <ArticleNew onClose={onClose} onSuccess={onSuccess} type={'create'}/>
                                            case 'Video':
                                                return <VideoNew setState={setState} type={'create'} onClose={onClose} onSuccess={onSuccess}/>
                                            case 'Checklist':
                                                return <ChecklistNew setState={setState} type={'create'} onClose={onClose} onSuccess={onSuccess}/>
                                            case 'Directions':
                                                return <DirectionNew setState={setState} onClose={onClose} onSuccess={onSuccess} type={'create'}/>
                                            case 'Selector':
                                                return <SelectorNew setState={setState} type={'create'} onClose={onClose} onSuccess={onSuccess}/>
                                            case 'Announcement':
                                                return <AnnouncementContent setState={setState} type={'create'} onClose={onClose} onSuccess={onSuccess}/>
                                            case 'Consent':
                                                return <ConsentNew setState={setState} type={'create'} onClose={onClose} onSuccess={onSuccess}/>
                                            case 'FAQ':
                                                return <FAQNew setState={setState} type={'create'} onClose={onClose} onSuccess={onSuccess}/>
                                            case 'Survey':
                                                return <SurveyNew setState={setState} type={'create'} onClose={onClose} onSuccess={onSuccess}/>
                                            case 'survey_sequence':
                                                return <SurveySequence setState={setState} type={'create'} onClose={onClose} onSuccess={onSuccess}/>
                                            default:
                                                return null
                                        }
                                    })()}
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>

    );
});

export default Content;