import React, { FC, InputHTMLAttributes } from 'react'

export type AppTextAreaProps = {
  value: string
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  isFull?: boolean
  label?: string
  error?: string
  extendClass?: string
  disabled?: boolean
  rows?: number
} & InputHTMLAttributes<HTMLTextAreaElement>

const AppTextArea: FC<AppTextAreaProps> = props => {
  const { label, name, value, rows, onChange, error, isFull, onBlur, disabled, extendClass, ...rest } = props
  return (
    <div className={`flex flex-col space-y-1 my-2 mt-6 ${isFull ? 'w-full' : ''}`}>
      {label && (
        <label htmlFor={name} className='block mb-1 text-base font-normal text-black dark:text-white'>
          {label}
        </label>
      )}
<textarea
        rows={rows}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className={`
         block w-full rounded-sm border border-borderGray bg-[#F5F5F5] placeholder:text-[#A4A5A5] p-2.5 text-md text-gray-900 
         focus:ring-blueColor
         focus:border-blueColor
         disabled:opacity-50
         disabled:cursor-not-allowed
         disabled:bg-gray-200
         disabled:border-gray-300
         disabled:text-gray-800
         ${extendClass || ''}`}
        {...rest}
      />
    </div>
  )
}

AppTextArea.defaultProps = {
  isFull: false,
  disabled: false
}
export default AppTextArea
