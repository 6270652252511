import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
interface Lang {
  CurrentLang: string;
}
const DropdownLanguage = (props: Lang) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  useEffect(() => {
    if (props.CurrentLang.startsWith("en-")) {
     
      setLanguage("en");
      i18n.changeLanguage("en");
    } else if (props.CurrentLang.startsWith("fr-")) {
      setLanguage("fr");
      i18n.changeLanguage("fr");
    } else if (props.CurrentLang.startsWith("es-")) {
      setLanguage("es");
      i18n.changeLanguage("es");
    }
  }, []);

  const handleLangChange = (evt: any) => {
    const lang = evt.target.value;
   
    setLanguage(lang);
    i18n.changeLanguage(lang);
    
  };

  return (
    <select
      className="text-sm rounded-sm w-36"
      onChange={handleLangChange}
      value={language}
    >
      <option value="en">English</option>
      <option value="fr">French</option>
      <option value="es">Spanish</option>
    </select>
  );
};

export default DropdownLanguage;

function elseif() {
  throw new Error("Function not implemented.");
}
