import React, {FC, memo, ReactNode, useContext, useRef, useState} from "react";
import {BsInfoSquareFill} from "react-icons/bs";
import {BiDotsVerticalRounded} from "react-icons/bi";
import useOnClickOutside from "../../Hook/UseOutsideHook";
import {HiUsers} from "react-icons/hi";
import {FaList} from "react-icons/fa";
import {Link} from "react-router-dom";
import {MdError} from "react-icons/md";
import { UserContext } from "../../Context/UserContext/UserContext";
import { useTranslation } from 'react-i18next';

type options = {
    icon: ReactNode,
    text: string,
    onClick: (id: number) => void
}
type SearchProps = {
    id?: number
    title?: string;
    description?: string,
    status?: boolean,
    to?: string,
    onClick?: () => void
    individuals?: number,
    stats?: number,
    actions?: number,
    contents?: number,
    dayAverageDuration?: number,
    linked_flows_count?: number
    dotOptionElm?: options[],
    specialText?:string | undefined
    specialText2?:string | undefined
    cardText?: string;
    cardTypeIcon?: ReactNode;
}

const PathwayCard: FC<SearchProps> = memo(({
                                               title,
                                               status,
                                               description,
                                               dotOptionElm,
                                               individuals,
                                               stats,
                                               actions,
                                               contents,
                                               dayAverageDuration,
                                               linked_flows_count,
                                               specialText,
                                               to,
                                               onClick,
                                               specialText2,
                                               id,
                                               cardText,
                                               cardTypeIcon
                                           }) => {
    const [showOption, setShowOption] = useState(false)
    const { isAdmin } = useContext(UserContext)
    const optionRef = useRef() as any;
    const { t, i18n } = useTranslation();

    useOnClickOutside(optionRef, () => setShowOption(false))

    const handleItemDispatch = (item: any) => {
        if (!id && id !== 0) return
        item.onClick(id)
        setShowOption(false)
    }

    return (
        <div className="border border-[#D8D8D8] rounded bg-white font-light">
            <Link to={to ? to : ''} onClick={onClick ? onClick : () => {
            }}>
                <div className="flex flex-col p-3 h-48 space-y-1.5 overflow-hidden">
                    <div className="flex justify-between mb-2">
                        <div className="flex flex-row items-center font-medium text-blue-600">
                            {cardTypeIcon}
                            <span className="flex text-md">{cardText}</span>
                        </div>
                        {
                            status !== undefined &&
                            <div className="flex">
                                {status ? <span className="inline text-sm font-medium text-blue-600">{t('PathwayPage.Active')}  </span> :
                                    <span className="inline text-sm font-medium text-orange-400"> {t('PathwayPage.Inactive')} </span>}
                            </div>
                        }
                    </div>
                    <span className="text-2xl font-medium text-gray-600 line-clamp-2">{title}</span>
                    <span className="text-sm font-normal text-gray-600 line-clamp-3">{description}</span>

                    {
                        specialText !== undefined &&
                        <div className="flex items-center space-x-1.5 rounded-md bg-yellow-100 pl-2 pr-2 pt-1 pb-1 text-yellow-800">
                            <MdError/>
                            <p>{specialText}</p>
                        </div>
                    }
                    {
                        specialText2 !== undefined &&
                        <div className="flex items-center space-x-1.5 rounded-md bg-sky-100 pl-2 pr-2 pt-1 pb-1 text-sky-700">
                            <BsInfoSquareFill/>
                            <p className="font-normal">{specialText2}</p>
                        </div>
                    }
                </div>
            </Link>
            <hr className="border"/>

            <div className="relative flex items-center justify-between p-3">
                <div className="grid grid-cols-2 gap-y-1.5 gap-x-3.5">
                    {
                        stats !== undefined &&
                        <div className="flex items-center space-x-1.5">
                            <FaList/>
                            <p>{stats} {t('PathwayPage.States')}</p>
                        </div>
                    }
                    {
                        individuals !== undefined &&
                        <div className="flex items-center space-x-1.5">
                            <HiUsers/>
                            <p>{individuals} {t('PathwayPage.Workers')}</p>
                        </div>

                    }

                    {
                        contents !== undefined &&
                        <div className="flex items-center space-x-1.5">
                            <FaList/>
                            <p>{contents} {t('PathwayPage.Contents')} </p>
                        </div>
                    }
                    {
                        actions !== undefined &&
                        <div className="flex items-center space-x-1.5">
                            <FaList/>
                            <p>{actions} {t('PathwayPage.Actions')} </p>
                        </div>
                    }
                    {/*{*/}
                    {/*    dayAverageDuration !== undefined &&*/}
                    {/*    <div className="col-span-2 flex items-center space-x-1.5">*/}
                    {/*        <RiTimerFill/>*/}
                    {/*        <p>{dayAverageDuration} Day Average Duration</p>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {
                        linked_flows_count !== undefined &&
                        <div className="flex items-center space-x-1.5">
                            <FaList/>
                            <p>Linked in {linked_flows_count} flows</p>
                        </div>
                    }

                </div>
                {isAdmin() &&  <div className="flex items-center space-x-2.5 text-xl">
                    <div ref={optionRef}>
                        {
                            dotOptionElm &&
                            <BiDotsVerticalRounded className="cursor-pointer "
                                                   onClick={() => setShowOption(prev => !prev)}/>
                        }
                        {
                            (showOption && dotOptionElm) &&
                            <div
                                className="absolute top-2/5 right-[-3rem] bg-white text-sm border shadow py-3 px-4">
                                <div className="space-y-2">
                                    {dotOptionElm?.map((item, index) => <div key={index}
                                                                             onClick={() => handleItemDispatch(item)}
                                                                             className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                                        {item.icon}
                                        <p>{item.text}</p>
                                    </div>)}
                                </div>
                            </div>
                        }
                    </div>
                </div> }
               

            </div>
        </div>
    );
});

export default PathwayCard;