import { FC, useState, useMemo, useEffect } from "react";
import { SelectedModalEnum } from "../Rule.types";
import { useMutation, useQuery } from "react-query";
import {
  createRule,
  getRuleOprands,
} from "../../../../apiQuery/Condition/Rule.apis";
import { IOperandsResponse } from "../../../../Types/Operands/Operands";
import initialQuery from "../../../../utils/InitialRules";
import {
  Field,
  RQBJsonLogic,
  RuleGroupType,
  parseJsonLogic,
} from "react-querybuilder";
import {
  QueryBuilderContainer,
  formatterQuery,
  backendReplaceAllObjectKeys,
} from "../../../Container";
import { AppButton, CloseIcon } from "../../../../Theme";
import { ruleBody } from "../../../../Common/Types";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import {
  getOneContentRule,
  updateContentRule,
} from "../../../../apiQuery/Condition/Content.Rules.api";
import {
  getOneActionRule,
  updateActionRule,
} from "../../../../apiQuery/Condition/Action.Rules.api";
import { getOneStateRule, updateStateRule } from "../../../../apiQuery/Condition/State.Rules.api";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import { renameRuleName } from "../../../../utils/RuleHelper"

interface EditRuleModalProps {
  type: SelectedModalEnum;
  ruleId: number;
  modalHandle: () => void;
}

export const EditRuleModal: FC<EditRuleModalProps> = (props) => {
  const [query, setQuery] = useState<RuleGroupType>(initialQuery);

  const [ruleName, setRuleName] = useState("");
  const [ruleDescription, setRuleDescription] = useState("");

  const [deliver, setDeliver] = useState({
    deliver_time: 0,
    deliver_unit: "",
    display_unit: "",
  });

  const [targetRules, setTargetRules] = useState({
    targetField: "",
    targetFunction: "",
  });

  const [rule, setRule] = useState<any>();

  const {
    isLoading,
    data: operands,
    error,
  } = useQuery<IOperandsResponse, Error>("operands", getRuleOprands);

  const { data: contentRule } = useQuery(
    ["get-one-content-rule", props.ruleId],
    () => getOneContentRule(props.ruleId),
    {
      enabled: props.type === SelectedModalEnum.content,
    }
  );

  const { data: actionRule } = useQuery(
    ["get-one-action-rule", props.ruleId],
    () => getOneActionRule(props.ruleId),
    {
      enabled: props.type === SelectedModalEnum.action,
    }
  );

  const { data: stateRule } = useQuery(
    ["get-one-state-rule", props.ruleId],
    () => getOneStateRule(props.ruleId),
    {
      enabled: props.type === SelectedModalEnum.state,
    }
  );

  useEffect(() => {
    const processObj = async () => {
      if (props.type === SelectedModalEnum.content && contentRule?.data) {
        const rule = contentRule.data.rule_json[0];
        const jsonLogic = await backendReplaceAllObjectKeys(rule.when);
        setQuery(parseJsonLogic(jsonLogic as RQBJsonLogic));
        setDeliver({
          // deliver_time: contentRule.data.deliver_time,
          deliver_time: Number(
            contentRule?.data.display_unit === "day"
              ? contentRule?.data.deliver_time / 24
              : contentRule?.data.deliver_time
          ),
          deliver_unit: contentRule.data.deliver_unit || "minute",
          display_unit: contentRule.data.display_unit || "minute",
        });
        setRuleName(rule.name.replaceAll("_", " "));
        setRuleDescription(rule.desc);
        setRule(contentRule.data);
        console.log("content Rule", {
          targetField: contentRule.data.target_field,
          targetFunction: contentRule.data.target_func,
        });
        setTargetRules({
          targetField: "OP." + contentRule.data.target_field,
          targetFunction: contentRule.data.target_func,
        });
      }

      if (props.type === SelectedModalEnum.action && actionRule?.data) {
        const rule = actionRule?.data?.rule_json[0];
        const jsonLogic = await backendReplaceAllObjectKeys(rule.when);
        setQuery(parseJsonLogic(jsonLogic as RQBJsonLogic));
        setDeliver({
          // deliver_time: actionRule?.data.deliver_time || 0,
          deliver_time: Number(
            actionRule?.data.display_unit === "day"
              ? actionRule?.data.deliver_time / 24
              : actionRule?.data.deliver_time
          ),
          deliver_unit: actionRule?.data.deliver_unit || "minute",
          display_unit: actionRule?.data.display_unit || "minute",
        });
        setRuleName(rule.name.replaceAll("_", " "));
        setRuleDescription(rule.desc);
        setRule(actionRule?.data);
      }

      if (props.type === SelectedModalEnum.state && stateRule?.data) {
        const rule = stateRule?.data?.rule_json[0];
        const jsonLogic = await backendReplaceAllObjectKeys(rule.when);
        setQuery(parseJsonLogic(jsonLogic as RQBJsonLogic));
        // for the states we do not have deliver_time and deliver_unit

        setRuleName(rule.name.replaceAll("_", " "));
        setRuleDescription(rule.desc);
        setRule(stateRule?.data);
      }
    };

    processObj();
  }, [actionRule, contentRule, props.type, stateRule]);

  //  @ts-ignore
  const fields: Field[] = useMemo(() => {
    return operands?.data?.map((op) => {
      return {
        name: op.value,
        label: op.display,
        valueEditorType: op.data_type === "bool" ? "checkbox" : "text",
        inputType: op.display.includes("Date") ? "date" : "text",
        operators: [
          { name: "=", label: "=" },
          { name: "!=", label: "!=" },
        ],
      };
    });
  }, [operands?.data]);

  // wanna extract list of the fields that have date inside of them
  const datesFields = useMemo(() => {
    if (fields) {
      return fields?.filter((field) => field.name.includes("Date"));
    }
    return [];
  }, [fields]);

  const updateAContentRule = useMutation(
    (ruleBody: any) => updateContentRule(props.ruleId, ruleBody),
    {
      onSuccess: () => {
        toast("Content Rule has been updated.", "success");
        props.modalHandle();
      },
      onError: (error: Error | any) => {
        toast(error.response.data.message, "error");
      },
    }
  );

  const updateAActionRule = useMutation(
    (ruleBody: ruleBody) => updateActionRule(props.ruleId, ruleBody),
    {
      onSuccess: () => {
        toast("Action Rule has been updated.", "success");
        props.modalHandle();
      },
      onError: (error: Error | any) => {
        toast(error.response.data.message, "error");
      },
    }
  );

  const updateAStateRule = useMutation(
    (ruleBody: ruleBody) => updateStateRule(props.ruleId, ruleBody),
    {
      onSuccess: () => {
        toast("State Rule has been updated.", "success");
        props.modalHandle();
      },
      onError: (error: Error | any) => {
        toast(error.response.data.message, "error");
      },
    }
  );

  const updateRule = async () => {
    if (ruleName === "") {
      toast("Rule name should not be empty", "error");
      return;
    }

    const deliver_time =
      deliver.deliver_unit === "day"
        ? 24 * deliver.deliver_time
        : deliver.deliver_time || 0;
    const deliver_unit =
      deliver.deliver_unit === "day"
        ? "hour"
        : deliver.deliver_unit || "minute";
    const display_unit = deliver.display_unit || "minute";

    const formattedQuery = await formatterQuery(query);
    const preparedForBackend = await backendReplaceAllObjectKeys(
      formattedQuery
    );

    const updatedRule = { ...rule };
    updatedRule.deliver_time = deliver_time;
    updatedRule.deliver_unit = deliver_unit;
    updatedRule.display_unit = display_unit;

    // if the type of the rule is content then we need to update the target_field and target_function
    if (props.type === SelectedModalEnum.content) {
      updatedRule.target_func = targetRules.targetFunction;
      updatedRule.target_field = targetRules.targetField.replace("OP.", "");
      updatedRule.is_scheduled_delay = false;
    }

    if (
      (targetRules.targetField !== "" || targetRules.targetFunction !== "") &&
      deliver_time <= 0
    ) {
      toast("Deliver time cannot be empty", "error");
      return;
    }

    updatedRule.rule_json = {
      when: preparedForBackend,
      desc: ruleDescription,
      name: renameRuleName(ruleName),
    };

    if (props.type === SelectedModalEnum.content) {
      updateAContentRule.mutateAsync({ ...updatedRule });
    }

    if (props.type === SelectedModalEnum.action) {
      updateAActionRule.mutateAsync({ ...updatedRule });
    }

    if (props.type === SelectedModalEnum.state) {
      // remove deliver_time and deliver_unit from updatedRule
      delete updatedRule.deliver_time;
      delete updatedRule.deliver_unit;
      updateAStateRule.mutateAsync({ ...updatedRule });
    }
  };

  return (
    <div className="relative z-50 flex flex-col min-w-[75%] max-h-[98%] py-6 overflow-auto bg-white rounded-md shadow-md text-textDark">
      <div className="flex justify-between px-10">
        <div className="flex flex-col mb-1">
          <div>
            <h2 className="mb-1 text-2xl font-normal capitalize text-textDark">
              Edit {props.type} Rule
            </h2>
          </div>
        </div>
        <button
          type="button"
          onClick={props.modalHandle}
          className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <CloseIcon className="w-4 h-4" />
          <span className="sr-only">Close menu</span>
        </button>
      </div>
      <hr className="w-full h-px my-5 border-1 border-borderGray dark:border-textDark" />
      <div className="flex flex-col items-center justify-between w-full px-10">
        <div className={`p-4 w-full`}>
          <p className="mb-2 text-sm text-textGray">Rule details</p>
          <div className="flex flex-col gap-1 pr-10">
            <div className="flex items-center ">
              <span className="text-sm w-60">Rule name</span>
              <AppInput
                isFull
                type="text"
                placeholder={""}
                value={ruleName}
                name="name"
                onChange={(e) => {
                  setRuleName(e.target.value);
                }}
                label=""
                extendClass="my-2"
              />
            </div>

            <div className="flex items-center ">
              <span className="text-sm w-60">Rule description</span>
              <AppTextArea
                placeholder={""}
                rows={4}
                value={ruleDescription}
                name="description"
                onChange={(e) => {
                  setRuleDescription(e.target.value);
                }}
                isFull
              />
            </div>
          </div>
        </div>

        <div className="w-[90%]">
          <QueryBuilderContainer
            fields={fields}
            query={query}
            setQuery={(q) => setQuery(q)}
          />
        </div>
        {props.type === SelectedModalEnum.content ||
        props.type === SelectedModalEnum.action ? (
          <div className="flex items-center justify-start w-full gap-2 px-1 py-4">
            <span className="text-sm text-textDark">Deliver this</span>
            <div className="flex items-center gap-2 w-44">
              <AppInput
                type="number"
                isFull
                label=" "
                id="Value"
                title=" "
                tabIndex={-1}
                min={0}
                placeholder="Value"
                name="Value"
                required
                onChange={(e) => {
                  setDeliver((prev) => {
                    return {
                      ...prev,
                      deliver_time: parseInt(e.target.value),
                    };
                  });
                }}
                // value={deliver.deliver_time.toString()}
                value={
                  deliver.display_unit === "day"
                    ? String(deliver.deliver_time)
                    : deliver.deliver_time.toString()
                }
              />
            </div>
            <div className="flex items-center justify-center gap-2 -mt-.5 w-44">
              <AppDropDown
                extendClass="mt-1.5"
                isFull
                label=""
                name="time"
                options={[
                  { label: "Minute", value: "minute" },
                  { label: "Hour", value: "hour" },
                  { label: "Days", value: "day" },
                ]}
                value={deliver.display_unit}
                onChange={(e) => {
                  setDeliver((prev) => {
                    return {
                      ...prev,
                      deliver_unit: e.target.value,
                      display_unit: e.target.value,
                    };
                  });
                }}
              />
            </div>
            {/* <span className="text-sm text-textDark">aft</span>  */}
          </div>
        ) : null}

        {props.type === SelectedModalEnum.content && (
          <div className="flex justify-start w-full gap-2 px-1 py-4">
            <div className="flex w-56 gap-2">
              <AppDropDown
                extendClass="mt-1.5"
                isFull
                label="Target Field"
                name="targetField"
                options={
                  datesFields &&
                  datesFields?.map((field) => {
                    return {
                      label: field.label,
                      value: field.name,
                    };
                  })
                }
                value={targetRules.targetField}
                onChange={(e) => {
                  setTargetRules((prev) => {
                    return {
                      ...prev,
                      targetField: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="flex gap-2 w-44">
              <AppDropDown
                extendClass="mt-1.5"
                isFull
                label="Target Function"
                name="targetFunction"
                options={[
                  { label: "ADD", value: "ADD" },
                  { label: "SUB", value: "SUB" },
                ]}
                value={targetRules.targetFunction}
                onChange={(e) => {
                  setTargetRules((prev) => {
                    return {
                      ...prev,
                      targetFunction: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
        )}

        <div className="self-start p-4">
          <AppButton
            variant="primary"
            type="submit"
            extendClass="px-3"
            onClick={updateRule}
          >
            <div className="flex items-center justify-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
              >
                <path
                  fill="currentColor"
                  d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"
                ></path>
              </svg>
              Update {props.type} rule
            </div>
          </AppButton>
        </div>
      </div>
    </div>
  );
};
