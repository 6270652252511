import React, { FC, useContext, useState } from "react";
import { Switch } from "@headlessui/react";
import { Tooltip } from "flowbite-react";
import { AppButton, InfoIcon } from "../../../../../Theme";
import NestedContentListItem from "../../../../Content/NestedContentListItem";
import { TextField } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { jobProfile, organization_T } from "../../../../../Common/Types";
import { updateOrganization } from "../../../../../apiQuery/Organization/Organization.apis";
import { toast } from "../../../../../utils/Toast";
import { uploadFile } from "../../../../../apiQuery/Common/Upload.api";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../../../Context/UserContext/UserContext";
import { store } from "../../../../../GlobalState/store";
import Button from "../../../../General/Button";
import { AiFillDelete, AiFillFile } from "react-icons/ai";

type props = {
  organization?: organization_T
}

const WorkdayTab: FC<props> = ({ organization }) => {
  const queryClient = useQueryClient()
  const [imageSrc, setImageSrc] = useState<string>()
  const [file, setFile] = useState<any>(undefined)
  const { t } = useTranslation();

  useContext(store);
  // const organization_id = useMemo(() => state.organization_id, [state.organization_id])
  const { currentOrganizationID } = useContext(UserContext)
  const organization_id = currentOrganizationID()

  const { handleSubmit, watch, setValue } = useForm<organization_T>({
    defaultValues: { ...organization }
  });

  // const items = watch("data.job_profiles") || []
  const items = organization?.data.job_profiles || []

  const [input, setInput] = useState('')
  const [baseColor, setBaseColor] = useState(organization?.data.base_color || '#000')
  const [baseBackgroundColor, setBaseBackgroundColor] = useState(organization?.data.base_background_color || '');


  const submitItem = () => {
    if (input === '') return
    setValue('data.job_profiles', [...items, input]);
    setInput('')
  }

  const handleRemove = (ind: number) => setValue('data.job_profiles', items.filter((_, index) => ind !== index))



  const handleUpdate = useMutation((jdata: jobProfile) => updateOrganization(organization_id, { ...jdata }), {
    onSuccess: async () => {
      setImageSrc(undefined)
      setFile(undefined)
      await queryClient.invalidateQueries(["organization", organization_id])
      // dispatch(setSnackbar({ isOpen: true, type: 'success', message: 'Organization has been updated' }))
      toast(t('organization.updated_organization'), 'success')
    },
    onError: (error: null | Error) => {
      const message = error?.message || `${t('organization.failed_to_update_organization')}`
      // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
      toast(message, 'error')
    }
  })
  const handleUpload = useMutation((formData: any) => uploadFile(formData), {})

  const onSubmit: SubmitHandler<organization_T> = async data => {

    // let logo = data.data.logo || ""
    let logo = organization?.data?.logo || "";

    if (imageSrc) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", file);
      const { link } = await handleUpload.mutateAsync(formData)
      logo = link
    }


    await handleUpdate.mutateAsync({
      // job_profiles: data.data.job_profiles,
      job_profiles: items,
      logo: logo,
      base_color: baseColor,
      base_background_color: baseBackgroundColor
    })
  }



  const [enabled, setEnabled] = useState(false);
  return (
    <div className="flex flex-col gap-5 mb-12">
      <div className="mb-3">
        <h2 className="mb-1 text-2xl font-normal text-textDark">Workday Integration</h2>
        <p className="text-sm text-textDark">
          Manage the Workday settings for your organization.
        </p>
      </div>
      <hr className="h-px border-1 border-borderGray dark:border-textDark" />
      <div>
        <h2 className="mt-2 mb-2 text-xl">Credentials</h2>
        <p className="mt-2 mb-6 text-sm text-textDark">Manage your Workday credentials which will be used to connect to your Workday instance.</p>
        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">ClientID</p>
          <input
            value={organization?.data?.setting?.wd_username || ""}
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"

            style={{ color: 'grey' }}
          />
        </div>
        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">Client Secret</p>
          <input
            value={organization?.data?.setting?.wd_password || ""}
            type="password"
            className="w-8/12 p-1 border rounded-md border-borderGray"

            style={{ color: 'grey' }}
          />
        </div>
        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">Refresh Token (General)</p>
          <input
            value={organization?.data?.setting?.refresh_token || ""}
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"

            style={{ color: 'grey' }}
          />
        </div>
        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">Refresh Token (RaaS Report)</p>
          <input
            type="text"
            value={organization?.data?.setting?.raas_refresh || ""}
            className="w-8/12 p-1 border rounded-md border-borderGray"
            style={{ color: 'grey' }}
          />
        </div>
      </div>
      <hr className="h-px border-1 border-borderGray dark:border-textDark" />
      <div>
        <h2 className="mt-2 mb-2 text-xl">Endpoints</h2>
        <p className="mt-2 mb-6 text-sm text-textDark">Manage your Workday Endpoints which will be used to query for new Candidate and Employee data.</p>

        <div className="mb-5">
          <span className="mb-1 text-base text-textDark">
            GetRaaSReport URL
          </span>
          <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>
        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetRaaSHireDate URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>

        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetCandidates URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>
        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetJobApplicationAdditionalData URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>

        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetAssessCandidate URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>

        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetApplicant URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>

        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetBackgroundCheck URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>
        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetWorkers URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>

        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetWorkerProfile URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>

        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetEvergreenRequisitions URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>
        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetJobRequisitions URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>

        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetPositions URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>

        <div className="mb-5">
          <p className="mb-1 text-base text-textDark">
            GetOrganization URL
            <Tooltip placement="right" style="light" animation={false} content={"Tooltip for info on this URL"}><InfoIcon /></Tooltip>
          </p>
          <input
            type="text"
            className="w-8/12 p-1 border rounded-md border-borderGray"
          />
        </div>
      </div>

      <hr className="h-px border-1 border-borderGray dark:border-textDark" />

      <div>


        <div className="pb-4 space-y-2">
          <span>{t('Job_profile')}</span>
          <p className="mt-2 mb-6 text-sm text-textDark">Manage your list of job families which will be included when your Workday sequences are executed.</p>
          <div className="flex py-1 space-x-10">
            <TextField label="Item" value={input} onChange={(e) => setInput(e.target.value)}
              type="text"
              className="w-full p-3 leading-tight text-gray-600 bg-white rounded-md appearance-none focus:outline-none" />

            <Button onClick={submitItem} label={
              <p className="text-white">{"Create Item"}</p>
            } extraClasses="w-1/3 px-3 py-3" />

          </div>
          <div>

            {
              items?.map((item, index) => <div key={index}
                className="flex items-center w-full px-2 space-x-2 border border-gray-500">
                <NestedContentListItem label={item} />
                <AiFillDelete onClick={() => handleRemove(index)} className="text-2xl cursor-pointer" />
              </div>)
            }
          </div>
        </div>
        <AppButton disabled={handleUpdate.isLoading || handleUpload.isLoading}
          variant="primary" type="submit" size="lg" >{t('Update')}</AppButton>
      </div>

      <hr className="h-px border-1 border-borderGray dark:border-textDark" />

      <div>
        <h2 className="mb-3 text-xl font-normal text-textDark">
          API Endpoints
        </h2>
        <div className="px-4">
          <div className="flex flex-col gap-2 p-4 border rounded-md border-borderGray">
            <p className="text-xs text-textDark">
              Set which API Endpoints should be active when pulling data from
              your Workday instance.
            </p>
            <div className="flex flex-col mb-2">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="getCandidate_API"
                  className="mb-2 text-xs text-textDark"
                >
                  GetCandidate API
                </label>
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={`${enabled ? "bg-greenColor" : "bg-teal-700"}
          relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">API Endpoints</span>
                  <span
                    aria-hidden="true"
                    className={`${enabled ? "translate-x-3" : "translate-x-0"}
            pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
              <input
                type="text"
                className="w-8/12 p-1 border rounded-md border-borderGray"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkdayTab;
