import { FormEvent, memo, useContext, useEffect, useState } from "react";
import { AppButton, CloseIcon } from "../../../../../../Theme";
import AppInput from "../../../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../../../Shared/AppDropDown/AppDropDown";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { useMutation, useQuery } from "react-query";
import { toast } from "../../../../../../utils/Toast"
import { ILink, IOrgLInksResponse } from "../../../../../../Types/Organization/Organization";
import {
  CreateOrgLink
} from "../../../../../../apiQuery/Organization/Organization.apis";
import UseUploadFile from "../../../../../../Hook/UseUploadFile";
import UseUpload from "../../../../../../Hook/UseUpload";
import { baseURL } from "../../../../../../apiQuery";
import UploadProgress from "../../../../../General/UploadProgress";
type props = {
  modalHandle: () => void;
  RefetchView: () => void;

};

type NewLinkFields = {
  thumbnail: string;
  title: string;
  description: string;
  url: string;
  link_seen_by: string;
};

const NewLinkModal = memo(({ modalHandle, RefetchView }: props) => {
  const { t } = useTranslation();


  const [state, setState] = useState({ thumbnail: "", title: "", description: "", link_seen_by: "Both", url: "" });



  //=====================  Upload Cover  ========================
  const [imageSrc, file, render, name] = UseUploadFile('Link')
  const { uploadForm, progress, isLoading } = UseUpload(
    `${baseURL}upload`
  );
  const [uploadState, setUploadState] = useState({
    file,
    name,
  })

  useEffect(() => {
    if (name && file) {
      setUploadState({
        file,
        name,
      })
    }
  }, [name, file])

  // ==================================================================



  const createStateMutation = useMutation(
    (newStateData: NewLinkFields) =>
      CreateOrgLink({
        ...newStateData,
      }),
    {
      onSuccess: async () => {
        RefetchView();
        modalHandle();
        toast("New Link successfully created.", "success");
      },
    }
  );

  const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();


    // link of the uploaded image is here.
    let _cover_imageLink = ''

    if (uploadState.file !== undefined) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", uploadState.file);
      const { link } = await uploadForm(formData)
      _cover_imageLink = link
    }

    createStateMutation.mutateAsync({
      ...state,
      thumbnail: _cover_imageLink
    });

  };

  return (
    <div className="absolute z-[999] flex w-[60%] m-5 h-full  overflow-auto bg-gray-50 border border-borderGray rounded-md shadow-lg  text-textDark Link-new-modal">
      <div className="flex flex-col w-full p-8">
        <div className="flex justify-between">
          <div className="flex flex-col gap-5 mb-2">
            <div>
              <h2 className="mb-1 text-xl font-normal text-textDark">
                New Link
              </h2>
              <p className="text-sm text-textDark">
                Create a new Link .
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={modalHandle}
            className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <CloseIcon className="w-4 h-4" />
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        <hr className="w-full h-px my-5 border-1 border-borderGray dark:border-textDark" />
        <form onSubmit={submitFormHandler} className="w-full">
          <AppInput
            isFull
            label="Title"
            id="Title"
            title="Title"
            tabIndex={-1}
            autoFocus
            placeholder="Link Title"
            name="title"
            required
            onChange={(e) => {
              setState((prev) => ({ ...prev, title: e.target.value }));
            }}
            value={state.title}
          />
          <AppInput
            isFull
            label="URL"
            id="URL"
            title="URL"
            tabIndex={-1}
            autoFocus
            placeholder="URL"
            name="URL"
            required
            onChange={(e) => {
              setState((prev) => ({ ...prev, url: e.target.value }));
            }}
            value={state.url}
          />

          <AppDropDown
            label={"link seen by"}
            defaultValue={"Both"}
            options={
              [{ value: "Candidate", label: "Candidate" }, { value: "Employee", label: "Employee" }, { value: "Both", label: "Both" }]
            }
            value={state.link_seen_by.toString()}
            name="link_seen_by"
            placeholder={`link seen by`}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                link_seen_by: e.target.value,
              }));
            }}
          />
          <AppTextArea
            placeholder={"Description"}
            rows={4}
            name="description"
            onChange={(e) => {
              setState((prev) => ({ ...prev, description: e.target.value }));
            }}
            label="Link Description"
            id="LinkDescription"
            value={state.description || ""}
          />

          {render}
          {uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}
          {isLoading ? <UploadProgress progress={progress} /> : null}


          <div className="mt-8">
            <AppButton variant="primary" type="submit">{`${t(
              "Save Changes"
            )}`}</AppButton>
          </div>
        </form>
      </div>
    </div>
  );
});

export default NewLinkModal;
