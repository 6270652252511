import React, {FC, InputHTMLAttributes} from "react";
import {TextField} from "@mui/material";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    errors?: any;
    register?: any;
    extraClasses?: string;
    onChange?: (event: any) => void;
}

const Input: FC<InputProps> = ({
                                   register,
                                   name,
                                   errors,
                                   extraClasses,
                                   onChange,
                                   ...rest
                               }) => {
    return (
        <div>
            <TextField label={rest.placeholder} error={!!errors[name]}
                       aria-invalid={!!errors[name] ? "true" : "false"}
                       {...register()}
                       className={`appearance-none rounded-md text-gray-600 w-full bg-white leading-tight focus:outline-none p-3 ${extraClasses}`}
                       {...rest}
                       placeholder=""
            />
            {
                errors[name]?.type === "required" && <p className="text-red-500">{errors[name]?.message}</p>
            }
            {
                errors[name]?.type === "maxLength" && <p className="text-red-500">Max length exceeded</p>
            }
            {
                errors[name]?.type === "minLength" && <p className="text-red-500">Min length is 5</p>
            }
        </div>
    );
};

export default Input;