import React from 'react';
import {Box, Skeleton} from "@mui/material";

const SkeletonLoader = () => {
    return (
        <div className="grid grid-cols-2 gap-5">
            {( Array.from(new Array(10))).map((_, index) => (
                <Box key={index} >
                        <Skeleton variant="rectangular" height={200} />
                        <Box>
                            <Skeleton />
                            <Skeleton width="60%" />
                        </Box>

                </Box>
            ))}
        </div>
    );
};

export default SkeletonLoader;