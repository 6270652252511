import React, { FC, memo } from 'react';
import { MdClose } from "react-icons/md";
import Button from "../../General/Button";

type state_props = {
    title: string,
    subTitle?: string,
    type: string
    description?: string,
}

const PreviewActionModal: FC<state_props> = memo(({ title, type, description, subTitle }) => {

    return (
        <div className="w-2/5 h-fit overflow-auto shadow-md rounded border max-w-lg min-w-[28rem] p-6 bg-white">

            {type === "sms" ?
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            <div className="py-4 text-right imessage w-fit">
                                <p className="from-me text-white bg-[#20DF26] w-full rounded-xl relative p-2">{title}</p>
                            </div>
                        </div>
                    </div>
                </div> : null
            }

            {type === "email" ?
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            <span className="text-2xl font-bold text-black">{title}</span>
                            <span className="text-xl text-gray-500">{subTitle}</span>
                        </div>
                    </div>

                    <div className="py-5 text-lg font-medium text-gray-700" dangerouslySetInnerHTML={{ __html: description || '' }} />
                </div> : null
            }

            {type === "notification" ?
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            <span className="text-2xl font-bold text-black">{title}</span>
                            <span className="text-xl text-gray-500">{subTitle}</span>
                        </div>
                    </div>
                    <p className="py-5 text-lg font-medium text-gray-700">{description}</p>
                    <Button label={"GOT IT"} extraClasses="text-white bg-[#3C78B4]" />
                </div> : null
            }

            {type === "al_notification" ?
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            <span className="text-2xl font-bold text-black">{title}</span>
                            <span className="text-xl text-gray-500">{title}</span>
                        </div>
                    </div>
                    <p className="py-5 text-lg font-medium text-gray-700">{description}</p>
                    <Button label={"GOT IT"} extraClasses="text-white bg-[#3C78B4]" />
                </div> : null
            }
            {/*<div className="flex flex-col">*/}
            {/*    <div className="flex justify-between">*/}
            {/*        <div className="flex flex-col">*/}

            {/*            {*/}
            {/*                type === "sms" ?*/}
            {/*                    <div className="py-4 text-right imessage w-fit">*/}
            {/*                        <p className="from-me text-white bg-[#20DF26] w-full rounded-xl relative p-2">{title}</p>*/}
            {/*                    </div>*/}
            {/*                    : <span className="text-2xl font-bold text-black">{title}</span>*/}
            {/*            }*/}
            {/*            <span className="text-xl text-gray-500">{subTitle}</span>*/}
            {/*        </div>*/}
            {/*        <MdClose className="text-3xl text-black"/>*/}
            {/*    </div>*/}

            {/*    {*/}
            {/*        type === "notification" && <p className="py-5 text-lg font-medium text-gray-700">{description}</p>*/}
            {/*    }*/}

            {/*    {*/}
            {/*        type === "email" && <div className="py-5 text-lg font-medium text-gray-700"*/}
            {/*                                 dangerouslySetInnerHTML={{__html: description || ''}}/>*/}
            {/*    }*/}

            {/*    <Button label={"GOT IT"} extraClasses="text-white bg-[#3C78B4]"/>*/}
            {/*</div>*/}

        </div>
    );
});

export default PreviewActionModal;