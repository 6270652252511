import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { deleteContentTemplateById, getContentTemplateFilterByContentTypePagination } from "../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { AppButton, AppDivider, ArrowIcon, CommandBar, DeleteIcon, Loader, PathwayIcon, PopupModal, THead, TabBar, Table } from "../../Theme";
import { getLangaugeLabel } from "../../utils/Languages";
import { Drawer } from "../../Theme/Drawer";
import { useNavigate } from "react-router-dom";
import { toast } from "../../utils/Toast";
import { Favorite } from "../Container";
import { TabContext } from "../../Context/TabContext/TabContext";
import AppSearch from "../Shared/AppSearch/AppSearch";
import { AppLoader } from "../Shared/AppLoader";
import { useInView } from "react-intersection-observer";
import { debounce } from "lodash";

export interface IWorkerResponse {
  data: {
    data:any[]
  }
  error: boolean
  message: string
  paginate: Paginate
  status: number
}

export interface Paginate {
  limit: number
  order: string
  next: string
  previous: string
}

//* Start function 
const ContentTemplatePage: FC = () => {

  //! States 
  const { globalTabs, updateTab } = useContext(TabContext);

  //! Hooks 
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const slug = params.templateSlug || "";


  //! Getting filter key from params
  const paramsValue = params?.id?.replace('filterParam=', '') || "";
  const [searchValue, setSearchValue] = useState<any>();

  useEffect(()=> {
  if (paramsValue) {
    setSearchValue(paramsValue === "allData" ? searchValue : paramsValue);
  }
  }, [paramsValue])


//! onSearchChange defined - input callback function | debounce call
const onSearchChange = (event: { target: { value: string; }; }) => {
  debounceFunction(event?.target?.value?.toLocaleLowerCase())
}
  //! Debounce function - Debounce to prevent frequent API requests 
const debounceFunction= useMemo(()=>{
  return debounce((item:any) => {
    setSearchValue(item) }, 700)
},[])

  //! API - Fetch data from API using debouncedFilterKey
  const fetchDATA = ({ pageParam = "" }) =>
  getContentTemplateFilterByContentTypePagination(slug, pageParam,searchValue);

  // @ts-ignore
  const { data, isLoading, refetch, isError, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery<any, Error>(['all-content-templates', slug, searchValue],fetchDATA, 
    {
    getNextPageParam: (lastPage, all) => {
      // console.log('lastPage',lastPage.data.paginate)
      if (lastPage && lastPage.data.data) {
        if (lastPage.data.paginate.next === "") return undefined;
        return lastPage.data.paginate.next;
      }
    },
    }
  );

  useEffect(() => {
    if (location.state && location.state.deleted) {
      refetch();
      queryClient.invalidateQueries('getContentTemplateFilterBySlug'); // O
    }
    refetch()
  }, [location, queryClient]);

  // ==========================- HOOKS ==========================================

  const tableRef = useRef<HTMLTableSectionElement | null>(null);


  // ============================ DRAWER ========================================

  const [selectedContentTemplate, setSelectedContentTemplate] = useState<any>();

  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = useCallback(
    () => setShowDrawer(false),
    []
  );

  const showDrawerHandler = useCallback(
    (contentTemplate: any) => {
      if (!showDrawer)
        setShowDrawer(true);
      setSelectedContentTemplate(contentTemplate);
    },
    [showDrawer]
  );



  // ============================ COMMANDBAR ====================================

  const [showCommandBar, setShowCommandBar] = useState(false);
  const [selectedContentTemplates, setSelectedContentTemplates] = useState<number[]>([]);

  useEffect(() => {
    if (selectedContentTemplates.length > 0) {
      setShowCommandBar(true);
    } else {
      setShowCommandBar(false);
    }
  }, [selectedContentTemplates.length]);


  // ========================== POPUP Modal  ===============================

  const [showPopupDeleteCotentTemplate, setShowPopupDeleteCotentTemplate] = useState(false);
  const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);



  const confirmDeleteContentTemplate = async () => {
    if (selectedContentTemplates.length <= 0) return;
    // close the popup
    setShowPopupDeleteCotentTemplate(false)
    setShowPopupDeleteConfirmation(false)
    try {
      // Map selected workers to an array of promises that delete them
      const deletePromises = selectedContentTemplates.map((worker) =>
        deleteContentTemplateById(worker),
      );

      // Wait for all delete promises to settle
      const results = await Promise.allSettled(deletePromises);
     
      const successPromises = results.filter(
        (result) => result.status === "fulfilled"
      );

      if(successPromises.length > 0)  {
        toast("Content Template deleted successfully", "success")
      }
     
      // Check for any failed promises and display error messages as toasts
      const failedPromises = results.filter(
      (result) => result.status === "rejected"
      );
      failedPromises.forEach((promise) => {
        // @ts-ignore
        const apiErrorMessage = promise?.reason?.response?.data.message || "Error deleting content template"
        toast(apiErrorMessage, "error")
      }
      
      );
      setSelectedContentTemplates([]);
      refetch()
    } catch (error) { }
  };

  // =============================  HANDLERS =====================================

  const chooseContentTemplateCheckboxHandler = useCallback((item: any) => {


    if (selectedContentTemplates.includes(item.id)) {
      setSelectedContentTemplates(
        selectedContentTemplates.filter(
          (content) => content !== item.id
        )
      );
    } else {
      setSelectedContentTemplates([
        ...selectedContentTemplates,
        item.id,
      ]);
    }
    // if the selectedPathways is empty, hide the commandbar
    if (selectedContentTemplates.length === 0) {
      setShowCommandBar(false);
    } else {
      setShowCommandBar(true);
    }
  }, [selectedContentTemplates])
  // ===================================================================
  const navigateToViewContentTemplate = (selectedContentTemplate: any) => {
    const templateId = selectedContentTemplate.id
    // Update tabs
    const title = selectedContentTemplate[slug]?.[0].title
    const newTab = {
      index: globalTabs.tabs.activeTab.index,
      path: `/content-templates/${slug}/view/${templateId}`,
      name: `Content - ${title}`,
    }
    updateTab(newTab)

    // 
    navigate(`/content-templates/${slug}/view/${templateId}`)
  }

  const navigateToNewContentTemplate = () => {
    navigate(`/content-templates/${slug}/new`)
  }


  const tableContentRef = useRef<HTMLTableSectionElement | null>(null);


  useEffect(() => {
		// get table client.y
		const tableElement = tableContentRef.current;
		const tableClientY = tableElement?.getBoundingClientRect().y;
		const tableHeight = tableElement?.clientHeight;
		const windowHeight = window.innerHeight;
		// check if table height is more than window height	  

		if (!hasNextPage) {
			return
		}

		if (
			tableHeight &&
			tableClientY &&
			windowHeight &&
			data &&
			data?.pages.length > 0
		) {
			if (tableClientY + tableHeight < windowHeight) {
				fetchNextPage();
			}

		}
	}, [data, fetchNextPage, hasNextPage]);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);


  if (isError) return <p>Something went wrong</p>;

  return (
    <Fragment>
      <section className="relative">
        <TabBar />
        <div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
          <AppButton variant='primary' size='xs' type='button' onClick={navigateToNewContentTemplate}>
            <div className="flex items-center gap-1">
              <span className="text-xs capitalize">New {slug.replaceAll('_', ' ')} Template</span>
              <span>+</span>
            </div>
          </AppButton>
          <AppSearch
            className={"search-box  block w-full h-8 border-transparent bg-primary p-2.5 text-sm text-textDark outline-none focus:border-transparent focus:ring-0"}
            placeHolder={"Search templates"}
            onChangeHandler={onSearchChange}
            defaultValue={searchValue ?? paramsValue==="allData" ? "":paramsValue}
          />
        </div>
        {/* breadcrumb*/}
        <div className="flex flex-col items-start justify-between pt-5 pl-5 shadow-sm ">
          <span className="pb-1 mb-4 text-sm text-grayColor"> <Link to="/content-templates">Content</Link> &nbsp;&nbsp;/&nbsp;&nbsp;{" "}
            <Link
              to={`/content-templates/${params.templateSlug}`}
              className="capitalize"
            >
              {params.templateSlug?.replaceAll('_', ' ')}
            </Link></span>
        </div>
        <div className="pb-10">
          <div className="w-[97%] py-5 m-auto space-y-5">
            <div className="flex items-center justify-center">
              <Loader isLoading={isLoading} size={35} />
            </div>
            {/* =============== */}
            <Table outsideRef={tableRef}>
              <THead>
                <tr>
                  <th scope="col" className="py-3 font-medium px-14">
                    Content Template
                  </th>
                  {/* <th scope="col" className="py-3 font-medium">
            Content Type
          </th> */}
                  <th scope="col" className="py-3 font-medium">
                    References
                  </th>
                  <th scope="col" className="py-3 font-medium">
                    Languages
                  </th>
                </tr>
              </THead>
              <tbody>
              { data && data?.pages?.map?.((infinitePage, idx) => {
                
                  return (
                    <Fragment key={idx}>

                       {infinitePage?.data?.data?.map((item:any, index: number) => { 
                            const englishTemplate = item[item?.content_type].filter((item:any) => item.language === "en")[0];
                            // console.log('englishTemplate',englishTemplate.id)
                            return (
                              <tr
                                className="transition-all delay-75 bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer "
                                key={englishTemplate?.id}
                              >
                                <th
                                  scope="row"
                                  className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                                >
                                  <div className="flex items-center justify-start">
                                    <input
                                      type="checkbox"
                                      onClick={() => chooseContentTemplateCheckboxHandler(item)}
                                      className="checkbox-worker custom__checkbox"
                                    />
                                    <div className="flex flex-col items-start justify-center p-2">
                                      <p
                                        onClick={() => showDrawerHandler(item)}
                                        className="mb-1 ml-1 text-lg whitespace-nowrap text-textDark dark:text-white">
                                        {/* {item[item.content_type]?.[0]?.title || ''} */}
                                        {/* This is only showing the English Title from template */}
                                        {englishTemplate?.title || ''}
                                      </p>
                                    </div>
                                  </div>
                                </th>
                                <td
                                  onClick={() => showDrawerHandler(item)}
                                  className="py-3 pr-6 font-medium text-textDark whitespace-nowrap dark:text-white">
                                  <div className="flex items-center gap-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      aria-hidden="true"
                                      viewBox="0 0 9.879 9.638"
                                      className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                                    >
                                      <path
                                        id="icon_content"
                                        d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
                                        transform="translate(-4 -4)"
                                        fill="currentColor"
                                      />
                                    </svg>
                                    <span className="mx-1 text-sm text-textDark">
                                      {item?.linked_content_count}
                                    </span>
                                  </div>
                                </td>
                                <td
                                  onClick={() => showDrawerHandler(item)}
                                  className="py-3 pr-6 font-medium text-textDark whitespace-nowrap dark:text-white ">
                                  <div className="flex items-center gap-2">
                                    {
                                      item[item?.content_type]?.sort((a:any, b:any) => {
                                        // Define a language order
                                        const languageOrder:any = { en: 0, es: 1, fr: 2 };
                                        // Get the language order for each object
                                        const orderA = languageOrder[a.language] || Infinity;
                                        const orderB = languageOrder[b.language] || Infinity;
                                        // Compare the language order
                                        return orderB - orderA;
                                        }).map(
                                        (item: any, idx: any, array: any) => (
                                          <span
                                            key={idx}
                                            className="text-sm text-textDark"
                                          >
                                            {getLangaugeLabel(item?.language)}
                                            {array.length > idx + 1 ? "," : null}
                                          </span>
                                        )
                                      ) || []
                                    }
                                  </div>
                                </td>
                              </tr>
                              );
                            })}
                    </Fragment>
                  );
                })}
                <tr ref={ref}></tr>
              </tbody>
            </Table>
            {/* =============== */}
          </div>
          <div className="flex items-center justify-center">
            {isFetchingNextPage && <AppLoader isLoading size={25} />}
          </div>
        </div>
      </section>

      {/* =============== DRAWER ==================== */}

      <Drawer outsideRef={tableRef} setOpen={closeDrawer} isOpen={showDrawer} title="" isFull classNames="overflow-y-scroll">
        {selectedContentTemplate ? (
          <div className="flex flex-col justify-between h-full overflow-y-scroll">
            <div className="flex flex-col gap-2 p-4">
              {/* ========== */}
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 7.573 8.414"
                  className="flex-shrink-0 w-10 h-10 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path
                    id="icon_pathways"
                    d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                    transform="translate(-6 -4)"
                    fill="currentColor"
                  />
                </svg>
                <div className="flex flex-col items-start justify-center p-2">
                  <p className="mb-2 text-lg whitespace-nowrap text-textDark dark:text-white">
                    {selectedContentTemplate[selectedContentTemplate.content_type][0].title}
                  </p>
                  <p className="flex items-center gap-1 text-xs whitespace-nowrap text-grayColor dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      viewBox="0 0 9.879 9.638"
                      className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                    >
                      <path
                        id="icon_content"
                        d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
                        transform="translate(-4 -4)"
                        fill="currentColor"
                      />
                    </svg>
                    <span className="inline text-xs"> {selectedContentTemplate.linked_content_count} References </span>
                  </p>
                </div>
                <div className="flex justify-end">
                  <div className="relative flex flex-col items-center self-center justify-center ">
                    <Favorite favorite_owner={"Content"} slug={selectedContentTemplate.id.toString()} />
                  </div>
                </div>
              </div>
              {/* ========== */}

              <div className="flex flex-col items-start justify-start gap-1 mb-2">
                <p className="text-sm text-textGray">
                  Content Template Description
                </p>
                <p className="text-sm text-textDark">
                  {selectedContentTemplate[selectedContentTemplate.content_type][0].description}
                </p>
              </div>
              {/* <hr className="h-px border-1 border-borderGray dark:border-textDark" /> */}
              <AppDivider />
              <div className="flex flex-col items-start justify-start gap-1 mb-2">
                <p className="text-sm text-textGray">
                  Content Template Details
                </p>
                <ul className="list-none">
                  <li className="flex gap-2 my-2">
                    <span className="w-20 text-sm text-textGray">Type</span>
                    <span className="text-sm capitalize text-textDark">
                      {selectedContentTemplate.content_type}
                    </span>
                  </li>
                  <li className="flex gap-2 my-2">
                    <span className="w-20 text-sm text-textGray">
                      References
                    </span>
                    <span className="text-sm text-textDark">
                      {selectedContentTemplate.linked_content_count}
                    </span>
                  </li>
                  <li className="flex gap-2 my-2">
                    <span className="w-20 text-sm text-textGray">Languages</span>

                    {selectedContentTemplate[selectedContentTemplate.content_type]
                      .map((item: any, idx: number, array: any) => (
                        <span
                          className="text-sm text-textDark"
                          key={idx}
                        >
                          {getLangaugeLabel(item.language)}
                          {array.length > idx + 1 ? "," : null}
                        </span>
                      )
                      )}
                  </li>

                  {/* <li className="flex gap-2 my-2">
                    <span className="w-20 text-sm text-textGray">
                      Content Slug
                    </span>
                    <span className="text-sm text-textDark">
                      {selectedContentTemplate.slug}
                    </span>
                  </li> */}
                </ul>
              </div>
              <hr className="h-px border-1 border-borderGray dark:border-textDark" />


              <div className="flex flex-col items-start justify-start gap-1 mb-2">
                <p className="text-sm text-textGray">
                  References
                </p>
                {selectedContentTemplate?.linked_content ? selectedContentTemplate?.linked_content
                  .map((item: any, idx: number, array: any) => {
                    return (
                      <>
                        <div className="flex items-center justify-start w-full gap-1 p-1 hover:bg-hoverLightBlue">
                          <PathwayIcon className="w-3 h-3 text-grayColor" />

                          <span
                            className="text-sm cursor-pointer text-textDark"
                            key={idx}
                            onClick={
                              () =>
                                navigate(
                                  `/pathways/${item.pathway_slug}`
                                )
                            }
                          >
                            {<span>{item.pathway_name}</span>}

                          </span>
                        </div>
                        <div className="flex items-center justify-start w-full gap-1 pl-5 hover:bg-hoverLightBlue">
                          <ArrowIcon className="w-3 h-3 text-grayColor" />

                          <span
                            className="text-sm cursor-pointer text-textDark"
                            key={idx}
                            onClick={
                              () =>
                                navigate(
                                  `/pathways/${item.pathway_slug}/state/${item.state_slug}`
                                )
                            }
                          >
                            {<span>{item.state_name}</span>}

                          </span>
                        </div>

                      </>

                    )
                  }
                  ) : null}
                <ul className="w-full list-none">
                  {/* {
            selectedContentTemplate.states.map((state, index) => {
              return (
                <li className="flex flex-col w-full gap-1 my-3" key={index}>
                  <p className="text-sm text-textDark">{state.name}</p>
                  <div className="flex items-center justify-between px-5 text-xs text-textDark">
                    <span className="flex items-center gap-1 text-xs text-textGray">
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 11.071 7.778"
                      >
                        <path
                          fill="currentColor"
                          d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
                          transform="translate(-1.9 -8.95)"
                        />
                      </svg>
                      {state.participant_count || 0} Workers</span>
                    <span className="flex items-center gap-1 text-xs text-textGray">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        viewBox="0 0 9.879 9.638"
                        className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                      >
                        <path
                          id="icon_content"
                          d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
                          transform="translate(-4 -4)"
                          fill="currentColor"
                        />
                      </svg>
                      {state.contents || 0} Contents</span>
                    <span className="flex items-center gap-1 text-xs text-textGray">
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 9.878 9.878"
                        className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                      >
                        <path
                          id="icon_actions"
                          d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
                          transform="translate(-2 -2)"
                          fill="currentColor"
                        />
                      </svg>
                      {state.actions || 0} Actions</span>
                  </div>
                </li>

              )
            })
          } */}
                </ul>
              </div>
              {/* <hr className="h-px border-1 border-borderGray dark:border-textDark" /> */}
              <AppDivider />
            </div>

            <div className="mb-6">
              <div
                onClick={() => navigateToViewContentTemplate(selectedContentTemplate)}
                className="flex items-center justify-center w-full py-6 bg-blueColor btn-drawer hover:cursor-pointer"
              >
                <span className="flex items-center justify-center gap-2 text-sm text-white">
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 7.573 8.414"
                    className="flex-shrink-0 w-3 h-3 text-white"
                  >
                    <path
                      id="icon_pathways"
                      d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                      transform="translate(-6 -4)"
                      fill="currentColor"
                    />
                  </svg>{" "}
                  View Content Template{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </Drawer>

      {/* =============== COMMANDBAR ==================== */}
      <CommandBar isOpen={showCommandBar} setOpen={() => setShowCommandBar(!showCommandBar)}>
        <span className="text-xs text-textGray">
          {selectedContentTemplates.length} Template{selectedContentTemplates.length > 1 ? "s" : ""}{" "}
          Selected
        </span>
        {
          selectedContentTemplates.length === 1 ?
            (<AppButton type="button" variant="primary" onClick={() => {
              // navigateToViewContentTemplate(selectedContentTemplate)
              navigate(`/content-templates/${slug}/view/${selectedContentTemplates[0]}`);

            }}>
              <div className="flex items-center gap-1 mx-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 96 960 960"
                  className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path
                    fill="currentColor"
                    d="M794 390L666 262l42-42q17-17 42.5-16.5T793 221l43 43q17 17 17 42t-17 42l-42 42zm-42 42L248 936H120V808l504-504 128 128z"></path>
                </svg>
              </div>
              Edit Content Template

            </AppButton>) : null
        }
        <AppButton
          type="button"
          variant="primary"
          onClick={() => setShowPopupDeleteConfirmation((prev) => !prev)}
        >
          <div className="flex items-center gap-1 mx-1">
            <DeleteIcon className="" />
            <span className="text-sm">Delete Content Template{selectedContentTemplates.length > 1 ? "s" : ""}</span>
          </div>
        </AppButton>
      </CommandBar>

      {/* ===================  POPUP Modal ======================== */}
      {/* <PopupModal
        setOpen={setShowPopupDeleteCotentTemplate}
        isOpen={showPopupDeleteCotentTemplate}
        title="If you want to continue, confirm by clicking the delete button below."
        onConfirmClick={showCofirmationToDeleteWithReferences}
      /> */}

      <PopupModal
        setOpen={setShowPopupDeleteConfirmation}
        isOpen={showPopupDeleteConfirmation}
        title="Are you sure you want to delete this Content Template ?
        Deleting this Content Template will delete all Content associated with this Template."
        onConfirmClick={confirmDeleteContentTemplate}
      />

    </Fragment >
  );
};


export default ContentTemplatePage;
