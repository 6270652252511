import { FC, ReactNode } from 'react'


interface TabActionLayoutProps {
	children: ReactNode
	name?: string
}

export const TabActionLayout: FC<TabActionLayoutProps> = (props) => {
	return (
		<div className="pb-10">
			<div className="w-[97%] py-5 m-auto space-y-5">
				<div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-hidden bg-white border-borderGray border-1">
					{props.children}
				</div>
			</div>
		</div>
	)
}