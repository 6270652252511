import React, {FC, InputHTMLAttributes} from 'react';
import {TextField} from "@mui/material";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    extraClasses?: string;
    onChange?: (event: any) => void;
    value?: string | number,
    label: string
    ref?: any
    select?: boolean
}


const StandardInput: FC<InputProps> = ({
                                           value,
                                           extraClasses,
                                           onChange,
                                           label,
                                           ref,
                                           select = false,
                                           ...rest
                                       }) => {


    return ( // @ts-ignore
        <TextField size={rest.size ? 'small' : 'medium'} value={value} onChange={onChange} label={label} select={!!select}
                   className={`appearance-none rounded-md text-gray-600 w-full bg-white leading-tight focus:outline-none p-3 ${extraClasses}`}
                   placeholder=""
                   ref={ref}
                   {...rest}
        />
    );
};

export default StandardInput;