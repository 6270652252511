import moment from "moment";
import { renameRuleName } from "../../utils/RuleHelper";

const jsonHandler = (timeValue: number, type: string, eventName: string, query: any, target_func?: any, val?: string) => {

    let value = undefined as any
    const modifuObj = query?.rules?.map((item: any) => {
        if (["OP.FirstDayOfWork", "OP.EffectiveDate", "OP.HireDateWorker"].includes(item.field)) {
            if (value === undefined) value = item?.field?.replaceAll("OP.", "")
        }
        return {
            ...item,
            value: ["OP.FirstDayOfWork", "OP.EffectiveDate", "OP.HireDateWorker"].includes(item.field) ? moment(item.value).format("MM-DD-YYYY") : item.value
        }
    })

    return {
        deliver_time: timeValue ? timeValue : 5,
        target_field: value,
        target_func: !value ? undefined : (value && !target_func) ? "ADD" : target_func,
        deliver_unit: type,
        rule_json: {
            desc: "rule delay " + eventName ? renameRuleName(eventName) : "", //@ts-ignore
            name: eventName ? renameRuleName(eventName) : "",
            when: query.rules.length === 1 ?
                {
                    // @ts-ignore
                    [query.rules[0]?.operator || ""]: [
                        { //@ts-ignore
                            "obj": modifuObj[0]?.field ? modifuObj[0]?.field === "OP.CheckInArray" ? `OP.CheckInArray('${val}',OP.ManagementChainEmployeeIDs)` : modifuObj[0]?.field : ""
                        },
                        { //@ts-ignore
                            "const": typeof modifuObj[0]?.value === "boolean" ? modifuObj[0]?.value : modifuObj[0]?.value || ""
                        }
                    ]
                } :
                {
                    [query.combinator]: modifuObj.map((item: any) => {
                        return { //@ts-ignore
                            [item?.operator || ""]: [
                                { //@ts-ignore
                                    "obj": item?.field ? item.field === "OP.CheckInArray" ? `OP.CheckInArray('${val}',OP.ManagementChainEmployeeIDs)` : item.field : ""
                                },
                                { //@ts-ignore
                                    "const": typeof item?.value === "boolean" ? item?.value : item?.value || ""
                                }
                            ]
                        }
                    })
                },
            salience: 0
        }
    }

}

export default jsonHandler
