import {RuleGroupType} from "react-querybuilder";

const initialQuery: RuleGroupType = {
    combinator: "and",
    rules: [
        // {
        //     field: "OP.DoNotHire",
        //     id: "r-0.8151233031018321",
        //     operator: "eq",
        //     value: false,
        //     valueSource: "value"
        // },
        // {
        //     field: "OP.Withdrawn",
        //     id: "r-0.5431098680822248",
        //     operator: "eq",
        //     value: false,
        //     valueSource: "value"
        // }
    ]
};

export default initialQuery