import { CSSProperties, FC } from "react";
import { HashLoader } from "react-spinners";

interface LoaderProps {
  isLoading: boolean;
  size?: number;
  isOverrideStyles?: boolean;
}

const override: CSSProperties = {
  position: "absolute",
  display: "flex",
  margin: "0 auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

export const Loader: FC<LoaderProps> = (props) => {
  const { isLoading, size, isOverrideStyles } = props;

  return (
    <HashLoader
      color="#2381E3"
      loading={isLoading}
      cssOverride={isOverrideStyles ? override : {}}
      size={size}
    />
  );
};

Loader.defaultProps = {
  size: 50,
  isOverrideStyles: false,
};
