type LanguagesOptionsType =  {
	value: string;
	label: string;
}[]

export const LanguagesOptions: LanguagesOptionsType = [
	{ value: "en", label: "English" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]

export const getLangaugeLabel = (language: string) => {
	switch (language) {
		case "en":
			return "English";
		case "es":
			return "Spanish";
		case "fr":
			return "French";
		default:
			return "English";
	}
}



export const LanguagesOptionsList:LanguagesOptionsType = [
	{ value:"-", label : "Choose an option" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]
