import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectEmployee = () => {
  // redirect to /fedex/employee-verification

  const navigate = useNavigate();

  useEffect(() => {
    navigate("/fedex/employee-verification");
  }, [navigate]);

  return <div>loading</div>;
};

export default RedirectEmployee;
