import React, { Fragment, useContext, useEffect, useMemo } from "react";
import Input from "../General/Form/Input";
import TextArea from "../General/Form/TextArea";
import Switch from "@mui/material/Switch";
import Button from "../General/Button";
import { TFlow } from "../../Common/Types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updateFlowState } from "../../apiQuery/StateFlow/StateFlow.apis";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import PageHeader from "../Layouts/PageHeader";
import { store } from "../../GlobalState/store";
import { Alert } from "@mui/material";
import SelectInput from "../General/Form/Select";
import { CurrentFlow_R } from "../../GlobalState/types";
import { getStateFlow } from "../../apiQuery/StateFlow/State.apis";
// import { setSnackbar } from "../../GlobalState/store.actions";
import SkeletonLoader from "../Helpers/Skeleton";
import { BiArrowBack } from "react-icons/bi";
import { withAuth } from "../../hoc/withAuth";
import { useTranslation } from "react-i18next";
import { toast } from "../../utils/Toast";

const EditPathway: React.FC = () => {
  const { t, i18n } = useTranslation();

  const params = useParams();
  const id = params.slug || "";

  const { dispatch } = useContext(store);

  const { state: globalState } = useContext(store);

  const organization_id = useMemo(
    () => globalState.organization_id,
    [globalState.organization_id]
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const currentFlow = useMemo(
    () => globalState.currentFlow,
    [globalState.currentFlow]
  );

  const { isLoading, isError, status, data, error, refetch, isFetching } =
    useQuery<CurrentFlow_R, Error>(
      ["individual-state-flow", id],
      () => getStateFlow(id),
      {
        enabled: !!id,
      }
    );

    useEffect(() => {
      if (data?.data?.name) {
        setValue("name", data?.data?.name);
        setValue("description", data?.data?.description);
        setValue("status", data?.data?.status);
        setValue("entry_state_id", data?.data?.entry_state_id || undefined);
      }
    }, [data]);

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      getValues,
    } = useForm<TFlow>();

    const updateStateFlowMutation = useMutation(
      (updateFlow: TFlow) =>
        updateFlowState(
          globalState.organization_id,
          {
            ...updateFlow,
            // organization_id
          },
          id
        ),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries("flow-state");
          await queryClient.invalidateQueries(["individual-state-flow", id]);
          // dispatch(setSnackbar({isOpen: true, type: 'success', message: 'Pathways has been updated.'}))
          toast("Pathways has been updated.", "success");
          navigate(`/pathways`);
        },
        onError: () => {
          // dispatch(setSnackbar({ isOpen: true, type: 'error', message: 'Error updating a Pathway.' }))
          toast("Error updating a Pathway.", "error");
        },
      }
    );

   

    const onSubmit: SubmitHandler<TFlow> = (data) => updateStateFlowMutation.mutate(data);
  
    // if (currentFlow.slug !== id) return <Navigate to="/dashboard" />;

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/*---------------------------------------------------------*/}
        {/*Edit Pathway page header with confirm/cancel actions*/}
        <PageHeader
          title={`${t("PathwayPage.Edit_Pathway")}`}
          button={
            <div className="flex space-x-3">
              <Button
                onClick={() => navigate(-1)}
                icon={<BiArrowBack className="mr-2 text-xl" />}
                label={`${t("PathwayPage.Go_Back")}`}
                extraClasses="w-fit px-3 py-3 bg-blue-400 text-white"
              />
              <Button
                disabled={updateStateFlowMutation.isLoading}
                type="submit"
                label={`${t("PathwayPage.Save_Changes")}`}
                extraClasses="w-fit px-3 py-3 bg-blue-400 text-white"
              />
            </div>
          }
        />

        {isLoading ? (
          <SkeletonLoader />
        ) : isError ? (
          <span>Error: {error?.message || "Something went wrong!"}</span>
        ) : data ? (
          <>
            {(!data?.data?.entry_state_id ||
              data?.data?.entry_state_id === 0) && (
              <Alert severity="warning">
                No Entry State has been set for this Pathway
              </Alert>
            )}

            {/*---------------------------------------------------------*/}
            {/*Edit Pathway form inputs*/}
            <div className="w-11/12 py-5 m-auto space-y-5 text-gray-600 text-md">
              <Input
                register={() =>
                  register("name", { required: "name is required" })
                }
                name="name"
                placeholder={`${t("PathwayPage.Pathway_Title")}`}
                errors={errors}
                extraClasses="w-2/5"
              />
              <TextArea
                register={() => register("description")}
                name="description"
                placeholder={`${t("PathwayPage.Pathway_Description")}`}
                errors={errors}
                extraClasses="w-2/5"
              />

              <div className="w-2/5">
                <SelectInput
                  defaultValue={data?.data?.entry_state_id || undefined}
                  placeholder={`${t(
                    "PathwayPage.Entry_State_for_this_Pathway"
                  )}`}
                  options={
                    (
                      data?.data?.states && [
                        {
                          name: "Not Selected",
                          id: 0,
                        },
                        ...data?.data?.states,
                      ]
                    )?.map((item) => {
                      return {
                        value: item.id || 0,
                        text: item.name,
                      };
                    }) || []
                  }
                  name="entry_state_id"
                  register={() => register("entry_state_id")}
                  errors={errors}
                />
              </div>

              <div className="flex flex-col space-y-2">
                <span>{`${t("PathwayPage.Pathway_Active")}`} </span>
                <Switch
                  defaultChecked={data?.data.status}
                  {...register("status")}
                  color="primary"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <span>
                  {`${t("PathwayPage.Allow_Multiple_Participants")}`}{" "}
                </span>
                <Switch
                  defaultChecked={data?.data?.allow_multiple_participants}
                  {...register("allow_multiple_participants")}
                  color="primary"
                />
              </div>
            </div>
          </>
        ) : null}
      </form>
    
    </Fragment>
  )

};

export default withAuth(EditPathway);
