import React, {FC, memo, useRef, useState} from "react";
import {GrFormFilter, GrFormSearch} from "react-icons/gr";
import useOnClickOutside from "../../../Hook/UseOutsideHook";
import {Fade} from "@mui/material";
import {MdClose} from "react-icons/md";
import { useTranslation } from 'react-i18next';

type SearchProps = {
    search: string;
    onSearch?: (event: any) => void;
    placeholder?: string
    options?:string[],
    selected?:string[],
    handleSelectFilter?:(prop:string)=>void
    type?:string
    handleRemoveFilters?:() => void
}

const Search: FC<SearchProps> = memo(({search, onSearch, placeholder,options,selected,handleSelectFilter,type,handleRemoveFilters}) => {
    const { t, i18n } = useTranslation();

    const [showOption, setShowOption] = useState(false)

    const optionRef = useRef() as any;
    useOnClickOutside(optionRef, () => setShowOption(false))

    return (
        <div className="relative flex items-center w-full h-12 space-x-2">
            <label className="relative block w-full">
                <GrFormSearch
                    className="absolute w-8 h-8 transform -translate-y-1/2 pointer-events-none top-1/2 left-3"/>
                <input value={search} onChange={onSearch}
                       className="w-full h-12 leading-tight text-black bg-white border rounded appearance-none border-dark focus:outline-none pl-14"
                       placeholder={placeholder}/>
            </label>
            {
                options &&
                <div ref={optionRef}>
                    <div className="p-3 bg-white border rounded cursor-pointer" onClick={() => setShowOption(prev => !prev)}>
                        <div className="flex items-center">
                            <GrFormFilter className="text-2xl"/>
                            <span>{t('StatePage.Filters')}({selected?.length || 0})</span>
                        </div>
                    </div>
                    <Fade in={showOption}>
                        <div
                            className="absolute top-14 right-1 bg-white rounded p-4 border border-[#D8D8D8]">
                            <p className="mb-3">{t('StatePage.Filter_by')}  {type} {t('StatePage.type')} </p>
                            <div className="grid grid-cols-4 gap-3">
                                {options?.map((item, index) => <div onClick={() => {
                                    handleSelectFilter && handleSelectFilter(item)
                                }} key={index}
                                 className={`rounded-md p-2 leading-4 cursor-pointer hover:bg-blue-500 hover:text-white capitalize ${selected?.includes(item) ? 'bg-blue-500 text-white': 'bg-gray-200'}`}>{`${t(`Search.${item}`)}`}</div>)
                                }
                            </div>
                        </div>
                    </Fade>
                </div>
            }

            {
                (selected && selected?.length !==0) &&
                <div onClick={handleRemoveFilters} className="flex p-3 border rounded cursor-pointer bg-white w-[14rem] space-x-1 flex items-center">
                    <MdClose className="text-2xl"/>
                    <span>{t('StatePage.Remove_Filters')} </span>
                </div>
            }


        </div>
    );
});

export default Search;