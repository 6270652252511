import React, {FC} from 'react';
import {AiOutlineInfoCircle} from "react-icons/ai";
import Button from "./Button";
import { useTranslation } from 'react-i18next';

type prop = {
    handleState: () => void,
    name: string
}

const ZeroState: FC<prop> = ({handleState, name}) => {
    const { t, i18n } = useTranslation();

    return (
        <div
            className="flex flex-col items-center justify-center flex-1 h-full space-x-3">
            <AiOutlineInfoCircle className="text-7xl"/>
            <div className="text-gray-400 space-y-3 max-w-[28rem]">
                <p className="text-3xl text-center"> {t('No_Data_Display')}</p>

                <Button label={name} onClick={handleState}
                        extraClasses={"bg-blue-500 px-2 py-3 text-white"}/>
            </div>

        </div>
    );
};

export default ZeroState;