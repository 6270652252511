export const CopyIcon = (props: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden='true'
            className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
            viewBox="0 -960 960 960"
        >
            <path fill="currentColor" d="M180-81q-24 0-42-18t-18-42v-603h60v603h474v60H180zm120-120q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300zm0-60h440v-560H300v560zm0 0v-560 560z"></path>
        </svg>
    )
};