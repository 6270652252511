import { FC, useEffect, useMemo, useRef, useState } from "react";


interface Options {
    value: string
    label: string
}


export interface AppSearchableDropDownProps {
    options: Options[]
    inputSearchPlaceholder?: string
    setSelectedItem: (value: string) => void
    defaultValue?:string
    isFull?: boolean
    label: string
    error?: string
    extendClass?: string
    disabled?: boolean
}


export const AppSearchableDropDown: FC<AppSearchableDropDownProps> = (props) => {

    const { setSelectedItem, defaultValue, inputSearchPlaceholder, options, label, error, isFull, disabled, extendClass, ...rest } = props

    const dropDropDownRef = useRef<HTMLDivElement>(null)
    const buttondropDropDownRef = useRef<HTMLButtonElement>(null)

    const [searchValue, setSearchRule] = useState<string>('')
    const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
    const [dynamicLabel, setDynamicLabel] = useState<string>(label)

    const searchResult: Options[] = useMemo(() => {
        if (searchValue === "") {
            return options
        }
        return options.filter(item => item.label.toLowerCase().includes(searchValue.toLowerCase()))
    }, [options, searchValue])


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const targetEl = event.target as Node; // Cast event.target to Node

            const filterEl = dropDropDownRef.current;
            const buttonEl = buttondropDropDownRef.current;

            if (
                filterEl &&
                buttonEl &&
                !filterEl.contains(targetEl) &&
                !buttonEl.contains(targetEl)
            ) {
                setIsOpenDropDown(false);
                setSearchRule('')
            }
        };

        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dropDropDownRef, buttondropDropDownRef, setIsOpenDropDown, setSearchRule]);



    // close the fitler with ESC key
    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsOpenDropDown(false);
                setSearchRule('')
            }
        };

        document.addEventListener("keydown", handleEscapeKey);

        return () => {
            document.removeEventListener("keydown", handleEscapeKey);
        };
    }, [setSearchRule]);


    useEffect(() => {
        if(defaultValue) {
            const i = options.find(item => item.value === defaultValue)
            if(i){
                setDynamicLabel(i?.label)
            }
        }
    },[defaultValue, options])


    // ========================
    return <div className="relative">
        <button disabled={disabled} ref={buttondropDropDownRef} onClick={() => setIsOpenDropDown(prev => !prev)} id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" data-dropdown-placement="bottom"

            className={
                `flex items-center justify-between p-2.5 border border-borderGray bg-[#F5F5F5] placeholder:text-[#A4A5A5] text-gray-900 text-md rounded-sm 
            disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-800
                    focus:ring-blueColor
                    focus:border-blueColor
                     w-full cursor-pointer
             ${extendClass || ''}
             `
            }

            type="button">
            {dynamicLabel}
            {
                !isOpenDropDown ? (<svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 -960 960 960"

                >
                    <path fill="currentColor" d="M480-344L240-584l43-43 197 197 197-197 43 43-240 240z"></path>
                </svg>) : (<svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 -960 960 960"
                >
                    <path fill="currentColor" d="M480-554L283-357l-43-43 240-240 240 240-43 43-197-197z"></path>
                </svg>)
            }
        </button>
        <div {...rest} ref={dropDropDownRef} id="dropdownSearch"
            className={`z-50 absolute top-12 max-h-[24rem] overflow-y-scroll overflow-x-hidden ${isOpenDropDown && disabled === false ? '' : 'hidden'} bg-[#F5F5F5] shadow min-w-60 text-md `}>
            <div className="p-3">
                <label htmlFor="input-group-search" className="sr-only">Search</label>
                <div className="relative">
                   
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 cursor-pointer">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="text" id="input-group-search"
                        value={searchValue}
                        onChange={e => setSearchRule(e.target.value)}
                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md shadow-sm bg-[#F5F5F5]  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder={inputSearchPlaceholder} />
                    
                </div>
            </div>
            <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" >
                {
                    searchResult.length > 0 ? <>
                        {searchResult.map((item, index) => {
                            return (
                                <li role="button" key={index} onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedItem(item.value)
                                    setDynamicLabel(item.label)
                                    setIsOpenDropDown(prev => false)
                           
                                }}>
                                    <div className={`${item.label === dynamicLabel ? 'ring-2 ring-blue-500' : ''} flex p-2 transition-all rounded cursor-pointer hover:bg-white dark:hover:bg-gray-600 hover:cursor-pointer`}>
                                        <div className="flex items-center h-5">
                                            <input id="helper-radio-4" name="helper-radio" type="radio" value={item.value}
                                                checked={defaultValue ? item.value === defaultValue : item.label === dynamicLabel}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    setSelectedItem(item.value)
                                                    setDynamicLabel(item.label)
                                                    setIsOpenDropDown(prev => false)
                                                    // const val = e.target.value
                                                    // setSelectedItem(val)
                                                    // setDynamicLabel(item.label)
                                                    // setIsOpenDropDown(prev => false)
                                                }}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        </div>
                                        <div className="ml-2 text-sm">
                                            <label htmlFor="helper-radio-4" className="font-medium text-gray-900 cursor-pointer dark:text-gray-300">
                                                <div>{item.label}</div>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </> : <>
                        <li>
                            <div className="w-full rounded">
                                <p className="text-sm text-center text-textDark">No items found.</p>
                            </div>
                        </li>
                    </>
                }
            </ul>

        </div>
    </div>
}