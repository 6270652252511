import React, { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery } from "react-query";
import { deleteContentTemplateById, getAllPathways, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { ConsentContentCreateOrUpdateRequest, ConsentContentResponse, ConsentContentTemplate, IConsent, IConsentContentTemplate, initialConsentContent } from '../../../../Types/ContentTemplate/Consent';
import UseUploadFile from '../../../../Hook/UseUploadFile';
import UseUpload from '../../../../Hook/UseUpload';
import { baseURL } from '../../../../apiQuery';
import UploadProgress from '../../../General/UploadProgress';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { useNavigate } from 'react-router-dom';

export type Language = "en" | "fr" | "es"

export const LanguagesOptionsList = [
	{ value: "-", label: "Choose an option" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]

interface ConsentTabContentProps {
	templateId: number,
}

const ConsentTabContent: FC<ConsentTabContentProps> = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	// =================================== Updoad cover image ===================================
	const [imageSrc, file, render, name] = UseUploadFile('Consent')
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);


	const [uploadState, setUploadState] = useState({
		file,
		name,
	})

	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])

	const { currentOrganizationID } = useContext(UserContext);

	const [state, setState] = useState<ConsentContentTemplate[]>([])

	const [consentContent, setConsentContent] = useState<ConsentContentTemplate>(initialConsentContent)


	const createOrUpdateButton = useRef<string>("Create")
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')

	//====================================================================================================================
	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);


	const { data: consentData, refetch } = useQuery<ConsentContentResponse, Error>
		(`consent-content-${props.templateId}`, () => getContentTemplateById(props.templateId));

	useEffect(() => {
		if (consentData?.data) {
			editableConsentContent(consentData?.data)
		}
	}, [consentData?.data])

	// useEffect(() => {
	// 	if (selectedLanguage === '') return
	// 	// get seleted index of the content template to be displayed as the current content
	// 	const selectedIndex = consentData?.data['consent'].findIndex((item: any) => item.language === selectedLanguage) as number
	// 	const consent: IConsent = consentData?.data['consent'][selectedIndex] as IConsent
	// 	const contentMetaData = consentData?.data.content_metadata[selectedIndex] as IContentMetadata
	// 	setConsentContent({
	// 		consent: consent,
	// 		content_metadata: contentMetaData
	// 	})
	// 	createOrUpdateButton.current = "Update"
	// }, [selectedLanguage])

	const deleteTemplateFromContentTemplateList = async () => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/consent', { state: { deleted: true } })
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.consent.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}

	//=====================================================
	//set english default language for content
	// useEffect(() => {
	// 	const consent = consentData?.data['consent'].find(item => item.language === "en")
	// 	const contentMetaData = consentData?.data.content_metadata.find(item => item.language === "en")
	// 	setConsentContent({
	// 		consent: consent as IConsent,
	// 		content_metadata: contentMetaData as IContentMetadata
	// 	})
	// 	createOrUpdateButton.current = "Update"
	// 	setSelectedLanguage("en")

	// }, [consentData?.data])
	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setConsentContent({
				consent: consentData?.data.consent.find((item: IConsent) => item.language === LanguageEnum.English) as IConsent,
				content_metadata: consentData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			createOrUpdateButton.current = "Update"
		}
	}, [consentData?.data.consent, consentData?.data.content_metadata])

	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.consent.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges

	}, [state])



	// ================================================= Mutations =================================================

	const updateConsentContentMutation = useMutation(
		(params: { id: number, data: ConsentContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Content updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	//=====================================================
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})

	const allPathways = useQuery<any>('all-pathways-list', () => getAllPathways())

	//=======================================================================================================


	const pathwayList = useMemo(() => {
		const item = { label: 'Not Selected', value: '0' }
		const list: { label: string, value: string }[] = allPathways.data?.data && allPathways.data?.data?.map((item: any) => {
			return {
				label: item.name,
				value: item.id
			}
		})

		list && list.unshift(item)
		return list || []
	}, [allPathways.data?.data])


	// handlers
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setConsentContent(prev => {
			return {
				...prev,
				consent: {
					...prev.consent,
					[name]: value
				}
			}
		})
	}




	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		//  tODO: validation goes here
		if (consentContent.consent.title === '') {
			toast('Please enter a title', 'error')
			return
		}

		// =============== CREATE =================
		if (createOrUpdateButton.current === "Create") {
			// Todo : check if the language is already exist
			if (state.some(item => item.consent.language === consentContent.consent.language)) {
				toast('This consent is already exist', 'error')
				return
			}
			let _cover_imageLink = ''

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				// setArticleContent(prev => ({...prev, cover_image: link}))
				_cover_imageLink = link
			}

			const consentContentWithImage: ConsentContentTemplate = {
				consent: consentContent.consent,
				content_metadata: {
					...consentContent.content_metadata,
					cover_image: _cover_imageLink,
				}
			}

			// setState(prev => [...prev, consentContentWithImage])
			const newState = [...state, consentContentWithImage]
			await createOrUpdateContentTemplate(newState)

		} else {
			// =============== UPDATE =================
			let cover_image = consentContent.content_metadata.cover_image

			if (file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", file);
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}
			const newState = state.map(item => {
				if (item.consent.language === consentContent.consent.language) {
					return {
						consent: consentContent.consent,
						content_metadata: {
							...consentContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})
			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = "Create"
		}



		createOrUpdateButton.current = "Create"
		// 	empty the fields
		setConsentContent(initialConsentContent)
		// reset fields
		setUploadState({
			file: undefined,
			name: '',
		})

	}

	const createOrUpdateContentTemplate = async (data: ConsentContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one consent template', 'info')
			return
		}

		const shapeData: ConsentContentCreateOrUpdateRequest = {
			content_type: EnumContentType.ConsentContent,
			consent: data.map(item => {
				return {
					...item.consent,
					title: item.consent.title,
					description: item.consent.description,
					body: item.consent.body,
					subscribe_user_to_pathway_id: item.consent.subscribe_user_to_pathway_id,
					language: item.consent.language as LanguageEnum,
				}
			}),
			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.consent.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}
		await updateConsentContentMutation.mutateAsync({ id: props.templateId, data: shapeData })

		setState([])
		setConsentContent(initialConsentContent)
	}


	const editableConsentContent = (consentContentTemplate: IConsentContentTemplate | undefined) => {



		if (!consentContentTemplate) return

		if (consentContentTemplate.consent === undefined) {
			consentContentTemplate.consent = [initialConsentContent.consent]
		}


		const combinedData: any[] = [];
		// Loop through each article
		consentContentTemplate.consent.forEach((consent: any) => {
			// Loop through each content metadata
			consentContentTemplate.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (consent.language === metadata.language) {
					// Combine the properties of the consent and metadata objects
					const combinedObj = {
						consent: consent,
						content_metadata: metadata
					};
					// Add the combined object to the final array
					combinedData.push(combinedObj);
				}
			});
		});

		setState(combinedData)
	}

	return (
		<Fragment>

			<ViewContentTemplateHeader
				title={consentData?.data.consent[0].title || ''}
				icon={<svg
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 7.573 8.414"
					className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
				>
					<path
						id="icon_pathways"
						d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
						transform="translate(-6 -4)"
						fill="currentColor"
					/>
				</svg>}
				referencesCount={consentData?.data.linked_content_count || 0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{
					consentData?.data.consent.sort((a, b) => {
						// Define a language order
						const languageOrder = { en: 0, es: 1, fr: 2 };

						// Get the language order for each object
						const orderA = languageOrder[a.language] || Infinity;
						const orderB = languageOrder[b.language] || Infinity;

						// Compare the language order
						return orderB - orderA;
					}).map((item: IConsent, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setConsentContent(initialConsentContent)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										// editableConsentContent(consentData.data)

										createOrUpdateButton.current = "Update"
										currentLanguage.current = item.language as LanguageEnum
										setConsentContent({
											consent: consentData.data.consent.find((consent) => consent.language === item.language) as IConsent,
											content_metadata: consentData.data.content_metadata.find((metadata: IContentMetadata) => metadata.language === item.language) as IContentMetadata
										})
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					(consentData?.data.consent?.length ?? 0) < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setConsentContent(initialConsentContent)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								{t('new_language')}
							</span>

						</AppButton>
					) : null
				}
			</div>




			<TabContentLayout >


				{/* =========================== */}
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{consentContent?.consent?.title || ""}</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">

					<div className='flex w-full gap-2 mb-8'>
						<AppButton size="xs" disabled={consentContent?.consent?.title === '' || updateConsentContentMutation.isLoading || deleteContentTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Content Template</AppButton>
						<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>{t("content_template.delete_content_template")}</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>
					</div>
					<hr className="mb-8" />
					<AppInput autoFocus placeholder={"Title"} value={consentContent?.consent?.title || ""} name='title' isFull onChange={handleChange} label='Title' required />
					<AppTextArea placeholder={"Description"} rows={4} value={consentContent?.consent?.description || ""} name='description' onChange={handleChange}
						label='Description' />
					<AppTextArea placeholder={"Body"} value={consentContent?.consent?.body || ""} rows={6} name='body' onChange={handleChange} label='Body' />

					<AppDropDown label={`${t('Content.Subscribe_User_To_Pathway')}`} name='subscribe_user_to_pathway_id'
						options={pathwayList}
						onChange={e => {
							setConsentContent(prev => {
								return {
									...prev,
									consent: {
										...prev.consent,
										subscribe_user_to_pathway_id: Number(e.target.value)
									}
								}
							})
						}}
						value={consentContent?.consent?.subscribe_user_to_pathway_id?.toString()}
						extendClass='my-2'
					/>

					{/* <AppInput placeholder={"Alternative Title"} value={consentContent?.content_metadata?.alt_title || ''} name='alt_title' isFull
						onChange={e => {
							setConsentContent({
								...consentContent,
								content_metadata: {
									...consentContent.content_metadata,
									alt_title: e.target.value
								}
							})
						}} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

					<AppInput placeholder={"Preview Title"} value={consentContent?.content_metadata?.preview_title} name='preview_title' isFull
						onChange={e => {
							setConsentContent(prev => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										preview_title: e.target.value
									}
								}
							})
						}} label='Preview Title' extendClass='my-2' maxLength={140} required />
					<AppInput placeholder={"Tag"} value={consentContent?.content_metadata?.tags[0]} name='tags' isFull onChange={e => {
						setConsentContent(prev => {
							return {
								...prev,
								content_metadata: {
									...prev.content_metadata,
									tags: [e.target.value]
								}
							}
						})
					}} label='Tag' extendClass='my-2' />


					{consentContent?.content_metadata?.cover_image ? <div className='cover__image-container'>
						<img alt="conver_image" src={consentContent.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
					</div> : null}


					{render}

					{uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}

					{
						isLoading ? <UploadProgress progress={progress} /> : null
					}
					{/* <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"}
						options={LanguagesOptions} value={consentContent?.content_metadata?.language || ""} name="language" onChange={handleChange} /> */}

					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={consentContent?.consent?.language || ""} name="language"
							onChange={e => {
								setConsentContent(prev => {
									return {
										...prev,
										consent: {
											...prev.consent,
											language: e.target.value as LanguageEnum
										}
									}
								})
							}} />
					}
				</form>

			</TabContentLayout>



			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>

		</Fragment>

	)
}


export default ConsentTabContent
