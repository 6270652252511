import React from 'react';
import PageHeader from "../Layouts/PageHeader";
import {useQuery} from "react-query";
import {Job} from "../../Common/Types";
import {getAllJobs} from "../../apiQuery/Job/Job.apis";
import SkeletonLoader from "../Helpers/Skeleton";
import {LinearProgress} from "@mui/material";
import JobList from "../List/JobList";
import { withAuth } from '../../hoc/withAuth';

const Jobs = () => {

    const {
        isLoading,
        isError,
        data,
        error,
        isFetching
    } = useQuery<Job, Error>(['jobs'], () => getAllJobs(), {})

    return (
        <>
            {
                isFetching &&
                <LinearProgress/>
            }

            <PageHeader
                title='Jobs'
            />

            <div className="pt-4 pb-44">
                <div className="w-11/12 py-2 m-auto space-y-5">
                    {isLoading ? (
                            <SkeletonLoader/>
                        ) :
                        isError ? (
                                <span>Error: {error?.message || "Something went wrong!"}</span>
                            ) :
                            data ?
                                <div className="space-y-8">
                                    <div className="grid grid-cols-2 gap-5">
                                        {
                                            data?.map((item,index) => <JobList func_name={item.func_name} key={index} desc={item.desc} name={item.name}
                                                                       weekday={item.weekday}
                                                                       hour={item.hour} minute={item.minute}
                                                                       offset_hour={item.offset_hour}
                                                                       offset={item.offset}
                                                                       offset_minute={item.offset_minute}
                                                                       weekday_name={item.weekday_name}/>)
                                        }
                                    </div>
                                </div>

                                : null
                    }
                </div>
            </div>
        </>
    );
};

export default withAuth(Jobs);