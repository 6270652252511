import React from "react";

interface AppSearchProps {
    className?: string;
    placeHolder?: string;
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?:string;
    defaultValue?:string
}

const AppSearch: React.FC<AppSearchProps> = ({ className, placeHolder, onChangeHandler, value, defaultValue }) => {
    return (
        <input
            className={className}
            type='search'
            placeholder={placeHolder}
            onChange={onChangeHandler}
            value={value}
            defaultValue={defaultValue}
        />
    );
};

export default AppSearch;
