/* eslint-disable */
export const ActionIcon = (props: { className?: string }) => {
    const { className } = props;
    return (
        <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9.878 9.878"
        className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
      >
        <path
          id="icon_actions"
          d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
          transform="translate(-2 -2)"
          fill="currentColor"
        />
      </svg>
    );
  };
  
  ActionIcon.defaultProps = {
    className: "",
  };
  