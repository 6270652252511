import { FormEvent, memo, useContext, useEffect, useState } from "react";
import { AppButton, CloseIcon } from "../../../Theme";
import AppInput from "../../Shared/AppInput/AppInput";
import AppTextArea from "../../Shared/AppTextArea/AppTextArea";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { useMutation, useQuery } from "react-query";
import { toast } from "../../../utils/Toast";
import {
  createFlowState,
  updateFlowState,
} from "../../../apiQuery/StateFlow/StateFlow.apis";
import { store } from "../../../GlobalState/store";
import { useParams } from "react-router-dom";
import {
  createState,
  createStateByPathwaySlug,
} from "../../../apiQuery/State/State.apis";

type props = {
  modalHandle: () => void;
};

type NewPathwayFields = {
  name: string;
  description: string;
  visible_to_worker: boolean;
  invalidate_user: boolean;
  disable_participant: boolean;
};

const NewStateModal = memo(({ modalHandle }: props) => {
  const { t } = useTranslation();

  const params = useParams<{ slug: string }>();
  const { state: globalState } = useContext(store);

  const pathwaySlug = params.slug || "";

  const [state, setState] = useState<NewPathwayFields>({
    name: "",
    description: "",
    visible_to_worker: true,
    invalidate_user: false,
    disable_participant: false,
  });

  const createStateMutation = useMutation(
    (newStateData: NewPathwayFields) =>
      createStateByPathwaySlug(globalState.organization_id, pathwaySlug, {
        ...newStateData,
        // pathway_id: pathwaySlug,
      }),
    {
      onSuccess: async () => {
        toast("State has been created.", "success");
        modalHandle();
      },
    }
  );

  const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await createStateMutation.mutateAsync(state);
  };

  return (
    <div className="relative z-50 flex w-2/5 h-full mt-5 overflow-auto bg-white rounded-md shadow-md text-textDark state-new-modal">
      <div className="flex flex-col w-full p-5">
        <div className="flex justify-between">
          <div className="flex flex-col gap-5 mb-2">
            <div className="mb-3">
              <h2 className="text-2xl font-normal text-textDark">New State</h2>
              <p className="text-sm text-textDark">
                Create a new state for your Pathway.
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={modalHandle}
            className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <CloseIcon className="w-4 h-4" />
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        <hr className="w-full h-px border-1 border-borderGray dark:border-textDark" />
        <form onSubmit={submitFormHandler} className="p-2 w-[90%]">
          <AppInput
            isFull
            label="State Title"
            id="StateTitle"
            title="State Title"
            tabIndex={-1}
            placeholder="State Title"
            name="name"
            required
            onChange={(e) => {
              setState((prev) => ({ ...prev, name: e.target.value }));
            }}
            value={state.name}
          />
          <AppTextArea
            placeholder={"State Description"}
            rows={4}
            name="description"
            onChange={(e) => {
              setState((prev) => ({ ...prev, description: e.target.value }));
            }}
            label="State Description"
            id="StateDescription"
            value={state.description || ""}
          />

          <div className="flex gap-5 mt-5">
            <div className="flex justify-start">
              <label
                htmlFor="getCandidate_API"
                className="mx-2 mb-2 text-xs text-textDark"
              >
                Visible to Worker
              </label>
              <Switch
                checked={state.visible_to_worker}
                onChange={() => {
                  setState((prev) => ({
                    ...prev,
                    visible_to_worker: !prev.visible_to_worker,
                  }));
                }}
                className={`${state.visible_to_worker === true
                  ? "bg-greenColor"
                  : "bg-teal-700"
                  }
                      relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Visible to Worker</span>
                <span
                  aria-hidden="true"
                  className={`${state.visible_to_worker === true
                    ? "translate-x-3"
                    : "translate-x-0"
                    }
                    pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div>

            <div className="flex justify-start">
              <label
                htmlFor="getCandidate_API"
                className="mx-2 mb-2 text-xs text-textDark"
              >
                Deactivate Participants
              </label>
              <Switch
                checked={state.disable_participant === true}
                onChange={() => {
                  setState((prev) => ({
                    ...prev,
                    disable_participant: !state.disable_participant,
                  }));
                }}
                className={`${state.disable_participant === true
                  ? "bg-greenColor"
                  : "bg-teal-700"
                  }
                      relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Deactivate Participants</span>
                <span
                  aria-hidden="true"
                  className={`${state.disable_participant === true
                    ? "translate-x-3"
                    : "translate-x-0"
                    }
                    pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div>
          </div>
          <div className="flex justify-start mt-3">
            <label
              htmlFor="getCandidate_API"
              className="mx-2 mb-2 text-xs text-textDark"
            >
              Invalidate User (deletes WS1 and Auth0 records)
            </label>
            <Switch
              checked={state.invalidate_user === true}
              onChange={() => {
                setState((prev) => ({
                  ...prev,
                  invalidate_user: !state.invalidate_user,
                }));
              }}
              className={`${state.invalidate_user === true ? "bg-greenColor" : "bg-teal-700"
                }
                      relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">
                Invalidate User (deletes WS1 and Auth0 records)
              </span>
              <span
                aria-hidden="true"
                className={`${state.invalidate_user === true
                  ? "translate-x-3"
                  : "translate-x-0"
                  }
                    pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          <div className="mt-4">
            <AppButton disabled={createStateMutation.isLoading} variant="primary" type="submit">{`${t(
              "PathwayPage.Save_Changes"
            )}`}</AppButton>
          </div>
        </form>
      </div>
    </div>
  );
});

export default NewStateModal;
