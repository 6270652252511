import { FormEvent, memo, useContext, useEffect, useState } from "react";
import { AppButton, CloseIcon } from "../../../Theme";
import AppInput from "../../Shared/AppInput/AppInput";
import AppTextArea from "../../Shared/AppTextArea/AppTextArea";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { useMutation, useQuery } from "react-query";
import { toast } from "../../../utils/Toast";
import {
  createFlowState,
  updateFlowState,
} from "../../../apiQuery/StateFlow/StateFlow.apis";
import { store } from "../../../GlobalState/store";

type props = {
  modalHandle: () => void;
};

type NewPathwayFields = {
  name: string;
  description: string;
  status: boolean;
  allow_multiple_participants: boolean;
};

const NewPathwayModal = memo(({ modalHandle }: props) => {
  const { t } = useTranslation();

  const { state: globalState } = useContext(store);

  const [state, setState] = useState({
    name: "",
    description: "",
    status: false,
    allow_multiple_participants: false,
  });

  const createStateMutation = useMutation(
    (newStateData: NewPathwayFields) =>
      createFlowState(globalState.organization_id, {
        ...newStateData,
      }),
    {
      onSuccess: async () => {
        modalHandle();
        toast("New Pathway successfully created.", "success");
      },
    }
  );

  const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createStateMutation.mutate(state);
  };

  return (
    <div className="relative z-50 flex w-2/5 h-full mt-5 overflow-auto bg-white rounded-md shadow-md text-textDark pathway-new-modal">
      <div className="flex flex-col w-full p-8">
        <div className="flex justify-between">
          <div className="flex flex-col gap-5 mb-2">
            <div>
              <h2 className="mb-1 text-2xl font-normal text-textDark">
                New Pathway
              </h2>
              <p className="text-sm text-textDark">
                Create a new Pathway and customize the settings.
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={modalHandle}
            className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <CloseIcon className="w-4 h-4" />
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        <hr className="w-full h-px my-5 border-1 border-borderGray dark:border-textDark" />
        <form onSubmit={submitFormHandler} className="w-full">
          <AppInput
            isFull
            label={'Pathway Title'}
            id="PathwayTitle"
            title={'Pathway Title'}
            tabIndex={-1}
            placeholder={'Pathway Title'}
            name="name"
            required
            onChange={(e) => {
              setState((prev) => ({ ...prev, name: e.target.value }));
            }}
            value={state.name}
          />
          <AppTextArea
            placeholder={"Pathway Description"}
            rows={4}
            name="description"
            onChange={(e) => {
              setState((prev) => ({ ...prev, description: e.target.value }));
            }}
            label="Pathway Description"
            id="PathwayDescription"
            value={state.description || ""}
          />

          <div className="flex gap-5 mt-8">
            <div className="flex justify-start mr-8">
              <label
                htmlFor="getCandidate_API"
                className="mr-2 text-md text-textDark"
              >
                Pathway Active
              </label>
              <Switch
                checked={state.status}
                onChange={() => {
                  setState((prev) => ({
                    ...prev,
                    status: !prev.status,
                  }));
                }}
                className={`${state.status === true ? "bg-greenColor" : "bg-teal-700"
                  }
                      relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mt-1`}
              >
                <span className="sr-only">Pathway Active</span>
                <span
                  aria-hidden="true"
                  className={`${state.status === true ? "translate-x-3" : "translate-x-0"
                    }
                    pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div>

            <div className="flex justify-start">
              <label
                htmlFor="getCandidate_API"
                className="mr-2 text-md text-textDark"
              >
                Allow Multiple Participants
              </label>
              <Switch
                checked={state.allow_multiple_participants === true}
                onChange={() => {
                  setState((prev) => ({
                    ...prev,
                    allow_multiple_participants:
                      !state.allow_multiple_participants,
                  }));
                }}
                className={`${state.allow_multiple_participants === true
                  ? "bg-greenColor"
                  : "bg-teal-700"
                  }
                    relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mt-1`}
              >
                <span className="sr-only">Allow Multiple Participants</span>
                <span
                  aria-hidden="true"
                  className={`${state.allow_multiple_participants === true
                    ? "translate-x-3"
                    : "translate-x-0"
                    }
                  pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div>
          </div>
          <div className="mt-8">
            <AppButton disabled={createStateMutation.isLoading} variant="primary" type="submit">{`${t(
              "PathwayPage.Save_Changes"
            )}`}</AppButton>
          </div>
        </form>
      </div>
    </div>
  );
});

export default NewPathwayModal;
