export const FilterIcon = (props: { className?: string }) => {
  const { className } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      className={`h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white
  ${className ? ` ${className}` : ''}`}
      viewBox='0 0 7.816 5.211'
    >
      <path
        fill='currentColor'
        d='M9.04 17.211v-.651h1.737v.651zm-1.74-2.28v-.651h5.211v.651zM6 12.651V12h7.816v.651z'
        transform='translate(-6 -12)'
      />
    </svg>
  )
}

FilterIcon.defaultProps = {
  className: ''
}
