/* eslint-disable no-lone-blocks */
import { Fragment, MouseEvent, memo, useContext } from "react";
import { ITab, TabContext } from "../../Context/TabContext/TabContext";
import { useNavigate } from "react-router-dom";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

const RemoveIcon = () => (
  <svg
    className="w-3 h-3 transition duration-100 cursor-pointer flex-shrink- hover:text-blueColor text-grayColor dark:text-gray-400 dark:group-hover:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 7.637 7.637"
  >
    <path
      id="icon_close_remove"
      d="M6.386,9.651,7.818,8.219,9.25,9.651l.4-.4L8.219,7.818,9.651,6.386l-.4-.4L7.818,7.417,6.386,5.986l-.4.4L7.417,7.818,5.986,9.25Zm1.432,1.986a3.7,3.7,0,0,1-1.48-.3A3.847,3.847,0,0,1,4.3,9.3a3.814,3.814,0,0,1,0-2.969,3.816,3.816,0,0,1,.821-1.212A3.914,3.914,0,0,1,6.339,4.3a3.814,3.814,0,0,1,2.969,0,3.812,3.812,0,0,1,2.029,2.029,3.814,3.814,0,0,1,0,2.969,3.914,3.914,0,0,1-.816,1.217,3.816,3.816,0,0,1-1.212.821A3.718,3.718,0,0,1,7.818,11.637Z"
      transform="translate(-4 -4)"
      fill="currentColor"
    />
  </svg>
);

const Seprator = () => <div className="h-8 border-l-2 text-textDark mx-0.5" />;

export const TabBar = memo(() => {
  const navigate = useNavigate();
  const { globalTabs, setActiveTab, closeTab, closeAllTabs } =
    useContext(TabContext);

  const navigateByClick = (tab: ITab) => {
    navigate(tab.path);
    setActiveTab(tab);
  };

  const setActiveHandler = (
    event: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>,
    tab: ITab
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as Element;
    if (!target.closest("button")) {
      // Run parent element's code
      navigateByClick(tab);
    }
  };

  const closeTabHandler = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    tab: ITab
  ) => {
    event.stopPropagation();
    closeTab(tab);
    const updatedTabs = globalTabs.tabs.allTabs.filter(
      (t) => t.index !== tab.index
    );
    navigate(updatedTabs[updatedTabs.length - 1].path);
  };

  return (
    <div className="border flex-1 border-[borderGray] bg-primary border-l-0 px-2 py-2.5 dark:bg-bckDark sm:px-4 z-30 flex gap-2 items-center transition-all w-[98%] flex-wrap">
      {globalTabs.tabs.allTabs.map((tab, idx, array) => {
        if (globalTabs.tabs.activeTab.index === tab.index)
          // show active tab
          return (
            <div
              role="button"
              key={tab.index}
              onClick={(event) => setActiveHandler(event, tab)}
              className={`inline-flex items-center justify-between max-w-[12rem] p-2 text-sm capitalize bg-white border rounded cursor-pointer justify-content text-textGray border-borderGray hover:bg-gray-100 hover:border-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 first-letter:
              ${tab.name.length > 9 ? "min-w-[13rem]" : ""}
              `}
            >
              {tab.name.length >= 23 ? <Tooltip
                placement="top"
                overlay={<span>{tab.name}</span>}
              >
                <span>                {tab.name.length >= 23 ? tab.name.slice(0, 23) + "..." : tab.name}
                </span>
              </Tooltip>
                : <span>                {tab.name.length >= 23 ? tab.name.slice(0, 23) + "..." : tab.name}
                </span>}
              <button
                onClick={(event) => closeTabHandler(event, tab)}
                type="button"
                className="mx-1 mt-1 text-gray-600 hover:text-gray-800 focus:outline-none"
              >
                <RemoveIcon />
              </button>
            </div>
          );
        else
          return (
            <Fragment key={tab.index}>
              <div
                role="button"
                onClick={(event) => setActiveHandler(event, tab)}
                className={`flex items-center justify-between max-w-[12rem] gap-1 text-sm capitalize cursor-pointer text-textGray
                ${tab.name.length > 9 ? "min-w-[13rem]" : ""}
                `}
              >
                {tab.name.length >= 23 ? <Tooltip
                  placement="top"
                  overlay={<span>{tab.name}</span>}
                >
                  <span>                {tab.name.length >= 23 ? tab.name.slice(0, 23) + "..." : tab.name}
                  </span>
                </Tooltip> : <span>                {tab.name.length >= 23 ? tab.name.slice(0, 23) + "..." : tab.name}
                </span>}
                <button
                  onClick={(event) => closeTabHandler(event, tab)}
                  type="button"
                  className="mx-1 mt-1 text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  <RemoveIcon />
                </button>
              </div>
              {array.length > idx + 1 ? <Seprator /> : null}
            </Fragment>
          );
      })}

      {globalTabs.tabs.allTabs.length > 5 ? (
        <div
          role="button"
          onClick={(event) => {
            closeAllTabs();
            navigate("/workers");
          }}
          className="flex p-2 bg-blueColor min-w-[8rem] rounded-sm items-center justify-between max-w-[10rem] gap-1 text-sm cursor-pointer text-white"
        >
          Close All Tabs
          <button type="button" className="mx-1 text-white focus:outline-none">
            <svg
              className="flex-shrink w-3 h-3 text-white transition duration-100 cursor-pointer"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 7.637 7.637"
            >
              <path
                id="icon_close_remove"
                d="M6.386,9.651,7.818,8.219,9.25,9.651l.4-.4L8.219,7.818,9.651,6.386l-.4-.4L7.818,7.417,6.386,5.986l-.4.4L7.417,7.818,5.986,9.25Zm1.432,1.986a3.7,3.7,0,0,1-1.48-.3A3.847,3.847,0,0,1,4.3,9.3a3.814,3.814,0,0,1,0-2.969,3.816,3.816,0,0,1,.821-1.212A3.914,3.914,0,0,1,6.339,4.3a3.814,3.814,0,0,1,2.969,0,3.812,3.812,0,0,1,2.029,2.029,3.814,3.814,0,0,1,0,2.969,3.914,3.914,0,0,1-.816,1.217,3.816,3.816,0,0,1-1.212.821A3.718,3.718,0,0,1,7.818,11.637Z"
                transform="translate(-4 -4)"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      ) : null}
    </div>
  );
});
