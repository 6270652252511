import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";

// for the create and update the selector
// to create with selector content type id is going to be 0
export interface IVariant {
	id: string | null
	content: string
}

export type IVariantForServer = string


export interface ISelector {
	id: number
	title: string
	description: string
	language: LanguageEnum
	variants: IVariant[]
	items: ISelectorItem[]
	updated_at?: string
}
export interface ISelectorForServer {
	id: number
	title: string
	description: string
	language: LanguageEnum
	variants: IVariantForServer[]
	items: ISelectorItemForServer[]
	updated_at?: string
}

export interface ISelectorItem {
	id?: number
	appId: string
	title: string
	description: string
	image: string
	language: LanguageEnum
}
export interface ISelectorItemForServer {
	id?: number
	title: string
	description: string
	image: string
	language: LanguageEnum
}


export type ISelectorContentTemplate = {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	selector: ISelector[];
}

export type SelectorContentResponse = {
	data: ISelectorContentTemplate
	error: boolean
	message: string
	status: number
}


export type SelectorContentTemplate = {
	selector: ISelector
	content_metadata: IContentMetadata
}

export interface SelectorContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	selector: ISelectorForServer[],
	content_metadata: IContentMetadata[]
}


export const initialSelectorContent: SelectorContentTemplate = {
	selector: {
		id: 0,
		title: '',
		description: '',
		language: "en" as LanguageEnum,
		variants: [],
		items: []
	},
	content_metadata: {
		id: 0,
		cover_image: '',
		preview_title: '',
		tags: [''],
		language: LanguageEnum.English,
		alt_title: '',
	}
}


// type Selector struct {
// 	ID        uint           `gorm:"primaryKey;not null" json:"id"`
// 	CreatedAt time.Time      `gorm:"not null" json:"created_at"`
// 	UpdatedAt time.Time      `json:"updated_at"`
// 	DeletedAt gorm.DeletedAt `gorm:"index" json:"deleted_at"`
// 	Language  Language       `gorm:"default:en" json:"language"`

// 	OrganizationID uint           `gorm:"index;not null" json:"organization_id"`
// 	Title          string         `gorm:"not null" json:"title"`
// 	Description    string         `json:"description"`
// 	Variants       pq.StringArray `gorm:"type:text[]" json:"variants"`
// 	SelectorItems  []SelectorItem `gorm:"foreignKey:SelectorID" json:"items"`
// 	//
// 	OwnerType string `gorm:"index;not null" json:"owner_type"`
// 	OwnerID   uint   `gorm:"index;not null" json:"owner_id"`
// }


// type SelectorItem struct {
// 	ID        uint           `gorm:"primaryKey;not null" json:"id"`
// 	CreatedAt time.Time      `gorm:"not null" json:"created_at"`
// 	UpdatedAt time.Time      `json:"updated_at"`
// 	DeletedAt gorm.DeletedAt `gorm:"index" json:"deleted_at"`
// 	Language  Language       `gorm:"default:en" json:"language"`

// 	Title       string `gorm:"not null" json:"title"`
// 	Description string `gorm:"not null" json:"description"`
// 	Image       string `gorm:"not null" json:"image"`
// 	SelectorID  uint   `gorm:"not null" json:"selector_id"`
// }