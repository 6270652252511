import PageHeader from "../../Layouts/PageHeader";
import {GrFormFilter, GrFormSearch} from "react-icons/gr";
import React, {useCallback, useContext, useMemo, useState} from "react";
import OverlayModal from "../../Layouts/OverlayModal";
import ViewStateTemplateModal from "./ViewStateTemplateModal";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {StateTemplates_R, StateTemplates_T} from "../../../Common/Types";
import {deleteStateTemplate, getAllStateTemplates} from "../../../apiQuery/State/Template.apis";
import {IoMdAddCircle} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import StateTemplateCard from "../../List/StateTemplateCard";
import {MdModeEditOutline} from "react-icons/md";
import {AiFillDelete, AiOutlineInfoCircle} from "react-icons/ai";
import Prompt from "../../General/Prompt";
import Search from "../../General/Form/Search";
import SkeletonLoader from "../../Helpers/Skeleton";
import PathwayCard from "../../List/PathwayCard";
import {store} from "../../../GlobalState/store";
import Button from "../../General/Button";
import ZeroState from "../../General/ZeroState";
import { withAuth } from "../../../hoc/withAuth";

const StateTemplates = () => {

    const {
        state: globalState
    } = useContext(store);
    const organization_iD = useMemo(() => globalState.organization_id, [globalState.organization_id])

    const [state, setState] = useState({
        modal: false,
        deletePrompt: false,
        callback: () => {
        },
        id: 0
    })
    const [search, setSearch] = useState('')
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const {
        isLoading,
        isError,
        status,
        data,
        error,
        isFetching
    } = useQuery<StateTemplates_R, Error>("state-templates", () => getAllStateTemplates(organization_iD))


    const handleState = useCallback((id: number) => {
        setState(prev => ({...prev, id}))
        handleModal()
    }, [])
    const handleModal = useCallback(() => setState(prev => ({...prev, modal: !prev.modal})), [])

    const {
        mutateAsync,
        isLoading: loadingDelete
    } = useMutation((id: number) => deleteStateTemplate(globalState.organization_id, id), {
        onSuccess: async () => await queryClient.invalidateQueries('state-templates')

    })

    const handleCloseItemConfirmation = useCallback((id = 0) => {
        setState(prev => ({...prev, id, deletePrompt: !prev.deletePrompt}))
    }, [])

    const options = useMemo(() => [
        {
            icon: <IoMdAddCircle/>,
            text: 'Create New StateTemplate Template',
            onClick: () => navigate('/state-templates/create-state-template')
        }
    ], [])

    const optionsOfStateTemplate = useMemo(() => [
        {
            icon: <MdModeEditOutline/>,
            text: 'Edit StateTemplate Template',
            onClick: (ID: number) => navigate(`/state-templates/edit-state-template/${ID}`)
        },
        {
            icon: <AiFillDelete/>,
            text: 'Delete StateTemplate Template',
            onClick: (ID: number) => setState(prev => ({
                ...prev,
                id: ID,
                deletePrompt: !prev.deletePrompt,
                callback: () => ID && mutateAsync(ID)
            }))
            //ID && mutateAsync(ID)
        }
    ], [])

    return (
        <div>
            <PageHeader
                title='State Templates'
                tabs={<></>}
                dotOptionElm={options}
            />

            <div className="w-11/12 py-8 m-auto">
                <Search search={search} onSearch={(e) => setSearch(e.target.value)} placeholder={"Search by name"}/>
                {/*<div className="flex items-center space-x-2">*/}
                {/*    <label className="relative block w-full">*/}
                {/*        <GrFormSearch*/}
                {/*            className="absolute w-8 h-8 transform -translate-y-1/2 pointer-events-none top-1/2 left-3"/>*/}
                {/*        <input value={search} onChange={(e) => setSearch(e.target.value)}*/}
                {/*               className="w-full h-16 leading-tight text-black bg-white border rounded shadow-lg appearance-none border-dark focus:outline-none pl-14"*/}
                {/*               placeholder="Search by name"/>*/}
                {/*    </label>*/}
                {/*    <div className="p-5 border rounded shadow-lg">*/}
                {/*        <GrFormFilter className="text-2xl"/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="py-4">
                    {isLoading ? (
                            <SkeletonLoader/>
                        ) :
                        isError ? (
                            <span>Error: {error?.message || "Something went wrong!"}</span>
                        ) : data ?
                            data?.data?.length === 0 ?
                                <ZeroState name={"Create a new StateTemplate Template"} handleState={() => navigate('/state-templates/create-state-template')}/>
                                :
                                (
                                    <>
                                        <div className="grid grid-cols-2 gap-5 py-5">
                                            {
                                                data?.data?.map(({
                                                                     id,
                                                                     name,
                                                                     description,
                                                                    //  organization_id,
                                                                     linked_flows_count,
                                                                     content_template_count,
                                                                 }, index) => {
                                                    // if (organization_iD !== organization_id) return

                                                    if (!name.toLowerCase().includes(search.toLowerCase())) return

                                                    return <PathwayCard key={index} id={id} title={name}
                                                                        onClick={() => handleState(id || 0)}
                                                                        dotOptionElm={optionsOfStateTemplate}
                                                                        linked_flows_count={linked_flows_count}
                                                                        contents={content_template_count}
                                                                        description={description}/>
                                                    // <StateTemplateCard key={index} ID={ID} name={name}
                                                    //                            optionsOfStateTemplate={optionsOfStateTemplate}
                                                    //                            content_template_count={content_template_count}
                                                    //                            handleState={() => handleState(ID)}
                                                    //                            linked_flows_count={linked_flows_count}/>

                                                })
                                            }
                                        </div>
                                        <div>{isFetching ? "Background Updating..." : " "}</div>
                                    </>
                                ) : null}
                </div>

            </div>
            <OverlayModal onClose={handleCloseItemConfirmation} isOpen={state.deletePrompt}>
                <Prompt
                    title="Delete State Template"
                    description="To delete these Item from your organization, confirm by clicking the button below."
                    confirmButtonText="Delete State Template"
                    cancelButtonText="Cancel"
                    onClickConfirm={() => {
                        state.callback()
                        handleCloseItemConfirmation()
                    }}
                    onClickCancel={handleCloseItemConfirmation}
                />
            </OverlayModal>
            <OverlayModal onClose={handleModal} isOpen={state.modal}>
                <ViewStateTemplateModal modalHandle={handleModal} currentId={state.id}/>
            </OverlayModal>
        </div>
    );
};

export default withAuth(StateTemplates);