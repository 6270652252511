export const ActiveTimerIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className={`custom__icon ${className || ""}`}
      viewBox="0 0 8.582 10"
    >
      <path
        fill="currentColor"
        d="M8.861 2.715V2h2.861v.715zm1.072 5.328h.715V5.3h-.715zM10.291 12a4.122 4.122 0 01-1.663-.34A4.354 4.354 0 016.34 9.372a4.239 4.239 0 010-3.325 4.354 4.354 0 012.288-2.289 4.122 4.122 0 011.663-.34 4.215 4.215 0 011.5.262 4.058 4.058 0 011.246.739l.608-.608.513.513-.608.608a4.4 4.4 0 01.733 1.156 3.948 3.948 0 01.3 1.621 4.122 4.122 0 01-.34 1.663 4.354 4.354 0 01-2.288 2.288 4.122 4.122 0 01-1.664.34z"
        transform="translate(-6 -2)"
      />
    </svg>
  );
};

ActiveTimerIcon.defaultProps = {
  className: "",
};
