import React, { createContext, useReducer, useEffect, useRef, useState, useMemo } from "react";

import { Actions, ActionType } from "./store.actions";
import { FlowState, Individuals, Workers } from "../Common/Types";
import { CurrentFlow } from "./types";
import { IWorker } from "../Types/Workers/Worker";
import { IPathways } from "../Types/Pathways/Pathways";
import { EnumContentType } from "../Types/ContentTemplate/ContentType";
import { EnumActionType } from "../Types/ActionTemplate/ActionTemplate";
import { ConnectionSocket } from "../services";
import { getToken } from "../utils/LocalStorage";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { INotification } from "../Types";
import { toast, ToastTypesEnum } from "../utils/Toast";

interface IStoreState {
  organization_id: number;
  stateFlow: FlowState[];
  individuals: Individuals[];
  currentFlow: CurrentFlow;
  loading: boolean;
  snackbar: {
    isOpen: boolean;
    type: "success" | "error";
    message: string;
  };
  persistenceType: string;
  currentWorker: IWorker | null;
  selectedPathway: IPathways | null;
  addContentTemplateModal: IAddContentTemplateModal | null
  addActionTemplateModal: IAddActionTemplateModal | null
}

interface IAppContext {
  state: IStoreState;
  dispatch: React.Dispatch<Actions>;
}

export interface IAddContentTemplateModal {
  template_id: number,
  state_slug: string,
  pathway_slug: string,
  is_template: boolean,
  content_type: EnumContentType,
}

export interface IAddActionTemplateModal {
  template_id: number,
  action_type: EnumActionType
}

const initialState: IStoreState = {
  organization_id: 1,
  stateFlow: [],
  individuals: [],
  currentFlow: {
    id: 0,
    name: "",
    description: "",
    slug: "",
    // organization_id: 0,
    entry_state_id: null,
    status: false,
    states: [],
    state_time_conditions: null,
  },
  loading: false,
  snackbar: {
    isOpen: false,
    type: "success",
    message: "",
  },
  persistenceType: "localStorage",
  currentWorker: null,
  selectedPathway: null,
  addContentTemplateModal: null,
  addActionTemplateModal: null,
};

const store = createContext<IAppContext>({
  state: initialState,
  dispatch: () => null,
});

const { Provider } = store;

const reducer = (state: IStoreState, action: Actions) => {
  const { stateFlow, individuals, currentFlow, currentWorker } = state;

  switch (action.type) {
    case ActionType.set_pathways:
      return {
        ...state,
        stateFlow: action.payload,
      };
    case ActionType.set_individuals:
      return {
        ...state,
        individuals: action.payload,
      };
    case ActionType.set_current_pathway:
      return {
        ...state,
        currentFlow: action.payload,
      };
    case ActionType.set_organization:
      return {
        ...state,
        organization_id: action.payload,
      };
    case ActionType.set_loading:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionType.set_snackbar:
      return {
        ...state,
        snackbar: action.payload,
      };
    case ActionType.set_currentWorker:
      return {
        ...state,
        currentWorker: action.payload,
      };

    case ActionType.set_selected_pathway:
      return {
        ...state,
        selectedPathway: action.payload,
      };

    case ActionType.set_add_content_template_modal:
      return {
        ...state,
        addContentTemplateModal: action.payload,
      };

    case ActionType.set_add_action_template_modal:
      return {
        ...state,
        addActionTemplateModal: action.payload,
      };

    default:
      return state;
  }
};



const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(reducer, initializeState());

  const initialRenderGlobalState = useRef(true);
  const initialRenderPersistenceType = useRef(true);


  const WS_URL = useMemo(() => {
    const token = getToken();
    const WS_URL = token !== null ? `ws://cravety-server-staging-dki6q33mdq-uc.a.run.app/api/notification/ws/admin?token=${token}` : null;
    return WS_URL
  } ,[])
 

  const { sendJsonMessage, readyState, lastMessage, lastJsonMessage } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log('WebSocket connection established.');
      // toast('kri','success',3500)
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true,
    onMessage: (event) => {
      // console.log('event',event.data)
      const notification = JSON.parse(event.data) as INotification

      if(notification.action){
        // const action = notification.action.endsWith('Created') ? 'created' ? notification.action.endsWith('Edited') : 'updated' : 'deleted'
          const message = `${notification.title}: ${notification.notification_msg}`
          toast(message,'info',3500)
      }
      }
    }
  );

  // useEffect(() => {
  //   if (readyState === ReadyState.OPEN) {
  //     console.log('hello message');
  //     // console.log('ready open',lastJsonMessage)
  //     // console.log('ready open',lastMessage)
  //     // sendJsonMessage({ type: 'message', data: 'Hello!' });
  //   }

    
  // },[readyState])
  
  // console.log('ready',lastJsonMessage)
  // console.log('ready',lastMessage)

  // const [socketUrl, setSocketUrl] = useState('wss://echo.websocket.org');

   // ===========================
  // const ws = useRef<WebSocket>(new WebSocket(`ws://cravety-server-staging-dki6q33mdq-uc.a.run.app/api/notification/ws/admin?token=${getToken()}`)).current;


  // useEffect(() => {
  //   const serverMessagses = [] 
  //   ws.onopen = () => {
  //     console.log('Connected to the server')
  //     // setDisableButton(false);
  //   };
  //   ws.onclose = (e) => {
  //     console.log('Disconnected. Check internet or server.')
  //     // setDisableButton(true);
  //   };
  //   ws.onerror = (e) => {
  //     // setServerState(e.message);
  //     console.log("error", e)
  //   };
  //   ws.onmessage = (e) => {
  //     // serverMessagesList.push(e.data);
  //     // setServerMessages([...serverMessagesList])
  //     console.log('message', e.data)
  //   };
  // } ,[])


  
  
  // const [socket, setSocket] = useState<ConnectionSocket | null>(null);




  // useEffect(() => {
  //   const socketConnection = new ConnectionSocket();

  //   socketConnection.onOpen(() => console.log('its connected!'))
  //   socketConnection.onMessage(event => {
  //    
  //   })
  //   setSocket(socketConnection);

  //   return () => {
  //     if (socketConnection) {
  //       socketConnection.disconnect();
  //     }
  //   };
  // }, []);


  useEffect(() => {
    /*
         populate either sessionStorage or localStorage
         data from globalState based on persistenceType
        */
    if (initialRenderGlobalState.current) {
      initialRenderGlobalState.current = false;
      return;
    }
    const getPersistenceType = state.persistenceType;
    if (getPersistenceType === "sessionStorage") {
      sessionStorage.setItem("globalState", JSON.stringify(state));
    } else if (getPersistenceType === "localStorage") {
      localStorage.setItem("globalState", JSON.stringify(state));
    }
  }, [state]);

  useEffect(() => {
    /*
         purge sessionStorage or localStorage when either is selected
        */
    if (initialRenderPersistenceType.current) {
      initialRenderPersistenceType.current = false;
      return;
    }
    const getPersistenceType = state.persistenceType;
    if (getPersistenceType === "sessionStorage") {
      localStorage.removeItem("globalState");
    } else if (getPersistenceType === "localStorage") {
      sessionStorage.removeItem("globalState");
    }
  }, [state.persistenceType]);

  // useStoreSideEffect(state, dispatch);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

function initializeState() {
  /*
     the order in which the the data is compared is very important;
     first try to populate the state from Storage if not return initialState
    */

  if (typeof Storage !== "undefined") {
  } else {
    throw new Error("You need to enable Storage to run this app.");
  }

  const fromLocalStorage = JSON.parse(
    localStorage.getItem("globalState") as string
  );
  // const fromSessionStorage = JSON.parse(sessionStorage.getItem('globalState') as string);
  // return fromSessionStorage || fromLocalStorage || initialState;
  return {
    ...initialState,
    ...fromLocalStorage,
    loading: false,
  };
}

export { store, AppProvider };
