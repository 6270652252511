import { ReactNode } from "react";

export enum SelectedModalEnum {
  none = "none",
  content = "content",
  state = "state",
  action = "action",
}

export interface IFilterItem {
  id: number;
  field: SelectedModalEnum;
  label: string;
  condition: string;
  value: string | boolean;
  type: "text" | "date" | "select";
  icon: ReactNode;
}
