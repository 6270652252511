import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";

// for the create and update the video
// to create with video content type id is going to be 0
export interface IVideo {
	id?: number
	title: string
	description: string
	body: string
	organization_id?: number
	language: LanguageEnum
	file: string
	updated_at?: string
}


export interface VideoContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	video: IVideo[],
	content_metadata: IContentMetadata[]
}


export type IVideoContentTemplate = {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	video: IVideo[];
}

export type VideoContentResponse = {
	data: IVideoContentTemplate
	error: boolean
	message: string
	status: number
}




export type VideoContentTemplate = {
	video: IVideo
	content_metadata: IContentMetadata
}

export const initialVideoContent: VideoContentTemplate = {
	video: {
		id: 0,
		title: '',
		description: '',
		body: '',
		language: LanguageEnum.English,
		file: '',
	},
	content_metadata: {
		id: 0,
		cover_image: '',
		preview_title: '',
		tags: [''],
		language: LanguageEnum.English,
		alt_title: '',
	}
}