import { EnumActionType, LanguageEnum } from "./ActionTemplate";
import { IActionMetadata } from "./ActionTemplate";

export interface IALNotification {
  title: string;
  al_message: string;
  language: LanguageEnum;
}
export interface AlNotificationActionCreateOrUpdateRequest {
  action_type: EnumActionType;
  alNotification: IALNotification[];
  action_metadata: IActionMetadata[];
}

export type ALNotificationActionTemplate = {
  alNotification: IALNotification;
  action_metadata: IActionMetadata;
};

export const initialAlNotificationContent: ALNotificationActionTemplate = {
  alNotification: {
    language: LanguageEnum.English,
    title: "",
    al_message: "",
  },
  action_metadata: {
    id: 0,
    organization_id: 0,
    language: LanguageEnum.English,
    action_type: "",
  },
};

export interface ALNotificationActionResponse {
  data: ALNotificationMetaData[];
  error: boolean;
  message: string;
  status: number;
}

export interface ALNotificationMetaData {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  language: string;
  title: string;
  al_message: string;
  organization_id: number;
  owner_type: string;
  owner_id: number;
}
