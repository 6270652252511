import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import AppInput from "../../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../../Theme";
import { object, string } from 'yup';
import { toast } from "../../../../../utils/Toast";
import axios from "axios";
import AppDropDown from "../../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../../Context/UserContext/UserContext";
import { getToken } from "../../../../../utils/LocalStorage";


interface IWorker {
  email_address: string
  phone_number: string
  first_name: string
  last_name: string
  primary_location_address: string
  job_family: string
  is_worker: boolean
  is_employee: boolean
  organization_id: number
}


const workerSchema = object({
  email_address: string().email().required(),
  phone_number: string().required(),
  first_name: string().required(),
  last_name: string().required(),
  primary_location_address: string(),
  job_family: string(),
})


const DEMO_URL = 'https://pathways-server-demo-7dw557qb5q-uc.a.run.app/api/v1/demo/worker'

const createWorker = async (data: IWorker) => {
  const token = getToken()
  const response = await axios.post(DEMO_URL, data, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return response.data
}

const WorkersTab: React.FC = () => {

  const [formData, updateFormData] = useState<IWorker>()
  const [errors, setErrors] = useState<any>({})
  const [selectedValue, setSelectedValue] = useState<any>();
  const { currentOrganizationID } = useContext(UserContext)



  const createAWorkerMutation = useMutation((data: IWorker) => createWorker(data), {
    onSuccess: () => {
      toast('Worker Created Successfully', 'success')
    }
  })


  const submitFormHandler = async (event: React.FormEvent) => {

    event.preventDefault();

    try {
      await workerSchema.validate(formData!, { abortEarly: false })
      createAWorkerMutation.mutateAsync({
        email_address: formData!.email_address,
        phone_number: formData!.phone_number,
        first_name: formData!.first_name,
        last_name: formData!.last_name,
        primary_location_address: formData?.primary_location_address || '',
        job_family: formData?.job_family || '',
        is_employee: formData?.is_employee || false,
        is_worker: formData?.is_worker || false,
        organization_id: currentOrganizationID()


      })

    } catch (error: any) {
      // show the error to the user
      if (error.inner) {
        setErrors(error.inner)
      }
      console.log('error', error)
    }

  }



  return (
    <div className="flex flex-col gap-5 mb-12">
      <div className="mb-2">
        <h2 className="text-2xl font-normal text-textDark">Workers</h2>
      </div>
      <hr className="h-px border-1 border-borderGray dark:border-textDark" />
      <form onSubmit={submitFormHandler} className="flex flex-col gap-4">

        <AppInput type="email" tabIndex={-1} autoFocus placeholder="Email Address"
          showStar
          onChange={e => {
            updateFormData({ ...formData!, email_address: e.target.value })
          }}
          value={formData?.email_address || ''} name='email_address' isFull label='Email Address' required />

        <AppInput type="text" tabIndex={0} placeholder="Phone Number"
          showStar
          onChange={e => {
            updateFormData({ ...formData!, phone_number: e.target.value })
          }}
          value={formData?.phone_number || ''} name='phone_number' isFull label='Phone Number' required />

        <AppInput type="text" tabIndex={1} placeholder="First Name"
          showStar
          onChange={e => {
            updateFormData({ ...formData!, first_name: e.target.value })
          }}
          value={formData?.first_name || ''} name='first_name' isFull label='First Name' required />

        <AppInput type="text" tabIndex={2} placeholder="Last Name"
          showStar
          onChange={e => {
            updateFormData({ ...formData!, last_name: e.target.value })
          }} value={formData?.last_name || ''} name='last_name' isFull label='Last Name' required />

        <AppInput type="text" showStar tabIndex={3} placeholder="Organization"
          onChange={e => {
            updateFormData({ ...formData!, primary_location_address: e.target.value })
          }} value={formData?.primary_location_address || ''} name='primary_location_address' isFull label='Organization' />


        <AppInput type="text" tabIndex={4} showStar placeholder="Role"
          onChange={e => {
            updateFormData({ ...formData!, job_family: e.target.value })
          }} value={formData?.job_family || ''} name='job_family' isFull label='Role' />
        <AppDropDown
          extendClass="mt-1.5"
          isFull
          name="type"
          options={[
            { label: "Choose a role", value: "0" },
            { label: "Candidate", value: "1" },
            { label: "Employee", value: "2" },
          ]}
          value={selectedValue}
          onChange={
            (e) => {
              setSelectedValue(e.target.value);
              if (e.target.value === "1") {
                updateFormData({
                  ...formData!,
                  is_worker: true,
                  is_employee: false
                });
              } else if (e.target.value === "2") {
                updateFormData({
                  ...formData!,
                  is_employee: true,
                  is_worker: false,

                });
              }
            }

          }
        />


        <div>
          <AppButton tabIndex={5} size="md" variant="primary" type="submit" extendClass="px-3">
            <div className="flex items-center justify-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
              >
                <path
                  fill="currentColor"
                  d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
              </svg>
              Save
            </div>
          </AppButton>
        </div>


      </form>
    </div>
  );
};

export default WorkersTab;
