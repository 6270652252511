export const DeactiveTimerIcon = (props: { className?: string }) => {
  const { className } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      className={`custom__icon ${className || ''}`}
      viewBox='0 0 9.19 10'
    >
      <path
        fill='currentColor'
        d='M11.542 12l-1.2-1.2a4.349 4.349 0 01-1.2.677 4.162 4.162 0 01-3.009-.093 4.228 4.228 0 01-2.224-2.225 4 4 0 01-.33-1.615 3.982 3.982 0 01.914-2.593L2.85 3.308l.5-.5 8.69 8.692zM6.357 2.694V2h2.778v.694zm4.907 7.049L8.093 6.572V5.206H7.4v.671L5.535 4.014a3.918 3.918 0 011.059-.475 4.213 4.213 0 011.152-.162 4.012 4.012 0 012.674.984l.59-.59.486.486-.59.59a3.632 3.632 0 01.763 1.253 4.3 4.3 0 01.243 1.441 4.232 4.232 0 01-.156 1.14 3.621 3.621 0 01-.492 1.062z'
        transform='translate(-2.85 -2)'
      />
    </svg>
  )
}

DeactiveTimerIcon.defaultProps = {
  className: ''
}
