import { FC, useEffect, useState } from "react";
import {
  Field,
  QueryBuilder,
  RuleGroupType,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { FieldSelector } from "./FieldSelector";

const MAX_NESTING_LEVEL = 1;

export interface QueryBuilderContainerProps {
  fields: Field[],
  query: RuleGroupType;
  setQuery: (query: RuleGroupType) => void;
}

export const QueryBuilderContainer:FC<QueryBuilderContainerProps> = (props) => {

  const [inAddRule, setOnAddrule] = useState(false);

  useEffect(() => {
    const elements = document.querySelectorAll(".ruleGroup");
    for (let i = 1; i < elements.length; i++) {
      elements[i]?.parentNode?.appendChild(elements[i]);
    }



    // const ruleCheckbox = document.querySelector(".0golam");
    // ruleCheckbox?.classList.remove("0golam");
    // ruleCheckbox?.classList.add(
    //   "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
    // );
  }, [inAddRule]);

  useEffect(() => {
    // const thirdBtnAddgroup = document.querySelectorAll('div[data-level="2"]');
    // for (let i = 0; i < thirdBtnAddgroup.length; i++) {
    //   thirdBtnAddgroup[i].classList.add("darkerthree");
    //   const btn = thirdBtnAddgroup[i]?.children[0];
    //   if (btn.childElementCount === 4) btn.children[2]?.remove();
    // }


    const thirdBtnAddgroup = document.querySelectorAll('div[data-level="2"]');

    for (let i = 0; i < thirdBtnAddgroup.length; i++) {
      thirdBtnAddgroup[i].classList.add("darkerthree");
      const btn = thirdBtnAddgroup[i]?.children[0];
      if (btn.childElementCount === 4) {
        btn.children[2]?.remove();
        thirdBtnAddgroup[i].children[0].children[0].remove();
      }
    }

    const ruleAddGroup = document.querySelectorAll(".ruleGroup-addGroup");
    for (let i = 0; i < ruleAddGroup.length; i++) {
      ruleAddGroup[i].innerHTML = "+ Add a Group";
    }
    if (ruleAddGroup.length > 1) {
      ruleAddGroup[ruleAddGroup.length - 1].remove();
    }

    const ruleAddRule = document.querySelectorAll(".ruleGroup-addRule");
    for (let i = 0; i < ruleAddRule.length; i++) {
      ruleAddRule[i].innerHTML = "Add Rule to this Group";
    }
    const ruleGroupHeader = document.querySelectorAll(".ruleGroup");
    const ruleGroupCombinators = document.querySelectorAll(
      ".ruleGroup-combinators"
    )[0];
    if (ruleGroupCombinators) ruleGroupCombinators.classList.add("backwhite");
    for (let i = 1; i < ruleGroupHeader.length; i++) {
      ruleGroupHeader[i].classList.add("relative");
    }
  }, [props.query]);

  const handleQueryChange = (q: RuleGroupType) => {
    if (hasExceededNestingLevel(q, MAX_NESTING_LEVEL)) {
      alert("You are not allowed to have more nested groups.");
    } else {
      props.setQuery(q)
    }
  };

  const hasExceededNestingLevel = (
    group: RuleGroupType,
    maxLevel: number,
    currentLevel = 0
  ): boolean => {
    if (currentLevel > maxLevel) {
      return true;
    }
    if (group.rules) {
      for (const rule of group.rules) {
        if (
          "combinator" in rule &&
          rule.rules &&
          hasExceededNestingLevel(rule, maxLevel, currentLevel + 1)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const handelOnAddGroup: any = (g: RuleGroupType) => {
    setOnAddrule((prev) => !prev);
    return g;
  };

  return (
    <div className="query-wraper">
      <QueryBuilder
        fields={props.fields}
        query={props.query}
        onQueryChange={handleQueryChange}
        onAddRule={handelOnAddGroup}
        controlElements={{
          fieldSelector: FieldSelector,
        }}
      />
    </div>
  );
};
