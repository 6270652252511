import API from ".."
import { IContentTemplateFilterResponse } from "../../Types/ContentTemplate/ContentTemplate"
import { EnumContentType } from "../../Types/ContentTemplate/ContentType"



export const getStateContentTemplateFilter = async <T, R extends EnumContentType>
	(
		pathway_slug: string,
		state_slug: string,
		content_type: EnumContentType, limit: number = 5, search: string = '', next: string = '', previous: string = '', pageDirection: string = '')
	: Promise<IContentTemplateFilterResponse<T, R>> => {

	const nextParams = next ? `&next=${next}` : ''
	const previousParams = previous ? `&previous=${previous}` : ''

	let params = ''
	if (pageDirection === 'next') {
		params = nextParams
	} else if (pageDirection === 'previous') {
		params = previousParams
	}

	const response: IContentTemplateFilterResponse<T, R> = await API.post(`/admin/organizations/pathways/${pathway_slug}/states/not/${state_slug}/content-template/filter?limit=${limit}${params}`,
		{
			"content_type": content_type,
			"search": search
		})

	return response;
}