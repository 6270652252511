import { FC, useContext, useEffect, useReducer, useRef, useState } from "react";
import { TabContentLayout } from "../TabContentLayout";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import UseUploadFile from "../../../../Hook/UseUploadFile";
import UseUpload from "../../../../Hook/UseUpload";
import { baseURL } from "../../../../apiQuery";
import UploadProgress from "../../../General/UploadProgress";
import { toast } from "../../../../utils/Toast";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { createContentTemplate } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { useNavigate, useParams } from "react-router-dom";
import { ISurveyItem, SurveyContentCreateOrUpdateRequest, SurveyContentTemplate, initialSurveyContent } from "../../../../Types/ContentTemplate/Survey";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { LanguagesOptions } from "../../../../utils/Languages";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd"
import { InlineSurveyItem } from "./InlineItem"
import { reorder } from "../../../Utils/Reorder"
import { v4 as uuidv4 } from 'uuid';
import { survey_items } from "../../../../Common/Types"


const NewSurvey: FC = () => {
  const params = useParams<{ templateSlug: string }>()
  const navigate = useNavigate();

  const { currentOrganizationID } = useContext(UserContext);

  const [surveyContent, setSurveyContent] = useState<SurveyContentTemplate>(initialSurveyContent)
  const createOrUpdateButton = useRef<string>("Create")
  const allSurveyContentsRef = useRef(null)
  const createOrUpdateItemButton = useRef<string>("Create")

  const [showInput, setShowInput] = useState({ appId: null, isInput: false });



  //=====================  Upload Cover  ========================
  const [imageSrc, file, render, name] = UseUploadFile('Survey')
  const { uploadForm, progress, isLoading } = UseUpload(
    `${baseURL}upload`
  );

  const [uploadState, setUploadState] = useState({
    file,
    name,
  })

  useEffect(() => {
    if (name && file) {
      setUploadState({
        file,
        name,
      })
    }
  }, [name, file])
  // ============================ Mutation ================================

  const createSurveyContentMutation = useMutation((param: SurveyContentCreateOrUpdateRequest) => createContentTemplate(param), {
    onSuccess: async (data) => {
      toast("Content created successfully", "success")
      const id = data?.data?.id
      if (id) {
        navigate(`/content-templates/${params.templateSlug}/view/${id}`)
      }
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message || "Something went wrong!"
      toast(message, "error")
    }
  })

  //=====================  Handlers  =================================
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }
    const survey_item = surveyContent.survey.survey_items || []
    let itemsData = reorder(
      survey_item,
      result.source.index,
      result.destination.index
    ) as survey_items[]

    itemsData = itemsData.map((item, ind) => ({
      ...item,
      order: ind
    }))
    // @ts-ignore
    // setWorkerFavorites(updatedFavorites);
    setSurveyContent((prev) => {
      return {
        ...prev,
        survey: {
          ...prev.survey,
          survey_items: itemsData,
        },
      }
    })

    // setSurveyContent(prev => {
    //   return {
    //     ...prev,
    //     checklist: {
    //       ...prev.checklist,
    //       items: updatedFavorites
    //     }
    //   }
    // })
  }

  // ======================  Submit Article  ========================
  const submitFormHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    if (surveyContent.survey.title === '') {
      toast('Please enter a title', 'error')
      return
    }


    if (surveyContent.survey.survey_items.length === 0) {
      toast('Please add at least one survey item', 'error')
      return
    }

    let _cover_imageLink = ''

    if (uploadState.file !== undefined) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", uploadState.file);
      const { link } = await uploadForm(formData)
      // setArticleContent(prev => ({...prev, cover_image: link}))
      _cover_imageLink = link
    }

    const consentContentWithImage: SurveyContentTemplate = {
      survey: surveyContent.survey,
      content_metadata: {
        ...surveyContent.content_metadata,
        cover_image: _cover_imageLink,
      }
    }

    // setState(prev => [...prev, consentContentWithImage])
    const newState = [consentContentWithImage]


    const shapeData: SurveyContentCreateOrUpdateRequest = {
      content_type: EnumContentType.SurveyContent,
      survey: newState.map(item => {
        return {
          title: item.survey.title,
          alt_title: item.survey.alt_title,
          description: item.survey.description,
          order: 0,
          survey_items: item.survey.survey_items.map((item) => ({
            label: item.label,
            value: item.value,
            language: item.language,
            order: item.order
          })),
          // language: item.survey.language as LanguageEnum,
          language: LanguageEnum.English,
        }
      }),
      content_metadata: newState.map(item => {
        return {
          cover_image: item.content_metadata.cover_image,
          preview_title: item.content_metadata.preview_title,
          tags: item.content_metadata.tags,
          organization_id: currentOrganizationID(),
          // language: item.content_metadata.language as LanguageEnum,
          language: LanguageEnum.English,
          alt_title: item.content_metadata.alt_title,
        }
      })
    }

    await createSurveyContentMutation.mutateAsync(shapeData)
  }

  const [item, setItem] = useState<ISurveyItem>({
    appId: uuidv4(),
    value: 0,
    label: '',
    order: 0,
    language: LanguageEnum.English,
  })

  const addItemToSurveyItems = () => {
    if (item.label === '') {
      toast('Please enter a label', 'error')
      return
    }

    setSurveyContent(prev => {
      return {
        ...prev,
        survey: {
          ...prev.survey,
          survey_items: [...prev.survey.survey_items, { ...item, appId: uuidv4(), order: surveyContent.survey.survey_items.length }]
        }
      }
    })

    setItem((prev) => ({
      appId: uuidv4(),
      value: 0,
      label: '',
      order: 0,
      language: LanguageEnum.English,
    }))
    createOrUpdateItemButton.current = "Create"
  }

  return <TabContentLayout>

    <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
      <h1 className="pb-8 text-xl">New Survery Content Template</h1>

      <h1 className="pb-8 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{surveyContent.survey.title || ''}</h1>

      <AppInput tabIndex={-1} autoFocus placeholder={"Title"} value={surveyContent.survey.title} name='title'
        isFull label='Title' required
        onChange={
          (e) => setSurveyContent({ ...surveyContent, survey: { ...surveyContent.survey, title: e.target.value } })
        }
      />
      <AppTextArea placeholder={"Description"} rows={4} value={surveyContent.survey.description} name='description'
        onChange={
          (e) => setSurveyContent({ ...surveyContent, survey: { ...surveyContent.survey, description: e.target.value } })
        }
        label='Description' />

      {/* <AppInput placeholder={"Alternative Title"} value={surveyContent.content_metadata.alt_title} name='alt_title' isFull
        onChange={e => {
          setSurveyContent({ ...surveyContent, content_metadata: { ...surveyContent.content_metadata, alt_title: e.target.value } })
        }} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}


      <AppInput placeholder={"Preview Title"} value={surveyContent.content_metadata.preview_title} name='preview_title' isFull
        onChange={e => {
          setSurveyContent({ ...surveyContent, content_metadata: { ...surveyContent.content_metadata, preview_title: e.target.value } })
        }} label='Preview Title' extendClass='my-2' maxLength={140} required />

      <AppInput placeholder={"Tag"} value={surveyContent.content_metadata.tags[0]} name='tags' isFull
        onChange={e => {
          setSurveyContent({ ...surveyContent, content_metadata: { ...surveyContent.content_metadata, tags: [e.target.value] } })
        }} label='Tag' extendClass='my-2' />




      {/* New code ZZZ */}
      <div className='p-2 my-2 border border-gray-200 rounded shadow-sm '>
        <p className='py-2 pl-1 font-semibold text-textDark'>Questions</p>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="grid grid-cols-1 gap-2 mb-2">
                {surveyContent.survey.survey_items.map((item: ISurveyItem, idx: number) => (
                  <Draggable key={item.appId} draggableId={`item-${item.appId}`} index={idx}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex flex-col "
                      >
                        <InlineSurveyItem
                          surveys={surveyContent}
                          item={item}
                          setSurvey={setSurveyContent}
                          showInputAppId={showInput.appId}
                          showInput={showInput}
                          setShowInput={setShowInput}
                        />

                      </div>)}
                  </Draggable>


                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* ADD new Question */}

        <div className='flex flex-col items-start justify-start w-[90%] p-2 m-1 border rounded bg-hoverLightBlue gap-4'>
          <p className='py-2 pl-1 font-semibold'>Survey Items</p>


          {/*  Survey Items */}
          <div className="flex flex-col items-start justify-start w-5/6 p-2">
            <AppInput label="Label" placeholder={"Label"} value={item.label} name='label'
              onChange={e => setItem(prev => { return { ...prev, label: e.target.value } })} extendClass='mb-2' isFull />
            <AppInput label="Value" type="number" placeholder={"Value"} value={String(item.value)} name='value'
              onChange={e => setItem(prev => { return { ...prev, value: Number(e.target.value) } })} extendClass='mb-2' isFull />
            {/* <AppDropDown label={"Language"} isFull disabled={true}
            options={LanguagesOptions} value={surveyContent.survey.language} name="language"
            onChange={e => setItem(prev => { return { ...prev, language: e.target.value as LanguageEnum } })} /> */}

            <AppButton variant={createOrUpdateItemButton.current === 'Create' ? 'success' : 'info'} type={"button"}
              size={"sm"} onClick={addItemToSurveyItems} extendClass="mx-2">{createOrUpdateItemButton.current} Survey Item</AppButton>
          </div>
        </div>
      </div>





      {/* Survey Items */}


      {/* Survey Items */}



      {surveyContent.content_metadata.cover_image ?
        <div className="cover__image-container">

          <img alt="conver_image" src={surveyContent.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
        </div> : null}
      {render}
      {uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}
      {isLoading ? <UploadProgress progress={progress} /> : null}

      {/* <AppDropDown label={"Language"}
        options={LanguagesOptions} value={surveyContent?.survey?.language || ""} name="language"
        onChange={
          (e) => setSurveyContent({ ...surveyContent, survey: { ...surveyContent.survey, language: e.target.value as LanguageEnum } })

        } /> */}
      <div className='flex w-full gap-2 mt-3 mb-3'>
        <AppButton disabled={isLoading || createSurveyContentMutation.isLoading} variant="primary" type="submit" extendClass="px-3">
          <div className="flex items-center justify-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 96 960 960"
              className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
            >
              <path
                fill="currentColor"
                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
            </svg>
            Create Content Template          </div>
        </AppButton>
      </div>
    </form>

  </TabContentLayout>
}

export default NewSurvey;