import React, { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useRef, useState } from 'react'
import { TabActionLayout } from "../TabActionLayout";
import { LanguageEnum, IActionTemplate } from '../../../../Types/ActionTemplate/ActionTemplate';
import { useTranslation } from "react-i18next";
import { LanguagesOptions, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumActionType } from "../../../../Types/ActionTemplate/ActionTemplate";
import { IActionMetadata } from "../../../../Types/ActionTemplate/ActionTemplate";
import { useMutation, useQuery } from "react-query";
import { deleteActionTemplateById, getActionTemplateById, updateActionTemplateById } from "../../../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
// import EmailItem from "./EmailItem";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import ViewActionTemplateHeader from '../../ViewActionTemplateHeader';
import { EmailAction, EmailActionCreateOrUpdateRequest } from '../../../../Types/ActionTemplate/Email';
import { AppButton, LanguageIcon, PopupModal } from '../../../../Theme';
import { useNavigate } from 'react-router-dom';

export type Language = "en" | "fr" | "es"

// export interface EmailAction {
// 	subject: string
// 	body: string
// 	language: Language
// }

// interface EmailActionCreateOrUpdateRequest {
// 	action_type: EnumActionType,
// 	action_metadata: IActionMetadata[],
// }

const initialEmailAction: EmailAction = {
	subject: '',
	body: '',
	language: LanguageEnum.English,
	importance: 0
}
interface EmailTabActionProps {
	templateId: number,
}

const EmailTabAction: FC<EmailTabActionProps> = (props) => {

	const navigate = useNavigate();
	const { currentOrganizationID } = useContext(UserContext);


	const { t } = useTranslation(); // TODO: use this for language

	const [state, setState] = useState<EmailAction[]>([])


	const [emailAction, setEmailAction] = useState<EmailAction>(initialEmailAction)



	const createOrUpdateButton = useRef<string>("Create")
	const allEmailActionsRef = useRef(null)
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')




	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	
	// ========================================================================================================================

	const { data: emailData, refetch } = useQuery<any, Error>
		(`email-content-${props.templateId}`, () => getActionTemplateById(props.templateId));


	useEffect(() => {
		if (emailData) {
			const newState = emailData?.data.action_metadata.map((item: any) => {
				return {
					subject: item.subject,
					body: item.body,
					language: item.language
				}
			})
			setState(newState)
		}
	}, [emailData])

	useEffect(() => {
		if (selectedLanguage === '') return
		// get seleted index of the content template to be displayed as the current content
		const selectedIndex = emailData?.data.action_metadata.findIndex((item: any) => item.language === selectedLanguage) as number
		const email = emailData?.data.action_metadata[selectedIndex]
		setEmailAction({
			subject: email.subject,
			body: email.body,
			language: email.language,
			importance: emailData.data.importance
		})
		createOrUpdateButton.current = "Update"
	}, [selectedLanguage])

	
	const deleteTemplateFromContentTemplateList = async () => {
		deleteActionTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/action-templates/email', { state: { deleted: true } })
	}



	//=====================================================
	//make english default language for content
	useEffect(() => {
		const email = emailData?.data.action_metadata.find((item: any) => item.language === "en")
		if (email) {
			setEmailAction({
				subject: email.subject,
				body: email.body,
				language: email.language,
				importance: emailData?.data.importance
			})
			createOrUpdateButton.current = "Update"
			setSelectedLanguage("en")
		}
	}, [emailData])

	// ================================================== Mutations =========================================================	s


	const deleteActionTemplateByID = useMutation((params: { id: number }) =>
	deleteActionTemplateById(params.id), {


	onSuccess: async (data) => {
		toast("Template deleted successfully", "success")

	},
	onError: (error: any) => {
		const message = error?.response?.data?.message || "Something went wrong!"
		toast(message, "error")
	}
    })

	const updateEmailActionMutation = useMutation(
		(params: { id: number, data: EmailActionCreateOrUpdateRequest }) => updateActionTemplateById(params.id, params.data), {
		onSuccess: async (data) => {
			toast("Action updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})



	// handlers
	// TODO: Write a global handle change function
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setEmailAction(prev => ({ ...prev, [name]: value }))
	}


	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (emailAction.subject === '' || emailAction.body === '') {
			toast('Please fill all fields', 'error')
			return
		}

		if (createOrUpdateButton.current === "Create") {
			if (state.some(item => item.language === emailAction.language)) {
				toast('This email is already exist', 'error')
				return
			}

			// setState(prev => [...prev, emailAction])
			const newState = [...state, emailAction]
			await createOrUpdateContentTemplate(newState)

		} else {
			// update the state
			const newState = state.map(item => {
				if (item.language === emailAction.language) {
					return emailAction
				}
				return item
			})

			setState(newState)
			createOrUpdateButton.current = "Create"
			await createOrUpdateContentTemplate(newState)
		}

		// 	empty the fields
		setEmailAction({
			subject: '',
			body: '',
			language: LanguageEnum.English,
			importance: 0
		})

	}

	const createOrUpdateContentTemplate = async (data: any) => {
		if (data.length === 0) {
			toast('Please add at least one email template', 'info')
			return
		}

		const action_metadata: IActionMetadata[] = data.map((item: any) => {
			return {
				...item.action_metadata,
				organization_id: currentOrganizationID(),
				language: item.language,
				subject: item.subject,
				body: item.body
			}
		})
		const shapeData: EmailActionCreateOrUpdateRequest = {
			action_type: EnumActionType.EmailAction,
			action_metadata: action_metadata,
			importance: emailAction.importance
		}

		await updateEmailActionMutation.mutateAsync({ id: props.templateId, data: shapeData })

		setState([])
		setSelectedLanguage('')
		setEmailAction(initialEmailAction)
	}


	const editableEmailAction = (emailAction: IActionTemplate) => {
		
		const items: EmailAction[] = emailAction.action_metadata.map((item: any) => {
			return {
				subject: item.subject,
				body: item.body,
				language: item.language,
				importance: item.importance
			}
		})
		setState(items)
	}


	return (
		<Fragment>
		
		<TabActionLayout>
			<ViewActionTemplateHeader
				title={emailData?.data.action_metadata[0].subject || ''}
				referencesCount={0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{

					emailData?.data.action_metadata.map((item: any, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setEmailAction(initialEmailAction)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										editableEmailAction(emailData.data)
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					state.length < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setEmailAction(initialEmailAction)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								+ New Language
							</span>

						</AppButton>
					) : null
				}
			</div>

			{/* =========================== */}
			<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{emailAction.subject || ''}</h1>
			<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">
				<div className='flex w-full gap-2 mb-3'>
					<AppButton size="xs" disabled={emailData?.data?.subject === '' || updateEmailActionMutation.isLoading || deleteActionTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Action Template</AppButton>
					<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>Delete Action Template</AppButton>
				</div>
				<AppInput autoFocus placeholder={"Subject"} value={emailAction.subject} name='subject' isFull onChange={handleChange} label='Subject' required />
				<AppTextArea placeholder={"Body"} value={emailAction.body} rows={6} name='body' onChange={handleChange} label='Body' required />
				<AppDropDown
					extendClass="mt-1.5"
					isFull
					label=""
					name="time"
					options={[
						{ label: "Standard", value: "0" },
						{ label: "Priority", value: "1" },
						{ label: "Urgent", value: "2" },
					]}
					value={emailAction.importance.toString()}
					onChange={
						(e) => {
							setEmailAction(prev => {
								return {
									...prev,
									importance: parseInt(e.target.value)
								}
							})
						}
					}
				/>
				<AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"} options={LanguagesOptions} value={emailAction.language} name="language" onChange={handleChange} />
			</form>
		</TabActionLayout>

			<PopupModal
			setOpen={setShowPopupDeleteConfirmation}
			isOpen={showPopupDeleteConfirmation}
			title="Are you sure you want to delete this Email Action Template ?
			Deleting this Action Template will delete all Actions associated with this Template."
			onConfirmClick={deleteTemplateFromContentTemplateList}
		/>

		</Fragment>

	)
}


export default EmailTabAction
