import { FormEvent, memo, useContext, useEffect, useMemo, useState } from "react";
import { AppButton, CloseIcon, LanguageIcon } from "../../../Theme";
import AppInput from "../../Shared/AppInput/AppInput";
import AppTextArea from "../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../Shared/AppDropDown/AppDropDown";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { useMutation, useQuery } from "react-query";
import { getStateFlow } from "../../../apiQuery/StateFlow/State.apis";
import { IPathwayLanguage, IPathwayResponse, IPathways } from "../../../Types/Pathways/Pathways";
import { toast } from "../../../utils/Toast";
import { updateFlowState } from "../../../apiQuery/StateFlow/StateFlow.apis";
import { store } from "../../../GlobalState/store";
import { LanguagesOptions, getLangaugeLabel } from "../../../utils/Languages";
import { createPathwayLanguage, deletePathwayLanguage, updatePathwayLanguage } from "../../../apiQuery/Pathways/pathways.apis";
import 'react-toastify/dist/ReactToastify.css';
import Pathway from "../Pathway";

type props = {
  modalHandle: () => void;
  slug: string;
};

type UpdatePathwayFields = {
  name: string;
  description: string;
  status: boolean;
  entry_state_id: number;
  allow_multiple_participants: boolean;
};


export const LanguagesOptionsNewLanguges = [
  { value: "-", label: "Choose an option" },
  { value: "fr", label: "French" },
  { value: "es", label: "Spanish" }
]

const EditPathwayModal = memo(({ modalHandle, slug }: props) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en')
  const [dropDownSelectedLanguage, setdropDownSelectedLanguage] = useState("-")

  // const [PathwayContent, setPathwayContent] = useState<IPathways>()

  const { state: globalState } = useContext(store);

  // const [currentLanguage, setCu] = useState('en')

  const [state, setState] = useState({
    name: "",
    description: "",
    display_title: "",
    status: false,
    entry_state_id: 0,
    allow_multiple_participants: false,
    pathway_languages: [] as IPathwayLanguage[] || []
  });

  const { isLoading, isError, status, data, error, refetch, isFetching } =
    useQuery<IPathwayResponse, Error>(
      ["individual-state-flow", slug],
      () => getStateFlow(slug),
      { enabled: !!slug, onSuccess: (data) => {
        if(selectedLanguage === "en") {
             setState({
              display_title: data.data.display_title,
              name: data.data.name,
              description: data.data.description,
              status: data.data.status,
              entry_state_id: data.data.entry_state_id,
              allow_multiple_participants: data.data.allow_multiple_participants,
              pathway_languages: data?.data?.pathway_languages || []
            });
        }
      } }
    );

  useEffect(() => {
    if (data?.data) {
      if (selectedLanguage === "en") {
         setState({
        display_title: data.data.display_title,
        name: data.data.name,
        description: data.data.description,
        status: data.data.status,
        entry_state_id: data.data.entry_state_id,
        allow_multiple_participants: data.data.allow_multiple_participants,
        pathway_languages: data.data.pathway_languages || []
      });
        setSelectedLanguage('en')
      }
      else if (selectedLanguage === "fr") {
        const pathwayData = data.data.pathway_languages.find(item => item.language === "fr")
        if (pathwayData) {
          setState({
            display_title: pathwayData.display_title,
            name: pathwayData.name,
            description: pathwayData.description,
            status: data.data.status,
            entry_state_id: data.data.entry_state_id,
            allow_multiple_participants: data.data.allow_multiple_participants,
            pathway_languages: data.data.pathway_languages || []
          });
          setSelectedLanguage('fr')
        }
      } else if (selectedLanguage === "es") {
        const pathwayData = data.data.pathway_languages.find(item => item.language === "es")
        if (pathwayData) {
          setState({
            display_title: pathwayData.display_title,
            name: pathwayData.name,
            description: pathwayData.description,
            status: data.data.status,
            entry_state_id: data.data.entry_state_id,
            allow_multiple_participants: data.data.allow_multiple_participants,
            pathway_languages: data.data.pathway_languages || []
          });
          setSelectedLanguage('es')
        }

      }
    }
  }, [data, selectedLanguage]);

  const updatePathwayMutation = useMutation(
    (updateFlow: UpdatePathwayFields) =>
      updateFlowState(
        globalState.organization_id,
        {
          ...updateFlow,
        },
        slug
      ),
    {
      onSuccess: async () => {
        refetch();
      },
      onError: () => {
        toast(
          `There was an error updating the Pathway "${data?.data?.name}"`,
          "error"
        );
      },
    }
  );

  const updatePathwayLanguageMutation = useMutation((params: {
    pathwayLanguageId: number
    data: IPathwayLanguage
  }) => updatePathwayLanguage(params.pathwayLanguageId, params.data), {
    onSuccess: () => {
      refetch()
    }
  })


  const deleteSelectedLanguage = async (
    pathwayLanguageId: number
  ) => {
    try {
      const deletedLanguage = await deletePathwayLanguage(pathwayLanguageId);
      toast(
        `${'Current language Deleted Successfuly'}`,
        "success"
      )


      refetch()

    } catch (error) {
      console.error('Error adding new language:', error);
      toast(
        `${error}`,
        "error"
      );
    }
  }
  const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedLanguage === "en") {
      updatePathwayMutation.mutate(state);
      modalHandle();
      toast(
        `The pathway was successfully updated.`,
        "success"
      );
    }
    else {
      const pathwayData = state.pathway_languages.find(item => item.language === selectedLanguage)
      if (pathwayData) {
        updatePathwayLanguageMutation.mutate({
          pathwayLanguageId: pathwayData.id as number,
          data: {
            ...pathwayData,
          }
        })
      }
      toast(
        `The Pathway Language was successfully updated`,
        "success"
      );
    }

  };


  const [newForm, setNewForm] = useState(false)

  const [newPathwayLanguageData, setNewPathwayLanguageData] = useState<IPathwayLanguage>({
    name: '',
    description: '',
    language: 'fr',
    display_title: '',
  });
  const newLanguage = () => {
    setNewPathwayLanguageData({
      name: '',
      description: '',
      language: 'fr',
      display_title: ''
    });
    setSelectedLanguage('')
    setNewForm(true)
  }

  const listOfLanguages = useMemo(() => {
    if (state?.pathway_languages)
      return LanguagesOptionsNewLanguges.filter(item => state.pathway_languages?.find(sl => sl.language === item.value) === undefined)
    return LanguagesOptionsNewLanguges
  }, [state.pathway_languages])

  const submitNewLanguage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();


    try {
      await createPathwayLanguage(slug, newPathwayLanguageData);
      await refetch()
      var currLanguage = newPathwayLanguageData.language
      toast(
        `New Language was successfully created`,
        "success"
      )
      setSelectedLanguage(currLanguage)
      setNewForm(false);
    } catch (error) {
      console.error('Error adding new language:', error);
      toast(
        `Couldn't Create New Language`,
        "error"
      );
    }
  };



  return (

    <div className="relative z-50 flex w-2/5 h-full mt-5 overflow-auto bg-white rounded-md shadow-md text-textDark pathway-edit-modal">
      <div className="flex flex-col w-full p-8">
        <div className="flex justify-between">
          <div className="flex flex-col gap-5 mb-2">
            <div>
              <h2 className="mb-1 text-2xl font-normal text-textDark">
                Edit Pathway
              </h2>
              <p className="text-sm text-textDark">
                Edit the pathway name, description, and other details.
              </p>
            </div>
          </div>


          <button
            type="button"
            onClick={modalHandle}
            className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <CloseIcon className="w-4 h-4" />
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        <div className="sticky top-0 z-30 flex gap-2 px-2 py-2 bg-white dark:bg-bckDark sm:px-1">


          <AppButton
            variant={selectedLanguage === "en" ? 'info' : 'primary'}
            type="button"
            size="xs"
            onClick={() => {
              const pathwayData = state.pathway_languages.find(item => item.language === selectedLanguage)
              // console.log('pathway_data', pathwayData)
              if (pathwayData) {
                updatePathwayLanguageMutation.mutate({
                  pathwayLanguageId: pathwayData.id as number,
                  data: {
                    ...pathwayData,
                  }
                })
              }
              // alert('to ' + "en" + selectedLanguage)  
              setSelectedLanguage("en")
              setNewForm(false)
            }}
          >
            <div className="flex items-center justify-center gap-1">
              <LanguageIcon className='w-3 h-3' />
              <span className="text-xs">{getLangaugeLabel("English")}</span>
            </div>
          </AppButton>
          {state.pathway_languages ? state.pathway_languages.sort((a, b) => b.language.toLowerCase().charCodeAt(0) - a.language.toLowerCase().charCodeAt(0))
          .map((item, idx) => (
            <AppButton
              key={idx}
              variant={selectedLanguage === item.language ? 'info' : 'primary'}
              type="button"
              size="xs"
              onClick={() => {

                const pathwayData = state.pathway_languages.find(item => item.language === selectedLanguage)
                if (pathwayData) {
                  updatePathwayLanguageMutation.mutate({
                    pathwayLanguageId: pathwayData.id as number,
                    data: {
                      ...pathwayData,
                    }
                  })
                }
                setSelectedLanguage(item.language)
                setNewForm(false)
              }}
            >
              <div key={item.language} className="flex items-center justify-center gap-1">
                <LanguageIcon className='w-3 h-3' />
                <span className="text-xs">{getLangaugeLabel(item.language)}</span>
              </div>
            </AppButton>


          )) : null
          }
          {  //add check for allow multiple participant if its true user shouldnt create new language
          }


        {
          !state.allow_multiple_participants && <>
          {
                  (state?.pathway_languages?.length || 0) < 2 ? (
                    <AppButton
                      variant={'primary'}
                      type="button"
                      size="xs"
                      onClick={newLanguage}
                    >
                      <span className="text-xs">
                        {t('new_language')}
                      </span>
                    </AppButton>
                  ) : null
            }

              <AppButton
                  variant={'danger'}
                  type="button"
                  size="xs"
                  onClick={() => {
                    const pathwayData = state.pathway_languages.find(
                      (item) => item.language === selectedLanguage
                    );
                    if (typeof pathwayData?.id === 'number') {
                      deleteSelectedLanguage(pathwayData?.id);
                      setSelectedLanguage('en');
                      refetch();
                    } else {
                      toast('Cannot Delete Current Language', 'error');
                    }
                  }}
                >
                  <span className="text-xs">
                    Delete Selected Language
                  </span>
                </AppButton>
          
          </>
        }


        </div>
        <hr className="w-full h-px my-5 border-1 border-borderGray dark:border-textDark" />



        {
          newForm ? (<form onSubmit={submitNewLanguage}>
            <AppInput
              isFull
              label="Pathway Title"
              id="PathwayTitle"
              title="Pathway Title"
              tabIndex={-1}
              placeholder="Pathway Title"
              name="name"
              required
              onChange={(e) => {
                setNewPathwayLanguageData((prev) => ({ ...prev, name: e.target.value }));
                setNewPathwayLanguageData((prev) => ({ ...prev, display_title: e.target.value }));


              }}
              value={newPathwayLanguageData?.name || ''}
            />
            <AppTextArea
              placeholder={"Pathway Description"}
              rows={4}
              name="description"
              onChange={(e) => {
                setNewPathwayLanguageData((prev) => ({ ...prev, description: e.target.value }));
              }}
              label="Pathway Description"
              id="PathwayDescription"
              value={newPathwayLanguageData?.description || ""}
            />

            <AppDropDown label={"Language"} options={listOfLanguages} value={dropDownSelectedLanguage || ""} name="language" onChange={(e) => {
              setdropDownSelectedLanguage(e.target.value);
              setNewPathwayLanguageData((prev) => ({ ...prev, language: e.target.value }));
            }} />

            <div className="mt-8">
              <AppButton variant="primary" type="submit">{`${t(
                "PathwayPage.Save_Changes"
              )}`}</AppButton>
            </div>
          </form>) :
            (<>

              <form onSubmit={submitFormHandler} className="w-full">
                <AppInput
                  isFull
                  label="Pathway Title"
                  id="PathwayTitle"
                  title="Pathway Title"
                  tabIndex={-1}
                  placeholder="Pathway Title"
                  name="name"
                  required
                  onChange={(e) => {
                    // setState((prev) => ({ ...prev, name: e.target.value }));
                    // update based on corresponding language
                    if (selectedLanguage !== 'en') {
                      const pathwayData = state.pathway_languages.find(item => item.language === selectedLanguage)
                      if (pathwayData) {
                        const updatedPathwayData = state.pathway_languages.map(item => {
                          if (item.language === selectedLanguage) {
                            return {
                              ...item,
                              name: e.target.value,
                              display_title: e.target.value
                            }
                          }
                          return item
                        })
                        setState((prev) => ({ ...prev, pathway_languages: updatedPathwayData }));
                      }
                    } else {
                      setState((prev) => ({ ...prev, name: e.target.value, display_title: e.target.value }));
                    }
                  }}
                  value={
                    selectedLanguage === 'en' ? state.name :
                      (state.pathway_languages && Array.isArray(state.pathway_languages)) ?
                        state.pathway_languages.find(item => item.language === selectedLanguage)?.name || '' :
                        ''

                  }
                />
                <AppTextArea
                  placeholder={"Pathway Description"}
                  rows={4}
                  name="description"
                  onChange={(e) => {
                    // setState((prev) => ({ ...prev, description: e.target.value }));
                    // update based on corresponding language
                    if (selectedLanguage !== 'en') {
                      const pathwayData = state.pathway_languages.find(item => item.language === selectedLanguage)
                      if (pathwayData) {
                        const updatedPathwayData = state.pathway_languages.map(item => {
                          if (item.language === selectedLanguage) {
                            return {
                              ...item,
                              description: e.target.value
                            }
                          }
                          return item
                        })
                        setState((prev) => ({ ...prev, pathway_languages: updatedPathwayData }));
                      }
                    } else {
                      setState((prev) => ({ ...prev, description: e.target.value }));
                    }
                  }}
                  label="Pathway Description"
                  id="PathwayDescription"
                  value={
                    selectedLanguage === 'en' ? state.description :
                      (state.pathway_languages && Array.isArray(state.pathway_languages)) ?
                        state.pathway_languages.find(item => item.language === selectedLanguage)?.description || '' :
                        ''

                  }
                />

                <AppDropDown
                  label={`${t("PathwayPage.Entry_State_for_this_Pathway")}`}
                  defaultValue={state.entry_state_id || undefined}
                  options={
                    (
                      data?.data?.states && [
                        {
                          name: "Not Selected",
                          id: 0,
                        },
                        ...data?.data?.states,
                      ]
                    )?.map((item) => {
                      return {
                        value: (item.id || 0).toString(),
                        label: item.name,
                      };
                    }) || []
                  }
                  value={state.entry_state_id.toString()}
                  name="entry_state_id"
                  placeholder={`${t("PathwayPage.Entry_State_for_this_Pathway")}`}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      entry_state_id: parseInt(e.target.value),
                    }));
                  }}
                />

                <div className="flex gap-5 mt-8">
                  <div className="flex justify-start mr-8">
                    <label
                      htmlFor="getCandidate_API"
                      className="mr-2 text-md text-textDark"
                    >
                      Pathway Active
                    </label>
                    <Switch
                      checked={state.status}
                      onChange={() => {
                        setState((prev) => ({
                          ...prev,
                          status: !prev.status,
                        }));
                      }}
                      className={`${state.status === true ? "bg-greenColor" : "bg-teal-700"
                        }
                      relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mt-1`}
                    >
                      <span className="sr-only">Pathway Active</span>
                      <span
                        aria-hidden="true"
                        className={`${state.status === true ? "translate-x-3" : "translate-x-0"
                          }
                    pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                  </div>

                  <div className="flex justify-start">
                    <label
                      htmlFor="getCandidate_API"
                      className="mr-2 text-md text-textDark"
                    >
                      Allow Multiple Participants
                    </label>
                    <Switch
                      checked={state.allow_multiple_participants === true}
                      onChange={() => {
                        setState((prev) => ({
                          ...prev,
                          allow_multiple_participants:
                            !state.allow_multiple_participants,
                        }));
                      }}
                      className={`${state.allow_multiple_participants === true
                        ? "bg-greenColor"
                        : "bg-teal-700"
                        }
                      relative inline-flex h-[18px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 mt-1`}
                    >
                      <span className="sr-only">Allow Multiple Participants</span>
                      <span
                        aria-hidden="true"
                        className={`${state.allow_multiple_participants === true
                          ? "translate-x-3"
                          : "translate-x-0"
                          }
                    pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                  </div>
                </div>

                <div className="mt-8">
                  <AppButton isLoading={updatePathwayMutation.isLoading} disabled={updatePathwayMutation.isLoading} variant="primary" type="submit">{`${t(
                    "PathwayPage.Save_Changes"
                  )}`}</AppButton>
                </div>
              </form>


            </>)
        }
      </div>
    </div>
  );
});



EditPathwayModal.displayName = "Edit Pathway Modal"
export default EditPathwayModal;
