// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

import { ApexOptions } from "apexcharts";
import ReactApexcharts from "react-apexcharts";

// ** Util Import
import { hexToRGBA } from "../../utils/hex-to-rgba";
import { FC, useMemo } from "react";

interface Props {
  title: string;
  labels: string[];
  data: number[];
}

const StateChart: FC<Props> = (Props) => {
  const sum = useMemo(() => {
    return Props.data.reduce((a, b) => {
      return a + b;
    }, 0);
  }, [Props.data]);


  // ** Hook
  const theme = useTheme();

  const options: ApexOptions = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        distributed: true,
        columnWidth: "51%",
        // endingShape: "rounded",
        // startingShape: "rounded",
      },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    colors: [
      hexToRGBA(theme.palette.warning.main, 1),
      hexToRGBA(theme.palette.warning.main, 1),
      hexToRGBA(theme.palette.warning.main, 1),
      hexToRGBA(theme.palette.warning.main, 1),
      hexToRGBA(theme.palette.warning.main, 1),
      hexToRGBA(theme.palette.warning.main, 1),
      hexToRGBA(theme.palette.warning.main, 1),
    ],
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    xaxis: {
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories: Props.labels,
      labels: {
        style: { colors: theme.palette.text.disabled },
        // rotate: 90,
      },
    },

    yaxis: { show: false },
    grid: {
      show: false,
      padding: {
        top: -30,
        left: -7,
        right: -4,
      },
    },
  };

  return (
    <Card>
      <CardHeader
        title={Props.title}
        subheader={sum}
        subheaderTypographyProps={{ sx: { lineHeight: 1.429 } }}
        titleTypographyProps={{ sx: { letterSpacing: "0.15px" } }}
      />
      <CardContent
        sx={{
          pt: {
            xs: `${theme.spacing(6)} !important`,
            md: `${theme.spacing(0)} !important`,
          },
        }}
      >
        <ReactApexcharts
          type="bar"
          height={215}
          options={options}
          series={[{ data: Props.data }]}
        />
        <Box
          sx={{
            mt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          { }
        </Box>
      </CardContent>
    </Card>
  );
};

export default StateChart;
