import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  FC,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getStateFlow } from "../../apiQuery/StateFlow/State.apis";
import { CurrentFlow_R } from "../../GlobalState/types";
import UseTab from "../../Hook/UseTab";
import OverlayModal from "../Layouts/OverlayModal";
import { Fade } from "@mui/material";
import PathwayCardSlug from "../List/PathwayCardSlug";
import SkeletonLoader from "../Helpers/Skeleton";
import Search from "../General/Form/Search";
import { store } from "../../GlobalState/store";
import { setCurrentPathway } from "../../GlobalState/store.actions";
import ZeroState from "../General/ZeroState";
import { MdBento, MdModeEditOutline } from "react-icons/md";
import { withAuth } from "../../hoc/withAuth";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AppButton,
  CloseEyeIcon,
  CommandBar,
  CopyIcon,
  CustomClipboard,
  DeleteIcon,
  EyeIcon,
  InfoIcon,
  Loader,
  PopupModal,
  THead,
  TabBar,
  Table,
  TransitionAnimation,
} from "../../Theme";
import { State } from "../../Common/Types";
import NewStateModal from "./Modal/NewStateModal";
import EditStateModal from "./Modal/EditStateModal";
import { toast } from "../../utils/Toast";
import { deleteState } from "../../apiQuery/State/State.apis";
import moment from "moment";

const PathwayPage: FC = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const pathwaySlug = params.slug || "";

  const { dispatch } = useContext(store);

  const navigate = useNavigate();

  const [templateModal, setTemplateModal] = useState(false);

  const { isLoading, isError, status, data, error, refetch, isFetching } =
    useQuery<CurrentFlow_R, Error>(
      ["individual-state-flow", pathwaySlug],
      () => getStateFlow(pathwaySlug),
      {
        enabled: !!pathwaySlug,
        onSuccess: (data) => {
          // dispatch(setCurrentPathway(data.data))
        },
      }
    );


  // const stateList = useMemo(() => {
  //   if(data?.data) {
  //     return data?.data?.states?.sort((a,b) => moment(a.CreatedAt).toLocaleString() > moment(b.CreatedAt).toLocaleString() ? 1 : -1)
  //   } else
  //   return null
  // } , [data?.data])


  const stateInfo = useMemo(() => {
    if(data?.data) {
      return data.data.states_info
    } else return null
  }, [data?.data])

  // const [tab, setTab] = UseTab("States");
  // const [search, setSearch] = useState("");

  // const handleDeleteState = useMutation(
  //   () => deleteState(organization_id, globalState.currentFlow.id, id),

  // );

  const optionsOfStateCard = useMemo(
    () => [
      {
        icon: <MdModeEditOutline />,
        text: t("PathwayPage.Edit_State"),
        onClick: (ID: number | string) =>
          navigate(`/pathways/${pathwaySlug}/state/${ID}/edit`),
      },
    ],
    []
  );

  useEffect(() => {
    if (data?.data) dispatch(setCurrentPathway(data.data));
  }, [data]);

  // ========================== New Modal  =====================================

  const [newStateModal, setNewStateModal] = useState(false);
  const newStateModalHandler = useCallback(
    async () => {
      setNewStateModal((prev) => !prev)
      await refetch()
    },
    [refetch]
  );

  // ========================== Edit Modal  =====================================

  const [editStateModal, setEditStateModal] = useState(false);
  const editStateModalHandler = useCallback(
    () => setEditStateModal((prev) => !prev),
    []
  );

  useMemo(()=>{refetch()}, [editStateModal])

  // ========================== HOOKS =======================================

  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  useEffect(() => {
    if (selectedStates.length > 0) {
      setShowCommandBar(true);
    } else {
      setShowCommandBar(false);
    }
  }, [selectedStates.length]);

  // ============================  Handlers  ==================================

  const choosePathwayCheckboxHandler = useCallback(
    (item: State) => {
      if (selectedStates.includes(item.slug)) {
        setSelectedStates(
          selectedStates.filter((pathway) => pathway !== item.slug)
        );
      } else {
        setSelectedStates([...selectedStates, item.slug]);
      }
      // if the selectedStates is empty, hide the commandbar
      if (selectedStates.length === 0) {
        setShowCommandBar(false);
      } else {
        setShowCommandBar(true);
      }
    },
    [selectedStates]
  );

  // ========================== COMMANDBAR  ==================================

  const [showCommandBar, setShowCommandBar] = useState(false);
  const closeCommandBar = () => setShowCommandBar((prev) => false)
  const [showPopupDeleteStates, setShowPopupDeleteStates] = useState(false);

  const confirmDeleteStates = async () => {
    if (selectedStates.length <= 0) return;
    try {
      // Map selected states to an array of promises that delete them
      const deletePromises = selectedStates.map((state) =>
        deleteState(pathwaySlug, state)
      );

      // Wait for all delete promises to settle
      const results = await Promise.allSettled(deletePromises);
      // Check for any failed promises and display error messages as toasts
      const failedPromises = results.filter(
        (result) => result.status === "rejected"
      );

      if (failedPromises.length > 0) {
        failedPromises.forEach((promise) =>
          // toast(promise.reason.message, "error")
          toast("Error deleting state", "error")
        );
      } else {
        toast('State are deleted!', 'success')
        setShowCommandBar(!showCommandBar)
        setSelectedStates([])
      }

      refetch();
    } catch (error) {
      toast('There was an error to delete', 'info')
    }
  }
  // ===============================================================

  return (
    <Fragment>
      <section className="relative">
        <TabBar />

        <div className="flex flex-col items-start justify-between p-5 bg-white border shadow-sm border-borderGray">
          <span className="pb-1 mb-4 text-sm text-grayColor">
            <Link to="/pathways">Pathways</Link> &nbsp;&nbsp;/&nbsp;&nbsp;{" "}
            {data?.data.name}
          </span>
          <div className="flex my-1">
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 7.573 8.414"
              className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
            >
              <path
                id="icon_pathways"
                d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                transform="translate(-6 -4)"
                fill="currentColor"
              />
            </svg>
            <div className="flex flex-col items-start p-2">
              <p className="text-xl whitespace-nowrap text-textDark dark:text-white">
                {data?.data.name}
              </p>

              <div className="flex items-center gap-3 px-2 py-1 transition-all delay-75 rounded-md hover:cursor-pointer hover:shadow-sm">
                <div className="flex items-center gap-1">
                  {data?.data.status !== undefined && (
                    <>
                      {data?.data.status ? (
                        <>
                          <EyeIcon className="w-4 h-4 text-grayColor" />
                          <span className="inline text-xs text-grayColor">
                            {t("PathwayPage.Active")}
                          </span>
                        </>
                      ) : (
                        <>
                          <CloseEyeIcon className="w-4 h-4 text-grayColor" />
                          <span className="inline text-xs text-grayColor">
                            {" "}
                            {t("PathwayPage.Inactive")}{" "}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="flex items-center gap-1">
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 11.071 7.778"
                  >
                    <path
                      fill="currentColor"
                      d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
                      transform="translate(-1.9 -8.95)"
                    />
                  </svg>
                  <span className="text-xs text-grayColor">
                    {data?.data.participant_count || 0} Workers
                  </span>
                </div>
                <CustomClipboard showInfoIcon={false} showClipboardText={false} clipboardText={pathwaySlug} />
              </div>
            </div>
          </div>
        </div>

        <div className="border border-[borderGray] bg-white border-l-0 border-t-0 px-2 py-2.5">
          <div className="flex items-center gap-2">
            <AppButton
              variant="primary"
              type="button"
              size="xs"
              onClick={() => {
                newStateModalHandler();

              }}
            >
              <div className="flex items-center justify-center gap-1">
                <span className="text-xs">New State</span>
                <span>+</span>
              </div>
            </AppButton>
          </div>
        </div>

        <div className="pb-10">
          <div className="w-[97%] py-5 m-auto space-y-5">
            <div className="flex items-center">
              <Loader isLoading={isLoading} size={35} />
            </div>
            <Table>
              <THead>
                <tr>
                  <th scope="col" className="py-3 font-medium px-14">
                    State
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Workers
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Contents
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Actions
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Notes
                  </th>
                </tr>
              </THead>
              <tbody>
                {data?.data?.states && data?.data?.states.map((item, index) => {
                  const date1 = new Date(item?.CreatedAt);
                  const date2 = new Date();
                  // @ts-ignore
                  const diffTime = Math.abs(date2 - date1);
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                  return (
                    <tr
                      className="transition-all bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer"
                      key={item.slug}
                    >
                      <th
                        scope="row"
                        className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white"
                      >
                        <div className="flex items-center justify-start">
                          <input
                            type="checkbox"
                            checked={selectedStates.length > 0 ? selectedStates.includes(item.slug) : false}
                            onClick={() => choosePathwayCheckboxHandler(item)}
                            className="w-4 h-4 text-blue-600 bg-transparent bg-gray-200 border-2 border-gray-300 rounded-sm cursor-pointer checkbox-worker focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                          />
                          <div className="flex flex-col items-start p-2">
                            <div className="flex items-center gap-1">
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 7.573 8.414"
                                className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                              >
                                <path
                                  id="icon_pathways"
                                  d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                                  transform="translate(-6 -4)"
                                  fill="currentColor"
                                />
                              </svg>
                              <p
                                onClick={() =>
                                  navigate(
                                    `/pathways/${data?.data?.slug}/state/${item.slug}`
                                  )
                                }
                                className="ml-1 text-lg whitespace-nowrap text-textDark dark:text-white"
                              >
                                {item.name}
                              </p>
                            </div>

                            <p className="flex items-center gap-1 text-xs whitespace-nowrap text-grayColor dark:text-white">
                              {item.status !== undefined && (
                                <>
                                  {item.status ? (
                                    <>
                                      <EyeIcon className="w-3 h-3" />
                                      <span className="inline text-xs">
                                        {t("PathwayPage.Active")}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <CloseEyeIcon className="w-3 h-3" />
                                      <span className="inline text-xs">
                                        {" "}
                                        {t("PathwayPage.Inactive")}{" "}
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </th>
                      <td
                        className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white"
                        width={200}
                        onClick={() =>
                          navigate(
                            `/pathways/${data?.data?.slug}/state/${item.slug}`
                          )
                        }
                      >
                        <div className="flex items-center gap-2">
                          <svg
                            aria-hidden="true"
                            className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 11.071 7.778"
                          >
                            <path
                              fill="currentColor"
                              d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
                              transform="translate(-1.9 -8.95)"
                            />
                          </svg>
                          <span className="text-textDark">
                            {item.participant_count || 0} Workers
                          </span>
                        </div>
                      </td>

                      <td
                        className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white"
                        width={200}
                        onClick={() =>
                          navigate(
                            `/pathways/${data?.data?.slug}/state/${item.slug}`
                          )
                        }
                      >
                        <div className="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            viewBox="0 0 9.879 9.638"
                            className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                          >
                            <path
                              id="icon_content"
                              d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
                              transform="translate(-4 -4)"
                              fill="currentColor"
                            />
                          </svg>
                          <span className="text-textDark">
                            {/* {data?.data.states_info
                              ? data?.data.states_info[index].content_count
                              : 0}{" "} */}
                              {
                                stateInfo?.find(info => info.state_id === item.id)?.content_count || 0
                              }{" "}
                            Contents
                          </span>
                        </div>
                      </td>

                      <td
                        className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white"
                        width={200}
                        onClick={() =>
                          navigate(
                            `/pathways/${data?.data?.slug}/state/${item.slug}`
                          )
                        }
                      >
                        <div className="flex items-center gap-2">
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 9.878 9.878"
                            className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                          >
                            <path
                              id="icon_actions"
                              d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
                              transform="translate(-2 -2)"
                              fill="currentColor"
                            />
                          </svg>
                          <span className="text-textDark">
                            {/* {data?.data.states_info
                              ? data?.data.states_info[index].action_count
                              : 0}{" "} */}
                              {
                                stateInfo?.find(info => info.state_id === item.id)?.action_count || 0
                              }{" "}
                            Actions
                          </span>
                        </div>
                      </td>

                      <td className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white">
                        <span>-</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </section>

      {/* =============== COMMANDBAR ==================== */}
      <CommandBar
        isOpen={showCommandBar}
        setOpen={() => setShowCommandBar(!showCommandBar)}
      >
        <span className="text-xs text-textGray">
          {selectedStates.length} State{selectedStates.length > 1 ? "s" : ""}{" "}
          Selected
        </span>
        {selectedStates.length === 1 ? (
          <AppButton
            type="button"
            variant="primary"
            onClick={() => {
              editStateModalHandler();
            }}
          >
            <div className="flex items-center gap-1 mx-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
              >
                <path
                  fill="currentColor"
                  d="M794 390L666 262l42-42q17-17 42.5-16.5T793 221l43 43q17 17 17 42t-17 42l-42 42zm-42 42L248 936H120V808l504-504 128 128z"
                ></path>
              </svg>
            </div>
            Edit State
          </AppButton>
        ) : null}
        <AppButton
          type="button"
          variant="primary"
          onClick={() => {
          }}
        >
          <div className="flex items-center gap-1 mx-1">
            <DeleteIcon className="" />
            <span className="text-sm"
              onClick={() => {
                setShowPopupDeleteStates(prev => !prev)
              }}
            >
              Delete State{selectedStates.length > 1 ? "s" : ""}{" "}
            </span>
          </div>
        </AppButton>
      </CommandBar>
      {/* 
      <OverlayModal onClose={handleTemplateModal} isOpen={templateModal}>
        <StateTemplate modalHandle={handleTemplateModal} refetch={refetch} />
      </OverlayModal> */}
      <OverlayModal onClose={() => {
        newStateModalHandler()
        setSelectedStates([])
        closeCommandBar()
        refetch()
      }

      } isOpen={newStateModal}>
        <NewStateModal modalHandle={() => {
          newStateModalHandler()
          setSelectedStates([])
          closeCommandBar()
          refetch()
        }} />
      </OverlayModal>

      <OverlayModal
        onClose={() => {
          editStateModalHandler()
          refetch()
        }}
        isOpen={editStateModal}>
        <EditStateModal
          modalHandle={() => {
            editStateModalHandler()
            setSelectedStates([])
            closeCommandBar()
            // refetch()
          }}
          slug={selectedStates[0]}
        />
      </OverlayModal>

      {/* ===================  POPUP Modal ======================== */}
      <PopupModal
        setOpen={setShowPopupDeleteStates}
        isOpen={showPopupDeleteStates}
        title="If you want to continue, confirm by clicking the delete button below."
        onConfirmClick={confirmDeleteStates}
      />
    </Fragment>
  );
};

export default withAuth(PathwayPage);
