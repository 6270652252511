import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../../General/Form/Input";
import TextArea from "../../General/Form/TextArea";
import Button from "../../General/Button";
import { AiFillDelete, AiFillFile } from "react-icons/ai";
import {
    Conditions_T,
    contents,
    faq,
    faqQuestions, ruleBody, ruleIds,
    Single_Content_Single,
    state_Edit_FAQContent
} from "../../../Common/Types";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import NestedContentListItem from "../NestedContentListItem";
import { reorder } from "../../Utils/Reorder";
import { CircularProgress, Fade } from "@mui/material";
import ContentExtra from "../../Event/ContentExtra";
import { MdModeEditOutline } from "react-icons/md";
import UseUploadFile from "../../../Hook/UseUploadFile";
import { uploadFile } from "../../../apiQuery/Common/Upload.api";
import { useMutation, useQueryClient } from "react-query";
import UseTab from "../../../Hook/UseTab";
import Tab from "../../Tabs/Tab";
import { BsFillEyeFill } from "react-icons/bs";
import Tabs from "../../Tabs/Tabs";
import OverlayModal from "../../Layouts/OverlayModal";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../../../GlobalState/store";
import { createContentInState, updateContent } from "../../../apiQuery/Contents/Content.apis";
// import { setSnackbar } from "../../../GlobalState/store.actions";
import {
    createFaqQuestion, deleteFaqQuestion,
    updateFaq,
    updateFaqQuestion,
} from "../../../apiQuery/Contents/ContentType.apis";
import PreviewContentModal from "../ContentPreview/PreviewContentModal";
import ContentTypeCardPreview from "../ContentPreview/ContentTypeCardPreview";
import Mobile from "../ContentPreview/Mobile";
import {
    createTimeBaseEvent,
    deleteTimeCondition,
    updateTimeCondition
} from "../../../apiQuery/Condition/TimeBase.apis";
import StandardInput from "../../General/Form/StandardInput";
import { createContentRule } from "../../../apiQuery/Condition/Rule.apis";
import WYSIWYGEditor from "../../General/Form/WYSIWYGEditor";
import { formatQuery } from "react-querybuilder";
import initialQuery from "../../../utils/InitialRules";
import jsonHandler from "../../Utils/JsonHandler";
import UseUpload from "../../../Hook/UseUpload";
import { baseURL } from "../../../apiQuery";
import UploadProgress from "../../General/UploadProgress";
import { getDifference } from "../../../utils/General";
import { toast } from "../../../utils/Toast";
import { useTranslation } from 'react-i18next';
import { EnumContentType } from '../../../Types/ContentTemplate/ContentType';
import { IFAQ } from '../../../Types/ContentTemplate/FAQ';
import moment from "moment";
import { getStateContentTemplateFilter } from '../../../apiQuery/ContentTemplate/StateContentTemplate.apis';
import AppButton from '../../Shared/AppButton/AppButton';
import AppInput from '../../Shared/AppInput/AppInput';
import { AppLoader } from '../../Shared/AppLoader';
import Condition from '../../Event/Condition';
import AppTextArea from '../../Shared/AppTextArea/AppTextArea';
import { CustomClipboard, AppButton as AppThemeButton  } from '../../../Theme';
import { setAddContentTemplateModal } from '../../../GlobalState/store.actions';
import { LanguageEnum } from '../../../Types/ContentTemplate/Langauge';
import { FAQ } from '../../../@Core';

type faqs = {
    question: string
    answer: string
}

type updateFAQ = faqQuestions & {
    id?: number
    faq_id: number
}

type deletedFaq = {
    id?: number
    faq_id: number
}

const FAQNew: FC<state_Edit_FAQContent> = memo(({ onClose, defaultValue, type, onSuccess }) => {

    const { t, i18n } = useTranslation();
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const { register, handleSubmit, setValue, watch, getValues, trigger, formState: { errors } } = useForm<faq>({
        defaultValues: defaultValue ? defaultValue?.faq : {}
    });

    const { uploadForm, progress, isLoading } = UseUpload(
        `${baseURL}upload`
    );


    const [altTitle, setAltTitle] = useState<string>("")

    const [timeEvent, setTimeEvent] = useState({
        isAdded: false,
        isRuleAdded: false,
    })

    //@ts-ignore
    const timeValue = parseInt(getValues("timeValue")) as number

    //@ts-ignore
    const eventName = getValues("eventName") as string

    //@ts-ignore
    const typeOf = getValues("typeOf") as string

    //@ts-ignore
    const checkInValue = getValues("checkInValue") as string

    //@ts-ignore
    const target_func = getValues("target_func") || undefined as string

    const [ruleId, setRuleId] = useState<ruleIds>({
        event: undefined,
        time: undefined
    });
    const [passedContent, setPassedContent] = useState({
        targetField: '',
        targetFunction: '',
    })

    const [query, setQuery] = useState(initialQuery);

    const params = useParams();
    const id = params.stateSlug || ""
    const pathwaySlug = params.slug || ""
    const {
        state,
        dispatch
    } = useContext(store);
    const organization_id = useMemo(() => state.organization_id, [state.organization_id])

    const [imageSrc, file, render, name] = UseUploadFile('FAQ')

    const title = getValues("title");
    const questions = watch("questions") || []

    const [modal, setModal] = useState({
        pathwayView: false,
        contentView: false
    })

    // mutations
    //================================================================================================

    const [previousPage, setPreviousPage] = useState<string>();
    const [nextPage, setNextPage] = useState<string>();
    const [contentPriority, setContentPriority] = useState<number>(0);
    const [searchContentTemplate, setSearchContentTemplate] = useState('')

    const isContentTemplate = useMemo(() => defaultValue?.template_id ? true : false, [defaultValue?.template_id])

    const { data: faqTemplateData, isLoading: faqCcontentTempalteIsLoading, isError: faqTemplateIsError, error: faqTemplateError, mutate } = useMutation(
        (pageDirection: 'next' | 'previous' | '') => getStateContentTemplateFilter<IFAQ, EnumContentType.FAQContent>
            (pathwaySlug, id, EnumContentType.FAQContent, 8, '', nextPage, previousPage, pageDirection), {
        onSuccess(data) {
            if (data?.data) {
                const { next, previous } = data?.data.paginate
                setNextPage(next)
                setPreviousPage(previous)
            }
        },
    });

    useEffect(() => {
        mutate('')
    }, [mutate]);






    const flattedFAQTemplateOptions = useMemo(() => {
        // const newOption = { value: '0', title: 'No Template Content' };
        const currentLanguage = i18n.language;

        const articleOptions = faqTemplateData && faqTemplateData.data.data.map((item) => {
            const { id } = item;
            return item.faq.map((ann) => {
                return {
                    template_id: id,
                    consent_id: ann.id,
                    title: ann.title,
                    description: ann.description,
                    updated_at: ann.updated_at,
                    metaData: item.content_metadata,
                    // address: ann.address,
                    questions: ann.questions,
                    language: ann.language
                }
            })
        }).flatMap((item) => item).filter(item => item.language === LanguageEnum.English)
        return articleOptions;
    }, [faqTemplateData, i18n.language]);


    const search_result = useMemo(() => {
        if (searchContentTemplate === '') return flattedFAQTemplateOptions
        return flattedFAQTemplateOptions &&
            flattedFAQTemplateOptions.filter(item => item.title.toLowerCase().includes(searchContentTemplate.toLowerCase()) ||
                item.description.toLowerCase().includes(searchContentTemplate.toLowerCase()))
    }, [flattedFAQTemplateOptions, searchContentTemplate])





    // Create content template 
    const addContentTemplate = async (item: any) => {
    

        const obj = {
            template_id: item.template_id as  number,
            state_slug: id as string,
            pathway_slug: pathwaySlug as string,
            is_template: true,
            content_type: EnumContentType.FAQContent,
        }

        dispatch(setAddContentTemplateModal(obj))

        
        onClose()
    }

    //==================================================================================================

    const createTimeEvent = useMutation((timeData: Conditions_T) => createTimeBaseEvent(organization_id, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.create_time_base_rule_failed')}`
            toast(message, 'error')
        }
    })
    const deleteCondition = useMutation((delId: number) => deleteTimeCondition(organization_id, delId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-time-rules", organization_id])
        }
    })
    const updateTimeEvent = useMutation((timeData: Conditions_T) => updateTimeCondition(organization_id, timeData.id || 0, {
        ...timeData,
        deliver_time: typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue,
        deliver_unit: typeOf === 'days' ? 'hour' : typeOf || "minute"
    }))
    const createRuleCondition = useMutation(() => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        // const target_func = getValues("target_func") as string
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, target_func, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore

            const message = error?.message || `${t('content.create_content_rule_failed')}`
            toast(message, 'error')
        }
    })

    const createRuleTimePassedCondition = useMutation(({ targetField, targetFunction }: any) => {
        const deliver_time = typeOf === 'days' ? 24 * timeValue : isNaN(timeValue) ? 5 : timeValue
        const deliver_unit = typeOf === 'days' ? 'hour' : typeOf || "minute" //@ts-ignore
        return createContentRule({ organization_id: organization_id, ...jsonHandler(timeValue, typeOf, eventName, query, targetFunction, checkInValue || ""), deliver_time: deliver_time, deliver_unit: deliver_unit, target_func: targetFunction, target_field: targetField })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["all-content-rules", organization_id])
        },
        onError: (error: null | Error) => { //@ts-ignore
            const message = error?.message || `${t('content.create_content_rule_failed')}`
            toast(message, 'error')
        }
    })

    const UpDateFunc = async (createdData: contents) => {
        if (ruleId.event === 0) {
            let ruleId = null
            if (query.rules.length !== 0) {
                // const { data } = await createRuleCondition.mutateAsync(
                //     // {
                //     //     organization_id: organization_id,
                //     //     ...jsonHandler(timeValue, typeOf, eventName, query,target_func)
                //     // }
                // )
                // ruleId = data?.id || null
                ruleId = await createRuleConditionForContent()
            }
            await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId })
        } else await updateContentMutation.mutateAsync({ ...createdData, rules_content_id: ruleId.event || null })
    }

    const createTimeRule = async (ID: number | undefined) => {
        const typeIs = typeOf === 'days' ? 'hour' : typeOf
        await createTimeEvent.mutateAsync({
            deliver_time: timeValue ? timeValue : 5,
            deliver_unit: typeIs || "minute",
            state_id: parseInt(defaultValue.state_id),
            content_id: ID
        })
    }

    const updateFaqQues = useMutation((items: updateFAQ) => updateFaqQuestion(organization_id, items.faq_id, items?.id || 0, { ...items }), {})
    const createFaqQues = useMutation((items: updateFAQ) => createFaqQuestion(organization_id, items.faq_id, { ...items }), {})
    const deleteFaqQues = useMutation((items: deletedFaq) => deleteFaqQuestion(organization_id, items.faq_id, items?.id || 0), {})

    const createContents = useMutation((newContent: contents) => createContentInState(organization_id, pathwaySlug, id, { ...newContent }), {
        onSuccess: async (createdData: Single_Content_Single) => {
            if (timeEvent.isAdded) {
                await createTimeRule(createdData.data?.id)
            }
            toast(`${t('content.created')}`, 'success')
            !!onSuccess && onSuccess()
            onClose()
        },
        onError: (error: null | Error) => {

            const message = error?.message || `${t('content.failed')}`
            toast(message, 'error')
        }
    })

    const updateContentMutation = useMutation((updateContentDetails: contents) => updateContent(organization_id, pathwaySlug, id, { ...updateContentDetails }, updateContentDetails.slug || ''), {
        onSuccess: async (data: Single_Content_Single) => {
            if (defaultValue) {
                if (data?.data?.faq) {
                    await updateContentFaqs.mutateAsync({
                        ...data?.data?.faq
                    })
                }
                toast(`${t('content.updated')}`, 'success')
                !!onSuccess && onSuccess()
                await queryClient.invalidateQueries(['content', defaultValue.id])
                onClose()
            }
        },
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const updateContentFaqs = useMutation((faqDetails: faq) => updateFaq(organization_id, { ...faqDetails }, faqDetails?.id || 0), {
        onError: (error: null | Error) => {
            const message = error?.message || `${t('content.updated_failed')}`
            toast(message, 'error')
        }
    })

    const [tab, setTab] = UseTab('FAQ Details')

    const handlePathwayView = useCallback(() => {
        setModal(prev => ({ ...prev, pathwayView: !prev.pathwayView }))
    }, [])

    const handleContentView = useCallback(() => {
        setModal(prev => ({ ...prev, contentView: !prev.contentView }))
    }, [])

    const handleUpload = useMutation((formData: any) => uploadFile(formData), {})

    const [inputs, setInputs] = useState({
        question: '',
        answer: ''
    })
    const [edit, setEdit] = useState({
        isEdit: false,
        index: 0
    })

    const [loading, setLoading] = useState(false)




    async function createRuleConditionForContent() {
        let data = null
        if (passedContent.targetField && passedContent.targetFunction) {


            const resultCondition = await createRuleTimePassedCondition.mutateAsync(
                {
                    targetField: passedContent.targetField,
                    targetFunction: passedContent.targetFunction
                }
            )
            data = resultCondition?.data
        } else {
            const resultRule = await createRuleCondition.mutateAsync()
            data = resultRule?.data
        }
        return data?.id || null
    }




    //  ========== submit form ==========

    const onSubmit: SubmitHandler<faq> = async data => {

        await trigger()
        let fileUpload = {
            link: ''
        }
        if (file !== undefined) {
            const formData = new FormData();
            // @ts-ignore
            formData.append("file", file);
            const { link } = await uploadForm(formData)
            fileUpload.link = link
        }

        let shapeData;
        if (isContentTemplate) {
            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "faq",
                // cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                priority: contentPriority,
                // tags: data?.tags ? [data.tags] : [],
                // preview_description: data?.preview_description,
                // preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                // faq: {
                //     title: data.title,
                //     description: data.description,
                //     questions,
                //     owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                // }
            }

        } else {

            shapeData = {
                ...defaultValue,
                [(type === 'create' || type === 'update') ? 'content_type' : 'content_template_type']: "faq",
                cover_image: fileUpload?.link || defaultValue?.cover_image || "",
                priority: isContentTemplate ? contentPriority : Number(data.priority),
                tags: data?.tags ? [data.tags] : [],
                preview_description: data?.preview_description,
                preview_title: data?.preview_title,
                is_time_base: timeEvent.isAdded,
                time_condition: timeEvent.isAdded ? undefined : null,
                alt_title: altTitle,
                faq: {
                    title: data.title,
                    description: data.description,
                    questions,
                    owner_type: (type === 'create' || type === 'update') ? "contents" : 'organizations'
                }
            }
        }

        if (type === 'update') {

            if (!isContentTemplate) {
                const deleteFaqs = getDifference(defaultValue?.faq?.questions, shapeData?.faq?.questions)
                await Promise.all(deleteFaqs?.map(async (item) => await deleteFaqQues.mutateAsync({
                    faq_id: defaultValue?.faq?.id,
                    id: item?.id
                })))
                await Promise.all(shapeData?.faq?.questions?.map(async (item: updateFAQ) => {
                    if (item?.id) {
                        await updateFaqQues.mutateAsync({
                            question: item.question,
                            answer: item.answer,
                            faq_id: defaultValue?.faq?.id,
                            id: item.id
                        })
                    } else {
                        await createFaqQues.mutateAsync({
                            question: item.question,
                            answer: item.answer,
                            faq_id: defaultValue?.faq?.id
                        })
                    }
                }))
            }

            const timeId = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined

            if (timeEvent.isAdded) {
                if (defaultValue?.time_condition && timeId) {
                    await updateTimeEvent.mutateAsync({
                        id: timeId,
                        deliver_time: timeValue ? timeValue : 5,
                        deliver_unit: typeOf || "minute",
                        state_id: parseInt(defaultValue.state_id),
                    })
                } else await createTimeRule(defaultValue.id)
            }
            if (!timeEvent.isAdded && defaultValue?.is_time_base) {
                if (id) await deleteCondition.mutateAsync(timeId)
            }
            await UpDateFunc(shapeData)
        }
        // if (type === 'create') {
        //     let ruleID = (timeEvent.isRuleAdded && ruleId.event !== 0) ? ruleId?.event || null : null
        //     if (timeEvent.isRuleAdded && ruleId.event === 0) {
        //         if (query.rules.length !== 0) {


        //             // let data = null
        //             // if (passedContent.targetField && passedContent.targetFunction) {
        //             //     const resultCondition = await createRuleTimePassedCondition.mutateAsync({
        //             //         targetField: passedContent.targetField,
        //             //         targetFunction: passedContent.targetFunction
        //             //     })
        //             //     data = resultCondition?.data
        //             // } else {
        //             //     const resultRule = await createRuleCondition.mutateAsync()
        //             //     data = resultRule?.data
        //             // }
        //             // ruleID = data?.id || null
        //             ruleID = await createRuleConditionForContent()
        //         }
        //     }
        //     await createContents.mutateAsync({
        //         ...shapeData,
        //         state_id: parseInt(id),
        //         organization_id: organization_id,
        //         pathway_id: pathwaySlug,
        //         rules_content_id: ruleID
        //     })
        // }
    };

    const handleAddItem = () => {
        if (edit.isEdit) {
            const updatedItem = [...questions];
            updatedItem[edit.index] = updatedItem[edit.index] = {
                ...questions[edit.index],
                question: inputs.question,
                answer: inputs.answer
            };
            setValue('questions', updatedItem);
            setInputs({ question: '', answer: '' })
            setEdit({ isEdit: false, index: 0 })
            setLoading(true)

            setTimeout(() => setLoading(false), 100)
            return;
        }

        setValue("questions", [...questions, {
            question: inputs.question,
            answer: inputs.answer
        }])
        setInputs({ question: '', answer: '' })
        setLoading(true)

        setTimeout(() => setLoading(false), 100)

    }

    const handleEdit = (item: faqs, ind: number) => {
        setEdit({ isEdit: true, index: ind })
        setInputs({ question: item.question, answer: item.answer })
    }

    const handleRemove = (ind: number) => setValue('questions', questions.filter((_, index) => ind !== index))

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }
        const itemsData = reorder(
            questions,
            result.source.index,
            result.destination.index
        ) as faqQuestions[]
        setValue("questions", itemsData)
    };

    useEffect(() => {
        if (defaultValue) {
            const id = defaultValue?.is_time_base ? defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.id : undefined
            setRuleId({ time: id, event: defaultValue?.rules_content_id })

            if (defaultValue?.time_condition && defaultValue?.time_condition[0] && defaultValue?.time_condition[0]?.deliver_time) {
                //@ts-ignore
                setValue("timeValue", defaultValue?.time_condition[0]?.deliver_time)  //@ts-ignore
                setValue("typeOf", defaultValue?.time_condition[0]?.deliver_unit)
            }

            setAltTitle(defaultValue?.alt_title)

            setContentPriority(defaultValue?.priority)

        }
    }, [])

    const onUpdateContentFields = async (priority:number, alternativeTitle:string) => {
        const shapeData  = {
            ...defaultValue,
            priority: priority,
            alt_title: alternativeTitle
        }

        console.log('shapeData', shapeData);
        
        // return 
        await updateContentMutation.mutateAsync(shapeData)
    }


    const contentSlug = defaultValue?.content_template?.faq[0]?.slug || defaultValue?.slug

    return (
        <div className="flex items-center w-full">
            <div className="flex flex-col w-full gap-4">
                {
                    contentSlug ? 
                    <CustomClipboard showInfoIcon clipboardText={contentSlug} />
                    : null
                }
                
                {
                    type === 'update' && isContentTemplate === true ? <>
                    <FAQ contentTemplate={defaultValue?.content_template} 
                    priority={contentPriority} 
                    alternativeTitle={altTitle}
                    onUpdateFields={onUpdateContentFields} />
                </> : null
                }

                <form onSubmit={handleSubmit(onSubmit)}
                    className='w-full h-full pb-2 space-y-3 overflow-auto text-gray-600 text-md'>


                    {type === 'create' ? <>
                        {<>
                            <AppInput placeholder='Search' value={searchContentTemplate} onChange={e => setSearchContentTemplate(e.target.value)} name='searchTemplate' />
                            <div className='flex items-center justify-center'>
                                {faqCcontentTempalteIsLoading ? <AppLoader isLoading size={35} /> : null}
                            </div>

                            <div className='grid grid-cols-2 gap-1'>
                                {search_result?.length === 0 ? <p className='my-2 text-md'>There isn't any template to show.</p> : search_result && search_result.map((item, index) => {
                                    return <div className='flex flex-col items-start justify-start p-4 m-1 transition-all delay-300 rounded-md bg-gray-50 hover:bg-white' key={index}>
                                        <span
                                            onClick={() => addContentTemplate(item)}
                                            className='px-2 text-xs border rounded hover:cursor-pointer hover:bg-sky-100 border-sky-500 bg-sky-50 text-sky-700'>Add +</span>
                                        <p className='my-1 font-medium text-md'>{item.title}</p>
                                        <p className='flex-1 mb-3 text-xs'>
                                            {item.description}
                                        </p>
                                        <p className='my-1 text-xs'>
                                        {/* @ts-ignore */}
                                        Languages: <span className='font-medium uppercase text-sky-700'>{item.metaData?.map((item: any) => item.language).join(', ')}</span>
                                        </p>
                                        {item.updated_at ? <p className="mt-1 text-xs">Last updated {moment(item.updated_at).fromNow() + '. on ' + moment(item.updated_at).format('MMM D, YYYY')
                                        }</p> : null}
                                    </div>
                                })}
                            </div>

                            <div className="flex">
                                <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
                                    onClick={() => {
                                        mutate('previous')
                                    }}
                                    extendClass="mx-1">Previous</AppButton>
                                <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
                                    onClick={() => {
                                        mutate('next')
                                    }}
                                    extendClass="mx-1">Next</AppButton>
                            </div>
                        </>}


                    </> : null}

                    {
                        type === 'update' && isContentTemplate === true ? <>

                            {/*---------------------------------------------------------*/}
                            {/*FAQ Details and Content Details tabs*/}
                            {/* <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView()
                                    e.stopPropagation()
                                }} />} title="FAQ Details">FAQ
                                    Details</Tab>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView()
                                    e.stopPropagation()
                                }} />} title="Content Details">Content Details</Tab>
                            </Tabs> */}

                            {/*---------------------------------------------------------*/}
                            {/*FAQ Details tab with form inputs*/}
                            {/* <Fade in={tab === "FAQ Details"}
                                style={{ display: tab === "FAQ Details" ? '' : 'none' }}>
                                <div className="space-y-4">
                                    <AppInput label='Title' name='title' value={defaultValue?.content_template?.faq[0]?.title || ''} disabled isFull extendClass='my-2' />
                                    <AppTextArea rows={4} name='description' label='Description' value={defaultValue?.content_template?.faq[0]?.description || ''} disabled />
                                    <p className="text-lg">Add FAQ Items</p>

                                    {
                                        loading ? <CircularProgress /> :
                                            <div className="flex flex-col items-end p-4 space-y-2 bg-gray-200">


                                                <StandardInput label="Question" value={inputs.question}
                                                    onChange={(e) => setInputs({
                                                        ...inputs,
                                                        question: e.target.value
                                                    })}
                                                    disabled
                                                />

                                                <WYSIWYGEditor placeholder={"Answer"} value={inputs.answer}
                                                    onChange={(e) => setInputs({ ...inputs, answer: e })} />

                                                <Button onClick={handleAddItem}
                                                    disabled
                                                    label={
                                                        <p className="p-2 text-base text-white">
                                                            {edit.isEdit ? 'Update FAQ Item' : 'Add FAQ Item'}
                                                        </p>
                                                    } />
                                            </div>
                                    }


                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot): JSX.Element => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}
                                                    className="pt-4 space-y-2">

                                                    {defaultValue?.content_template?.faq[0]?.questions && defaultValue?.content_template?.faq[0]?.questions.map((item: any, index: number) => (
                                                        <Draggable key={index} draggableId={index.toString()} index={index}>
                                                            {(provided, snapshot): JSX.Element => (
                                                                <div
                                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="flex items-center w-full space-x-2">
                                                                        <NestedContentListItem label={item.question} />
                                                                        <MdModeEditOutline
                                                                            onClick={() => handleEdit(item, index)}
                                                                            className="text-2xl cursor-pointer" />
                                                                        <AiFillDelete
                                                                            onClick={() => handleRemove(index)}
                                                                            className="text-3xl cursor-pointer" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }


<AppThemeButton  type='button' onClick={() => { navigate(`/content-templates/faq/view/${defaultValue?.content_template?.id}`)  }} size='md' variant="info">
                                        <div className='flex items-center justify-between gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
</svg>
<span>Edit FAQ Template</span>
                                        </div>
                                        </AppThemeButton> 


                                </div>
                            </Fade> */}

                            {/*---------------------------------------------------------*/}
                            {/*Content Details tab with form inputs*/}
                            {/* <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div>
                                    <div>
                                        <label htmlFor='alt_title' className='mb-1 ml-1 text-sm'>Alternative Title</label>
                                        <input
                                            id="alt_title"
                                            type="text"
                                            className="block w-full p-4 text-gray-900 border border-gray-300 rounded-md text-md focus:ring-blue-500 focus:border-blue-500"
                                            value={altTitle}
                                            maxLength={30}
                                            onChange={e => {
                                                setAltTitle(prev => {
                                                    return e.target.value
                                                })
                                            }}
                                            placeholder='Alternative Title'
                                        />
                                    </div> */}

                                    {/* ======= */}
                                    {/*  <ContentExtra typeOf={typeOf} query={query} setQuery={setQuery}
                                prevTitle={watch("preview_title")}
                                rule={ruleId} setRule={setRuleId} name={name} defaultValue={defaultValue}
                                register={register} errors={errors}
                                render={render} setTimeEvent={setTimeEvent}
                                setPassedContent={setPassedContent}
                            /> */}

                                    {/* <div className="py-4 space-y-3">
                                        <div className="grid grid-cols-1 gap-3">
                                            <AppInput label="Content Card" name="content_card" value={defaultValue?.content_template?.content_metadata[0]?.preview_title} disabled isFull extendClass='my-2' />
                                            <AppInput
                                                label="Content Tag"
                                                name="content_tag"
                                                value={defaultValue?.content_template?.content_metadata?.[0]?.tags?.[0]}
                                                disabled
                                                isFull
                                                extendClass='my-2'
                                            />
                                                                                            {defaultValue?.content_template?.content_metadata[0]?.cover_image ? <div className="cover__image-container">
                                                <img className='rounded-sm' alt='cover image' title='cover image' src={defaultValue?.content_template?.content_metadata[0]?.cover_image} />
                                                </div> : null}
                                        </div>
                                    </div>
                                    <AppInput label="Content Priority" type='number' min={0} name="content_priority" onChange={e => setContentPriority(e.target.valueAsNumber)} value={contentPriority.toString()} isFull extendClass='my-2' /> */}

                                    {/* <Condition
                                        errors={errors}
                                        register={register}
                                        query={query}
                                        setQuery={setQuery}
                                        isContent
                                        rule={ruleId}
                                        setRule={setRuleId}
                                        setTimeEvent={setTimeEvent}
                                        name={name}
                                        typeOf={typeOf}
                                        setPassedContent={setPassedContent}
                                    /> */}

                                       {/*Submit form to create the FAQ*/}
                            {/* <Button
                                disabled={handleUpload.isLoading || createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || updateTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading || createFaqQues.isLoading || updateContentFaqs.isLoading || deleteFaqQues.isLoading}
                                type="submit" label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>Update</p>
                                    </div>
                                } extraClasses="w-fit px-3 py-3" />
                                </div>
                            </Fade> */}

                            {/*---------------------------------------------------------*/}
                         

                        </> : null
                    }


                    {
                        type === 'update' && isContentTemplate === false ? <>

                            <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handlePathwayView()
                                    e.stopPropagation()
                                }} />} title="FAQ Details">FAQ
                                    Details</Tab>
                                <Tab count={<BsFillEyeFill className="ml-2" onClick={(e) => {
                                    handleContentView()
                                    e.stopPropagation()
                                }} />} title="Content Details">Content Details</Tab>
                            </Tabs>

                            {/*FAQ Details tab with form inputs*/}
                            <Fade in={tab === "FAQ Details"}
                                style={{ display: tab === "FAQ Details" ? '' : 'none' }}>
                                <div className="space-y-4">
                                    <Input
                                        register={() => register("title", { required: 'title is required', maxLength: 140 })}
                                        name="title"
                                        placeholder="FAQ Title"
                                        errors={errors}


                                    />
                                    {
                                        //to stop textarea causing too many re-renders
                                        tab === "FAQ Details" &&
                                        <TextArea
                                            register={() => register("description", { maxLength: 140 })}

                                            name="description"

                                            errors={errors}
                                            placeholder="FAQ Description" />
                                    }

                                    <p className="text-lg">Add FAQ Items</p>

                                    {
                                        loading ? <CircularProgress /> :
                                            <div className="flex flex-col items-end p-4 space-y-2 bg-gray-200">


                                                <StandardInput label="Question" value={inputs.question}
                                                    onChange={(e) => setInputs({
                                                        ...inputs,
                                                        question: e.target.value
                                                    })}

                                                />

                                                <WYSIWYGEditor placeholder={"Answer"} value={inputs.answer}
                                                    onChange={(e) => setInputs({ ...inputs, answer: e })} />

                                                <Button onClick={handleAddItem}
                                                    disabled={inputs.question === "" || inputs.answer === ""}
                                                    label={
                                                        <p className="p-2 text-base text-white">
                                                            {edit.isEdit ? 'Update FAQ Item' : 'Add FAQ Item'}
                                                        </p>
                                                    } />
                                            </div>
                                    }


                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot): JSX.Element => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}
                                                    className="pt-4 space-y-2">

                                                    {questions.map((item: any, index: number) => (
                                                        <Draggable key={index} draggableId={index.toString()} index={index}>
                                                            {(provided, snapshot): JSX.Element => (
                                                                <div
                                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="flex items-center w-full space-x-2">
                                                                        <NestedContentListItem label={item.question} />
                                                                        <MdModeEditOutline
                                                                            onClick={() => handleEdit(item, index)}
                                                                            className="text-2xl cursor-pointer" />
                                                                        <AiFillDelete
                                                                            onClick={() => handleRemove(index)}
                                                                            className="text-3xl cursor-pointer" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                    {
                                        isLoading ? <UploadProgress progress={progress} /> : null
                                    }
                                </div>
                            </Fade>


                            <Fade in={tab === "Content Details"}
                                style={{ display: tab === "Content Details" ? '' : 'none' }}>
                                <div>
                                    {/* ===== */}

                                    <div>
                                        <label htmlFor='alt_title' className='mb-1 ml-1 text-sm'>Alternative Title</label>
                                        <input
                                            id="alt_title"
                                            type="text"
                                            className="block w-full p-4 text-gray-900 border border-gray-300 rounded-md text-md focus:ring-blue-500 focus:border-blue-500"
                                            value={altTitle}
                                            maxLength={30}
                                            onChange={e => {
                                                setAltTitle(prev => {
                                                    return e.target.value
                                                })
                                            }}
                                            placeholder='Alternative Title'
                                        />
                                    </div>

                                    {/* ======= */}
                                    <ContentExtra typeOf={typeOf} query={query} setQuery={setQuery}
                                        prevTitle={watch("preview_title")}
                                        rule={ruleId} setRule={setRuleId} name={name} defaultValue={defaultValue}
                                        register={register} errors={errors}
                                        render={render} setTimeEvent={setTimeEvent}
                                        setPassedContent={setPassedContent}
                                    />
                                </div>
                            </Fade>

                            {/*---------------------------------------------------------*/}
                            {/*Submit form to create the FAQ*/}
                            <Button
                                disabled={handleUpload.isLoading || createContents.isLoading || updateContentMutation.isLoading || createTimeEvent.isLoading || updateTimeEvent.isLoading || isLoading || updateTimeEvent.isLoading || deleteCondition.isLoading || createFaqQues.isLoading || updateContentFaqs.isLoading || deleteFaqQues.isLoading}
                                type="submit" label={
                                    <div className="flex items-center space-x-2 text-white">
                                        <AiFillFile className="text-3xl" />
                                        <p>Update</p>
                                    </div>
                                } extraClasses="w-fit px-3 py-3" />

                        </> : null
                    }


                </form>
            </div>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for FAQ Page*/}
            <OverlayModal onClose={handlePathwayView} isOpen={modal.pathwayView}>
                <div className="relative flex p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md h-fit w-fit">
                    <Mobile>
                        <PreviewContentModal questions={getValues("questions")} description={getValues("description")}
                            tags={[getValues("tags") || ""]}
                            type={'faq'}
                            title={title}
                            image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""} />
                    </Mobile>
                </div>
            </OverlayModal>

            {/*---------------------------------------------------------*/}
            {/*Preview modal for Video Content Card*/}
            <OverlayModal onClose={handleContentView} isOpen={modal.contentView}>
                <div className="h-fit overflow-auto bg-gray-100 p-2 rounded-md flex text-gray-600 w-[40%] relative">
                    <ContentTypeCardPreview
                        type={'faq'}
                        image={typeof imageSrc === 'string' ? imageSrc : defaultValue?.cover_image ? defaultValue?.cover_image : ""}
                        tags={[getValues("tags") || ""] || ['']}
                        title={getValues("preview_title") || defaultValue?.preview_title || ""}
                    />
                </div>
            </OverlayModal>

        </div>
    );
});

export default FAQNew;
