import {
  alNotifiactionsActions,
  emailActions,
  notificationActions,
  smsActions,
} from "../../Common/Types";
import API from "../index";

export const createActionInState = async (
  organ: number,
  pathway_slug: string,
  state_slug: string,
  values:
    | emailActions
    | smsActions
    | notificationActions
    | alNotifiactionsActions
) => {
  const { data } = await API.post(
    `/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/actions/`,
    { ...values }
  );
  return data;
};

export const deleteAction = async (
  organ: number,
  pathway_slug: string,
  state_slug: string,
  action_slug: string
) => {
  const { data } = await API.delete(
    `/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/actions/${action_slug}`
  );
  return data;
};

export const getAction = async (
  organ: number,
  pathway_slug: string,
  state_slug: string,
  action_slug: string
) => {
  const { data } = await API.get(
    `/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/actions/${action_slug}`
  );
  return data;
};

// emailActions | smsActions | notificationActions
export const updateAction = async (
  datas: any,
  organ: number,
  pathway_slug: string,
  state_slug: string,
  action_slug: string
) => {
  const { data } = await API.put(
    `/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/actions/${action_slug}`,
    { ...datas }
  );
  return data;
};
