import API from "../index";
import {addStateTem, StateTemplates_T, update_StateTemplates_T} from "../../Common/Types";

type value = {
    organization_id: number,
    flow_id: number
}

export const getAllStateTemplates = async (organ: number) => {
    const {data} = await API.get(`/organizations/state-templates/`);
    // const {data} = await API.get('/template/states');
    return data;
};

export const getAllAvailableStateTemplates = async (values: value) => {
    const {data} = await API.post('/template/available-state-templates', {...values});
    return data;
};

export const createStateTemplates = async (organ: number,values: StateTemplates_T) => {
    const {data} = await API.post(`/organizations/state-templates/`, {...values});
    // const {data} = await API.post('/template/states', {...values});
    return data;
};

export const addStateFromStateTemplate = async (values: addStateTem) => {
    const {data} = await API.post('/states/add-state-from-statetemplate', {...values});
    return data;
};

export const getSingleStateTemplate = async (organ: number,id: number) => {
    const {data} = await API.get(`/organizations/state-templates/${id}`);
    // const {data} = await API.get(`/admin/template/states/${id}`);
    return data;
};

export const deleteStateTemplate = async (organ: number, id: number) => {
    const {data} = await API.delete(`/organizations/state-templates/${id}`);
    // const {data} = await API.delete(`/template/states/${id}`);
    return data;
};

export const updateStateTemplate = async (organ: number,datas: update_StateTemplates_T, id: string) => {
    const {data} = await API.put(`/organizations/state-templates/${id}`, {...datas});
    // const {data} = await API.put(`/template/states/${id}`, {...datas});
    return data;
};





