import React, { FC, useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { useMutation } from "react-query";
import { EnumActionType, IActionMetadata, IActionTemplate } from '../../../../Types/ActionTemplate/ActionTemplate';
import { IActionTemplateFilterResponse } from "../../../../Types/ActionTemplate/ActionTemplate";
import AppButton from "../../../Shared/AppButton/AppButton";
import AppInput from "../../../Shared/AppInput/AppInput";
import { deleteActionTemplateById, getActionTemplateFilter } from "../../../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { toast } from "../../../../utils/Toast";


interface AllALNotificationActionProps {
    onEditClick: (alNotificationAction: IActionTemplate) => void
}



const AllALNotificationActions = (props: AllALNotificationActionProps, ref: any) => {

    const [search, setSearch] = useState<string>("");
    const activateSearch = useRef<boolean>(false)
    const [previousPage, setPreviousPage] = useState<string>();
    const [nextPage, setNextPage] = useState<string>();


    // TODO: add type & cache data
    const { data, isLoading, isError, error, mutate } = useMutation(
        (pageDirection: 'next' | 'previous' | '') => getActionTemplateFilter<IActionTemplateFilterResponse, EnumActionType.ALNotificationAction>
            ([EnumActionType.ALNotificationAction], 3, search, nextPage, previousPage, pageDirection), {
        onSuccess(data) {

            if (data?.data) {
                const { next, previous } = data?.data.paginate
                setNextPage(next)
                setPreviousPage(previous)
            }
        },
    });


    // enabled: activateSearch.current,
    // 	staleTime: Infinity
    //

    useImperativeHandle(ref, () => ({
        refresh: () => {
            mutate('')
        }
    }));

    useEffect(() => {
        mutate('')
    }, [mutate]);




    const searchHandler = () => {
        activateSearch.current = true
        mutate('')
        // setCurrentPage(1)
        // refetch()
    }


    const deleteActionTemplate = useMutation((params: { id: number }) => deleteActionTemplateById(params.id), {
        onSuccess: () => {
            toast("Action template deleted successfully", "success")
            mutate('')
        },
        onError: (error: any) => {

        },
    })

    const cofirmDeleteModal = (item: any) => {
        const actionTemplateId = item.id as number
        deleteActionTemplate.mutateAsync({ id: actionTemplateId })
        mutate('')
    }

    if (isLoading) return <h3>Loading...</h3>;
    if (isError)
        return (
            <>
                <h3>Oops, something went wrong</h3>
            </>
        );

    return (
        <div className="">

            <div className="flex items-center justify-start">
                <AppInput placeholder={"Search"} value={search} name='search' onChange={(e) => setSearch(e.target.value)} extendClass="mr-5" />
                <AppButton variant="primary" type={"button"} size={"md"} onClick={searchHandler} extendClass="mx-2">Search</AppButton>
            </div>
            <div className="flex items-center justify-between">
                <h1 className="mx-2 my-2 font-semibold">All Saved AL Notifications Templates</h1>
                <div className="flex">
                    <AppButton variant="info" type={"button"} size={"sm"} disabled={!previousPage}
                        onClick={() => {
                            activateSearch.current = true
                            mutate('previous')
                        }}
                        extendClass="mx-1">Previous Template</AppButton>
                    <AppButton variant="info" type={"button"} size={"sm"} disabled={!nextPage}
                        onClick={() => {
                            mutate('next')
                            activateSearch.current = true
                        }}
                        extendClass="mx-1">Next Template</AppButton>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-2">
                {data?.data.data.map((item, idx) => {
                    return (
                        <div className="p-2 border rounded border-gray-200 bg-[#e5e5e5]" key={idx}>
                            <p>Action template: {item.action_metadata.map((item, idx) => (<span key={idx} className="mr-1 font-semibold">{item.language},</span>))}</p>
                            {item.action_metadata.map((al_notification: IActionMetadata, idx: number) => {
                                return (

                                    <div key={idx}>
                                        {al_notification.language === "en" ? (
                                            <div>
                                                <AppInput value={al_notification.title as string} name={"title"} disabled isFull />
                                            </div>) : null}
                                    </div>
                                )
                            })}
                            <div className="flex">
                                <AppButton variant="info" type={"button"} size={"sm"} onClick={() => props.onEditClick(item)} extendClass="mr-2">Edit Template</AppButton>
                                <AppButton variant="danger" type={"button"} size={"sm"} onClick={() => cofirmDeleteModal(item)}>Delete Template</AppButton>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


AllALNotificationActions.displayName = "All AL Notifications Actions"
export default forwardRef(AllALNotificationActions)
