import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  FC,
} from "react";

export interface ITab {
  index?: number;
  name: string;
  path: string;
}

interface IState {
  tabs: {
    activeTab: ITab;
    allTabs: ITab[];
  };
}

const initialState: IState = {
  tabs: {
    activeTab: {
      index: 0,
      name: "workers",
      path: "/workers/",
    },
    allTabs: [
      {
        index: 0,
        name: "workers",
        path: "/workers/",
      },
    ],
  },
};

export type ContextType = {
  globalTabs: IState;
  setTab: (tab: ITab) => void;
  setActiveTab: (tab: ITab) => void;
  updateTab: (tab: ITab) => void;
  closeTab: (tab: ITab) => void;
  closeAllTabs: () => void;
};

export const TabContext = createContext<ContextType>({
  globalTabs: initialState,
  setActiveTab: (tab: ITab) => {},
  updateTab: () => {},
  setTab: (tab: ITab) => {},
  closeTab: () => {},
  closeAllTabs: () => {},
});

interface TabProviderProps {
  children: React.ReactNode;
}

export const TabProvider: FC<TabProviderProps> = ({ children }) => {
  const [globalTabs, setGlobalTabs] = useState<IState>(initialState);

  useEffect(() => {
    // Load tabs from localStorage on component mount
    const savedTabs = localStorage.getItem("tabs");
    if (savedTabs) {
      setGlobalTabs(JSON.parse(savedTabs));
    }
  }, []);

  useEffect(() => {
    // Save tabs to localStorage whenever it changes
    localStorage.setItem("tabs", JSON.stringify(globalTabs));
  }, [globalTabs]);

  const setTab = useCallback(
    (tab: ITab) => {
      const lastIndex = globalTabs.tabs.allTabs[
        globalTabs.tabs.allTabs.length - 1
      ].index as number;
      const newTab = {
        ...tab,
        index: lastIndex + 1,
      };

      setGlobalTabs((prev) => {
        return {
          ...prev,
          tabs: {
            activeTab: newTab,
            allTabs: [...prev.tabs.allTabs, newTab],
          },
        };
      });
    },
    [globalTabs.tabs.allTabs]
  );

  const setActiveTab = useCallback(
    (tab: ITab) => {
      const findTab = globalTabs.tabs.allTabs.find(
        (t) => t.index === tab.index
      ) as ITab;
      setGlobalTabs((prev) => {
        return {
          ...prev,
          tabs: {
            ...prev.tabs,
            activeTab: findTab,
          },
        };
      });
    },
    [globalTabs.tabs.allTabs]
  );

  const closeTab = useCallback(
    (tab: ITab) => {
      if (globalTabs.tabs.allTabs.length === 1) {
        return;
      }
      const updatedTabs = globalTabs.tabs.allTabs.filter(
        (t) => t.index !== tab.index
      );

      if (tab.index === globalTabs.tabs.activeTab.index) {
        setGlobalTabs((prev) => {
          return {
            ...prev,
            tabs: {
              ...prev.tabs,
              allTabs: updatedTabs,
              // activeTab: updatedTabs[0]
              activeTab: updatedTabs[updatedTabs.length - 1],
            },
          };
        });
      } else {
        setGlobalTabs((prev) => {
          return {
            ...prev,
            tabs: {
              ...prev.tabs,
              allTabs: updatedTabs,
            },
          };
        });
      }
    },
    [globalTabs.tabs.activeTab.index, globalTabs.tabs.allTabs]
  );

  const updateTab = useCallback(
    (tab: ITab) => {
      const findIndex = globalTabs.tabs.allTabs.findIndex(
        (t) => t.index === tab.index
      );
      const newTabs = [...globalTabs.tabs.allTabs];
      newTabs[findIndex] = tab;
      setGlobalTabs((prev) => {
        return {
          ...prev,
          tabs: {
            activeTab: tab,
            allTabs: newTabs,
          },
        };
      });
    },
    [globalTabs.tabs.allTabs]
  );

  const closeAllTabs = useCallback(() => {
    setGlobalTabs(initialState);
  }, []);

  const value = {
    globalTabs,
    setTab,
    setActiveTab,
    updateTab,
    closeTab,
    closeAllTabs,
  };

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};
