import React, {memo, useContext, useMemo} from 'react';
import {MdClose} from "react-icons/md";
import Tabs from "../../Tabs/Tabs";
import UseTab from "../../../Hook/UseTab";
import {useQuery} from "react-query";
import {StateTemplates_T} from "../../../Common/Types";
import {getSingleStateTemplate} from "../../../apiQuery/State/Template.apis";
import NestedContentListItem from "../../Content/NestedContentListItem";
import {Tooltip} from "@mui/material";
import Tab from "../../Tabs/Tab";
import {store} from "../../../GlobalState/store";

type props = {
    modalHandle: () => void,
    currentId: number
}

const ViewStateTemplateModal = memo(({modalHandle, currentId}: props) => {
    const [tab, setTab] = UseTab('Contents')
    // const {globalState, dispatch} = useContext(globalContext);
    const {
        state: globalState
    } = useContext(store);
    const organization_iD = useMemo(() => globalState.organization_id, [globalState.organization_id])

    const {
        isLoading,
        isError,
        status,
        data,
        error,
        refetch,
        isFetching
    } = useQuery<StateTemplates_T, Error>(['state-template', currentId.toString()], () => getSingleStateTemplate(organization_iD,currentId), {
        enabled: !!currentId
    })

    return (
        <div
            className="h-full overflow-auto modal bg-gray-100 p-8 rounded-md flex text-gray-600 w-8/12 relative">
            {
                isLoading ? (
                        "Loading..."
                    ) :
                    isError ? (
                            <span>Error: {error?.message || "Something went wrong!"}</span>
                        ) :
                        <div className="w-full space-y-6">
                            <div className="w-full flex justify-between items-start text-lg">
                                <div className="space-y-2">
                                    <p className=" text-2xl line-clamp-2">{data?.name}</p>
                                    <p>{data?.description}</p>
                                </div>
                                <div onClick={modalHandle} className="bg-gray-400 cursor-pointer rounded-full p-1">
                                    <MdClose className="text-white"/>
                                </div>
                            </div>
                            <Tabs selectedTab={tab} setSelectedTab={setTab}>
                                <Tab count={`(${data?.content_templates?.length || 0})`} title="Contents">Contents</Tab>
                                <Tab count={`(${data?.connected_flows?.length || 0})`} title="Linked Flows">Linked
                                    StateFlows</Tab>
                            </Tabs>

                            {
                                tab === "Contents" &&
                                <>
                                    {
                                        data?.content_templates?.map((content, index) => {
                                                const title = content.article?.title || content.video?.title || content.direction?.title || content.checklist?.title || content.selector?.title || content?.picture?.title || content.announcement?.title || content.faq?.title || ""
                                                return (
                                                    <div key={index} className="flex justify-between items-center">
                                                        <div className="flex items-center space-x-4 w-full">
                                                            <div className="w-[10rem]">
                                                                <NestedContentListItem label={content.content_template_type}
                                                                                       extraClasses={`cursor-pointer bg-[#65B7CB] text-white`}/>
                                                            </div>
                                                            <p>{title}</p>
                                                        </div>

                                                        <Tooltip
                                                            arrow
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        maxWidth: '20rem',
                                                                        bgcolor: '#65B7CB',
                                                                        '& .MuiTooltip-arrow': {
                                                                            color: '#65B7CB',
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            title={
                                                                <div className="bg-[#65B7CB] p-3 px-2 text-white text-lg">
                                                                    {/*todo: reuse this component as this repeat twice in (ViewContentTemplateModal.tsx)*/}
                                                                    {(() => {
                                                                        switch (content.content_template_type) {
                                                                            case 'article':
                                                                                return <div>
                                                                                    <div dangerouslySetInnerHTML={{ __html: content?.article?.body || '' }} />
                                                                                    <div className="flex space-x-3">
                                                                                        <p>Description:</p>
                                                                                        <p>{content?.article?.description}</p>
                                                                                    </div>
                                                                                </div>

                                                                            case 'video':
                                                                                return <div className="space-y-2">
                                                                                    <video>{content.video?.file}</video>
                                                                                    <div className="flex space-x-3">
                                                                                        <p>Description:</p>
                                                                                        <p>{content?.video?.description}</p>
                                                                                    </div>
                                                                                </div>

                                                                            case 'checklist':
                                                                                return <div className="space-y-2">
                                                                                    <p>List Item</p>
                                                                                    <div>
                                                                                        {
                                                                                            content?.checklist?.items?.map((item) =>
                                                                                                <li>
                                                                                                    {item}
                                                                                                </li>)
                                                                                        }
                                                                                    </div>
                                                                                    <div className="flex space-x-3">
                                                                                        <p>Description:</p>
                                                                                        <p>{content?.checklist?.description}</p>
                                                                                    </div>
                                                                                </div>
                                                                            case 'direction':
                                                                                return <div className="space-y-2">
                                                                                    <p>{content?.direction?.address}</p>
                                                                                    <div className="flex space-x-3">
                                                                                        <p>Description:</p>
                                                                                        <p>{content?.direction?.description}</p>
                                                                                    </div>
                                                                                </div>
                                                                            case 'selector':
                                                                                return <div className="space-y-2">
                                                                                    <p>{content?.selector?.title}</p>
                                                                                    <div className="flex space-x-3">
                                                                                        <p>Description:</p>
                                                                                        <p>{content?.selector?.description}</p>
                                                                                    </div>
                                                                                </div>
                                                                            case 'announcement':
                                                                                return <div className="space-y-2">
                                                                                    <p>{content?.announcement?.title}</p>
                                                                                    <div className="flex space-x-3">
                                                                                        <p>Description:</p>
                                                                                        <p>{content?.announcement?.description}</p>
                                                                                    </div>
                                                                                </div>
                                                                            case 'faq':
                                                                                return <div className="space-y-2">
                                                                                    <p>{content?.faq?.title}</p>
                                                                                    <div className="flex space-x-3">
                                                                                        <p>Description:</p>
                                                                                        <p>{content?.faq?.description}</p>
                                                                                    </div>
                                                                                </div>
                                                                            default:
                                                                                return null
                                                                        }
                                                                    })()}
                                                                </div>}>
                                                            <p>(Preview)</p>
                                                        </Tooltip>

                                                    </div>
                                                )
                                            }
                                        )
                                    }

                                </>
                            }

                            {
                                tab === "Linked Flows" &&
                                <div>
                                    {
                                        data?.connected_flows?.map((item, index) =>
                                            <div key={index} className="font-semibold flex space-x-1.5 py-1">
                                                <p>State Linked in </p>
                                                <p className="text-yellow-700">Pathways "{item.name}"</p>

                                            </div>)
                                    }

                                </div>
                            }
                        </div>
            }

        </div>
    );
});

export default ViewStateTemplateModal;