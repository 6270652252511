import React, {InputHTMLAttributes} from 'react'

interface Options {
	value: string
	label: string
}

export type AppDropDownProps = {
	options: Options[]
	value: string
	name: string
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
	isFull?: boolean
	type?: string
	label?: string
	error?: string
	extendClass?: string
	disabled?: boolean
} & InputHTMLAttributes<HTMLSelectElement>

const AppDropDown: React.FC<AppDropDownProps> = props => {
	const {options ,label, name, type, value, onChange, error, onBlur, isFull, disabled, extendClass, ...rest} = props
	return (
		<div className={`flex flex-col space-y-1 my-2 ${isFull ? 'w-full' : ''}`}>
			{label && (
				<label htmlFor={name} className='block mb-1 text-base font-normal text-black dark:text-white'>
					{label}
				</label>
			)}
			<select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...rest}
        className={
        ` border border-borderGray bg-[#F5F5F5] placeholder:text-[#A4A5A5] text-gray-900 text-md rounded-sm 
		disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-800
				focus:ring-blueColor
				focus:border-blueColor
				block w-full cursor-pointer
         ${extendClass || ''}
         `
        }>
        {options.map((option, idx) => { return <option key={idx} value={option.value}>{option.label}</option> })}
      </select>
			{error && <span className='mt-2 text-xs text-red-500'>{error}</span>}
		</div>
	)
}

export default AppDropDown
