import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import Organization from "./Organization";
// import Organization from "./Organization";
import DeleteAccount from "./DeleteAccount";
import { useQuery } from "react-query";
import { organization_T } from "../../../../Common/Types";
import { getOrganization } from "../../../../apiQuery/Organization/Organization.apis";
import { store } from "../../../../GlobalState/store";
import GeneralTab from "./OrganizationTabs/GeneralTab";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import SecurityTab from "./OrganizationTabs/SecurityTab";
import PreferncesTab from "./PersonalTabs/PreferencesTab";
import NotificationsTab from "./PersonalTabs/NotificationsTab";
import MembersTab from "./OrganizationTabs/MembersTab";
import WorkdayTab from "./OrganizationTabs/WorkdayTab";
import WorkspaceOneTab from "./OrganizationTabs/WorkspaceOneTab";
import ConditionsTab from "./PersonalTabs/ConditionsTab";
import LinksTab from "./OrganizationTabs/LinksTab";
import WorkersTab from "./OrganizationTabs/WorkersTab";
import SupportLinks from "./OrganizationTabs/SupportLinks";

type props = {
  modalHandle: () => void;
};
const Setting = memo(({ modalHandle }: props) => {
  const [selectedItem, setSelectItem] = useState("General");
  const { user } = useAuth0();

  const { state, dispatch } = useContext(store);
  const organization_id = useMemo(
    () => state.organization_id,
    [state.organization_id]
  );

  const { isLoading, data, error } = useQuery<organization_T, Error>(
    ["organization", organization_id],
    () => getOrganization(organization_id),
    {}
  );

  const [openIntegrationMenu, setOpenIntegrationMenu] = useState(false)

  const organizationsMenuList = [
    // {
    //   item: "Overview",
    //   click: () => setSelectItem("Overview"),
    // },
    {
      item: "General",
      click: () => setSelectItem("General"),
    },
    {
      item: "Members",
      click: () => setSelectItem("Members"),
    },
    {
      item: "Workers",
      click: () => setSelectItem("Workers"),
    },
    {
      item: "Support Links",
      click: () => setSelectItem("Support Links"),
    },
    // {
    //   item: "Security",
    //   click: () => setSelectItem("Security"),
    // },
    {
      item: "Links",
      click: () => setSelectItem("Links"),
    },
    // {
    //   item: "Profile",
    //   click: () => setSelectItem("Profile"),
    // },
    // {
    //   item: "Integrations",
    //   click: () => setSelectItem("Integrations"),
    //   submenus: ['Workday', 'Workspace One']
    // },
  ];
  const integrationsList = [
    {
      item: "Workday",
      click: () => setSelectItem("Workday"),
    },
    {
      item: "Workspace One",
      click: () => setSelectItem("Workspace One"),
    }
  ];

  const personal = [
    // {
    //   item: "Profile",
    //   click: () => setSelectItem("Profile"),
    // },
    {
      item: "Preferences",
      click: () => setSelectItem("Preferences"),
    },
    {
      item: "Notifications",
      click: () => setSelectItem("Notifications"),
    },
    {
      item: "Conditions",
      click: () => setSelectItem("Conditions"),
    },
  ];


  useEffect(() => {
      if(selectedItem === "Workday" || selectedItem === "Workspace One"){
        setOpenIntegrationMenu(true)}
  } , [selectedItem])

  return (
    <div className="relative z-50 flex w-3/5 h-full mt-5 overflow-auto bg-white rounded-md shadow-md text-textDark modal">
      <div className="sticky top-0 flex flex-col w-3/12 py-8 space-y-10 bg-white border border-borderGray">
        <div className="space-y-2">
          <p className="px-4 text-sm text-gray-400 uppercase">{user?.email}</p>
          <ul className="mb-5">
            <li className="px-4 py-2">
              <p className="text-sm text-textGray">Organization</p>
            </li>
            <li className="">
              {organizationsMenuList.map(({ item, click }, index) => (
                <div
                  onClick={click}
                  key={index}
                  className={`flex rounded-sm items-center space-x-3 px-4 py-1 m-0 transition-all hover:bg-primary cursor-pointer ${item === selectedItem && "bg-primary"
                    }`}>
                  <p className={`flex w-full items-center justify-between text-md ${item === selectedItem ? "text-blueColor" : "text-textDark"} `}><span>{item}</span></p>
                </div>
              ))}

              <div
                onClick={() => setOpenIntegrationMenu(prev => !prev)}
                className={`flex flex-col rounded-sm  py-2 m-0 transition-all cursor-pointer ${"Integrations" === selectedItem && "bg-primary"
                  }`}>
                <p className={`flex w-full items-center px-3 justify-between text-sm ${"Integrations" === selectedItem ? "text-blueColor" : "text-textDark"} `}><span>Integrations</span>
                  {
                    openIntegrationMenu ? <ChevronUpIcon className="w-4 h-4 text-textGray" /> : <ChevronDownIcon className="w-4 h-4 text-textGray" />
                  }</p>
                {
                  openIntegrationMenu ? <ul className="mt-1 list-none">

                    {integrationsList.map(({ item, click }, index) => (
                      <div
                        onClick={click}
                        key={index}
                        className={`flex rounded-sm items-center space-x-3 px-4 py-1 m-0 transition-all hover:bg-primary cursor-pointer ${item === selectedItem && "bg-primary"
                          }`}>
                        <p className={`flex w-full items-center justify-between text-md ${item === selectedItem ? "text-blueColor" : "text-textDark"} `}><span>{item}</span></p>
                      </div>
                    ))}
                  </ul> : null
                }
              </div>

            </li>
          </ul>

          {/* <ul className="mb-3">
            <li className="px-4 py-2 mt-12">
              <p className="text-sm text-textGray">Personal</p>
            </li>
            {personal.map(({ item, click }, index) => {
              return (
                <li key={index}>
                  <div
                    onClick={click}
                    className={`flex rounded-sm items-center space-x-3 px-4 py-1 m-0 transition-all hover:bg-primary cursor-pointer ${item === selectedItem && "bg-primary "
                      }`}
                  >
                    <p
                      className={`text-md ${item === selectedItem
                        ? "text-blueColor"
                        : "text-textDark"
                        } `}
                    >
                      {item}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul> */}
        </div>
      </div>

      <div className="flex w-9/12 px-16 py-10">
        <div className="w-full space-y-8 text-lg">
          {(() => {
            switch (selectedItem) {
              // case "Security":
              //   return <SecurityTab />;
              case "Workday":
                return <WorkdayTab organization={data} />;
              case "Workspace One":
                return <WorkspaceOneTab />;
              // case "Profile":
              //   return <DeleteAccount />;
              case "General":
                return <GeneralTab organization={data} />;
              case 'Preferences':
                return <PreferncesTab />
              case 'Notifications':
                return <NotificationsTab />
              case 'Conditions':
                return <ConditionsTab />
              case 'Links':
                return <LinksTab />
              // case "Overview":
              //   return <Organization organization={data} />;
              case "Workers": 
                return <WorkersTab />
  
              case "Support Links": 
                return <SupportLinks />
                
              case "Members":
                return <MembersTab />
              case "Delete User Record":
                return <DeleteAccount />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
});

export default Setting;
