import React, {FC} from 'react';
import {MdCheckCircle} from "react-icons/md";
import {ContCommon} from "../../../Common/Types";

export type listItem = {
    title: string,
}

const ChecklistItem = (prop: listItem) => {
    return (
        <div className='flex flex-row mb-4'>
            <div className='mr-3'><MdCheckCircle className='text-2xl text-gradient-stop'/></div>
            <div className='w-full text-gray-600'>{prop.title}</div>
        </div>
    )
}

const ChecklistPage: FC<ContCommon> = (prop) => {
    return (

        <div className="bg-white w-full h-full flex flex-col p-4 whitespace-pre-wrap rounded-b-md">
            {
                prop.items?.map((mame, index) => <ChecklistItem key={index} title={mame}/>)
            }
        </div>

    );
};

export default ChecklistPage;