import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type prop = {
    onChange: (val: string) => void
    value: string
    placeholder: string
}

const WYSIWYGEditor: FC<prop> = ({ onChange, value, placeholder }) => {


    const [editorState, setEditorState] = useState(() => {
        // Initialize editorState only once, using a function inside useState
        if (value) {
          const blocksFromHtml = convertFromHTML(value);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHtml.contentBlocks,
            blocksFromHtml.entityMap
          );
          return EditorState.createWithContent(contentState);
        } else {
          return EditorState.createEmpty();
        }
      });
    
      useEffect(() => {
        // Update editorState when value changes
        if (value !== draftToHtml(convertToRaw(editorState.getCurrentContent()))) {
          const blocksFromHtml = convertFromHTML(value);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHtml.contentBlocks,
            blocksFromHtml.entityMap
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
      }, [value]);
    
      const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        onChange(html);
      };



    console.log('`value from upper`', value)

    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // const [updated, setUpdated] = useState(false);


    // useEffect(() => {
    //     // if (!updated) {
    //     if(value)  {
    //         const defaultValue = value ? value : "";
    //         const blocksFromHtml = htmlToDraft(defaultValue);
    //         const contentState = ContentState.createFromBlockArray(
    //             blocksFromHtml.contentBlocks,
    //             blocksFromHtml.entityMap
    //         );
    //         const newEditorState = EditorState.createWithContent(contentState);
    //         setEditorState(newEditorState);
    //     }
            
    //     // }
    // }, [value]);

    return (
        <React.Fragment>

            <div className=" flex-col w-ful min-h-[16rem] p-0.5 ">
                <label
                    className="block mb-1 text-base font-normal text-black"
                >
               {placeholder}
                </label>{" "}
                <Editor
                    editorClassName="p-2  block w-full rounded-sm border border-borderGray bg-[#F5F5F5] placeholder:text-[#A4A5A5] p-2.5 text-md text-gray-900 
                    focus:ring-blueColor
                    focus:border-blueColor
                    disabled:opacity-50
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:border-gray-300
                    disabled:text-gray-800"
                    wrapperClassName="w-full h-36"
                    editorStyle={{ minHeight: "6rem" }}

                    placeholder={placeholder}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji', 'image', 'history'],
                        inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline']
                        },
                        list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['unordered', 'ordered'],
                            // unordered: { icon: unordered, className: undefined },
                            // ordered: { icon: ordered, className: undefined },
                            // indent: { icon: indent, className: undefined },
                            // outdent: { icon: outdent, className: undefined },
                        },
                    }}
                    spellCheck
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                />
            </div>
        </React.Fragment>
    );
};

export default WYSIWYGEditor;