export const SmsIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      aria-hidden='true'
      className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
    >
      <path fill="currentColor" d="M309.846 529.154q14.693 0 25.039-10.347 10.346-10.346 10.346-25.038t-10.346-25.038q-10.346-10.346-25.039-10.346-15.076 0-25.23 10.346t-10.154 25.038q0 14.692 10.154 25.038 10.154 10.347 25.23 10.347zm172.385 0q14.692 0 25.038-10.347 10.346-10.346 10.346-25.038t-10.346-25.038q-10.346-10.346-25.038-10.346t-25.038 10.346q-10.347 10.346-10.347 25.038t10.347 25.038q10.346 10.347 25.038 10.347zm167.307 0q14.692 0 25.038-10.347 10.346-10.346 10.346-25.038t-10.346-25.038q-10.346-10.346-25.038-10.346T624.5 468.731q-10.346 10.346-10.346 25.038t10.346 25.038q10.346 10.347 25.038 10.347zM100.001 937.536V253.694q0-23 17.082-40.346 17.082-17.347 40.611-17.347h644.612q23 0 40.346 17.347 17.347 17.346 17.347 40.346v484.612q0 23-17.347 40.346-17.346 17.347-40.346 17.347H241.539L100.001 937.536z"></path>
    </svg>
  )
};