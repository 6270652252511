import {
    addStateTem,
    ContentTemplates_T,
    convert,
    convertContent,
    updateContentTempl,
    valueAdd
} from "../../Common/Types";
import API from "../index";

export const createContentTemplates = async (organ: number, values: ContentTemplates_T) => {
    const {data} = await API.post(`/organizations/content-templates/`, {...values});
    // const {data} = await API.post('/template/contents', {...values});
    return data;
};

export const getAllContentTemplates = async (organ: number) => {
    const {data} = await API.get(`/organizations/content-templates/`);
    // const {data} = await API.get('/template/contents');
    return data;
};

export const getSingleContentTemplate = async (organ: number, id: number) => {
    const {data} = await API.get(`/organizations/content-templates/${id}`);
    // const {data} = await API.get(`/admin/template/contents/${id}`);
    return data;
};

export const deleteContentTemplate = async (id: number) => {
    const {data} = await API.delete(`/template/contents/${id}`);
    return data;
};

export const updateContentTemplate = async (datas: updateContentTempl, id: number) => {
    const {data} = await API.put(`/template/contents/${id}`, {...datas});
    return data;
};

export const getAllAvailableContentTemplates = async (values: convert) => {
    const {data} = await API.post('/template/available-content-templates', {...values});
    return data;
};

export const addContentFromContentTemplate = async (values: valueAdd) => {
    const {data} = await API.post('/states/add-content-from-contenttemplate', {...values});
    return data;
};

export const convertContentToContentTemplate = async (organ: number, pathwayId: number, state_id: string, content_id: number, values: convertContent) => {
    const {data} = await API.post(`/admin/organizations/pathways/${pathwayId}/states/${state_id}/contents/${content_id}/convert-to-template`, {...values});
    // const {data} = await API.post('/template/convert-content-to-contenttemplate', {...values});
    return data;
}

export const removeLinkToContentTemplate = async (organ: number, pathwayId: number, state_id: string, content_id: number,template_id:number) => {
    const {data} = await API.put(`admin/organizations/pathways/${pathwayId}/states/${state_id}/contents/${content_id}/remove-template-link?content_template=${template_id}`)
    // const {data} = await API.post(`/contents/remove-link/${id}`);
    return data;
}