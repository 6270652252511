import React, {
  FC,
  useState,
  useEffect,
  Fragment,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from "react";
import { THead, Table, Loader, ArrowIcon, ContentTemplateIcons, TabBar, AppButton } from "../../Theme";
import { useQuery } from "react-query";
import {
  getContentTemplateBySearch,
} from "../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { EnumContentType } from "../../Types/ContentTemplate/ContentType";
import { Drawer } from "../../Theme/Drawer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "../../utils/Toast";
import { TabContext } from "../../Context/TabContext/TabContext";
import AppSearch from "../Shared/AppSearch/AppSearch";
import { debounce } from "lodash";

const contentTypeDescription: Record<EnumContentType, string> = {
  [EnumContentType.ArticleContent]: "Article",
  [EnumContentType.AnnouncementContent]: "Announcement",
  [EnumContentType.ChecklistContent]: "Checklist",
  [EnumContentType.ConsentContent]: "Consent",
  [EnumContentType.DirectionContent]: "Direction",
  [EnumContentType.VideoContent]: "Video",
  [EnumContentType.FAQContent]: "FAQ",
  [EnumContentType.SelectorContent]: "Selector",
  [EnumContentType.SurveyContent]: "Survey",
  [EnumContentType.SurveySequenceContent]: "Survey Sequence",
};

type SelectedContentTemplate = {
  title: string;
  count: string;
};

const contentTemplatesTypes = [
  {
    id: 1,
    field: "announcement",
    label: "Announcement",
    condition: "includes",
    value: "",
    type: "text",
    icon: <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">{ContentTemplateIcons.announcement}</div>
  },
  {
    id: 12,
    field: "article",
    label: "Article",
    condition: "includes",
    value: "",
    type: "text",
    icon: <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">{ContentTemplateIcons.article}</div>
  },
  {
    id: 3,
    field: "checklist",
    label: "Checklist",
    condition: "includes",
    value: "",
    type: "text",
    icon: <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">{ContentTemplateIcons.checklist}</div>
  },
  {
    id: 4,
    field: "video",
    label: "Video",
    condition: "includes",
    value: "",
    type: "text",
    icon: <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">{ContentTemplateIcons.video}</div>
  },
  {
    id: 5,
    field: "faq",
    label: "FAQ",
    condition: "includes",
    value: "",
    type: "text",
    icon: <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">{ContentTemplateIcons.faq}</div>
  },
  {
    id: 6,
    field: "selector",
    label: "Selector",
    condition: "includes",
    value: "",
    type: "text",
    icon: (
      <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">
        {ContentTemplateIcons.selector}
      </div>
    ),
  },
  {
    id: 11,
    field: "consent",
    label: "Consent",
    condition: "is",
    value: "",
    type: "text",
    icon: (
      <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">
        {ContentTemplateIcons.consent}
      </div>
    ),
  },
  {
    id: 7,
    field: "direction",
    label: "Direction",
    condition: "is",
    value: "",
    type: "text",
    icon: (
      <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">
        {ContentTemplateIcons.direction}
      </div>
    ),
  },
  {
    id: 8,
    field: "survey",
    label: "Survey",
    condition: "is",
    value: "",
    type: "text",
    icon: (
      <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">
        {ContentTemplateIcons.survey}
      </div>
    ),
  },
  {
    id: 9,
    field: "survey_sequence",
    label: "Survey Sequence",
    condition: "is",
    value: "",
    type: "text",
    icon: (
      <div className="flex-shrink-0 w-5 h-5 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white">
        {ContentTemplateIcons.survey_sequence}
      </div>
    ),
  },
];


//* Start function 
const ContentTemplatePage: FC = () => {
  const navigate = useNavigate();


  //! States 
  const { updateTab, globalTabs } = useContext(TabContext);
  const [showDrawer, setShowDrawer] = useState(false);
  const [allFitlers, setAllFilters] = useState(contentTemplatesTypes);


  //! States for filter | search 
  const [filterKey, setFilterKey] = useState<any>(null);

  //! Debounce function - Debounce to prevent frequent API requests 
  const debounceFunction = useMemo(() => {
   return debounce((item:any) => setFilterKey(item) ,700)
  }, [filterKey]);


  //! API - Fetch data from API using filterKey
  const { data, isLoading, error } = useQuery(
    ["contentTemplates",filterKey],
    () => getContentTemplateBySearch(filterKey)
  );

  //! Initialize state with an empty object for all data that fetched
  const [list, setCounts] = useState({}); 



  // ============================== HOOKS ===============================
  const tableRef = useRef<HTMLTableSectionElement | null>(null);


  //!UseEffect for Update search result 
  useEffect(() => {
    if (data && data?.data) {
      const _data = data?.data?.data;
      const _counts: any = {};
      _data.forEach((item: any) => {
        if (item && item?.content_type) {
          const contentType = item?.content_type;
          // Check if contentType already exists in _counts, if not, set it to 1, otherwise increment by 1
          // @ts-ignore
          _counts[contentType] = _counts[contentType]
            ? _counts[contentType] + 1
            : 1;
        }
      });
      setCounts(_counts); // Update state with the new counts
    }
  }, [data]);

  // ==================================================================

  const [selectedContentTemplate, setSelectedContentTemplate] =
    useState<SelectedContentTemplate>({
      title: "",
      count: "0",
    });

  // ========================== DRAWER  ===============================

  const closeDrawer = useCallback(() => setShowDrawer(false), []);

  const showDrawerHandler = useCallback(
    (selectedContentTemplate: SelectedContentTemplate) => {
      if (!showDrawer) setShowDrawer(true);
      setSelectedContentTemplate(selectedContentTemplate);
    },
    [showDrawer]
  );


  const filterRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [searchFitlerText, setSearchFilterText] = useState("");

  // Search in filters base on label
  const searchFilterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(e.target.value);
    if (e.target.value !== "") {
      const newFilters = allFitlers.filter((item: any) =>
        item.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setAllFilters(newFilters);
    } else {
      setAllFilters(contentTemplatesTypes);
    }
  };

  // Close the filter by clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      const filterEl = filterRef.current;
      const buttonEl = buttonRef.current;

      if (
        filterEl &&
        buttonEl &&
        !filterEl.contains(targetEl) &&
        !buttonEl.contains(targetEl)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterRef, buttonRef, setShowFilter]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      const filterEl = filterRef.current;
      const buttonEl = buttonRef.current;

      if (
        filterEl &&
        buttonEl &&
        !filterEl.contains(targetEl) &&
        !buttonEl.contains(targetEl)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterRef, buttonRef, setShowFilter]);

  // ==================================================================

  if (error) {
    return (
      <div className="relative flex flex-col items-center justify-center p-5">
        <p className="text-xl text-red-500">Something went wrong</p>
      </div>
    );
  }

  return (
    <Fragment>
      <section className="relative">
        <TabBar />
        {/* ======================== new content Operation START ======================================== */}
        <div className=" border border-[borderGray] bg-white border-left-0 px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30">
          <div className="relative flex flex-wrap items-center gap-5">
          <div className="bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
            <AppButton
              ref={buttonRef}
              variant="primary"
              type="button"
              size="xs"
              onClick={() => setShowFilter(!showFilter)}
            >
              <div className="flex items-center justify-center gap-1">
                <span className="text-xs">New Content Template</span>
                <span>+</span>
              </div>
            </AppButton>
            <AppSearch
              className={
                "search-box  block w-full h-8  border-transparent bg-primary p-2.5 text-sm text-textDark outline-none focus:border-transparent focus:ring-0"
              }
              placeHolder={"Search Contents"}
              onChangeHandler={(event) => {
                debounceFunction(event?.target?.value);
              }}
            />
            </div>

            {showFilter ? (
              <div
                ref={filterRef}
                className="absolute top-0 left-0 z-50 p-1 bg-white border rounded shadow-md border-borderGray w-60"
              >
                <input
                  type="text"
                  className="block w-full p-2 text-sm border-transparent rounded-lg outline-none text-textDark focus:border-transparent focus:ring-0"
                  placeholder="Search"
                  value={searchFitlerText}
                  onChange={searchFilterHandler}
                />
                <hr className="h-px border-1 border-borderGray dark:border-textDark" />

                {allFitlers.map((item, i) => (
                  <div
                    key={i}
                    className="flex items-center justify-between p-2 transition-all rounded-md cursor-pointer hover:bg-lightGrayColor"
                    onClick={() => {
                      setShowFilter(false);
                      navigate(`/content-templates/${item.field}/new`);
                    }}
                  >
                    <div className="flex items-center gap-2 hover:cursor-pointer">
                      {item.icon}
                      <span className="text-sm transition-all text-textDark">
                        {item.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        {/* ======================== new content Operation END ======================================== */}

        {/* breadcrumb*/}
        <div className="flex flex-col items-start justify-between pt-5 pl-5 shadow-sm ">
          <span className="pb-1 mb-4 text-sm text-grayColor">
            {" "}
            <Link to="/content-templates">Content</Link>{" "}
            &nbsp;&nbsp;/&nbsp;&nbsp;{" "}
          </span>
        </div>

        <div className="pb-10">
          <div className="w-[97%] py-5 m-auto space-y-5">
            <div className="flex items-center justify-center">
              <Loader isLoading={isLoading} size={35} />
            </div>
            <Table outsideRef={tableRef}>
              <THead>
                <tr>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Content Template
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium">
                    Quantity
                  </th>
                  <th scope="col" className="px-6 py-3 font-medium text-left" />
                </tr>
              </THead>
              <tbody>
                {Object.entries(list).map(([content_type, count]) => (
                  <tr
                    className="capitalize transition-all bg-white border-b hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer"
                    key={content_type}
                  >
                    <th
                      scope="row"
                      className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white w-96"
                      onClick={() =>
                        showDrawerHandler({
                          title: content_type,
                          count: count as string,
                        })
                      }
                    >
                      <div className="flex items-center justify-start gap-1 ">
                        <div className="w-5 h-5 text-grayColor">
                          {
                            ContentTemplateIcons[
                              content_type as EnumContentType
                            ]
                          }
                        </div>
                        <span className="capitalize text-textDark">
                          {content_type?.replaceAll("_", " ")} Templates
                        </span>
                      </div>
                    </th>
                    <td
                      onClick={() =>
                        showDrawerHandler({
                          title: content_type,
                          count: count as string,
                        })
                      }
                      className="flex flex-1 w-full py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white"
                    >
                      <div className="flex items-center justify-center gap-1">
                        <ArrowIcon className="w-3 h-3 text-grayColor" />
                        <span className="text-textDark">
                          {count as string} Templates
                        </span>
                      </div>
                    </td>
                    <td className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white">
                      <div
                        onClick={() => {
                          if (count === 0) {
                            toast("There are not any content template", "info");
                            return;
                          }

                          const newTab = {
                            index: globalTabs.tabs.activeTab.index,
                            path: `/content-templates/${content_type}`,
                            name: `Content - ${content_type}`,
                          };
                          updateTab(newTab);
                            /* filterKey for search content */
                            const filterLabel = "filterParam="
                          navigate(`/content-templates/${content_type}/${filterLabel}${filterKey || "allData"}`);
                        }}
                        className="flex items-center justify-center w-2/4 gap-3 p-1 rounded-md cursor-pointer bg-blueColor hover:shadow-sm"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 9.052 8.046"
                          className="w-3 h-3"
                        >
                          <path
                            fill="#fff"
                            d="M8.037 16.046L6 14.009l2.037-2.037.528.528-1.132 1.132h3.6v.754h-3.6l1.131 1.131zm4.124-1.659a.387.387 0 01-.377-.377.356.356 0 01.113-.27.372.372 0 01.264-.107.365.365 0 01.377.377.372.372 0 01-.107.264.356.356 0 01-.271.113zm1.509 0a.387.387 0 01-.377-.377.356.356 0 01.113-.27.372.372 0 01.264-.107.365.365 0 01.377.377.372.372 0 01-.107.264.356.356 0 01-.271.113zm-.654-2.313l-.528-.528 1.131-1.131h-3.6v-.756h3.6l-1.132-1.131.528-.528 2.037 2.037zm-5.633-1.66a.387.387 0 01-.377-.377.356.356 0 01.113-.27.372.372 0 01.264-.107.366.366 0 01.377.377.372.372 0 01-.107.264.356.356 0 01-.27.113zm1.509 0a.387.387 0 01-.377-.377.356.356 0 01.113-.27.372.372 0 01.264-.107.365.365 0 01.377.377.372.372 0 01-.107.264.356.356 0 01-.27.113z"
                            transform="translate(-6 -8)"
                          ></path>
                        </svg>
                        <span className="text-white">View Templates</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-3 h-3 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
      {/* =============== DRAWER ==================== */}
      <Drawer
        outsideRef={tableRef}
        setOpen={closeDrawer}
        isOpen={showDrawer}
        title=""
      >
        <div className="flex flex-col h-full gap-2 p-4 overflow-y-scroll">
          <div className="flex items-center justify-between">
            <span className="text-xs text-textGray">Template Details</span>
          </div>
          {/* ========== */}

          <ul className="list-none">
            <li className="flex gap-2 my-2">
              <span className="w-20 text-sm text-textGray">Name</span>
              <span className="text-sm capitalize text-textDark">
                {selectedContentTemplate.title.replaceAll("-", " ")}
              </span>
            </li>
            <li className="flex gap-2 my-2">
              <span className="w-20 text-sm text-textGray">Templates</span>
              <span className="text-sm text-textDark">
                {selectedContentTemplate.count}
              </span>
            </li>
          </ul>
          <hr className="h-px border-1 border-borderGray dark:border-textDark" />
          <div className="flex flex-col items-start justify-start gap-2">
            <span className="text-sm text-textGray">Description </span>
            <p className="capitalize text-textDark">
              {
                contentTypeDescription[
                  selectedContentTemplate.title as EnumContentType
                ]
              }
            </p>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default ContentTemplatePage;
