export const NotificationIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      aria-hidden='true'
      className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
    >

      <path fill="currentColor" d="M132.078 490.923q0-78.692 33.23-149.076 33.231-70.385 93.538-121l31.384 34.23q-53.384 44.154-83.077 105.423-29.692 61.269-29.692 130.423h-45.383zm651.076 0q0-69.154-28.577-130.423Q726 299.231 673 255.077l31-34.23q59.692 51.231 92.115 121.307 32.423 70.077 32.423 148.769h-45.384zM170.001 850.615v-45.384h82.846V496.154q0-82.076 49.884-145.461 49.885-63.384 130.116-78.846v-28.615q0-19.923 13.615-32.423 13.615-12.5 33.538-12.5 19.923 0 33.538 12.5 13.615 12.5 13.615 32.423v28.615q80.231 15.462 130.423 78.846 50.193 63.385 50.193 145.461v309.077h82.23v45.384H170.001zM480 968.307q-28.923 0-50.615-21-21.692-21-21.692-51.307h144.614q0 30.307-21 51.307-21 21-51.307 21z"></path>
    </svg>
  )
};
