import { MenuItem, TextField } from '@mui/material'
import { materialControlElements } from '@react-querybuilder/material'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import QueryBuilder from 'react-querybuilder'
import { getAllRule, getRuleOprands } from '../../apiQuery/Condition/Rule.apis'
import { getState } from '../../apiQuery/State/State.apis'
import { Rules_R, State_R_Single } from '../../Common/Types'
import { store } from '../../GlobalState/store'
import UseQuery from '../../Hook/UseQuery'
import initialQuery from '../../utils/InitialRules'
import CustomInput from '../Event/Comp/CustomInput'
import CustomSelect from '../Event/Comp/CustomSelect'
import { Operand_R } from '../Event/Comp/models'
import Button from '../General/Button'
import StandardInput from '../General/Form/StandardInput'

interface AddTimeWithRuleProps {
    stateId: string
    onChooseRuleName: (e: React.ChangeEvent<HTMLInputElement>) => void
    onSelectRule: (rule: number) => void
    onSelectedJsonRule: any
    query: any
    setQuery: any
    isConditionCraetedBefore: boolean
}

const TimeWithRule: FC<AddTimeWithRuleProps> = (props) => {
    const { stateId, onSelectRule, onChooseRuleName, onSelectedJsonRule, query, setQuery, isConditionCraetedBefore } = props


    const {
        state,
        dispatch
    } = useContext(store);


    // Use Query
    const stateData = useQuery<State_R_Single, Error>(['state', stateId], () => getState(state.currentFlow.id.toString(), stateId), {})
    const {
        isLoading,
        data,
        error
    } = useQuery<Rules_R, Error>(["all-state-rules", 1], () => getAllRule(1))

    const {
        isLoading: operandsLoading,
        data: operands,
        error: operandsError
    } = useQuery<Operand_R, Error>("operands", getRuleOprands)



    // End Use Query


    // States
    // const [query, setQuery] = useState(initialQuery);
    const [isConditionCraeted, setIsConditionCraeted] = useState<boolean>(props.isConditionCraetedBefore)
    const [rule, setRule] = useState<number | null | undefined>(null);
    const [showCheckIn, setCheckIn] = useState(false);
    const [showNewCondition, setShowNewCondition] = useState(false)

    const [timeEvent, setTimeEvent] = useState({
        value: 0,
        type: 'minute',
        isAdded: false,
        isRuleAdded: false,
        ruleName: '',
        name: '',
        idVal: ''
    })


    // End States


    // =================== Memos

    //@ts-ignore
    const fields: Field[] = useMemo(() => {
        return operands?.data?.map((item) => {
            return {
                name: item.value,
                label: item.display,
                operators: item.data_type === "string" ? [
                    { name: "eq", label: "=" },
                    { name: "not", label: "!=" }
                ] : [{ name: "eq", label: "=" }],
                valueEditorType: item.data_type === "string" ? "text" : "checkbox",
                inputType: ["OP.FirstDayOfWork", "OP.EffectiveDate", "OP.HireDateWorker"].includes(item.value) && "date",
                defaultValue: item.data_type === "string" ? "" : false
            }
        })
    }, [operands?.data])

    const newEventCondition = useMemo(() => [{
        id: 0,
        rule_name: "New Condition"
    }], [])


    // 
    const { json } = UseQuery(rule, data?.data)

    // =================== Use Effects

    useEffect(() => {
        if (stateData?.data?.data?.state) {
            setRule(stateData?.data?.data?.state?.rules_state_id)
            onSelectRule(stateData?.data?.data?.state?.rules_state_id)
        }

    }, [stateData?.data])

    useEffect(() => {
        if (query.rules.find((item: any) => item.field === "OP.CheckInArray")) setCheckIn(true)
        else setCheckIn(false)
    }, [query.rules])

    useEffect(() => {
        if (json) {
            // @ts-ignore
            onSelectedJsonRule(json as Rules_R)
   
        }
    }, [json])



    // =================== Handlers methods

    const handleRule = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const id = parseInt(event.target.value)
        if (id === 0) setShowNewCondition(true)
        setRule(id);
        onSelectRule(id)


    };


    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimeEvent && setTimeEvent(prev => ({
            ...prev,
            ruleName: e.target.value
        }))
        onChooseRuleName(e)
    }

    const handleChangeIdVal = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimeEvent && setTimeEvent(prev => ({
            ...prev,
            idVal: e.target.value
        }))
    }



    // =================== JSX
    return <section>
        {
            (data?.data) ? <div>
                {
                    isConditionCraeted ? <>
                        <Button
                            onClick={() => setIsConditionCraeted(prev => !prev)}
                            label="Edit Condition" extraClasses="w-fit px-3 py-3 text-white text-sm"
                        />

                    </> : <>

                        {
                            rule !== null ?
                                <>

                                    {
                                        !showNewCondition ?
                                            <select
                                                className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-sm focus:ring-blue-500 focus:border-blue-500"
                                                defaultValue={stateData?.data?.data?.state?.rules_state_id}
                                                // fullWidth
                                                // select
                                                title="Select Rule"
                                                value={rule}
                                                onChange={handleRule}
                                            >
                                                {(data?.data ? [...data?.data, ...newEventCondition] : [...newEventCondition])?.map((option, ind) => (
                                                    <option key={ind} value={option.id}>
                                                        {option.rule_name}
                                                    </option>
                                                ))}
                                            </select> :
                                            <div className="w-full p-2 rounded-md">

                                                {operandsLoading && (
                                                    <div className="flex items-center justify-center w-full p-5">
                                                        <svg
                                                            className="w-6 h-6 text-blue-700 animate-spin"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                strokeWidth="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                )}
                                                <div>
                                                    <div className="py-2">
                                                        <StandardInput label="State condition name"
                                                            value={timeEvent.ruleName}
                                                            onChange={handleChangeName} />
                                                    </div>
                                                    <QueryBuilder
                                                        controlElements={{
                                                            ...materialControlElements,
                                                            addGroupAction: () => null,
                                                        }}
                                                        fields={fields}
                                                        query={query}
                                                        onQueryChange={q => setQuery(q)}
                                                    />

                                                    {
                                                        showCheckIn &&
                                                        <div className="grid w-full grid-cols-2 pt-2 gap-x-3">

                                                            <StandardInput label="Value"
                                                                onChange={handleChangeIdVal}
                                                                value={timeEvent.idVal}
                                                            />

                                                            <CustomSelect onChange={() => {
                                                            }}
                                                                classNames="bg-white rounded-md pl-2"
                                                                value={'OP.ManagementChainEmployeeIDs'}
                                                                options={[
                                                                    {
                                                                        value: "OP.ManagementChainEmployeeIDs",
                                                                        title: "OP.ManagementChainEmployeeIDs"
                                                                    }
                                                                ]}
                                                            />

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                    }

                                    {
                                        // has {json?.deliver_time || 'no'} {json?.deliver_time ? json?.deliver_unit : null} time
                                        // offset and is delivered
                                        (json && rule) ? <div>This condition
                                            when {json.rules?.map((item: any, index: number) => <span
                                                className="inline"
                                                key={index}> {item.field} {item.operator === "eq" ? "=" : item.operator === "not" ? "!=" : item.operator === "not" ? "!=" : json.combinator === "eq" ? "=" : json.combinator === "not" ? "!=" : null} {String(item.value)} {json.rules.length - 1 !== index ? json.combinator.toUpperCase() : null}</span>)}</div> : null
                                    }
                                </> : null
                        }
                    </>
                }
            </div> : null}

    </section>
}

export default TimeWithRule