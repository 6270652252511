//^  This component renders two buttons, one for creating a new pathway and one for opening a filter modal.

import { AppButton } from "../../../Theme";
import { PathwayButtonsIcon } from "../../../Theme/Icons/PathwayButtonsIcon";
import { FilterIcon } from "../../../Theme";
import { useTranslation } from "react-i18next";

//! Type Script ⬇ 
interface IProps { buttonRef?: any; showFilter: boolean; newPathwayModalHandler:() => void; setShowFilter: (showFilter:boolean)=>void}

//^ Start component ⬇
const PathwayOptionalButtons = ({ buttonRef, showFilter, newPathwayModalHandler, setShowFilter}: IProps)=> {

  const {t}=useTranslation()

  return (
    <div className="flex items-center gap-2">

      {/*//! Button 1  ->  New pathway + */}
      <AppButton ref={buttonRef} variant="primary" type="button" size="xs" onClick={() => {newPathwayModalHandler()}}
      >
        <div className="flex items-center justify-center gap-1">
          <PathwayButtonsIcon />
          <span className="text-xs">{t('PathwayPage.New_Pathway')}</span>
          <span>+</span>
        </div>
      </AppButton>

      {/*//! Button 2  ->  Filter */}
      <AppButton ref={buttonRef} variant="primary" type="button" size="xs" onClick={() => setShowFilter(!showFilter)}
      >
        <div className="flex items-center justify-center gap-1">
          <FilterIcon className="w-3 h-3" />
          <span className="text-xs">{t('PathwayPage.Filter')}</span>
          <span>+</span>
        </div>
      </AppButton>
    </div>
  );
}

export default PathwayOptionalButtons;
