
import { ruleBody } from "../../Common/Types";
import API from "../index";


export const getOneContentRule = async (ruleId: number) => {
    const { data } = await API.get(`/admin/content/rules/${ruleId}`);
    return data;
};

export const updateContentRule = async (ruleId:number,rule:any) => {
    const {data} = await API.put(`/admin/content/rules/${ruleId}`, {...rule});
    return data;
}



interface ICreateTimeCoditionForContent {
    state_id: number
    content_id: number
    deliver_time:number
    deliver_unit:string
    display_unit?:string
}

export const createTimeCoditionForContent = async (values: ICreateTimeCoditionForContent) => {
    const {data} = await API.post(`/admin/organizations/conditions/`, values);
    return data;
}