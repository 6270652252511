import { FormEvent, memo, useContext, useEffect, useState } from "react";
import { AppButton, CloseIcon } from "../../../../../../Theme";
import AppInput from "../../../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../../../Shared/AppDropDown/AppDropDown";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useInfiniteQuery } from "react-query";
import { toast } from "../../../../../../utils/Toast"
import { ILink, IOrgLInksResponse } from "../../../../../../Types/Organization/Organization";
import {
  getAllOrganizationLinks,
  getSingleOrganizationLinks,
  UpdateOrgLink
} from "../../../../../../apiQuery/Organization/Organization.apis";
type props = {
  modalHandle: (L: ILink) => void;
  RefetchView: () => void;
  Link: ILink;
};


const EditLinkModal = memo(({ modalHandle, Link, RefetchView }: props) => {
  const { t } = useTranslation();
  const [state, setState] = useState(Link);
  useEffect(() => {
    if (Link) {
      setState(Link);
    }
  }, [Link]);

  const updateLInkMutation = useMutation(
    (updateLink: ILink) =>
      UpdateOrgLink(updateLink.id as number, updateLink),
    {
      onSuccess: async () => {
        RefetchView()
        modalHandle(Link);
        toast(
          `successfully updated`,
          "success"
        );
      },
      onError: () => {
        toast(
          `There was an error updating`,
          "error"
        );
      },
    }
  );

  const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateLInkMutation.mutate(state);
  };

  return (
    <div className="absolute z-[999] flex w-[60%] m-5 h-full  overflow-auto bg-gray-50 border border-borderGray rounded-md shadow-lg  text-textDark Link-new-modal">
      <div className="flex flex-col w-full p-8">
        <div className="flex justify-between">
          <div className="flex flex-col gap-5 mb-2">
            <div>
              <h2 className="mb-1 text-2xl font-normal text-textDark">
                Edit Link
              </h2>
              <p className="text-sm text-textDark">
                Edit the Link name, description, and other details.
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={() => modalHandle(Link)}
            className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <CloseIcon className="w-4 h-4" />
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        <hr className="w-full h-px my-5 border-1 border-borderGray dark:border-textDark" />
        <form onSubmit={submitFormHandler} className="w-full">
          <AppInput
            isFull
            label="Title"
            id="Title"
            title="Title"
            tabIndex={-1}
            autoFocus
            placeholder="Link Title"
            name="title"
            required
            onChange={(e) => {
              setState((prev) => ({ ...prev, title: e.target.value }));
            }}
            value={state.title}
          />
          <AppInput
            isFull
            label="URL"
            id="URL"
            title="URL"
            tabIndex={-1}
            autoFocus
            placeholder="URL"
            name="URL"
            required
            onChange={(e) => {
              setState((prev) => ({ ...prev, url: e.target.value }));
            }}
            value={state.url}
          />

          <AppDropDown
            label={"link seen by"}
            defaultValue={"Both"}
            options={
              [{ value: "Candidate", label: "Candidate" }, { value: "Employee", label: "Employee" }, { value: "Both", label: "Both" }]
            }
            value={state.link_seen_by.toString()}
            name="link_seen_by"
            placeholder={`link_seen_by`}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                link_seen_by: e.target.value,
              }));
            }}
          />
          <AppTextArea
            placeholder={"Description"}
            rows={4}
            name="description"
            onChange={(e) => {
              setState((prev) => ({ ...prev, description: e.target.value }));
            }}
            label="Link Description"
            id="LinkDescription"
            value={state.description || ""}
          />

          <div className="mt-8">
            <AppButton variant="primary" type="submit">{`${t(
              "Save Changes"
            )}`}</AppButton>
          </div>
        </form>
      </div>
    </div>
  );
});

export default EditLinkModal;
