import React, { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useRef, useState } from 'react'
import { TabActionLayout } from "../TabActionLayout";
import { LanguageEnum } from '../../../../Types/ActionTemplate/ActionTemplate';
import { useTranslation } from "react-i18next";
import { LanguagesOptions, getLangaugeLabel } from "../../../../utils/Languages";
import { EnumActionType } from "../../../../Types/ActionTemplate/ActionTemplate";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createActionTemplate, deleteActionTemplateById, getActionTemplateById, updateActionTemplateById } from "../../../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
// import AllWs1notificationActions from "./AllWS1Action";
// import Ws1notificationItem from "./WS1Item";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import ViewActionTemplateHeader from '../../ViewActionTemplateHeader';
import { AppButton, LanguageIcon, PopupModal } from '../../../../Theme';
import { useNavigate } from 'react-router-dom';

export type Language = "en" | "fr" | "es"

export const importanceOptions: {
	value: string
	label: string
}[]
	= [
		{
			value: '0',
			label: "Standard"
		},
		{
			value: '1',
			label: "Priority"

		},
		{
			value: '2',
			label: "Urgent"
		}
	]

export interface Ws1notificationAction {
	subtitle: string
	title: string
	description: string
	language: Language
	importance: number
}


interface Ws1notificationActionCreateOrUpdateRequest {
	action_type: EnumActionType,
	action_metadata: Ws1notificationAction[],
	importance: number
}

const initialWs1notificationAction: Ws1notificationAction = {
	title: '',
	description: '',
	subtitle: '',
	language: "en",
	importance: 0
}
interface WS1TabActionProps {
	templateId: number,
}


const Ws1notificationTabAction: FC<WS1TabActionProps> = (props) => {

	const queryClient = useQueryClient()
	const navigate = useNavigate();

	const { currentOrganizationID } = useContext(UserContext);


	const { t } = useTranslation(); // TODO: use this for language

	const [state, setState] = useState<Ws1notificationAction[]>([])


	const [ws1notificationAction, setWs1notificationAction] = useState<Ws1notificationAction>(initialWs1notificationAction)

	const createOrUpdateButton = useRef<string>("Create")
	const allWs1notificationActionsRef = useRef(null)

	const [selectedLanguage, setSelectedLanguage] = useState<string>('')



	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);


	// ========================================================================================================================

	const { data: notificationData, refetch } = useQuery<any, Error>
		(`notification-content-${props.templateId}`, () => getActionTemplateById(props.templateId));


	const deleteActionTemplateByID = useMutation((params: { id: number }) =>
	deleteActionTemplateById(params.id), {


	onSuccess: async (data) => {
		toast("Template deleted successfully", "success")

	},
	onError: (error: any) => {
		const message = error?.response?.data?.message || "Something went wrong!"
		toast(message, "error")
	}
	})

	// ========================================================================================================================

	useEffect(() => {
		if (notificationData) {
	
			const imp = parseInt(notificationData?.data?.importance);
			const newState = notificationData?.data.action_metadata.map((item: any) => {
				return {
					title: item.title,
					description: item.description,
					subtitle: item.subtitle,
					language: item.language,
					importance: imp
				}
			})
			setState(newState)
		}
	}, [notificationData])

	useEffect(() => {
		if (selectedLanguage === '') return
		// get seleted index of the content template to be displayed as the current content
		const selectedIndex = notificationData?.data.action_metadata.findIndex((item: any) => item.language === selectedLanguage) as number
		const item = notificationData?.data.action_metadata[selectedIndex]
		setWs1notificationAction({
			description: item.description,
			subtitle: item.subtitle,
			title: item.title,
			language: item.language as Language,
			importance: parseInt(notificationData?.data?.importance)
		})
		createOrUpdateButton.current = "Update"
	}, [selectedLanguage])

	const deleteTemplateFromContentTemplateList = async () => {
		deleteActionTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/action-templates/notification', { state: { deleted: true } })
	}
	//=====================================================
	//make english default language for content
	useEffect(() => {
		const notification = notificationData?.data.action_metadata.find((item: any) => item.language === "en")
		if (notification) {
			setWs1notificationAction({
				description: notification.description,
				importance: notification.importance,
				language: LanguageEnum.English,
				subtitle: notification.subtitle,
				title: notification.title
			})
			createOrUpdateButton.current = "Update"
			setSelectedLanguage("en")
		}
	}, [notificationData])
	// ================================================== Mutations =========================================================	s

	const updateWs1notificationActionMutation = useMutation(
		(params: { id: number, data: Ws1notificationActionCreateOrUpdateRequest }) => updateActionTemplateById(params.id, params.data), {
		onSuccess: async (data) => {
			toast("Action updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})



	// handlers
	// TODO: Write a global handle change function
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setWs1notificationAction(prev => ({ ...prev, [name]: value }))
	}

	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		//  tODO: validation goes here
		if (ws1notificationAction.title === '') {
			toast('Title is required', 'error')
			return
		}

		if (createOrUpdateButton.current === "Create") {

			if (state.some(item => item.language === ws1notificationAction.language)) {
				toast('This ws1notification is already exist', 'error')
				return
			}

			// setState(prev => [...prev, ws1notificationAction])
			const newState = [...state, ws1notificationAction]
			await createOrUpdateContentTemplate(newState)


		} else {
			// update the state
			const newState = state.map(item => {
				if (item.language === ws1notificationAction.language) {
					return ws1notificationAction
				}
				return item
			})

			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = "Create"
		}

		// 	empty the fields
		setWs1notificationAction({
			description: '',
			subtitle: '',
			title: '',
			language: 'en',
			importance: 2
		})
		setSelectedLanguage('')

	}
	// createOrUpdateContentTemplate(newState)
	const createOrUpdateContentTemplate = async (data: any) => {
		if (data.length === 0) {
			toast('Please add at least one ws1notification template', 'info')
			return
		}





		const action_metadata: Ws1notificationAction[] = data.map((item: any) => {
			return {
				...item.action_metadata,
				organization_id: currentOrganizationID(),
				language: item.language,
				importance: parseInt(item.importance),
				title: item.title,
				description: item.description,
				subtitle: item.subtitle,
			}
		})
		const shapeData: Ws1notificationActionCreateOrUpdateRequest = {
			action_type: EnumActionType.Ws1notificationAction,
			action_metadata: action_metadata,
			importance: ws1notificationAction.importance
		}

		await updateWs1notificationActionMutation.mutateAsync({ id: props.templateId, data: shapeData })

		setState([])
		setSelectedLanguage('')
		setWs1notificationAction(initialWs1notificationAction)
	}


	const editableWs1notificationAction = (ws1notificationAction: any, importanceModel?: number) => {
		let items: Ws1notificationAction[] = []

		if (importanceModel && importanceModel >= 0) {

			items = ws1notificationAction.action_metadata.map((item: any) => {
				return {
					description: item.description,
					subtitle: item.subtitle,
					title: item.title,
					language: item.language as Language,
					importance: importanceModel
				}
			}) as Ws1notificationAction[]
		} else {

			items = ws1notificationAction.action_metadata.map((item: any) => {
				return {
					description: item.description,
					subtitle: item.subtitle,
					title: item.title,
					language: item.language as Language,
					importance: parseInt(ws1notificationAction.importance)
				}
			}) as Ws1notificationAction[]
		}
		setState(items)
	}


	return (
	<Fragment>
		<TabActionLayout>
			<ViewActionTemplateHeader
				title={notificationData?.data.action_metadata[0].title || ''}
				referencesCount={0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{

					notificationData?.data.action_metadata.map((item: any, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setWs1notificationAction(initialWs1notificationAction)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {

										editableWs1notificationAction(notificationData.data, parseInt(notificationData?.data?.importance))
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					state.length < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setWs1notificationAction(initialWs1notificationAction)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								+ New Language
							</span>

						</AppButton>
					) : null
				}
			</div>

			<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{ws1notificationAction.title|| ''}</h1>
			<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">
				<div className='flex w-full gap-2 mb-3'>
					<AppButton size="xs" disabled={notificationData?.data?.title === '' || updateWs1notificationActionMutation.isLoading || deleteActionTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Action Template</AppButton>
					<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>Delete Action Template</AppButton>
				</div>
				<AppInput autoFocus placeholder={"Title"} value={ws1notificationAction.title} name='title' isFull onChange={handleChange} label='Title' required />
				<AppInput placeholder={"Subtitle"} value={ws1notificationAction.subtitle} name='subtitle' isFull onChange={handleChange} label='Subtitle' />
				<AppTextArea placeholder={"Description"} value={ws1notificationAction.description} rows={6} name='description' onChange={handleChange} label='Description' />
				<AppDropDown label={"Importance"} options={importanceOptions} value={
					ws1notificationAction?.importance?.toString() || '2'} name="importance" onChange={e => {
						setWs1notificationAction(prev => {
							return {
								...prev,
								importance: parseInt(e.target.value)
							}
						})
					}} />
				<AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false}
					label={"Language"} options={LanguagesOptions} value={ws1notificationAction.language} name="language" onChange={handleChange} />

			</form>

		</TabActionLayout>

		<PopupModal
			setOpen={setShowPopupDeleteConfirmation}
			isOpen={showPopupDeleteConfirmation}
			title="Are you sure you want to delete this Notification Action Template ?
			Deleting this Action Template will delete all Actions associated with this Template."
			onConfirmClick={deleteTemplateFromContentTemplateList}
		/>
	</Fragment>
	)
}


export default Ws1notificationTabAction
