// ** MUI Imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { Icon } from "@iconify/react";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  candidates: number;
  employees: number;
}

const ActiveUsers:FC<Props> = (props) => {

  const { t }  = useTranslation()

  return (
    <Card>
      <CardHeader
        title="Report"
        subheader="Count of Activates"
        subheaderTypographyProps={{ sx: { lineHeight: 1.429 } }}
        titleTypographyProps={{ sx: { letterSpacing: "0.15px" } }}
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(6)} !important` }}>
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{ bgcolor: "#EEFBE5", padding: "10px", borderRadius: "6px" }}
            >
              <Icon color="#71DE33" width="30" icon="mdi:account-tie" />
            </Box>
            <Typography sx={{ mb: 0.5, textTransform: 'capitalize' }} variant="body2" >
              {t('candidates')}
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>{props.candidates}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ bgcolor: "#EBEDFF", padding: "10px", borderRadius: "6px" }}>
              <Icon color="#6F68DA" width="30" icon="mdi:handshake-outline" />
            </Box>
            <Typography sx={{ mb: 0.5, textTransform: 'capitalize' }} variant="body2">
              {t('employees')}
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>{props.employees}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ActiveUsers;
