export const EmailIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      aria-hidden='true'
      className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
    >


      <path fill="currentColor" d="M157.694 875.999q-23.616 0-40.654-17.039-17.039-17.038-17.039-40.654V333.694q0-23.616 17.039-40.654 17.038-17.039 40.654-17.039h644.612q23.616 0 40.654 17.039 17.039 17.038 17.039 40.654v484.612q0 23.616-17.039 40.654-17.038 17.039-40.654 17.039H157.694zM480 582.076l334.615-221.461-6.154-39.23-328.461 213-328.461-213-6.154 39.23L480 582.076z"></path>
    </svg>
  )
};