import React, {FC} from 'react';
import LinearProgressWithLabel from "./LinearProgress";

type props = {
    progress: number
}
const UploadProgress: FC<props> = ({progress}) => {
    return (
        <>
            <div className="flex flex-col space-y-2">
                <LinearProgressWithLabel value={progress}/>
                <p className="text-xl">Uploading File(s). Hang
                    tight! </p>
            </div>
        </>
    );
};

export default UploadProgress;