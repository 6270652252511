import API from "../index";

export const uploadFile = async (formData: any) => {
    const {data} = await API.post('/upload', formData);
    return data;
};

export const getUploadUrl = async (file_name: string) => {
    const {data} = await API.get(`/upload-url?file_name=${file_name}`);
    return data;
};