import React, { memo, useContext, useMemo } from 'react';
import { Single_Action_R } from "../../../Common/Types";
import { MdClose } from "react-icons/md";
import EmailNew from "../ActionCreate/EmailNew";
import SmsTextNew from "../ActionCreate/SmsTextNew";
import PushAction from "../ActionCreate/PushNotificationNew";
import { store } from "../../../GlobalState/store";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getAction } from "../../../apiQuery/Actions/Actions.apis";
import { CircularProgress } from "@mui/material";
import ALNotificationNew from '../ActionCreate/ALNotificationNew';

type props = {
    modalHandle: () => void,
    action_slug: string,
    onSuccess: () => void
}

const EditActionModal = memo(({ modalHandle, action_slug, onSuccess }: props) => {

    const {
        state: globalState,
    } = useContext(store);
    const organization_id = useMemo(() => globalState.organization_id, [globalState.organization_id])
    const params = useParams();
    const id = params.stateSlug || ""

    const {
        isLoading,
        isError,
        data,
        error,
    } = useQuery<Single_Action_R, Error>(['action', action_slug], () => getAction(organization_id, globalState.currentFlow.slug, id, action_slug), {
        enabled: !!action_slug
    })

    return (
        <div
            className="relative flex w-2/3 h-full p-8 overflow-auto text-gray-600 bg-gray-100 rounded-md modal">
            <div className="w-full">
                <div className="flex items-center justify-between w-full pb-4 text-lg">
                    <p className="text-2xl ">Edit Action</p>
                    <div onClick={modalHandle} className="p-1 bg-gray-400 rounded-full cursor-pointer">
                        <MdClose className="text-white" />
                    </div>
                </div>

                {
                    isLoading ? (
                        <CircularProgress />
                    ) :
                        isError ? (
                            <span>Error: {error?.message || "Something went wrong!"}</span>
                        ) : data ?
                            <>
                                {(() => {
                                    switch (data?.data?.action?.action_type) {
                                        case 'email':
                                            return <EmailNew type={'update'} onSuccess={onSuccess} onClose={modalHandle}
                                                defaultValue={data?.data?.action} />
                                        case 'sms':
                                            return <SmsTextNew type={'update'} onSuccess={onSuccess}
                                                onClose={modalHandle} defaultValue={data?.data?.action} />
                                        case 'notification':
                                            return <PushAction type={'update'} onSuccess={onSuccess}
                                                onClose={modalHandle} defaultValue={data?.data?.action} />
                                        case 'al_notification':
                                            return <ALNotificationNew type={'update'} onSuccess={onSuccess}
                                                onClose={modalHandle} defaultValue={data?.data?.action} />
                                        default:
                                            return null
                                    }
                                })()}
                            </> : null
                }
            </div>
        </div>
    );
});

export default EditActionModal;