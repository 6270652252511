import React, { FC, useState } from 'react'
import {State } from "../../Common/Types";
import { IStateTimeCondition } from "../../Types/StateTimeCondition";


interface AddTimeSinceProps {
    StatesList: State[] | null
    onSelectedTargetState: (targetState: number) => void
    onSelectedTimeUntilTransition: (maxTime: number) => void
    onSelectedTimeUnit: (timeUnit: string) => void
    onSelectedDisplayUnit: (timeUnit: string) => void
    savedStateTimeCondition?: IStateTimeCondition | null
    isDisabled?:boolean
}


const timeUnits: { value: string, text: string }[] = [{
    value: 'minute',
    text: "minutes"
},
{
    value: 'hour',
    text: "hours"

},
{
    value: 'day',
    text: "days"
}]


const AddTimeSince: FC<AddTimeSinceProps> = (props) => {
    const { onSelectedTargetState, StatesList, onSelectedTimeUntilTransition, onSelectedTimeUnit, onSelectedDisplayUnit, savedStateTimeCondition, isDisabled } = props

    const [maxTimeState, setMaxTimeState] = useState(savedStateTimeCondition?.max_time_state || 0);

    return (
        <div className="flex items-center space-x-1">

            <div className="w-64 -top-10">
                <label htmlFor="target_state" className='text-xs'>Target State</label>
                <select
                    disabled={isDisabled ? true : false}
                    className='border border-borderGray bg-[#F5F5F5] placeholder:text-[#A4A5A5] text-gray-900 text-md rounded-sm 
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-800
                            focus:ring-blueColor
                            focus:border-blueColor
                            block w-full cursor-pointer'
                    title={"Target State"}
                    name={"target_state"}
                    id="target_state"
                    onChange={e => {
                        onSelectedTargetState(Number(e.target.value))
                    }}
                    defaultValue={savedStateTimeCondition ? savedStateTimeCondition.target_state : undefined}
                >
                    <option value="-1">-- Select An State --</option>
                    {
                        savedStateTimeCondition && savedStateTimeCondition.target_state ?
                            StatesList && StatesList.map((option, index) => {
                                return <option key={index} value={option.id} selected={Number(savedStateTimeCondition.target_state) === Number(option.id)}>
                                    {option.name}
                                </option>
                            })
                            :
                            StatesList && StatesList.map((option, index) => {
                                return <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            })
                    }
                </select>
                {/*<p className="text-red-500">{errors[name]?.message}</p>*/}
            </div>


            <div className="w-60">
                <label htmlFor="max_time_state" className='text-xs'>Time Until Transition</label>
                <input
                     disabled={isDisabled ? true : false}
                className='w-full block rounded-sm border border-borderGray bg-[#F5F5F5]  text-md text-gray-900
        focus:border-blueColor focus:ring-blueColor placeholder:text-[#A4A5A5]
          disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-800'
                    type="number"
                    value={maxTimeState}
                    id='max_time_state'
                    min={0}
                    onChange={e => {
                        setMaxTimeState(Number(e.target.value))
                        onSelectedTimeUntilTransition(Number(e.target.value))
                    }}
                    title="Time Until Transition"
                />
            </div>

            <div className="w-40">
                <label htmlFor="time_unit" className='text-xs'>Time Unit</label>

                <select
                 disabled={isDisabled ? true : false}
                    className='border border-borderGray bg-[#F5F5F5] placeholder:text-[#A4A5A5] text-gray-900 text-md rounded-sm 
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-800
                            focus:ring-blueColor
                            focus:border-blueColor
                            block w-full cursor-pointer'
                    name="time_unit"
                    id="time_unit"
                    title='Time Unit'
                    defaultValue={savedStateTimeCondition ? savedStateTimeCondition.display_unit : undefined}
                    onChange={e => {
                        onSelectedTimeUnit(e.target.value)
                        onSelectedDisplayUnit(e.target.value)
                    }}
                >
                    {timeUnits?.map((option, index) =>
                        <option key={index}
                            value={option.value}
                            selected={savedStateTimeCondition?.display_unit === option.value}
                        >
                            {option.text}
                        </option>
                    )}

                </select>
                
            </div>

        </div>
    )
}

export default AddTimeSince
