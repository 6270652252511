import Pathways from "../Pathway/Pathways";
import Pathway from "../Pathway/Pathway";
import EditPathway from "../Pathway/EditPathway";
import StateDetails from "../State/ViewState";
import NewPathway from "../Pathway/NewPathway";
import NewState from "../State/NewState";
// import ContentTemplates from "../Templates/ContentTemplate/ContentTemplates";
import StateTemplates from "../Templates/StateTemplate/StateTemplates";
import NewStateTemplate from "../Templates/StateTemplate/NewStateTemplate";
import Authentication from "../Auth/Authentication";
import Workers from "../Worker/Workers";
import Worker from "../Worker/Worker";
import EditState from "../State/EditState";
import WorkerParticipantHistory from "../Worker/WorkerParticipantHistory";
import Conditions from "../Event/Pages/Conditions";
import Jobs from "../Jobs/Jobs";
import Login from "../Login/Login";
import Employee from "../Employee/Employee";
import ContentTemplates from "../ContentTemplate/ContentTemplates";
import ActionTemplates from "./../ActionTemplate/ActionTemplates";
import Analytics from "../Analytics/Analytics";
import ContentTemplatePage from "../ContentTemplate/ContentTemplate";
import ViewContentTemplatePage from "../ContentTemplate/ViewContentTemplate";
import NewContentTemplatePage from "../ContentTemplate/NewContentTemplate";
import NewActionTemplatePage from "../ActionTemplate/NewActionTemplate";
import ActionTemplatePage from "./../ActionTemplate/ActionTemplate";
import ViewActionTemplate from "../ActionTemplate/ViewActionTemplate";
import RedirectEmployee from "../Employee/RedirectEmployee";
import { DashboardPage } from "../Dashboard";

export const publicRoutes = [
  {
    element: <Authentication />,
    path: "/",
    name: "Dashboard",
  },
  {
    element: <Login />,
    path: "/:company",
    name: "Login",
  },
  {
    element: <Login />,
    path: "/login",
    name: "Login",
  },
  // redirect to /fedex/employee-verification
  {
    element: <RedirectEmployee />,
    path: "/employee-verification",
    name: "Employee",
  },
  {
    element: <Employee />,
    path: "/fedex/employee-verification",
    name: "Employee",
  },
];

export const PrivateRoutes = [
  {
    element: <Workers />,
    path: "/workers",
    name: "Workers",
  },
  {
    element: <Worker />,
    path: "/workers/:workerId",
  },
  {
    element: <WorkerParticipantHistory />,
    path: "/workers/:workerId/pathway/:pathwayId/participant/:participantId",
  },
  {
    element: <Pathways />,
    path: "/pathways",
    name: "Pathways",
  },
  {
    element: <Pathway />,
    path: "/pathways/:slug",
  },
  {
    element: <EditPathway />,
    path: "/pathways/:slug/edit",
  },
  {
    element: <StateDetails />,
    path: "/pathways/:slug/state/:stateSlug",
  },
  {
    element: <EditState />,
    path: "/pathways/:slug/state/:stateSlug/edit",
  },
  {
    element: <NewState />,
    path: "/pathways/:slug/newState",
  },
  {
    element: <NewPathway />,
    path: "/pathways/new/stateFlow",
  },
  // {
  // 	element: <ContentTemplates/>,
  // 	path: '/content-templates'
  // },
  {
    element: <StateTemplates />,
    path: "/state-templates",
  },
  {
    element: <NewStateTemplate />,
    path: "/state-templates/create-state-template",
  },
  {
    element: <NewStateTemplate />,
    path: "/state-templates/edit-state-template/:stateTempId",
  },
  {
    element: <Conditions />,
    path: "/conditions",
    name: "Conditions",
  },
  {
    element: <Jobs />,
    path: "/jobs",
    name: "Jobs",
  },
  {
    element: <ContentTemplates />,
    path: "/content-templates",
    name: "ContentTemplates",
  },
  {
    element: <ContentTemplatePage />,
    path: "/content-templates/:templateSlug/:id",
    name: "ContentTemplate",
  },
  {
    element: <ViewContentTemplatePage />,
    path: "/content-templates/:templateSlug/view/:templateId",
    name: "ContentTemplate",
  },
  {
    element: <NewContentTemplatePage />,
    path: "/content-templates/:templateSlug/new",
    name: "ContentTemplate",
  },
  {
    element: <ActionTemplates />,
    path: "/action-templates",
    name: "ActionTemplates",
  },
  {
    element: <ActionTemplatePage />,
    path: "/action-templates/:actionType/:id",
    name: "ActionTemplates",
  },
  {
    element: <ViewActionTemplate />,
    path: "/action-templates/:actionType/view/:actionId",
    name: "ActionTemplates",
  },
  {
    element: <NewActionTemplatePage />,
    path: "/action-templates/:actionType/new",
    name: "ActionTemplates",
  },
  {
    element: <Analytics />,
    path: "/analytics",
    name: "Analytics",
  },
  {
    element: <DashboardPage />,
    path: "/dashboard",
    name: "Dashboard",
  }
];
