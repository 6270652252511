import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";

// for the create and update the Checklist
// to create with Checklist content type id is going to be 0


export interface IChecklistItems {
	content:string
	id:string
}
export interface IChecklist {
	id: number
	title: string
	description: string
	organization_id?: number
	language: LanguageEnum
	items: IChecklistItems[] 
	updated_at?: string
}

export interface IChecklistForServer {
	id?: number
	title: string
	description: string
	organization_id?: number
	language: LanguageEnum
	items: string[] 
	updated_at?: string
}



export type IChecklistContentTemplate = {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	checklist: IChecklist[];
}

export type ChecklistContentResponse = {
	data: IChecklistContentTemplate
	error: boolean
	message: string
	status: number
}


export type ChecklistContentTemplate = {
	checklist: IChecklist
	content_metadata: IContentMetadata
}

export interface ChecklistContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	checklist: IChecklistForServer[],
	content_metadata: IContentMetadata[]
}

export const initialChecklistContent: ChecklistContentTemplate = {
	checklist: {
		id: 0,
		title: '',
		description: '',
		language: LanguageEnum.English,
		items: []
	}
	,
	content_metadata: {
		id: 0,
		cover_image: '',
		preview_title: '',
		tags: [''],
		language: LanguageEnum.English,
		alt_title: '',
	}
}