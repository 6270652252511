/* eslint-disable no-unused-vars */
import { FC, ForwardedRef, useEffect, useRef } from "react";
import { AppButton } from "../Shared";
import { CommandIcon, DownloadIcon, LabelIcon } from "../Icons";
import { TransitionAnimation } from "../Transition";

export interface CommandBarProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  outsideRef?: ForwardedRef<HTMLDivElement>;  // ref passed in through props
}

export const CommandBar: FC<CommandBarProps> = (props) => {
  const { isOpen, setOpen, children, outsideRef } = props;

  // Reference to the commandBarRef
  const commandBarRef = useRef<HTMLDivElement | null>(null);

    // close the fitler with ESC key
    useEffect(() => {
      const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          setOpen(false);
        }
      };
  
      document.addEventListener("keydown", handleEscapeKey);
  
      return () => {
        document.removeEventListener("keydown", handleEscapeKey);
      };
    }, []);

  return (
   <TransitionAnimation position="y-axis" show={isOpen}>
     <div
      ref={commandBarRef}
      className={`fixed transition-all border border-borderGray delay-75 right-[25rem] bottom-0 z-50 m-2 mb-6 flex min-w-[20%] max-w-max items-center justify-center gap-2 rounded-md bg-gray-50 p-3 shadow-lg 
      ${isOpen ? "" : "hidden"}
      `}
    >
      {children}
    </div>
   </TransitionAnimation>
  );
};
