import {
	ChangeEvent,
	FC,
	FormEvent,
	Fragment,
	memo,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	deleteContentTemplateById,
	getContentTemplateById,
	updateContentTemplateById,
} from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
// import AppButton from "../../../Shared/AppButton/AppButton";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import {
	ISurvey,
	ISurveyItem,
} from "../../../../Types/ContentTemplate/Survey";
import { ISurveySequence, ISurveySequenceContentTemplate, SurveySequenceContentCreateOrUpdateRequest, SurveySequenceContentResponse, SurveySequenceContentTemplate, SurveySequenceContentUpdatedResponse, initialSurveySequence } from "../../../../Types/ContentTemplate/SurveySequence";
// import AllSurveySequenceContent from "./AllSurveySequenceContent";
// import SurveySequenceItem from "./SurveySequenceItem";
import UseUploadFile from "../../../../Hook/UseUploadFile";
import UseUpload from "../../../../Hook/UseUpload";
import { baseURL } from "../../../../apiQuery";
import UploadProgress from "../../../General/UploadProgress";
import ViewContentTemplateHeader from "../../ViewContentTemplateHeader";
import { LanguageIcon, AppButton, PopupModal } from "../../../../Theme";
import { useNavigate } from "react-router-dom";
import { InlineSurveyItem } from "./InlineItem";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd"
import { reorder } from "../../../Utils/Reorder"
import { v4 as uuidv4 } from 'uuid';
import { AppLoader } from "../../../Shared/AppLoader";


export type Language = "en" | "fr" | "es";

export const LanguagesOptionsList = [
	{ value: "-", label: "Choose an option" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]



const initialItem = {
	appId: uuidv4(),
	value: 0,
	label: "",
	order: 0,
	language: LanguageEnum.English,
};

const initialSurvey = {
	title: "",
	alt_title: "",
	description: "",
	language: LanguageEnum.English,
	id: 0,
	appId: uuidv4(),
	order: 0,
	survey_items: [],
}




interface SurveySequenceTabContentProps {
	templateId: number,
}

const SurveySequenceTabContent: FC<SurveySequenceTabContentProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	// ================================================== Updoad cover image ==================================================
	const [imageSrc, file, render, name] = UseUploadFile('Survey')

	const queryClient = useQueryClient()

	const { uploadForm, progress, isLoading: isLoadingSurveySequnece } = UseUpload(`${baseURL}upload`)

	const [uploadState, setUploadState] = useState({
		file,
		name,
	})

	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])

	// ================================================== ==================================================
	const { currentOrganizationID } = useContext(UserContext)

	const sectionPageRef = useRef<HTMLDivElement>(null)

	const [surveys, setSurveys] = useState<ISurvey[]>([])
	const [surveySequenceContent, setSurveySequenceContent] =
		useState<SurveySequenceContentTemplate>(initialSurveySequence)

	const [state, setState] = useState<SurveySequenceContentTemplate[]>([])

	const [item, setItem] = useState<ISurveyItem>(initialItem)

	const [survey, setSurvey] = useState<ISurvey>(initialSurvey)

	const createOrUpdateButton = useRef<string>('Create')
	// const allSurveySequenceContentsRef = useRef(null);
	const createOrUpdateItemButton = useRef<string>('Create')

	const [selectedLanguage, setSelectedLanguage] = useState<string>('')
	//====================================================================================================================

	// delete action template
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] =
		useState(false)
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);

	const [surveySequenceData, setSurveySequenceData] = useState<SurveySequenceContentUpdatedResponse>()


	const {
		data: surveySequenceDataBeforeUpdate,
		refetch,
		isLoading,
	} = useQuery<SurveySequenceContentResponse, Error>(
		`surveySequenceData-content-${props.templateId}`,
		() => getContentTemplateById(props.templateId),
		{
			onSuccess(data) {
				const survey_sequence = data.data.survey_sequence
				const surveySequenceWithAppId = survey_sequence.map(
					(surveySequence) => {
						return {
							...surveySequence,
							surveys: surveySequence.surveys.map((survey) => ({
								...survey,
								survey_items: survey.survey_items.map((surveyItem) => {
									return { ...surveyItem, appId: uuidv4() } as ISurveyItem
								}) as ISurveyItem[],
								appId: uuidv4(),
							})) as ISurvey[],
							appId: uuidv4(),
						} as ISurveySequence
					}
				)
				const dataWithAppId = {
					...data,
					data: { ...data.data, survey_sequence: surveySequenceWithAppId },
				}
				setSurveySequenceData(dataWithAppId)
			},
		}
	)

	useEffect(() => {
		if (surveySequenceData?.data) {
			editableSurveyContent(surveySequenceData?.data)
		}
	}, [surveySequenceData?.data])

	// useEffect(() => {
	// 	if (selectedLanguage === '') return
	// 	// get seleted index of the content template to be displayed as the current content
	// 	const selectedIndex = surveySequenceData?.data['survey_sequence'].findIndex(
	// 		(item: any) => item.language === selectedLanguage
	// 	) as number
	// 	const survey_sequence: ISurveySequence = surveySequenceData?.data[
	// 		'survey_sequence'
	// 	][selectedIndex] as ISurveySequence
	// 	const contentMetaData = surveySequenceData?.data.content_metadata[
	// 		selectedIndex
	// 	] as IContentMetadata
	// 	setSurveySequenceContent({
	// 		survey_sequence: survey_sequence,
	// 		content_metadata: contentMetaData,
	// 	})
	// 	createOrUpdateButton.current = 'Update'
	// }, [selectedLanguage])

	const deleteTemplateFromContentTemplateList = async () => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/survey_sequence', { state: { deleted: true } })
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.survey_sequence.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}

	//=====================================================
	//set english default language for content

	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setSurveySequenceContent({
				survey_sequence: surveySequenceData?.data.survey_sequence.find((item) => item.language === LanguageEnum.English) as ISurveySequence,
				content_metadata: surveySequenceData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			createOrUpdateButton.current = "Update"
		}
	}, [surveySequenceData?.data.survey_sequence, surveySequenceData?.data.content_metadata])


	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.survey_sequence.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges

	}, [state])
	//=====================================================

	// ================================================== Mutations ==================================================

	const updateSurveySequenceContentMutation = useMutation(
		(params: {
			id: number
			data: SurveySequenceContentCreateOrUpdateRequest
		}) => updateContentTemplateById(props.templateId, params.data),
		{
			onSuccess: async (data) => {
				toast('Content updated successfully', 'success')
				await refetch()
			},
			onError: (error: any) => {
				const message =
					error?.response?.data?.message || 'Something went wrong!'
				toast(message, 'error')
			},
		}
	)
	//=====================================================
	const deleteContentTemplateByID = useMutation(
		(params: { id: number }) => deleteContentTemplateById(params.id),
		{
			onSuccess: async (data) => {
				toast('Template deleted successfully', 'success')
			},
			onError: (error: any) => {
				const message =
					error?.response?.data?.message || 'Something went wrong!'
				toast(message, 'error')
			},
		}
	)
	// ======================== Handlers================================================

	const onAddSurvey = () => {
		setSurveys((prev) => {
			return [
				...prev,
				{ ...initialSurvey, appId: uuidv4(), order: prev.length },
			]
		})

		createOrUpdateItemButton.current = 'Create'
	}


	const handleChange = (
		e:
			| ChangeEvent<HTMLInputElement>
			| ChangeEvent<HTMLTextAreaElement>
			| ChangeEvent<HTMLSelectElement>
	) => {
		const { name, value } = e.target
		setSurveySequenceContent((prev) => {
			return {
				...prev,
				survey_sequence: {
					...prev.survey_sequence,
					[name]: value,
				},
			}
		})
	}

	const handleChangeLanguage = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target



		setSurveySequenceContent((prev) => {
			return {
				...prev,
				content_metadata: {
					...prev.content_metadata,
					language: value as LanguageEnum
				},
				survey_sequence: {
					...prev.survey_sequence,
					language: value as LanguageEnum,
				},
			}
		})

	}

	const onDragEnd = (result: DropResult): void => {
		if (!result.destination) {
			return
		}
		const localSurveys = surveys || []
		let updatedSurveys = reorder(
			localSurveys,
			result.source.index,
			result.destination.index
		) as ISurveyItem[]

		updatedSurveys = updatedSurveys.map((item, ind) => ({
			...item,
			order: ind
		}))


		// @ts-ignore
		// setWorkerFavorites(updatedFavorites);
		setSurveys(() => updatedSurveys)
	}



	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		event.stopPropagation()

		if (surveySequenceContent.survey_sequence.title === '') {
			toast('Please fill all the fields', 'info')
			return
		}

		if (surveys.length === 0) {
			toast('Please add at least one survey', 'info')
			return
		}

		if (createOrUpdateButton.current === 'Create') {
			// Todo : check if the language is already exist
			if (
				state.some(
					(item) =>
						item.survey_sequence.language ===
						surveySequenceContent.survey_sequence.language
				)
			) {
				toast('This survey sequence is already exist', 'error')
				return
			}

			let _cover_imageLink = ''

			if (uploadState.file !== undefined) {
				const formData = new FormData()
				// @ts-ignore
				formData.append('file', uploadState.file)
				const { link } = await uploadForm(formData)
				// setArticleContent(prev => ({...prev, cover_image: link}))
				_cover_imageLink = link
			}

			const surveySequenceContentWithImage: SurveySequenceContentTemplate = {
				survey_sequence: {
					...surveySequenceContent.survey_sequence,
					surveys: surveys.map(survey => ({ ...survey, language: surveySequenceContent.survey_sequence.language, survey_items: survey.survey_items.map((surveyItems) => ({ ...surveyItems, language: surveySequenceContent.survey_sequence.language })) })),
				},
				content_metadata: {
					...surveySequenceContent.content_metadata,
					cover_image: _cover_imageLink,
					language: surveySequenceContent.survey_sequence.language,
				},
			}

			// setState(prev => [...prev, surveySequenceContentWithImage])
			const newState = [...state, surveySequenceContentWithImage]
			await createOrUpdateContentTemplate(newState)

			setSurvey(initialSurvey)
			setItem(initialItem)
		} else {
			let cover_image =
				surveySequenceContent?.content_metadata?.cover_image || ''

			if (file !== undefined) {
				const formData = new FormData()
				// @ts-ignore
				formData.append('file', file)
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}

			const newState = state.map((item) => {
				if (
					item.survey_sequence.language ===
					surveySequenceContent.survey_sequence.language
				) {
					return {
						survey_sequence: {
							...surveySequenceContent.survey_sequence,
							surveys: surveys,
						},
						content_metadata: {
							...surveySequenceContent.content_metadata,
							cover_image,
						},
					}
				}
				return item
			})

			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = 'Create'
		}

		setSurvey(initialSurvey)
		setItem(initialItem)
		setSurveySequenceContent(initialSurveySequence)
		setUploadState({
			file: undefined,
			name: '',
		})
	}

	const createOrUpdateContentTemplate = async (
		data: SurveySequenceContentTemplate[]
	) => {
		if (data.length === 0) {
			toast('Please add at least one survey sequence template', 'info')
			return
		}


		const shapeData: SurveySequenceContentCreateOrUpdateRequest = {
			content_type: EnumContentType.SurveySequenceContent,
			survey_sequence: data.map((item) => {
				return {
					...item.survey_sequence,
					title: item.survey_sequence.title,
					description: item.survey_sequence.description,
					surveys: item.survey_sequence.surveys.map((survey) => {
						delete survey.id
						survey.survey_items.map((surveyItem) => {
							delete surveyItem.id
							surveyItem.language = item.survey_sequence.language as LanguageEnum
							return surveyItem
						})
						return survey
					}),
					language: item.survey_sequence.language as LanguageEnum,
				}
			}),
			content_metadata: data.map((item) => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.survey_sequence.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			}),
		}

		await updateSurveySequenceContentMutation.mutateAsync({
			id: props.templateId,
			data: shapeData,
		})
		setState([])
		setSurveys([])
		setSelectedLanguage('')
	}

	const editableSurveyContent = (surveySequenceContent: any) => {
		const combinedData: any[] = []
		// Loop through each article
		surveySequenceContent.survey_sequence.forEach((survey: any) => {
			// Loop through each content metadata
			surveySequenceContent.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (survey.language === metadata.language) {
					// Combine the properties of the survey and metadata objects
					const combinedObj = {
						survey_sequence: survey,
						content_metadata: metadata,
					}

					// setSurveys(prev => [...prev, survey.surveys])
					// Add the combined object to the final array
					combinedData.push(combinedObj)
				}
			})
		})
		const surveys = combinedData[0].survey_sequence.surveys.map((survey: any) => ({ ...survey, appId: uuidv4() }))
		if (surveys) setSurveys(surveys)

		setState(combinedData)
	}

	const deleteSurveyItem = (index: number) => {
		const newSurveys = surveys.filter((_, idx) => idx !== index)
		setSurveys(newSurveys)
	}

	const saveSurveyItem = (index: number, survey: ISurvey) => {
		// if (survey.survey_items.length === 0) {
		// 	toast('Please add at least one survey item', 'info')
		// 	return
		// }

		// if (survey.title === '') {
		// 	toast('Please enter a title for the survey', 'info')
		// 	return
		// }
		const newSurveysList = surveys.map((s, idx) => {
			if (idx === index) {
				return survey
			}
			return s
		})

		setSurveys(newSurveysList)
		// toast("Survey item saved.", "success");
	}




	return (
		<div ref={sectionPageRef}>
			<ViewContentTemplateHeader
				title={surveySequenceData?.data.survey_sequence[0].title || ''}
				referencesCount={surveySequenceData?.data.linked_content_count || 0}
			/>

			<div className="border border-borderGray border-t-0 bg-white px-2 py-2.5  sm:px-4 sticky top-0 z-30 flex gap-2">
				{surveySequenceData?.data.survey_sequence.sort((a, b) => {
					// Define a language order
					const languageOrder = { en: 0, es: 1, fr: 2 };

					// Get the language order for each object
					const orderA = languageOrder[a.language] || Infinity;
					const orderB = languageOrder[b.language] || Infinity;

					// Compare the language order
					return orderB - orderA;
				}).map(
					(item: any, idx: number) => {
						return (
							<AppButton
								key={item.id}
								variant={
									selectedLanguage === item.language ? 'info' : 'primary'
								}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setSurveySequenceContent(initialSurveySequence)
										setSelectedLanguage('')
										createOrUpdateButton.current = 'Create'
									} else {
										// editableSurveyContent(surveySequenceData.data)
										setSurveys(item.surveys)
										createOrUpdateButton.current = "Update"
										currentLanguage.current = item.language as LanguageEnum
										setSurveySequenceContent({
											survey_sequence: surveySequenceData.data.survey_sequence.find((ss) => ss.language === item.language) as ISurveySequence,
											content_metadata: surveySequenceData.data.content_metadata.find((metadata: IContentMetadata) => metadata.language === item.language) as IContentMetadata
										})

										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className="w-3 h-3" />
									<span className="text-xs">
										{getLangaugeLabel(item.language)}
									</span>
								</div>
							</AppButton>
						)
					}
				)}
				{(surveySequenceData?.data.survey_sequence?.length ?? 0) < 3 ? (
					<AppButton
						variant={'primary'}
						type="button"
						size="xs"
						onClick={() => {
							setSurveySequenceContent(initialSurveySequence)
							setSurveys([])
							setSelectedLanguage('')
							createOrUpdateButton.current = 'Create'
						}}
					>
						<span className="text-xs">{t('new_language')}</span>
					</AppButton>
				) : null}
			</div>

			<TabContentLayout>
				{/* =========================== */}
				<AppLoader isLoading={isLoadingSurveySequnece} />
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">
					{surveySequenceContent?.survey_sequence?.title || ''}
				</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">
					<div className="flex w-full gap-2 mb-8">
						<AppButton
							size="xs"
							disabled={
								surveySequenceContent?.survey_sequence?.title === '' ||
									updateSurveySequenceContentMutation.isLoading ||
									deleteContentTemplateByID.isLoading
									? true
									: false
							}
							variant="primary"
							type="submit"
						>
							{createOrUpdateButton.current} Content Template
						</AppButton>
						<AppButton
							disabled={selectedLanguage ? false : true}
							size="xs"
							variant="primary"
							type="button"
							onClick={() => {
								setShowPopupDeleteConfirmation(true)
								sectionPageRef?.current?.scrollIntoView({ behavior: 'smooth' })
							}}
						>
							{t('content_template.delete_content_template')}
						</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>
					</div>
					<hr className="mb-8" />

					<AppInput
						autoFocus
						placeholder={'Title'}
						value={surveySequenceContent?.survey_sequence?.title || ''}
						name="title"
						isFull
						onChange={handleChange}
						label="Title"
						required
					/>
					<AppTextArea
						placeholder={'Description'}
						rows={4}
						value={surveySequenceContent?.survey_sequence?.description || ''}
						name="description"
						onChange={handleChange}
						label="Description"
					/>

					{/* ===================== Survey Items ======================== */}

					<div className="p-2 ">
						<AppButton
							type="button"
							size="sm"
							variant="primary"
							extendClass="my-2"
							onClick={() => {
								setSurveys((prev) => {
									return [...prev, { ...initialSurvey, appId: uuidv4(), order: prev.length }]
								})

								createOrUpdateItemButton.current = 'Create'
							}}
						>
							Add Survey
						</AppButton>
					</div>


					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									className="flex flex-col gap-2"
								>
									{surveys.sort((a, b) => a.order - b.order).map((item: ISurvey, idx: number) => {
										return (
											<Draggable
												key={idx}
												draggableId={`survey-${idx}`}
												index={idx}
											>
												{(provided, snapshot) => (
													<div
														className="flex flex-col "
														ref={provided.innerRef}
														{...provided.draggableProps}
													>
														<SurveyItemDetails
															dragHandleProps={provided.dragHandleProps}
															index={idx}
															key={item.id ?? item.appId}

															survey={item}
															onDeleteSurveyItem={deleteSurveyItem}
															onSaveSurveyItem={saveSurveyItem}
															language={
																surveySequenceContent?.survey_sequence?.language
															}
														/>
													</div>
												)}
											</Draggable>
										)
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

					{/* ===================== Survey Items ======================== */}

					{/* <AppInput
						placeholder={'Alternative Title'}
						value={surveySequenceContent?.content_metadata?.alt_title || ''}
						name="alt_title"
						isFull
						onChange={(e) => {
							setSurveySequenceContent({
								...surveySequenceContent,
								content_metadata: {
									...surveySequenceContent.content_metadata,
									alt_title: e.target.value,
								},
							})
						}}
						label="Alternative Title"
						maxLength={30}
						extendClass="my-2"
						required
					/> */}

					<AppInput
						placeholder={'Preview Title'}
						value={surveySequenceContent?.content_metadata?.preview_title || ''}
						name="preview_title"
						isFull
						onChange={(e) => {
							setSurveySequenceContent((prev) => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										preview_title: e.target.value,
									},
								}
							})
						}}
						label="Preview Title"
						extendClass="my-2"
						maxLength={140}
						required
					/>
					<AppInput
						placeholder={'Tag'}
						value={surveySequenceContent?.content_metadata?.tags?.[0] || ''}
						name="tags"
						isFull
						onChange={(e) => {
							setSurveySequenceContent((prev) => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										tags: [e.target.value],
									},
								}
							})
						}}
						label="Tag"
						extendClass="my-2"
					/>
					{surveySequenceContent?.content_metadata?.cover_image ? (
						<div className='cover__image-container'>
							<img
								alt="conver_image"
								src={surveySequenceContent?.content_metadata?.cover_image}
								className="my-2 rounded-sm"
							/>
						</div>
					) : null}
					{render}

					{uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}

					{isLoading ? <UploadProgress progress={progress} /> : null}

					{/* ========================================================= */}
					{/* <AppDropDown
						disabled={createOrUpdateButton.current === 'Update'}
						label={'Language'}
						options={LanguagesOptions}
						value={surveySequenceContent?.survey_sequence?.language || ''}
						name="language"
						onChange={handleChangeLanguage}
					/> */}
					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={surveySequenceContent?.survey_sequence?.language || ""} name="language" onChange={handleChange} />
					}
				</form>
			</TabContentLayout>

			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</div>
	)
};



export interface SurveyItemDetailsProps {
	survey: ISurvey;
	index: number;
	dragHandleProps: any;
	onDeleteSurveyItem: (index: number) => void;
	onSaveSurveyItem: (index: number, survey: ISurvey) => void;
	language: LanguageEnum
}


const SurveyItemDetails = memo((props: SurveyItemDetailsProps) => {

	// ======================== useStates ================================

	const [item, setItem] = useState<ISurveyItem>(initialItem);

	const [showInput, setShowInput] = useState({ appId: null, isInput: false });

	const [survey, setSurvey] = useState<ISurvey>(props.survey);

	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

	//========================= useRef ==========================================

	const createOrUpdateItemButton = useRef<string>("Create");

	const saveOrUpdateSurveyButton = useRef<string>("Save");

	//=====================  useEffects  =================================

	useEffect(() => {
		props.onSaveSurveyItem(props.index, survey)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [survey])

	// useEffect(() => {
	//   if(props.survey) {
	//     setSurvey(props.survey)
	//   }
	// },[props.survey])

	// useEffect(() => {
	//   if (props.survey.survey_items.length > 0) {
	//     setSurvey(props.survey)
	//   }
	// }, [props.survey])

	//=====================  Handlers  =================================
	const onDragEnd = (result: DropResult): void => {
		if (!result.destination) {
			return
		}
		const survey_items = survey.survey_items || []
		let updatedSurveyItems = reorder(
			survey_items,
			result.source.index,
			result.destination.index
		) as ISurveyItem[]

		updatedSurveyItems = updatedSurveyItems.map((item, ind) => ({
			...item,
			order: ind
		}))


		// @ts-ignore
		// setWorkerFavorites(updatedFavorites);
		setSurvey((prev) => {
			return {
				...prev,
				survey_items: updatedSurveyItems,
			}
		})
	}


	const addASurveyItem = () => {


		if (item.label === '') {
			toast('Please enter a label', 'error')
			return
		}

		setSurvey(prev => {
			return {
				...prev,
				survey_items: [...prev.survey_items, { ...item, order: prev.survey_items.length }]
			}
		})

		setItem({
			value: 0,
			label: '',
			id: 0,
			appId: uuidv4(),
			order: 0,
			language: LanguageEnum.English,
		})
		createOrUpdateItemButton.current = "Create"
	};


	return (
		<div className="p-3 my-5 overflow-hidden border rounded border-borderGray bg-gray-50">
			<button {...props.dragHandleProps}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 4.566 7.325"
					aria-hidden="true"
					className={
						'h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white '
					}
				>
					<path
						id="icon_drag"
						d="M14.893,15.325a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,15.325Zm2.758,0a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,17.651,15.325Zm-2.758-2.758a.879.879,0,0,1-.629-.252A.862.862,0,0,1,14,11.662a.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652A.913.913,0,0,1,14.893,12.566Zm2.758,0a.879.879,0,0,1-.629-.252.862.862,0,0,1-.263-.652.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652.913.913,0,0,1-.647.252ZM14.893,9.808a.85.85,0,0,1-.629-.269A.9.9,0,0,1,14.9,8a.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,9.808Zm2.758,0a.85.85,0,0,1-.629-.269A.9.9,0,0,1,17.662,8,.9.9,0,0,1,18.3,9.545.891.891,0,0,1,17.651,9.808Z"
						transform="translate(-14 -8)"
						fill="currentColor"
					/>
				</svg>{' '}
			</button>
			<div className="flex items-center justify-between p-3">
				<div className="flex flex-col w-full py-2 pl-1">
					<span className="font-semibold text-darkColor">
						Survey Items Details
					</span>
					{isCollapsed ? (
						<>
							<p className="mt-2 text-sm text-textDark">
								Title: {survey.title}
							</p>
						</>
					) : null}
				</div>
				<button
					onClick={(event) => {
						event.preventDefault()
						setIsCollapsed((prev) => !prev)
					}}
				>
					{!isCollapsed ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6"
							viewBox="0 -960 960 960"
						>
							<path
								fill="currentColor"
								d="M480-344L240-584l43-43 197 197 197-197 43 43-240 240z"
							></path>
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6"
							viewBox="0 -960 960 960"
						>
							<path
								fill="currentColor"
								d="M480-554L283-357l-43-43 240-240 240 240-43 43-197-197z"
							></path>
						</svg>
					)}
				</button>
			</div>

			<div className={`${isCollapsed ? 'hidden' : ''}`}>
				<div className="grid grid-cols-1 gap-2 mb-2">
					<div className="w-11/12 p-1 m-1">
						<AppInput
							placeholder={'Title'}
							name="title"
							isFull
							onChange={(e) => {
								setSurvey((prev) => {
									return {
										...prev,
										title: e.target.value,
									}
								})
							}}
							value={survey.title}
							label="Title"
							required
						/>
						{/* <AppInput
							placeholder={'Alternative Title'}
							name="survey_alt_title"
							isFull
							onChange={(e) => {
								setSurvey((prev) => {
									return {
										...prev,
										alt_title: e.target.value,
									}
								})
							}}
							value={survey.alt_title}
							label="Alternative Title"
							required
						/> */}

						<AppTextArea
							rows={3}
							placeholder={'Description'}
							name="description"
							value={survey.description}
							isFull
							onChange={(e) => {
								setSurvey((prev) => {
									return {
										...prev,
										description: e.target.value,
									}
								})
							}}
							label="Description"
						/>
					</div>
				</div>

				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								className="flex flex-col gap-2"
							>
								{survey?.survey_items
									? survey?.survey_items
										?.sort((a, b) => a.order - b.order)
										?.map((item: ISurveyItem, idx: number) => {
											return (
												<Draggable
													key={item.appId}
													draggableId={`item-${item.appId}`}
													index={idx}
												>
													{(provided, snapshot) => (
														<div
															className="flex flex-col "
															ref={provided.innerRef}
															{...provided.draggableProps}
														>
															<InlineSurveyItem
																dragHandleProps={provided.dragHandleProps}
																surveys={survey}
																item={item}
																setSurvey={setSurvey}
																showInputAppId={showInput.appId}
																showInput={showInput}
																setShowInput={setShowInput}
															/>
														</div>
													)}
												</Draggable>
											)
										})
									: null}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>

				{/* ADD new Survey */}
				<div className="flex flex-col gap-2 items-start justify-start w-[90%] p-2 m-1 border rounded bg-hoverLightBlue">
					<AppInput
						placeholder={'Survey Item Label'}
						value={item.label}
						name="label"
						label="Survey Item Label"
						onChange={(e) =>
							setItem((prev) => {
								return { ...prev, label: e.target.value }
							})
						}
						extendClass=""
						isFull
					/>
					<AppInput
						placeholder={'Survey Item Value'}
						value={item.value.toString()}
						name="value"
						label="Survey Item Value"
						onChange={(e) =>
							setItem((prev) => {
								return { ...prev, value: Number(e.target.value) }
							})
						}
						extendClass=""
						isFull
					/>

					{/* <AppDropDown
						label={'Language'}
						isFull
						disabled
						options={LanguagesOptions}
						// value={item.language}
						value={props.language}
						name="language"
						onChange={(e) =>
							setItem((prev) => {
								return { ...prev, language: e.target.value as LanguageEnum }
							})
						}
					/> */}

					<AppButton
						variant={
							createOrUpdateItemButton.current === 'Create'
								? 'secondary'
								: 'info'
						}
						type={'button'}
						size={'sm'}
						onClick={addASurveyItem}
						extendClass="mx-2"
					>
						{createOrUpdateItemButton.current} Survey Item
					</AppButton>
				</div>
			</div>

			<div className="flex w-full p-2 mt-3 bg-gray-50">
				{/* <AppButton
					variant="success"
					onClick={() => {
						props.onSaveSurveyItem(props.index, survey)
						toast('Saved Survey')
					}}
					type="button"
					size="sm"
					extendClass="mx-2"
				>
					Save Survey
				</AppButton> */}
				<AppButton
					variant="danger"
					onClick={() => {
						props.onDeleteSurveyItem(props.index)
					}}
					type="button"
					size="sm"
					extendClass="mx-2"
				>
					Delete Survey
				</AppButton>
			</div>
		</div>
	)
})

SurveyItemDetails.displayName = "SurveyItemDetails";

export default SurveySequenceTabContent;
