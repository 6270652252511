import { getToken } from "../../utils/LocalStorage";
import API from "../index";

export const getAllFavorites = async () => {
  const { data } = await API.get(`/admin/organizations/favorites/`);
  return data;
};

export const DeleteFavoriteBySlug = async (favorite_slug:string) => {
    const { data } = await API.delete(`/admin/organizations/favorites/:${favorite_slug}`);
    return data;
}

export const CreateNewFavorite = async (values:any)=>{
  const { data } = await API.post(`/admin/organizations/favorites}`,{...values});
  return data;
}