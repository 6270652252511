import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import NestedContentListItem from '../../../Content/NestedContentListItem'
import AppInput from '../../../Shared/AppInput/AppInput'
import AppTextArea from '../../../Shared/AppTextArea/AppTextArea'
import { ISelectorItem } from '../../../../Types/ContentTemplate/Selector'
import { AppButton, AppIconButton } from '../../../../Theme'
import { baseURL } from '../../../../apiQuery/index';
import _ from 'lodash'
import NewUseUploadFile from '../../../../Hook/NewUseUploadFile'
import UseUpload from "../../../../Hook/UseUpload"

interface InlineSelectorItemProps {
	item: ISelectorItem
	selectorItems: ISelectorItem[]
	index: number
	setSelectorItem: Dispatch<SetStateAction<ISelectorItem>>
	setSelectorItems: Dispatch<SetStateAction<ISelectorItem[]>>
}



const InlineSelectorItem: React.FC<InlineSelectorItemProps> = ({
	item,
	selectorItems,
	index,
	setSelectorItem,
	setSelectorItems,
}) => {
	// TODO: Add ability to edit cover image of items

	
	const [imageSrc, itemCoverImageFile, render, itemCoverImageName, setItemCoverImageSrc] = NewUseUploadFile('Item')


	
	
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);
	
	async function handleUploadNewImage(uploadStateItem: {file: any, name: any}) {
		let itemImageLink = ''
		if (uploadStateItem.file !== undefined) {
			const formData = new FormData();
			// @ts-ignore
			formData.append("file", uploadStateItem.file);
			const { link } = await uploadForm(formData)
			if (link) {
			}
			itemImageLink = link
			setTempItem((prev) => ({...prev, image: itemImageLink}))
		}
	}




	const [uploadStateItem, setUploadStateItem] = useState({
		file: itemCoverImageFile,
		name: itemCoverImageName,
	})

	useEffect(() => {

		if (itemCoverImageName && itemCoverImageFile) {
			setUploadStateItem({
				file: itemCoverImageFile,
				name: itemCoverImageName,
			})
		}
	}, [itemCoverImageName, itemCoverImageFile])

	const [isEditing, setIsEditing] = useState(false)
	const [tempItem, setTempItem] = useState(item)


	// =============== useEffects =====================
	useEffect(() => {
		handleUploadNewImage(uploadStateItem)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadStateItem])

	useEffect(() => {
		setItemCoverImageSrc(item.image)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item])
	
	//================ handlers========================

	const handleEdit = () => {
		setIsEditing(true)
	}

	const handleSave = () => {
		if (tempItem.title.trim() === '') {
			// Prevent saving if the title is empty
			return
		}

		setSelectorItems((prev) =>
			prev.map((i) => (i.appId === tempItem.appId ? tempItem : i))
		)

		setIsEditing(false)
	}

	const handleCancel = () => {
		// Reset the temporary item to the original item
		setTempItem(item)

		setIsEditing(false)
	}

	const handleDelete = () => {
		const clonedSelectorItems = [...selectorItems]

		const updatedSelectorItems = clonedSelectorItems.filter((i) => {
			return i.appId !== item.appId
		})

		setSelectorItems(updatedSelectorItems)
	}

	return (
		<div className="flex items-center w-[90%] gap-2 p-1 m-1 bg-primary rounded shadow-sm">
			<div className="flex items-center justify-between w-full gap-2">
				{isEditing ? (
					<>
						<div className="flex flex-col w-[90%]">
							<AppInput
								placeholder={'Title'}
								value={tempItem.title}
								name="title"
								onChange={(e) =>
									setTempItem((prev) => {
										return { ...prev, title: e.target.value }
									})
								}
								extendClass=""
								isFull
							/>

							<AppTextArea
								rows={2}
								placeholder={'Description'}
								value={tempItem.description}
								name="description"
								onChange={(e) =>
									setTempItem((prev) => {
										return { ...prev, description: e.target.value }
									})
								}
								extendClass=""
								isFull
							/>
						</div>
						{render}

						<div className="flex items-center gap-2">
							<AppIconButton
								variant="danger"
								type="button"
								onClick={handleDelete}
							/>
							<AppIconButton
								variant="success"
								type="button"
								onClick={handleSave}
							/>
							<AppIconButton
								variant="default"
								type="button"
								onClick={handleCancel}
							/>
						</div>
					</>
				) : null}

				{!isEditing && (
					<>
						<NestedContentListItem label={tempItem.title} />
						{tempItem.image ? (
							<img className="h-10" src={tempItem.image} alt="itemImage" />
						) : null}
						<AppIconButton
							variant="danger"
							type="button"
							onClick={handleDelete}
						/>
						<AppIconButton variant="info" type="button" onClick={handleEdit} />
					</>
				)}
			</div>
		</div>
	)
}

export default InlineSelectorItem
