import { RuleGroupTypeAny, formatQuery } from "react-querybuilder"
import { renameRuleName } from "../../../utils/RuleHelper"

const getNewKey=(key:string)=>{
    if(key ==="obj") {return "var"}
    if(key ==="var") {return "obj"}
    if(key ==="eq") {return "=="}
    if(key ==="=="){return "eq"}
    if(key ==="not") {return "!="}
    if(key ==="!="){return "not"}
    return key
}

export const backendReplaceAllObjectKeys = (obj:any) => {
  return new Promise((resolve, reject) => {
    const processObject = (inputObj:any) => {
      if (Array.isArray(inputObj)) {
        let constIndex = -1;
        let constValue:any = "" ;
        for (let i = 0; i < inputObj.length; i++) {
          if (typeof inputObj[i] === "object") {
            for (const key in inputObj[i]) {
              if (key === "const") {
                constIndex = i;
                constValue = inputObj[i].const;
                delete inputObj[i].const; // Delete the read-only property
              }
            }
          } else {
        
            if(typeof inputObj[i] === "string") {
              constIndex = i
              constValue = {
                "const": inputObj[i]          
              }
              delete inputObj[i]
            }
          }
        }
        if (constIndex !== -1) {
          inputObj[constIndex] = constValue;
        }
      }

      if (Array.isArray(inputObj)) {
        for (let i = 0; i < inputObj.length; i++) {
          processObject(inputObj[i]);
        }
      } else if (typeof inputObj === "object" && inputObj !== null) {
        const keys = Object.keys(inputObj);
        for (const key of keys) {
          const newKey = getNewKey(key);
          if (key !== newKey) {
            Object.defineProperty(
              inputObj,
              newKey,
              Object.getOwnPropertyDescriptor(inputObj, key) || {} // Use an empty object as a fallback if the property descriptor is undefined
            );
            delete inputObj[key]; // Delete the original property
          }
          processObject(inputObj[newKey]);
        }
      }
    };
    processObject(obj);
    resolve(obj);
  });
};
export const formatterQuery = (query:RuleGroupTypeAny) => {
    return new Promise((resolve, reject) => {
      const format = formatQuery(query, 'jsonlogic')
      resolve(format)
    })
} 


export const objectFormatter =  (obj: any) => {
  return obj
}

export const ruleNameFormatter = (ruleName:string | any) => { 

  if(typeof ruleName !== "string") {
    return objectFormatter(ruleName as any)
  }

  return ruleName.split('.')[1].replace(/([A-Z])/g, ' $1').trim().includes('I D') ? 
  ruleName.split('.')[1].replace(/([A-Z])/g, ' $1').trim().replace('I D', 'ID') :
  ruleName.split('.')[1].replace(/([A-Z])/g, ' $1').trim()
}