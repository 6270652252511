import React, {FC, memo, ReactNode, useContext, useRef, useState} from "react";
import {BiDotsVerticalRounded} from "react-icons/bi";
import TypeIcon from "./TypeIcon";
import useOnClickOutside from "../../Hook/UseOutsideHook";
import {FaList} from "react-icons/fa";
import { UserContext } from "../../Context/UserContext/UserContext";
import { useTranslation } from 'react-i18next';

type options = {
    icon: ReactNode,
    text: string,
    onClick: (id: number, temp?: number) => void
}
type SearchProps = {
    id?: number
    title?: string;
    type?: string,
    isTemplate?: boolean,
    willTemplateLinked?: boolean
    dotOptionElm?: options[],
    onClick?: () => void,
    linked_content_count?: number,
    contentTemp?: number
    ruleType?: string
    contentPriority?: number;
}

const CardUi: FC<SearchProps> = memo(({
                                          title,
                                          type = "",
                                          isTemplate,
                                          dotOptionElm,
                                          id,
                                          ruleType,
                                          onClick,
                                          willTemplateLinked,
                                          linked_content_count,
                                          contentTemp,

                                          contentPriority
                                      }) => {

     const { isAdmin } = useContext(UserContext)

     const { t, i18n } = useTranslation();

    const [showOption, setShowOption] = useState(false)

    const optionRef = useRef() as any;
    useOnClickOutside(optionRef, () => setShowOption(false))

    const handleItemDispatch = (item: any) => {
        if (!id && id !== 0) return
        item.onClick(id, contentTemp)
        setShowOption(false)
    }

    return (
        <div className={`border border-[#D8D8D8] rounded bg-white font-light hover:shadow-md`}>
            <div onClick={() => onClick ? onClick() : {}}
                 className={`p-3 h-52 space-y-1.5 overflow-hidden ${onClick && 'cursor-pointer'}`}>
                <div className="flex items-center pb-3 space-x-2 text-base text-primary-400">
                    <TypeIcon type={type.toLowerCase()}/>
                    <span className="text-sm font-normal uppercase"> {`${t(`Search.${type}`)}`}</span>
                </div>
                <span className="text-xl font-medium text-gray-600 line-clamp-2">{title}</span>
                {/*{*/}
                {/*    isTemplate !== undefined &&*/}
                {/*    <div className='text-sm font-normal'>Content{*/}
                {/*        isTemplate ? <span className="inline text-blue-600"> linked </span> :*/}
                {/*            <span className="inline text-orange-400"> not linked </span>*/}
                {/*    }to a template*/} 
                {/*    </div>*/}
                {/*}*/}

                <div className='text-sm font-normal'>
                 {t('Card.Content_Priority')}: {contentPriority}
                </div>

                {/*{*/}
                {/*    willTemplateLinked !== undefined &&*/}
                {/*    <div>Content <span className="inline text-blue-600"> will be linked </span> to a template</div>*/}
                {/*}*/}
            </div>

            <hr/>

            <div className="relative flex items-center justify-between p-3">
                <span
                    className='text-sm font-normal'>{ruleType === 'time base condition' ? 'Time-based Delivery Condition' : ruleType === 'rule condition with delay' ? 'Rule-based Delivery Condition' : 'No display Condition'}</span>
                {
                    linked_content_count !== undefined &&
                    <div className="flex items-center space-x-1">
                        <FaList/>
                        <p className='text-sm font-normal'>Linked in {linked_content_count} states</p>
                    </div>
                }

                <div className="flex items-center space-x-2.5 text-xl">
                    {isAdmin() && <div ref={optionRef}>
                        {
                            dotOptionElm &&
                            <BiDotsVerticalRounded className="cursor-pointer "
                                                   onClick={() => setShowOption(prev => !prev)}/>
                        }
                        {
                            (showOption && dotOptionElm) &&
                            <div
                                className="absolute top-2/5 right-[-3rem] bg-white text-sm border shadow py-3 px-4">
                                <div className="space-y-2">
                                    {dotOptionElm?.map((item, index) => <div key={index}
                                                                             onClick={() => handleItemDispatch(item)}
                                                                             className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                                        {item.icon}
                                        <p>{item.text}</p>
                                    </div>)}
                                </div>
                            </div>
                        }
                    </div>}
                </div>

            </div>
        </div>
    );
});

export default CardUi;