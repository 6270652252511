import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { AppButton, AppDivider, CommandBar, DeleteIcon, Loader, PopupModal, THead, TabBar, Table } from "../../Theme";
import { getLangaugeLabel } from "../../utils/Languages";
import { Drawer } from "../../Theme/Drawer";
import { useNavigate } from "react-router-dom";
import { deleteActionTemplateById, getActionTemplateFilterByActionType, getActionTemplateFilterByActionTypePagination } from "../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { Favorite } from "../Container";
import AppSearch from "../Shared/AppSearch/AppSearch";
import { toast } from "../../utils/Toast";
import { AppLoader } from "../Shared/AppLoader";
import { useInView } from "react-intersection-observer";
import { debounce } from "lodash";


const ActionTemplatePage: FC = () => {
  const params = useParams();
  const type = params.actionType || "";

  const queryClient = useQueryClient();

  // Getting filter key from params
  const navigate = useNavigate();
  const location = useLocation();

  //! Getting filter key from params
  let paramsValue = params.id?.replace('filterParam=', '') || "";
  const [searchValue, setSearchValue] = useState<any>();

  useEffect(() => {
    if (paramsValue) {
      setSearchValue(paramsValue === "allData" ? searchValue : paramsValue)
    }
  }, [paramsValue])


  //! onSearchChange defined - input callback function
  const onSearchChange = (event: { target: { value: string; }; }) => {
    debounceFunction(event.target.value.toLocaleLowerCase())
  }
  //! Debounce function - Debounce to prevent frequent API requests 
  const debounceFunction = useMemo(() => {
    return debounce((item: any) => {
      setSearchValue(item)
    }, 700)
  }, [])

  //! API - Fetch data from API using debounced FilterKey
  const fetchData = ({ pageParam = "" }) =>
    getActionTemplateFilterByActionTypePagination(type, pageParam, searchValue);

  // @ts-ignore
  const { data, isLoading, refetch, isError, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery<any, Error>(['all-action-templates', type, searchValue], fetchData,
    {
      getNextPageParam: (lastPage, all) => {
        if (lastPage && lastPage.data.data) {
          if (lastPage.data.paginate.next === "") return undefined;
          return lastPage.data.paginate.next;
        }
      },
    }
  );

  useEffect(() => {
    if (location.state && location.state.deleted) {
      refetch();
      queryClient.invalidateQueries('getActionTemplateFilterByActionType'); // O
    } else
      refetch();
  }, [location, queryClient]);

  // ============================== HOOKS ===============================
  const tableRef = useRef<HTMLTableSectionElement | null>(null);


  // ============================ DRAWER ========================================

  const [selectedActionTemplate, setSelectedActionTemplate] = useState<any>();

  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = useCallback(
    () => setShowDrawer(false),
    []
  );


  const showDrawerHandler = useCallback(
    (actionTemplate: any) => {
      setShowDrawer(prev => !prev);
      setSelectedActionTemplate(actionTemplate);
    },
    []
  );


  // ============================ COMMANDBAR ====================================
  const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
  const [showCommandBar, setShowCommandBar] = useState(false);
  const [selectedActionTemplates, setSelectedActionTemplates] = useState<number[]>([]);

  useEffect(() => {
    if (selectedActionTemplates.length > 0) {
      setShowCommandBar(true);
    } else {
      setShowCommandBar(false);
    }
  }, [selectedActionTemplates.length]);

  const tableContentRef = useRef<HTMLTableSectionElement | null>(null);
  useEffect(() => {
    // get table client.y
    const tableElement = tableContentRef.current;
    const tableClientY = tableElement?.getBoundingClientRect().y;
    const tableHeight = tableElement?.clientHeight;
    const windowHeight = window.innerHeight;
    // check if table height is more than window height	  

    if (!hasNextPage) {
      return
    }

    if (
      tableHeight &&
      tableClientY &&
      windowHeight &&
      data &&
      data?.pages.length > 0
    ) {
      if (tableClientY + tableHeight < windowHeight) {
        fetchNextPage();
      }

    }
  }, [data, fetchNextPage, hasNextPage]);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  // ========================== HANDLERS ========================================
  const navigateToViewActionTemplate = (selectedActionTemplate: any) => {
    const templateId = selectedActionTemplate.id
    navigate(`/action-templates/${type}/view/${templateId}`)
  }

  const navigateToNewActionTemplate = () => {
    navigate(`/action-templates/${type}/new`)
  }

  if (isError) return <p>Something went wrong</p>;



  const importanceSwitch = (item: any) => {
    let importanceLabel;

    switch (item) {
      case 0:
        importanceLabel = 'Standard';
        break;
      case 1:
        importanceLabel = 'Priority';
        break;
      case 2:
        importanceLabel = 'Urgent';
        break;
      default:
        importanceLabel = 'Unknown';
    }

    return importanceLabel;
  }


  const sliceTitle = (title: string) => {
    if (title.length > 90) {
      return title.slice(0, 90) + '...';
    } else {
      return title;
    }
  }


  const chooseActionCheckboxHandler = (item: any) => {
    if (selectedActionTemplates.includes(item.id)) {
      setSelectedActionTemplates(
        selectedActionTemplates.filter(
          (content) => content !== item.id
        )
      );
    } else {
      setSelectedActionTemplates([
        ...selectedActionTemplates,
        item.id,
      ]);
    }
    // if the selectedPathways is empty, hide the commandbar
    if (selectedActionTemplates.length === 0) {
      setShowCommandBar(false);
    } else {
      setShowCommandBar(true);
    }
  }



  const confirmDeleteActionTemplate = async () => {
    if (selectedActionTemplates.length <= 0) return;
    // close the popup
    setShowPopupDeleteConfirmation(false)
    try {
      // Map selected workers to an array of promises that delete them
      const deletePromises = selectedActionTemplates.map((worker) =>
        deleteActionTemplateById(worker),
      );

      // Wait for all delete promises to settle
      const results = await Promise.allSettled(deletePromises);
      const successPromises = results.filter(
        (result) => result.status === "fulfilled"
      );
      successPromises.forEach((promise) =>
        toast("Action deleted successfully", "success")
      );

      // Check for any failed promises and display error messages as toasts
      const failedPromises = results.filter(
        (result) => result.status === "rejected"
      );
      failedPromises.forEach((promise) => {

        // @ts-ignore
        const apiErrorMessage = promise?.reason?.response?.data.message || "Error deleting content template"
        toast(apiErrorMessage, "error")
      }
      );


      setSelectedActionTemplates([]);
      refetch();
    } catch (error) {
      const apiErrorMessage = "Error deleting content template"
      toast(apiErrorMessage, "error")
    }
  };



  return (
    <Fragment>
      <section className="relative">
        <TabBar />
        <div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
          <AppButton
            variant="primary"
            size="xs"
            type="button"
            onClick={navigateToNewActionTemplate}
          >
            <div className="flex items-center justify-center gap-1">
              <span className="text-xs capitalize">New {type === 'al_notification' ? 'AL Notification' : type} Template</span>
              <span>+</span>
            </div>
          </AppButton>
          <AppSearch
            className={
              "search-box  block w-full h-8 rounded-lg border-transparent bg-primary p-2.5 text-sm text-textDark outline-none focus:border-transparent focus:ring-0"
            }
            placeHolder={"Search templates"}
            onChangeHandler={onSearchChange}
            defaultValue={searchValue ?? paramsValue === "allData" ? "" : paramsValue}
          />
        </div>
        <div className="pb-10">
          <div className="w-[97%] py-5 m-auto space-y-5">
            <div className="flex items-center justify-center">
              <Loader isLoading={isLoading} size={35} />
            </div>
            {/* =============== */}
            <Table outsideRef={tableRef}>
              <THead>
                <tr>
                  <th scope="col" className="py-3 font-medium px-14">
                    {type === 'al_notification' ? 'AL Notification' : type} Templates
                  </th>
                  <th scope="col" className="py-3 font-medium">
                    Importance
                  </th>
                  <th scope="col" className="py-3 font-medium">
                    Languages
                  </th>
                </tr>
              </THead>
              <tbody>
                {data && data?.pages?.map((infinitePage, idx) => {

                  return (
                    <Fragment key={idx}>

                      {infinitePage?.data?.data?.map((item: any, index: number) => {


                        return (
                          <Fragment key={index}>
                            <tr
                              className="transition-all delay-75 bg-white border-b hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer "
                              key={idx}
                            >
                              <th
                                scope="row"
                                className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                              >
                                <div className="flex items-center justify-start">
                                  <input
                                    type="checkbox"
                                    onChange={() => chooseActionCheckboxHandler(item)}
                                    className="w-4 h-4 mx-2 text-blue-600 bg-transparent bg-gray-100 border-2 rounded-sm cursor-pointer border-borderGray focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                                  />
                                  <div className="flex flex-col items-start justify-center p-2">
                                    <p
                                      onClick={() => showDrawerHandler(item)}
                                      className="ml-1 whitespace-nowrap text-md text-textDark dark:text-white"
                                    >
                                      {sliceTitle(
                                        type === "notification"
                                          ? item.action_metadata[0].title
                                          : type === "email"
                                            ? item.action_metadata[0].subject
                                            : type === "sms"
                                              ? item.action_metadata[0].text :
                                              type === "al_notification" ?
                                                item.action_metadata[0].title
                                                : null
                                      )}
                                    </p>
                                    <p className="flex gap-1 text-xs whitespace-nowrap text-grayColor dark:text-white">
                                      {/* <ActiveTimerIcon className="w-3 h-3" /> */}
                                      {/* {item.linked_content ? "Active" : "Inactive"} */}
                                    </p>
                                  </div>
                                </div>
                              </th>

                              {/* <td className="py-3 pr-6 font-medium text-center capitalize text-textDark whitespace-nowrap dark:text-white">
                {item.content_type}
              </td> */}
                              <td
                                onClick={() => showDrawerHandler(item)}
                                className="py-3 pr-6 font-medium text-textDark whitespace-nowrap dark:text-white"
                              >
                                <div className="flex items-center gap-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    viewBox="0 0 9.879 9.638"
                                    className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                                  >
                                    <path
                                      id="icon_content"
                                      d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
                                      transform="translate(-4 -4)"
                                      fill="currentColor"
                                    />
                                  </svg>
                                  <span className="mx-1 text-sm text-textDark">
                                    {importanceSwitch(item.importance)}
                                  </span>
                                </div>
                              </td>
                              <td
                                onClick={() => showDrawerHandler(item)}
                                className="py-3 pr-6 font-medium text-textDark whitespace-nowrap dark:text-white "
                              >
                                <div className="flex items-center gap-2">
                                  {item.action_metadata.map(
                                    (item: any, idx: any, array: any) => (
                                      <span key={idx} className="text-sm text-textDark">
                                        {getLangaugeLabel(item.language)}
                                        {array.length > idx + 1 ? "," : null}
                                      </span>
                                    )
                                  )}
                                </div>
                              </td>
                            </tr>
                            <tr ref={ref}></tr>
                          </Fragment>
                        );
                      })}

                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
            {/* =============== */}
          </div>
          <div className="flex items-center justify-center">
            {isFetchingNextPage && <AppLoader isLoading size={25} />}
          </div>
        </div>
      </section>

      {/* =============== DRAWER ==================== */}

      <Drawer
        outsideRef={tableRef}
        setOpen={closeDrawer}
        isOpen={showDrawer}
        title=""
        isFull
        classNames="overflow-y-scroll"
      >
        {selectedActionTemplate ? (
          <div className="flex flex-col justify-between h-full overflow-y-scroll">
            <div className="flex flex-col gap-2 p-4">
              {/* ========== */}
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 7.573 8.414"
                  className="flex-shrink-0 w-10 h-10 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path
                    id="icon_pathways"
                    d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                    transform="translate(-6 -4)"
                    fill="currentColor"
                  />
                </svg>
                <div className="flex flex-col items-start justify-center p-2">
                  <p className="mb-2 text-lg whitespace-nowrap text-textDark dark:text-white">
                    {selectedActionTemplate.action_metadata[0].text || selectedActionTemplate.action_metadata[0].title}
                  </p>

                  <p className="flex items-center gap-1 text-xs whitespace-nowrap text-grayColor dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      viewBox="0 0 9.879 9.638"
                      className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                    >
                      <path
                        id="icon_content"
                        d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
                        transform="translate(-4 -4)"
                        fill="currentColor"
                      />
                    </svg>
                  </p>
                </div>
                <div className="flex justify-end">
                  <div className="relative flex flex-col items-center self-center justify-center ">
                    <Favorite
                      favorite_owner="Action"
                      slug={selectedActionTemplate.id.toString()}
                    />
                  </div>
                </div>
              </div>

              {/* ========== */}

              <div className="flex flex-col items-start justify-start gap-1 mb-2">
                <p className="text-xs text-textGray">
                  Action Template Description
                </p>
                <p className="text-sm text-textDark">
                  {selectedActionTemplate.action_metadata[0].subject || selectedActionTemplate.action_metadata[0].al_message}
                </p>
              </div>
              {/* <hr className="h-px border-1 border-borderGray dark:border-textDark" /> */}
              <AppDivider />
              <div className="flex flex-col items-start justify-start gap-1 mb-2">
                <p className="text-xs text-textGray">Action Template Details</p>
                <ul className="list-none">
                  <li className="flex gap-2 my-2">
                    <span className="w-20 text-sm text-textGray">Type</span>
                    <span className="text-sm capitalize text-textDark">
                      {selectedActionTemplate.action_type.replaceAll('_', ' ')}
                    </span>
                  </li>
                  <li className="flex gap-2 my-2">
                    <span className="w-20 text-sm text-textGray">
                      Languages
                    </span>

                    {selectedActionTemplate.action_metadata.map(
                      (item: any, idx: number, array: any) => (
                        <span className="text-sm text-textDark" key={idx}>
                          {getLangaugeLabel(item.language)}
                          {array.length > idx + 1 ? "," : null}
                        </span>
                      )
                    )}
                  </li>
                </ul>
              </div>

              <AppDivider />
            </div>

            <div className="mb-6">
              <div
                onClick={() =>
                  navigateToViewActionTemplate(selectedActionTemplate)
                }
                className="flex items-center justify-center w-full py-6 bg-blueColor btn-drawer hover:cursor-pointer"
              >
                <span className="flex items-center justify-center gap-2 text-sm text-white">
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 7.573 8.414"
                    className="flex-shrink-0 w-3 h-3 text-white"
                  >
                    <path
                      id="icon_pathways"
                      d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                      transform="translate(-6 -4)"
                      fill="currentColor"
                    />
                  </svg>{" "}
                  View Action Template{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </Drawer>

      {/* =============== COMMANDBAR ==================== */}
      <CommandBar
        isOpen={showCommandBar}
        setOpen={() => setShowCommandBar(!showCommandBar)}
      >
        <span className="text-xs text-textGray">
          {selectedActionTemplates.length} Template
          {selectedActionTemplates.length > 1 ? "s" : ""} Selected
        </span>
        {selectedActionTemplates.length === 1 ? (
          <AppButton
            type="button"
            variant="primary"
            onClick={() => {
              navigate(
                `/action-templates/${params.actionType}/view/${selectedActionTemplates[0]}`
              );
            }}
          >
            <div className="flex items-center gap-1 mx-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="flex-shrink-0 w-4 h-4 transition duration-100 cursor-pointer text-grayColor dark:text-gray-400 dark:group-hover:text-white"
              >
                <path
                  fill="currentColor"
                  d="M794 390L666 262l42-42q17-17 42.5-16.5T793 221l43 43q17 17 17 42t-17 42l-42 42zm-42 42L248 936H120V808l504-504 128 128z"
                ></path>
              </svg>
            </div>
            Edit Action Template
          </AppButton>
        ) : null}
        <AppButton
          type="button"
          variant="primary"
          onClick={() => setShowPopupDeleteConfirmation((prev) => !prev)}
        >
          <div className="flex items-center gap-1 mx-1">
            <DeleteIcon className="" />
            <span className="text-sm">
              Delete Action Template
              {selectedActionTemplates.length > 1 ? "s" : ""}
            </span>
          </div>
        </AppButton>
      </CommandBar>


      <PopupModal
        setOpen={setShowPopupDeleteConfirmation}
        isOpen={showPopupDeleteConfirmation}
        title="Are you sure you want to delete this Action Template ?
        Deleting this Action Template will delete all Actions associated with this Template."
        onConfirmClick={confirmDeleteActionTemplate}
      />

    </Fragment>
  );
};

export default ActionTemplatePage;
