import React, {ReactElement, useCallback} from "react"

type Props = {
    title: string
    count?: string | ReactElement
    setSelectedTab: string | ((tab: string) => void)
    selectedTab: string | ((tab: string) => void)
}

const TabTitle: React.FC<Props> = ({title, count, setSelectedTab, selectedTab}) => {

    const onClick = useCallback(() => {
        // @ts-ignore
        setSelectedTab(title)
    }, [setSelectedTab])

    return <div>

        <button type="button" className="font-semibold pb-2 flex items-center" onClick={onClick}>
            {title} {count && count}
        </button>
        {
            selectedTab === title && <div className="bg-indigo-400 h-0.5"/>
        }
    </div>
}

export default TabTitle