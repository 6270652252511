import React, { ForwardedRef, useCallback } from "react";
import { ArrowIcon, EyeIcon, CloseEyeIcon, THead, Table } from "../../Theme";
import { IPathways } from '../../Types/Pathways/Pathways';
import { useTranslation } from "react-i18next";


interface IPropsPathway {
  tableRef?: any;
  data?: any;
  selectedPathways: string[];
  outSideRef?: ForwardedRef<HTMLTableRowElement>;
  showDrawer:boolean;
  setShowDrawer:(showDrawer:boolean)=>void;
  setSelectedPathway:(selectedPathway:IPathways)=>void;
  setSelectedPathways:(selectedPathways: string[]) => void;
  setShowCommandBar:(showCommandBar:boolean)=>void
}


const PathwaysTable=({tableRef, data, selectedPathways, outSideRef, showDrawer, 
  setSelectedPathway, setShowDrawer, setSelectedPathways, setShowCommandBar}: IPropsPathway) => {
  const { t } = useTranslation();

  const showDrawerHandler = useCallback(
    (selectedPathway: IPathways) => {
      if (!showDrawer) setShowDrawer(true);
      setSelectedPathway(selectedPathway);
    },
    [showDrawer]
  );

  const choosePathwayCheckboxHandler = useCallback(
    (item: IPathways) => {
      if (selectedPathways.includes(item.slug)) {
        setSelectedPathways(
          selectedPathways.filter((pathway:any) => pathway !== item.slug)
        );
      } else {
        setSelectedPathways([...selectedPathways, item.slug]);
      }
      //! if the selectedPathways is empty, hide the CommandBar
      if (selectedPathways.length === 0) {
        setShowCommandBar(false);
      } else {
        setShowCommandBar(true);
      }
    },
    [selectedPathways]
  );
  return (
    <Table outsideRef={tableRef}>
      <THead>
        <tr>
          <th scope="col" className="py-3" />
          <th scope="col" className="px-6 py-3 font-medium">
          {t('PathwayPage.Pathway')}
          </th>
          <th scope="col" className="px-6 py-3 font-medium text-left">
          {t('PathwayPage.Entry_State')}

          </th>
          <th scope="col" className="px-6 py-3 font-medium text-left">
          {t('PathwayPage.State')}

          </th>
          <th scope="col" className="px-6 py-3 font-medium text-left">
          {t('PathwayPage.Workers')}

          </th>
        </tr>
      </THead>
      <tbody>
        {data?.map((infinitePage: any, index: any) => {
          return (
            <React.Fragment key={index}>
              {infinitePage?.data?.map((item: IPathways) => {
                return (
                  <tr
                    key={item.slug}
                    className="transition-all bg-white border-b row-worker hover:bg-primary dark:hover:bg-gray-600 hover:cursor-pointer"
                  >
                    <td
                      className="py-3 pl-4 font-medium text-textDark whitespace-nowrap dark:text-white"
                      width={50}
                    >
                      <input
                        type="checkbox"
                        checked={
                          selectedPathways?.length > 0
                            ? selectedPathways?.includes(item?.slug)
                            : false
                        }
                        onClick={() => choosePathwayCheckboxHandler(item)}
                        className="w-4 h-4 text-blue-600 bg-transparent bg-gray-200 border-2 border-gray-300 rounded-sm cursor-pointer checkbox-worker focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                      />
                    </td>
                    <td
                      className="py-3 pr-6 font-medium text-textDark whitespace-nowrap dark:text-white"
                      width={400}
                    >
                      <div className="flex items-center justify-start">
                        <div className="flex flex-col items-start justify-center p-2">
                          <div className="flex items-center gap-1">
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 7.573 8.414"
                              className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                            >
                              <path
                                id="icon_pathways"
                                d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                                transform="translate(-6 -4)"
                                fill="currentColor"
                              />
                            </svg>
                            <p
                              onClick={() => showDrawerHandler(item)}
                              className="ml-1 text-lg whitespace-nowrap text-textDark dark:text-white"
                            >
                              {item.name}
                            </p>
                          </div>

                          <p className="flex items-center gap-1 pl-5 text-sm whitespace-nowrap text-grayColor dark:text-white">
                            {item.status !== undefined && (
                              <>
                                {item.status ? (
                                  <>
                                    <EyeIcon className="w-3 h-3" />
                                    <span className="inline text-xs">
                                      {t("PathwayPage.Active")}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <CloseEyeIcon className="w-3 h-3" />
                                    <span className="inline text-xs">
                                      {" "}
                                      {t("PathwayPage.Inactive")}{" "}
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td
                      onClick={() => showDrawerHandler(item)}
                      className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white"
                      width={500}
                    >
                      <div className="flex items-center justify-start gap-1">
                        {item.entry_state_name ? (
                          <>
                            {" "}
                            <ArrowIcon className="w-3 h-3 text-lightGrayColor" />
                            <span className="text-md text-textDark">
                              {item.entry_state_name}
                            </span>
                          </>
                        ) : null}
                      </div>
                    </td>
                    <td
                      onClick={() => showDrawerHandler(item)}
                      className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white"
                      width={200}
                    >
                      <div className="flex items-center justify-start gap-1">
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 7.573 8.414"
                          className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                        >
                          <path
                            id="icon_pathways"
                            d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                            transform="translate(-6 -4)"
                            fill="currentColor"
                          />
                        </svg>
                        <span className="text-sm text-textDark">
                          {item.state_count || 0} States
                        </span>
                      </div>
                    </td>
                    <td
                      onClick={() => showDrawerHandler(item)}
                      className="py-3 pl-4 font-medium text-left text-textDark whitespace-nowrap dark:text-white"
                    >
                      <div className="flex items-center justify-start gap-1">
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-3 h-3 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 11.071 7.778"
                        >
                          <path
                            fill="currentColor"
                            d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
                            transform="translate(-1.9 -8.95)"
                          />
                        </svg>
                        <span className="text-sm text-textDark">
                          {" "}
                          {item.participant_count || 0} Workers
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          );
        })}
        <tr className="w-0 h-0 bg-white border-none" ref={outSideRef}></tr>
      </tbody>
    </Table>
  );
}

export default PathwaysTable;
