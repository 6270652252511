import API from "../index";
import { convert, serachTypes, State } from "../../Common/Types";
import { IStateLanguages } from "../../Types/States/States";

export const createState = async (
  organ: number,
  pathwayID: number,
  values: State
) => {
  const { data } = await API.post(
    `/admin/organizations/pathways/${pathwayID}/states/`,
    { ...values }
  );
  return data;
};

export const createStateByPathwaySlug = async (
  organ: number,
  pathwaySlug: string,
  values: any
) => {
  const { data } = await API.post(
    `/admin/organizations/pathways/${pathwaySlug}/states/`,
    { ...values }
  );
  return data;
};

export const updateState = async (
  pathwaySlug: string,
  stateSlug: string,
  values: any
) => {
  const { data } = await API.put(
    `/admin/organizations/pathways/${pathwaySlug}/states/${stateSlug}`,
    { ...values }
  );
  return data;
};

export const getState = async (pathwaySlug: string, StateSlug: string) => {
  const { data } = await API.get(
    `/admin/organizations/pathways/${pathwaySlug}/states/${StateSlug}`
  );
  return data;
};

export const deleteState = async (
  pathway_slug: string,
  state_slug: string
) => {
  const { data } = await API.delete(
    `/admin/organizations/pathways/${pathway_slug}/states/${state_slug}`
  );
  return data;
};

export const removeLinkToStateTemplate = async (id: number) => {
  const { data } = await API.post(`states/remove-link/${id}`);
  return data;
};

export const convertStateToStateTemplate = async (
  organ: number,
  pathwayID: number,
  stateID: string,
  values: convert
) => {
  const { data } = await API.post(
    `admin/organizations/pathways/${pathwayID}/states/${stateID}/convert-to-template`,
    { ...values }
  );
  return data;
};

export const getAllParticipantInState = async (
  organ: number,
  pathwaySlug: string,
  stateSlug: string,
  pageParam: string,
  filter?: serachTypes
) => {
  const { data } = await API.post(
    `/admin/organizations/pathways/${pathwaySlug}/states/${stateSlug}/participants/filter?limit=${20}${
      pageParam && `&next=${pageParam}`
    }`,
    {
      ...filter,
    }
  );
  return data;
};

// export const getAllContentsInState = async (organ: number, pathwayID: string, stateID: string, pageParam: string) => {
//     const { data } = await API.get(`/admin/organizations/pathways/${pathwayID}/states/${stateID}/contents?limit=${20}${pageParam && `&next=${pageParam}`}`)
//     return data;
// };

export const getAllContentsInState = async (
  organ: number,
  pathwaySlug: string,
  slug: string,
  pageParam: string,
  filter?: any
) => {
  const { data } = await API.post(
    `/admin/organizations/pathways/${pathwaySlug}/states/${slug}/contents/temp/filter?limit=50${
      pageParam && `&next=${pageParam}`
    }`,
    {
      content_type: null,
      search: "",
    }
  );
  return data;
};

export const getAllContentsInStateFilter = async (
  organ: number,
  pathwaySlug: string,
  slug: string,
  pageParam: string,
  datas: string[],
  search: string
) => {
  const { data } = await API.post(
    `/admin/organizations/pathways/${pathwaySlug}/states/${slug}/contents/temp/filter?limit=${20}${
      pageParam && `&next=${pageParam}`
    }`,
    {
      content_type: !!datas.length ? datas : null,
      search: search,
    }
  );
  return data;
};

// ============================================================

export const getAllActionInStateFilter = async (
  organ: number,
  pathwayID: string,
  stateID: string,
  pageParam: string,
  datas: string[],
  search: string
) => {
  const { data } = await API.post(
    `/admin/organizations/pathways/${pathwayID}/states/${stateID}/actions/temp/filter?limit=${20}${
      pageParam && `&next=${pageParam}`
    }`,
    {
      action_type: !!datas.length ? datas : undefined,
      search: search,
    }
  );
  return data;
};

export const getAllActionInState = async (
  organ: number,
  pathwayID: string,
  stateID: string,
  pageParam: string
) => {
  const { data } = await API.get(
    `/admin/organizations/pathways/${pathwayID}/states/${stateID}/actions?limit=25&page=${
      pageParam && `&next=${pageParam}`
    }`
  );
  return data;
};

export const createStateLanguage = async (pathwaySlug: string, stateSlug:string, data:IStateLanguages) => {
  const response = await API.post(`/admin/organizations/pathways/${pathwaySlug}/states/${stateSlug}/language`, data);
  return response.data;
}
export const updateStateLanguage = async (pathwaySlug: string, languageId: number, data:IStateLanguages) => {
  const response = await API.put(`/admin/organizations/pathways/${pathwaySlug}/states/language/${languageId}`, data);
  return response.data;
}
export const deleteStateLanguage = async (pathwaySlug: string, languageId:number) => {
  const response = await API.delete(`/admin/organizations/pathways/${pathwaySlug}/states/language/${languageId}`);
  return response.data;
}