export enum EnumActionType {
  EmailAction = "email",
  SMSAction = "sms",
  Ws1notificationAction = "notification",
  ALNotificationAction = "al_notification",
}
interface Paginate {
  limit: number;
  order: string;
  next: string;
  previous: string;
}
export enum LanguageEnum {
  English = "en",
  French = "fr",
  Spanish = "es",
}
// ActionMetadata
export interface IActionMetadata {
  id?: number;
  language: LanguageEnum;
  text?: string;
  subject?: string;
  body?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  organization_id?: number;
  al_message?: string;
  action_type?: string;
}
interface Response {
  created_at: string;
  updated_at: string;
}

export type IResponseActionMetadata = IActionMetadata & Response;
////////////////////////////////////////////////////////////////////////////

export interface IActionTemplateFilterResponse {
  data: {
    data: IActionTemplate[];
    error: boolean;
    message: string;
    paginate: Paginate;
    status: number;
  };
}
export type Data<T, R extends EnumActionType> = {
  [key in R]: T[];
} & IShape<R>;
interface IShape<T extends EnumActionType> {
  [key: string]: T;
}
export type IActionTemplate = {
  action_metadata: IActionMetadata[];
  action_type: EnumActionType;
  id: number;
  organization_id: number;
  importance?: number;
};
