


export const sortTemplate = <T extends any[]>(templates:T): T => {
    return templates.sort((a, b) => {
        // Define a language order
        const languageOrder = { en: 0, es: 1, fr: 2 };

        // Get the language order for each object
        // @ts-ignore
        const orderA = languageOrder[a.language] || Infinity;
        // @ts-ignore
        const orderB = languageOrder[b.language] || Infinity;

        // Compare the language order
        return orderB - orderA;
    })
}