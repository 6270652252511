import React, { createContext, useState, useEffect, useCallback } from 'react'
// import { validateAuthentication } from '../../services/AuthService'
import { getToken, removeOrganization, removeToken } from '../../utils/LocalStorage'
import { UserType, UserRoles } from './UserTypes'
import { useNavigate } from 'react-router-dom'
import { validateAuthentication } from '../../services/AuthService'


const initialUserState: UserType = {
    currentOrganization: '',
    user: null,
    token: '',
    isAuthenticated: false,
    userRole: [UserRoles.Member]
}

export type ContextType = {
    currentUser: UserType
    updateCurrentUser: (user: UserType) => void
    isAdmin: () => boolean,
    currentOrganizationID: () => number,
    resetCurrentUser : () => void,
}

export const UserContext = createContext<ContextType>({
    currentUser: initialUserState,
    updateCurrentUser: (user: UserType) => { },
    isAdmin: (): any => {},
    currentOrganizationID : (): any => {},
    resetCurrentUser : ()=> {}
})

interface UserProviderProps {
    children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<UserType>(initialUserState)

    // const [flag, setFlag] = useState<boolean>(false)
    // const reload = () => setFlag(pre => !pre)

    const updateCurrentUser = useCallback((user: UserType) => {
        setCurrentUser({ ...currentUser, ...user })
    },[currentUser])


    const resetCurrentUser = () => {
        setCurrentUser(initialUserState)
    }


    const isAdmin = ()=> {
        if(currentUser.userRole?.length) {
            return currentUser.userRole.includes(UserRoles.Admin)
        }
        else
        return false
    }


    const currentOrganizationID = ():number => currentUser.user?.user_org_id as number

   
    const navigate = useNavigate()


    const validateUser = useCallback(() => {
        validateAuthentication().then((loggedUserData:any) => {
            if(loggedUserData.profile) {
                updateCurrentUser({
                    isAuthenticated: true,
                    userRole:loggedUserData.profile.roles,
                    user: loggedUserData.profile
                })
            }
        }).catch(errorCode => {
            if(errorCode === 403) {
                updateCurrentUser({isAuthenticated: false, userRole:[], user: {} })
                removeToken()
                removeOrganization()
                navigate('/login')
            } 
        })
    },[navigate, updateCurrentUser])
    
    useEffect(() => {
   
        if(getToken()) {
            validateUser()
        }
    },[])


    useEffect(() => {
        if( currentUser.isAuthenticated && !currentUser.currentOrganization && !currentUser.user ) {
           
          validateUser()
        }
    },[currentUser.currentOrganization, currentUser.isAuthenticated, currentUser.user, validateUser])


    const value = { currentUser, updateCurrentUser, isAdmin, currentOrganizationID, resetCurrentUser }

    return <UserContext.Provider value={value}>
        {children}
    </UserContext.Provider>
}
