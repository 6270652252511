import { cssTransition, toast as ToastNotification } from 'react-toastify';


export const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut"
});


export type ToastTypesEnum = 'info'| 'success' | 'warning' | 'error' | 'default'

export const toast = (title: string, type?: ToastTypesEnum, delayClose?:number) => {

  ToastNotification(title, {
    position: "top-right",
    autoClose: delayClose ? delayClose : 3200,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: type || "default",
  });
}