import API from "../index";
import { serachTypes } from "../../Common/Types";
import { getToken } from "../../utils/LocalStorage";

export const getAllWorkers = async (
  organ: number,
  pageParam: string,
  filter?: any
) => {
  if (getToken()) {
    const { data } = await API.post(
      `/admin/organizations/users/filter?limit=${30}${
        pageParam && `&next=${pageParam}`
      }`,
      {
        ...filter,
      }
    );
    return data;
  }
  return [];
};

export const getSingleWorker = async (id: number) => {
  const { data } = await API.get(`/admin/users/${id}/user-details`);
  return data;
};

export const deleteWorker = async (user_id: number) => {
  const { data } = await API.delete(`/admin/users/${user_id}/delete/wipe`);
  return data;
};

export const getParticipantDetails = async (organ: number, userID: string) => {
  const { data } = await API.get(
    `/admin/organizations/users/${userID}/participants`
  );
  return data;
};

export const getPathwaysParticipantDetails = async (
  organ: number,
  userID: string,
  participant_slug: string
) => {
  const response = await API.get(
    `/admin/users/${userID}/organizations/participants/${participant_slug}/participant-details`
  );
  return response.data;
};

export const getPathwaysParticipantContents = async (
  userId: number,
  organ: number,
  participant_slug: string,
  state_slug: string
) => {
  // admin/users/:user_id/organizations/:organization_id/participants/:participant_id/history/:state_id/content
  const response = await API.get(
    `/admin/users/${userId}/organizations/participants/${participant_slug}/history/${state_slug}/content`
  );
  //`/admin/participants/${participant_id}/state/${state_id}/content`);
  return response.data;
};

export const getPathwaysParticipantActions = async (
  userId: number,
  organ: number,
  participant_slug: string,
  state_slug: string
) => {
  // const {data} = await API.get(`/admin/participants/${participant_id}/state/${state_id}/action`);
  const { data } = await API.get(
    `/admin/users/${userId}/organizations/participants/${participant_slug}/history/${state_slug}/action`
  );
  return data;
};
export const getStateParticipant = async (
  pathway_slug: string,
  state_slug: string,
  state_participant_id:number
) => {
  const response = await API.get(
    `/admin/organizations/pathways/${pathway_slug}/states/${state_slug}/state-participants/${state_participant_id}`
  );
  return response.data;
};