import { useNavigate, useParams } from "react-router-dom";
import { EnumActionType } from "../../Types/ActionTemplate/ActionTemplate";
import NewEmail from "../ActionTemplate/ActionTabs/EmailTabAction/NewEmail";
import NewWS1Notification from "./ActionTabs/WS1TabAction/NewNotification";
import NewSMS from "./ActionTabs/SmsTabAction/NewSMS";
import { AppButton } from "../../Theme";
import NewALNotification from "./ActionTabs/ALNotificationTabAction/NewALNotification";

const NewActionTemplatePage = () => {

    const navigate = useNavigate()
    const params = useParams<{ actionType: string }>()
    const navigateBackToActionTemplates = () => navigate(`/action-templates/${params.actionType as string}/filterParam=allData`)

    return (
        <section className='relative'>

            <div className="border border-[borderGray] bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
                <AppButton variant='primary' size='xs' type='button' onClick={navigateBackToActionTemplates}>
                    <div className="flex items-center gap-1">
                        <span className="text-xs capitalize">Back to {params.actionType === 'al_notification' ? 'AL Notifications' : params.actionType}</span>
                    </div>
                </AppButton>
            </div>
            {(() => {
                switch (params.actionType) {
                    case EnumActionType.EmailAction:
                        return <NewEmail />;
                    case EnumActionType.SMSAction:
                        return <NewSMS />;
                    case EnumActionType.Ws1notificationAction:
                        return <NewWS1Notification />;
                    case EnumActionType.ALNotificationAction:
                        return <NewALNotification />;
                    default:
                        return <></>;
                }
            })()}
        </section>
    )
}

export default NewActionTemplatePage;