import React, { InputHTMLAttributes } from "react";

export type AppInputProps = {
  value?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isFull?: boolean;
  maxLength?: number;
  type?: string;
  label?: string;
  error?: string;
  extendClass?: string;
  disabled?: boolean;
  showStar?: boolean;
  customWidth?: string
} & InputHTMLAttributes<HTMLInputElement>;

const AppInput: React.FC<AppInputProps> = (props) => {
  const {
    showStar,
    label,
    name,
    type,
    maxLength,
    value,
    onChange,
    error,
    onBlur,
    isFull,
    disabled,
    extendClass,
    customWidth,
    ...rest
  } = props;
  return (
    <div className={`flex flex-col ${isFull ? "w-full" : customWidth ? customWidth : "w-[40rem]"}`}>
      {label && (
        <div className="flex items-center gap-1">
          <label
            htmlFor={name}
            className="block mb-1 text-base font-normal text-black"
          >
            {label}
          </label>{" "}
          {showStar && <span className="text-red-500">*</span>}

        </div>
      )}
      {/* p-2.5 */}
      <input
        name={name}
        type={type || "text"}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        className={`w-full block rounded-sm border border-borderGray bg-[#F5F5F5]  text-md text-gray-900
        focus:border-blueColor focus:ring-blueColor placeholder:text-[#A4A5A5]
          disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-800
         ${extendClass || ""}`}
        {...rest}
      />
      {typeof maxLength === 'number' && typeof value?.length === 'number' && (
        <p className="block mb-1 ml-auto font-normal text-gray-500"
        >{maxLength - value?.length} characters left</p>
      )}
      {error && <span className="mt-2 text-xs text-red-500">{error}</span>}
    </div>
  );
};

AppInput.defaultProps = {
  isFull: false,
  disabled: false,
};

export default AppInput;
