import React, {useCallback,useContext,useMemo,useState,FC,Fragment,useEffect,useRef, useTransition,} from "react";
import {deleteFlowState} from "../../apiQuery/StateFlow/StateFlow.apis";
import {useInfiniteQuery,useQuery} from "react-query";
import { store } from "../../GlobalState/store";
import {setPathways} from "../../GlobalState/store.actions";
import { withAuth } from "../../hoc/withAuth";
import {AppButton,CloseIcon,EmailIcon,Loader,TabBar,PopupModal} from "../../Theme";
import { toast } from "../../utils/Toast";
import {getAllPathways,getSinglePathway} from "../../apiQuery/Pathways/pathways.apis";
import { IFilterItem, IPathways, IPathwaysResponse } from "../../Types/Pathways/Pathways";
import { useInView } from "react-intersection-observer";
import OverlayModal from "../Layouts/OverlayModal";
import EditPathwayModal from "./Modal/EditPathwayModal";
import NewPathwayModal from "./Modal/NewPathwayModal";
import { PathwayFilterIcon } from "../../Theme/Icons/PathwayFilterIcon";
import PathwaysTable from "./PathwaysTable";
import PathwayOptionalButtons from "./assets/PathwayOptoionalButtons";
import { useTranslation } from "react-i18next";
import { PathwayUpdated } from "../../Types/Pathways/SinglePathwayVar";
import AppDrawer from "./Modal/PathwayDrawer";
import CommandBarFunction from "./Modal/PathwayCB";

//! -------  Filter data | Search Bar  -------Start
const filterItems: IFilterItem[] = [
  {
 id: 1,field: "name",label: "Pathway Name",condition: "include",value: "",type: "text",
 icon: <PathwayFilterIcon icon="pathway" />,
  },

  { id: 2, field: "status", label: "Status", condition: "is", value: "", type: "select", 
 icon:<PathwayFilterIcon icon="status" />
  },
];

const initialFilterItem: IFilterItem = { 
  id: 0, field: "", label: "", condition: "", value: "", type: "text", icon: <EmailIcon />
};

//^ Start Component 
const PathwaysPage: FC = () => {
  const [applyQueries, setApplyQueries] = useState({filter: {},});
  const [selectedPathways, setSelectedPathways] = useState<string[]>([]);
  const [newPathwayModal, setNewPathwayModal] = useState(false);
  const [editPathwayModal, setEditPathwayModal] = useState(false);
  const [showCommandBar, setShowCommandBar] = useState(false);
  const [showPopupDeletePathways, setShowPopupDeletePathways] = useState(false);
  const [selectedPathway, setSelectedPathway] = useState<IPathways>();
  const [showDrawer, setShowDrawer] = useState(false);
  const [allFitlers, setAllFilters] = useState<IFilterItem[]>(filterItems);
  const [activeFilters, setActiveFilters] = useState<IFilterItem[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchFitlerText, setSearchFilterText] = useState("");
  const [applyFilter, setApplyFilter] = useState<{
    item: IFilterItem;
    show: boolean;
  }>({
    item: initialFilterItem,
    show: false,
  });

//! Hooks
  const filterRef = useRef<HTMLDivElement>(null);
  const applyFilterRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const { ref, inView } = useInView();
  const { state: globalState, dispatch } = useContext(store);
const {t}=useTranslation()
//!  organization id to find the main data
  const organization_id = useMemo(
    () => globalState.organization_id,
    [globalState.organization_id]
  );

//! useInfiniteQuery----> Fetch pathway data | StateFlow_R 
  const fetchPathways = ({ pageParam = "" }) =>
    getAllPathways(organization_id, pageParam, { ...applyQueries });

  const { isLoading, data, refetch, fetchNextPage,
  } = useInfiniteQuery<IPathwaysResponse, Error>(
    [applyQueries],
    fetchPathways,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginate.next === "") return undefined;
        return lastPage.paginate.next;
      },
      onSuccess: (data) => {
        const arr = [] as any;
        data?.pages?.forEach((item) => item.data?.map((ds) => arr.push(ds)));
        dispatch(setPathways(arr));
      },
    }
  );
//! Infinite scroll useEffect
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);


//!  open/close Command modal
  useEffect(() => {
 selectedPathways.length > 0 ? setShowCommandBar(true): setShowCommandBar(false)
  }, [selectedPathways.length]);
  const closeCommandBar = () => setShowCommandBar((prev) => false)

//!  Create New Pathway  modal  | open/close modal
  const newPathwayModalHandler = useCallback(
    async () => {
      setNewPathwayModal((prev) => !prev)
      await refetch()
    },
    [refetch]
  );

//! Edit pathway Modal
  const editPathwayModalHandler = useCallback(
    () => setEditPathwayModal((prev) => !prev),
    []
  );

//! Delete pathway 
  const confirmDeletePathways = async () => {
//! Check if there are any selected pathways
    if (selectedPathways?.length <= 0) return;
    try {
      
//! Map selected Pathways to an array of promises that delete them
      const deletePromises = selectedPathways?.map((pathway) =>
        deleteFlowState(pathway)
      );

//! Wait for all delete promises to settle
      const results = await Promise.allSettled(deletePromises);
      
//! failed promises
      const failedPromises = results?.filter(
        (result) => result?.status === "rejected"
      );

      if (failedPromises.length > 0) {
        failedPromises.forEach((promise) =>
//! toast(promise.reason.message, "error")
          toast("Error deleting Pathway", "error")
        );
      } else {
        toast('Pathway are deleted!', 'success')
        setShowCommandBar(!showCommandBar)
        setSelectedPathways([])
      }
      refetch();
    } catch (error) {
      toast('There was an error to delete', 'info')
    }
  }

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 1200);
  }, []);

//!  FILTER  
  useEffect(() => {
//! Save tabs to localStorage whenever it
    if (activeFilters.length > 0)
      localStorage.setItem("pathwayFilters", JSON.stringify(activeFilters));
  }, [activeFilters]);

  useEffect(() => {

//! Load tabs from localStorage on component mount
    const savedFilters = localStorage.getItem("pathwayFilters");

    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters || "") as IFilterItem[];
      let newFilter = {};
      if (parsedFilters) {
        parsedFilters.forEach((i) => {
          newFilter = { ...newFilter, [i.field]: i.value };
        });
      }
      setApplyQueries((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            ...newFilter,
          },
        };
      });
      setActiveFilters(parsedFilters);
    }
  }, []);

//! Search in filters base on label
  const searchFilterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(e.target.value);
    if (e.target.value !== "") {
      const newFilters = allFitlers.filter((item) =>
        item.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setAllFilters(newFilters);
    } else {
      setAllFilters(filterItems);
    }
  };

  const applyInputFilterHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const inputElement = event.target as HTMLInputElement; // type assertion
//! if the input is empty return
      if (inputElement.value === "") {
        return;
      }

//! if the same field and value is already in the active filter return
      const isExist = activeFilters.find((item) => {
        return (
          item.field === applyFilter.item.field ||
          item.value === inputElement.value
        );
      });
      if (isExist) {
        toast("This filter is already applied", "info");
        return;
      }

//! Add new filter to active filter
      const newItem: IFilterItem = {
        ...applyFilter.item,
        value: inputElement.value,
      };
      setActiveFilters((prev) => [...prev, newItem]);

      //! Create a new Filter for apply filter
      const newFitler = {
        [applyFilter.item.field]: inputElement.value,
      };
      setApplyQueries((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            ...newFitler,
          },
        };
      });

//! close the filter
      setApplyFilter({ item: initialFilterItem, show: false });
    }
  };

  const applyFilterStatusHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value === "active" ? "true" : "false";
    const isExist = activeFilters.find((item) => {
      return item.field === applyFilter.item.field || item.value === value;
    });
    if (isExist) {
      toast("This filter is already applied", "info");
      return;
    }

//! add new filter to active filter
    const newItem: IFilterItem = {
      ...applyFilter.item,
      value: value,
      condition: value === "true" ? "is" : "is not",
    };
    setActiveFilters((prev) => [...prev, newItem]);

//! create a new fitler for apply filter
    const newFitler = {
      [applyFilter.item.field]: value,
    };

    setApplyQueries((prev) => {
      return {
        ...prev,
        filter: {
          ...prev.filter,
          ...newFitler,
        },
      };
    });

//! close the filter
    setApplyFilter({ item: initialFilterItem, show: false });
  };

  const toggleFilterHandler = (item: IFilterItem, idx: number) => {
    // set the condition to exclude
    const newActiveFilters = [...activeFilters];
    if (item.type === "text") {
      newActiveFilters[idx].condition =
        newActiveFilters[idx].condition === "include" ? "exclude" : "include";
      setActiveFilters(newActiveFilters);
      if (newActiveFilters[idx].condition === "exclude") {
        // find the field name based  on the index and the name of the field
        let fieldName = Object.keys(applyQueries.filter)[idx];
       
        // create a new fitler for apply filter
        const excludeFieldName = fieldName.startsWith("not_")
          ? fieldName
          : `not_${fieldName}`;
        const newFitler = {
          [excludeFieldName]: newActiveFilters[idx].value,
        };
        const newApplyQueries = { ...applyQueries };
        // remove the previous filename from the applyQueries
        // @ts-ignore
        delete newApplyQueries.filter[fieldName];

        // add the new filter to the applyQueries
        setApplyQueries((prev) => {
          return {
            ...prev,
            filter: {
              ...prev.filter,
              ...newFitler,
            },
          };
        });
      } else {
        // find the field name based  on the index and the name of the field
        let fieldName = Object.keys(applyQueries.filter)[idx];

        const newApplyQueries = { ...applyQueries };

        for (const [key, value] of Object.entries(newApplyQueries.filter)) {
          if (key === fieldName) {
            // @ts-ignore
            delete newApplyQueries.filter[key];
            // @ts-ignore
            newApplyQueries.filter[fieldName.replace("not_", "")] = value;
          }
        }

        setApplyQueries(newApplyQueries);
      }
    }

    //   Select 
    if (item.type === "select" && item.field === "status") {
      newActiveFilters[idx].condition =
        newActiveFilters[idx].condition === "is" ? "is not" : "is";
      newActiveFilters[idx].value =
        newActiveFilters[idx].value === "true" ? "false" : "true";
      setActiveFilters(newActiveFilters);

      // find the field name based  on the index and the name of the field
      const fieldName = Object.keys(applyQueries.filter)[idx];
      const newFitler = {
        [fieldName]: newActiveFilters[idx].value,
      };

    
      const newApplyQueries = { ...applyQueries };
      // remove the previous filename from the applyQueries based on field name status and value true
      // @ts-ignore
      delete newApplyQueries.filter[fieldName];
      // add the new filter to the applyQueries
      setApplyQueries((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            ...newFitler,
          },
        };
      });
    }
  };

//! close the fitler with ESC key
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowFilter(false);
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

//! Close the filter by clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      const filterEl = filterRef.current;
      const buttonEl = buttonRef.current;

      if (
        filterEl &&
        buttonEl &&
        !filterEl.contains(targetEl) &&
        !buttonEl.contains(targetEl)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterRef, buttonRef, setShowFilter]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      if (
        applyFilterRef.current &&
        !applyFilterRef.current.contains(targetEl) &&
        applyFilter.show === true
      ) {
        setApplyFilter((prev) => ({
          ...prev,
          show: false,
        }));
      }
    };

    setTimeout(() =>
      document.addEventListener("click", handleClickOutside, false)
    );
    //! document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [applyFilter.show, applyFilterRef, setApplyFilter]);

  const { data: singlePathwayData } = useQuery(
    ["get-single-pathway", selectedPathway?.slug],
    () => getSinglePathway(selectedPathway?.slug as string),
    {
      enabled: !!selectedPathway?.slug,
    }
  );

  const singlePathwayVar: PathwayUpdated = singlePathwayData?.data;

  //^ UI Section 

  //! Filter section at the Filter button on the top of pathway table
  const filterSection=()=>{
    return(
        <>
          {applyFilter?.show ? (
              <div
                ref={applyFilterRef}
                className="absolute top-0 left-0 z-50 p-2 bg-white border rounded shadow-md border-borderGray w-60 "
              >
                {applyFilter?.item?.type === "text" ? (
                  <input
                    className="block w-full p-2 text-sm border-transparent rounded-lg outline-none text-textDark focus:border-transparent focus:ring-0"
                    type="text"
                    id="fitler_search"
                    name="filter_search"
                    placeholder={`Search ${applyFilter.item?.label}`}
                    autoFocus
                    onKeyDown={applyInputFilterHandler}
                  />
                ) : null}
                {applyFilter?.item?.type === "select" ? (
                  <div className="flex flex-col gap-2">
                    <label className="flex items-center p-1" htmlFor="active">
                      <input
                        type="checkbox"
                        id="active"
                        name="status"
                        value="active"
                        onChange={applyFilterStatusHandler}
                        className="w-4 h-4 mr-2 custom__checkbox"
                      />
                      <span className="text-sm text-textDark">{t('PathwayPage.Active')}</span>
                    </label>

                    <label
                      className="flex items-center p-1 text-sm text-textDark"
                      htmlFor="inactive"
                    >
                      <input
                        type="checkbox"
                        id="inactive"
                        name="status"
                        value="inactive"
                        className="w-4 h-4 mr-2 custom__checkbox"
                        onChange={applyFilterStatusHandler}
                      />
                      <span className="text-sm text-textDark">{t('PathwayPage.Inactive')}</span>
                    </label>
                  </div>
                ) : null}
              </div>
            ) : null}

            {showFilter ? (
              <div
                ref={filterRef}
                className="absolute top-0 left-0 z-50 p-1 bg-white border rounded shadow-md border-borderGray w-60"
              >
                <input
                  type="text"
                  className="block w-full p-2 text-sm border-transparent rounded-lg outline-none text-textDark focus:border-transparent focus:ring-0"
                  placeholder="Filter"
                  value={searchFitlerText}
                  onChange={searchFilterHandler}
                />
                <hr className="h-px border-1 border-borderGray dark:border-textDark" />

                {allFitlers.map((item, i) => (
                  <div
                    key={i}
                    className="flex items-center justify-between p-2 transition-all rounded-md cursor-pointer hover:bg-lightGrayColor"
                    onClick={() => {
                      setShowFilter(false);
                      setApplyFilter({ item: item, show: true });
                    }}
                  >
                    <div className="flex items-center gap-2 hover:cursor-pointer">
                      {item.icon}
                      <span className="text-sm transition-all text-textDark">
                        {item.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
</>
    )
  }

  //! Active filters | All filters that are currently applied and displayed
  const ShowActiveFilter=() => {
    return(
      <>
              {activeFilters?.length > 0 ? (
          <div className="border border-[borderGray] bg-white p-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30">
            <div className="relative flex items-center justify-between">
              {/*//!  Show Filters  */}
              <div className="flex gap-2">
                {activeFilters.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex items-center justify-start gap-2"
                    >
                      <div className="flex items-center justify-center h-full gap-1 px-1 bg-white">
                        <span className="px-2 py-1 text-xs rounded-sm bg-primary text-textDark">
                          {item?.label}
                        </span>
                        <span
                          className="px-1 py-1 text-xs rounded-sm cursor-pointer bg-primary text-grayColor"
                          onClick={() => toggleFilterHandler(item, idx)}
                        >
                          {item?.condition}
                        </span>
                        <span className="px-2 py-1 text-xs rounded-sm bg-primary text-textDark">
                          {/* {item.value} */}
                          {item?.type === "select" ? (
                            <>{item.value === "true" ? t('PathwayPage.Active') : t('PathwayPage.Inactive')}</>
                          ) : null}
                          {item.type === "text" ? <> {item.value} </> : null}
                        </span>
                        <div
                          className="flex items-center justify-center h-full px-1 py-1 rounded-sm bg-primary"
                          onClick={() => {
                            const newActiveFilters = [...activeFilters];
                            const findFilterItem = newActiveFilters.filter(
                              (_, i) => i === idx
                            )[0];

                            setActiveFilters((prev) => {
                              return prev.filter((_, i) => i !== idx);
                            });

                            // remove the filter from the applyQueries
                            const newApplyQueries = { ...applyQueries };
                            if (findFilterItem.condition === "exclude") {
                              // @ts-ignore
                              delete newApplyQueries.filter[
                                `not_${findFilterItem.field}`
                              ];
                            } else {
                              // @ts-ignore
                              delete newApplyQueries.filter[
                                findFilterItem.field
                              ];
                            }
                            setApplyQueries(newApplyQueries);
                          }}
                        >
                          <CloseIcon className="h-3" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="flex items-center justify-center gap-2 w-60">
                <span className="text-sm text-textGray">
                  <span className="text-sm text-textGray">
                    {data?.pages?.[0]?.data?.length || 0} {t('PathwayPage.Results')}
                  </span>
                </span>
                <AppButton
                  variant="primary"
                  type="button"
                  size="xs"
                  onClick={() => {
                    setActiveFilters([]);
                    setApplyQueries({ filter: {} });
                    localStorage.removeItem("pathwayFilters");
                    refetch();
                  }}
                >
                  <div className="flex items-center gap-1 flex-justify-center">
                    <span>{t('PathwayPage.Clear_All_Filter')}</span>
                    <CloseIcon className="w-3 h-3" />
                  </div>
                </AppButton>
              </div>
            </div>
          </div>
        ) : null}
      </>
    )
  }

  //! Popup Modals
  const popUpModals= () => {
    return(
      <>
      {/*//!  POPUP Modal New Pathway  */}
      <OverlayModal onClose={newPathwayModalHandler} isOpen={newPathwayModal}>
        <NewPathwayModal modalHandle={newPathwayModalHandler} />
      </OverlayModal>

{/*//!  POPUP Modal Edit Pathway  */}
      <OverlayModal onClose={() => {
        editPathwayModalHandler()
        setSelectedPathways([])
        closeCommandBar()
        refetch()
      }} isOpen={editPathwayModal}>
        <EditPathwayModal
          modalHandle={() => {
            editPathwayModalHandler()
            setSelectedPathways([])
            closeCommandBar()
            refetch()
          }}
          slug={selectedPathways[0]}
        />
      </OverlayModal>

{/*//!  POPUP Modal Delete Pathway */}
      <PopupModal
        setOpen={setShowPopupDeletePathways}
        isOpen={showPopupDeletePathways}
        title="If you want to continue, confirm by clicking the delete button below."
        onConfirmClick={confirmDeletePathways}
      />
      </>
    )
  } 
  
  //* Main UI 
  return (
    <Fragment>
      <section className="relative">
        <TabBar />
           <div className="border border-[borderGray] bg-white border-l-0 border-t-0 px-2 py-2.5 dark:bg-bckDark sm:px-4">
             <div className="relative flex flex-wrap items-center justify-between">
            
              {/*//! New Pathway and Filter Button in the top of pathway table */}
              <PathwayOptionalButtons buttonRef={buttonRef} showFilter={showFilter} newPathwayModalHandler={newPathwayModalHandler} setShowFilter={setShowFilter}/>

              {/*//! Filter section at the Filter button */}
              {filterSection()}

              </div>
            </div>

          {/*//! All filters that are currently applied and displayed */}
          {ShowActiveFilter()}

        <div className="pb-10">
          <div className="w-[97%] py-5 m-auto space-y-5">
            <div className="flex items-center justify-center">
              
              {/*//! A loader for while data is being loaded from the database and fetching.  */}
              <Loader isLoading={isLoading} size={35} />

            </div>

            {/*//! Pathway table */}
            <PathwaysTable setSelectedPathways={setSelectedPathways} setShowCommandBar={setShowCommandBar} showDrawer={showDrawer} setSelectedPathway={setSelectedPathway} setShowDrawer={setShowDrawer} tableRef={tableRef} data={data?.pages} selectedPathways={selectedPathways} outSideRef={ref}/>

          </div>
        </div>
      </section>

        {/*//! Drawer component */}
        <AppDrawer tableRef={tableRef} setShowDrawer={setShowDrawer}setShowCommandBar={setShowCommandBar} showDrawer={showDrawer} selectedPathway={selectedPathway} singlePathwayVar={singlePathwayVar}/>

        {/*//! Command Bar */}
        <CommandBarFunction showCommandBar={showCommandBar} setShowCommandBar={setShowCommandBar} selectedPathways={selectedPathways} editPathwayModalHandler={editPathwayModalHandler} setShowPopupDeletePathways={setShowPopupDeletePathways}/>

        {/*//! PopUp modals */}
        {popUpModals()}

    </Fragment>
  );
};
PathwaysPage.displayName = "Pathways Page";
export default withAuth(PathwaysPage);
