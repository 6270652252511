import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PageHeader from "../Layouts/PageHeader";
import Tabs from "../Tabs/Tabs";
import Tab from "../Tabs/Tab";
import UseTab from "../../Hook/UseTab";
import WorkerParticipantStateHistory from "./WorkerParticipantStateHistory";
import { store } from "../../GlobalState/store";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Pathway_Participant,
  Pathway_Participant_State,
  Worker_details,
  participant_ContentsActions,
} from "../../Common/Types";
import {
  getPathwaysParticipantActions,
  getPathwaysParticipantContents,
  getPathwaysParticipantDetails,
  getSingleWorker,
  getStateParticipant,
} from "../../apiQuery/Workers/Workers.apis";
import { getPathwayParticipantState } from "../../apiQuery/State/StateParticipant.apis";
import {
  Fade,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table as MTable,
} from "@mui/material";
import { CurrentFlow_R } from "../../GlobalState/types";
import { getStateFlow } from "../../apiQuery/StateFlow/State.apis";
import { withAuth } from "../../hoc/withAuth";
import {
  ActiveTimerIcon,
  AppButton,
  AppInput,
  ContentTemplateIcons,
  CustomClipboard,
  Loader,
  PathwayIcon,
  SearchIcon,
  THead,
  TabBar,
  Table,
} from "../../Theme";
import { Link } from "react-router-dom";
import moment, { duration } from "moment";
import { Drawer } from "../../Theme/Drawer";
import {
  IParticipantState,
  IParticipantStateResonse,
} from "../../Types/Workers/ParticipantState";
import { EnumContentType } from "../../Types/ContentTemplate/ContentType";

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="h-3 w-3 flex-shrink-0 text-grayColor transition duration-100 hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white;"
    viewBox="0 0 5.284 5.284"
  >
    <path
      fill="currentColor"
      d="M280 336v3.473a.568.568 0 00.566.566h3.642l-.849.849.4.4 1.529-1.529-1.529-1.529-.4.4.849.849h-3.642V336z"
      transform="translate(-280 -336)"
    ></path>
  </svg>
);

const WorkerParticipantHistory: FC = () => {
  const { state } = useContext(store);
  const params = useParams();
  // const workerId = parseInt(params.workerId || "")
  const workerId = params.workerId || "";
  const id = params.workerId || "";
  // const pathwayId = params.pathwayId || ""

  const organization_id = useMemo(
    () => state.organization_id,
    [state.organization_id]
  );

  // const pathwayId = parseInt(params.pathwayId || "")
  const pathwaySlug = params.pathwayId || "";
  const participantSlug = params.participantId || "";

  const [tab, setTab] = UseTab("Pathways History");

  const worker = useQuery<Worker_details, Error>(
    ["workers", id],
    () => getSingleWorker(parseInt(id)),
    {
      enabled: id !== "",
    }
  );

  const pathwaysDetails = useQuery<CurrentFlow_R, Error>(
    ["individual-state-flow", pathwaySlug],
    () => getStateFlow(pathwaySlug)
  );



  const [selected, setSelected] = useState<null | IParticipantState>(null);

  const [activeDataTable, setActiveDataTable] = useState<
    "Contents" | "Actions"
  >("Contents");

  // Pathway_Participant_State
  const { isLoading, isError, data, error, isFetching } = useQuery<
    IParticipantStateResonse,
    Error
  >(["Pathway_Participant_State", pathwaySlug, participantSlug], async () =>
    await getPathwayParticipantState(participantSlug, pathwaySlug)
  );

  const participantDetails = useQuery<Pathway_Participant, Error>(
    ["pathwayParticipantDetails", workerId, participantSlug],
    () =>
      getPathwaysParticipantDetails(organization_id, workerId, participantSlug),
    {
      // enabled: tab === "Participant Details"
    }
  );
  const stateParID = data?.data?.find((item) => item.state_slug === selected?.state_slug)?.id;

  const stateParticipant = useQuery(
    ['stateParticipant', pathwaySlug, selected?.state_slug, stateParID],
    async () => await getStateParticipant(pathwaySlug, selected!.state_slug, stateParID!),
    {
      enabled: stateParID !== undefined, // Only enable the query when stateParID is defined
    }
  );

  const contents = useQuery<participant_ContentsActions, Error>(
    ["participant-contents", participantSlug, selected],
    () =>
      getPathwaysParticipantContents(
        parseInt(workerId),
        organization_id,
        participantSlug,
        selected?.state_slug as string
      ),
    {
      enabled: activeDataTable === "Contents" && selected !== null,
    }
  );



  const actions = useQuery<participant_ContentsActions, Error>(
    ["participant-actions", participantSlug, selected],
    () =>
      getPathwaysParticipantActions(
        parseInt(workerId),
        organization_id,
        participantSlug,
        selected?.state_slug as string
      ),
    {
      enabled: activeDataTable === "Actions" && selected !== null,
    }
  );

  //===================================================================================================

  const handeSelected = useCallback((selected: IParticipantState) => {
    setSelected(selected);
  }, []);

  function createData(name: string, value: string) {
    return { name, value };
  }

  // @ts-ignore prettier-ignore
  const rows = useMemo(
    () =>
      Object.keys(participantDetails?.data?.data || {}).map((item) =>
        //   @ts-ignore prettier-ignore
        createData(item, participantDetails?.data?.data[item]?.toString() || "")
      ),
    [participantDetails?.data]
  );

  const workerFullName = useMemo(
    () => worker?.data?.data?.first_name + " " + worker?.data?.data?.last_name,
    [worker?.data]
  );

  useEffect(() => {
    if (data?.data && data?.data[0] && data?.data[0].state_slug) {
      setSelected(data?.data[0]);
    }
  }, [data]);

  // ========================== DRAWER  ===============================
  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = () => setShowDrawer(false);

  // =================================================================
  function pluralize(value: any, unit: any) {
    return value === 1 ? unit : `${unit}s`;
  }
  // ========================== SEARCH  ===============================
  const [searchTerm, setSearchTerm] = useState("");
  // ==================================================================

  // ===================================================================
  if (isLoading) return <p>Loading</p>;

  if (isError)
    return <span>Error: {error?.message || "Something went wrong!"}</span>;

    function convertDurationToTimestamp(durationString: string) {
      if (!durationString)
        return '';

      const matches = durationString?.match(/\d+[hms]/g) ?? null;

      let hours = 0;
      let minutes = 0;
      let seconds = 0;

      if (!matches) {
        // Handle the case where there are no matches (e.g., an empty string)
        return '';
      }
      
      for (const match of matches) {
        const value = parseInt(match);
        if (match.endsWith('h')) {
          hours += value;
        } else if (match.endsWith('m')) {
          minutes += value;
        } else if (match.endsWith('s')) {
          seconds += value;
        }
      }
    
      const now = new Date();
      const daysToAdd = Math.floor(hours / 24);
    
      now.setHours(now.getHours() - hours ?? 0);
      now.setMinutes(now.getMinutes() - minutes ?? 0);
      now.setSeconds(now.getSeconds() - seconds ?? 0);
      now.setMilliseconds(0);
    
      now.setDate(now.getDate() - daysToAdd);

      const isoString = now.toISOString();
      return isoString;
    }

  const pathwayTitle = rows.filter((item) => item.name === "pathway_title")[0]?.value || "";
  const stateEnterDate = moment(stateParticipant?.data?.data?.created_at).format('MMMM DD, YYYY')
  const timeInState = stateParticipant?.data?.data?.time_in_state ?? ''
  const timeInStateDuration = moment.duration(moment().diff(convertDurationToTimestamp(timeInState)));
  const stateEnterDateDuration = moment.duration(moment().diff(stateParticipant?.data?.data?.created_at));
  const totalTime = timeInState ? timeInStateDuration : stateEnterDateDuration;

  return (
    <Fragment>
      <section className="relative">
        <TabBar />

        <div className="flex flex-col items-start justify-between p-5 bg-white border border-borderGray">
          <span className="pb-1 mb-4 text-sm text-grayColor">
            <Link to="/workers">Workers</Link> &nbsp;&nbsp;/&nbsp;&nbsp;{" "}
            <span
              onClick={() => window.history.back()}
              className="cursor-pointer"
            >
              {worker?.data?.data?.first_name} {worker?.data?.data?.last_name}
            </span>{" "}
            / {pathwaysDetails?.data?.data?.name || ""} - {pathwayTitle}
          </span>
          <div className="flex">
            <div className="relative flex flex-col items-center self-center justify-center w-20 h-20 mr-2 bg-pink-500 rounded-md">
              <span className="text-2xl text-white uppercase dark:text-white">
                {`${worker?.data?.data?.first_name} ${worker?.data?.data?.last_name}`
                  .split(" ") // split string into an array of words
                  .map((word) => word.charAt(0)) // get first letter of each word
                  .join("")}
              </span>
            </div>
            <div className="flex flex-col items-start justify-center p-2">
              <p className="mb-2 text-lg whitespace-nowrap text-textDark dark:text-white">
                {worker?.data?.data?.first_name}{" "}
                {worker?.data?.data?.last_name}
              </p>
              <div className="flex items-center gap-2">
                <div
                  onClick={() => {
                    setShowDrawer(!showDrawer);
                  }}
                  className="flex items-center justify-center gap-1 px-2 py-1 transition-all delay-75 rounded-md bg-primary hover:cursor-pointer hover:shadow-sm"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 11.071 7.778"
                  >
                    <path
                      fill="currentColor"
                      d="M1.9 16.728V15.55a1.459 1.459 0 01.225-.8 1.372 1.372 0 01.626-.532 9.93 9.93 0 011.649-.571 6.462 6.462 0 011.509-.175 6.383 6.383 0 011.5.175 10.015 10.015 0 011.641.576 1.41 1.41 0 01.632.532 1.43 1.43 0 01.232.8v1.177zm8.767 0V15.55a2.027 2.027 0 00-.4-1.3 2.934 2.934 0 00-1.052-.82 12.42 12.42 0 011.628.294 5.739 5.739 0 011.24.445 1.938 1.938 0 01.651.589 1.373 1.373 0 01.238.789v1.177zm-4.759-4.02a1.8 1.8 0 01-1.879-1.879A1.8 1.8 0 015.908 8.95a1.8 1.8 0 011.879 1.879 1.8 1.8 0 01-1.879 1.879zm4.509-1.879a1.8 1.8 0 01-1.879 1.879 2.8 2.8 0 01-.307-.019 1.325 1.325 0 01-.307-.069 2.055 2.055 0 00.457-.77 3.137 3.137 0 00.157-1.021 2.915 2.915 0 00-.157-1 2.663 2.663 0 00-.457-.8 2.643 2.643 0 01.307-.063 2.111 2.111 0 01.307-.025 1.8 1.8 0 011.879 1.879z"
                      transform="translate(-1.9 -8.95)"
                    />
                  </svg>
                  <span className="text-sm text-textDark">
                    View Participants Details
                  </span>

                </div>
                <CustomClipboard showInfoIcon clipboardText={participantSlug} />
              </div>
            </div>
          </div>
        </div>

        <div className="border border-[borderGray] border-t-0 bg-white py-2.5 px-5 shadow-sm flex items-center gap-2">
          {data?.data?.map((item) => {
            return (
              <AppButton
                key={item.state_slug}
                variant={
                  selected?.state_slug === item.state_slug ? "info" : "primary"
                }
                onClick={() => handeSelected(item)}
                type="button"
              >
                <div className="flex items-center gap-1">
                  <svg
                    aria-hidden="true"
                    className={
                      "h-3 w-3 " +
                      (selected?.state_slug === item.state_slug
                        ? " text-white"
                        : "text-grayColor")
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 7.573 8.414"
                  >
                    <path
                      id="icon_pathways"
                      d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
                      transform="translate(-6 -4)"
                      fill="currentColor"
                    />
                  </svg>
                  {item.name}
                </div>
              </AppButton>
            );
          })}
        </div>

        <div className="bg-white p-5 flex flex-col justify-between  shadow-sm borde border-borderGray w-[97%] mx-auto my-3 rounded ">
          <div className="flex items-center gap-2">
            <ActiveTimerIcon />
            {/* <h1 className='text-lg text-textDark'>{pathwaysDetails?.data?.data?.entry_state_name || ""}</h1> */}
            <h1 className="text-lg capitalize text-textDark">
              {selected?.name}
            </h1>
          </div>
          <p className="ml-5 text-sm text-textDark">
            State entered on{" "}
            {stateEnterDate || "no date found"}.
            {totalTime.asMinutes() > 1 ? ' Total time in state is ' : ''}
            {totalTime.years() > 0 ? `${totalTime.years()} ${pluralize(totalTime.years(), 'year')} ` : ''}
            {totalTime.months() > 0 ? `${Math.floor(totalTime.asDays() / 30)} ${pluralize(totalTime.months(), 'month')} ` : ''}
            {totalTime.days() > 0 ? `${Math.floor(totalTime.asDays() % 30)} ${pluralize(totalTime.days(), 'day')} ` : ''}
            {totalTime.hours() > 0 ? `${totalTime.hours()} ${pluralize(totalTime.hours(), 'hour')} ` : ''}
            {totalTime.minutes() > 0 ? `${totalTime.minutes()} ${pluralize(totalTime.minutes(), 'minute')} ` : ''}
          </p>

          <div className="flex items-center gap-3 px-4 pt-4">
            <AppButton
              variant={activeDataTable === "Contents" ? "info" : "primary"}
              onClick={() => setActiveDataTable("Contents")}
              type="button"
            >
              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  viewBox="0 0 9.879 9.638"
                  className={
                    "h-3 w-3 " +
                    (activeDataTable === "Contents"
                      ? " text-white"
                      : "text-grayColor")
                  }
                >
                  <path
                    id="icon_content"
                    d="M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z"
                    transform="translate(-4 -4)"
                    fill="currentColor"
                  />
                </svg>
                Delivered Contents
              </div>
            </AppButton>

            <AppButton
              variant={activeDataTable === "Actions" ? "info" : "primary"}
              onClick={() => setActiveDataTable("Actions")}
              type="button"
            >
              <div className="flex items-center gap-1">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 9.878 9.878"
                  className={
                    "h-3 w-3 " +
                    (activeDataTable === "Actions"
                      ? " text-white"
                      : "text-grayColor")
                  }
                >
                  <path
                    id="icon_actions"
                    d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
                    transform="translate(-2 -2)"
                    fill="currentColor"
                  />
                </svg>
                Delivered Actions
              </div>
            </AppButton>
          </div>
        </div>

        {/* =============================================================================== */}

        <div className="pb-10">
          <div className="w-[97%] py-2 m-auto space-y-2">
            <div className="flex items-center justify-center">
              {/* {isFetching && <Loader isLoading size={35} />} */}
              <Loader isLoading={isLoading} size={35} />
            </div>
            <div ref={tableRef} className="relative overflow-x-auto overflow-y-hidden shadow-md sm:rounded-lg">
              {activeDataTable === "Contents" ? (
                <table className="w-full overflow-y-hidden text-sm text-left text-textDark">
                  <thead className="text-lg font-normal capitalize text-textDark bg-primary dark:bg-primary">
                    <tr>
                      <th scope="col" className="px-2 py-3 font-medium">
                        Type
                      </th>
                      <th scope="col" className="px-2 py-3 font-medium">
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 font-medium text-left"
                      >
                        Last Viewed
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 font-medium text-left"
                      >
                        Display Condition
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 font-medium text-left"
                      >
                        Interacted
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 font-medium text-left"
                      >
                        Time Delivered
                      </th>
                    </tr>
                  </thead>
                  <tbody className="overflow-y-hidden">
                    {contents?.data?.data &&
                      contents?.data?.data.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="transition-all bg-white border-b hover:bg-primary"
                          >
                            <td className="py-3 pl-2 font-medium text-textDark whitespace-nowrap dark:text-white ">
                              <div className="flex items-center gap-1">
                                <div className="w-5 h-5 text-grayColor">
                                  {
                                    ContentTemplateIcons[
                                    item.Type as EnumContentType
                                    ]
                                  }
                                </div>
                                <span className="capitalize text-md text-textDark">
                                  {item.Type}
                                </span>
                              </div>
                            </td>
                            <td
                              width={400}
                              className="py-3 pl-2 font-medium text-textDark dark:text-white"
                            >
                              <span className="text-md text-textDark">
                                {item.Title}
                              </span>
                            </td>

                            <td className="py-3 pl-2 font-medium text-textDark whitespace-nowrap dark:text-white">
                              <span className="text-md text-textDark">
                                {item.LastViewed
                                  ? moment(item.LastViewed)
                                    .local()
                                    .format("LLL")
                                  : "Not yet viewed"}
                              </span>
                            </td>
                            <td className="py-3 pl-2 font-medium text-textDark whitespace-nowrap dark:text-white">
                              <span className="text-md text-textDark">
                                {item.DisplayCondition}
                              </span>
                            </td>
                            <td className="py-3 pl-2 font-medium text-textDark whitespace-nowrap dark:text-white">
                              <span className="text-md text-textDark">
                                {item.Interacted ? "True" : "False"}
                              </span>
                            </td>
                            <td className="py-3 pl-2 font-medium text-textDark dark:text-white">
                              <span className="text-md text-textDark">
                                {moment(item.TimeDelivered)
                                  .local()
                                  .format("LLL")}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <table className="w-full overflow-y-hidden text-sm text-left text-textDark">
                  <thead className="text-lg font-normal capitalize text-textDark bg-primary dark:bg-primary">
                    <tr>
                      <th scope="col" className="px-2 py-3 font-medium">
                        Type
                      </th>
                      <th scope="col" className="px-2 py-3 font-medium">
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 font-medium text-left"
                      >
                        Last Viewed
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 font-medium text-left"
                      >
                        Display Condition
                      </th>
                      {/* <th
                        scope="col"
                        className="px-2 py-3 font-medium text-left"
                      >
                        Time Delivered
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="overflow-y-hidden">
                    {actions?.data?.data &&
                      actions?.data?.data.map((item, index) => {
                        return (
                          <tr key={index} className="bg-white border-b">
                            <td className="py-3 pl-2 font-medium text-textDark whitespace-nowrap dark:text-white">
                              <span className="text-md text-textDark">
                                {item.Type === 'al_notification' ? 'AL Notification' : item.Type}
                              </span>
                            </td>
                            <td
                              width={400}
                              className="py-3 pl-2 font-medium text-textDark dark:text-white"
                            >
                              <span className="text-md text-textDark">
                                {item.Title}
                              </span>
                            </td>

                            <td className="py-3 pl-2 font-medium text-textDark whitespace-nowrap dark:text-white">
                              <span className="text-md text-textDark">
                                {item.LastViewed
                                  ? moment(item.LastViewed)
                                    .local()
                                    .format("LLL")
                                  : "Unviewed"}
                              </span>
                            </td>
                            <td className="py-3 pl-2 font-medium text-textDark whitespace-nowrap dark:text-white">
                              <span className="text-md text-textDark">
                                {item.DisplayCondition}
                              </span>
                            </td>
                            {/* <td className="py-3 pl-2 font-medium text-textDark dark:text-white">
                              <span className="text-md text-textDark">
                                {moment(item.TimeDelivered)
                                  .local()
                                  .format("LLL")}
                              </span>
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        {/* =============================================================================== */}
        {/* <div className="hidden w-11/12 mx-auto my-10 bg-white rounded" >

                    {
                        data ?
                            <Fade in={tab === "Pathways History"}
                                style={{ display: tab === "Pathways History" ? '' : 'none' }}>
                                <div className="w-full h-full border border-[#D8D8D8] flex">

                                    <div className="w-[25rem] rounded h-full border-r border-borderGray">
                                        {
                                            data?.data?.map((item: any, index: number) => <div key={index}
                                                className={`${(selected === item.state_slug) && 'bg-blue-500 text-white'} cursor-pointer`}
                                                onClick={() => {
                                                    handeSelected(item.state_slug)
                                                }}>
                                                <div className="p-3">
                                                    <p className="text-xl">State {index + 1}</p>
                                                    <p className="text-sm font-light">{item.name}</p>
                                                </div>
                                            </div>)
                                        }
                                    </div>

                                    {
                                        selected &&
                                        <WorkerParticipantStateHistory state_slug={selected}
                                            participant_id={participantSlug} />
                                    }


                                </div>
                            </Fade >
                            : null
                    }


                    {
                        participantDetails.isLoading ? (
                            <p>Loading!</p>
                        ) :
                            participantDetails.isError ? (
                                <span>Error: {participantDetails.error?.message || "Something went wrong!"}</span>
                            ) :
                                participantDetails.data ? <Fade in={tab === "Participant Details"}
                                    style={{ display: tab === "Participant Details" ? '' : 'none' }}>

                                    <TableContainer component={Paper}>
                                        <MTable sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead className="bg-neutral-100">
                                                <TableRow>
                                                    <TableCell width={500}>Field</TableCell>
                                                    <TableCell align="left">Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row, index) => (
                                                    <TableRow key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        {
                                                            typeof (row.value) !== 'object' &&
                                                            <TableCell>{row.value}</TableCell>
                                                        }
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </MTable>
                                    </TableContainer>
                                </Fade> : null
                    }

                </div> */}
      </section>

      {/*-------------------------------------------------------------*/}
      {/*Page header for the Worker's Participant (pathway) history*/}
      {/* <PageHeader
                title={workerFullName + ": " + pathwaysDetails?.data?.data?.name || ""}
                tabs={
                    <Tabs selectedTab={tab} setSelectedTab={setTab}>
                        <Tab title="Pathways History">Pathways History</Tab>
                        <Tab title="Participant Details">Participant Details</Tab>
                    </Tabs>
                }
            /> */}

      <Drawer outsideRef={tableRef} setOpen={closeDrawer} isOpen={showDrawer} title={``} isFull>
        <div className="flex flex-col justify-between h-full overflow-hidden">
          <div className="h-full p-5 overflow-hidden">
            <div className="relative flex flex-col h-full ">
              <div
                style={{ marginLeft: "-.5rem", marginBottom: ".5rem" }}
                className="sticky top-0 flex flex-col items-start justify-start w-full p-2 transition ease-in-out bg-white "
              >
                <h5 className="mb-2 text-lg text-textDark">
                  Participant Details for{" "}
                  {pathwaysDetails?.data?.data?.name || ""}{" "}
                </h5>
                <div className="flex items-center justify-start w-full rounded-lg bg-primary">
                  <SearchIcon className="ml-2" />
                  <AppInput
                    id="search"
                    value={searchTerm}
                    name="search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search Participants Details"
                    isFull
                  />
                </div>
              </div>
              <ul className="h-full overflow-y-scroll">
                {rows
                  .filter((item) =>
                    item.name.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((row, index) => (
                    <li className="flex flex-col py-1 overflow-x-hidden w-[98%]" key={index}>
                      <span className="text-base text-textDark">
                        {row.name}
                      </span>
                      <div className="flex items-center justify-start gap-1 mb-2 ml-3 text-sm text-grayColor ">
                        <ArrowIcon />
                        <p className="text-sm break-words text-grayColor w-[95%]">
                          {typeof row.value !== "object" && row.value}
                        </p>
                      </div>
                      <hr className="h-px border-1 border-borderGray dark:border-textDark" />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default withAuth(WorkerParticipantHistory);