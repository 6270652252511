import React, {FC, memo} from 'react';
import {FaArrowRight} from "react-icons/fa";

type state_props = {
    title: string,
    type: string
    body?: string,
    description?: string,
    image?: string,
    video?: string,
    tags?: string[]
    children?: JSX.Element
}

const ContentTypeCardPreview: FC<state_props> = memo(({title, body, type, description, image, video, tags, children}) => {

    const addDefaultSrc = (ev: any) => ev.target.src = "https://www.fedex.com/content/dam/fedex/us-united-states/about-us/images/2020/Q4/EU_EN_2020_1_web_jpg_NA_connect_possibilities_home_marketing_673415817_smaller.jpg"

    return (
        <div className="w-full h-full text-white pt-2">
            <div>
                <div className="text-general-text relative shadow-xl h-64 ">
                    <img src={image} onError={addDefaultSrc} className="h-3/4 w-full rounded-t" alt=""/>
                    <div className="text-general-text p-3 mt-12 bg-white h-fit m-4 absolute relative bottom-28 shadow-xl-t rounded">
                        {
                            tags?.length ?
                                <span className="text-white text-xs px-1 py-0.5 rounded-md bg-organization-secondary uppercase">
                                    {tags[0]}
                                </span> : null
                        }

                        <p className="text-lg mt-1 leading-5 line-clamp-3">
                            {title}
                        </p>
                        <div className="text-xs mt-2 flex items-center">

                            {(() => {
                                switch (type) {
                                    case 'article':
                                        return 'Read'
                                    case 'video':
                                        return 'Watch'
                                    case 'checklist':
                                        return 'Read'
                                    case 'faq':
                                        return 'Read'
                                    case 'direction':
                                        return 'See'
                                    case 'announcement':
                                        return 'Read'
                                    case 'selector':
                                        return 'Choose'
                                    case 'survey':
                                        return 'Take'
                                    default:
                                        return null
                                }
                            })()}
                            <p className="inline capitalize pl-1">{type}</p>
                            <FaArrowRight className="ml-3 capitalize"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ContentTypeCardPreview;