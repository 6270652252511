export const updateById =  
(
    arr: [any],
    id: number,
    newQuestion: string,
    newAnswer: string
  ) => {
    // Find the index of the object with the given id
    const indexToUpdate = arr.findIndex((item: any) => item.id === id);
  
    // If the id is not found, return the original array
    if (indexToUpdate === -1) {
      return arr;
    }
  
    // Create a new object with the updated question
    const updatedItem = {
      ...arr[indexToUpdate],
      question: newQuestion,
      answer: newAnswer
    };
  
    // Create a new array with the updated object
    const updatedArray = [
      ...arr.slice(0, indexToUpdate),
      updatedItem,
      ...arr.slice(indexToUpdate + 1),
    ];
  
    return updatedArray;
  }