import { ReactNode, Fragment } from "react";
import { Transition } from "@headlessui/react";

export const TransitionAnimation = (props: {
  children: ReactNode;
  show: boolean;
  position: "y-axis" | "x-axis";
}) => {
  let position_enter_leave = "";
  let position_enter_leave_from = "";
  if (props.position === "y-axis") {
    position_enter_leave = "translate-y-1";
    position_enter_leave_from = "translate-y-0";
  }
  if (props.position === "x-axis") {
    position_enter_leave = "translate-x-1";
    position_enter_leave_from = "translate-x-0";
  }
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-300"
      enterFrom={`opacity-0 ${position_enter_leave}`}
      enterTo={`opacity-100 ${position_enter_leave_from}`}
      leave="transition ease-in duration-300"
      leaveFrom={`opacity-100 ${position_enter_leave_from}`}
      leaveTo={`opacity-0 ${position_enter_leave}`}
      show={props.show}
    >
      {props.children}
    </Transition>
  );
};
