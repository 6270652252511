/* eslint-disable no-unused-vars */
import { FC, Fragment, useContext, useState } from "react";
import { AppButton } from "../../Shared";
import { ContentIcon, NotificationsIcon, PathwayIcon } from "../../Icons";
import { UserContext } from "../../../Context/UserContext/UserContext";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { getAllUserNotification, getAllUserUnviewedNotification, setUserNotificationsAsViewed } from "../../../apiQuery/Notification";
import { INotification } from "../../../Types";
import { AppLoader } from "../../../components/Shared/AppLoader";
import moment from "moment";
import { Tooltip } from "flowbite-react";


type NotificationRequest = {
  data: INotification[]
}


const renderIcon = (action: string) => { 

  if(action.startsWith('Pathway')) { 
    return   <svg
    aria-hidden="true"
    className={`text-grayColor w-6 h-6`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 7.573 8.414"
  >
    <path
      id="icon_pathways"
      d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
      transform="translate(-6 -4)"
      fill="currentColor"
    />
  </svg>
  }

  if(action.startsWith('Content')) {
    return  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 9.879 9.638'
    aria-hidden='true'
   className={`text-grayColor w-6 h-6`}
  >
    <path
      id='icon_content'
      d='M11.469,12.915V4.723A.76.76,0,0,1,12,4.9a.705.705,0,0,1,.193.548v6.746A.705.705,0,0,1,12,12.74.76.76,0,0,1,11.469,12.915Zm-6.746.723A.72.72,0,0,1,4,12.915V4.723A.72.72,0,0,1,4.723,4H9.542a.72.72,0,0,1,.723.723v8.192a.72.72,0,0,1-.723.723ZM13.4,12.18V5.47a.547.547,0,0,1,.361.1.482.482,0,0,1,.12.379V11.7a.482.482,0,0,1-.12.379A.547.547,0,0,1,13.4,12.18Z'
      transform='translate(-4 -4)'
      fill='currentColor'
    />
  </svg>
  }
  if(action.startsWith('State')) {
    return <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 96 960 960"
    aria-hidden="true"
    className={`text-grayColor w-6 h-6`}
  >
    <path
      fill="currentColor"
      d="M451.154 948.691l-282.306-163q-13.866-8.04-21.356-21.443-7.491-13.403-7.491-28.633v-319.23q0-15.23 7.491-28.633 7.49-13.403 21.356-21.443l282.306-163q13.943-7.615 29.01-7.615 15.067 0 28.682 7.615l282.306 163q13.866 8.04 21.356 21.443 7.491 13.403 7.491 28.633v319.23q0 15.23-7.491 28.633-7.49 13.403-21.356 21.443l-282.306 163q-13.943 7.615-29.01 7.615-15.067 0-28.682-7.615zm6.154-359.922v311.308l17.307 9.692q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l17.307-9.692v-311.47L774.615 431v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480 550.462 211.077 394.308l-19.922 11.349q-3.077 1.923-4.424 3.846-1.346 1.923-1.346 3.847v18.265l271.923 157.154z"
    ></path>
  </svg>
  }
  if(action.startsWith('Action')) { 
    return <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.878 9.878"
    className={`text-grayColor w-6 h-6`}
  >
    <path
      id="icon_actions"
      d="M6.939,8.118,5.761,6.939,6.939,5.761,8.118,6.939Zm-.954-3.3-.932-.932L6.939,2,8.825,3.886l-.932.932-.954-.954Zm-2.1,4.007L2,6.939,3.886,5.053l.932.932-.954.954.954.954Zm6.107,0-.932-.932.954-.954-.954-.954.932-.932,1.886,1.886ZM6.939,11.878,5.053,9.993l.932-.932.954.954.954-.954.932.932Z"
      transform="translate(-2 -2)"
      fill="currentColor"
    />
  </svg>
  }

}




export const Header: FC = () => {
  const { t } = useTranslation();

  const { currentUser } = useContext(UserContext);

  const [activeTab, setActiveTab] = useState<'All' | 'Unread'>('All'); 



  // Queries

  const {
    data: getAllNotifications,
    isLoading: allLoading
  } = useQuery<NotificationRequest, Error>('getAllNotifications', () => getAllUserNotification(5), {
    enabled: activeTab === 'All',
  })

  const {
    data: getUnreadNotification,
    isLoading: unreadLoading,
    refetch: refetchUnread,
  } = useQuery<NotificationRequest, Error>('getUnreadNotification', () => getAllUserUnviewedNotification(5),{
    enabled: activeTab === 'Unread'
  })


  const setNotificationAsReadMutation = useMutation((ids:number[]) => setUserNotificationsAsViewed(ids), {
    onSuccess: () => {
      refetchUnread();
    }
  })

  

    // handlers 

    const clearHandler = () => {
      // setSolutions([]); // Clear the solutions array
      if(!unreadLoading) {
        const listOfIds = getUnreadNotification?.data.map((item: INotification) => item.ID);
        if(listOfIds) {
          setNotificationAsReadMutation.mutate(listOfIds);
        }
      }
    };


    const markAsReadHandler = (id:number) => { 
      setNotificationAsReadMutation.mutate([id])
    }

    if (!currentUser.isAuthenticated) {
      return null;
    }
  return (
    <nav className="border border-[borderGray] bg-white border-b-0 border-l-0 px-2 py-2.5 dark:bg-bckDark sm:px-4">
      <div className="relative flex flex-wrap items-center justify-between">
        <div className="flex items-center">
        </div>
        <div className="z-40 hidden w-full md:block md:w-auto" id="navbar-default ">

          <div className="flex items-center ">

            <Popover className="relative flex items-center">
              {({ open }) => (
                <>

                  <Popover.Button
                    className={`
                ${open ? '' : 'text-opacity-90'}
                custom__btn group inline-flex items-center rounded-md bg-blueColor px-4 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >

                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      className={`mx-1 text-white h-3 w-3`}
                      viewBox='0 0 5.96 7.264'
                    >
                      <path
                        fill='currentColor'
                        d='M6.98 9.764a.747.747 0 00.745-.745h-1.49a.745.745 0 00.745.745zm2.235-2.235V5.666a2.27 2.27 0 00-1.676-2.354v-.253a.559.559 0 10-1.118 0v.253a2.263 2.263 0 00-1.676 2.354v1.863L4 8.274v.373h5.96v-.373z'
                        transform='translate(-4 -2.5)'
                      />
                    </svg>
                    <span>{t('Notifications')}</span>
                    {
                      open ?  <ChevronUpIcon className="w-5 h-5 ml-2 text-white transition duration-150 ease-in-out group-hover:text-opacity-80" />
                      :  <ChevronDownIcon className="w-5 h-5 ml-2 text-white transition duration-150 ease-in-out group-hover:text-opacity-80" />
                    }
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 max-w-sm px-4 mt-3 transform -translate-x-1/2 w-[25rem] top-10 -right-52 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="flex items-center gap-2 p-2 bg-gray-50">
                        <AppButton variant={activeTab === 'All' ? 'info' :'primary'} type="button" onClick={() => {
                             setActiveTab('All')
                            }}>
                              {t('All')}
                            </AppButton>
                            <AppButton variant={activeTab === 'Unread' ? 'info' :'primary'} type="button" onClick={() => {
                              setActiveTab('Unread')
                            }}>
                             {t('Unread')}
                            </AppButton>
                        </div>
                        <div className="relative grid gap-8 bg-white p-7">
                          {activeTab === 'Unread' ? <>
                          {unreadLoading ? <div className="flex items-center justify-center p-2 -m-3">
                            <AppLoader isLoading={unreadLoading} size={25} />
                          </div> : null}
                          {
                            getUnreadNotification?.data.map((item: INotification) => {
                              return (
                                <div
                                key={item.ID}
                                className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg group hover:cursor-pointer hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                <div className="flex items-center justify-center w-12 h-12 text-white shrink-0 sm:h-12 sm:w-12">
                                  {renderIcon(item.action)}
                                </div>
                                <div className="w-full ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    <strong>{item.sender_nickname}:</strong> {item.title}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {item.notification_msg}
                                  </p>
                                  <div className="flex items-center justify-between">
                                  <p className="text-xs text-blueColor">
                                    {moment(item.CreatedAt).fromNow()}
                                  </p>
                                  <div className="transition-all delay-75 group-hover:block" onClick={() => markAsReadHandler(item.ID)}>
                                  <Tooltip
                                    placement="top"
                                    style="light"
                                    content={<span className="text-xs text-darkColor">Mark as viewed</span>}
                                    >  
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 -960 960 960"
                                    className={`text-grayColor w-5 h-5 hover:text-blueColor`}
                                  >
                                    <path fill="currentColor" d="M699.077-174.232L577.385-296.308 609-327.538l90.077 89.077 175.077-175.077 31.614 32.615-206.691 206.691zm-599.076 55.768v-683.842q0-23.616 17.039-40.654 17.038-17.039 40.654-17.039h644.612q23.616 0 40.654 17.039 17.039 17.038 17.039 40.654v299.614H497.308v242.691H241.539L100.001-118.464z" />
                                  </svg>
                                    </Tooltip>
                                    
                                  </div>
                                  </div>
                                </div>
                              </div>
                              )
                            })
                          }
                          </>  :null}



                          {activeTab === "All" ? 
                          
                         <>
                         {allLoading ? <div className="flex items-center justify-center p-2 -m-3">
                            <AppLoader isLoading={allLoading} size={25} />
                          </div> : null}

                          {
                            getAllNotifications?.data?.map((item: INotification) => {
                              return (
                                <div
                                key={item.ID}
                                className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg group hover:cursor-pointer hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                <div className="flex items-center justify-center w-12 h-12 text-white shrink-0 sm:h-12 sm:w-12">
                                  {renderIcon(item.action)}
                                </div>
                                <div className="w-full ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    <strong>{item.sender_nickname}:</strong> {item.title}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {item.notification_msg}
                                  </p>
                                  <p className="text-xs text-blueColor">
                                    {moment(item.CreatedAt).fromNow()}
                                  </p>
                                </div>
                              </div>
                              )
                            })
                          }
                         
                         
                         </> : null}


                          {/* {
                            activeTab === 'Unread' &&  getUnreadNotification?.data  (
                              <div className="flex items-center justify-center">
                                <p className="text-sm font-medium text-gray-900">
                                  {t('NoNotifications')}
                                </p>
                              </div>
                            )
                          } */}

                        </div>

                        {
                          activeTab === "Unread" && (<div className="flex items-center justify-end p-2 bg-gray-50">
                            <AppButton disabled={setNotificationAsReadMutation.isLoading} variant="primary" type="button" onClick={clearHandler}>
                              {t('MarkAllAsRead')}
                            </AppButton>
                          </div>)
                        }

                      </div>


                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

          </div>
          {/* <SwitchTheme /> */}

        </div>
      </div>

    </nav>
  );
};


function Icon() {
  return (
    <NotificationsIcon className="mr-1 " />
  )
}