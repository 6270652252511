import React, { Dispatch, SetStateAction, useState } from 'react'
import { AppButton, AppIconButton } from '../../../../Theme'
import AppInput from '../../../Shared/AppInput/AppInput'
import {
	IVariant,
	SelectorContentTemplate,
} from '../../../../Types/ContentTemplate/Selector'
import NewUseUploadFile from "../../../../Hook/NewUseUploadFile"

interface InlineSelectorVariantProps {
	item: IVariant
	setSelectorContent: Dispatch<SetStateAction<SelectorContentTemplate>>
	setShowInput: any
	idx: number
	setVariants: any
	setVariant: any
	showInputId: string | null
	showInput: { id: string | null; isInput: boolean }
}

const InlineSelectorVariant: React.FC<InlineSelectorVariantProps> = ({
	item,
	idx,
	setSelectorContent,
	setVariants,
	setVariant,
	setShowInput,
	showInputId,
	showInput,
}) => {
	const [inputChange, setInputChange] = useState(item)
	const [validation, setValidation] = useState('')

	const handleEdit = () => {
		setShowInput((prev: any) => {
			return { id: item.id, isInput: true }
		})
	}

	const handleDelete = () => {
		setVariants((prev: any[]) => {
			return prev.filter((_, index) => index !== idx)
		})
	}

	const handleSave = () => {
		if (inputChange.content.length < 1) {
			setValidation("You can't save it as empty")
		} else {
			setShowInput((prev: { id: null | number; isInput: boolean }) => {
				return { id: null, isInput: false }
			})

			// Update selector items directly within the component
			setVariants((prev: any) =>
				prev.map((i: any) => (i.id === item.id ? inputChange : i))
			)
		}
	}

	const handleCancel = () => {
		setShowInput((prev: { id: null | number; isInput: boolean }) => {
			return { id: null, isInput: false }
		})
		setInputChange(item)
	}

	return (
		<div className="flex items-center w-[90%] gap-2 p-2 m-1 bg-primary rounded shadow-sm">
			{/* ... other code ... */}
			<div className="flex items-center justify-between w-full gap-2">
				{showInput.isInput && showInputId === item.id ? (
					<div className="flex flex-col w-full">
						<AppInput
							autoFocus
							name=""
							isFull
							onChange={(e) => {
								if (e?.target?.value.length > 0) {
									setValidation('')
								}
								setInputChange((prev) => ({
									...prev,
									content: e?.target?.value,
								}))
							}}
							type="text"
							extendClass=""
							value={inputChange.content}
						/>
					</div>
				) : (
					<p className="text-sm text-black">{item.content}</p>
				)}
				<div className="flex items-center justify-center gap-2">
					{showInput.isInput && showInputId === item.id ? (
						<>
							<AppIconButton
								variant="danger"
								type="button"
								onClick={handleDelete}
							/>
							<AppIconButton
								variant="success"
								type="button"
								onClick={handleSave}
							/>
							<AppIconButton
								variant="default"
								type="button"
								onClick={handleCancel}
							/>
						</>
					) : (
						<>
							<AppIconButton
								variant="danger"
								type="button"
								onClick={handleDelete}
							/>
							<AppIconButton
								variant="info"
								type="button"
								onClick={handleEdit}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default InlineSelectorVariant
