// @ts-nocheck
import Select from "@atlaskit/select";
import { useMemo } from "react";
import { FieldSelectorProps } from "react-querybuilder";


export const FieldSelector = ({
  options,
  value,
  handleOnChange,
}: FieldSelectorProps) => {
  const groupedOptions = useMemo(() => {
    const groups = new Set();
    options.forEach((opt) => groups.add(opt.group));

    return Array.from(groups).map((group) => ({
      label: group,
      options: options
        .filter((opt) => opt.group === group)
        .map((opt) => ({
          label: opt.label,
          value: opt.name,
        })),
    }));
  }, [options]);

  const selectedLabel = options.find((opt) => opt.name === value)?.label;
  const val = { label: selectedLabel, value };

  return (
    <Select
      value={val}
      onChange={(e) => handleOnChange(e?.value)}
      className="single-select"
      classNamePrefix="react-select"
      options={groupedOptions}
      placeholder="Select Field"
    />
  );
};