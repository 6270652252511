export enum EnumContentType {
	ArticleContent = "article",
	AnnouncementContent = "announcement",
	ChecklistContent = "checklist",
	ConsentContent = "consent",
	DirectionContent = "direction",
	VideoContent = "video",
	FAQContent = "faq",
	// PictureContent = "picture",
	SelectorContent = "selector",
	SurveyContent = "survey",
	SurveySequenceContent = "survey_sequence",
}
