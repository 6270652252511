import {
  article,
  contents,
  video,
  checklist,
  selector,
  announcement,
  faq,
  survey,
  direction,
  survey_items,
  faqQuestions,
  consent,
  survey_sequence,
  surveys,
} from "../../Common/Types";
import API from "../index";

export const updateVideo = async (organ: number, datas: video, id: number) => {
  const { data } = await API.put(`/admin/organizations/videos/${id}`, {
    ...datas,
  });
  return data;
};

export const updateArticle = async (
  organ: number,
  datas: article,
  id: number
) => {
  const { data } = await API.put(`/admin/organizations/articles/${id}`, {
    ...datas,
  });
  return data;
};

export const updateConsent = async (
  organ: number,
  datas: consent,
  id: number
) => {
  const { data } = await API.put(`/admin/organizations/consents/${id}`, {
    ...datas,
  });
  return data;
};

export const updateDirection = async (
  organ: number,
  datas: direction,
  id: number
) => {
  const { data } = await API.put(`/admin/organizations/directions/${id}`, {
    ...datas,
  });
  return data;
};

export const updateChecklist = async (
  organ: number,
  datas: checklist,
  id: number
) => {
  const { data } = await API.put(`/admin/organizations/checklists/${id}`, {
    ...datas,
  });
  return data;
};

export const updateSelector = async (
  organ: number,
  datas: selector,
  id: number
) => {
  const { data } = await API.put(`/admin/organizations/selectors/${id}`, {
    ...datas,
  });
  return data;
};

export const updateAnnouncement = async (
  organ: number,
  datas: announcement,
  id: number
) => {
  const { data } = await API.put(`/admin/organizations/announcements/${id}`, {
    ...datas,
  });
  return data;
};

export const updateFaq = async (organ: number, datas: faq, id: number) => {
  const { data } = await API.put(`/admin/organizations/faqs/${id}`, {
    ...datas,
  });
  return data;
};

export const updateSurvey2 = async (
  organ: number,
  datas: survey,
  id: number
) => {
  const { data } = await API.put(`/admin/organizations/surveys/${id}`, {
    ...datas,
  });
  return data;
};

export const updateSurveySeq = async (
  organ: number,
  datas: survey_sequence,
  id: number
) => {
  const { data } = await API.put(
    `/admin/organizations/survey_sequences/${id}`,
    { ...datas }
  );
  return data;
};

export const updateSurveyItems = async (
  organ: number,
  survey_id: number,
  survey_item_id: number,
  label: string,
  alt_title: string,
  order?: number
) => {
  const { data } = await API.put(
    `/admin/organizations/surveys/${survey_id}/survey-items/${survey_item_id}`,
    { label, alt_title, order }
  );
  return data;
};

export const updateFaqQuestion = async (
  organ: number,
  faq_id: number,
  question_id: number,
  datas: faqQuestions
) => {
  const { data } = await API.put(
    `/admin/organizations/faqs/${faq_id}/questions/${question_id}`,
    { ...datas }
  );
  return data;
};

export const createFaqQuestion = async (
  organ: number,
  faq_id: number,
  datas: faqQuestions
) => {
  const { data } = await API.post(
    `/admin/organizations/faqs/${faq_id}/questions`,
    { ...datas }
  );
  return data;
};

export const deleteFaqQuestion = async (
  organ: number,
  faq_id: number,
  question_id: number
) => {
  const { data } = await API.delete(
    `/admin/organizations/faqs/${faq_id}/questions/${question_id}`
  );
  return data;
};

export const deleteSurvey = async (organ: number, survey_id: number) => {
  const { data } = await API.delete(
    `/admin/organizations/surveys/${survey_id}`
  );
  return data;
};

export const createSurveyItem = async (
  organ: number,
  survey_id: number,
  label: string,
  alt_title: string,
  order?: number
) => {
  const { data } = await API.post(
    `/admin/organizations/surveys/${survey_id}/survey-items`,
    { label, alt_title, order }
  );
  return data;
};

export const createSurveyInSurveySeq = async (
  organ: number,
  survey_sequence_id: number,
  item: surveys
) => {
  const { data } = await API.post(
    `/admin/organizations/survey_sequences/${survey_sequence_id}/surveys`,
    { ...item }
  );
  return data;
};

export const deleteSurveyItem = async (
  organ: number,
  survey_id: number,
  survey_item_id: number
) => {
  const { data } = await API.delete(
    `/admin/organizations/surveys/${survey_id}/survey-items/${survey_item_id}`
  );
  return data;
};
