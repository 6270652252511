import { FC, useContext, useReducer } from "react";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import { toast } from "../../../../utils/Toast";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { createActionTemplate } from "../../../../apiQuery/ActionTemplate/ActionTemplate.apis";
import { EnumActionType, IActionMetadata } from "../../../../Types/ActionTemplate/ActionTemplate";
import { TabActionLayout } from "../TabActionLayout";
import { ALNotificationActionTemplate, initialAlNotificationContent } from "../../../../Types/ActionTemplate/ALNotification";


export type ALNotificationActionCreateOrUpdateRequest = {
    action_type: EnumActionType.ALNotificationAction,
    action_metadata: IActionMetadata[],
}

const NewALNotification: FC = () => {
    const params = useParams<{ actionType: string }>()
    const navigate = useNavigate();

    const { currentOrganizationID } = useContext(UserContext);

    const [formData, updateFormData] = useReducer((prev: ALNotificationActionTemplate, next: Partial<ALNotificationActionTemplate>) => {
        return { ...prev, ...next }
    }, initialAlNotificationContent)

    // ============================ Mutation ================================

    const createALNotificationActionMutation = useMutation((param: ALNotificationActionCreateOrUpdateRequest) => createActionTemplate(param), {
        onSuccess: async (data) => {
            toast("Action created successfully", "success")
            const id = data?.data?.id
            if (id) {
                navigate(`/action-templates/${params.actionType}/view/${id}`)
            }
        },
        onError: (error: any) => {
            const message = error?.response?.data?.message || "Something went wrong!"
            toast(message, "error")
        }
    })

    // ======================  Submit AL Notification  ========================
    const submitFormHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (formData.action_metadata.title === '') {
            toast('Please enter a title ', 'error')
            return
        }
        if (formData.action_metadata.al_message === '') {
            toast('Please enter a message', 'error')
            return
        }


        const ALNotificationAction: ALNotificationActionTemplate = {
            alNotification: formData.alNotification,
            action_metadata: {
                ...formData.action_metadata,
                language: formData.action_metadata.language
            }
        }
        const data = [ALNotificationAction]

        const action_metadata: IActionMetadata[] = data.map((item) => {
            return {
                id: 0,
                organization_id: currentOrganizationID(),
                language: LanguageEnum.English,
                title: item.action_metadata.title,
                al_message: item.action_metadata.al_message
            }
        })

        const shapeData: ALNotificationActionCreateOrUpdateRequest = {
            action_type: EnumActionType.ALNotificationAction,
            action_metadata: action_metadata,
        }

        await createALNotificationActionMutation.mutateAsync(shapeData)
    }
    return <TabActionLayout>

        <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
            <h1 className="text-xl">Create New AL Notification Action Template</h1>
            <h1 className="pb-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{formData.action_metadata.title || ''}</h1>



            <AppTextArea placeholder={"AL Notification Title"} required value={formData.action_metadata.title!} rows={1} name='title'
                onChange={
                    (e) => updateFormData({ action_metadata: { ...formData.action_metadata, title: e.target.value } })
                }
                label='Title' />
            <AppTextArea placeholder={"AL Notification Message"} required value={formData.action_metadata.al_message!} rows={6} name='message'
                onChange={
                    (e) => updateFormData({ action_metadata: { ...formData.action_metadata, al_message: e.target.value } })
                }
                label='Message' />
            {/* <AppDropDown
                extendClass="mt-1.5"
                isFull
                label=""
                name="time"
                options={[
                    { label: "Standard", value: "0" },
                    { label: "Priority", value: "1" },
                    { label: "Urgent", value: "2" },
                ]}
                value={formData.importance.toString()}
                onChange={
                    (e) => updateFormData({ importance: parseInt(e.target.value, 10) })
                }
            /> */}

            <div className='flex w-full gap-2 mb-3'>
                <AppButton disabled={createALNotificationActionMutation.isLoading} variant="primary" type="submit" extendClass="px-3">
                    <div className="flex items-center justify-center gap-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 96 960 960"
                            className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
                        >
                            <path
                                fill="currentColor"
                                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
                        </svg>
                        Create Action Template
                    </div>
                </AppButton>
            </div>
        </form>

    </TabActionLayout>
}

export default NewALNotification;