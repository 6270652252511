export const SearchIcon = (props: { className?: string }) => {
  const { className } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      className={`custom__icon ${className || ''}`}
      viewBox='0 0 7.315 7.315'
    >
      <path
        fill='currentColor'
        d='M12.868 13.265L10.2 10.593a2.239 2.239 0 01-.715.407 2.636 2.636 0 01-2.723-.615 2.6 2.6 0 010-3.678 2.518 2.518 0 011.849-.757 2.489 2.489 0 011.834.762 2.6 2.6 0 01.615 2.682 2.579 2.579 0 01-.427.762l2.682 2.662zm-4.257-2.723a1.9 1.9 0 001.4-.584 2 2 0 000-2.814 1.9 1.9 0 00-1.4-.584 1.995 1.995 0 00-2 1.991 1.995 1.995 0 002 1.991z'
        transform='translate(-6 -5.95)'
      />
    </svg>
  )
}

SearchIcon.defaultProps = {
  className: ''
}
