import { IContentMetadata } from "./ContentMetadata";
import { EnumContentType } from "./ContentType";
import { LanguageEnum } from "./Langauge";

// for the create and update the Announcement
// to create with Announcement content type id is going to be 0
export interface IAnnouncement {
	id?: number
	title: string
	description: string
	body: string
	organization_id?: number
	language: LanguageEnum
	updated_at?: string
}


export interface AnnouncementContentCreateOrUpdateRequest {
	content_type: EnumContentType,
	announcement: IAnnouncement[],
	content_metadata: IContentMetadata[]
}


export type IAnnouncementContentTemplate = {
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	organization_id: number;
	content_type: string;
	linked_content: any;
	linked_content_count: number;
	content_metadata: IContentMetadata[];
	announcement: IAnnouncement[];
}

export type AnnouncementContentResponse = {
	data: IAnnouncementContentTemplate
	error: boolean
	message: string
	status: number
}


export type AnnouncementContentTemplate = {
	announcement: IAnnouncement
	content_metadata: IContentMetadata
}

export const initialAnnouncementContent: AnnouncementContentTemplate = {
	announcement: {
		id: 0,
		title: '',
		description: '',
		body: '',
		language: LanguageEnum.English,
	},
	content_metadata: {
		id: 0,
		cover_image: '',
		preview_title: '',
		tags: [''],
		language: LanguageEnum.English,
		alt_title: '',
	}
}