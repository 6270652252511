import React, { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery, } from "react-query";
import { deleteContentTemplateById, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { getUploadUrl, uploadFile } from '../../../../apiQuery/Common/Upload.api'
import UseUpload from '../../../../Hook/UseUpload';
import { baseURL } from '../../../../apiQuery';
import UseUploadFile from '../../../../Hook/UseUploadFile';
import axios from 'axios';
import { IVideo, IVideoContentTemplate, VideoContentCreateOrUpdateRequest, VideoContentResponse, VideoContentTemplate, initialVideoContent } from '../../../../Types/ContentTemplate/Video';
import UploadProgress from '../../../General/UploadProgress';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { useNavigate } from 'react-router-dom';

export type Language = "en" | "fr" | "es"



export const LanguagesOptionsList = [
	{ value: "-", label: "Choose an option" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]


export interface VideoContent {
	title: string
	description: string
	body: string
	language: Language
}


interface VideoTabContentProps {
	templateId: number,
}


const VideoTabContent: FC<VideoTabContentProps> = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { currentOrganizationID } = useContext(UserContext);


	//=============================================== upload video & Cover ===============================================
	const handleUpload = useMutation((formData: any) => uploadFile(formData), {})
	const [imageSrc, file, render, name] = UseUploadFile('Video')
	const { uploadForm, progress, isLoading } = UseUpload(`${baseURL}upload`);
	const [video, setVideo] = useState<any>(undefined)
	const [uploadedVideo, setUploadFile] = useState<File | undefined>(undefined)
	const getUpload = useMutation(() => getUploadUrl(uploadedVideo?.name || ""), {})


	const [uploadState, setUploadState] = useState({
		file,
		name,
	})

	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])

	//=====================================================================================================================

	const [selectedLanguage, setSelectedLanguage] = useState<string>('')

	const [state, setState] = useState<VideoContentTemplate[]>([])

	const [videoContent, setVideoContent] = useState<VideoContentTemplate>(initialVideoContent)

	const createOrUpdateButton = useRef<string>("Create")

	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);


	//=============================  Mutations ======================================================= 

	const updateVideoContentMutation = useMutation(
		(params: { id: number, data: VideoContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Template updated successfully", "success")
			await refetch()
			// alert('currentLanguage' + )
			setSelectedLanguage(currentLanguage.current)
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
	//=====================================================
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
	// =============================================================================================



	const { data: videoData, refetch } = useQuery<VideoContentResponse, Error>
		(`video-content-${props.templateId}`, () => getContentTemplateById(props.templateId));

	useEffect(() => {
		if (videoData?.data) {
			editableVideoContent(videoData?.data)
		}
	}, [videoData?.data])

	// useEffect(() => {
	// 	if (selectedLanguage === '') return
	// 	// get seleted index of the content template to be displayed as the current content
	// 	const selectedIndex = videoData?.data['video'].findIndex((item: any) => item.language === selectedLanguage) as number
	// 	const announcement: IVideo = videoData?.data['video'][selectedIndex] as IVideo
	// 	const contentMetaData = videoData?.data.content_metadata[selectedIndex] as IContentMetadata
	// 	setVideoContent({
	// 		video: announcement,
	// 		content_metadata: contentMetaData
	// 	})
	// 	createOrUpdateButton.current = "Update"
	// }, [selectedLanguage])



	const deleteTemplateFromContentTemplateList = async () => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/video', { state: { deleted: true } })
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.video.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}

	//=====================================================
	//set english default language for content


	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setVideoContent({
				video: videoData?.data.video.find((item: IVideo) => item.language === LanguageEnum.English) as IVideo,
				content_metadata: videoData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			setSelectedLanguage(LanguageEnum.English)
			createOrUpdateButton.current = "Update"
		}
	}, [videoData?.data.video, videoData?.data.content_metadata])

	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)

	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.video.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges

	}, [state])



	//=====================================================

	// ===================================== Handlers ===============================================
	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setVideoContent(prev => {
			return {
				...prev,
				video: {
					...prev.video,
					[name]: value
				}
			}
		})
	}


	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		//  tODO: validation goes here
		if (videoContent.video.title === '') {
			toast('Title is required', 'error')
			return
		}


		// =================== CREATE : main state ==================
		if (createOrUpdateButton.current === "Create") {

			// Todo : check if the language is already exist
			if (state.some(item => item.video.language === videoContent.video.language)) {
				toast('This video is already exist', 'error')
				return
			}


			let coverImage = {
				link: ''
			}

			if (file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", file);
				const { link } = await uploadForm(formData)
				coverImage.link = link
			}

			let videoUpload = {
				link: ''
			}

			if (uploadedVideo !== undefined) {
				const { upload_link, link } = await getUpload.mutateAsync()

				videoUpload.link = link

				axios.put(upload_link, uploadedVideo, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
			}

			const videoContentWithImageAndVideo: VideoContentTemplate = {
				video: {
					...videoContent.video,
					file: videoUpload.link,
				},
				content_metadata: {
					...videoContent.content_metadata,
					cover_image: coverImage.link,
				}
			}

			// setState(prev => [...prev, videoContentWithImageAndVideo])
			const newState = [...state, videoContentWithImageAndVideo]
			await createOrUpdateContentTemplate(newState)
			setSelectedLanguage('')

		} else {

			// =================== UPDATE ===================

			let cover_image = videoContent?.content_metadata?.cover_image || ''

			if (file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", file);
				const { link } = await uploadForm(formData)
				cover_image = link
			}

			let videoUpload = {
				link: videoContent.video.file
			}

			if (uploadedVideo !== undefined) {
				const { upload_link, link } = await getUpload.mutateAsync()

				videoUpload.link = link

				axios.put(upload_link, uploadedVideo, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
			}

			const newState = state.map(item => {
				if (item.video.language === videoContent.video.language) {
					return {
						video: {
							...videoContent.video,
							file: videoUpload.link,
						},
						content_metadata: {
							...videoContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})

			setState(newState)
			await createOrUpdateContentTemplate(newState)
			createOrUpdateButton.current = "Create"
			// setSelectedLanguage()
		}


		// 	empty the fields
		setVideoContent(initialVideoContent)
		// reset fields
		setUploadState({
			file: undefined,
			name: '',
		})
		setUploadFile(undefined)
		setVideo(undefined)

	}

	const createOrUpdateContentTemplate = async (data: VideoContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one video template', 'info')
			return
		}

		const shapeData: VideoContentCreateOrUpdateRequest = {
			content_type: EnumContentType.VideoContent,
			video: data.map(item => {
				return {
					...item.video,
					title: item.video.title,
					description: item.video.description,
					body: item.video.body,
					file: item.video.file,
					language: item.video.language as LanguageEnum,
				}
			}),
			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.video.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}


		await updateVideoContentMutation.mutateAsync({ id: props.templateId, data: shapeData })
		setVideoContent(initialVideoContent)
		setSelectedLanguage('')
	}


	const editableVideoContent = (videoContentTemplate: IVideoContentTemplate) => {

		const combinedData: any[] = [];
		// Loop through each article
		videoContentTemplate.video.forEach((video: any) => {
			// Loop through each content metadata
			videoContentTemplate.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (video.language === metadata.language) {
					// Combine the properties of the video and metadata objects
					const combinedObj = {
						video: video,
						content_metadata: metadata
					};
					// Add the combined object to the final array
					combinedData.push(combinedObj);
				}
			});
		});

		setState(combinedData)
	}


	const videoChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		return new Promise((resolve, reject) => {
			const file = event.currentTarget.files![0];
			setUploadFile(file)
			window.URL = window.webkitURL || window.URL;
			const reader = new FileReader();
			// If error occurs, reject the promise
			reader.onerror = () => {
				reject("Err")
			}
			reader.onload = () => {
				if (reader.result !== undefined) {
					// @ts-ignore
					resolve(window.URL.revokeObjectURL(reader.result))
				} else {
					reject("Err")
				}
			}
			const rr = window.URL.createObjectURL(file)
			setVideo(rr)
		});
	}

	return (
		<Fragment>

			<ViewContentTemplateHeader
				title={videoData?.data.video[0].title || ''}
				icon={<svg
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 7.573 8.414"
					className="flex-shrink-0 transition duration-100 h-14 w-14 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white"
				>
					<path
						id="icon_pathways"
						d="M9.471,12.414v-2.1A1.806,1.806,0,0,0,9.3,9.48a2.783,2.783,0,0,0-.515-.673l.452-.452a2.017,2.017,0,0,1,.289.316q.153.2.258.368a4.373,4.373,0,0,1,.352-.473,3.369,3.369,0,0,1,.331-.337,2.816,2.816,0,0,0,.878-1.194A4.511,4.511,0,0,0,11.553,5.2l-.947.947L10.165,5.7l1.7-1.7,1.7,1.7-.442.442L12.184,5.2a5.135,5.135,0,0,1-.258,2.088,3.973,3.973,0,0,1-1.036,1.425,2.623,2.623,0,0,0-.626.768,1.941,1.941,0,0,0-.163.831v2.1ZM7.451,6.566a4.092,4.092,0,0,1-.068-.552,6.756,6.756,0,0,1-.005-.8l-.936.936L6,5.7,7.7,4l1.7,1.7-.442.442L8.019,5.2q-.021.4-.011.7a3.469,3.469,0,0,0,.053.521Zm.883,1.8a4.367,4.367,0,0,1-.394-.5,2.489,2.489,0,0,1-.331-.678l.621-.158a2.427,2.427,0,0,0,.252.5,2.932,2.932,0,0,0,.294.389Z"
						transform="translate(-6 -4)"
						fill="currentColor"
					/>
				</svg>}
				referencesCount={videoData?.data.linked_content_count || 0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{
					videoData?.data.video.sort((a, b) => {
						// Define a language order
						const languageOrder = { en: 0, es: 1, fr: 2 };

						// Get the language order for each object
						const orderA = languageOrder[a.language] || Infinity;
						const orderB = languageOrder[b.language] || Infinity;

						// Compare the language order
						return orderB - orderA;
					}).map((item: IVideo, idx: number) => {
						return (
							<AppButton
								key={idx}
								variant={selectedLanguage === item.language ? 'info' : 'primary'}
								type="button"
								size="xs"
								onClick={() => {
									if (selectedLanguage === item.language) {
										setVideoContent(initialVideoContent)
										setSelectedLanguage('')
										createOrUpdateButton.current = "Create"
									} else {
										// editableVideoContent(videoData.data)

										createOrUpdateButton.current = "Update"
										currentLanguage.current = item.language as LanguageEnum
										const video = videoData.data.video.find((article) => article.language === item.language) as IVideo
										setVideoContent({
											video: video,
											content_metadata: videoData.data.content_metadata.find((metadata: IContentMetadata) => metadata.language === item.language) as IContentMetadata
										})
										if (video.file === "") {
											setVideo(undefined)
											setUploadFile(undefined)
										}
										setSelectedLanguage(item.language)
									}
								}}
							>
								<div className="flex items-center justify-center gap-1">
									<LanguageIcon className='w-3 h-3' />
									<span className="text-xs">{getLangaugeLabel(item.language)}</span>
								</div>
							</AppButton>
						)
					})
				}
				{
					(videoData?.data.video?.length ?? 0) < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setVideoContent(initialVideoContent)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"
							}}
						>
							<span className="text-xs">
								{t('new_language')}
							</span>

						</AppButton>
					) : null
				}
			</div>




			<TabContentLayout>

				{/* =========================== */}
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{videoContent?.video?.title || ""}</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">

					<div className='flex w-full gap-2 mb-8'>
						<AppButton size="xs" disabled={videoContent?.video?.title === '' || updateVideoContentMutation.isLoading || deleteContentTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Content Template</AppButton>
						<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>{t("content_template.delete_content_template")}</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>
					</div>
					<hr className="mb-8" />
					{/* ======================== */}

					{isLoading ? <UploadProgress progress={progress} /> : null}

					<AppInput autoFocus placeholder={"Title"} value={videoContent?.video?.title || ""} name='title' isFull onChange={handleChange} label='Title' required />
					<AppTextArea placeholder={"Description"} rows={4} value={videoContent?.video?.description || ""} name='description' onChange={handleChange}
						label='Description' />
					<AppTextArea placeholder={"Body"} value={videoContent?.video?.body || ""} rows={6} name='body' onChange={handleChange} label='Body' />


					<div className="mt-2 mb-3">
						<label htmlFor="contained-button-video-file"
							className="flex flex-col items-center justify-center p-8 text-gray-400 bg-white border-2 border-gray-300 border-dashed cursor-pointer">
							<span>Select Video</span>
							<span>(16:9 ratio, 25mb limit)</span>
						</label>

						<input onChange={videoChangeHandler}
							type="file"
							accept="video/*"
							style={{ display: 'none' }}
							id="contained-button-video-file" />
					</div>
					<div className='my-2'>
						{videoContent?.video?.file ? (<a className="my-6 text-blue-500" href={videoContent?.video?.file}>Video Link</a>) : null}
						{uploadedVideo !== undefined ? <p>{uploadedVideo.name}</p> : null}
					</div>


					{/* ========================================== */}

					{/* <AppInput placeholder={"Alternative Title"} value={videoContent?.content_metadata?.alt_title || ''} name='alt_title' isFull
						onChange={e => {
							setVideoContent({
								...videoContent,
								content_metadata: {
									...videoContent.content_metadata,
									alt_title: e.target.value
								}
							})
						}} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

					<AppInput placeholder={"Preview Title"} value={videoContent?.content_metadata?.preview_title || ""} name='preview_title' isFull
						onChange={e => {
							setVideoContent(prev => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										preview_title: e.target.value
									}
								}
							})
						}} label='Preview Title' extendClass='mb-2 mt-3' maxLength={140} required />

					<AppInput
						placeholder={"Tag"}
						value={videoContent?.content_metadata?.tags?.[0] || ""}
						name="tags"
						isFull
						onChange={(e) => {
							const tags = [e.target.value];
							setVideoContent({
								...videoContent,
								content_metadata: {
									...videoContent.content_metadata,
									tags,
								},
							});
						}}
						label="Tag"
						extendClass="my-2"
					/>

					{videoContent?.content_metadata?.cover_image ?
						<div className='cover__image-container'>
							<img alt="cover_image" src={videoContent.content_metadata.cover_image} className='my-2 rounded-sm' />
						</div>
						: null}
					{render}
					{
						uploadState.name !== undefined ? <p>{uploadState.name}</p> : null
					}

					{/* ========================================== */}
					{/* <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} extendClass='mt-4' label={"Language"}
						options={LanguagesOptions} value={videoContent?.video?.language || ""} name="language" onChange={handleChange} /> */}

					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={videoContent?.video?.language || ""} name="language" onChange={handleChange} />
					}

				</form>


			</TabContentLayout>


			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</Fragment>
	)
}


export default VideoTabContent
