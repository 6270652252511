import React, {FC, useCallback, useState} from 'react';
import {ContCommon} from "../../../Common/Types";
import Button from "../../General/Button";
import {MdCheckCircle} from "react-icons/md";

const SurveyPreview: FC<ContCommon> = ({survey}) => {

    const [selected, setSelected] = useState(0)
    const handleChange = useCallback((item: number) => setSelected(item), []);

    return (
        <div className="pb-4 space-y-5">
            <div className="space-y-2">
                {
                    survey?.map((item, index) =>
                        <div key={index}
                             className={`bg-white text-center p-3 rounded-lg cursor-pointer ${selected === index && 'outline outline-offset-2 outline-cyan-500'}`}
                             onClick={handleChange.bind('', index)}>
                            <span className="text-black ">{item.label}</span>
                        </div>)
                }
            </div>

            <Button icon={<MdCheckCircle className="mx-1"/>} label={"Complete Survey"}
                    extraClasses={"bg-[#9263BD] opacity-90"}/>
        </div>
    );
};

export default SurveyPreview