import React, { useContext, useEffect, useMemo, useState } from "react";
import PageHeader from "../Layouts/PageHeader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { State, State_R, State_R_Single, TFlow } from "../../Common/Types";
import { getState, updateState } from "../../apiQuery/State/State.apis";
import { store } from "../../GlobalState/store";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../General/Button";
import Input from "../General/Form/Input";
import TextArea from "../General/Form/TextArea";
import { SubmitHandler, useForm } from "react-hook-form";
import SkeletonLoader from "../Helpers/Skeleton";
import { Autocomplete, Chip, LinearProgress, TextField } from "@mui/material";
import { CurrentFlow_R } from "../../GlobalState/types";
import { getStateFlow } from "../../apiQuery/StateFlow/State.apis";
import { BiArrowBack } from "react-icons/bi";
import Switch from "@mui/material/Switch";
import { withAuth } from "../../hoc/withAuth";
import { useTranslation } from "react-i18next";
import { toast } from "../../utils/Toast";

type possibleStates = {
  selectedStates: State[];
  isChanged: boolean;
};

const EditState = () => {
  const { t, i18n } = useTranslation();

  const { state: globalState, dispatch } = useContext(store);
  const organization_id = useMemo(
    () => globalState.organization_id,
    [globalState.organization_id]
  );

  const params = useParams();
  const id = params.stateSlug || "";
  const pathwaySlug = params.slug || "";

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const currentState = useMemo(
    () =>
      globalState.currentFlow.states?.find(
        (item) => item.slug === id.toString()
      ),
    [globalState.currentFlow]
  );

  const { data: allStates } = useQuery<CurrentFlow_R, Error>(
    ["individual-state-flow", globalState.currentFlow.slug],
    () => getStateFlow(globalState.currentFlow.slug)
  );

  const { isLoading, isError, data, error, refetch, isFetching } = useQuery<
    State_R_Single,
    Error
  >(["state", id], () => getState(globalState.currentFlow.slug, id), {});

  const updateCurrentState = useMutation(
    (stateData: State) =>
      updateState(globalState.currentFlow.slug, id, {
        ...stateData,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          "individual-state-flow",
          globalState.currentFlow.slug,
        ]);
        await queryClient.invalidateQueries(["state", id]);
        // dispatch(setSnackbar({ isOpen: true, type: 'success', message: 'StateTemplate has been updated.' }))
        toast("StateTemplate has been updated.", "success");
        navigate(`/pathways/${globalState.currentFlow.slug}`);
      },
      onError: (error: any) => {
        const message =
          typeof error.response !== "undefined"
            ? error?.response?.data?.message
            : error?.message || "Cannot update the StateTemplate";
        // dispatch(setSnackbar({ isOpen: true, type: 'error', message }))
        toast(message, "error");
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<State>();
  const onSubmit: SubmitHandler<State> = async (data) => {
    const ids = updateNextPossible.selectedStates.map((item) => item.id); // @ts-ignore
    const nextPossibleStates: number[] = ids
      ? ids.filter((it) => it !== undefined)
      : [];
    await updateCurrentState.mutateAsync({
      ...data,
      next_possible_states: nextPossibleStates,
    });
  };

  const [updateNextPossible, setUpdateNextPossible] = useState<possibleStates>({
    selectedStates: [],
    isChanged: false,
  });

  useEffect(() => {
    if (data?.data?.state?.name) {
      setValue("name", data?.data?.state?.name);
      setValue("description", data?.data?.state?.description);
      setValue("visible_to_worker", data?.data?.state?.visible_to_worker);
      setValue("invalidate_user", data?.data?.state?.invalidate_user);
      setValue("disable_participant", data?.data?.state?.disable_participant);
    }
  }, [data]);

  useEffect(() => {
    if (data && allStates) {
      const nextPossible = data?.data?.state?.next_possible_states;
      const ss = [] as unknown as State[];
      nextPossible?.map((item) => {
        const tt = allStates?.data?.states?.find((data) => data.id === item);
        if (tt) ss.push(tt);
      });

      setUpdateNextPossible((val) => ({
        ...val,
        selectedStates: ss,
      }));
    }
  }, [data, allStates]);

  return (
    <>
      {isFetching && <LinearProgress />}

      <form
        className="space-y-6 overflow-scroll"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PageHeader
          title={currentState?.name || ""}
          button={
            <div className="flex space-x-3">
              <Button
                onClick={() => navigate(-1)}
                icon={<BiArrowBack className="mr-2 text-xl" />}
                label={`${t("StatePage.Go_Back")}`}
                extraClasses="w-fit px-3 py-3 bg-blue-400 text-white"
              />
              <Button
                disabled={updateCurrentState.isLoading}
                type="submit"
                label={`${t("StatePage.Save_Changes")}`}
                extraClasses="w-fit px-3 py-3 bg-blue-400 text-white"
              />
            </div>
          }
        />

        {isLoading ? (
          <SkeletonLoader />
        ) : isError ? (
          <span>Error: {error?.message || "Something went wrong!"}</span>
        ) : data ? (
          <div className="w-11/12 py-5 m-auto space-y-5 text-gray-600 text-md">
            <Input
              register={() =>
                register("name", { required: "name is required" })
              }
              name="name"
              placeholder={`${t("StatePage.State_Name")}`}
              errors={errors}
              extraClasses="w-2/5"
            />
            <TextArea
              register={() => register("description")}
              name="description"
              placeholder={`${t("StatePage.State_Description")}`}
              errors={errors}
              extraClasses="w-2/5"
            />
            <div className="flex flex-col space-y-2">
              <span>{`${t("StatePage.Visible_to_Worker")}`}</span>
              <Switch
                defaultChecked={data?.data?.state?.visible_to_worker}
                {...register("visible_to_worker")}
                color="primary"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <span>{t("StatePage.Invalidate_User")}</span>
              <Switch
                defaultChecked={data?.data?.state?.invalidate_user}
                {...register("invalidate_user")}
                color="primary"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <span>{t("StatePage.Deactivate_Participants")}</span>
              <Switch
                defaultChecked={data?.data?.state?.disable_participant}
                {...register("disable_participant")}
                color="primary"
              />
            </div>

            <div className="flex flex-col pb-10 mb-6 space-y-2">
              <Autocomplete
                multiple
                id="NextPossibleStates"
                value={updateNextPossible.selectedStates}
                onChange={(event, newValue) => {
                  setUpdateNextPossible({
                    isChanged: true,
                    selectedStates: [...newValue],
                  });
                }}
                options={
                  allStates?.data?.states?.filter(
                    (item) => item.id !== parseInt(id)
                  ) || []
                }
                getOptionLabel={(option) => option.name}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip label={option.name} {...getTagProps({ index })} />
                  ))
                }
                style={{ width: 500, background: "white" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("StatePage.Next_Possible_States")}
                    placeholder={`${t("StatePage.State")}`}
                  />
                )}
              />
            </div>
          </div>
        ) : null}
      </form>
    </>
  );
};

export default withAuth(EditState);
