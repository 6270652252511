import React, {FC, memo} from 'react';
import {
    MdAnnouncement,
    MdArticle,
    MdChecklistRtl,
    MdDirections,
    MdEmail,
    MdOutlineSignalCellularNull,
    MdSms
} from "react-icons/md";
import {RiSurveyFill, RiVideoFill, RiCheckDoubleFill} from "react-icons/ri";
import {AiTwotoneSound} from "react-icons/ai";
import {BsFillLayersFill, BsFillQuestionSquareFill} from "react-icons/bs";
import {FaUpload} from "react-icons/fa";
import {VscGraph} from "react-icons/vsc";


type typeIcon = {
    type: string | undefined
}

const TypeIcon: FC<typeIcon> = memo(({type}) => {
    return (
        <div>
            {(() => {
                switch (type) {
                    case 'article':
                        return <MdArticle/>
                    case 'video':
                        return <RiVideoFill/>
                    case 'checklist':
                        return <MdChecklistRtl/>
                    case 'direction':
                        return <MdDirections/>
                    case 'email':
                        return <MdEmail/>
                    case 'sms':
                        return <MdSms/>
                    case 'notification':
                        return <AiTwotoneSound/>
                    case 'selector':
                        return <BsFillLayersFill/>
                    case 'picture':
                        return <FaUpload/>
                    case 'consent':
                        return <RiCheckDoubleFill/>
                    case 'announcement':
                        return <MdAnnouncement/>
                    case 'faq':
                        return <BsFillQuestionSquareFill/>
                    case 'survey':
                        return <RiSurveyFill/>
                    case 'survey_sequence':
                        return <VscGraph/>
                    default:
                        return <MdOutlineSignalCellularNull/>
                }
            })()}
        </div>
    );
});

export default TypeIcon;