import React, {FC} from 'react';
import {RiTimerFill} from "react-icons/ri";
import {AiFillInteraction} from "react-icons/ai";
import {BsInfoSquareFill} from "react-icons/bs";
import {Conditions_T} from "../../Common/Types";

type props = {
    is_time_base?: boolean
    time_condition?: Conditions_T[]
    rules_id?: number
    rule_name: string
}

const ViewEvent: FC<props> = ({is_time_base, time_condition, rules_id, rule_name}) => {
    return (
        <>
            {
                is_time_base ?
                    <div
                        className="flex flex-col justify-center rounded-md bg-sky-100 pl-4 pr-4 pt-2 pb-2 text-sky-700 mt-4 border-none">
                        <div className="flex flex-row font-medium items-center">
                            <RiTimerFill/>
                            <p className="text-lg font-medium ml-2">Time delivery condition</p>
                        </div>
                        {
                            time_condition?.map((item, index) => <div
                                key={index}>
                                <div className="flex space-x-4">
                                    <div className="flex">
                                        <p>This Content is
                                            delivered <b>{item.deliver_time} {item.deliver_unit}s</b> after the Worker
                                            enters this state</p>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                    :
                    rules_id ?
                        <div
                            className="flex flex-col justify-center rounded-md bg-sky-100 pl-4 pr-4 pt-2 pb-2 text-sky-700 mt-4 border-none">
                            <div className="flex flex-row font-medium items-center">
                                <AiFillInteraction/>
                                <p className="text-lg font-medium ml-2">Event delivery condition</p>
                            </div>
                            <p>This Content is delivered if <b>{rule_name}</b></p>
                        </div> :
                        <div
                            className="flex flex-col justify-center rounded-md bg-sky-100 pl-4 pr-4 pt-2 pb-2 text-sky-700 mt-4 border-none">
                            <div className="flex flex-row font-medium items-center">
                                <BsInfoSquareFill/>
                                <p className="text-lg font-medium ml-2">No delivery condition</p>
                            </div>
                            <p>This Content has no delivery condition, meaning that it will be delivered as soon as the
                                Worker enters this State.</p>
                        </div>
            }
        </>
    );
};

export default ViewEvent;