import { EnumActionType, LanguageEnum } from "./ActionTemplate";
import { IActionMetadata } from "./ActionTemplate";


export interface ISMS {
	text: string
	language: LanguageEnum

}
export interface SMSActionCreateOrUpdateRequest {
	importance : number,
	action_type: EnumActionType,
	sms: ISMS[],
	action_metadata: IActionMetadata[]
}

export type SMSActionTemplate = {
	sms: ISMS,
	action_metadata: IActionMetadata,
	importance:number
}

export const initialSMSContent: SMSActionTemplate = {
	sms: {
		language: LanguageEnum.English,
		text: '',

	},
	action_metadata: {
		id: 0,
		organization_id: 0,
		language: LanguageEnum.English,
		text: '',
		subject: '',
		body: '',
		title: '',
		subtitle: '',
		description: '',
	},
	importance:0
}



export interface SMSActionResponse {
	data: SMSMetaData[]
	// id: number
	// created_at: string
	// updated_at: string
	// deleted_at: any
	// action_type: string
	// importance: number
	// action_metadata: SMSMetaData[]
	// organization_id: number
	error: boolean
	message: string
	status: number
}

export interface SMSMetaData {
	id: number
	created_at: string
	updated_at: string
	deleted_at: any
	language: string
	text: string
	organization_id: number
	owner_type: string
	owner_id: number
}
