import React, { ChangeEvent, FC, FormEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { TabContentLayout } from "../TabContentLayout";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useTranslation } from "react-i18next";
import { getLangaugeLabel } from "../../../../utils/Languages";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { IContentMetadata } from "../../../../Types/ContentTemplate/ContentMetadata";
import { useMutation, useQuery } from "react-query";
import { deleteContentTemplateById, getContentTemplateById, updateContentTemplateById } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";

import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { FAQContentCreateOrUpdateRequest, FAQContentTemplate, IFAQ, IFAQQuestion, fAQContentResponse, initialFAQContent } from '../../../../Types/ContentTemplate/FAQ';
import UseUploadFile from '../../../../Hook/UseUploadFile';
import UseUpload from '../../../../Hook/UseUpload';
import { baseURL } from '../../../../apiQuery';
import UploadProgress from '../../../General/UploadProgress';
import { LanguageIcon, AppButton, PopupModal } from '../../../../Theme';
import ViewContentTemplateHeader from '../../ViewContentTemplateHeader';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { reorder } from '../../../Utils/Reorder';
import { InlineFAQItem } from './InlineItem';
import { v4 as uuidv4 } from 'uuid';


export type Language = "en" | "fr" | "es"


export const LanguagesOptionsList = [
	{ value: "-", label: "Choose an option" },
	{ value: "fr", label: "French" },
	{ value: "es", label: "Spanish" }
]


interface FAQTabContentProps {
	templateId: number,
}
const FAQTabContent: FC<FAQTabContentProps> = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	// updoad cover image
	const [imageSrc, file, render, name] = UseUploadFile('FAQ')
	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);



	const [uploadState, setUploadState] = useState({
		file,
		name,
	})

	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])

	//===================================================================================================

	const { currentOrganizationID } = useContext(UserContext);



	const [state, setState] = useState<FAQContentTemplate[]>([])

	const [faqContent, setFAQContent] = useState<FAQContentTemplate>(initialFAQContent)



	const createOrUpdateButton = useRef<string>("Create")
	const createOrUpdateItemButton = useRef<string>("Create")
	const [selectedLanguage, setSelectedLanguage] = useState<string>('')
	//=========================================================================================================================
	// delete action template 
	const [showPopupDeleteConfirmation, setShowPopupDeleteConfirmation] = useState(false);
	const [showPopupLanguageDeleteConfirmation, setShowPopupLanguageDeleteConfirmation] = useState(false);




	const { data: faqData, refetch } = useQuery<fAQContentResponse, Error>
		(`faqData-content-${props.templateId}`, () => getContentTemplateById(props.templateId));

	useEffect(() => {
		if (faqData?.data) {
			editableFAQContent(faqData?.data)
		}
	}, [faqData?.data])

	// useEffect(() => {
	// 	if (selectedLanguage === '') return
	// 	// get seleted index of the content template to be displayed as the current content
	// 	const selectedIndex = faqData?.data['faq'].findIndex((item: any) => item.language === selectedLanguage) as number
	// 	const faq: IFAQ = faqData?.data['faq'][selectedIndex] as IFAQ
	// 	const contentMetaData = faqData?.data.content_metadata[selectedIndex] as IContentMetadata
	// 	setFAQContent({
	// 		faq: faq,
	// 		content_metadata: contentMetaData
	// 	})
	// 	createOrUpdateButton.current = "Update"
	// }, [selectedLanguage])

	const deleteTemplateFromContentTemplateList = async () => {
		deleteContentTemplateByID.mutateAsync({ id: props.templateId })
		navigate('/content-templates/faq', { state: { deleted: true } })
	}

	const deleteLanguageTemplateFromContentTemplate = async () => {
		if (selectedLanguage === 'en') return toast('You cannot remove english template', 'error')
		const templateWithDeletedLanguageTemplate = state.filter(a => a.faq.language !== selectedLanguage)

		createOrUpdateContentTemplate(templateWithDeletedLanguageTemplate)
	}
	//=====================================================
	//set english default language for content
	// useEffect(() => {
	// 	const faq = faqData?.data['faq'].find(item => item.language === "en")
	// 	const contentMetaData = faqData?.data.content_metadata.find(item => item.language === "en")


	// 	setFAQContent({
	// 		faq: faq as IFAQ,
	// 		content_metadata: contentMetaData as IContentMetadata
	// 	})
	// 	createOrUpdateButton.current = "Update"
	// 	setSelectedLanguage("en")

	// }, [faqData?.data])


	const currentLanguage = useRef<LanguageEnum>(LanguageEnum.English)


	useEffect(() => {
		if (currentLanguage.current === LanguageEnum.English) {
			setFAQContent({
				faq: faqData?.data.faq.find((item: IFAQ) => item.language === LanguageEnum.English) as IFAQ,
				content_metadata: faqData?.data.content_metadata.find((item: IContentMetadata) => item.language === LanguageEnum.English) as IContentMetadata
			})
			createOrUpdateButton.current = "Update"
		}
	}, [faqData?.data.content_metadata, faqData?.data.faq])

	const listOfLanguages = useMemo(() => {
		const LanguagesOptionsNewLanguges = LanguagesOptionsList.filter(item => state?.find(sl => sl.faq.language === item.value) === undefined)
		return LanguagesOptionsNewLanguges
	}, [state])


	// ====================================================== Mutations ======================================================

	const updateFAQContentMutation = useMutation(
		(params: { id: number, data: FAQContentCreateOrUpdateRequest }) => updateContentTemplateById(props.templateId, params.data), {
		onSuccess: async (data) => {
			toast("Content updated successfully", "success")
			await refetch()
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})
	//=====================================================
	const deleteContentTemplateByID = useMutation((params: { id: number }) =>
		deleteContentTemplateById(params.id), {


		onSuccess: async (data) => {
			toast("Template deleted successfully", "success")

		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})



	// ===================================================== Handlers ==============================================================


	const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setFAQContent(prev => ({ ...prev, faq: { ...prev.faq, [name]: value } }))
	}



	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()


		if (faqContent.faq.language as string === "-") {
			toast('Please select a language', 'error')
			return
		}

		//  tODO: validation goes here
		if (faqContent.faq.title === '') {
			toast('Please enter a title', 'error')
			return
		}

		if (createOrUpdateButton.current === "Create") {
			// Todo : check if the language is already exist
			if (state.some(item => item.faq.language === faqContent.faq.language)) {
				toast('This FAQ is already exist', 'error')
				return
			}


			if (faqContent.faq.questions.length === 0) {
				toast('Please add at least one question', 'error')
				return
			}


			let _cover_imageLink = ''

			if (uploadState.file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", uploadState.file);
				const { link } = await uploadForm(formData)
				_cover_imageLink = link
			}

			const faqContentWithImage: FAQContentTemplate = {
				faq: {
					...faqContent.faq,
					language: faqContent.faq.language as LanguageEnum,
					questions: faqContent.faq.questions.map(item => {
						return {
							...item,
							language: faqContent.faq.language as LanguageEnum
						}
					})
				},
				content_metadata: {
					...faqContent.content_metadata,
					cover_image: _cover_imageLink,
					language: faqContent.faq.language as LanguageEnum,
				}
			}



			// setState(prev => [...prev, faqContentWithImage])
			const newState = [...state, faqContentWithImage]
			await createOrUpdateContentTemplate(newState)

		} else {
			// update the state
			let cover_image = faqContent?.content_metadata?.cover_image || ''

			if (file !== undefined) {
				const formData = new FormData();
				// @ts-ignore
				formData.append("file", file);
				const { link } = await uploadForm(formData)
				if (!link) {
					toast('Please upload a cover image', 'error')
					return
				}
				cover_image = link
			}

			// update the state
			const newState = state.map(item => {
				if (item.faq.language === faqContent.faq.language) {
					return {
						faq: faqContent.faq,
						content_metadata: {
							...faqContent.content_metadata,
							cover_image,
						}
					}
				}
				return item
			})
			await createOrUpdateContentTemplate(newState)
			setState(newState)
			createOrUpdateButton.current = "Create"
		}
		// 	empty the fields
		setFAQContent(initialFAQContent)
		setUploadState({
			file: undefined,
			name: '',
		})
		setSelectedLanguage('')
	}

	const onDragEnd = (result: DropResult): void => {
		if (!result.destination) {
			return;
		}
		const list = faqContent?.faq?.questions || [];
		let upDatedList = reorder(
			list,
			result.source.index,
			result.destination.index
		) as IFAQQuestion[];

		upDatedList = upDatedList.map((item, ind) => ({
			...item,
			order: ind
		}))

		// @ts-ignore
		setFAQContent(prev => ({
			...prev,
			faq: {
				...prev.faq,
				questions: upDatedList,
			}
		}));
	};
	const createOrUpdateContentTemplate = async (data: FAQContentTemplate[]) => {
		if (data.length === 0) {
			toast('Please add at least one FAQ template', 'info')
			return
		}
		const shapeData: FAQContentCreateOrUpdateRequest = {
			content_type: EnumContentType.FAQContent,
			faq: data.map(item => {
				return {
					...item.faq,
					title: item.faq.title,
					description: item.faq.description,
					questions: item.faq.questions.map(question => (
						{
							question: question.question, order: question.order, answer: question.answer,
							language: question.language, faq_id: question.faq_id
						})),
					language: item.faq.language as LanguageEnum,
				}
			}),
			content_metadata: data.map(item => {
				return {
					...item.content_metadata,
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.faq.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}
		// 
		await updateFAQContentMutation.mutateAsync({ id: props.templateId, data: shapeData })

		setState([])
		setFAQContent(initialFAQContent)
	}


	const editableFAQContent = (faqContentTemplate: any) => {

		const combinedData: any[] = [];
		// Loop through each article
		faqContentTemplate.faq.forEach((faq: any) => {
			// Loop through each content metadata
			faqContentTemplate.content_metadata.forEach((metadata: any) => {
				// Check if the language matches
				if (faq.language === metadata.language) {
					// Combine the properties of the faq and metadata objects
					const combinedObj = {
						faq: faq,
						content_metadata: metadata
					};
					// Add the combined object to the final array
					combinedData.push(combinedObj);
				}
			});
		});
		setState(combinedData)
	}


	const [item, setItem] = useState<IFAQQuestion>({
		question: '',
		answer: '',
		order: 0,
		language: LanguageEnum.English,
		faq_id: 0,
		id: uuidv4()
	})

	const addItemToQuestions = () => {
		if (item.answer === '' || item.question === '') {
			toast('Please fill the question and answer fields', 'info')
			return
		}

		item.language = faqContent.faq.language

		setFAQContent(prev => {
			return {
				...prev,
				faq: {
					...prev.faq,
					questions: [...prev.faq.questions, { ...item, order: prev.faq.questions.length }]
				}
			}
		})

		setItem({
			question: '',
			answer: '',
			order: 0,
			language: LanguageEnum.English,
			faq_id: 0,
			id: uuidv4()
		})
		createOrUpdateItemButton.current = "Create"
	}


	const [showInput, setShowInput] = useState({ id: null, isInput: false });


	console.log('test',
		{
			c: createOrUpdateButton.current === "Create",
			cl: currentLanguage.current !== LanguageEnum.English
		})
	return (
		<Fragment>

			<ViewContentTemplateHeader
				title={faqData?.data.faq[0].title || ''}
				referencesCount={faqData?.data.linked_content_count || 0}
			/>


			<div className="border border-[borderGray] border-t-0 bg-white px-2 py-2.5 dark:bg-bckDark sm:px-4 sticky top-0 z-30 flex gap-2">
				{faqData?.data.faq.sort((a, b) => {
					// Define a language order
					const languageOrder = { en: 0, es: 1, fr: 2 };

					// Get the language order for each object
					const orderA = languageOrder[a.language] || Infinity;
					const orderB = languageOrder[b.language] || Infinity;

					// Compare the language order
					return orderB - orderA;
				}).map((item: IFAQ, idx: number) => {
					return (
						<AppButton
							key={idx}
							variant={selectedLanguage === item.language ? 'info' : 'primary'}
							type="button"
							size="xs"
							onClick={() => {
								if (selectedLanguage === item.language) {
									setFAQContent(initialFAQContent)
									setSelectedLanguage('')
									createOrUpdateButton.current = "Create"
								} else {
									// editableFAQContent(faqData.data)

									createOrUpdateButton.current = "Update"
									currentLanguage.current = item.language as LanguageEnum
									setFAQContent({
										faq: faqData.data.faq.find((faq: IFAQ) => faq.language === item.language) as IFAQ,
										content_metadata: faqData.data.content_metadata.find((metadata: IContentMetadata) => metadata.language === item.language) as IContentMetadata
									})
									setSelectedLanguage(item.language)
								}
							}}
						>
							<div className="flex items-center justify-center gap-1">
								<LanguageIcon className='w-3 h-3' />
								<span className="text-xs">{getLangaugeLabel(item.language)}</span>
							</div>
						</AppButton>
					)
				})
				}
				{
					(faqData?.data.faq?.length ?? 0) < 3 ? (
						<AppButton
							variant={'primary'}
							type="button"
							size="xs"
							onClick={() => {
								setFAQContent(initialFAQContent)
								setSelectedLanguage('')
								createOrUpdateButton.current = "Create"

							}}
						>
							<span className="text-xs">
								{t('new_language')}
							</span>

						</AppButton>
					) : null
				}
			</div>


			<TabContentLayout >
				<h1 className="px-5 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{faqContent?.faq?.title || ""}</h1>
				<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%]">
					<div className='flex w-full gap-2 mb-8'>
						<AppButton size="xs" disabled={faqContent?.faq?.title === '' || updateFAQContentMutation.isLoading || deleteContentTemplateByID.isLoading ? true : false} variant='primary' type='submit'>{createOrUpdateButton.current} Content Template</AppButton>
						<AppButton disabled={selectedLanguage ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupDeleteConfirmation(true)}>{t("content_template.delete_content_template")}</AppButton>
						<AppButton disabled={selectedLanguage && selectedLanguage !== 'en' ? false : true} size="xs" variant='primary' type='button' onClick={() => setShowPopupLanguageDeleteConfirmation(true)}>{t("content_template.delete_content_language")}</AppButton>

					</div>
					<hr className="mb-8" />


					<AppInput autoFocus placeholder={"Title"} value={faqContent?.faq?.title || ""} name='title' isFull onChange={handleChange} label='Title' required />
					<AppTextArea placeholder={"Description"} rows={4} value={faqContent?.faq?.description || ""} name='description' onChange={handleChange}
						label='Description' />

					<div className='p-2 my-2 border border-gray-200 rounded shadow-sm '>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided, snapshot) => (
									<div {...provided.droppableProps} ref={provided.innerRef} className="grid grid-cols-1 gap-2 mb-2">
										{faqContent?.faq?.questions?.sort((a, b) => a.order - b.order).map((item: IFAQQuestion, idx: number) => (
											<Draggable key={idx} draggableId={`item-${idx}`} index={idx}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className="flex flex-col "
													>
														<InlineFAQItem
															key={item.id}
															item={item}
															idx={idx}
															setFAQContent={setFAQContent}
															showInputId={showInput.id}
															showInput={showInput}
															setShowInput={setShowInput}
														/>

													</div>)}
											</Draggable>


										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>

						{/* ADD new Question */}
						<div className="flex flex-col items-start justify-start w-[90%] p-2 m-1 border rounded bg-hoverLightBlue">
							<AppInput placeholder={"Question"} value={item.question} name='question'
								onChange={e => setItem(prev => { return { ...prev, question: e.target.value } })} extendClass='' isFull />
							<AppTextArea rows={4} placeholder={"Answer"} value={item.answer} name='answer'
								onChange={e => setItem(prev => { return { ...prev, answer: e.target.value } })} extendClass='' isFull />

							<AppButton variant={createOrUpdateItemButton.current === 'Create' ? 'success' : 'info'} type={"button"}
								size={"sm"} onClick={addItemToQuestions} extendClass="mx-2">{createOrUpdateItemButton.current} Question</AppButton>
						</div>
					</div>

					{/* ====================== extra fields =========== */}

					{/* <AppInput placeholder={"Alternative Title"} value={faqContent?.content_metadata?.alt_title || ''} name='alt_title' isFull
						onChange={e => {
							setFAQContent({
								...faqContent,
								content_metadata: {
									...faqContent.content_metadata,
									alt_title: e.target.value
								}
							})
						}} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}



					<AppInput placeholder={"Preview Title"} value={faqContent?.content_metadata?.preview_title} name='preview_title' isFull
						onChange={e => {
							setFAQContent(prev => {
								return {
									...prev,
									content_metadata: {
										...prev.content_metadata,
										preview_title: e.target.value
									}
								}
							})
						}} label='Preview Title' extendClass='my-2' maxLength={140} required />
					<AppInput placeholder={"Tag"} value={faqContent?.content_metadata?.tags[0]} name='tags' isFull onChange={e => {
						setFAQContent(prev => {
							return {
								...prev,
								content_metadata: {
									...prev.content_metadata,
									tags: [e.target.value]
								}
							}
						})
					}} label='Tag' extendClass='my-2' />
					{faqContent?.content_metadata?.cover_image ?

						<div className='cover__image-container'>
							<img alt="conver_image" src={faqContent?.content_metadata?.cover_image} className='my-2 rounded-sm' />
						</div>
						: null}
					{render}

					{
						uploadState.name !== undefined ? <p>{uploadState.name}</p> : null
					}

					{
						isLoading ? <UploadProgress progress={progress} /> : null
					}
					{
						createOrUpdateButton.current === "Create" && <AppDropDown label={"Language"}
							options={listOfLanguages} value={faqContent?.faq?.language || ""} name="language" onChange={handleChange} />
					}

					{/* <AppDropDown disabled={createOrUpdateButton.current === "Update" ? true : false} label={"Language"}
						options={listOfLanguages} value={faqContent?.faq?.language || ""} name="language" onChange={handleChange} /> */}
				</form>
			</TabContentLayout>


			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupDeleteConfirmation}
				isOpen={showPopupDeleteConfirmation}
				title={`${t('content_template.delete_a_content_template')}`}
				onConfirmClick={deleteTemplateFromContentTemplateList}
			/>
			<PopupModal
				extraClasses="mt-12"
				isParentTop
				setOpen={setShowPopupLanguageDeleteConfirmation}
				isOpen={showPopupLanguageDeleteConfirmation}
				title={`${t('content_template.delete_a_language_content_template')}`}
				onConfirmClick={deleteLanguageTemplateFromContentTemplate}
			/>
		</Fragment>
	)
}


export default FAQTabContent
