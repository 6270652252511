// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { hexToRGBA } from "../../utils/hex-to-rgba";
import { ApexOptions } from "apexcharts";
import ReactApexcharts from "react-apexcharts";
import { FC } from "react";

// const series = [{ data: Props.loginPerDays }];

interface Props {
  name: "User Logins";
  totalLogins: number;
  data: number[];
}

const UserLogin: FC<Props> = (Props) => {
  // ** Hook
  const theme = useTheme();

  const options: ApexOptions = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
    },
    tooltip: { enabled: false },
    grid: {
      strokeDashArray: 6,
      borderColor: theme.palette.divider,
      xaxis: {
        lines: { show: true },
      },
      yaxis: {
        lines: { show: false },
      },
      padding: {
        top: -15,
        left: -7,
        right: 7,
        bottom: -15,
      },
    },
    stroke: { width: 3 },
    colors: [hexToRGBA(theme.palette.info.main, 1)],
    markers: {
      size: 6,
      offsetY: 2,
      offsetX: -1,
      strokeWidth: 3,
      colors: ["transparent"],
      strokeColors: "transparent",
      discrete: [
        {
          size: 6,
          seriesIndex: 0,
          strokeColor: theme.palette.info.main,
          fillColor: theme.palette.background.paper,
          dataPointIndex: Props.data.length - 1,
        },
      ],
      hover: { size: 7 },
    },
    xaxis: {
      labels: { show: false },
      axisTicks: { show: false },
      axisBorder: { show: false },
    },
    yaxis: {
      labels: { show: false },
    },
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
          <Typography variant="h6" sx={{ mr: 1.5 }}>
            {Props.totalLogins}
          </Typography>
          {/* <Typography variant="subtitle2" sx={{ color: "success.main" }}>
            +62%
          </Typography> */}
        </Box>
        <Typography variant="body2">Logins of users in last 7 days</Typography>
        <ReactApexcharts
          type="line"
          height={165}
          options={options}
          series={[Props]}
        />
      </CardContent>
    </Card>
  );
};

export default UserLogin;
