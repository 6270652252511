import { FC, ReactNode } from 'react'


interface TabContentLayoutProps {
	children: ReactNode
	name?: string
}

export const TabContentLayout: FC<TabContentLayoutProps> = (props) => {
	// return (
	// 	<div className="flex flex-col p-4 bg-white" id={props.name} role="tabpanel" aria-labelledby={`${props.name}-tab`}>
	// 		{props.children}
	// 	</div>)
	return (
		<div className="pb-10">
			<div className="w-[97%] py-5 m-auto space-y-5">
				<div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-hidden bg-white border-borderGray border-1">
					{props.children}
				</div>
			</div>
		</div>
	)
}

