import { FC, ForwardedRef, ReactNode } from "react";

export interface TableProps {
  children: ReactNode;
  outsideRef?: ForwardedRef<HTMLDivElement>;
}

export const Table: FC<TableProps> = (props) => {
  return (
    <div ref={props.outsideRef} className="relative overflow-x-auto overflow-y-hidden shadow-md sm:rounded-lg">
      <table className="w-full overflow-x-hidden text-sm text-left text-textDark">
        {props.children}
      </table>
    </div>
  );
};
