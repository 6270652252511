

const convertMinutes = (minutes: number)  => {
    const days = Math.floor(minutes / 1440); // 1 day has 1440 minutes
    const hours = Math.floor((minutes % 1440) / 60); // remaining minutes divided by 60 gives hours
    const remainingMinutes = minutes % 60; // remaining minutes after converting to hours
  
    let result = "";
  
    if (days > 0) {
      result += `${days} day(s)`;
    }
  
    if (hours > 0) {
      result += `${result ? " and " : ""}${hours} hour(s)`;
    }
  
    if (remainingMinutes > 0) {
      result += `${result ? " and " : ""}${remainingMinutes} minute(s)`;
    }
    return result;
  }
  
const  convertHoursToDaysAndHours = (hours: number) => {
    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;
  
    let result = "";
  
    if (days > 0) {
      result += `${days} day(s)`;
    }
    if (remainingHours > 0) {
      result += `${result ? " and " : ""}${remainingHours} hour(s)`;
    }
    return result;
}
  
  const changeFormat = (
    deliver_unit: "hour" | "minute",
    deliver_time: number
  ) => {
    if (deliver_unit === "hour") return convertHoursToDaysAndHours(deliver_time);
    else return convertMinutes(deliver_time);
  };
  
  
  
  
  export const deliverTimeAndUnitCalculator = ( deliverTime:number, deliverUnit: 'hour' | 'minute'):string => {
      return changeFormat(deliverUnit, deliverTime)
  }