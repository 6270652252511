import { FC, memo, useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { TabContentLayout } from "../TabContentLayout";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import UseUploadFile from "../../../../Hook/UseUploadFile";
import UseUpload from "../../../../Hook/UseUpload";
import { baseURL } from "../../../../apiQuery";
import UploadProgress from "../../../General/UploadProgress";
import { toast } from "../../../../utils/Toast";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { createContentTemplate } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { useNavigate, useParams } from "react-router-dom";
import { SurveySequenceContentCreateOrUpdateRequest, SurveySequenceContentTemplate, initialSurveySequence } from "../../../../Types/ContentTemplate/SurveySequence";
import { ISurvey, ISurveyForServer, ISurveyItem } from "../../../../Types/ContentTemplate/Survey";
import AppDropDown from "../../../Shared/AppDropDown/AppDropDown";
import { LanguagesOptions } from "../../../../utils/Languages";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { InlineSurveyItem } from "./InlineItem";
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import SurveySequenceTabContent from "./SurveySequenceTabContent"
import { reorder } from "../../../Utils/Reorder"
import _, { random } from "lodash"
import { deepEqual } from "assert"
import { survey_items } from "../../../../Common/Types"

// const removeIdFromSurveyItems = (surveys: ISurvey[]): ISurvey[] => {
// 	return surveys.map((survey) => ({
// 		...survey,
// 		survey_items: survey.survey_items.map((i) => ({
// 			language: i.language,
// 			label: i.label,
// 			order: i.order,
// 			value: i.value
// 		})),
// 	}))
// }



const initialItem = {
	appId: uuidv4(),
	value: 0,
	label: "",
	order: 0,
	language: LanguageEnum.English,
};

const initialSurvey = {
	title: "",
	alt_title: "",
	description: "",
	language: LanguageEnum.English,
	id: 0,
	appId: uuidv4(),
	order: 0,
	survey_items: [],
}


const NewSurveySequence: FC = () => {
	const params = useParams<{ templateSlug: string }>()
	const navigate = useNavigate();

	const { currentOrganizationID } = useContext(UserContext);

	const [surveys, setSurveys] = useState<ISurvey[]>([]);
	const [surveySequence, setSurveySequenceContent] = useState<SurveySequenceContentTemplate>(initialSurveySequence);
	const createOrUpdateItemButton = useRef<string>("Create");


	//=====================  Upload Cover  ========================

	const [imageSrc, file, render, name] = UseUploadFile('Survey')


	const { uploadForm, progress, isLoading } = UseUpload(
		`${baseURL}upload`
	);

	const [uploadState, setUploadState] = useState({
		file,
		name,
	})

	useEffect(() => {
		if (name && file) {
			setUploadState({
				file,
				name,
			})
		}
	}, [name, file])


	// ============================ Mutation ================================

	const createSurveySequenceContentMutation = useMutation((param: SurveySequenceContentCreateOrUpdateRequest) => createContentTemplate(param), {
		onSuccess: async (data) => {
			toast("Content created successfully", "success")
			const id = data?.data?.id
			if (id) {
				navigate(`/content-templates/${params.templateSlug}/view/${id}`)
			}
		},
		onError: (error: any) => {
			const message = error?.response?.data?.message || "Something went wrong!"
			toast(message, "error")
		}
	})




	//  Submit Survey Sequence

	// ========================Handlers================================

	const onDragEnd = (result: DropResult): void => {
		if (!result.destination) {
			return
		}
		const localSurveys = surveys || []
		let updatedSurveys = reorder(
			localSurveys,
			result.source.index,
			result.destination.index
		) as ISurveyItem[]

		updatedSurveys = updatedSurveys.map((item, ind) => ({
			...item,
			order: ind
		}))

		// @ts-ignore
		// setWorkerFavorites(updatedFavorites);
		setSurveys(updatedSurveys)
	}




	const submitFormHandler = async (event: React.FormEvent) => {
		event.preventDefault();
		event.stopPropagation()

		if (surveySequence.survey_sequence.title === '') {
			toast('Please enter a title', 'error')
			return
		}


		let _cover_imageLink = ''

		if (uploadState.file !== undefined) {
			const formData = new FormData();
			// @ts-ignore
			formData.append("file", uploadState.file);
			const { link } = await uploadForm(formData)
			// setArticleContent(prev => ({...prev, cover_image: link}))
			_cover_imageLink = link
		}

		const surveySequenceContentWithImage: SurveySequenceContentTemplate = {
			survey_sequence: {
				...surveySequence.survey_sequence,
				surveys: surveys,
			},
			content_metadata: {
				...surveySequence.content_metadata,
				cover_image: _cover_imageLink,
			}
		}


		const newState = [surveySequenceContentWithImage]


		const shapeData: SurveySequenceContentCreateOrUpdateRequest = {
			content_type: EnumContentType.SurveySequenceContent,
			survey_sequence: newState.map(item => {
				return {
					...item.survey_sequence,
					title: item.survey_sequence.title,
					description: item.survey_sequence.description,
					surveys: item.survey_sequence.surveys.map((survey) => {
						survey.survey_items.map((surveyItem) => {
							return {
								id: surveyItem.id,
								label: surveyItem.label,
								language: surveyItem.language,
								order: surveyItem.order,
								value: surveyItem.value,
							}
						})
						return {
							id: survey.id,
							description: survey.description,
							language: survey.language,
							order: survey.order,
							survey_items: survey.survey_items,
							title: survey.title,
							alt_title: survey.alt_title,
							updated_at: survey.updated_at,
						} as ISurveyForServer
					}),
					language: item.survey_sequence.language as LanguageEnum,
				}
			}),
			content_metadata: newState.map(item => {
				return {
					cover_image: item.content_metadata.cover_image,
					preview_title: item.content_metadata.preview_title,
					tags: item.content_metadata.tags,
					organization_id: currentOrganizationID(),
					language: item.content_metadata.language as LanguageEnum,
					alt_title: item.content_metadata.alt_title,
				}
			})
		}

		//     const shapeData: SurveySequenceContentCreateOrUpdateRequest = {
		//   content_type: EnumContentType.SurveySequenceContent,
		//   survey_sequence: newState.map(item => {
		//     return {
		// 			title: item.survey_sequence.title,
		// 			description: item.survey_sequence.description,
		// 			survey: {
		// 				...item.survey_sequence,
		// 				surveys: item.survey_sequence.surveys.map((survey) => ({
		// 					...survey,
		// 					survey_items: survey.survey_items.map((item) => ({
		// 						order: item.order,
		// 						label: item.label,
		// 						value: item.value,
		// 						language: item.language,
		// 					})),
		// 				})),
		// 			},
		// 			language: item.survey_sequence.language as LanguageEnum,
		// 		}
		//   }),
		//   content_metadata: newState.map(item => {
		//     return {
		//       cover_image: item.content_metadata.cover_image,
		//       preview_title: item.content_metadata.preview_title,
		//       tags: item.content_metadata.tags,
		//       organization_id: currentOrganizationID(),
		//       language: item.content_metadata.language as LanguageEnum,
		//       alt_title: item.content_metadata.alt_title,
		//     }
		//   })
		// }

		await createSurveySequenceContentMutation.mutateAsync(shapeData)

	}


	const deleteSurveyItem = (index: number) => {
		const newSurveys = surveys.filter((_, idx) => idx !== index)
		setSurveys(newSurveys)
	}


	const saveSurveyItem = (index: number, survey: ISurvey) => {
		// if (survey.survey_items.length === 0) {
		//   toast("Please add at least one survey item", "info");
		//   return;
		// }

		// if (survey.title === "") {
		//   toast("Please enter a title for the survey", "info");
		//   return;
		// }

		const newSurveysList = surveys.map((s, idx) => {
			if (idx === index) {
				return survey
			}
			return s
		})

		if (index === undefined) {
			setSurveys((prev) => [...prev, survey])
		} else {
			setSurveys(newSurveysList)
		}


		// toast("Survey item saved.", "success");
	}

	// const saveSurveyItem = 

	return <TabContentLayout>

		<form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
			<h1 className="pb-8 text-xl">New Survery Sequence Content Template</h1>

			<h1 className="pb-8 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{surveySequence.survey_sequence.title || ''}</h1>
			<AppInput tabIndex={-1} autoFocus placeholder={"Title"} value={surveySequence.survey_sequence.title} name='title'
				isFull label='Title' required
				onChange={
					(e) => setSurveySequenceContent({ ...surveySequence, survey_sequence: { ...surveySequence.survey_sequence, title: e.target.value } })
				}
			/>
			<AppTextArea placeholder={"Description"} rows={4} value={surveySequence.survey_sequence.description} name='description'
				onChange={
					(e) => setSurveySequenceContent({ ...surveySequence, survey_sequence: { ...surveySequence.survey_sequence, description: e.target.value } })
				}
				label='Description' />



			{/* <AppInput placeholder={"Alternative Title"} value={surveySequence.content_metadata.alt_title} name='alt_title' isFull
				onChange={e => {
					setSurveySequenceContent({ ...surveySequence, content_metadata: { ...surveySequence.content_metadata, alt_title: e.target.value } })
				}} label='Alternative Title' maxLength={30} extendClass='my-2' required /> */}

			<AppInput placeholder={"Preview Title"} value={surveySequence.content_metadata.preview_title} name='preview_title' isFull
				onChange={e => {
					setSurveySequenceContent({ ...surveySequence, content_metadata: { ...surveySequence.content_metadata, preview_title: e.target.value } })
				}} label='Preview Title' extendClass='my-2' maxLength={140} required />

			<AppInput placeholder={"Tag"} value={surveySequence.content_metadata.tags[0]} name='tags' isFull
				onChange={e => {
					setSurveySequenceContent({ ...surveySequence, content_metadata: { ...surveySequence.content_metadata, tags: [e.target.value] } })
				}} label='Tag' extendClass='my-2' />

			{/* ===================== Survey Items ======================== */}

			<div className="p-2 ">
				<AppButton
					type="button"
					size="sm"
					variant="primary"
					extendClass="my-2"
					onClick={() => {
						//TODO: check is english is duplicated prevent to add

						setSurveys(prev => {
							return [
								...prev,
								{ ...initialSurvey, appId: uuidv4(), order: prev.length }
							]
						})

						createOrUpdateItemButton.current = "Create";
					}}
				>
					Add Survey
				</AppButton>
			</div>

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable-surveys">
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							className="flex flex-col gap-2"
						>
							{surveys.sort((a, b) => a.order - b.order).map((item: ISurvey, idx: number) => {
								return (
									<Draggable
										key={idx}
										draggableId={`survey-${idx}`}
										index={idx}
									>
										{(provided, snapshot) => (
											<div
												className="flex flex-col "
												ref={provided.innerRef}
												{...provided.draggableProps}
											>
												<SurveyItemDetails
													dragHandleProps={provided.dragHandleProps}
													index={idx}
													survey={item}
													key={item.id || item.appId}
													onDeleteSurveyItem={deleteSurveyItem}
													onSaveSurveyItem={saveSurveyItem}
												/>
											</div>
										)}
									</Draggable>
								)
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>


			{/* ===================== Survey Items ======================== */}

			{surveySequence.content_metadata.cover_image ?
				<div className="cover__image-container">

					<img alt="conver_image" src={surveySequence.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
				</div> : null}
			{render}
			{uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}
			{isLoading ? <UploadProgress progress={progress} /> : null}
			<div className='flex w-full gap-2 mt-3 mb-3'>
				<AppButton variant="primary" type="submit" extendClass="px-3">
					<div className="flex items-center justify-center gap-1">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 96 960 960"
							className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
						>
							<path
								fill="currentColor"
								d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
						</svg>
						Create Content Template          </div>
				</AppButton>
			</div>
		</form>

	</TabContentLayout>
}




export interface SurveyItemDetailsProps {
	survey: ISurvey;
	index: number;
	dragHandleProps: any;
	onDeleteSurveyItem: (index: number) => void;
	onSaveSurveyItem: (index: number, survey: ISurvey) => void;
}

const SurveyItemDetails = memo((props: SurveyItemDetailsProps) => {

	// ======================== useStates ================================

	const [item, setItem] = useState<ISurveyItem>({ ...initialItem, appId: uuidv4() });

	const [showInput, setShowInput] = useState({ appId: null, isInput: false });

	const [survey, setSurvey] = useState<ISurvey>(props.survey);

	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

	//========================= useRef ==========================================

	const createOrUpdateItemButton = useRef<string>("Create");

	const saveOrUpdateSurveyButton = useRef<string>("Save");



	//=====================  useEffects  =================================



	// useEffect(() => {
	//   if (props.survey.survey_items.length > 0) {
	//     setSurvey(props.survey)
	//   }
	// }, [props.survey])

	useEffect(() => {
		props.onSaveSurveyItem(props.index, survey)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [survey])




	//=====================  Handlers  =================================

	const onDragEnd = (result: DropResult): void => {
		if (!result.destination) {
			return
		}
		const survey_items = survey.survey_items || []
		let updatedSurveyItems = reorder(
			survey_items,
			result.source.index,
			result.destination.index
		) as ISurveyItem[]

		updatedSurveyItems = updatedSurveyItems.map((item, ind) => ({
			...item,
			order: ind,
		}))

		// @ts-ignore
		// setWorkerFavorites(updatedFavorites);
		setSurvey((prev) => {
			return {
				...prev,
				survey_items: updatedSurveyItems,
			}
		})
	}

	const addASurveyItem = () => {


		if (item.label === '') {
			toast('Please enter a label', 'error')
			return
		}


		setSurvey(prev => {
			return {
				...prev,
				survey_items: [...prev.survey_items, { ...item, order: prev.survey_items.length }]
			}
		})

		setItem({
			value: 0,
			label: '',
			id: 0,
			appId: uuidv4(),
			order: 0,
			language: LanguageEnum.English,
		})
		createOrUpdateItemButton.current = "Create"
	};


	return (
		<div className="p-3 my-5 overflow-hidden border rounded border-borderGray bg-gray-50">
			<button {...props.dragHandleProps}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 4.566 7.325"
					aria-hidden="true"
					className={
						'h-4 w-4 flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white '
					}
				>
					<path
						id="icon_drag"
						d="M14.893,15.325a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,15.325Zm2.758,0a.85.85,0,0,1-.629-.269.9.9,0,0,1,.641-1.539.9.9,0,0,1,.635,1.545A.891.891,0,0,1,17.651,15.325Zm-2.758-2.758a.879.879,0,0,1-.629-.252A.862.862,0,0,1,14,11.662a.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652A.913.913,0,0,1,14.893,12.566Zm2.758,0a.879.879,0,0,1-.629-.252.862.862,0,0,1-.263-.652.9.9,0,0,1,1.539-.641.863.863,0,0,1,.269.641.853.853,0,0,1-.269.652.913.913,0,0,1-.647.252ZM14.893,9.808a.85.85,0,0,1-.629-.269A.9.9,0,0,1,14.9,8a.9.9,0,0,1,.635,1.545A.891.891,0,0,1,14.893,9.808Zm2.758,0a.85.85,0,0,1-.629-.269A.9.9,0,0,1,17.662,8,.9.9,0,0,1,18.3,9.545.891.891,0,0,1,17.651,9.808Z"
						transform="translate(-14 -8)"
						fill="currentColor"
					/>
				</svg>{' '}
			</button>

			<div className="flex items-center justify-between p-3">
				<div className="flex flex-col w-full py-2 pl-1">
					<span className="font-semibold text-darkColor">
						Survey Items Details
					</span>
					{isCollapsed ? (
						<>
							<p className="mt-2 text-sm text-textDark">
								Title: {survey.title}
							</p>
						</>
					) : null}
				</div>
				<button
					onClick={(event) => {
						event.preventDefault()
						setIsCollapsed((prev) => !prev)
					}}
				>
					{!isCollapsed ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6"
							viewBox="0 -960 960 960"
						>
							<path
								fill="currentColor"
								d="M480-344L240-584l43-43 197 197 197-197 43 43-240 240z"
							></path>
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6"
							viewBox="0 -960 960 960"
						>
							<path
								fill="currentColor"
								d="M480-554L283-357l-43-43 240-240 240 240-43 43-197-197z"
							></path>
						</svg>
					)}
				</button>
			</div>
			<div className={`${isCollapsed ? 'hidden' : ''}`}>
				<div className="grid grid-cols-1 gap-2 mb-2">
					<div className="w-11/12 p-1 m-1">
						<AppInput
							placeholder={'Title'}
							name="title"
							isFull
							onChange={(e) => {
								setSurvey((prev) => {
									return {
										...prev,
										title: e.target.value,
									}
								})
							}}
							value={survey.title}
							label="Title"
							required
						/>
						{/* <AppInput
							placeholder={'Alternative Title'}
							name="survey_alt_title"
							isFull
							onChange={(e) => {
								setSurvey((prev) => {
									return {
										...prev,
										alt_title: e.target.value,
									}
								})
							}}
							value={survey.alt_title}
							label="Alternative Title"
							required
						/> */}
						<AppTextArea
							rows={3}
							placeholder={'Description'}
							name="description"
							value={survey.description}
							isFull
							onChange={(e) => {
								setSurvey((prev) => {
									return {
										...prev,
										description: e.target.value,
									}
								})
							}}
							label="Description"
						/>
					</div>
				</div>

				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={`droppable-survey-items-${survey.appId}`}>
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								className="flex flex-col gap-2"
							>
								{survey?.survey_items
									? survey?.survey_items
										?.sort((a, b) => a.order - b.order)
										?.map((item, idx: number) => {
											return (
												<Draggable
													key={item.appId}
													draggableId={`item-${survey.appId}-${item.appId ?? idx}`}
													index={idx}
												>
													{(provided, snapshot) => (
														<div
															className="flex flex-col "
															ref={provided.innerRef}
															{...provided.draggableProps}
														>
															<InlineSurveyItem
																dragHandleProps={provided.dragHandleProps}
																surveys={survey}
																item={item}
																setSurvey={setSurvey}
																showInputAppId={showInput.appId}
																showInput={showInput}
																setShowInput={setShowInput}
															/>
														</div>
													)}
												</Draggable>
											)
										})
									: null}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				{/* ADD new Survey */}
				<div className="flex flex-col items-start justify-start w-[90%] p-2 m-1 border rounded bg-hoverLightBlue gap-4">
					<AppInput
						placeholder={'Survey Item Label'}
						value={item.label}
						name="label"
						label="Survey Item Label"
						onChange={(e) =>
							setItem((prev) => {
								return { ...prev, label: e.target.value }
							})
						}
						extendClass=""
						isFull
					/>
					<AppInput
						type="number"
						placeholder={'Survey Item Value'}
						value={item.value.toString()}
						name="label"
						label="Survey Item Value"
						onChange={(e) =>
							setItem((prev) => {
								return { ...prev, value: Number(e.target.value) }
							})
						}
						extendClass=""
						isFull
					/>

					<AppDropDown
						label={'Language'}
						isFull
						disabled
						options={LanguagesOptions}
						value={item.language}
						name="language"
						onChange={(e) =>
							setItem((prev) => {
								return { ...prev, language: e.target.value as LanguageEnum }
							})
						}
					/>

					<AppButton
						variant={
							createOrUpdateItemButton.current === 'Create'
								? 'secondary'
								: 'info'
						}
						type={'button'}
						size={'sm'}
						onClick={addASurveyItem}
						extendClass="mx-2"
					>
						{createOrUpdateItemButton.current} Survey Item
					</AppButton>
				</div>
			</div>

			<div className="flex w-full p-2 mt-3 bg-gray-50">
				{/* <AppButton variant="success" onClick={() => {
          props.onSaveSurveyItem(props.index, survey)
          setIsCollapsed(true)
        }} type="button" size="sm" extendClass="mx-2">Save Survey</AppButton> */}
				<AppButton
					variant="danger"
					onClick={() => {
						props.onDeleteSurveyItem(props.index)
					}}
					type="button"
					size="sm"
					extendClass="mx-2"
				>
					Delete Survey
				</AppButton>
			</div>
		</div>
	)
})

export default NewSurveySequence;