import { FC, useContext, useEffect, useRef, useState } from "react";
import { TabContentLayout } from "../TabContentLayout";
import AppInput from "../../../Shared/AppInput/AppInput";
import { AppButton } from "../../../../Theme";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import UseUploadFile from "../../../../Hook/UseUploadFile";
import UseUpload from "../../../../Hook/UseUpload";
import { baseURL } from "../../../../apiQuery";
import UploadProgress from "../../../General/UploadProgress";
import { toast } from "../../../../utils/Toast";
import { EnumContentType } from "../../../../Types/ContentTemplate/ContentType";
import { UserContext } from "../../../../Context/UserContext/UserContext";
import { LanguageEnum } from "../../../../Types/ContentTemplate/Langauge";
import { useMutation } from "react-query";
import { createContentTemplate } from "../../../../apiQuery/ContentTemplate/ContentTemplate.apis";
import { useNavigate, useParams } from "react-router-dom";
import { FAQContentCreateOrUpdateRequest, FAQContentTemplate, IFAQQuestion, initialFAQContent } from "../../../../Types/ContentTemplate/FAQ";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd"
import { InlineFAQItem } from "./InlineItem"
import { reorder } from "../../../Utils/Reorder"
import { v4 as uuidv4 } from 'uuid';


const NewFAQ: FC = () => {
  const params = useParams<{ templateSlug: string }>()
  const navigate = useNavigate();

  const { currentOrganizationID } = useContext(UserContext);

  const [FAQContent, setFAQContent] = useState<FAQContentTemplate>(initialFAQContent)

  const createOrUpdateItemButton = useRef<string>("Create")

  const [showInput, setShowInput] = useState({ id: null, isInput: false });



  //=====================  Handlers  =================================
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }
    const list = FAQContent?.faq?.questions || [];
    let upDatedList = reorder(
      list,
      result.source.index,
      result.destination.index
    ) as IFAQQuestion[];

    upDatedList = upDatedList.map((item, ind) => ({
      ...item,
      order: ind
    }))

    // @ts-ignore
    // setWorkerFavorites(updatedFavorites);
    setFAQContent(prev => ({
      ...prev,
      faq: {
        ...prev.faq,
        questions: upDatedList,
      }
    }));
  };

  //=====================  Upload Cover  =================================
  const [imageSrc, file, render, name] = UseUploadFile('FAQ')
  const { uploadForm, progress, isLoading } = UseUpload(
    `${baseURL}upload`
  );
  const [uploadState, setUploadState] = useState({
    file,
    name,
  })

  useEffect(() => {
    if (name && file) {
      setUploadState({
        file,
        name,
      })
    }
  }, [name, file])

  // ============================ Mutation ================================

  const createFAQContentMutation = useMutation((param: FAQContentCreateOrUpdateRequest) => createContentTemplate(param), {
    onSuccess: async (data) => {
      toast("Content created successfully", "success")
      const id = data?.data?.id
      if (id) {
        navigate(`/content-templates/${params.templateSlug}/view/${id}`)
      }
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message || "Something went wrong!"
      toast(message, "error")
    }
  })

  // ======================  Submit Article  ========================
  const submitFormHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    if (FAQContent.faq.title === '') {
      toast('Please enter a title', 'error')
      return
    }



    let _cover_imageLink = ''

    if (uploadState.file !== undefined) {
      const formData = new FormData();
      // @ts-ignore
      formData.append("file", uploadState.file);
      const { link } = await uploadForm(formData)
      _cover_imageLink = link
    }

    const faqContentWithImage: FAQContentTemplate = {
      faq: FAQContent.faq,
      content_metadata: {
        ...FAQContent.content_metadata,
        cover_image: _cover_imageLink,
      }
    }

    const newState = [faqContentWithImage]
    const shapeData: FAQContentCreateOrUpdateRequest = {
      content_type: EnumContentType.FAQContent,
      faq: newState.map(item => {
        return {
          title: item.faq.title,
          description: item.faq.description,
          questions: item.faq.questions.map(question => ({ question: question.question, order: question.order, answer: question.answer, language: question.language, faq_id: question.faq_id })),
          language: item.faq.language as LanguageEnum,
        }
      }),
      content_metadata: newState.map(item => {
        return {
          cover_image: item.content_metadata.cover_image,
          preview_title: item.content_metadata.preview_title,
          tags: item.content_metadata.tags,
          organization_id: currentOrganizationID(),
          language: item.content_metadata.language as LanguageEnum,
          alt_title: item.content_metadata.alt_title,
        }
      })
    }
    await createFAQContentMutation.mutateAsync(shapeData)
  }



  const [item, setItem] = useState<IFAQQuestion>({
    question: '',
    order: 0,
    answer: '',
    language: LanguageEnum.English,
    faq_id: 0,
    id: uuidv4()
  })

  const addItemToQuestions = () => {
    if (item.answer === '' || item.question === '') {
      toast('Please fill the question and answer fields', 'info')
      return
    }

    item.language = FAQContent.faq.language

    setFAQContent(prev => {
      return {
        ...prev,
        faq: {
          ...prev.faq,
          questions: [...prev.faq.questions,
          {
            id: uuidv4(),
            order: prev.faq.questions.length,
            question: item.question,
            answer: item.answer,
            faq_id: item.faq_id,
            language: item.language
          }
          ]
        }
      }
    })

    setItem({
      question: '',
      order: 0,
      answer: '',
      language: LanguageEnum.English,
      faq_id: 0,
      id: uuidv4()
    })
    createOrUpdateItemButton.current = "Create"
  }


  return <TabContentLayout>

    <form onSubmit={submitFormHandler} className="px-5 py-2 w-[90%] mb-4">
      <h1 className="pb-8 text-xl">New FAQ Content Template</h1>

      <h1 className="pb-8 mt-5 mb-2 ml-1 text-xl font-normal text-textDark">{FAQContent.faq.title || ''}</h1>

      <AppInput tabIndex={-1} autoFocus placeholder={"Title"} value={FAQContent.faq.title} name='title'
        isFull label='Title' required
        onChange={
          (e) => setFAQContent({ ...FAQContent, faq: { ...FAQContent.faq, title: e.target.value } })
        }
      />
      <AppTextArea placeholder={"Description"} rows={4} value={FAQContent.faq.description} name='description'
        onChange={
          (e) => setFAQContent({ ...FAQContent, faq: { ...FAQContent.faq, description: e.target.value } })
        }
        label='Description' />


      {/* <AppInput placeholder={"Alternative Title"} value={FAQContent.content_metadata.alt_title} name='alt_title' isFull
        onChange={e => {
          setFAQContent({ ...FAQContent, content_metadata: { ...FAQContent.content_metadata, alt_title: e.target.value } })
        }} label='Alternative Title' maxLength={30} extendClass='my-2' /> */}

      <AppInput placeholder={"Preview Title"} value={FAQContent.content_metadata.preview_title} name='preview_title' isFull
        onChange={e => {
          setFAQContent({ ...FAQContent, content_metadata: { ...FAQContent.content_metadata, preview_title: e.target.value } })
        }} label='Preview Title' extendClass='my-2' maxLength={140} required />

      <AppInput placeholder={"Tag"} value={FAQContent.content_metadata.tags[0]} name='tags' isFull
        onChange={e => {
          setFAQContent({ ...FAQContent, content_metadata: { ...FAQContent.content_metadata, tags: [e.target.value] } })
        }} label='Tag' extendClass='my-2' />


      {/* NEW CODE ZZZ */}
      <div className='p-2 my-2 border border-gray-200 rounded shadow-sm '>
        <p className='py-2 pl-1 font-semibold text-textDark'>Questions</p>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="grid grid-cols-1 gap-2 mb-2">
                {FAQContent.faq.questions.map((item: IFAQQuestion, idx: number) => (
                  <Draggable key={idx} draggableId={`item-${idx}`} index={idx}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex flex-col "
                      >
                        <InlineFAQItem
                          key={item.id}
                          item={item}
                          idx={idx}
                          setFAQContent={setFAQContent}
                          showInputId={showInput.id}
                          showInput={showInput}
                          setShowInput={setShowInput}
                        />

                      </div>)}
                  </Draggable>


                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* ADD new Question */}
        <div className="flex flex-col items-start justify-start w-[90%] p-2 m-1 border rounded bg-hoverLightBlue">
          <AppInput placeholder={"Question"} value={item.question} name='question'
            onChange={e => setItem(prev => { return { ...prev, question: e.target.value } })} extendClass='' isFull />
          <AppTextArea rows={4} placeholder={"Answer"} value={item.answer} name='answer'
            onChange={e => setItem(prev => { return { ...prev, answer: e.target.value } })} extendClass='' isFull />

          <AppButton variant={createOrUpdateItemButton.current === 'Create' ? 'success' : 'info'} type={"button"}
            size={"sm"} onClick={addItemToQuestions} extendClass="mx-2">{createOrUpdateItemButton.current} Question</AppButton>
        </div>
      </div>




      {FAQContent.content_metadata.cover_image ?
        <div className="cover__image-container">

          <img alt="conver_image" src={FAQContent.content_metadata.cover_image} className='my-2 rounded-sm cover-image' />
        </div>
        : null}
      {render}
      {uploadState.name !== undefined ? <p>{uploadState.name}</p> : null}
      {isLoading ? <UploadProgress progress={progress} /> : null}
      <div className='flex w-full gap-2 mt-3 mb-3'>
        <AppButton disabled={isLoading || createFAQContentMutation.isLoading} variant="primary" type="submit" extendClass="px-3">
          <div className="flex items-center justify-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 96 960 960"
              className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
            >
              <path
                fill="currentColor"
                d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"></path>
            </svg>
            Create Content Template          </div>
        </AppButton>
      </div>
    </form>

  </TabContentLayout>
}

export default NewFAQ;