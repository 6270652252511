import { FC, useState, useMemo, memo } from "react";
import { SelectedModalEnum } from "../Rule.types";
import { useMutation, useQuery } from "react-query";
import { createActionRule, createContentRule, createRule, getRuleOprands } from "../../../../apiQuery/Condition/Rule.apis";
import { IOperandsResponse } from "../../../../Types/Operands/Operands";
import initialQuery from "../../../../utils/InitialRules";
import { Field, RuleGroupType } from "react-querybuilder";
import { QueryBuilderContainer, formatterQuery, backendReplaceAllObjectKeys } from "../../../Container";
import { AppButton, CloseIcon } from "../../../../Theme";
import { ruleBody } from "../../../../Common/Types";
import { toast } from "../../../../utils/Toast";
import AppInput from "../../../Shared/AppInput/AppInput";
import AppTextArea from "../../../Shared/AppTextArea/AppTextArea";
import { renameRuleName } from "../../../../utils/RuleHelper"

interface NewContentModalProps {
  type: SelectedModalEnum;
  modalHandle: () => void;
}

export const NewRuleModal: FC<NewContentModalProps> = (props) => {
  const [query, setQuery] = useState<RuleGroupType>(initialQuery);

  const [ruleName, setRuleName] = useState('')
  const [ruleDescription, setRuleDescription] = useState('')

  const {
    isLoading,
    data: operands,
    error,
  } = useQuery<IOperandsResponse, Error>("operands", getRuleOprands);

  //  @ts-ignore
  const fields: Field[] = useMemo(() => {
    return operands?.data?.map((op) => {
      return {
        name: op.value,
        label: op.display,
        valueEditorType: op.data_type === "bool" ? "checkbox" : "text",
        inputType: op.display.includes("Date") ? "date" : "text",
        operators: [
          { name: "=", label: "=" },
          { name: "!=", label: "!=" },
        ],
      };
    });
  }, [operands?.data]);

  const createAContentRule = useMutation((ruleBody: ruleBody) => createContentRule(ruleBody), {
    onSuccess: () => {
      toast('Content Rule has been created.', 'success')
      props.modalHandle()
    },
    onError: (error:any) => {
      toast(error.response.data.message, 'error')
     }

  })

  const createAActionRule = useMutation((ruleBody: ruleBody) => createActionRule(ruleBody), {
    onSuccess: () => {
      toast('Action Rule has been created.', 'success')
      props.modalHandle()
    },
    onError: (error:any) => {
      toast(error.response.data.message, 'error')
     }
  })

  const createAStateRule = useMutation((ruleBody: ruleBody) => createRule(ruleBody), {
    onSuccess: () => {
      toast('State Rule has been created.', 'success')
      props.modalHandle()
    },
    onError: (error:any) => {
      toast(error.response.data.message, 'error')
     }
  })


  const saveRule = async () => {

    if (ruleName === "") {
      toast('Rule name should not be empty', 'error')
      return
    }

    const formattedQuery = await formatterQuery(query)

    const preparedForBackend = await backendReplaceAllObjectKeys(formattedQuery)
  

    if (props.type === SelectedModalEnum.content) {

      createAContentRule.mutateAsync({
        deliver_time: 0,
        deliver_unit: 'minute',
        rule_json: {
          when: preparedForBackend,
          desc: ruleDescription,
          name: renameRuleName(ruleName),
          salience: 0
        }
      })

    }
    if (props.type === SelectedModalEnum.state) {


      createAStateRule.mutateAsync({
        deliver_time: 0,
        deliver_unit: 'minute',
        rule_json: {
          when: preparedForBackend,
          desc: ruleDescription,
          name: renameRuleName(ruleName),
          salience: 0
        }
      })

    }

    if (props.type === SelectedModalEnum.action) {
      createAActionRule.mutateAsync({
        deliver_time: 0,
        deliver_unit: 'minute',
        rule_json: {
          when: preparedForBackend,
          desc: ruleDescription,
          name: renameRuleName(ruleName),
          salience: 0
        }
      })
      }

  }

  return (
    <div className="relative z-50 flex flex-col min-h-[65%] min-w-[75%] max-h-[98%] py-6 overflow-auto bg-white rounded-md shadow-md text-textDark">
      <div className="flex justify-between px-10">
        <div className="flex flex-col mb-1">
          <div>
            <h2 className="mb-1 text-2xl font-normal capitalize text-textDark">
              New {props.type} Rule
            </h2>
          </div>
        </div>
        <button
          type="button"
          onClick={props.modalHandle}
          className="absolute mr-2 z-50 top-2.5 right-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <CloseIcon className="w-4 h-4" />
          <span className="sr-only">Close menu</span>
        </button>
      </div>
      <hr className="w-full h-px my-5 border-1 border-borderGray dark:border-textDark" />
      <div className="flex flex-col items-center justify-between w-full px-10">

        <div className={`p-4 w-full`}>
          <p className="mb-2 text-sm text-textGray">Rule details</p>
          <div className="flex flex-col gap-1 pr-10">
            <div className="flex items-center ">
              <span className="text-sm w-60">Rule name</span>
              <AppInput isFull type="text" placeholder={""} value={ruleName} name='name'
                onChange={e => {
                  setRuleName(e.target.value)
                }} label='' extendClass='my-2' />
            </div>

            <div className="flex items-center ">
              <span className="text-sm w-60">Rule description</span>
              <AppTextArea placeholder={""} rows={4} value={ruleDescription} name='description'
                onChange={
                  (e) => { setRuleDescription(e.target.value) }
                } isFull />
            </div>
          </div>
        </div>

        <div className="w-[90%]">
          <QueryBuilderContainer
            fields={fields}
            query={query}
            setQuery={(q) => setQuery(q)}
          />
        </div>
        <div className="self-start p-4">
          <AppButton variant="primary" type="submit" extendClass="px-3" onClick={saveRule}>
            <div className="flex items-center justify-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 96 960 960"
                className="flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor"
              >
                <path
                  fill="currentColor"
                  d="M840 373v503q0 24-18 42t-42 18H180q-24 0-42-18t-18-42V276q0-24 18-42t42-18h503l157 157zM479.765 811Q523 811 553.5 780.735q30.5-30.264 30.5-73.5Q584 664 553.735 633.5q-30.264-30.5-73.5-30.5Q437 603 406.5 633.265q-30.5 30.264-30.5 73.5Q376 750 406.265 780.5q30.264 30.5 73.5 30.5zM233 472h358V329H233v143z"
                ></path>
              </svg>
              Save Rule
            </div>
          </AppButton>
        </div>
      </div>
    </div>
  );
};
